import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import { batch } from 'react-redux';
import {
  setMonitoringList,
  setMonitoringPagination,
  setMonitoringSorting,
} from './sliceReducer';
import { monitoringApi } from '../../../common/api/services/MonitorngApi/monitoringApi';

export const getMonitoring = createAsyncThunk(
  'monitoring/getMonitoring',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const currentPagination = (getState() as IRootState).monitoring
      .monitoringList.pagination;
    const currentFilter = (getState() as IRootState).monitoring.monitoringList
      .filter;
    const currentSort = (getState() as IRootState).monitoring.monitoringList
      .sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;
    const response = await monitoringApi.getMonitoringList(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setMonitoringList(response));
        dispatch(setMonitoringPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeMonitoringSorting = createAsyncThunk(
  'monitoring/changeMonitoringSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).monitoring
      .monitoringList.sort;

    if (currentSortBy !== sortBy) {
      dispatch(setMonitoringSorting([currentSortBy, 'asc']));
      dispatch(getMonitoring({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setMonitoringSorting([currentSortBy, 'desc']));
      dispatch(getMonitoring({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setMonitoringSorting(['', '']));
      dispatch(getMonitoring({ currentPage: 1 }));
      return;
    }
  },
);

export const saveMonitoringNotes = createAsyncThunk(
  'monitoring/saveMonitoringNotes',
  async (
    [consultantId, clientId, notes]: [string, string, string],
    { dispatch },
  ) => {
    await monitoringApi.saveMonitoringNotes(consultantId, clientId, notes);
    dispatch(getMonitoring());
  },
);

export const exportToExelMonitoring = createAsyncThunk(
  'monitoring/exportToExelMonitoring',
  async (_, { getState }) => {
    const { monitoringList } = (getState() as IRootState).monitoring;
    const { sort, filter, selectedElement } = monitoringList;

    await monitoringApi.exportToExel(filter, sort, selectedElement);
  },
);
