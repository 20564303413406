import './CommonTexts.less';
import moment from '../../common/constants/moment';
import CommonDatePicker from '../date-picker/CommonDatePicker';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {
  commonDateMonthFormat,
  commonDateSpaceFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Dropdown from '../dropdown/Dropdown';
import { setSelectedDublicateIdx } from '../../redux/reducers/onboarding/sliceReducer';
import { IComment } from '../../common/api/services/ConsultantApi/types/IComments';
import { convertSelectedIdx } from '../../common/utils/onboardingSortHelper';
import Checkbox from '../checkbox/Checkbox';

interface IConfirmProps {
  names: string[];
  fields: Array<string[]>;
  newClientsList: string[];
}

interface IPayslipsProps {
  payslipsCount: number;
  type: 'delete' | 'publish' | 'unpublish';
}
interface IACPayslipsProps {
  payslipsCount: number;
  type: 'delete' | 'publish' | 'unpublish';
  payslips: Array<any>;
}

interface IDeleteTimesheetProps {
  publicId: string;
  firstName: string;
  lastName: string;
  type: 'Timesheet' | 'Expenses';
}
interface IDeleteCommentProps {
  comment?: IComment;
}
interface IUploadDate {
  payslipsCount: number;
  onChangeDate: (date: moment.Moment) => void;
}

interface IGenerateProps {
  onChangeDate: (date: { month: number; year: number } | null) => void;
  perMonthOnly: boolean;
  onChangePerMonthOnly: (perMonthOnly: boolean) => void;
}

export const ConfirmChangingClientData = (props: IConfirmProps) => {
  const { fields, names, newClientsList } = props;

  const setClientText = (client: string, index: number, length: number) => {
    if (length === 1 || index + 1 === length) {
      return client;
    }
    return `${client}, `;
  };

  return (
    <div className="change-client-container">
      {newClientsList.length > 0 && (
        <div className="change-client-clients-list">
          You are going to create a new client
          {newClientsList.length > 1 ? 's' : ''}{' '}
          <b>
            {newClientsList.map((client, index) =>
              setClientText(client, index, newClientsList.length),
            )}
          </b>
          . Are you sure you want to proceed? This action is irreversible
        </div>
      )}
      {fields.map((field, index) => (
        <div key={index} className="change-client-fields-title">
          <span>
            You are going to add a next new info for <b>{names[index] || ''}</b>
            . Are you sure you want to proceed? This action is irreversible.
          </span>
          <br />
          <ul className="change-client-fields-list">
            {field.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export const DeletePayslipsText = ({ payslipsCount, type }: IPayslipsProps) => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to {type || ''} these {payslipsCount || ''} files?
  </span>
);

export const UploadDatePayslips = ({
  payslipsCount,
  onChangeDate,
}: IUploadDate) => {
  return (
    <div>
      <h4 style={{ marginBottom: '1rem' }}>
        Update date ({payslipsCount || 1})
      </h4>
      <CommonDatePicker
        defaultValue={''}
        changeDate={(date) => {
          onChangeDate(moment(date ? moment(date) : ''));
        }}
        className="date-picker-form form-date-picker"
        placeholder="Select the payment date"
      />
    </div>
  );
};
export const DeleteActivityReportPayslipsText = ({
  payslipsCount,
  type,
  payslips,
}: IACPayslipsProps) => (
  <div>
    <span style={{ fontSize: '16px', textAlign: 'center' }}>
      Are you sure you want to {type || ''} these {payslipsCount || ''} files?
    </span>
    <div className="payslips-list">
      {payslips.map((item, index) => (
        <span key={index} style={{ color: '#e48900', marginTop: '0.5rem' }}>
          {`${item?.item?.firstName || ''} ${item?.item?.lastName || ''} ${`(${
            item.clientName || ''
          })`},
            ${item?.commonPeriod || ''}`}
        </span>
      ))}
    </div>
  </div>
);
export const DeletePayslipText = (props: { type?: string }) => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this {props.type || 'payslip'} file?
  </span>
);

export const DeleteDocumentText = () => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this document?
  </span>
);

export const DeleteFilesText = () => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this files?
  </span>
);

export const DeleteFileText = () => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this file?
  </span>
);

export const GenerateTimesheets = (props: any) => {
  const { onChangeDate } = props;
  const [currentDate, setCurrentDate] = useState<string>('');
  const disabledDate = (current: moment.Moment) => {
    return current && current < moment();
  };

  const selectDate = (date: Date | null) => {
    setCurrentDate(date ? moment(date).format(commonDateMonthFormat) : '');
    if (date) {
      onChangeDate({
        month: moment(date).month() + 1,
        year: moment(date).year(),
      });
      return;
    }
    onChangeDate(null);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: '20px', fontWeight: 700 }}>
        Generate documents
      </span>
      <span className="generate-description" style={{ marginBottom: '1rem' }}>
        For what period should the Timesheets and Expenses be generated? If you
        don’t pick any period, they will be generated for the actual month
      </span>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className="generate-timesheet-date">
        <CommonDatePicker
          picker="month"
          label="Period"
          defaultValue={currentDate || ''}
          changeDate={selectDate}
          className="date-picker-form"
          disabledDate={disabledDate}
        />
        {currentDate && (
          <CloseCircleOutlined
            style={{ marginLeft: '1rem' }}
            onClick={() => selectDate(null)}
          />
        )}
      </div>
    </div>
  );
};

export const GenerateTimesheetsText = (props: IGenerateProps) => {
  const { onChangeDate, onChangePerMonthOnly, perMonthOnly } = props;
  const [currentDate, setCurrentDate] = useState<string>('');
  const disabledDate = (current: moment.Moment) => {
    return current && current < moment();
  };

  const selectDate = (date: Date | null) => {
    setCurrentDate(date ? moment(date).format(commonDateMonthFormat) : '');
    if (date) {
      onChangeDate({
        month: moment(date).month() + 1,
        year: moment(date).year(),
      });
      return;
    }
    onChangeDate(null);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: '20px', fontWeight: 700 }}>
        Generate documents
      </span>
      <span className="generate-description" style={{ marginBottom: '1rem' }}>
        For what period should the Timesheets and Expenses be generated? If you
        don’t pick any period, they will be generated for the actual month
      </span>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className="generate-timesheet-date">
        <CommonDatePicker
          picker="month"
          label="Period"
          defaultValue={currentDate || ''}
          changeDate={selectDate}
          className="date-picker-form"
          disabledDate={disabledDate}
        />
        {currentDate && (
          <CloseCircleOutlined
            style={{ marginLeft: '1rem' }}
            onClick={() => selectDate(null)}
          />
        )}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Checkbox
          label="Apply only for monthly contracts"
          checked={!!perMonthOnly}
          onChange={onChangePerMonthOnly}
        />
      </div>
    </div>
  );
};

export const DeleteTimesheetText = (props: IDeleteTimesheetProps) => {
  const { publicId, firstName, lastName, type } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: '20px', fontWeight: 700 }}>
        Delete {type.toLowerCase()}
      </span>
      <span className="generate-description">
        Are you sure you want to delete {type} {publicId || ''} for{' '}
        {firstName || ''} {lastName || ''}?
      </span>
      {type === 'Timesheet' && (
        <span className="generate-description">
          If you delete this Timesheet, the Expenses connected to it will be
          deleted as well.
        </span>
      )}
    </div>
  );
};

export const SelectDublicateClient = () => {
  const dispatch = useAppDispatch();
  const { workContract } = useAppSelector((state) => state.onboarding);
  const { companies, selectedDublicateIdx } = workContract;

  const extractFirstNumberInParentheses = (inputString: string) => {
    // Define a regular expression pattern to match the first number in parentheses
    const regex = /\((\d+)\)/;

    // Use the `exec` method to find the first match in the input string
    const match = regex.exec(inputString);

    // Check if a match was found
    if (match && match[1]) {
      // Extract and return the number from the matched group
      dispatch(setSelectedDublicateIdx(parseInt(match[1], 10) - 1));
    } else {
      dispatch(setSelectedDublicateIdx(0));
    }
  };

  return (
    <div style={{ padding: '0 1rem' }}>
      <div style={{ marginBottom: '1rem' }}>
        <b style={{ fontSize: '16px' }}>
          Based on what client you would like to duplicate information?
        </b>
      </div>
      <Dropdown
        options={companies
          .filter((item) => item.name)
          .map((item, index) => `(${index + 1}) ${item.name}`)}
        label="Client name"
        customClassName="dropdown-placeholder"
        value={companies[selectedDublicateIdx].name}
        onChange={(value) => extractFirstNumberInParentheses(value)}
        isSearch={false}
        showSearch={false}
      />
    </div>
  );
};

export const DeleteComment = (props: IDeleteCommentProps) => {
  const { comment } = props;
  return (
    <span style={{ fontSize: '16px', fontWeight: 400 }}>
      Are you sure you want to delete comment from{' '}
      {comment?.user?.firstName || ''} {comment?.user.lastName || ''} /{' '}
      {moment(comment?.updatedAt).format(commonDateSpaceFormat)}? Changes you
      have made will not be saved.
    </span>
  );
};
export const ChangeStatusComment = (props: IDeleteCommentProps) => {
  const { comment } = props;

  return (
    <span style={{ fontSize: '16px', fontWeight: 400 }}>
      You want to mark comment <b>&quot;{comment?.comment}&quot;</b> from{' '}
      {comment?.user?.firstName || ''} {comment?.user.lastName || ''} /{' '}
      {moment(comment?.updatedAt).format(commonDateSpaceFormat)} as{' '}
      {comment?.status === 'active' ? 'completed' : 'uncompleted'}. Proceed?
    </span>
  );
};

export const DeleteRate = () => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this rate?
  </span>
);

export const FinishContractText = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontWeight: 700, fontSize: '20px' }}>
        Finish the contract
      </span>
      <span style={{ fontWeight: 400, fontSize: '16px', marginTop: '20px' }}>
        Are you sure you want to mark the Consultant as with the finished
        contract?
      </span>
      <span style={{ fontWeight: 400, fontSize: '16px', marginTop: '30px' }}>
        This action is irreversible and will remove him from the general table
      </span>
    </div>
  );
};

export const ConfirmApprove = ({ mainActivity }: any) => {
  return (
    <div>
      <span style={{ fontSize: '16px' }}>
        Are you sure you want to approve this{' '}
        {mainActivity.kind === 'T' ? 'Timesheet' : 'Expenses'}?{' '}
        {mainActivity['consultant.firstName'] || ''}{' '}
        {mainActivity['consultant.lastName'] || ''} already has a published
        payslip for{' '}
        {moment(mainActivity.period).format(commonMonthYearSpaceFormat)}
      </span>
    </div>
  );
};

export const DeleteBankAccount = ({ name }: any) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    <h4>Delete bank account</h4>
    <span style={{ fontSize: '16px' }}>
      Are you sure you want to delete Bank account {name}? This action is
      irreversible
    </span>
  </div>
);

export const DeleteInvoice = ({ invoiceNumber }: any) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    <h4>Delete invoice {invoiceNumber}</h4>
    <span style={{ fontSize: '16px' }}>
      Are you sure you want to delete <b>Invoice {invoiceNumber}</b>? This
      action is irreversible
    </span>
  </div>
);

export const DeleteInvoiceText = () => (
  <span style={{ fontSize: '16px', textAlign: 'center' }}>
    Are you sure you want to delete this invoice?
  </span>
);
