import { LeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { activityApi } from '../../common/api/services/ActivityApi/activityApi';
import {
  IARPAyloadDTO,
  IARPayload,
  IActivitiesResponse,
  IActivityReportResponse,
  IExpenseResponse,
} from '../../common/api/services/ActivityApi/types/IActivityReportResponse';
import './ActivityReportInfo.less';
import { openErrorNotification } from '../../components/notification/Notification';
import { consultantApi } from '../../common/api/services/ConsultantApi/consultantApi';
import { IComments } from '../../common/api/services/ConsultantApi/types/IComments';
import ActivityReportInfoTab from './components/ActivityReportInfoTab';
import AddActivityReportModal from './modals/AddActivityReportModal';
import { Spin, Tabs } from 'antd';
import TrashIcon from '../../assets/icons/TrashIcon';
import AddNewModal from './modals/AddNewModal';
import moment from '../../common/constants/moment';

const ActivityReportInfo = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const consultantId = searchParams.get('consultantId');
  const projectId = searchParams.get('projectId');
  const clientId = searchParams.get('clientId');
  const period = searchParams.get('period');
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const projectIdAll = urlParams.getAll('projectId');
  const consultantIdAll = urlParams.getAll('consultantId');
  const clientIdAll = urlParams.getAll('clientId');
  const periodAll = urlParams.getAll('period');

  const result = projectIdAll.map((id, index) => ({
    projectId: id,
    consultantId: consultantIdAll[index],
    clientId: clientIdAll[index],
    period: periodAll[index],
  }));

  const [activityReports, setActivityReports] = useState(result);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainInfo, setMainInfo] = useState<Array<IActivityReportResponse>>([]);
  const [payloadInfo, setPayloadInfo] = useState<IARPayload | null>(null);
  //TODO ts
  const [activityReportPayload, setActivityReportPayload] = useState<
    Array<IARPAyloadDTO>
  >([]);
  const [activitiesInfo, setActivitiesInfo] = useState<
    Array<IActivitiesResponse>
  >([]);
  const [expencesInfo, setExpencesInfo] = useState<Array<IExpenseResponse>>([]);
  const [commentsList, setCommentsList] = useState<IComments>([]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenAddNew, setIsOpenAddNew] = useState(false);

  //loaders
  const [isLoadingTimesheets, setIsLoadingTimesheets] = useState(false);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoadingEO, setIsLoadingEO] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isLoadingAR, setIsLoadingAR] = useState(false);

  useEffect(() => {
    //1 request preview
    getActivityReport();
  }, [selectedIndex]);

  useEffect(() => {
    //2 request payload
    getPayloadInfo();
  }, [payloadInfo]);

  useEffect(() => {
    //3 request other-activities
    getActivities();
  }, [payloadInfo]);

  useEffect(() => {
    //4 request other-expenses
    getEO();
  }, [mainInfo]);

  useEffect(() => {
    //5 request comment
    getComments();
  }, [mainInfo]);

  const getPayloadInfo = () => {
    if (!payloadInfo) {
      return;
    }
    setIsLoadingTimesheets(true);

    activityApi
      .getARInfoPayload(payloadInfo)
      .then((resp: any) => {
        setActivityReportPayload(resp);
      })
      .catch((e) => {
        openErrorNotification('Failed to get timesheets/expences table info');
      })
      .finally(() => setIsLoadingTimesheets(false));
  };

  const getActivities = () => {
    if (!payloadInfo) {
      return;
    }
    setIsLoadingActivities(true);

    activityApi
      .getARInfoActivities({
        consultantId: activityReports[selectedIndex].consultantId as string,
        period: mainInfo[selectedIndex].commonPeriod as string,
      })
      .then((resp: any) => {
        setActivitiesInfo(resp);
      })
      .catch((e) => {
        openErrorNotification('Failed to get other activities table info');
      })
      .finally(() => setIsLoadingActivities(false));
  };

  const getEO = () => {
    if (!mainInfo.length) {
      return;
    }
    setIsLoadingEO(true);

    activityApi
      .getARInfoExpences(
        mainInfo[selectedIndex].expensesOptimization.map((item) => item._id),
      )
      .then((resp: any) => {
        setExpencesInfo(resp);
      })
      .catch((e) => {
        openErrorNotification('Failed to get EO table info');
      })
      .finally(() => setIsLoadingEO(false));
  };

  const addActivityReport = (
    selectedConsultantId?: string | null,
    selectedProjectId?: string | null,
    selectedPeriod?: string | null,
  ) => {
    if (
      !selectedConsultantId ||
      !selectedProjectId ||
      !clientId ||
      !selectedPeriod
    ) {
      return;
    }
    setActivityReports([
      ...activityReports,
      {
        clientId,
        consultantId: selectedConsultantId,
        projectId: selectedProjectId,
        period: moment(selectedPeriod).format('YYYY-MM-DD'),
      },
    ]);
    setSelectedIndex(selectedIndex + 1);
  };

  const getActivityReport = () => {
    setIsLoadingAR(true);
    activityApi
      .getARInfoMain(activityReports)
      .then((resp: Array<IActivityReportResponse>) => {
        setIsOpenAddModal(false);
        if (resp) {
          setMainInfo(resp || []);
          const timesheets: Array<string> = [];
          const expenses: Array<string> = [];

          resp[selectedIndex].timesheets.forEach((item) => {
            timesheets.push(item._id);

            item.expenses.forEach((expense) => {
              expenses.push(expense._id);
            });
          });
          setPayloadInfo({ timesheets, expenses });
        }
      })
      .catch((e) => {
        if (e?.response?.data?.messages) {
          e?.response?.data?.messages.forEach((item: any) =>
            openErrorNotification(item.message),
          );
          return;
        }
        openErrorNotification(
          e?.response?.data?.message || 'No approval timesheets found.',
        );
      })
      .finally(() => setIsLoadingAR(false));
  };

  const getComments = () => {
    if (!mainInfo.length) {
      return;
    }
    setIsLoadingComments(true);
    consultantApi
      .getComments(mainInfo[selectedIndex].consultantId)
      .then((resp: any) => {
        setCommentsList(resp.result);
      })
      .catch((e) => {
        openErrorNotification('Failed to get comments');
      })
      .finally(() => setIsLoadingComments(false));
  };

  const closeModal = () => {
    setIsOpenAddModal(false);
  };

  const removeActivityReport = () => {
    const reports = activityReports.filter(
      (item, index) => index !== selectedIndex,
    );
    setSelectedIndex(0);
    setActivityReports(reports);
  };

  const closeAddNew = () => {
    setIsOpenAddNew(false);
    getActivityReport();
  };

  const tabs = (
    <Tabs
      defaultActiveKey="1"
      activeKey={selectedIndex.toString()}
      onTabClick={(key: string) => {
        if (key !== selectedIndex.toString()) setSelectedIndex(+key);
      }}
      items={
        mainInfo.length > 0
          ? mainInfo.map((report, index) => {
              return {
                label: (
                  <div className="tab-wrapper-lable">
                    {`${report.firstName || ''} ${report.lastName || ''}`}{' '}
                    {selectedIndex !== 0 && selectedIndex === index && (
                      <TrashIcon
                        style={{
                          marginLeft: '15px',
                          height: '15px',
                          width: '15px',
                        }}
                        onClick={() => removeActivityReport()}
                      />
                    )}
                  </div>
                ),
                key: index.toString(),
                children: (
                  <ActivityReportInfoTab
                    mainInfo={mainInfo}
                    selectedIndex={selectedIndex}
                    activityReportPayload={activityReportPayload}
                    activitiesInfo={activitiesInfo}
                    expencesInfo={expencesInfo}
                    consultantId={mainInfo[selectedIndex]?.consultantId}
                    commentsList={commentsList}
                    getComments={getComments}
                    isLoadingActivities={isLoadingActivities}
                    isLoadingComments={isLoadingComments}
                    isLoadingEO={isLoadingEO}
                    isLoadingTimesheets={isLoadingTimesheets}
                    setIsOpenAddNew={setIsOpenAddNew}
                    payloadInfo={payloadInfo}
                    activityReports={activityReports}
                    getPayloadInfo={getPayloadInfo}
                  />
                ),
              };
            })
          : undefined
      }
    />
  );

  return (
    <Spin spinning={isLoadingAR}>
      <div className="container-report">
        <div className="header-section-info">
          <span className="back-title" onClick={() => navigate(-1)}>
            <LeftOutlined />
            Back
          </span>
          <span
            className="title-text add-activity"
            onClick={() => setIsOpenAddModal(true)}>
            + Add activity report
          </span>
        </div>
        {mainInfo.length > 1 ? (
          <div>{tabs}</div>
        ) : (
          <ActivityReportInfoTab
            mainInfo={mainInfo}
            selectedIndex={selectedIndex}
            activityReportPayload={activityReportPayload}
            activitiesInfo={activitiesInfo}
            expencesInfo={expencesInfo}
            consultantId={mainInfo[selectedIndex]?.consultantId}
            commentsList={commentsList}
            getComments={getComments}
            isLoadingActivities={isLoadingActivities}
            isLoadingComments={isLoadingComments}
            isLoadingEO={isLoadingEO}
            isLoadingTimesheets={isLoadingTimesheets}
            setIsOpenAddNew={setIsOpenAddNew}
            payloadInfo={payloadInfo}
            activityReports={activityReports}
            getPayloadInfo={getPayloadInfo}
          />
        )}
        {isOpenAddModal && (
          <AddActivityReportModal
            clientId={
              mainInfo[selectedIndex]?.consultantOnboarding?.workContract
                ?.companies?.clientId
            }
            clientName={
              mainInfo[selectedIndex]?.consultantOnboarding?.workContract
                ?.companies?.name
            }
            isOpen={isOpenAddModal}
            onClose={closeModal}
            getActivityReport={addActivityReport}
            consultantId={mainInfo[selectedIndex]?.consultantId}
            mainInfo={mainInfo}
            commonPeriod={period}
            activityReports={activityReports}
          />
        )}
        {isOpenAddNew && (
          <AddNewModal
            isOpen={isOpenAddNew}
            closeAddNew={closeAddNew}
            mainInfo={mainInfo[selectedIndex]}
            period={activityReports[selectedIndex]?.period}
          />
        )}
      </div>
    </Spin>
  );
};

export default ActivityReportInfo;
