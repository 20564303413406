import { getTimesheetList } from '../../../redux/reducers/timesheets/asyncThunkActions';
import { clearTimesheetFiltersAndSorts } from '../../../redux/reducers/timesheets/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';
import ResetFilterBtn from '../../../components/resetFilterBtn/ResetFilterBtn';

const TimesheetsExtraSection = () => {
  const dispatch = useAppDispatch();
  const {
    sort: timesheetSorts,
    filter: timesheetFilters,
    selectedConsultant: timesheetSelectedConsultant,
  } = useAppSelector((state) => state.timesheets.timesheetList);

  const isResetTimesheetFilters =
    timesheetSorts.sortBy !== '' ||
    Object.values(timesheetFilters).some((el) => el?.length !== 0) ||
    timesheetSelectedConsultant?.length > 0;

  return (
    <>
      {isResetTimesheetFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearTimesheetFiltersAndSorts());
            dispatch(getTimesheetList({ currentPage: 1 }));
          }}>
          <ResetFilterBtn />
        </button>
      )}
    </>
  );
};

export default TimesheetsExtraSection;
