import { ComponentType, useEffect } from 'react';
import { useAppDispatch } from '../../redux/store';
import {
  addNewClientEntity,
  deleteClientEntity,
  getClientById,
} from '../../redux/reducers/clients/asyncThunkActions';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import {
  IEventForAddressAndInvoiceSection,
  IEventsForPhoneAndEmail,
} from '../../pages/modify-client-page/types';
import { TSelectedClientInvoiceNames } from '../../redux/reducers/clients/type';
import { ClientEntity } from '../api/services/ClientApi/enums/ClientEntity';
import { IDeleteClientEntityRequest } from '../api/services/ClientApi/types/IUpdateClientEntity';

const { clients } = ROUTER_PATHS;

const { ADDRESSES, EMAILS, PHONES } = ClientEntity;

interface IProps
  extends IEventsForPhoneAndEmail,
    IEventForAddressAndInvoiceSection {}
export const withUpdateClient = (WrappedComponent: ComponentType<IProps>) => {
  const Component = () => {
    const { clientId } = useParams<{ clientId: string }>();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (clientId) {
        dispatch(getClientById(clientId));
      } else {
        navigation(clients);
      }
    }, []);

    const onAddNewAddress = (): void => {
      dispatch(addNewClientEntity(ADDRESSES));
    };

    const onDeleteAddress = (id: number | string) => (): void => {
      const query: IDeleteClientEntityRequest = {
        entity: ADDRESSES,
        id: id as string,
      };
      dispatch(deleteClientEntity(query));
    };

    const onAddEmailEntity = (): void => {
      dispatch(addNewClientEntity(EMAILS));
    };

    const onAddPhoneEntity = (): void => {
      dispatch(addNewClientEntity(PHONES));
    };

    const onDeleteEmailEntity = (id: number | string) => (): void => {
      const query: IDeleteClientEntityRequest = {
        entity: EMAILS,
        id: id as string,
      };
      dispatch(deleteClientEntity(query));
    };

    const onDeletePhoneEntity = (id: number | string) => (): void => {
      const query: IDeleteClientEntityRequest = {
        entity: PHONES,
        id: id as string,
      };
      dispatch(deleteClientEntity(query));
    };

    const onAddNewInvoiceSection =
      (name: TSelectedClientInvoiceNames) => (): void => {
        dispatch(addNewClientEntity(name as ClientEntity));
      };

    const onDeleteInvoiceSection =
      (id: number | string, name: TSelectedClientInvoiceNames) => (): void => {
        const query: IDeleteClientEntityRequest = {
          entity: name as ClientEntity,
          id: id as string,
        };
        dispatch(deleteClientEntity(query));
      };

    return (
      <WrappedComponent
        {...{
          onAddEmailEntity,
          onAddPhoneEntity,
          onDeleteEmailEntity,
          onDeletePhoneEntity,
          onAddNewInvoiceSection,
          onDeleteInvoiceSection,
          onAddNewAddress,
          onDeleteAddress,
        }}
      />
    );
  };

  return <Component />;
};
