import { ComponentType, useEffect } from 'react';
import { useAppDispatch } from '../../redux/store';
import { AsyncThunk } from '@reduxjs/toolkit';
import { IOnboardingSaveOptions } from '../../redux/reducers/onboarding/types';
import { clearOnboardingErrorsValidation } from '../../redux/reducers/onboarding/sliceReducer';

export const withOnboardingWillUnmount = (
  WrappedComponent: ComponentType,
  // eslint-disable-next-line @typescript-eslint/ban-types
  event: AsyncThunk<void, IOnboardingSaveOptions, {}>,
) => {
  const Component = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      return () => {
        dispatch(clearOnboardingErrorsValidation());
        dispatch(event({}));
      };
      // eslint-disable-next-line
    }, []);

    return <WrappedComponent />;
  };

  return <Component />;
};
