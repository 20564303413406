import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import { getExpensesList } from '../../../redux/reducers/expenses/asyncThunkActions';
import { clearExpensesFiltersAndSorts } from '../../../redux/reducers/expenses/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';
import ResetFilterBtn from '../../../components/resetFilterBtn/ResetFilterBtn';

const ExpensesExtraSection = () => {
  const dispatch = useAppDispatch();
  const { sort: expensesSorts, filter: expensesFilters } = useAppSelector(
    (state) => state.expenses.expensesList,
  );
  const isResetExpensesFilters =
    expensesSorts.sortBy !== '' ||
    Object.values(expensesFilters).some((el) => el?.length !== 0);

  return (
    <>
      {isResetExpensesFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearExpensesFiltersAndSorts());
            dispatch(getExpensesList({ currentPage: 1 }));
          }}>
          <ResetFilterBtn />
        </button>
      )}
    </>
  );
};

export default ExpensesExtraSection;
