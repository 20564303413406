import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import {
  clearSelectedTimesheet,
  setErrorMessages,
  setIsLoading,
  setIsOpenModalHasExpenses,
  setTimesheetList,
  setTimesheetPagination,
  setTimesheetSorting,
  updateFilesSelectedTimesheet,
  updateIsUpLoadingEditTimesheet,
  updateOpenModalAddTimesheet,
  updateOpenModalEditTimesheet,
  updateSelectedTimesheet,
} from './sliceReducer';
import { batch } from 'react-redux';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../common/api/services/FileApi/enums/RequestEnums';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import { timesheetApi } from '../../../common/api/services/TimesheetApi/timesheetApi';
import { IConsultantTimesheet } from '../../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { INewTimesheet } from '../../../common/api/services/TimesheetApi/types/INewTimesheet';
import { ITimesheetResponse } from '../../../common/api/services/TimesheetApi/types/ITimesheetResponse';

export const getTimesheetList = createAsyncThunk(
  'timesheets/getTimesheetList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    dispatch(setIsLoading(true));
    const currentPagination = (getState() as IRootState).timesheets
      .timesheetList.pagination;
    const currentFilter = (getState() as IRootState).timesheets.timesheetList
      .filter;
    const currentSort = (getState() as IRootState).timesheets.timesheetList
      .sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await consultantApi.getConsultantTimesheet(
      page,
      perPage,
      currentFilter,
      currentSort,
    );
    dispatch(setIsLoading(false));
    if (response) {
      batch(() => {
        dispatch(setTimesheetList(response));
        dispatch(setTimesheetPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeTimesheetSorting = createAsyncThunk(
  'timesheets/changeTimesheetSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).timesheets.timesheetList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setTimesheetSorting([currentSortBy, 'asc']));
      dispatch(getTimesheetList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setTimesheetSorting([currentSortBy, 'desc']));
      dispatch(getTimesheetList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setTimesheetSorting(['', '']));
      dispatch(getTimesheetList({ currentPage: 1 }));
      return;
    }
  },
);

export const changeInternalTimesheetSorting = createAsyncThunk(
  'timesheets/changeInternalTimesheetSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).timesheets.timesheetList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setTimesheetSorting([currentSortBy, 'asc']));
      dispatch(getInternalTimesheet({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setTimesheetSorting([currentSortBy, 'desc']));
      dispatch(getInternalTimesheet({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setTimesheetSorting(['', '']));
      dispatch(getInternalTimesheet({ currentPage: 1 }));
      return;
    }
  },
);

export const saveAsDraft = createAsyncThunk(
  'timesheets/saveAsDraft',
  async (_, { dispatch, getState }) => {
    const { selectedTimesheet } = (getState() as IRootState).timesheets;

    const { _id: timesheetId } = selectedTimesheet;

    const { isSuccess, data } = await consultantApi.putTimesheet(timesheetId, {
      ...selectedTimesheet,
      status: TimesheetStatus.DRAFT,
    });

    if (isSuccess) {
      dispatch(updateOpenModalEditTimesheet(false));
      dispatch(getTimesheetList());
      dispatch(clearSelectedTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const submit = createAsyncThunk(
  'timesheets/submit',
  async (_, { dispatch, getState }) => {
    const { selectedTimesheet } = (getState() as IRootState).timesheets;

    const {
      _id: timesheetId,
      hasActivityPerMonth,
      approvalRequired,
      isSigned,
    } = selectedTimesheet;

    if (!approvalRequired && !isSigned && hasActivityPerMonth) return;

    const status = !hasActivityPerMonth
      ? TimesheetStatus.NO_ACTIVITY
      : isSigned
      ? TimesheetStatus.PRE_APPROVED
      : TimesheetStatus.WAITING_FOR_APPROVAL;

    const { isSuccess, data } = await consultantApi.putTimesheet(timesheetId, {
      ...selectedTimesheet,
      status,
    });

    if (isSuccess) {
      const response = await consultantApi.getConsultantExpensesByStatusDraft();
      const isOpenModal = !!response?.result.length;

      if (!isOpenModal) {
        dispatch(clearSelectedTimesheet());
      }

      dispatch(setIsOpenModalHasExpenses(isOpenModal));
      dispatch(updateOpenModalEditTimesheet(false));
      dispatch(getTimesheetList());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const uploadFile = createAsyncThunk(
  'timesheets/uploadFile',
  async (selectedFiles: FileList | File[], { dispatch }) => {
    if (selectedFiles.length === 0) return;

    dispatch(updateIsUpLoadingEditTimesheet(true));

    const files: File[] = [];
    for (let i = 0; i < selectedFiles.length; i += 1) {
      const file = selectedFiles[i];
      if (file) files.push(file);
    }

    const response = await fileApi.uploadFile(
      files,
      UploadEntityId.timesheetFile,
      UploadEntity.consultantTimesheet,
    );

    if (response) {
      dispatch(updateFilesSelectedTimesheet(response));
    }

    dispatch(updateIsUpLoadingEditTimesheet(false));
  },
);

export const getInternalTimesheet = createAsyncThunk(
  'timesheets/getInternalTimesheet',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const currentPagination = (getState() as IRootState).timesheets
      .timesheetList.pagination;
    const currentFilter = (getState() as IRootState).timesheets.timesheetList
      .filter;
    const currentSort = (getState() as IRootState).timesheets.timesheetList
      .sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await timesheetApi.fetchTimesheets(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setTimesheetList(response));
        dispatch(setTimesheetPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const saveAsDraftInternalTimesheet = createAsyncThunk(
  'timesheets/saveAsDraftInternalTimesheet',
  async (_, { dispatch, getState }) => {
    const { selectedTimesheet } = (getState() as IRootState).timesheets;

    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      timesheetId,
      consultantId,
      {
        ...selectedTimesheet,
        status: TimesheetStatus.DRAFT,
      },
    );

    if (isSuccess) {
      dispatch(updateOpenModalAddTimesheet(false));
      dispatch(updateOpenModalEditTimesheet(false));
      dispatch(getInternalTimesheet());
      dispatch(clearSelectedTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const submitInternalTimesheet = createAsyncThunk(
  'timesheets/submitInternalTimesheet',
  async (_, { dispatch, getState }) => {
    const { selectedTimesheet } = (getState() as IRootState).timesheets;

    const {
      _id: timesheetId,
      consultantId,
      hasActivityPerMonth,
      approvalRequired,
      isSigned,
    } = selectedTimesheet;

    if (!approvalRequired && !isSigned && hasActivityPerMonth) return;

    const status = !hasActivityPerMonth
      ? TimesheetStatus.NO_ACTIVITY
      : isSigned
      ? TimesheetStatus.PRE_APPROVED
      : TimesheetStatus.WAITING_FOR_APPROVAL;

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      timesheetId,
      consultantId,
      {
        ...selectedTimesheet,
        status,
      },
    );

    if (isSuccess) {
      dispatch(updateOpenModalAddTimesheet(false));
      dispatch(updateOpenModalEditTimesheet(false));
      dispatch(clearSelectedTimesheet());
      dispatch(getInternalTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const publishInternalTimesheet = createAsyncThunk(
  'timesheets/publishInternalTimesheet',
  async (payload: INewTimesheet, { dispatch }) => {
    const { isSuccess, data } = await timesheetApi.newTimesheet(payload);

    if (isSuccess) {
      dispatch(getInternalTimesheet());
      dispatch(updateSelectedTimesheet(data));
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const approveInternalTimesheet = createAsyncThunk(
  'timesheets/approveInternalTimesheet',
  async (selectedTimesheet: Partial<IConsultantTimesheet>, { dispatch }) => {
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const body = {
      ...selectedTimesheet,
      status: TimesheetStatus.APPROVED,
    };

    if (selectedTimesheet.status === TimesheetStatus.WAITING_FOR_APPROVAL) {
      body.status = TimesheetStatus.CLIENT_APPROVAL;
    }

    if (selectedTimesheet?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      timesheetId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      consultantId!,
      body,
    );

    if (isSuccess) {
      dispatch(getInternalTimesheet());
      dispatch(clearSelectedTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

//TODO temporary solution
export const approveAllSelectedInternalTimesheet = createAsyncThunk(
  'timesheets/approveAllSelectedInternalTimesheet',
  async (
    selectedTimeSheetsForApprove: (IConsultantTimesheet | ITimesheetResponse)[],
    { dispatch, getState },
  ) => {
    const { selectedConsultant } = (getState() as IRootState).timesheets
      .timesheetList;

    selectedConsultant.forEach((selectedTimesheetId) => {
      const currentTimesheet = selectedTimeSheetsForApprove.find(
        (el: { _id: string }) => el._id === selectedTimesheetId,
      );
      if (currentTimesheet) {
        dispatch(approveInternalTimesheet(currentTimesheet));
      }
    });
  },
);

export const rejectInternalTimesheet = createAsyncThunk(
  'timesheets/rejectInternalTimesheet',
  async (selectedTimesheet: Partial<IConsultantTimesheet>, { dispatch }) => {
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const body = {
      ...selectedTimesheet,
      status: TimesheetStatus.REJECTED,
    };

    if (selectedTimesheet?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      timesheetId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      consultantId!,
      body,
    );

    if (isSuccess) {
      dispatch(getInternalTimesheet());
      dispatch(clearSelectedTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const expireInternalTimesheet = createAsyncThunk(
  'timesheets/expireInternalTimesheet',
  async (selectedTimesheet: IConsultantTimesheet, { dispatch }) => {
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      timesheetId,
      consultantId,
      {
        status: TimesheetStatus.EXPIRED,
      },
    );

    if (isSuccess) {
      dispatch(getInternalTimesheet());
      dispatch(clearSelectedTimesheet());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);
