import { FC, Key, useEffect, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { TActionOption } from '../waiting-room-page/types';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionsMenu from './components/ActionsMenu';
import { Modal, TableProps } from 'antd';
import moment from '../../common/constants/moment';

import './ConsultantsPage.less';
import ModalArchiveContent from './components/ModalArchiveContent';
import {
  changeConsultantListSorting,
  getConsultantsList,
  updateApproveAdminConsultant,
  updateApproveFinanceConsultant,
} from '../../redux/reducers/consultants/asyncThunkActions';
import {
  changeConsultantsFiltering,
  setIsShowConsultantModal,
  updateModalFinanceByConsultantId,
  updateModalAdminByConsultantId,
  selectConsultantListItem,
} from '../../redux/reducers/consultants/sliceReducer';
import ModalApproveConsultant from './components/ModalApproveConsultant';
import { OnboardingRequestStatuses } from '../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import ModalCreateOnboardingConsultant from './components/ModalCreateOnboardingConsultant';
import ModalByFinanceAndAdmin from './components/ModalByFinanceAndAdmin';
import { clearOnboardingState } from '../../redux/reducers/onboarding/sliceReducer';
import { useTableApprovedByFilter } from '../../common/hooks/useTableApprovedByFilter';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { useNavigate } from 'react-router-dom';
import TableTooltip from '../../components/common-table/table-tooltip';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import { consultantApi } from '../../common/api/services/ConsultantApi/consultantApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import FormatPhoneNumber from '../../components/format-phone-number/FormatPhoneNumber';

const { onboarding } = ROUTER_PATHS;

const { draft, published, toConfirm, empty, toVerify, archived } =
  OnboardingRequestStatuses;

type TStatusParserKey =
  | OnboardingRequestStatuses.draft
  | OnboardingRequestStatuses.published
  | OnboardingRequestStatuses.toConfirm
  | OnboardingRequestStatuses.empty
  | OnboardingRequestStatuses.toVerify
  | OnboardingRequestStatuses.archived;

const statusParser: Record<TStatusParserKey, string> = {
  [draft]: 'DRAFT',
  [empty]: 'DRAFT',
  [published]: 'PUBLISHED',
  [toConfirm]: 'TO CONFIRM',
  [toVerify]: 'TO VERIFY',
  [archived]: 'ARCHIVED',
};

const filterNames = [draft, published, toConfirm, toVerify, archived];

interface ITableElement {
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  consultantOnboardingStatus: OnboardingRequestStatuses;
  approvedByFinance: boolean;
  approvedByAdmin: boolean;
}

const ConsultantsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [isOpenModal, setOpenModal] = useState(false);
  const {
    isShowCreateConsultantModal,
    isOpenModalFinance,
    isOpenModalAdmin,
    selectedConsultant,
  } = useAppSelector((state) => state.consultants);
  const [modalType, setModalType] = useState<TActionOption>('archive');

  useEffect(() => {
    dispatch(getConsultantsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { content, pagination, filter, sort, selectedConsultants } =
    useAppSelector((state) => state.consultants.consultantsList);
  const { perPage, currentPage, totalElement } = pagination;

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const changeFilter = (
    data: Record<string, string[] | string | boolean>,
  ): void => {
    dispatch(changeConsultantsFiltering(data));
  };

  const showModal = (action: TActionOption) => (): void => {
    setModalType(action);
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const searchFilterArg = {
    fetchRequest: () => dispatch(getConsultantsList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getConsultantsList({ currentPage, perPage }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      const sortName = sortBy === 'saleName' ? 'manager.firstName' : sortBy;
      dispatch(changeConsultantListSorting(sortName as string));
    }
  };

  const onCloseCreateConsultant = (): void => {
    dispatch(setIsShowConsultantModal(false));
    dispatch(clearOnboardingState());
  };

  const changeFinanceBy = (): void => {
    if (selectedConsultant) {
      dispatch(updateApproveFinanceConsultant(selectedConsultant._id));
    }
  };

  const changeAdminBy = (): void => {
    if (selectedConsultant) {
      dispatch(updateApproveAdminConsultant(selectedConsultant._id));
    }
  };

  const hideModalAdminBy = () => {
    dispatch(updateModalAdminByConsultantId(null));
    dispatch(getConsultantsList());
  };

  const hideModalFinanceBy = () => {
    dispatch(updateModalFinanceByConsultantId(null));
    dispatch(getConsultantsList());
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectConsultantListItem(newSelectedRowKeys as string[]));
  };

  const onInvite = (id: string) => {
    consultantApi
      .inviteConsultant(id)
      .then(() =>
        openSuccessNotification('The invitation was sent successfully'),
      )
      .catch(() => {
        openErrorNotification('Error with sent invitation');
      });
  };

  const rowSelection = {
    selectedRowKeys: selectedConsultants,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const columns: ColumnsType<ITableElement> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
        searchAsString: false,
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Payroll/Corp',
      dataIndex: 'workForm',
      key: 'workForm',
      fixed: 'left',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.workForm as string[]) ?? [],
        fieldFilterName: 'workForm',
      })(['payroll', 'corporation']),
      className: sortClassName('workForm'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.firstName as string) ?? '',
      })('firstName'),
      sorter: true,
      sortDirections: ['descend'],
      className: sortClassName('firstName'),
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.lastName as string) ?? '',
      })('lastName'),
      sorter: true,
      className: sortClassName('lastName'),
      sortDirections: ['descend'],
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.email as string) ?? '',
      })('email'),
      width: 300,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={300}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phone',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.phone as string) ?? '',
      })('phone'),
      sorter: true,
      className: sortClassName('phone'),
      sortDirections: ['descend'],
      width: 200,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          <FormatPhoneNumber number={text} />
        </TableTooltip>
      ),
    },
    {
      title: 'Onboarding status',
      dataIndex: 'consultantOnboardingStatus',
      key: 'consultantOnboardingStatus',
      className: `status-td ${sortClassName('consultantOnboardingStatus')}`,
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.consultantOnboardingStatus as string[]) ?? [],
        fieldFilterName: 'consultantOnboardingStatus',
      })(filterNames),
      render: (text: TStatusParserKey) => {
        const className = empty === text ? draft : text;
        return (
          <span
            className={
              className === 'archived' ? 'archivedConsultant' : className
            }>
            {statusParser[text]}
          </span>
        );
      },
      width: 200,
    },
    {
      title: 'Approved by',
      dataIndex: 'approvedBy',
      key: 'approvedBy',
      width: 200,
      ...useTableApprovedByFilter({
        fetchRequest: () => dispatch(getConsultantsList({ currentPage: 1 })),
        changeValues: changeFilter as (date: Record<string, boolean>) => void,
        defaultValues: {
          'consultantDetails.approvedByAdmin':
            !!filter['consultantDetails.approvedByAdmin'],
          'consultantDetails.approvedByFinance':
            !!filter['consultantDetails.approvedByFinance'],
        },
      })(),
      render: (_, { approvedByFinance, approvedByAdmin }) => {
        return renderApprovedByColumn(approvedByFinance, approvedByAdmin);
      },
    },
    {
      title: 'Received WC',
      dataIndex: 'receivedWC',
      key: 'receivedWC',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.receivedWC as string[]) ?? [],
        fieldFilterName: 'receivedWC',
      })(['Yes', 'No']),
      sorter: true,
      className: sortClassName('receivedWC'),
      sortDirections: ['descend'],
      width: 200,
      render: (received: string, record: any) => {
        return <span>{record?.receivedWC ? 'Yes' : 'No'}</span>;
      },
    },
    {
      title: 'Received CC',
      dataIndex: 'receivedCC',
      key: 'receivedCC',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.receivedCC as string[]) ?? [],
        fieldFilterName: 'receivedCC',
      })(['Yes', 'No']),
      sorter: true,
      className: sortClassName('receivedCC'),
      sortDirections: ['descend'],
      width: 200,
      render: (received: string, record: any) => {
        return <span>{record?.receivedCC ? 'Yes' : 'No'}</span>;
      },
    },
    {
      title: 'Invitation date',
      dataIndex: 'invitationDate',
      key: 'invitationDate',
      width: 200,
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['invitationDate']
          ? (filter['invitationDate'] as string)
          : '',
        field: 'invitationDate',
      })(),
      sorter: true,
      className: sortClassName('invitationDate'),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text: string) => {
        const invitationDate = text
          ? moment(text).format(commonMonthTimeFormat)
          : '';
        return (
          <TableTooltip tootltipTitle={invitationDate} columnWidth={200}>
            {invitationDate}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 80,
      fixed: 'right',
      render: (
        text,
        { key, approvedByFinance, approvedByAdmin, consultantOnboardingStatus },
      ) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionsMenu
                  status={consultantOnboardingStatus}
                  consultantId={key}
                  showArchiveModal={showModal('archive')}
                  onApproveFinance={(id: string) =>
                    dispatch(updateModalFinanceByConsultantId(id))
                  }
                  onApproveAdmin={(id: string) =>
                    dispatch(updateModalAdminByConsultantId(id))
                  }
                  onInvite={onInvite}
                  {...{ approvedByAdmin, approvedByFinance }}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
    },
  ];

  const data = content.map((consultant) => {
    const {
      _id,
      publicId,
      firstName,
      lastName,
      email,
      phone,
      consultantOnboardingStatus,
      consultantOnboarding,
      consultantDetails,
    } = consultant;
    const companies = consultantOnboarding?.workContract?.companies;
    const clients = consultantOnboarding?.clientContract?.clients;
    return {
      key: _id,
      publicId,
      firstName,
      lastName,
      email,
      phone,
      consultantOnboardingStatus,
      approvedByFinance: consultantDetails?.approvedByFinance,
      approvedByAdmin: consultantDetails?.approvedByAdmin,
      receivedWC:
        companies && companies.length > 0
          ? companies[0].receivedWorkContract
          : false,
      receivedCC:
        clients && clients.length > 0
          ? clients[0].receivedClientContract
          : false,
      invitationDate: consultantDetails?.invitationDate,
      workForm: consultantOnboarding?.personalInformation?.workForm || '',
    };
  });

  const onDoubleClick = (record: any) => {
    navigation(onboarding + `/${record.key}`);
  };

  const isModalArchive = 'archive' === modalType;
  const isApprovedByAdmin =
    !!selectedConsultant?.consultantDetails?.approvedByAdmin;
  const isApprovedByFinance =
    !!selectedConsultant?.consultantDetails?.approvedByFinance;
  const firstName = selectedConsultant?.firstName ?? '';
  const lastName = selectedConsultant?.lastName ?? '';
  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChange}
        rowSelection={rowSelection}
        onDoubleClick={onDoubleClick}
      />
      {isOpenModal && (
        <Modal
          width={600}
          title={isModalArchive ? 'Archive consultant' : 'Invitation link'}
          open={isOpenModal}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper">
          {!isModalArchive && (
            <></> // <ModalInviteContent leadDetails={selectedLead.leadDetails!} />
          )}

          {isModalArchive && <ModalArchiveContent hideModal={handleCancel} />}
        </Modal>
      )}

      {/* <Modal
        width={600}
        title="Approve consultant"
        open={false}
        onCancel={() => null}
        centered
        footer={null}
        destroyOnClose={true}
        wrapClassName="modal-window-wrapper">
        <ModalApproveConsultant hideModal={() => null} />
      </Modal> */}

      {isOpenModalAdmin && (
        <ModalByFinanceAndAdmin
          hideModal={hideModalAdminBy}
          fullName={`${firstName} ${lastName}`}
          submit={changeAdminBy}
          isApproved={isApprovedByAdmin}
          isOpen={isOpenModalAdmin}
        />
      )}

      {isOpenModalFinance && (
        <ModalByFinanceAndAdmin
          hideModal={hideModalFinanceBy}
          fullName={`${firstName} ${lastName}`}
          submit={changeFinanceBy}
          isApproved={isApprovedByFinance}
          isOpen={isOpenModalFinance}
        />
      )}

      {isShowCreateConsultantModal && (
        <Modal
          width={600}
          title="Add new consultant"
          open={isShowCreateConsultantModal}
          onCancel={onCloseCreateConsultant}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper">
          <ModalCreateOnboardingConsultant
            hideModal={onCloseCreateConsultant}
          />
        </Modal>
      )}
    </>
  );
};

export default ConsultantsPage;

const renderApprovedByColumn = (
  isFinance?: boolean,
  isAdmin?: boolean,
): string => {
  const finance = isFinance ? 'Finance' : '';
  const admin = isAdmin ? 'Admin' : '';
  const separator = isFinance && isAdmin ? '/' : '';
  const result = finance + separator + admin;
  return !!result ? result : 'No';
};
