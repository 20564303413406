import Button from '../../../components/button/Button';
import {
  clearTimesheetFiltersAndSorts,
  updateOpenModalAddTimesheet,
} from '../../../redux/reducers/timesheets/sliceReducer';
import {
  GenerateTimesheets,
  GenerateTimesheetsText,
} from '../../../components/common-texts/CommonTexts';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import CrossIcon from '../../../assets/icons/CrossIcon';
import { setErrorMessages } from '../../../redux/reducers/timesheets/sliceReducer';
import {
  approveAllSelectedInternalTimesheet,
  getInternalTimesheet,
} from '../../../redux/reducers/timesheets/asyncThunkActions';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useEffect, useState } from 'react';
import moment from '../../../common/constants/moment';
import { timesheetApi } from '../../../common/api/services/TimesheetApi/timesheetApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { Col, Modal, Row } from 'antd';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import '../../../components/extra-section/ExtraSection.less';

const InternalTimesheetsExtraSection = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGenerateTimesheetsModal, setIsOpenGenerateTimesheetsModal] =
    useState(false);
  const [generateDate, setGenerateDate] = useState<{
    month: number;
    year: number;
  } | null>(null);
  const [timesheetsForApprove, setTimesheetsForApprove] = useState<any>([]);
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const {
    sort: timesheetSorts,
    filter: timesheetFilters,
    selectedConsultant: timesheetSelectedConsultant,
  } = useAppSelector((state) => state.timesheets.timesheetList);

  useEffect(() => {
    if (timesheetSelectedConsultant.length === 0 || !isOpen) return;
    setIsFetch(true);

    timesheetApi
      .fetchTimesheetBySelectedIds(timesheetSelectedConsultant)
      .then((res) => {
        setTimesheetsForApprove(res?.result || []);
      })
      .catch(() => setTimesheetsForApprove([]))
      .finally(() => setIsFetch(false));
  }, [timesheetSelectedConsultant, isOpen]);

  const amountSelectedTimesheets = timesheetSelectedConsultant.length;
  const isResetTimesheetFilters =
    timesheetSorts.sortBy !== '' ||
    Object.values(timesheetFilters).some((el) => el.length !== 0) ||
    timesheetSelectedConsultant.length > 0;

  const hasSelectedInternalTimesheetConsultants =
    Object.values(timesheetSelectedConsultant).length > 0;

  const listTimesheetsWithNumeriqComments = timesheetsForApprove.filter(
    (timesheet: { _id: string; numeriqComments?: string }) =>
      timesheetSelectedConsultant.includes(timesheet._id) &&
      timesheet.numeriqComments,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any[];

  const generateTimesheets = () => {
    const year = generateDate?.year || moment().year();
    const month = generateDate?.month || moment().month() + 1;
    timesheetApi
      .generateTimesheets(year, month)
      .then(() => {
        setIsOpenGenerateTimesheetsModal(false);
        openSuccessNotification('Timesheets were generated successfully');
        dispatch(getInternalTimesheet());
      })
      .catch(() => {
        openErrorNotification('Failed to generate timesheets');
      });
  };

  const onHide = (): void => setIsOpen(false);
  const onTimesheetApproveSelected = () => {
    const isCondition = timesheetSelectedConsultant.every((timesheetId) => {
      const currentTimesheet = timesheetsForApprove.find(
        (timesheet: { _id: string }) => timesheet._id === timesheetId,
      );
      if (currentTimesheet) {
        return (
          currentTimesheet.status === TimesheetStatus.CLIENT_APPROVAL ||
          currentTimesheet.status === TimesheetStatus.PRE_APPROVED
        );
      }
      return false;
    });

    if (isCondition) {
      dispatch(approveAllSelectedInternalTimesheet(timesheetsForApprove));
    } else {
      openErrorNotification(
        'You can approve only Timesheets with the statuses “Client approval” and “Pre-approved”',
      );
    }
    onHide();
  };
  return (
    <>
      {hasSelectedInternalTimesheetConsultants && (
        <Button
          onClick={() => setIsOpen(true)}
          buttonStyle="secondary"
          buttonSize="medium"
          buttonWidth={160}>
          Approve selected
        </Button>
      )}
      {isResetTimesheetFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearTimesheetFiltersAndSorts());
            dispatch(getInternalTimesheet({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}
      <Button
        onClick={() => {
          setIsOpenGenerateTimesheetsModal(true);
        }}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={180}>
        Generate timesheets
      </Button>
      <Button
        onClick={() => {
          dispatch(updateOpenModalAddTimesheet(true));
          dispatch(setErrorMessages(null));
        }}
        buttonStyle="primary"
        buttonSize="medium"
        buttonWidth={160}
        leftIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
        Add new
      </Button>
      {isOpenGenerateTimesheetsModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={() => setIsOpenGenerateTimesheetsModal(false)}
          confirmAction={() => generateTimesheets()}
          confirmBtnTitle="Yes, generate"
          content={<GenerateTimesheets onChangeDate={setGenerateDate} />}
          isOpen={isOpenGenerateTimesheetsModal}
          isHideAttentionText
          width={580}
          btnWidth={'250px'}
        />
      )}
      {isOpen && (
        <Modal
          width={480}
          open={isOpen}
          onCancel={onHide}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p className="archive-text">
            Are you sure you want to approve {amountSelectedTimesheets} selected
            timesheet{amountSelectedTimesheets > 1 && 's'}?
          </p>
          {listTimesheetsWithNumeriqComments.length > 0 && (
            <div
              style={{
                color: '#E48900',
                marginTop: -8,
                marginBottom: 32,
                fontSize: 16,
              }}>
              Next Consultants left comments for Numeriq:
              {listTimesheetsWithNumeriqComments.map((item, idx) => {
                return (
                  <p key={item._id} style={{ margin: 0, color: '#E48900' }}>
                    {idx + 1}. {item.consultant.firstName}{' '}
                    {item.consultant.lastName} -{' '}
                    {getFormatDateWithMonth(item.period)},{' '}
                    {item.workContract.name}
                  </p>
                );
              })}
            </div>
          )}
          <Row gutter={[16, 0]} className="archive-buttons">
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={onHide}>
                Return
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                disable={isFetch}
                onClick={onTimesheetApproveSelected}>
                Yes, approve
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default InternalTimesheetsExtraSection;
