import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
// import { ISort } from '../../../../redux/types';
// import { getQueryParams } from '../../utils/getQueryParams';
// import { IConsultantTimesheet } from '../ConsultantApi/types/IConsultantTimesheet';
// import { IResponse } from '../../types/IResponse';
// import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { FXRateType, IBankAccount, IFXRates, IVATs } from './types/ISystemInfo';

class SystemInfoApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/system-info`;

  getActualFXRates(period: string) {
    return this.get<IGetListDto<IFXRates>>(`/fxrates/actual?period=${period}`)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  getActualVAT(period: string) {
    return this.get<IGetListDto<IVATs>>(`/vatrates/actual?period=${period}`)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  getBankAccounts(currency?: string) {
    return this.get<IGetListDto<IBankAccount>>(
      `/bank-account${currency ? `?currency=${currency}` : ''}`,
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  addBankAccount(body: IBankAccount) {
    return this.post(`/bank-account`, body);
  }
  updateBankAccount(body: IBankAccount) {
    return this.put(`/bank-account/${body._id}`, body);
  }
  deleteBankAccount(id: string) {
    return this.delete(`/bank-account/${id}`);
  }
  getFXRatesHistory(currency: FXRateType) {
    return this.get<IGetListDto<IVATs>>(`/fxrates?currencyPair=${currency}`)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  getVATHistory() {
    return this.get<IGetListDto<IVATs>>(`/vatrates`)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  createRXRate(body: { currencyPair: string; rate: number; period: string }) {
    return this.post('/fxrates', body);
  }
  createVAT(body: { name: string; percentage: number; period: string }) {
    return this.post('/vatrates', body);
  }
  updateRXRate(id: string, value: number) {
    return this.put(`/fxrates/${id}`, { rate: value });
  }
  updateVAT(id: string, value: number) {
    return this.put(`/vatrates/${id}`, { percentage: value });
  }
  deleteRXRate(id: string) {
    return this.delete(`/fxrates/${id}`);
  }
  deleteVAT(id: string) {
    return this.delete(`/vatrates/${id}`);
  }
}

export const systemInfoApi = new SystemInfoApi();
