import { FC } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { clearAuthState } from '../../../../redux/reducers/auth/sliceReducer';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../navigation/routerPaths';
import logoutIcon from '../../../../assets/images/logout-icon.svg';
import './LogoutBtn.less';

const { login } = ROUTER_PATHS;

const LogoutBtn: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(clearAuthState());
    navigate(login);
  };

  return (
    <>
      <div className="mobile-logout" onClick={logoutHandler}>
        <span style={{ marginLeft: '30px' }}>Logout</span>
        <img src={logoutIcon} alt="" className="logout-btn" />
      </div>
      <img
        src={logoutIcon}
        alt=""
        onClick={logoutHandler}
        className="logout-btn"
      />
    </>
  );
};

export default LogoutBtn;
