import { useState } from 'react';
import {
  IARPAyloadDTO,
  IARPayload,
  IActivitiesResponse,
  IActivityReportResponse,
  IExpenseResponse,
} from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';
import { IComments } from '../../../common/api/services/ConsultantApi/types/IComments';
import Button from '../../../components/button/Button';
import CommentsSection from './CommentsSection';
import ConsultantInfo from './ConsultantInfo';
import MainTable from './MainTable';
import ActivitiesTable from './tables/ActivitiesTable';
import EOTable from './tables/EOTable';

interface IProps {
  mainInfo: Array<IActivityReportResponse>;
  activityReportPayload: Array<IARPAyloadDTO>;
  activitiesInfo: Array<IActivitiesResponse>;
  expencesInfo: Array<IExpenseResponse>;
  consultantId: string | null;
  commentsList: IComments;
  getComments: () => void;
  selectedIndex: number;
  isLoadingTimesheets: boolean;
  isLoadingActivities: boolean;
  isLoadingEO: boolean;
  isLoadingComments: boolean;
  setIsOpenAddNew: (isOpen: boolean) => void;
  payloadInfo: IARPayload | null;
  activityReports: Array<{
    consultantId: string | null;
    projectId: string | null;
    clientId: string | null;
    period: string | null;
  }>;
  getPayloadInfo: () => void;
}

const ActivityReportInfoTab = (props: IProps) => {
  const {
    mainInfo,
    activityReportPayload,
    activitiesInfo,
    expencesInfo,
    consultantId,
    commentsList,
    getComments,
    selectedIndex,
    isLoadingTimesheets,
    isLoadingActivities,
    isLoadingEO,
    isLoadingComments,
    setIsOpenAddNew,
    payloadInfo,
    activityReports,
    getPayloadInfo,
  } = props;
  const [activeTab, setActiveTab] = useState('1');
  return (
    <div>
      <ConsultantInfo
        consultantInfo={mainInfo}
        selectedIndex={selectedIndex}
        activityReports={activityReports}
        payloadInfo={activityReportPayload}
        getPayloadInfo={getPayloadInfo}
      />
      <MainTable
        activityReportPayload={activityReportPayload}
        isLoadingTimesheets={isLoadingTimesheets}
        setIsOpenAddNew={setIsOpenAddNew}
        payloadInfo={payloadInfo}
        setActiveTab={setActiveTab}
      />
      {activeTab === '1' && (
        <div className="secondary-tables">
          <ActivitiesTable
            activitiesInfo={activitiesInfo}
            isLoadingActivities={isLoadingActivities}
          />
          <EOTable expencesInfo={expencesInfo} isLoadingEO={isLoadingEO} />
        </div>
      )}
      {activeTab === '1' && (
        <CommentsSection
          consultantId={consultantId}
          commentsList={commentsList}
          getComments={getComments}
          isLoadingComments={isLoadingComments}
        />
      )}
    </div>
  );
};

export default ActivityReportInfoTab;
