import { FC, useEffect, useState } from 'react';
import { Col, Row, Switch } from 'antd';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';

import './ModalTimesheet.less';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantsResponse } from '../../../common/api/services/ConsultantApi/types/IConsultantsResponse';
import useDebounce from '../../../common/hooks/useDebounce';
import ILabelInValue from '../../../common/types/ILabelInValue';
import moment from 'moment';
import { INewTimesheet } from '../../../common/api/services/TimesheetApi/types/INewTimesheet';
import { isMonthDisabled } from '../../../common/utils/isMonthDisabled';
import { SalaryType } from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import { commonYearDateFormat } from '../../../common/constants/dateConstants';

const initDropdownValue = { label: '', value: '' };
const initDate = { startDate: undefined, endDate: undefined };

interface IProps {
  getError: (fieldName: string) => string;
  handleCancel: () => void;
  onPublish: (params: INewTimesheet) => () => void;
}

const ModalAddTimesheet: FC<IProps> = (props) => {
  const { getError, handleCancel, onPublish } = props;
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [consultants, setConsultants] =
    useState<IGetListDto<IConsultantsResponse> | null>(null);
  const [optionConsultants, setOptionConsultants] = useState<ILabelInValue[]>(
    [],
  );
  const [selectedConsultant, setSelectedConsultant] =
    useState<ILabelInValue>(initDropdownValue);
  const [searchConsultant, setSearchConsultant] = useState<string>('');
  const searchConsultantDeb = useDebounce(searchConsultant, 500);

  const [clients, setClients] = useState<ILabelInValue[]>([]);

  const [selectedClient, setSelectedClient] =
    useState<ILabelInValue>(initDropdownValue);

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [visibility, setVisibility] = useState(true);
  const [isDisabledVisibility, setIsDisabledVisibility] =
    useState<boolean>(false);
  const [dateContract, setDateContract] =
    useState<Record<string, string | undefined>>(initDate);

  const [isShowPeriodWarning, setIsShowPeriodWarning] =
    useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const response = await consultantApi.getConsultants(
        1,
        0,
        {
          fullName: searchConsultantDeb,
          consultantOnboardingStatus: 'published,toConfirm',
        },
        { sort: 'asc', sortBy: 'firstName' },
      );
      const data = response?.result.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item._id,
      }));
      if (data) {
        setConsultants(response);
        setOptionConsultants(data);
      }
      setIsFetching(false);
    };

    getData();
  }, [searchConsultantDeb]);

  const onSearchConsultant = (value: string) => {
    setSearchConsultant(value);
  };

  const onChangeConsultant = (item: ILabelInValue) => {
    setSelectedClient(initDropdownValue);
    setDateContract(initDate);
    setSelectedDate('');
    setIsShowPeriodWarning(false);
    if (!item) {
      setSelectedConsultant(initDropdownValue);
      setClients([]);
      setDateContract(initDate);
      return;
    }
    setSelectedConsultant({ value: item.value, label: item.label });

    const selectedConsultant = consultants?.result.find(
      (consultant) => consultant._id === item.value,
    );
    const consultantCompanies =
      selectedConsultant?.consultantOnboarding?.workContract?.companies;
    const isSalaryPerMonth =
      consultantCompanies && consultantCompanies.length > 0
        ? consultantCompanies[0].type === SalaryType.PerMonth
        : false;
    setIsDisabledVisibility(isSalaryPerMonth);
    setVisibility(!isSalaryPerMonth);
    const data =
      selectedConsultant?.consultantOnboarding.workContract?.companies.map(
        (company) => ({
          label: `${company.name} ${
            company.projectDescription ? `(${company.projectDescription})` : ''
          }`,
          value: company._id,
        }),
      ) || [];
    setClients(data);
  };

  const onChangeClient = (item: ILabelInValue) => {
    setSelectedClient({ value: item.value, label: item.label });
    const client = consultants?.result
      .find((consultant) => consultant._id === selectedConsultant.value)
      ?.consultantOnboarding.clientContract?.clients.find(
        (client) => client.companyId === item.value,
      );
    const { startDate, endDate } = client || {};
    setDateContract({ startDate, endDate });
    setSelectedDate('');
  };

  const onClear = () => {
    setSearchConsultant('');
  };

  const isExistTimesheetByPeriod = (period: string): void => {
    const checkPeriod = async () => {
      const response = await consultantApi.getConsultantTimesheetByConsultantId(
        selectedConsultant.value,
        1,
        1,
        {
          period,
          clientName: selectedClient.label,
        },
        { sort: '', sortBy: '' },
      );
      const data = response?.total;
      setIsShowPeriodWarning(!!data);
    };
    checkPeriod();
  };

  const onChangeDate = (value: Date | null) => {
    setSelectedDate(value ? moment(value)?.format(commonYearDateFormat) : '');
    if (value) {
      isExistTimesheetByPeriod(moment(value).format(commonYearDateFormat));
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 24 }}>
        <Col span={24}>
          <Dropdown
            allowClear={!!selectedConsultant.value}
            labelInValue={true}
            isSearch={false}
            options={optionConsultants}
            value={selectedConsultant}
            onChange={onChangeConsultant}
            onSearch={onSearchConsultant}
            onClear={onClear}
            label="Consultant"
            isFetching={isFetching}
            errorMessage={getError(`consultantId`)}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Dropdown
            options={clients}
            labelInValue={true}
            value={selectedClient}
            disabled={clients.length === 0}
            onChange={onChangeClient}
            label="Client"
            showSearch={false}
            errorMessage={getError(`companyId`)}
          />
        </Col>
      </Row>

      <Row style={{ height: '75px' }}>
        <Col span={24}>
          <CommonDatePicker
            picker="month"
            label="Period"
            defaultValue={selectedDate || ''}
            changeDate={onChangeDate}
            className="date-picker-form"
            errorMessage={getError(`period`)}
            disabled={!selectedClient.value}
            disabledDate={isMonthDisabled(dateContract)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="switch-container">
          <span>Visible for consultant</span>
          <Switch
            onChange={setVisibility}
            checked={visibility}
            disabled={isDisabledVisibility}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Button
            buttonStyle="secondary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            buttonStyle="primary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={onPublish({
              consultantId: selectedConsultant.value,
              companyId: selectedClient.value,
              period: selectedDate,
              visibility: visibility ? 'public' : 'private',
            })}>
            Publish
          </Button>
        </Col>
      </Row>

      {getError('') && (
        <p className="error" style={{ maxWidth: '400px' }}>
          {getError('')}.
        </p>
      )}

      {isShowPeriodWarning && (
        <p className="error" style={{ maxWidth: '380px' }}>
          You already have the Timesheet for picked month. Are you sure you want
          to create another one?
        </p>
      )}
    </>
  );
};

export default ModalAddTimesheet;
