import { PaymentStatuses } from '../api/services/EmployeePayslips/types/types';

export type TPaylipPaymentStatusParserKey =
  | PaymentStatuses.PAID
  | PaymentStatuses.PARTIALLY_PAID
  | PaymentStatuses.TO_PAY;

export const payslipPaymentStatusParser: Record<
  TPaylipPaymentStatusParserKey,
  string
> = {
  [PaymentStatuses.PAID]: 'paid',
  [PaymentStatuses.PARTIALLY_PAID]: 'partially-paid',
  [PaymentStatuses.TO_PAY]: 'to-pay',
};
