export const checkArrayEmptyObj = (array: any) => {
  return array.filter((item: any) => {
    if (typeof item === 'string') {
      return item;
    }
    return Object.values(item).some((val) => {
      return val;
    });
  });
};
