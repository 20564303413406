import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Moment } from 'moment';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import FilterIcon from '../../assets/icons/FilterIcon';
import Button from '../../components/button/Button';
import CommonDatePicker from '../../components/date-picker/CommonDatePicker';
import {
  commonDateMonthFormat,
  commonYearDateFormat,
} from '../constants/dateConstants';
import moment from '../../common/constants/moment';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, string>) => void;
  values: string;
  field: string;
}

export const useTableDateFilterByField = ({
  fetchRequest,
  changeValues,
  values,
  field,
}: IArguments) => {
  const createdBetween = values ? values.split('|') : '';
  const from = createdBetween ? moment(createdBetween[0]) : '';
  const to = createdBetween ? moment(createdBetween[1]) : '';

  const [fromValue, setFromValue] = useState<Moment | string>(from);
  const [toValue, setToValue] = useState<Moment | string>(to);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (from || to) {
      setIsFiltered(true);
    }

    if (!from && !to) {
      setIsFiltered(false);
      setFromValue('');
      setToValue('');
    }
  }, [values]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (!!fromValue || !!toValue) {
      if (!!fromValue && !!toValue) {
        const from = (fromValue as Moment).format(commonYearDateFormat);
        const to = (toValue as Moment).format(commonYearDateFormat);
        changeValues({
          [field]: from + '|' + to,
        });
      }

      if (fromValue && !toValue) {
        const from = (fromValue as Moment).format(commonYearDateFormat);

        changeValues({
          [field]: from,
        });
      }

      if (!fromValue && toValue) {
        const to = (toValue as Moment).format(commonYearDateFormat);

        changeValues({
          [field]: to,
        });
      }

      setIsFiltered(true);
      fetchRequest();
    } else {
      setIsFiltered(false);
    }
    confirm();
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({
      [field]: '',
    });
    fetchRequest();
    confirm();
    setFromValue('');
    setToValue('');
    setIsFiltered(false);
  };

  return (): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-date-filter-dropdown">
        <div className="filter-date">
          <CommonDatePicker
            defaultValue={
              fromValue ? moment(fromValue).format(commonDateMonthFormat) : ''
            }
            changeDate={(date) => setFromValue(date ? moment(date) : '')}
            className="date-picker-form"
            placeholder="From"
          />
        </div>

        <div className="filter-date">
          <CommonDatePicker
            defaultValue={
              toValue ? moment(toValue).format(commonDateMonthFormat) : ''
            }
            changeDate={(date) => {
              setToValue(moment(date ? moment(date) : ''));
            }}
            className="date-picker-form"
            placeholder="To"
          />
        </div>

        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            onClick={() => onClear(confirm)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};
