import { Link } from 'react-router-dom';
import Button from '../../../components/button/Button';
import {
  exportToExelClient,
  getClientList,
} from '../../../redux/reducers/clients/asyncThunkActions';
import { clearClientsFiltersAndSorts } from '../../../redux/reducers/clients/sliceReducer';
import CrossIcon from '../../../assets/icons/CrossIcon';
import ExitIcon from '../../../assets/icons/ExitIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import '../../../components/extra-section/ExtraSection.less';

const { clients, modify } = ROUTER_PATHS;

const ClientsExtraSection = () => {
  const dispatch = useAppDispatch();

  const { sort: clientsSorts, filter: clientsFilters } = useAppSelector(
    (state) => state.clients.clientList,
  );
  const { selectedClients } = useAppSelector((state) => state.clients);

  const isResetClientsFilters =
    clientsSorts.sortBy !== '' ||
    Object.values(clientsFilters).some((el) => el.length !== 0) ||
    selectedClients.length;

  return (
    <>
      {isResetClientsFilters ? (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearClientsFiltersAndSorts());
            dispatch(getClientList({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      ) : null}
      <Link to={clients + modify}>
        <Button
          buttonStyle="primary"
          buttonSize="medium"
          buttonWidth={183}
          leftIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
          Add new
        </Button>
      </Link>
      <Button
        onClick={() => dispatch(exportToExelClient())}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={200}
        leftIcon={<ExitIcon className="export-icon" />}>
        Export to Excel
      </Button>
    </>
  );
};

export default ClientsExtraSection;
