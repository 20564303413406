import { useState } from 'react';
import { viewIncoiceStatusParser } from '../../../common/constants/timesheetStatusParser';
import { InvoiseStatuses } from '../../../common/api/services/InvoiceApi/types/types';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { ISort } from '../../../redux/types';

interface IProps {
  id: string;
  getInvoices: (currentSort?: ISort, currentFilter?: any) => void;
  isInvoicePage?: boolean;
}

const ChangeStatusTooltip = (props: IProps) => {
  const { id, getInvoices, isInvoicePage } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onSelectStatus = (status: InvoiseStatuses) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    invoiceApi
      .updateInvoiceStatus(id, viewIncoiceStatusParser[status])
      .then(() => {
        openSuccessNotification('Invoice status was updated successfully');
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message || 'Failed to change status',
        );
      })
      .finally(() => {
        getInvoices();
        setIsLoading(false);
      });
  };
  return (
    <div
      className={
        isInvoicePage
          ? 'tooltip-status-wrapper-invoice'
          : 'tooltip-status-wrapper'
      }>
      {Object.values(InvoiseStatuses).map((option) => (
        <div
          className="status-wrapper"
          key={option}
          onClick={() => onSelectStatus(option)}>
          <span className={viewIncoiceStatusParser[option]}>{option}</span>
        </div>
      ))}
    </div>
  );
};

export default ChangeStatusTooltip;
