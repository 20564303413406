import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const WarningIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583336 6.99943C0.583336 3.3201 3.57 0.332764 7.25 0.332764C10.9367 0.332764 13.9167 3.3201 13.9167 6.99943C13.9167 10.6801 10.9367 13.6661 7.25 13.6661C3.57 13.6661 0.583336 10.6801 0.583336 6.99943ZM6.66325 4.47275C6.66325 4.15342 6.92992 3.88608 7.24992 3.88608C7.56992 3.88608 7.82992 4.15342 7.82992 4.47275V7.41942C7.82992 7.74008 7.56992 7.99942 7.24992 7.99942C6.92992 7.99942 6.66325 7.74008 6.66325 7.41942V4.47275ZM7.25643 10.1201C6.92976 10.1201 6.66976 9.85343 6.66976 9.53343C6.66976 9.21343 6.92976 8.95343 7.24976 8.95343C7.57643 8.95343 7.83643 9.21343 7.83643 9.53343C7.83643 9.85343 7.57643 10.1201 7.25643 10.1201Z"
        fill="#E48900"
      />
    </svg>
  );
};

export default WarningIcon;
