import { InvoiseStatuses } from '../../../common/api/services/InvoiceApi/types/types';

export enum TableColorContants {
  blueColor = ' blue-background',
  orangeColor = ' orange-background',
  greyColor = ' grey-background',
  lightOrangeColor = ' light-orange-background',
  greenColor = ' green-background',
}

export const TableColorStatusParser: Record<any, string> = {
  [InvoiseStatuses.DRAFT]: '',
  [InvoiseStatuses.PAID]: TableColorContants.greenColor,
  [InvoiseStatuses.SENT]: TableColorContants.greyColor,
  [InvoiseStatuses.PARTIALLY_PAID]: TableColorContants.lightOrangeColor,
};
