import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  Col,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
  notification,
} from 'antd';
import { IWorkContract } from '../../../../redux/reducers/onboarding/types';

import CrossIcon from '../../../../assets/icons/CrossIcon';
import Input from '../../../../components/input/Input';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import Dropdown from '../../../../components/dropdown/Dropdown';
import currencies from '../../../../common/constants/mockCurrencies';
import { countries } from '../../../../common/constants/mockCountries';
import { Link } from 'react-router-dom';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import {
  SalaryType,
  TreasuryPaymentDate,
} from '../../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { IncomeLossInsurance } from '../../../../common/api/services/OnboardingApi/enums/OnboardingIncomeLossInsurance';
import InputNumber from '../../../../components/input/InputNumber';
import { clientApi } from '../../../../common/api/services/ClientApi/clientApi';
import {
  resetAddresses,
  updateOnboardingWorkContract,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import useDebounce from '../../../../common/hooks/useDebounce';
import {
  capitalizeString,
  compareString,
  sortString,
} from '../../../../common/utils/stringsUtil';
import { initWorkContract, IInitWorkContract, IAddressCompany } from './helper';
import { IClientResponse } from '../../../../common/api/services/ClientApi/types/IClientResponse';
import { IGetListDto } from '../../../../common/api/types/IGetListDto';
import ILabelInValue from '../../../../common/types/ILabelInValue';
import ErrorIcon from '../../../../assets/icons/ErrorIcon';
import { Roles } from '../../../../common/enums/Roles';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';

const insurances = Object.values(IncomeLossInsurance);

type workSalaryType = SalaryType | '';

const paymentDates = (type: workSalaryType) => {
  if (type === '') {
    return Object.values(TreasuryPaymentDate);
  }
  if (type === SalaryType.PerMonth) {
    return [TreasuryPaymentDate.IN_DAYS];
  }
  return [TreasuryPaymentDate.ASAP];
};

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessages: any;
  getError: (fieldName: string, isGroup?: boolean, likeIs?: boolean) => string;
  workContract: IWorkContract;
  onAddNewClient: () => void;
  clearValidation: () => void;
  onDeleteClient: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  onSelectCurrentClient: (
    index: number,
  ) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onChangeNumber: (
    fieldName: string,
  ) => (value: number | string | null) => void;
  onChangePhone: (value: string) => void;
  isAdmin: boolean;
  isFirstOpen: boolean;
  setIsFirstOpen: (isOpen: boolean) => void;
}

const WorkContractTabInformationSection: FC<IProps> = (props) => {
  const {
    errorMessages,
    workContract,
    getError,
    clearValidation,
    onAddNewClient,
    onChange,
    onChangeDropdown,
    onSelectCurrentClient,
    onDeleteClient,
    onChangePhone,
    onChangeNumber,
    isAdmin,
    isFirstOpen,
    setIsFirstOpen,
  } = props;

  const dispatch = useAppDispatch();

  const isConsultant = useAppSelector(
    (state) => state.auth.role === Roles.CONSULTANT,
  );
  const { initialWorkContractOrder } = useAppSelector(
    (state) => state.onboarding,
  );

  const { selectedClientIdx, companies } = workContract;

  const {
    clientId,
    name = '',
    email = '',
    phone = '',

    hasAdditionalAccidentInsurance = true,
    incomeLossInsurance = '',
    lossOfIncome = 0,
    hasCBA = false,
    cbaOption,
    hasTreasury = false,
    treasuryPaymentDate = '',
    hasSalaryAmount = false,
    salaryAmount = 0,
    invoiceCurrency = '',
    paymentCurrency = '',
    professionalAccident = 0.2,
    nonProfessionalAccident = 0.99,
    type = '',
    isCctOfficial = false,
    lossOfIncome5050 = false,
    accidentInsurance5050 = false,
  } = companies[selectedClientIdx];

  const clientCount = companies.length;

  const refPrevInput = useRef<string>('');
  const isFirstRender = useRef<boolean>(true);
  const isSelected = useRef<boolean>(false);

  const [clients, setClients] = useState<IInitWorkContract>({
    ...initWorkContract,
  });

  const { isFetching, response: stateResponse } = clients;
  const { searchName, selectedName, listOfNames } = clients;
  const { searchEmail, selectedEmail, listOfEmails } = clients;
  const { searchPhone, selectedPhone, listOfPhones } = clients;
  const [selectedClientId, setSelectedClientId] = useState('');
  const searchNameDeb = useDebounce(searchName, 500);

  const convertedSelectedIdx = convertSelectedIdx(
    companies,
    initialWorkContractOrder,
    selectedClientIdx,
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!isAdmin) return;

    const getClient = async () => {
      const state: Partial<IInitWorkContract> = {};

      try {
        let response: IGetListDto<IClientResponse> | null = null;
        if (clientId) {
          // It's a selected client from Dropdown
          if (isSelected.current) {
            const found = clients.response?.result.find(
              (item) => item._id === clientId,
            );
            if (found) {
              response = { result: [found], total: 1 };
            }
            isSelected.current = false;
          } else {
            const responseById = await clientApi.getClientById(clientId);
            setSelectedClientId(
              responseById?.publicId ? responseById.publicId.toString() : '',
            );
            const result = responseById ? [responseById] : [];
            response = { result, total: result.length };
          }
        } else {
          await clientApi
            .getClients(
              1,
              20,
              searchNameDeb ? { name: `${searchNameDeb}` } : {},
              { sort: 'asc', sortBy: 'name' },
            )
            .then((resp) => {
              response = resp;
              if (resp?.result.length === 0 && !isFirstOpen) {
                onSelectClientName();
                setSelectedClientId('');
              }
              if (isFirstOpen) {
                setIsFirstOpen(false);
              }
            });
        }

        const companyNames =
          response &&
          response.result.map((item) => ({
            label: `${item?.publicId ? `[#${item.publicId}] ` : ''}${
              item?.name
            }`,
            value: item?._id,
          }));

        // it found only one company then select it automatically fill all fields
        if (
          companyNames?.length === 1 &&
          (compareString(
            convertSearchName(companyNames[0].label),
            searchNameDeb,
          ) ||
            (companyNames && clientId === companyNames[0].value))
        ) {
          state.selectedName = companyNames[0];

          state.selectedEmail =
            response?.result[0]?.emails.find((item) =>
              compareString(item.email || '', email),
            )?.email || null;
          state.listOfEmails =
            response?.result[0]?.emails
              .sort((a, b) => sortString(a.email || '', b.email || ''))
              .map((item) => item.email || '') || [];

          state.selectedPhone =
            response?.result[0]?.phones.find((item) => phone === item.phone)
              ?.phone || null;
          state.listOfPhones =
            response?.result[0]?.phones
              .sort((a, b) => sortString(a.phone || '', b.phone || ''))
              .map((item) => item.phone || '') || [];
          state.fullAddresses = response?.result[0]?.addresses || [];
          state.listOfAddresses =
            response?.result[0]?.addresses
              .map(({ street = '', city = '', country = '', postCode }) => ({
                label: street,
                value: `${street}||-||${city}||-||${postCode}||-||${country}`,
              }))
              .sort((a, b) => a.value.localeCompare(b.value)) || [];

          dispatch(
            updateOnboardingWorkContract({ clientId: companyNames[0].value }),
          );
        } else {
          dispatch(updateOnboardingWorkContract({ clientId: null }));
          // state.addresses = addresses.map(() => ({
          //   searchStreet: '',
          //   selectedStreet: null,
          // }));
          // state.listOfAddresses = [];
        }
        if (companyNames) {
          state.listOfNames = companyNames;
        }
        state.response = response;
      } catch (error) {
        // TODO add error handling
        console.log('error', error);
      }
      setClients((obj) => ({
        ...obj,
        ...state,
        isFetching: false,
      }));
    };

    setClients((obj) => ({
      ...obj,
      isFetching: true,
      listOfNames: [],
      listOfEmails: [],
      listOfPhones: [],
    }));
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchNameDeb]);

  useEffect(() => {
    if (!isAdmin) return;
    if (name !== searchName) {
      const state: IInitWorkContract = {
        ...initWorkContract,
        response: stateResponse,
        searchName: name,
        searchEmail: email,
        searchPhone: phone,
      };
      setClients((obj) => ({
        ...obj,
        ...state,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    const message =
      getError(`the client name exists`, false, true) ||
      errorMessages ===
        'The draft status cannot be applied after the workContract has been successfully committed'
        ? errorMessages
        : '';

    if (message && typeof message === 'string' && message.length > 1) {
      notification.open({
        message: 'Warning alert',
        description: capitalizeString(message),
        type: 'error',
        icon: <ErrorIcon />,
        className: 'common-error-notification',
      });
      clearValidation();
      return;
    }
  }, [clearValidation, errorMessages, getError]);

  const onChangeSearchName = (data: string) => {
    if (!data && refPrevInput.current?.length !== 1) return;
    refPrevInput.current = convertSearchName(data);
    setSelectedClientId('');

    setClients((obj) => ({
      ...obj,
      searchName: convertSearchName(data),
      selectedName: null,
    }));

    dispatch(
      updateOnboardingWorkContract({
        name: convertSearchName(data),
        clientId: null,
      }),
    );
  };

  const onChangeNameDropdown = (data: ILabelInValue) => {
    setClients((obj) => ({
      ...obj,
      selectedName: convertDataSearchName(data),
      getEqual: !!data,
    }));

    if (data) isSelected.current = true;
    onChangeSearchName(convertSearchName(data?.label));
    setSelectedClientId(
      data?.label ? data.label.replace(/\[#(\d+)\].*/, '$1') : '',
    );
    dispatch(
      updateOnboardingWorkContract({
        name: convertSearchName(data?.label) || '',
        clientId: data?.value || null,
      }),
    );
  };

  const onChangeSwitch = (value: boolean, fieldName: string) => {
    dispatch(updateOnboardingWorkContract({ [fieldName]: value }));
  };

  const onChangeStringSearch = (fieldName: string) => (data: string) => {
    if (data === '' && refPrevInput.current?.length !== 1) return;
    const selected = `selected${capitalizeString(fieldName)}`;
    const search = `search${capitalizeString(fieldName)}`;
    refPrevInput.current = data;

    setClients((obj) => ({ ...obj, [search]: data, [selected]: data }));
    dispatch(updateOnboardingWorkContract({ [fieldName]: data }));
  };

  const onChangeStringDropdown = (fieldName: string) => (value: string) => {
    const selected = `selected${capitalizeString(fieldName)}`;
    const search = `search${capitalizeString(fieldName)}`;

    setClients((obj) => ({ ...obj, [selected]: value, [search]: value }));
    dispatch(updateOnboardingWorkContract({ [fieldName]: value }));
  };

  const onClear = (fieldName: string) => () => {
    const selected = `selected${capitalizeString(fieldName)}`;
    const search = `search${capitalizeString(fieldName)}`;

    setClients((obj) => ({ ...obj, [search]: '', [selected]: null }));

    dispatch(
      updateOnboardingWorkContract({
        [fieldName]: '',
        clientId: fieldName === 'name' ? null : clientId || null,
      }),
    );
  };

  const convertSearchName = (name: string) => {
    return name ? name.replace(/\[[^\]]*\]/g, '').trimStart() : '';
  };

  const convertDataSearchName = (data: ILabelInValue) => {
    return { ...data, label: data?.label ? convertSearchName(data.label) : '' };
  };

  const onSelectClientName = () => {
    onChangeStringDropdown('email')('');
    onChangeStringDropdown('phone')('');
    dispatch(resetAddresses());
  };

  return (
    <Col span={12}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {isAdmin ? (
            <Dropdown
              allowClear={!!searchName || !!selectedName?.label}
              labelInValue={true}
              isFetching={isFetching}
              label={`Client company name ${
                selectedClientId ? `[#${selectedClientId}]` : ''
              }`}
              value={selectedName}
              searchValue={searchName}
              showArrow={listOfNames.length > 0}
              onClear={onClear('name')}
              onSearch={onChangeSearchName}
              onChange={onChangeNameDropdown}
              onSelect={onSelectClientName}
              options={listOfNames}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].name`,
              )}
            />
          ) : (
            <Input
              name="name"
              label="Client company name"
              value={name}
              onChange={onChange}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].name`,
              )}
            />
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          {isAdmin && listOfEmails.length > 0 ? (
            <Dropdown
              label="Email"
              allowClear={!!searchEmail || !!selectedEmail}
              value={selectedEmail}
              searchValue={searchEmail}
              onClear={onClear('email')}
              onSearch={onChangeStringSearch('email')}
              onChange={onChangeStringDropdown('email')}
              options={listOfEmails}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].email`,
              )}
            />
          ) : (
            <Input
              name="email"
              label="Email"
              value={email}
              onChange={onChange}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].email`,
              )}
            />
          )}
        </Col>
        <Col span={12} style={{ zIndex: 10 }}>
          {isAdmin && listOfPhones.filter((item) => item).length > 0 ? (
            <Dropdown
              label="Phone number"
              allowClear={!!searchPhone || !!selectedPhone}
              value={selectedPhone}
              searchValue={searchPhone}
              onClear={onClear('phone')}
              onSearch={onChangeStringSearch('phone')}
              onChange={onChangeStringDropdown('phone')}
              options={listOfPhones}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].phone`,
              )}
            />
          ) : (
            <Input
              inputType="phone"
              value={phone}
              onChange={onChangePhone}
              label="Phone number"
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].phone`,
              )}
              placeholder="+XX XXX XXX XX XX"
            />
          )}
        </Col>
      </Row>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Complementary accident insurance:</span>
          <Popover
            className="info-pop-button"
            placement="rightTop"
            content={
              <p>
                Standard coverage is 80% of your salary up to CHF 148&apos;200.
                <br /> Complementary insurance covers 80% of your entire salary.
              </p>
            }
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover>
        </Col>
        <Col>
          <Radio.Group
            name="hasAdditionalAccidentInsurance"
            value={hasAdditionalAccidentInsurance}
            onChange={onChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputNumber
              name="professionalAccident"
              label="Professional accident %"
              value={professionalAccident}
              onChange={onChangeNumber('professionalAccident')}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].professionalAccident`,
              )}
              placeholder="Number"
              showIcons={false}
              showAsterisk
            />
          </Col>
          <Col span={12} style={{ zIndex: 1 }}>
            <InputNumber
              name="nonProfessionalAccident"
              value={nonProfessionalAccident}
              onChange={onChangeNumber('nonProfessionalAccident')}
              label="Non-professional accident %"
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].nonProfessionalAccident`,
              )}
              showIcons={false}
              showAsterisk
            />
          </Col>
        </Row>
      )}
      {!isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Accident Insurance 50-50</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Switch
              onChange={(e) => onChangeSwitch(e, 'accidentInsurance5050')}
              checked={!!accidentInsurance5050}
            />
          </Col>
        </Row>
      )}
      {!isConsultant && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Dropdown
              options={insurances}
              value={incomeLossInsurance}
              onChange={onChangeDropdown('incomeLossInsurance')}
              label="Loss of income insurance"
              showSearch={false}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].incomeLossInsurance`,
              )}
              disabled={!isAdmin}
              allowClear
              onClear={() => onChangeDropdown('incomeLossInsurance')('')}
              showAsterisk
            />
          </Col>
          <Col span={12}>
            <InputNumber
              label="Loss of income %"
              name="lossOfIncome"
              value={lossOfIncome}
              onChange={onChangeNumber('lossOfIncome')}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].lossOfIncome`,
              )}
              placeholder="Number"
              showIcons={false}
              disabled={!isAdmin}
              showAsterisk
            />
          </Col>
        </Row>
      )}
      {!isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Loss of Income 50-50</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Switch
              onChange={(e) => onChangeSwitch(e, 'lossOfIncome5050')}
              checked={!!lossOfIncome5050}
            />
          </Col>
        </Row>
      )}
      {!isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Are you CBA/CCT?</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Radio.Group name="hasCBA" value={hasCBA} onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {hasCBA && !isConsultant && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={['0.80 % ER', '0.40% - 0.40% EE/ER']}
              value={cbaOption}
              onChange={onChangeDropdown('cbaOption')}
              label="CBA/CCT option"
              showSearch={false}
              allowClear={!!cbaOption}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].cbaOption`,
              )}
              showAsterisk
            />
          </Col>
        </Row>
      )}
      {hasCBA && !isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Are you CCT official?</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Radio.Group
              name="isCctOfficial"
              value={isCctOfficial}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {!isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Do you have a treasury?</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Radio.Group
              name="hasTreasury"
              value={hasTreasury}
              disabled={isConsultant}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {hasTreasury && isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={paymentDates(type as workSalaryType)}
              value={treasuryPaymentDate}
              onChange={onChangeDropdown('treasuryPaymentDate')}
              label="Payment date"
              showSearch={false}
              disabled={isConsultant}
              showAsterisk
              allowClear={!!treasuryPaymentDate}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].treasuryPaymentDate`,
              )}
            />
          </Col>
        </Row>
      )}

      {!isConsultant && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <span>Do you have a fixed AVS/AHV salary?</span>
            <span className="asterisk">*</span>
          </Col>
          <Col>
            <Radio.Group
              name="hasSalaryAmount"
              value={hasSalaryAmount}
              disabled={isConsultant}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {hasSalaryAmount && !isConsultant && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputNumber
              label="AVS/AHV salary amount per month"
              onChange={onChangeNumber('salaryAmount')}
              value={salaryAmount}
              disabled={isConsultant}
              name="salaryAmount"
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].salaryAmount`,
              )}
              showIcons={false}
              showAsterisk
            />
          </Col>
        </Row>
      )}

      <div className="address-title">
        <h4>Currency</h4>
        <Popover
          className="info-pop-button"
          placement="topRight"
          content={<CurrencyInfoContent />}
          trigger="hover"
          overlayClassName="info-pop-content"
          showArrow={false}>
          <button>
            <WarningIcon />
          </button>
        </Popover>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Dropdown
            options={currencies}
            value={invoiceCurrency}
            onChange={onChangeDropdown('invoiceCurrency')}
            label="Invoice currency"
            showSearch={false}
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].invoiceCurrency`,
            )}
            allowClear
            onClear={() => onChangeDropdown('invoiceCurrency')('')}
          />
        </Col>
        <Col span={12}>
          <Dropdown
            options={currencies.filter(
              (value) =>
                (value === 'CHF' && value === invoiceCurrency) ||
                value === 'CHF' ||
                value === invoiceCurrency,
            )}
            value={paymentCurrency}
            onChange={onChangeDropdown('paymentCurrency')}
            label="Payment currency"
            showSearch={false}
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].paymentCurrency`,
            )}
            allowClear
            onClear={() => onChangeDropdown('paymentCurrency')('')}
          />
        </Col>
      </Row>

      {clientCount !== 1 && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <button className="remove-client-btn" onClick={onDeleteClient}>
              <TrashIcon />
              <span>Delete this client</span>
            </button>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default WorkContractTabInformationSection;

const CurrencyInfoContent: FC = () => (
  <p>
    Invoice Currency relates to the invoice from Numeriq to the client,
    <br />
    Payment Currency relates to the Salary payment currency.
  </p>
);
