import { ChangeEvent, FC } from 'react';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';
import Input from '../../../components/input/Input';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { updateOnboardingPersonalInfo } from '../../../redux/reducers/onboarding/sliceReducer';
import { createConsultant } from '../../../redux/reducers/consultants/asyncThunkActions';

interface IProps {
  hideModal: () => void;
}

const ModalCreateOnboardingConsultant: FC<IProps> = ({ hideModal }) => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, email, phone } = useAppSelector(
    (state) => state.onboarding.personalInfo,
  );
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.personalInfo,
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    dispatch(updateOnboardingPersonalInfo({ [inputName]: inputValue }));
  };

  const onChangePhone = (value: string) => {
    dispatch(updateOnboardingPersonalInfo({ phone: value }));
  };

  const onSubmit = () => {
    dispatch(createConsultant());
  };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    }

    return '';
  };

  return (
    <div className="modal-create-onboarding-consultant">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="firstName"
            label="First name"
            value={firstName}
            onChange={onChange}
            errorMessage={getError('firstName')}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="lastName"
            label="Last name"
            value={lastName}
            errorMessage={getError('lastName')}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="email"
            label="Email"
            value={email}
            onChange={onChange}
            errorMessage={getError('email')}
          />
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <Input
            inputType="phone"
            value={phone}
            onChange={onChangePhone}
            label="Phone number"
            errorMessage={getError('phone')}
          />
        </Col>
      </Row>
      <Row gutter={[32, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={hideModal}>
            Cancel
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ModalCreateOnboardingConsultant;
