export const formatAmount = (input: string | number | null | undefined) => {
  if (!input && typeof input !== 'number') {
    return '';
  }
  const number = typeof input === 'string' ? parseFloat(input) : input;
  if (isNaN(number)) {
    return '';
  }
  let formatted = number.toFixed(2);
  // eslint-disable-next-line prefer-const
  let [integer, decimal] = formatted.split('.');
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  formatted = integer + '.' + decimal;
  return formatted;
};
