import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const CopyIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.16667"
        y="1.5"
        width="8.33333"
        height="11.6667"
        rx="2.5"
        stroke="#4196F0"
        strokeWidth="1.5"
      />
      <path
        d="M11.5835 5.16772C12.3308 5.59999 12.8335 6.40792 12.8335 7.33327V13.9999C12.8335 15.3806 11.7142 16.4999 10.3335 16.4999H7.00019C6.07483 16.4999 5.2669 15.9972 4.83464 15.2499"
        stroke="#4196F0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CopyIcon;
