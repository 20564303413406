import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { Space } from 'antd';
import Button from '../../components/button/Button';
import FilterIcon from '../../assets/icons/FilterIcon';
import { OnboardingRequestStatuses } from '../api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { MonitoringStatuses } from '../api/services/MonitorngApi/enums/MonitoringStatuses';
import { LeadStatuses } from '../enums/Statuses';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, string[]>) => void;
  defaultValues: string[];
  fieldFilterName: string;
  className?: string;
  isNoValue?: boolean;
}

export const useTableSelectStatusFilter = ({
  fetchRequest,
  changeValues,
  defaultValues,
  fieldFilterName,
  className,
  isNoValue,
}: IArguments) => {
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string[]>(
    defaultValues ? defaultValues : [],
  );

  useEffect(() => {
    if (defaultValues.length > 0) {
      setIsFiltered(true);
    }

    if (defaultValues.length === 0) {
      setIsFiltered(false);
      setSelectedValue([]);
    }
  }, [defaultValues.length]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (changeValues.length > 0) {
      setIsFiltered(true);
      changeValues({ [fieldFilterName]: selectedValue });
      fetchRequest();
    } else {
      setIsFiltered(false);
    }

    confirm();
  };

  const onChange = ({ target }: CheckboxChangeEvent) => {
    const filterValue =
      target.value !== 'Yes' && target.value !== 'No'
        ? target.value
        : target.value === 'Yes'
        ? 'true'
        : isNoValue
        ? 'No'
        : 'false';
    if (target.checked) {
      setSelectedValue((prevState) => [...prevState, filterValue]);
    } else {
      setSelectedValue((prevState) =>
        prevState.filter((el) => el !== filterValue),
      );
    }
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({ [fieldFilterName]: [] });
    fetchRequest();
    setSelectedValue([]);
    confirm();
    setIsFiltered(false);
  };

  const checkValue = (value: string) => {
    if (value !== 'Yes' && value !== 'No') return selectedValue.includes(value);
    if (value === 'Yes') return selectedValue.includes('true');
    if (value === 'No')
      return selectedValue.includes(isNoValue ? 'No' : 'false');
    return false;
  };

  const onSelectAll = (values: any) => {
    const newValues = values.map((item: string) => {
      return item !== 'Yes' && item !== 'No'
        ? item
        : item === 'Yes'
        ? 'true'
        : isNoValue
        ? 'No'
        : 'false';
    });
    setSelectedValue(newValues);
  };
  //NOTE: need refactor: dataIndex: string[] leads to create new array
  return (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div
        className={`table-select-filter-dropdown ${
          className ? className : ''
        }`}>
        <span
          onClick={() => onSelectAll(dataIndex)}
          className="select-all-filters">
          Select all
        </span>
        <div className="selected-checkbox-group">
          {dataIndex.map((item: string) => {
            return (
              <Checkbox
                name={item}
                key={item}
                value={item}
                onChange={onChange}
                checked={checkValue(item)}>
                {renderCheckboxName(item)}
              </Checkbox>
            );
          })}
        </div>

        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            style={{ color: isFiltered ? '#0671e0' : '#abbed1' }}
            onClick={() => onClear(confirm)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};

const isOnboardingStatuses = (
  b: OnboardingRequestStatuses | MonitoringStatuses | string,
): boolean => {
  return Object.values(OnboardingRequestStatuses).includes(
    b as OnboardingRequestStatuses,
  );
};

const isMonitoringStatuses = (
  b: OnboardingRequestStatuses | MonitoringStatuses | string,
): boolean => {
  return Object.values(MonitoringStatuses).includes(b as MonitoringStatuses);
};
//TODO: all statuses to same case
const isLeadStatuses = (b: LeadStatuses | string): boolean => {
  return Object.values(LeadStatuses).includes(b as LeadStatuses);
};

const renderCheckboxName = (
  name: OnboardingRequestStatuses | MonitoringStatuses | string,
): string => {
  if (isOnboardingStatuses(name) || isLeadStatuses(name)) {
    return OnboardingRequestStatuses.toConfirm === name
      ? 'To confirm'
      : OnboardingRequestStatuses.toVerify === name
      ? 'To verify'
      : name[0].toUpperCase() + name.slice(1);
  } else if (isMonitoringStatuses(name)) {
    return name[0].toUpperCase() + name.slice(1);
  } else {
    return name;
  }
};
