import { ISort } from '../../../../redux/types';
import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';

class DocumentsApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/document`;

  getDocuments(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<any>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    });
  }
  getConsultantDocuments(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<any>>('/consultant', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    });
  }
  deleteDocument(documentId: string) {
    return this.delete(`/${documentId}`);
  }
}

export const documentsApi = new DocumentsApi();
