import { FC, Fragment } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';

const AdditionalInfoTab: FC = () => {
  const { contacts } = useAppSelector(
    (state) => state.onboarding.additionalInfo,
  );

  return (
    <Row className="my-information-tab">
      <Col span={12}>
        <h4>Emergency contact</h4>

        {contacts.map(
          ({ firstName, lastName, phone, email, relationshipStatus }, idx) => (
            <Fragment key={idx}>
              {contacts.length > 1 && (
                <h4 className="others-title">Contact {idx + 1}</h4>
              )}
              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">First name</span>
                      <span className="item-value">
                        {firstName ? firstName : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Last name</span>
                      <span className="item-value">
                        {lastName ? lastName : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Phone</span>
                      <span className="item-value">{phone ? phone : '-'}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Email</span>
                      <span className="item-value">{email ? email : '-'}</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">
                        Relationship with the contact person
                      </span>
                      <span className="item-value">
                        {relationshipStatus ? relationshipStatus : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Fragment>
          ),
        )}
      </Col>
    </Row>
  );
};

export default AdditionalInfoTab;
