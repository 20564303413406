export function createIndexMapByKey(array: any, key: string) {
  return array.reduce((acc: any, item: any, index: number) => {
    if (key in item) {
      acc[item[key]] = index;
    }
    return acc;
  }, {});
}

export function applyClientOrder(clients: any, clientsOrder: any) {
  return clients.sort((a: any, b: any) => {
    const indexA = clientsOrder[a._id];
    const indexB = clientsOrder[b._id];

    // If either of the clients is not in clientsOrder, they will retain their original order
    if (indexA === undefined || indexB === undefined) {
      return 0;
    }

    return indexA - indexB;
  });
}

export function convertSelectedIdx(array: any, object: any, index: number) {
  const objectId = array[index]._id;
  return object[objectId];
}
