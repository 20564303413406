export const loadPageDataFromLocalStorage = (pageId: string, key: string) => {
  const data = localStorage.getItem(`${pageId}_${key}`);
  return data ? JSON.parse(data) : null;
};

export const savePageDataToLocalStorage = (
  pageId: string,
  key: string,
  value: any,
) => {
  localStorage.setItem(`${pageId}_${key}`, JSON.stringify(value));
};

export const clearPageDataFromLocalStorage = (
  pageId: string,
  keys: string[],
) => {
  keys.forEach((key) => localStorage.removeItem(`${pageId}_${key}`));
};
