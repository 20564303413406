import { ChangeEvent, FC } from 'react';
import { RadioChangeEvent, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  clearOnboardingErrorsValidation,
  clearOnboardingWorkPermit,
  updateOnboardingWorkPermit,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import WorkPermitTabDocumentSection from './WorkPermitTabDocumentSection';
import WorkPermitTabInformationSection from './WorkPermitTabInformationSection';
import moment from '../../../../common/constants/moment';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';

const WorkPermitTab: FC = () => {
  const dispatch = useAppDispatch();

  const { documents, ...restWorkPermit } = useAppSelector(
    (state) => state.onboarding.workPermit,
  );
  const isSwissNationality = useAppSelector(
    (state) => state.onboarding.personalInfo.nationality === 'Switzerland',
  );

  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.workPermit,
  );

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement> | RadioChangeEvent,
  ): void => {
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;

    if (fieldName === 'hasWorkPermit' && !fieldValue) {
      dispatch(clearOnboardingWorkPermit());
      dispatch(clearOnboardingErrorsValidation());
    }

    dispatch(updateOnboardingWorkPermit({ [fieldName]: fieldValue }));
  };

  const onChangeDate =
    (fieldName: string) =>
    (date: Date | null): void => {
      const value = date ? moment(date)?.format(commonYearDateFormat) : '';
      dispatch(updateOnboardingWorkPermit({ [fieldName]: value }));
    };

  const onChangeDropdown =
    (fieldName: string) =>
    (data: any): void => {
      dispatch(updateOnboardingWorkPermit({ [fieldName]: data }));
    };

  return (
    <Row className="work-permit-tab my-information-tab" gutter={[16, 0]}>
      <WorkPermitTabInformationSection
        workPermit={restWorkPermit}
        onChange={onChange}
        onChangeDate={onChangeDate}
        isSwissNationality={isSwissNationality}
        onChangeDropdown={onChangeDropdown}
        errorMessages={errorMessages}
      />

      <WorkPermitTabDocumentSection
        hasWorkPermit={restWorkPermit.hasWorkPermit}
        documents={documents}
        isSwissNationality={isSwissNationality}
        documentError={errorMessages['data.documents'] ?? ''}
      />
    </Row>
  );
};

export default WorkPermitTab;
