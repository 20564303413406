import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Input from '../../../components/input/Input';
import TrashIcon from '../../../assets/icons/TrashIcon';
import CrossIcon from '../../../assets/icons/CrossIcon';
import Dropdown from '../../../components/dropdown/Dropdown';
import { countries } from '../../../common/constants/mockCountries';
import { IClientRequest } from '../../../common/api/services/ClientApi/types/IClientRequest';
import { TSelectedClientInvoiceNames } from '../../../redux/reducers/clients/type';
import StreetDropdown from './StreetDropdown';
import UploadDropzone from '../../../components/upload-dropzone/UploadDropzone';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import {
  addFile,
  addNewAddressLineClient,
  changeAddressLineClient,
  removeAddressLineClient,
  removeFile,
} from '../../../redux/reducers/clients/sliceReducer';
import { openErrorNotification } from '../../../components/notification/Notification';

interface IProps {
  values: Pick<
    IClientRequest,
    | 'signatories'
    | 'invoiceries'
    | 'reminders'
    | 'addresses'
    | 'clientContacts'
    | 'files'
  >;
  onAddNewAddress: () => void;
  onDeleteAddress: (idx: number | string) => () => void;
  onChangeAddress: (
    idx: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;

  onChangeDropdownAddress: (
    idx: number,
    extraName: string,
  ) => (value: any) => void;
  onChangeInvoiceSection: (
    idx: number,
    name: TSelectedClientInvoiceNames,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  onAddNewInvoiceSection: (name: TSelectedClientInvoiceNames) => () => void;
  onDeleteInvoiceSection: (
    idx: number | string,
    name: TSelectedClientInvoiceNames,
  ) => () => void;
  getError: (fileName: string) => string;
  onSelectStreet: (
    idx: number,
    postcode: string,
    street: string,
    city: string,
  ) => () => void;
  onChangeClientContacts: (
    index: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const InvoiceInformation: FC<IProps> = (props) => {
  const params: any = useParams();
  const {
    values,
    onAddNewAddress,
    onDeleteAddress,
    onChangeAddress,
    onChangeDropdownAddress,
    onChangeInvoiceSection,
    onAddNewInvoiceSection,
    onDeleteInvoiceSection,
    onSelectStreet,
    onChangeClientContacts,
    getError,
  } = props;
  const {
    signatories,
    invoiceries,
    reminders,
    addresses,
    clientContacts,
    files,
  } = values;
  const dispatch = useAppDispatch();

  return (
    <Col span={12}>
      <h4>Invoice information</h4>
      {signatories.map(({ email, name, lastName, _id }, idx) => {
        const change = onChangeInvoiceSection(idx, 'signatories');
        return (
          <Fragment key={_id === '' ? idx : _id}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="name"
                  label="Signatory Name"
                  value={name}
                  onChange={change}
                  errorMessage={getError(`signatories[${idx}].name`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="lastName"
                  label="Signatory Last Name"
                  value={lastName}
                  onChange={change}
                  errorMessage={getError(`signatories[${idx}].lastName`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="email"
                  label="Signatory email"
                  value={email}
                  onChange={change}
                  errorMessage={getError(`signatories[${idx}].email`)}
                />
              </Col>
            </Row>

            {signatories.length > 1 && (
              <Row gutter={[16, 16]} justify="end" align="middle">
                <button
                  className="remove-child-btn"
                  onClick={onDeleteInvoiceSection(
                    _id === '' ? idx : _id,
                    'signatories',
                  )}>
                  Delete address
                  <TrashIcon />
                </button>
              </Row>
            )}
          </Fragment>
        );
      })}

      <button
        className="add-common-btn add-invoice-btn"
        onClick={onAddNewInvoiceSection('signatories')}>
        <CrossIcon />
        Add new Signatory
      </button>

      {invoiceries.map(({ name, lastName, email, _id }, idx) => {
        const change = onChangeInvoiceSection(idx, 'invoiceries');

        return (
          <Fragment key={_id === '' ? idx : _id}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="name"
                  label="Invoicing Name"
                  value={name}
                  onChange={change}
                  errorMessage={getError(`invoiceries[${idx}].name`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="lastName"
                  label="Invoicing Last Name"
                  value={lastName}
                  onChange={change}
                  errorMessage={getError(`invoiceries[${idx}].lastName`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="email"
                  label="Invoicing email"
                  value={email}
                  onChange={change}
                  errorMessage={getError(`invoiceries[${idx}].email`)}
                />
              </Col>
            </Row>

            {invoiceries.length > 1 && (
              <Row gutter={[16, 16]} justify="end" align="middle">
                <button
                  className="remove-child-btn"
                  onClick={onDeleteInvoiceSection(
                    _id === '' ? idx : _id,
                    'invoiceries',
                  )}>
                  Delete address
                  <TrashIcon />
                </button>
              </Row>
            )}
          </Fragment>
        );
      })}

      <button
        className="add-common-btn add-invoice-btn"
        onClick={onAddNewInvoiceSection('invoiceries')}>
        <CrossIcon />
        Add new Invoicing
      </button>

      {reminders.map(({ name, lastName, email, _id }, idx) => {
        const change = onChangeInvoiceSection(idx, 'reminders');

        return (
          <Fragment key={_id === '' ? idx : _id}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="name"
                  label="Reminder Name"
                  value={name}
                  onChange={change}
                  errorMessage={getError(`reminders[${idx}].name`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="lastName"
                  label="Reminder Last Name"
                  value={lastName}
                  onChange={change}
                  errorMessage={getError(`reminders[${idx}].lastName`)}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="email"
                  label="Reminder email"
                  value={email}
                  onChange={change}
                  errorMessage={getError(`reminders[${idx}].email`)}
                />
              </Col>
            </Row>

            {reminders.length > 1 && (
              <Row gutter={[16, 16]} justify="end" align="middle">
                <button
                  className="remove-child-btn"
                  onClick={onDeleteInvoiceSection(
                    _id === '' ? idx : _id,
                    'reminders',
                  )}>
                  Delete address
                  <TrashIcon />
                </button>
              </Row>
            )}
          </Fragment>
        );
      })}

      <button
        className="add-common-btn add-invoice-btn"
        onClick={onAddNewInvoiceSection('reminders')}>
        <CrossIcon />
        Add new Reminder
      </button>

      {clientContacts.map(({ name, lastName, _id, email, phone }, idx) => (
        <Fragment key={_id || idx}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Input
                name="name"
                value={name}
                onChange={onChangeClientContacts(idx)}
                label="Client contact Name"
                errorMessage={getError(`clientContacts[${idx}].name`)}
              />
            </Col>
            <Col span={12}>
              <Input
                name="lastName"
                value={lastName}
                onChange={onChangeClientContacts(idx)}
                label="Client contact Last Name"
                errorMessage={getError(`clientContacts[${idx}].lastName`)}
              />
            </Col>
          </Row>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Input
                name="email"
                value={email}
                onChange={onChangeClientContacts(idx)}
                label="Client contact Email"
                errorMessage={getError(`clientContacts[${idx}].email`)}
              />
            </Col>
            <Col span={12}>
              <Input
                inputType="phone"
                value={phone}
                onChange={onChangeClientContacts(idx)}
                label="Client contact Phone number"
                errorMessage={getError(`clientContacts[${idx}].phone`)}
              />
            </Col>
          </Row>
        </Fragment>
      ))}

      {addresses.map(
        (
          {
            street = '',
            postCode = '',
            country = '',
            city = '',
            _id,
            vat = undefined,
            addressLines = [],
          },
          idx,
        ) => (
          <div
            key={_id === '' ? idx : _id}
            style={{
              borderBottom: '7px solid #f5f7fa',
              paddingBottom: '1rem',
            }}>
            <h4 style={{ marginTop: '1rem' }}>Address {idx + 1}</h4>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <StreetDropdown
                  getError={getError}
                  idx={idx}
                  onChangeDropdownAddress={onChangeDropdownAddress}
                  onSelectStreet={onSelectStreet}
                  street={street}
                  key={idx}
                />
              </Col>
            </Row>
            {addressLines?.length
              ? addressLines.map((addressLine, lineIndex) => (
                  <Row gutter={[16, 16]} key={lineIndex}>
                    <Col span={24}>
                      <>
                        <Input
                          name="lineIndex"
                          label="Address (street name and number)"
                          value={addressLine}
                          onChange={(value: any) =>
                            dispatch(
                              changeAddressLineClient({
                                index: idx,
                                lineIndex,
                                value: value.target.value,
                              }),
                            )
                          }
                          errorMessage={getError(
                            `addresses[${idx}].addressLines[${[lineIndex]}]`,
                          )}
                        />
                        <Row
                          gutter={[16, 16]}
                          justify="end"
                          align="middle"
                          style={{ marginTop: '0.5rem' }}>
                          <button
                            className="remove-child-btn"
                            onClick={() =>
                              dispatch(
                                removeAddressLineClient({
                                  index: idx,
                                  lineIndex,
                                }),
                              )
                            }>
                            Delete address line
                            <TrashIcon />
                          </button>
                        </Row>
                      </>
                    </Col>
                  </Row>
                ))
              : null}

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <button
                  className="add-common-btn"
                  onClick={() => dispatch(addNewAddressLineClient(idx))}>
                  <CrossIcon />
                  Add new address line
                </button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="postCode"
                  label="Postcode"
                  value={postCode}
                  onChange={onChangeAddress(idx)}
                  errorMessage={getError(`addresses[${idx}].postCode`)}
                />
              </Col>
              <Col span={8}>
                <Dropdown
                  options={countries}
                  label="Country"
                  value={country}
                  onChange={onChangeDropdownAddress(idx, 'country')}
                  errorMessage={getError(`addresses[${idx}].country`)}
                  isSearch={true}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="city"
                  label="City"
                  value={city}
                  onChange={onChangeAddress(idx)}
                  errorMessage={getError(`addresses[${idx}].city`)}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="vat"
                  label="VAT"
                  value={vat?.toString() || ''}
                  onChange={onChangeAddress(idx)}
                  errorMessage={getError(`addresses[${idx}].vat`)}
                />
              </Col>
            </Row>

            {addresses.length > 1 && (
              <Row gutter={[16, 16]} justify="end" align="middle">
                <button
                  className="remove-child-btn"
                  onClick={onDeleteAddress(_id === '' ? idx : _id)}>
                  Delete address
                  <TrashIcon />
                </button>
              </Row>
            )}
          </div>
        ),
      )}
      <button className="add-common-btn" onClick={onAddNewAddress}>
        <CrossIcon />
        Add address
      </button>
      {params?.clientId && (
        <>
          <h4 className="title-section">Timesheets documents</h4>
          <UploadDropzone
            defaultFiles={files.filter((item: any) => item.type === 'T')}
            onSubmit={(filesArray) =>
              fileApi
                .uploadClientDocuments(filesArray, params?.clientId, 't')
                .then((resp: any) => dispatch(addFile(resp.uploadedFiles)))
                .catch((e) => openErrorNotification('Failed to upload file'))
            }
            removeFile={(filesId) =>
              fileApi
                .uploadClientDocuments(files, params?.clientId, 't')
                .then(() => dispatch(removeFile(filesId)))
                .catch((e) => openErrorNotification('Failed to delete file'))
            }
            errorMessage={getError('data.files')}
            isDownload
          />
          <h4 className="title-section">EC documents</h4>
          <UploadDropzone
            defaultFiles={files.filter((item: any) => item.type === 'EC')}
            onSubmit={(filesArray) =>
              fileApi
                .uploadClientDocuments(filesArray, params?.clientId, 'ec')
                .then((resp: any) => dispatch(addFile(resp.uploadedFiles)))
                .catch((e) => openErrorNotification('Failed to upload file'))
            }
            removeFile={(filesId) =>
              fileApi
                .uploadClientDocuments(files, params?.clientId, 'ec')
                .then(() => dispatch(removeFile(filesId)))
                .catch((e) => openErrorNotification('Failed to delete file'))
            }
            errorMessage={getError('data.files')}
            isDownload
          />
        </>
      )}
    </Col>
  );
};

export default InvoiceInformation;
