import { ISort } from '../../../../redux/types';
import { ApiServiceBase } from '../../ApiServiceBase';
import { MainActivitiesType } from './types/types';
import { getQueryParams } from '../../utils/getQueryParams';
import { IGetListDto } from '../../types/IGetListDto';

class MainActivitiesApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/main-activities`;
  getMainActivities(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<MainActivitiesType>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    });
  }
  addNotes(mainActivityId: string, notes: string) {
    return this.put(`/${mainActivityId}/notes`, { notes });
  }
}
export const mainActivitiesApi = new MainActivitiesApi();
