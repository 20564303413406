import { FC } from 'react';
import { Col } from 'antd';
import UploadDropzone from '../../../../components/upload-dropzone/UploadDropzone';
import { removeOnboardingWorkPermit } from '../../../../redux/reducers/onboarding/sliceReducer';
import { useAppDispatch } from '../../../../redux/store';
import { IUploadFileResponse } from '../../../../common/api/services/FileApi/types/IUploadFileResponse';
import { uploadFileOnboardingWorkPermit } from '../../../../redux/reducers/onboarding/tabs/workPermitAsyncThunk';

interface IProps {
  isSwissNationality: boolean;
  hasWorkPermit: boolean;
  documents: IUploadFileResponse[];
  documentError: string | undefined;
}

const WorkPermitTabDocumentSection: FC<IProps> = ({
  documents,
  isSwissNationality,
  documentError,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Col span={12}>
      <div className="document-title">
        <h4>Document</h4>
      </div>
      <UploadDropzone
        defaultFiles={documents}
        onSubmit={(files) => dispatch(uploadFileOnboardingWorkPermit(files))}
        removeFile={(file) => dispatch(removeOnboardingWorkPermit(file))}
        disabled={isSwissNationality}
        errorMessage={documentError}
        isDownload
      />
    </Col>
  );
};

export default WorkPermitTabDocumentSection;
