import { useState } from 'react';
import Input from '../../../components/input/Input';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../../activity-report-page/ActivityReportPage.less';
import { formatAmount } from '../../../common/utils/formatAmount';

interface IProps {
  defaultValue: number | string;
  data: any;
  onChangeValue: (invoiceId: string, value: string | number) => void;
  type: 'text' | 'number';
}

const InputRowChange = (props: IProps) => {
  const { defaultValue, data, onChangeValue, type } = props;
  const [isChangeMode, setIsChangeMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const changeModeOn = () => {
    setInputValue(defaultValue?.toString() || '');
    setIsChangeMode(true);
  };

  const onCancel = () => {
    setIsChangeMode(false);
  };

  const onConfirm = () => {
    onChangeValue(data.internalId, inputValue);
    setIsChangeMode(false);
  };

  if (isChangeMode) {
    return (
      <div className="change-mode-wrapper">
        <Input
          value={inputValue}
          onChange={onChangeInput}
          label=""
          inputType={type}
        />
        <CloseOutlined
          onClick={onCancel}
          style={{
            color: '#e02b1d',
            width: '10px',
            height: '10px',
            marginLeft: '10px',
          }}
        />
        <CheckOutlined
          onClick={onConfirm}
          style={{
            color: '#4e9262',
            width: '10px',
            height: '10px',
            marginLeft: '10px',
          }}
        />
      </div>
    );
  }

  return (
    <div className="full-size" onClick={changeModeOn}>
      <span>{formatAmount(defaultValue) || 'No'}</span>
    </div>
  );
};

export default InputRowChange;
