import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { ISort } from '../../../../redux/types';
import { getQueryParams } from '../../utils/getQueryParams';
import { IExpensesResponse } from './types/IExpensesResponse';
import { IConsultantExpenses } from '../ConsultantApi/types/IConsultantExpenses';
import { INewExpense } from './types/INewExpense';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { IResponse } from '../../types/IResponse';

class ExpensesApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/expenses`;

  fetchExpenses(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IExpensesResponse> | null> {
    return this.get<IGetListDto<IExpensesResponse>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  fetchExpenseById(id: string) {
    return this.get<IGetListDto<IExpensesResponse | null>>(`/${id}`, {})
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  newExpense(query: INewExpense): Promise<IResponse> {
    return this.post<INewExpense, IConsultantExpenses>('', query)
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }
  addExpensesNotes(expensesId: string, notes: string) {
    return this.put(`/${expensesId}/notes`, { notes });
  }
  deleteExpense(expenseId: string) {
    return this.delete(`/${expenseId}`);
  }
}

export const expensesApi = new ExpensesApi();
