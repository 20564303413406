import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { TOnboardingTabStatus } from '../../redux/reducers/onboarding/types';
import { OnboardingRequestStatuses } from '../api/services/OnboardingApi/enums/OnboardingRequestStatuses';

const {
  personalInformation,
  clientContract: clientContractPath,
  workContract: workContractPath,
  workPermit,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
} = ROUTER_PATHS;

export const getIsHideDraftBtn = (
  pathName: string,
  tabStatus: TOnboardingTabStatus,
): boolean =>
  (pathName.includes(personalInformation) &&
    (tabStatus[0] === OnboardingRequestStatuses.draft ||
      tabStatus[0] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(workContractPath) &&
    (tabStatus[1] === OnboardingRequestStatuses.draft ||
      tabStatus[1] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(clientContractPath) &&
    (tabStatus[2] === OnboardingRequestStatuses.draft ||
      tabStatus[2] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(workPermit) &&
    (tabStatus[3] === OnboardingRequestStatuses.draft ||
      tabStatus[3] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(bankAccount) &&
    (tabStatus[4] === OnboardingRequestStatuses.draft ||
      tabStatus[4] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(childAllowance) &&
    (tabStatus[5] === OnboardingRequestStatuses.draft ||
      tabStatus[5] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(avsAhv) &&
    (tabStatus[6] === OnboardingRequestStatuses.draft ||
      tabStatus[6] === OnboardingRequestStatuses.empty)) ||
  (pathName.includes(additionalInformation) &&
    (tabStatus[7] === OnboardingRequestStatuses.draft ||
      tabStatus[7] === OnboardingRequestStatuses.empty));
