import React, { useEffect, useState } from 'react';
import Dropdown from '../../dropdown/Dropdown';
import Button from '../../button/Button';
import { openErrorNotification } from '../../notification/Notification';
import { ISort } from '../../../redux/types';

interface IProps {
  filters: any;
  sortBy: any;
  onChangeMobileSort?: (sort: ISort) => void;
  setIsOpenSorting: (isOpen: boolean) => void;
}

const MobileSorting = (props: IProps) => {
  const { filters, sortBy, onChangeMobileSort, setIsOpenSorting } = props;
  const [selectedSort, setSelectedSort] = useState<ISort>({
    sortBy: '',
    sort: '',
  });

  useEffect(() => {
    if (sortBy) {
      setSelectedSort(sortBy);
    }
  }, []);

  const onSubmitSorting = () => {
    if (!selectedSort.sort || !selectedSort.sortBy) {
      openErrorNotification('Please select all values');
      return;
    }
    onChangeMobileSort && onChangeMobileSort(selectedSort);
    setIsOpenSorting(false);
  };

  const resetSorting = () => {
    onChangeMobileSort && onChangeMobileSort({ sort: '', sortBy: '' });
    setIsOpenSorting(false);
  };
  return (
    <div className="filters-mobile-container">
      <h4 className="filters-mobile-title">Sorting</h4>
      <div className="sort-mobile-container">
        <Dropdown
          label="Sort by"
          onChange={(value) =>
            setSelectedSort((prevState) => ({ ...prevState, sortBy: value }))
          }
          options={filters
            .filter((item: any) => item.sorter)
            .map((item: any) => {
              return {
                value: item.dataIndex,
                label: item.title,
              };
            })}
          value={selectedSort?.sortBy}
        />
        <Dropdown
          label="Sort"
          onChange={(value) =>
            setSelectedSort((prevState) => ({ ...prevState, sort: value }))
          }
          options={['descending', 'ascending']}
          value={selectedSort?.sort}
        />
      </div>
      <div className="mobile-filters-footer mobile-sorting-footer">
        <Button
          buttonSize="normal"
          buttonStyle="error"
          onClick={() => setIsOpenSorting(false)}>
          Close
        </Button>
        <Button buttonSize="normal" buttonStyle="error" onClick={resetSorting}>
          Reset
        </Button>
        <Button
          buttonSize="normal"
          buttonStyle="primary"
          onClick={onSubmitSorting}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MobileSorting;
