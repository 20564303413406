import { MonitoringStatuses } from '../api/services/MonitorngApi/enums/MonitoringStatuses';

export const renderEndOfDateContract = (
  startDate: number | null,
  endDate: number | null,
): string => {
  if (startDate === null || endDate === null) return 'Indefinite';

  if (startDate > 0) return 'Contract not started';
  //NOTE: if change name 'Contract finished' change on action menu too
  if (endDate < 0) return 'Contract finished';

  return endDate + ' days';
};

export const renderEndOfDatePermit = (
  startDate: number | null,
  endDate: number | null,
): string => {
  if (startDate === null || endDate === null) return 'No';

  if (startDate > 0) return 'Work permit not started';
  if (endDate < 0) return 'Work permit finished';

  return endDate + ' days';
};

export const renderStatusPermit = (
  endDate: number | null,
): MonitoringStatuses | 'No' => {
  if (!endDate) return MonitoringStatuses.optimal;

  if (endDate >= 40) {
    return MonitoringStatuses.optimal;
  }

  if (endDate < 14) {
    return MonitoringStatuses.action;
  }

  if (endDate < 40) {
    return MonitoringStatuses.follow;
  }

  return 'No';
};

export const renderStatusContract = (
  endDate: number | null,
): MonitoringStatuses | 'No' => {
  if (!endDate) return MonitoringStatuses.optimal;

  if (endDate > 32 && endDate < 40) {
    return MonitoringStatuses.follow;
  }

  if (endDate <= 32) {
    return MonitoringStatuses.action;
  }

  if (endDate >= 40) {
    return MonitoringStatuses.optimal;
  }

  return 'No';
};
