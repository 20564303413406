import React from 'react';
import { IMobileFilter } from '../../types';
import Input from '../../../input/Input';
import MagnifierIcon from '../../../../assets/icons/MagnifierIcon';
import { Checkbox } from 'antd';

interface IProps {
  filter: any;
  onChangeFilter: (value: string, key: string) => void;
}

const SelectFilter = (props: IProps) => {
  const { filter, onChangeFilter } = props;

  const selectValue = (value: string) => {
    const isIncludeValue = filter?.defaultFilter?.includes(value);
    onChangeFilter(
      isIncludeValue
        ? filter.defaultFilter.filter((item: any) => item !== value)
        : [...filter.defaultFilter, value],
      filter.dataIndex,
    );
  };
  return (
    <div className="icon-wrapper">
      <h4>{filter.title}</h4>
      <div className="select-mobile-filter">
        {filter.selectArray.map((item: any) => {
          return (
            <Checkbox
              name={item}
              key={item}
              value={item}
              onChange={(e) => selectValue(e.target.value)}
              checked={filter?.defaultFilter?.includes(item)}>
              {item[0].toUpperCase() + item.slice(1)}
            </Checkbox>
          );
        })}
      </div>
      <MagnifierIcon />
    </div>
  );
};

export default SelectFilter;
