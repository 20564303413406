import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const TrashIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.36709 4.82278C0.803817 4.07176 1.3397 3 2.27848 3H9.72152C10.6603 3 11.1962 4.07176 10.6329 4.82278V4.82278C10.2221 5.37056 10 6.0368 10 6.72152V11C10 12.4728 8.80609 13.6667 7.33333 13.6667H4.66667C3.19391 13.6667 2 12.4728 2 11V6.72152C2 6.0368 1.77792 5.37056 1.36709 4.82278V4.82278Z"
        stroke="#C43025"
        strokeWidth="1.2"
      />
      <path
        d="M7.33325 10.3335L7.33325 6.3335"
        stroke="#C43025"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 10.3335L4.66675 6.3335"
        stroke="#C43025"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66659 3L8.30382 1.9117C8.12233 1.36724 7.61281 1 7.03891 1H4.96093C4.38702 1 3.87751 1.36724 3.69602 1.9117L3.33325 3"
        stroke="#C43025"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TrashIcon;
