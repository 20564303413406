import { FC } from 'react';
import CommonTable from '../../../../components/common-table/CommonTable';
import { ColumnsType } from 'antd/es/table/interface';

const TimesheetsAndExpenses: FC = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'Number',
      dataIndex: 'treasury',
      key: 'treasury',
    },
    {
      title: 'H/D/M',
      dataIndex: 'advancedTiming',
      key: 'advancedTiming',
    },
    {
      title: 'StdT',
      dataIndex: 'billCurrency',
      key: 'billCurrency',
    },
    {
      title: 'OvT',
      dataIndex: 'billRateType',
      key: 'billRateType',
    },
    {
      title: 'DbT',
      dataIndex: 'billRate',
      key: 'billRate',
    },
    {
      title: 'Oth',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'Currency',
      dataIndex: 'billRateType',
      key: 'billRateType',
    },
    {
      title: 'Rate',
      dataIndex: 'billRate',
      key: 'billRate',
    },
    {
      title: 'Total',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'HP validated',
      dataIndex: 'billRate',
      key: 'billRate',
    },
    {
      title: 'Inovice #',
      dataIndex: 'salary',
      key: 'salary',
    },
  ];

  return (
    <div className="timesheets-and-expenses">
      <CommonTable data={[]} columns={columns} />
      <span>Expenses optimization</span>
      <CommonTable data={[]} columns={columns} />
    </div>
  );
};

export default TimesheetsAndExpenses;
