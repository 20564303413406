import { useState } from 'react';
import ExitIcon from '../../../assets/icons/ExitIcon';
import {
  IInvoice,
  invoiseStatusesArray,
} from '../../../common/api/services/InvoiceApi/types/types';
import Button from '../../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import '../GenerateInvoicePage.less';
import moment from '../../../common/constants/moment';
import { commonMonthTimeFormat } from '../../../common/constants/dateConstants';
import { viewIncoiceStatusParser } from '../../../common/constants/timesheetStatusParser';
import { TableColorStatusParser } from '../../../components/common-table/common/tableColorConstants';

interface IProps {
  invoice: IInvoice | null;
  saveAsDraft: (isHideNotification?: boolean) => void;
  onChangeStatus: (status: string, prevStatus: string | undefined) => void;
  onSendInvoice: () => void;
}
const { activityReport, activityReportInfo } = ROUTER_PATHS;

const GenerateInvoiceHeader = (props: IProps) => {
  const { invoice, saveAsDraft, onChangeStatus, onSendInvoice } = props;
  const navigate = useNavigate();
  const [isOpenExit, setIsOpenExit] = useState(false);

  const onExit = () => {
    navigate(activityReport);
  };

  const onOpenAR = () => {
    const newUrlParams = new URLSearchParams();
    if (invoice?.origins) {
      invoice?.origins.forEach((item) => {
        newUrlParams.append('projectId', item.projectId);
        newUrlParams.append('consultantId', item.consultantId);
        newUrlParams.append('clientId', item.clientId);
        newUrlParams.append('period', item.period);
      });

      const newUrl = `${activityReportInfo}?${newUrlParams.toString()}`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <div className="generate-header">
      <div>
        <div className="generate-header-left">
          <h4 style={{ width: '55%' }}>Invoice #{invoice?.publicId || ''}</h4>
          <Select
            value={invoice?.status || 'Draft'}
            className={`invoice-select-status invoice-select-status-${
              viewIncoiceStatusParser[invoice?.status || '']
            }`}
            dropdownStyle={{ padding: 0, borderRadius: '5px' }}
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            style={{ marginBottom: 0 }}
            onChange={(value) => onChangeStatus(value, invoice?.status)}>
            {invoiseStatusesArray.map((item) => (
              <Select.Option
                key={item}
                className={TableColorStatusParser[item]}>
                <span>{item}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
        {invoice?.lastModification && (
          <span className="generate-header-left-bottom">
            <span style={{ fontWeight: 400 }}>Last modification:</span>{' '}
            {invoice?.lastModification?.eventType || ''} at{' '}
            {invoice?.lastModification?.timestamp
              ? moment(invoice?.lastModification?.timestamp).format(
                  commonMonthTimeFormat,
                )
              : ''}{' '}
            by {invoice?.lastModification?.user?.firstName || ''}{' '}
            {invoice?.lastModification?.user?.lastName || ''}
          </span>
        )}
      </div>
      <div className="generate-header-right">
        <div
          className="generate-header-exit"
          onClick={() => setIsOpenExit(true)}>
          <ExitIcon className="export-icon" /> <span>Exit</span>
        </div>

        <Button
          buttonWidth="100%"
          buttonSize="normal"
          buttonStyle="secondary"
          onClick={onOpenAR}>
          Open AR
        </Button>
        <Button
          buttonWidth="100%"
          buttonSize="normal"
          buttonStyle="secondary"
          onClick={() => saveAsDraft(false)}>
          Save as draft
        </Button>
        <Button
          buttonWidth="100%"
          buttonSize="normal"
          buttonStyle="primary"
          onClick={onSendInvoice}>
          Send
        </Button>
      </div>
      {isOpenExit && (
        <AttentionModal
          cancelBtnTitle="Return"
          closeModal={() => setIsOpenExit(false)}
          confirmAction={onExit}
          confirmBtnTitle="Yes, exit"
          isOpen={isOpenExit}
          content={
            <span>
              Are you sure you want to exit? Changes you have made will not be
              saved.
            </span>
          }
          hideCloseBtn
          isHideAttentionText
        />
      )}
    </div>
  );
};

export default GenerateInvoiceHeader;
