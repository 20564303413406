import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { ExpensesType } from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { IConsultantExpenses } from '../../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import { expensesType } from '../../../common/utils/mockExpensesType';
import Checkbox from '../../../components/checkbox/Checkbox';

import './ModalExpense.less';
import { useAppSelector } from '../../../redux/store';
import { Roles } from '../../../common/enums/Roles';
import PreviewImage from '../../../components/file-preview/preview-image/PreviewImage';
import ExcelPreview from '../../../components/file-preview/excel-preview/ExcelPreview';
import { EyeOutlined } from '@ant-design/icons';
import {
  isDocFormat,
  isImgFormat,
} from '../../../common/utils/checkFileFormat';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  selectedExpense: IConsultantExpenses;
}

const ModalViewExpense: FC<IProps> = (props) => {
  const { selectedExpense } = props;
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;
  const {
    workContract,
    formType,
    type,
    isSigned,
    approvalRequired,
    period,
    total,
    documents,
    companyRepresentativeEmail,
    representativeFirstName,
    representativeLastName,
    comments,
    numeriqComments,
    visibility,
    consultant,
  } = selectedExpense;

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [previewId, setPreviewId] = useState<string | null>(null);

  const openPreview = (type: 'docs' | 'image', id: string) => {
    setPreviewType(type);
    setIsOpenPreview(true);
    setPreviewId(id);
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setPreviewType('');
    setPreviewId(null);
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <p className="info-title">{`Consultant's ID`}</p>
          <p className="info-content">{consultant?.publicId || ''}</p>
        </Col>
        <Col span={8}>
          <p className="info-title">{`Consultant's name`}</p>
          <p className="info-content">{consultant?.firstName || ''}</p>
        </Col>
        <Col span={8}>
          <p className="info-title">{`Consultant's last name`}</p>
          <p className="info-content">{consultant?.lastName || ''}</p>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p className="info-title">Expenses form</p>
          <p className="info-content">{formType}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Type of expense</p>
          <p className="info-content">{expensesType[type]}</p>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p className="info-title">Client name</p>
          <p className="info-content">
            {type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
              ? workContract?.name || ''
              : 'Numeriq'}
          </p>
        </Col>
        <Col span={16}>
          <p className="info-title">Period</p>
          <p className="info-content">{getFormatDateWithMonth(period)}</p>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <p className="info-title">Total sum</p>
          <p className="info-content">{total}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Currency</p>
          <p className="info-content">
            {type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
            type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
              ? 'CHF'
              : workContract?.invoiceCurrency || ''}
          </p>
        </Col>
      </Row>
      {isAdmin && (
        <Row>
          <Col span={8}>
            <p className="info-title">Visibility</p>
            <p className="info-content">{visibility || 'public'}</p>
          </Col>
        </Row>
      )}

      {isSigned && (
        <Row justify="space-between">
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              label="My expenses has been Signed/Approved."
              checked
              disabled
            />
          </Col>
        </Row>
      )}
      {approvalRequired && type !== ExpensesType.EXPENSES_FOR_OPTIMISATION && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              checked
              label="My expenses requires manager's online approval."
              disabled
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p className="info-title">Attached file</p>
          <p className="info-content documents">
            {documents.map(({ originalFilename }) => (
              <span key={originalFilename}>
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    fileApi.downloadFileByUrl(originalFilename);
                  }}>
                  {originalFilename}
                </a>
                {isImgFormat(originalFilename) && (
                  <span
                    onClick={() => openPreview('image', originalFilename)}
                    style={{ marginLeft: '1rem' }}>
                    <EyeOutlined className="preview-icon" />
                  </span>
                )}
                {isDocFormat(originalFilename) && (
                  <span
                    onClick={() => openPreview('docs', originalFilename)}
                    style={{ marginLeft: '1rem' }}>
                    <EyeOutlined className="preview-icon" />
                  </span>
                )}
              </span>
            ))}
          </p>
        </Col>
      </Row>

      {type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT && approvalRequired && (
        <>
          <Row>
            <Col span={24}>
              <p className="info-title">Client representative email</p>
              <p className="info-content">{companyRepresentativeEmail}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <p className="info-title">Representative First Name</p>
              <p className="info-content">{representativeFirstName}</p>
            </Col>
            <Col span={14}>
              <p className="info-title">Representative Last Name</p>
              <p className="info-content">{representativeLastName}</p>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className="info-title">Comments for the Client</p>
              <p className="info-content">{comments || '-'}</p>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p className="info-title">Comments for Numeriq</p>
          <p className="info-content">{numeriqComments || '-'}</p>
        </Col>
      </Row>
      {isOpenPreview && previewType === 'image' && previewId && (
        <PreviewImage
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
      {isOpenPreview && previewType === 'docs' && previewId && (
        <ExcelPreview
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
    </>
  );
};

export default ModalViewExpense;
