import { FormInstance, Tabs } from 'antd';
import DetailsContent from './tabsContent/DetailsContent';
import AttachmentsContent from './tabsContent/AttachmentsContent';
import PreviewContent from './tabsContent/PreviewContent';
import '../GenerateInvoicePage.less';
import TrashIcon from '../../../assets/icons/TrashIcon';
import {
  IInvoice,
  InvoiceLayout,
  InvoiseStatuses,
} from '../../../common/api/services/InvoiceApi/types/types';
import { useState } from 'react';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import { DeleteInvoice } from '../../../components/common-texts/CommonTexts';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { IClient } from '../../../common/api/services/OnboardingApi/types/IClientContract';
import { IVATs } from '../../../common/api/services/SystemInfoApi/types/ISystemInfo';

interface IProps {
  invoice: IInvoice | null;
  form: FormInstance<any>;
  errorMessages: any;
  client: IClient | null;
  onChangeLayout: (value: InvoiceLayout) => void;
  onChangeInvoiceDate: (value: string) => void;
  setInvoice: any;
  saveAsDraft: () => void;
  vats: IVATs;
  activeKey: string;
  setActiveKey: (key: string) => void;
}
const { activityReport } = ROUTER_PATHS;

const ContentWrapper = (props: IProps) => {
  const {
    invoice,
    form,
    errorMessages,
    client,
    onChangeLayout,
    onChangeInvoiceDate,
    setInvoice,
    saveAsDraft,
    vats,
    activeKey,
    setActiveKey,
  } = props;
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleTabChange = (key: string) => {
    if (
      key === '3' &&
      activeKey !== '3' &&
      invoice?.status === InvoiseStatuses.DRAFT
    ) {
      saveAsDraft();
    }
    setActiveKey(key);
  };

  const onDeleteInvoice = () => {
    if (invoice) {
      invoiceApi
        .deleteInvoice(invoice?._id)
        .then(() => {
          openSuccessNotification('Invoice was deleted successfully');
          navigate(activityReport);
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Failed to delete invoice',
          );
        });
    }
  };

  const tabs = (
    <Tabs
      tabBarExtraContent={
        <div
          className="generate-delete-icon"
          onClick={() => setIsOpenDeleteModal(true)}>
          <TrashIcon />
          <span>Delete</span>
        </div>
      }
      onChange={handleTabChange}
      activeKey={activeKey}
      defaultActiveKey="1"
      items={[
        {
          label: `Details`,
          key: '1',
          children: (
            <DetailsContent
              invoice={invoice}
              form={form}
              errorMessages={errorMessages}
              client={client}
              onChangeLayout={onChangeLayout}
              onChangeInvoiceDate={onChangeInvoiceDate}
              setInvoice={setInvoice}
              saveAsDraft={saveAsDraft}
              vats={vats}
            />
          ),
        },
        {
          label: `Attachments`,
          key: '2',
          children: (
            <AttachmentsContent
              publicId={invoice?.publicId}
              activeKey={activeKey}
            />
          ),
        },
        {
          label: `Preview`,
          key: '3',
          children: <PreviewContent activeKey={activeKey} />,
        },
      ]}
    />
  );
  return (
    <div className="generate-tabs-wrapper">
      {tabs}
      {isOpenDeleteModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={() => setIsOpenDeleteModal(false)}
          confirmAction={onDeleteInvoice}
          confirmBtnTitle="Yes, delete"
          isOpen={isOpenDeleteModal}
          isHideAttentionText
          content={<DeleteInvoice invoiceNumber={invoice?.publicId || ''} />}
        />
      )}
    </div>
  );
};

export default ContentWrapper;
