import { Col, Modal, Row } from 'antd';
import Button from '../../../components/button/Button';
import { useState } from 'react';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onSubmit: (date: string) => void;
}

const MultipleDateModal = (props: IProps) => {
  const { isOpen, onCloseModal, onSubmit } = props;
  const [selectedDate, setSelectedDate] = useState('');
  const onChangeDate = (date: any) => {
    setSelectedDate(date);
  };
  return (
    <Modal
      open={isOpen}
      onCancel={onCloseModal}
      footer={null}
      centered
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <h4>Set up payment date</h4>
        <Row gutter={[16, 16]} style={{ height: '40px' }}>
          <Col span={24}>
            <CommonDatePicker
              changeDate={onChangeDate}
              defaultValue={selectedDate}
              className="date-picker-form multiple-invoice-picker"
            />
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            buttonSize="medium"
            buttonStyle="error"
            buttonWidth={200}
            onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            buttonWidth={200}
            disable={!selectedDate}
            onClick={() => onSubmit(selectedDate)}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MultipleDateModal;
