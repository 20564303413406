import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setCurrentClientOnboardingContract,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
  setOnboardingWorkContract,
  setIsPublishedBeforeNewClient,
  setIsHasDeletedClient,
  setSelectedDublicateIdx,
  setWorkContract,
  setClientContract,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { checkWorkContract } from '../../../../common/utils/validateRequiredFields';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IOnboardingSaveOptions, IWorkContract } from './../types';
import { getOnboardingProfile } from '../actionAsyncThunk';
import { compareEndDate } from '../../../../common/utils/compareEndDate';
import {
  applyClientOrder,
  convertSelectedIdx,
} from '../../../../common/utils/onboardingSortHelper';

export const saveDraftWorkContractAsEmployee = createAsyncThunk(
  'onboarding/saveDraftWorkContract',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      tabStatus,
      consultantId,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[1] === OnboardingRequestStatuses.draft ||
      tabStatus[1] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    if (isDraft) {
      const workContract = checkWorkContract(workContractFromState);
      const dataQuery: IQueryParameters<IWorkContract> = {
        key: OnboardingRequestKeys.workContract,
        status: OnboardingRequestStatuses.draft,
        type: OnboardingRequestTypes.required,
        // data: workContract,
        data: {
          ...workContract,
          companies: applyClientOrder(
            workContract.companies,
            initialWorkContractOrder,
          ),
        },
      };

      const { isSuccess, data } = await onboardingApi.putWorkContractAsEmployee(
        consultantId,
        dataQuery,
      );

      if (isSuccess) {
        dispatch(
          setWorkContract(
            data.consultantOnboarding.workContract.companies
              .slice()
              .sort(compareEndDate),
          ),
        );
        dispatch(
          setClientContract(
            data.consultantOnboarding.clientContract.clients
              .slice()
              .sort(compareEndDate),
          ),
        );
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 1, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 1,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workContract',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveDraftWorkContractAsConsultant = createAsyncThunk(
  'onboarding/saveDraftWorkContractAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      tabStatus,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[1] === OnboardingRequestStatuses.draft ||
      tabStatus[1] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    if (isDraft) {
      const workContract = checkWorkContract(workContractFromState);
      const dataQuery: IQueryParameters<IWorkContract> = {
        key: OnboardingRequestKeys.workContract,
        status: OnboardingRequestStatuses.draft,
        type: OnboardingRequestTypes.required,
        data: {
          ...workContract,
          companies: applyClientOrder(
            workContract.companies,
            initialWorkContractOrder,
          ),
        },
      };

      const { isSuccess, data } =
        await onboardingApi.putWorkContractAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(
                tabStatus,
                1,
                redirectRootRout,
                false,
                true,
              ),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 1,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workContract',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const dublicateClientWorkContractAsEmployee = createAsyncThunk(
  'onboarding/dublicateClientWorkContractAsEmployee',
  async (_, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      consultantId,
      tabStatus,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;
    const { selectedDublicateIdx, companies } = workContractFromState;
    const workContract = checkWorkContract(workContractFromState);
    const dataQuery: IQueryParameters<IWorkContract> = {
      key: OnboardingRequestKeys.workContract,
      status:
        tabStatus[1] === OnboardingRequestStatuses.draft ||
        tabStatus[1] === OnboardingRequestStatuses.empty
          ? OnboardingRequestStatuses.draft
          : OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      // data: workContract,
      data: {
        ...workContract,
        companies: applyClientOrder(
          workContract.companies,
          initialWorkContractOrder,
        ),
      },
    };
    const convertedSelectedIdx = convertSelectedIdx(
      companies,
      initialWorkContractOrder,
      selectedDublicateIdx,
    );
    const { isSuccess, data } = await onboardingApi.putWorkContractAsEmployee(
      consultantId,
      dataQuery,
    );

    if (
      isSuccess &&
      data?.consultantOnboarding?.workContract?.tab?.status === 'published'
    ) {
      dispatch(setIsPublishedBeforeNewClient(true));
    }
    if (isSuccess) {
      const { consultantId } = (getState() as IRootState).onboarding;
      const { isSuccess, data } =
        await onboardingApi.addClientWorkContractAsEmployee(consultantId);
      if (isSuccess) {
        data.consultantOnboarding.workContract.companies[
          data.consultantOnboarding.workContract.companies.length - 1
        ] = {
          ...data.consultantOnboarding.workContract.companies[
            convertedSelectedIdx
          ],
          _id: data.consultantOnboarding.workContract.companies[
            data.consultantOnboarding.workContract.companies.length - 1
          ]._id,
          rate: 0,
          startDate: '',
          endDate: '',
        };
        data.consultantOnboarding.clientContract.clients[
          data.consultantOnboarding.clientContract.clients.length - 1
        ] = {
          ...data.consultantOnboarding.clientContract.clients[
            convertedSelectedIdx
          ],
          _id: data.consultantOnboarding.clientContract.clients[
            data.consultantOnboarding.clientContract.clients.length - 1
          ]._id,
          billRate: '',
          rate: 0,
          startDate: '',
          endDate: '',
        };
        dispatch(setSelectedDublicateIdx(0));
        dispatch(setOnboardingWorkContract({ ...data, isNewClient: true }));
        dispatch(
          setOnboardingTabStatus({
            key: 1,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workContract',
            fieldValue: data,
          }),
        );
      } else {
        // TODO handle error
        console.log('Error: addClientWorkContract - ', data);
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workContract',
          fieldValue: data,
        }),
      );
    }
  },
);
export const addClientWorkContractAsEmployee = createAsyncThunk(
  'onboarding/addClientWorkContractAsEmployee',
  async (_, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      consultantId,
      tabStatus,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;

    const workContract = checkWorkContract(workContractFromState);
    const dataQuery: IQueryParameters<IWorkContract> = {
      key: OnboardingRequestKeys.workContract,
      status:
        tabStatus[1] === OnboardingRequestStatuses.draft ||
        tabStatus[1] === OnboardingRequestStatuses.empty
          ? OnboardingRequestStatuses.draft
          : OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      // data: workContract,
      data: {
        ...workContract,
        companies: applyClientOrder(
          workContract.companies,
          initialWorkContractOrder,
        ),
      },
    };

    const { isSuccess, data } = await onboardingApi.putWorkContractAsEmployee(
      consultantId,
      dataQuery,
    );

    if (
      isSuccess &&
      data?.consultantOnboarding?.workContract?.tab?.status === 'published'
    ) {
      dispatch(setIsPublishedBeforeNewClient(true));
    }
    if (isSuccess) {
      const { consultantId } = (getState() as IRootState).onboarding;
      const { isSuccess, data } =
        await onboardingApi.addClientWorkContractAsEmployee(consultantId);

      if (isSuccess) {
        dispatch(setOnboardingWorkContract({ ...data, isNewClient: true }));
        dispatch(
          setOnboardingTabStatus({
            key: 1,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workContract',
            fieldValue: data,
          }),
        );
      } else {
        // TODO handle error
        console.log('Error: addClientWorkContract - ', data);
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workContract',
          fieldValue: data,
        }),
      );
    }
  },
);

export const addClientWorkContractAsConsultant = createAsyncThunk(
  'onboarding/addClientWorkContractAsConsultant',
  async (_, { getState, dispatch }) => {
    const { workContract: workContractFromState } = (getState() as IRootState)
      .onboarding;

    const workContract = checkWorkContract(workContractFromState);
    const dataQuery: IQueryParameters<IWorkContract> = {
      key: OnboardingRequestKeys.workContract,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: workContract,
    };

    const { isSuccess, data } = await onboardingApi.putWorkContractAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    if (isSuccess) {
      const { isSuccess, data } =
        await onboardingApi.addClientWorkContractAsConsultant();
      if (isSuccess) {
        dispatch(setOnboardingWorkContract({ ...data, isNewClient: true }));
        dispatch(
          setOnboardingTabStatus({
            key: 1,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(saveDraftWorkContractAsConsultant({}));
      } else {
        // TODO handle error
        console.log('Error: addClientWorkContract - ', data);
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workContract',
          fieldValue: data,
        }),
      );
    }
  },
);

export const deleteClientWorkContractAsEmployee = createAsyncThunk(
  'onboarding/deleteClientWorkContract',
  async (_, { getState, dispatch }) => {
    const { consultantId } = (getState() as IRootState).onboarding;
    const selectedClientIdx = (getState() as IRootState).onboarding.workContract
      .selectedClientIdx;
    const companyId = (getState() as IRootState).onboarding.workContract
      .companies[selectedClientIdx]._id;
    const tabStatus = (getState() as IRootState).onboarding.tabStatus;
    const workContractCompanies = (getState() as IRootState).onboarding
      .workContract.companies;

    const { isSuccess, data } =
      await onboardingApi.deleteClientWorkContractAsEmployee(
        consultantId,
        companyId,
      );

    if (isSuccess && tabStatus[1] === 'published') {
      dispatch(setIsHasDeletedClient(true));
    }
    if (isSuccess) {
      dispatch(
        setCurrentClientOnboardingContract(
          selectedClientIdx === 0 ? 0 : selectedClientIdx - 1,
        ),
      );
      const companies = workContractCompanies.filter(
        (c, index) => index !== selectedClientIdx,
      );
      data.consultantOnboarding.workContract.companies = companies;
      dispatch(setOnboardingWorkContract(data));
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.draft,
        }),
      );

      dispatch(saveDraftWorkContractAsEmployee({}));
    } else {
      // TODO handle error
      console.log('Error: deleteClientWorkContract - ', data);
    }
  },
);

export const deleteClientWorkContractAsConsultant = createAsyncThunk(
  'onboarding/deleteClientWorkContractAsConsultant',
  async (_, { getState, dispatch }) => {
    const selectedClientIdx = (getState() as IRootState).onboarding.workContract
      .selectedClientIdx;
    const companyId = (getState() as IRootState).onboarding.workContract
      .companies[selectedClientIdx]._id;

    const { isSuccess, data } =
      await onboardingApi.deleteClientWorkContractAsConsultant(companyId);

    if (isSuccess) {
      dispatch(
        setCurrentClientOnboardingContract(
          selectedClientIdx === 0 ? 0 : selectedClientIdx - 1,
        ),
      );
      dispatch(setOnboardingWorkContract(data));
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.draft,
        }),
      );

      dispatch(saveDraftWorkContractAsConsultant({}));
    } else {
      // TODO handle error
      console.log('Error: deleteClientWorkContract - ', data);
    }
  },
);

export const savePublishWorkContractAsEmployee = createAsyncThunk(
  'onboarding/savePublishWorkContract',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      tabStatus,
      consultantId,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;

    const workContract = checkWorkContract(workContractFromState);

    const dataQuery: IQueryParameters<IWorkContract> = {
      key: OnboardingRequestKeys.workContract,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      // data: workContract,
      data: {
        ...workContract,
        companies: applyClientOrder(
          workContract.companies,
          initialWorkContractOrder,
        ),
      },
    };

    const { isSuccess, data } = await onboardingApi.putWorkContractAsEmployee(
      consultantId,
      dataQuery,
    );
    if (isSuccess) {
      dispatch(setOnboardingWorkContract(data));
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.published,
          isAdmin: true,
        }),
      );
      const newStatus = [...tabStatus];
      if (
        newStatus[2] !== data.consultantOnboarding.clientContract.tab.status
      ) {
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: data.consultantOnboarding.clientContract.tab.status,
            isAdmin: true,
          }),
        );
        newStatus[2] = data.consultantOnboarding.clientContract.tab.status;
      }
      dispatch(push(getOnboardingRedirect(newStatus, 1, redirectRootRout)));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workContract',
          // Backend returns some errors as text, not as object
          fieldValue: typeof data === 'string' ? { [`${data}`]: data } : data,
        }),
      );
    }
  },
);

export const savePublishWorkContractAsConsultant = createAsyncThunk(
  'onboarding/savePublishWorkContractAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const {
      workContract: workContractFromState,
      tabStatus,
      initialWorkContractOrder,
    } = (getState() as IRootState).onboarding;

    const workContract = checkWorkContract(workContractFromState);

    const dataQuery: IQueryParameters<IWorkContract> = {
      key: OnboardingRequestKeys.workContract,
      status: OnboardingRequestStatuses.toVerify,
      type: OnboardingRequestTypes.required,
      data: {
        ...workContract,
        companies: applyClientOrder(
          workContract.companies,
          initialWorkContractOrder,
        ),
      },
    };

    const { isSuccess, data } = await onboardingApi.putWorkContractAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.toVerify,
        }),
      );
      dispatch(
        push(
          getOnboardingRedirect(tabStatus, 1, redirectRootRout, false, true),
        ),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workContract',
          fieldValue: data,
        }),
      );
    }
  },
);
