export interface IFXRate {
  _id: string;
  currencyPair: string;
  rate: number;
  period: string;
  createdAt: string;
  updatedAt: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  };
}

export interface IVAT {
  _id: string;
  name: string;
  percentage: number;
  isEditable: true;
  period: string;
  createdAt: string;
  updatedAt: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  };
}

export type IFXRates = Array<IFXRate>;

export type IVATs = Array<IVAT>;

export enum FXRateType {
  GBP = 'GBP/CHF',
  EUR = 'EUR/CHF',
  USD = 'USD/CHF',
}

export enum VATType {
  Exempt = 'Exempt',
  Exportation = 'Exportation',
  Formation = 'Formation',
  SwissVAT = 'Swiss VAT',
}

export interface IBankAccount {
  name: string;
  credit: string;
  iban: string;
  swift: string;
  currency: string;
  _id: string;
}

export type BankAccountsType = Array<IBankAccount>;
