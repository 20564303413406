import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { ISort } from '../../../../redux/types';
import { ConsultantRoute } from './enums/ConsultantRoute';
import { IConsultantTimesheet } from './types/IConsultantTimesheet';
import { putResponse } from './types/putResponse';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { IConsultantExpenses } from './types/IConsultantExpenses';
import { getQueryParams } from '../../utils/getQueryParams';
import { IConsultantsResponse } from './types/IConsultantsResponse';
import { IGetConsultantInfoResponse } from '../OnboardingApi/types/IGetConsultantInfoResponse';
import { ICreateConsultantRequest } from './types/ICreateConsultantRequest';
import {
  ICreateConsultantFailedResponse,
  ICreateConsultantSuccessResponse,
} from './types/ICreateConsultantResponse';
import { downloadExel, downloadFileType } from '../../utils/downloadExel';
import moment from 'moment';

class ConsultantApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/consultant`;

  getConsultants(
    currentPage: number,
    perPage: number,
    filter: Record<string, string[] | string | boolean>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<IConsultantsResponse>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  getConsultantTimesheet(
    currentPage: number,
    perPage: number,
    filter: Record<string, string>,
    sort: ISort,
  ): Promise<IGetListDto<IConsultantTimesheet> | null> {
    return this.get<IGetListDto<IConsultantTimesheet>>(
      `/${ConsultantRoute.timesheet}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  getConsultantTimesheetByConsultantId(
    consultantId: string,
    currentPage: number,
    perPage: number,
    filter: Record<string, string>,
    sort: ISort,
  ): Promise<IGetListDto<IConsultantTimesheet> | null> {
    return this.get<IGetListDto<IConsultantTimesheet>>(
      `/${consultantId}/${ConsultantRoute.timesheet}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  putTimesheet(
    timesheetId: string,
    query: IConsultantTimesheet,
  ): Promise<putResponse> {
    return this.put<IConsultantTimesheet, IConsultantTimesheet>(
      `/${ConsultantRoute.timesheet}/${timesheetId}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putInternalTimesheet(
    timesheetId: string,
    consultantId: string,
    query: any,
  ): Promise<putResponse> {
    return this.put<IConsultantTimesheet, IConsultantTimesheet>(
      `/${consultantId}/${ConsultantRoute.timesheet}/${timesheetId}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  getConsultantExpenses(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<any> | null> {
    return this.get<IGetListDto<IConsultantExpenses>>(
      `/${ConsultantRoute.expenses}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  getConsultantExpensesByConsultantId(
    consultantId: string,
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<any> | null> {
    return this.get<IGetListDto<IConsultantExpenses>>(
      `/${consultantId}/${ConsultantRoute.expenses}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  getConsultantExpensesByStatusDraft(): Promise<IGetListDto<any> | null> {
    return this.get<IGetListDto<IConsultantExpenses>>(
      `/${ConsultantRoute.expenses}`,
      {
        query: { status: 'Draft' },
      },
    )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  putExpense(
    expenseId: string,
    query: IConsultantExpenses,
  ): Promise<putResponse> {
    return this.put<IConsultantExpenses, IConsultantExpenses>(
      `/${ConsultantRoute.expenses}/${expenseId}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putInternalExpense(
    expenseId: string,
    consultantId: string,
    query: any,
  ): Promise<putResponse> {
    return this.put<IConsultantExpenses, IConsultantExpenses>(
      `/${consultantId}/${ConsultantRoute.expenses}/${expenseId}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  getConsultantByConsultantId(
    consultantId: string,
  ): Promise<IGetConsultantInfoResponse | null> {
    return this.get<IGetConsultantInfoResponse>(`/${consultantId}`)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  createConsultant(
    data: ICreateConsultantRequest,
  ): Promise<
    ICreateConsultantFailedResponse | ICreateConsultantSuccessResponse
  > {
    return this.post<ICreateConsultantRequest, any>('', data)
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  approveAdminConsultantByConsultantId(
    consultantId: string,
  ): Promise<IConsultantsResponse | null> {
    return this.put<any, IConsultantsResponse>(
      `/${consultantId}/${ConsultantRoute.onboarding}/${ConsultantRoute.approveAdmin}`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  approveFinanceConsultantByConsultantId(
    consultantId: string,
  ): Promise<IConsultantsResponse | null> {
    return this.put<any, IConsultantsResponse>(
      `/${consultantId}/${ConsultantRoute.onboarding}/${ConsultantRoute.approveFinance}`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  inviteConsultant(consultantId: string) {
    return this.put(`/${consultantId}/${ConsultantRoute.invite}`);
  }

  fetchConsultantNoExpenses(
    timesheetId: string,
  ): Promise<IConsultantsResponse | null> {
    return this.put<any, IConsultantsResponse>(
      `/${ConsultantRoute.timesheet}/${timesheetId}/${ConsultantRoute.noExpenses}`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  exportToExel(
    filter: Record<string, string | boolean | string[]>,
    sort: ISort,
    selectedIds: string[],
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    return this.get<any>(`/${ConsultantRoute.export}`, {
      query,
      responseType: 'blob',
    })
      .then((response) => downloadExel(response))
      .catch(() => null);
  }
  downloadCSVActivityReport(ids: Array<string | number>): Promise<any> {
    const uniqueIds = Array.from(new Set(ids));
    return this.get<any>(
      `/csv/in-out?publicIds=${uniqueIds.map((id) => `${id}`)}`,
      {
        responseType: 'blob',
      },
    )
      .then((response) => {
        downloadFileType(
          response,
          `In_Out_${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
          'csv',
        );
      })
      .catch(() => null);
  }
  downloadCSVEmployee(
    ids: Array<string | number>,
    companyIds: Array<string>,
  ): Promise<any> {
    const uniqueIds = Array.from(new Set(ids));
    return this.get<any>(
      `/csv/employee?publicIds=${uniqueIds.map((id) => `${id}`)}`,
      // `/csv/employee?selectedIds=${ids.map(
      //   (id) => `${id}`,
      // )}&companyIds=${companyIds.map((id) => `${id}`)}`,
      {
        responseType: 'blob',
      },
    ).then((response) =>
      downloadFileType(
        response,
        `Employee_${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
        'csv',
      ),
    );
  }
  downloadCSVChildren(ids: Array<string | number>): Promise<any> {
    const uniqueIds = Array.from(new Set(ids));
    return this.get<any>(
      `/csv/children?publicIds=${uniqueIds.map((id) => `${id}`)}`,
      {
        responseType: 'blob',
      },
    )
      .then((response) =>
        downloadFileType(
          response,
          `Children_${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
          'csv',
        ),
      )
      .catch(() => null);
  }
  addComment(consultantId: string, body: { source: string; comment: string }) {
    return this.post(`/${consultantId}/comments`, body)
      .then((response) => response)
      .catch(() => null);
  }
  getComments(consultantId: string) {
    return this.get(`/${consultantId}/comments`)
      .then((response) => response)
      .catch(() => null);
  }
  deleteComment(consultantId: string, commentId: string) {
    return this.delete(`/${consultantId}/comments/${commentId}`);
  }
  updateComment(
    consultantId: string,
    commentId: string,
    status?: string,
    comment?: string,
  ) {
    const body: { status?: string; comment?: string } = {};
    if (status) {
      body.status = status;
    }
    if (comment) {
      body.comment = comment;
    }
    return this.put(`/${consultantId}/comments/${commentId}`, body)
      .then((response) => response)
      .catch(() => null);
  }
}

export const consultantApi = new ConsultantApi();
