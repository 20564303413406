import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  clearOnboardingErrorsValidation,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { ROUTER_PATHS } from '../../../../navigation/routerPaths';
import { push } from 'redux-first-history';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IBankAccountRequest } from '../../../../common/api/services/OnboardingApi/types/IBankAccountRequest';
import { ISsnInformationRequest } from '../../../../common/api/services/OnboardingApi/types/IAvsAhvRequest';
import { IWorkPermit } from './../types';
import { getStringToIBAN } from '../../../../common/utils/getStringToIBAN';
import { getOnboardingProfile } from '../actionAsyncThunk';

const { workPermit, bankAccount, avsAhv, myInformation } = ROUTER_PATHS;

export const saveAsPublishMyInformation = createAsyncThunk(
  'onboarding/saveAsPublishMyInformation',
  async (_, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName = state.router.location?.pathname ?? '';

    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(workPermit)) {
      dispatch(savePublishMyInformationWorkPermit());
    }

    if (pathName.includes(bankAccount)) {
      dispatch(savePublishMyInformationBankAccount());
    }

    if (pathName.includes(avsAhv)) {
      dispatch(savePublishMyInformationAvsAhv());
    }
  },
);

export const savePublishMyInformationWorkPermit = createAsyncThunk(
  'onboarding/savePublishMyInformationWorkPermit',
  async (_, { getState, dispatch }) => {
    const { workPermit, tabStatus } = (getState() as IRootState).onboarding;
    const requestStatus = workPermit.hasWorkPermit
      ? OnboardingRequestStatuses.published
      : OnboardingRequestStatuses.toConfirm;

    const requestType = workPermit.hasWorkPermit
      ? OnboardingRequestTypes.required
      : OnboardingRequestTypes.optional;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: requestStatus,
      type: requestType,
      data: workPermit,
    };

    const { isSuccess, data } = await onboardingApi.putWorkPermitAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: workPermit.hasWorkPermit
            ? OnboardingRequestStatuses.published
            : OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(
        push(getOnboardingRedirect(tabStatus, 3, myInformation, false, true)),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workPermit',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishMyInformationBankAccount = createAsyncThunk(
  'onboarding/savePublishMyInformationBankAccount',
  async (_, { getState, dispatch }) => {
    const { bankAccount, tabStatus } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: bankAccount.hasBankAccount
        ? OnboardingRequestStatuses.published
        : OnboardingRequestStatuses.toConfirm,
      type: bankAccount.hasBankAccount
        ? OnboardingRequestTypes.required
        : OnboardingRequestTypes.optional,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    const { isSuccess, data } = await onboardingApi.putBankAccountAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    if (isSuccess) {
      dispatch(
        push(getOnboardingRedirect(tabStatus, 4, myInformation, false, true)),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: bankAccount.hasBankAccount
            ? OnboardingRequestStatuses.published
            : OnboardingRequestStatuses.toConfirm,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'bankAccount',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishMyInformationAvsAhv = createAsyncThunk(
  'onboarding/savePublishMyInformationAvsAhv',
  async (_, { getState, dispatch }) => {
    const { ssnInformation, tabStatus } = (getState() as IRootState).onboarding;
    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status: ssnInformation.hasSSN
        ? OnboardingRequestStatuses.published
        : OnboardingRequestStatuses.toConfirm,
      type: ssnInformation.hasSSN
        ? OnboardingRequestTypes.required
        : OnboardingRequestTypes.optional,
      data: ssnInformation,
    };

    const { isSuccess, data } = await onboardingApi.putAvsAhvAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: ssnInformation.hasSSN
            ? OnboardingRequestStatuses.published
            : OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(
        push(getOnboardingRedirect(tabStatus, 6, myInformation, false, true)),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'ssnInformation',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveAsDraftMyInformation = createAsyncThunk(
  'onboarding/saveAsDraftMyInformation',
  async (_, { dispatch, getState }) => {
    const pathName = (getState() as IRootState).router.location?.pathname ?? '';
    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(workPermit)) {
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftMyInformationWorkPermit(true));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftMyInformationBankAccount(true));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftMyInformationAvsAhv(true));
    }
  },
);

export const saveDraftMyInformationWorkPermit = createAsyncThunk(
  'onboarding/saveDraftMyInformationWorkPermit',
  async (moveToNextPage: boolean | undefined, { getState, dispatch }) => {
    const { workPermit, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft = tabStatus[3] === OnboardingRequestStatuses.draft;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: workPermit,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putWorkPermitAsConsultant(
        dataQuery,
      );
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(tabStatus, 3, myInformation, false, true),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 3,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workPermit',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveDraftMyInformationBankAccount = createAsyncThunk(
  'onboarding/saveDraftsaveDraftMyInformationBankAccountBankAccount',
  async (moveToNextPage: boolean | undefined, { getState, dispatch }) => {
    const { bankAccount, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft = tabStatus[4] === OnboardingRequestStatuses.draft;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putBankAccountAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(tabStatus, 4, myInformation, false, true),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 4,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'bankAccount',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveDraftMyInformationAvsAhv = createAsyncThunk(
  'onboarding/saveDraftMyInformationAvsAhv',
  async (moveToNextPage: boolean | undefined, { getState, dispatch }) => {
    const { ssnInformation, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft = tabStatus[6] === OnboardingRequestStatuses.draft;

    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: ssnInformation,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putAvsAhvAsConsultant(
        dataQuery,
      );
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(tabStatus, 6, myInformation, false, true),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 6,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'ssnInformation',
            fieldValue: data,
          }),
        );
      }
    }
  },
);
