import { Modal, Radio } from 'antd';
import './ChangedEmailModal.less';
import { useAppSelector } from '../../../redux/store';
import Button from '../../../components/button/Button';
import { useDispatch } from 'react-redux';
import { setUseNewEmail } from '../../../redux/reducers/onboarding/sliceReducer';

interface IProps {
  isOpen: boolean;
  confirmAction: () => void;
  closeModal: () => void;
}

const ChooseEmailModal = (props: IProps) => {
  const { isOpen, confirmAction, closeModal } = props;
  const dispatch = useDispatch();
  const { personalInfo, consultantEmail, useNewEmail } = useAppSelector(
    (state) => state.onboarding,
  );
  const { email } = personalInfo;

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      onCancel={closeModal}
      width={500}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div>
        <div style={{ margin: '1.5rem 0' }}>
          <span>
            You are trying to update the email for the Consultant. Please pick
            the email that should receive the confirmation link
          </span>
        </div>
        <Radio.Group
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2rem',
          }}
          name="swissWorkPermit"
          onChange={(value) => dispatch(setUseNewEmail(value.target.value))}
          value={useNewEmail}>
          <Radio value={false} style={{ marginBottom: '1rem' }} defaultChecked>
            {consultantEmail}
          </Radio>
          <Radio value={true}>{email}</Radio>
        </Radio.Group>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            buttonWidth={'200px'}
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={() => {
              dispatch(setUseNewEmail(null));
              closeModal();
            }}>
            Cancel
          </Button>
          <Button
            buttonWidth={'200px'}
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => {
              if (useNewEmail === null) {
                return;
              }
              confirmAction();
            }}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseEmailModal;
