export const TabConstant = {
  ['consultantOnboarding.personalInformation']: 'Personal information',
  ['consultantOnboarding.workContract']: 'Work contract',
  ['consultantOnboarding.additionalInformation']: 'Additional information',
  ['consultantOnboarding.bankAccount']: 'Bank account',
  ['consultantOnboarding.childAllowances']: 'Children',
  ['consultantOnboarding.clientContract']: 'Client contract',
  ['consultantOnboarding.ssnInformation']: 'Pension',
  ['consultantOnboarding.taxAtSource']: 'Tax-at-source',
  ['consultantOnboarding.workPermit']: 'Work permit',
};
