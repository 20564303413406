import {
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Input from '../../../../components/input/Input';
import {
  IClientContract,
  IWorkContract,
} from '../../../../redux/reducers/onboarding/types';
import { countries } from '../../../../common/constants/mockCountries';
import Checkbox from '../../../../components/checkbox/Checkbox';
import { FeesOptions } from '../../../../common/constants/feesOptions';
import InputNumber from '../../../../components/input/InputNumber';
import { sourceOfFeesOptions } from '../../../../common/constants/sourceOfFeesOptions';
import { numeriqOfficeOptions } from '../../../../common/constants/numeriqOfficeOptions';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  addCCEmail,
  addNewAddressLine,
  addPurchaseFile,
  addPurchaseItem,
  changeAddressLine,
  changeCCEmail,
  changePurchaseItemValue,
  dublicateAddressOnBoardingClientContract,
  removeAddressLine,
  removeCCEmail,
  removePurchaseFile,
  removePurchaseItem,
  updateOnboardingClientContractAddress,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../../common/api/services/FileApi/enums/RequestEnums';
import { openErrorNotification } from '../../../../components/notification/Notification';
import UploadedFileIcon from '../../../../assets/icons/UploadedFileIcon';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import { systemInfoApi } from '../../../../common/api/services/SystemInfoApi/SystemInfoApi';
import { IBankAccount } from '../../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import ILabelInValue from '../../../../common/types/ILabelInValue';
import {
  IAddressCompany,
  IInitWorkContract,
  initWorkContract,
} from '../work-contact-tab/helper';
import { capitalizeString } from '../../../../common/utils/stringsUtil';
import { clientApi } from '../../../../common/api/services/ClientApi/clientApi';

const fees = ['Percentage', 'Nominal'];

const { Option } = Select;

interface IProps {
  clientContract: IClientContract;
  workContract: IWorkContract;
  getError: (fieldName: string, isGroup?: boolean) => string;
  onChange: (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent) => void;
  onChangeCheckbox: (
    fieldName: 'receivedClientContract' | 'agencyConfirmed',
  ) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  resetFields: (fieldName: string, fieldValue: string) => void;
  onChangeNumber: (
    fieldName: string,
  ) => (value: number | string | null) => void;
  onAddNewAddress: () => void;
  onDeleteAddress: (index: number) => () => void;
  onChangeAddressByIndex: (index: number) => (data: any) => void;
  onChangeAddressByIndexField: (
    index: number,
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => (data: any) => void;
  isAdmin: boolean;
}

const ClientContractTabAgencySection: FC<IProps> = (props) => {
  const {
    clientContract,
    workContract,
    getError,
    onChange,
    onChangeCheckbox,
    onChangeDropdown,
    onChangeNumber,
    isAdmin,
    onDeleteAddress,
    onChangeAddressByIndex,
    onChangeAddressByIndexField,
    onAddNewAddress,
  } = props;

  const dispatch = useAppDispatch();

  const refPrevInput = useRef<string>('');
  const { selectedClientIdx, clients } = clientContract;
  const { initialClientContractOrder } = useAppSelector(
    (state) => state.onboarding,
  );

  const [clientsList, setClientsList] = useState<IInitWorkContract>({
    ...initWorkContract,
  });

  const [bankAccounts, setBankAccounts] = useState([]);

  const convertedSelectedIdx = convertSelectedIdx(
    clients,
    initialClientContractOrder,
    selectedClientIdx,
  );

  const {
    isAgencyInvolved = false,
    clientId,
    agencyName = '',
    agencyEmail = '',
    agencyCountry = '',
    receivedClientContract = false,
    clientContractLink = '',
    agencyConfirmed = false,
    agencyLink = '',
    billRate = '',
    agencyMargin = 0,
    contractNumber = '',
    numeriqFeesType = '',
    numeriqFees = 0,
    sourceOfFees = '',
    minFee = 0,
    maxFee = 0,
    numeriqOffice = '',
    agencyEmailsCC = [],
    enabledPurchaseOrders = false,
    bankAccount,
    purchaseOrders = [
      {
        attachments: [],
        endDate: '',
        poComment: '',
        poNumber: '',
        startDate: '',
        indefinite: false,
      },
    ],
    addresses: addressesFromState,
  } = clients[selectedClientIdx];

  const {
    addresses: addressesToVerify,
    listOfAddresses,
    fullAddresses,
  } = clientsList;

  const addresses =
    addressesFromState.length > 0
      ? addressesFromState
      : [
          {
            street: '',
            city: '',
            country: '',
            postCode: '',
            vat: undefined,
            addressLines: [],
          },
        ];

  const { companies } = workContract;
  const rate =
    companies.find(
      (item) => item?._id === clients[selectedClientIdx]?.companyId,
    )?.rate || 0;
  const salaryAmount =
    companies.find(
      (item) => item?._id === clients[selectedClientIdx]?.companyId,
    )?.salaryAmount || 0;
  const currencyWC = companies.find(
    (item) => item?._id === clients[selectedClientIdx]?.companyId,
  )?.invoiceCurrency;

  useEffect(() => {
    if (isAdmin) {
      systemInfoApi
        .getBankAccounts(currencyWC || '')
        .then((resp: any) => setBankAccounts(resp.result))
        .catch((e: any) => console.log(e));
    }
  }, [currencyWC]);

  useEffect(() => {
    if (clientId && isAdmin) {
      clientApi.getClientById(clientId).then((resp: any) => {
        setClientsList((prev) => {
          return {
            ...prev,
            clientId: resp._id,
            addresses: resp.addresses?.length
              ? addresses.map(
                  ({
                    street = '',
                    city = '',
                    postCode = '',
                    country = '',
                    _id = '',
                  }) => {
                    const found = resp.addresses.find(
                      (item: any) =>
                        item.street === street &&
                        item.country === country &&
                        item.city === city &&
                        item.postCode === postCode &&
                        item.addressId === _id,
                    );
                    return {
                      searchStreet: street,
                      selectedStreet: found
                        ? {
                            label: street,
                            value: `${street}||-||${city}||-||${postCode}||-||${country}||-||${_id}`,
                          }
                        : null,
                    };
                  },
                )
              : [],
            listOfAddresses:
              resp?.addresses
                .map(
                  ({
                    street = '',
                    city = '',
                    country = '',
                    postCode = '',
                    _id = '',
                  }) => ({
                    label: street,
                    value: `${street}||-||${city}||-||${postCode}||-||${country}||-||${_id}`,
                  }),
                )
                .sort((a: any, b: any) => a.value.localeCompare(b.value)) || [],
            fullAddresses: resp?.addresses || [],
          };
        });
      });
    }
  }, [clientId]);

  const nominalFeesLabel =
    FeesOptions.nominal === numeriqFeesType
      ? 'Nominal fees CHF'
      : 'Percentage fees %';

  const addEmail = () => {
    dispatch(addCCEmail());
  };

  const removeEmail = (index: number) => {
    dispatch(removeCCEmail(index));
  };

  const onChangeEmail = (value: string, index: number) => {
    dispatch(changeCCEmail({ value, index }));
  };

  const onChangePurchaseOrderItem = (
    fieldName: string,
    value: string | boolean,
    index: number,
  ) => {
    if (fieldName === 'indefinite' && value) {
      dispatch(
        changePurchaseItemValue({ fieldName: 'endDate', value: '', index }),
      );
    }
    dispatch(changePurchaseItemValue({ fieldName, value, index }));
  };

  const onUploadFiles = (file: any, index: number) => {
    fileApi
      .uploadFileByUploadFile(
        file,
        UploadEntityId.clientContract,
        UploadEntity.consultantOnboarding,
      )
      .then((resp: any) => {
        dispatch(addPurchaseFile({ value: resp, index }));
      })
      .catch((e) => {
        openErrorNotification('Failed to upload files');
      });
  };

  const removeFile = (originalFilename: string, index: number) => {
    fileApi
      .removeFileByName(originalFilename)
      .then(() => {
        dispatch(removePurchaseFile({ originalFilename, index }));
      })
      .catch((e) => openErrorNotification('Failed to delete file'));
  };

  const customOption = (array: ILabelInValue[]) => {
    if (!array.length) return null;
    return array.map((item) => {
      const values = item.value.split('||-||');
      return (
        // eslint-disable-next-line react/jsx-no-undef
        <Option value={item.value} key={item.value}>
          {`${values[0]}, ${values[1]}, ${values[2]}, ${values[3]}`}
        </Option>
      );
    });
  };

  const onChangeAddressSearchByIdx =
    (index: number, fieldName: string) => (data: string) => {
      if (data === '' && refPrevInput.current?.length !== 1) return;
      const selected = `selected${capitalizeString(fieldName)}`;
      const search = `search${capitalizeString(fieldName)}`;
      refPrevInput.current = data;

      setClientsList((obj) => ({
        ...obj,
        addresses: obj.addresses.map((item, idx) =>
          idx === index ? { ...item, [search]: data, [selected]: null } : item,
        ),
      }));
      dispatch(
        updateOnboardingClientContractAddress({ index, [fieldName]: data }),
      );
    };

  const onChangeAddressDropdownByIdx =
    (index: number) => (data: ILabelInValue) => {
      const clientId = clientsList;
      if (!clientId) return;

      const values = data?.value.split('||-||');
      if (!values) return;
      const [street, city, postCode, country, _id] = values;

      const addressId: any =
        fullAddresses.find(
          (item) =>
            item.street === street &&
            item.city === city &&
            item.postCode === postCode &&
            item.country === country &&
            item._id === _id,
        )?._id || null;
      const selectedAddress = fullAddresses.find(
        (a) =>
          a.city === city &&
          a.country === country &&
          a.postCode === postCode &&
          a.street === street &&
          a._id === _id,
      );

      const fields = {
        street,
        city,
        postCode,
        country,
        addressId,
        vat: selectedAddress?.vat,
        addressLines: selectedAddress?.addressLines || [],
      };

      const newStreet: IAddressCompany = {
        searchStreet: street,
        selectedStreet: {
          label: street,
          value: `${street}||-||${city}||-||${postCode}||-||${country}||-||${_id}`,
        },
      };

      setClientsList((obj) => ({
        ...obj,
        addresses: obj.addresses.map((item, idx) =>
          idx === index ? { ...item, ...newStreet } : item,
        ),
      }));
      dispatch(updateOnboardingClientContractAddress({ index, ...fields }));
    };

  const onClearAddressByIdx = (index: number, fieldName: string) => () => {
    const selected = `selected${capitalizeString(fieldName)}`;
    const search = `search${capitalizeString(fieldName)}`;

    setClientsList((obj) => ({
      ...obj,
      addresses: obj.addresses.map((item, idx) =>
        idx === index ? { ...item, [search]: '', [selected]: null } : item,
      ),
    }));

    dispatch(updateOnboardingClientContractAddress({ index, [fieldName]: '' }));
  };

  return (
    <Col span={12}>
      {isAdmin && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={numeriqOfficeOptions}
              value={numeriqOffice}
              onChange={onChangeDropdown('numeriqOffice')}
              label="Numeriq Office"
              showSearch={false}
              allowClear={!!numeriqOffice}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].numeriqOffice`,
              )}
              showAsterisk
            />
          </Col>
        </Row>
      )}

      {isAdmin && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ marginTop: 45 }}>
          <Col span={10}>
            <span>Is agency involved?</span>
            <span className="asterisk">*</span>
          </Col>

          <Col span={14}>
            <Radio.Group
              className="radio-group-space-around"
              name="isAgencyInvolved"
              value={isAgencyInvolved}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {isAgencyInvolved && isAdmin && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                name="agencyName"
                label="Agency name"
                value={agencyName}
                onChange={onChange}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].agencyName`,
                )}
                showAsterisk
              />
            </Col>

            <Col span={12}>
              <Input
                name="agencyEmail"
                label="Agency email"
                value={agencyEmail}
                onChange={onChange}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].agencyEmail`,
                )}
                showAsterisk
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                options={countries}
                label="Agency country"
                allowClear
                customClassName="dropdown-placeholder"
                placeholder="Type to search"
                value={agencyCountry || undefined}
                onChange={onChangeDropdown('agencyCountry')}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].agencyCountry`,
                )}
                isSearch
                showAsterisk
              />
            </Col>
          </Row>
          {agencyEmailsCC.length > 0 && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {agencyEmailsCC.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: 'flex', marginBottom: '1rem' }}>
                    <Input
                      name="agencyEmailsCC"
                      label="CC email"
                      value={agencyEmailsCC[index]}
                      onChange={(value: any) =>
                        onChangeEmail(value.target.value, index)
                      }
                      errorMessage={getError(
                        `data.clients[${convertedSelectedIdx}].agencyEmailsCC[${index}]`,
                      )}
                      showAsterisk
                    />
                    <div
                      className="remove-cc-container"
                      onClick={() => removeEmail(index)}>
                      <span>Delete</span>
                      <TrashIcon style={{ marginLeft: '15px' }} />
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <span onClick={() => addEmail()} className="add-cc-text">
                + Add CC email
              </span>
            </Col>
          </Row>
        </>
      )}

      {isAdmin && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Input
                name="contractNumber"
                label="Client contract number"
                value={contractNumber}
                onChange={() => null}
                isShowClipboardCopyLink={true}
                disabled
                showAsterisk
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Checkbox
                label="Received Client contract."
                checked={receivedClientContract}
                onChange={onChangeCheckbox('receivedClientContract')}
                showAsterisk
              />
            </Col>
          </Row>
          {receivedClientContract && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="clientContractLink"
                  label="Client contract link"
                  value={clientContractLink}
                  onChange={onChange}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].clientContractLink`,
                  )}
                  showAsterisk
                />
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Checkbox
                label="MOA (Agency) confirmed."
                checked={agencyConfirmed}
                onChange={onChangeCheckbox('agencyConfirmed')}
                showAsterisk
              />
            </Col>
          </Row>
          {agencyConfirmed && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="agencyLink"
                  label="Agency link"
                  value={agencyLink}
                  onChange={onChange}
                  showAsterisk
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].agencyLink`,
                  )}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {addresses.map(
        (
          {
            street = '',
            postCode = '',
            country = '',
            city = '',
            vat = '',
            addressLines = [],
          },
          idx,
        ) => {
          return (
            <div
              key={`${clients[selectedClientIdx]._id}${idx}`}
              style={{
                borderBottom: '7px solid #f5f7fa',
                paddingBottom: '1rem',
              }}>
              <h4 style={{ marginTop: '1rem' }}>Address {idx + 1}</h4>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {isAdmin &&
                  // addresses.length <= addressesToVerify.length &&
                  listOfAddresses.length > 0 ? (
                    <Dropdown
                      customOption={customOption(listOfAddresses)}
                      label="Address (street name and number)"
                      allowClear={
                        !!addressesToVerify[idx]?.searchStreet ||
                        !!addressesToVerify[idx]?.selectedStreet
                      }
                      labelInValue={true}
                      value={
                        addressesToVerify[idx]?.selectedStreet ||
                        addressesFromState[idx]?.street
                      }
                      searchValue={addressesToVerify[idx]?.searchStreet}
                      onClear={onClearAddressByIdx(idx, 'street')}
                      onSearch={onChangeAddressSearchByIdx(idx, 'street')}
                      onSelect={onChangeAddressDropdownByIdx(idx)}
                      options={listOfAddresses}
                      errorMessage={
                        getError(
                          `data.clients[${convertedSelectedIdx}].addresses[${idx}].street`,
                        ) ||
                        getError(
                          `data.clients[${convertedSelectedIdx}].addresses`,
                          true,
                        )
                      }
                    />
                  ) : (
                    <Input
                      name="street"
                      label="Address (street name and number)"
                      value={street}
                      onChange={onChangeAddressByIndex(idx)}
                      errorMessage={
                        getError(
                          `data.clients[${convertedSelectedIdx}].addresses[${idx}].street`,
                        ) ||
                        getError(
                          `data.clients[${convertedSelectedIdx}].addresses`,
                          true,
                        )
                      }
                    />
                  )}
                </Col>
              </Row>
              {addressLines?.length
                ? addressLines.map((addressLine, lineIndex) => (
                    <Row gutter={[16, 16]} key={lineIndex}>
                      <Col span={24}>
                        <>
                          <Input
                            name="lineIndex"
                            label="Address (street name and number)"
                            value={addressLine}
                            onChange={(value: any) =>
                              dispatch(
                                changeAddressLine({
                                  index: idx,
                                  lineIndex,
                                  value: value.target.value,
                                }),
                              )
                            }
                            errorMessage={
                              getError(
                                `data.clients[${convertedSelectedIdx}].addresses[${idx}].addressLines[${[
                                  lineIndex,
                                ]}]`,
                              ) ||
                              getError(
                                `data.clients[${convertedSelectedIdx}].addresses`,
                                true,
                              )
                            }
                          />
                          <Row gutter={[16, 16]} justify="end" align="middle">
                            <button
                              className="remove-child-btn"
                              onClick={() =>
                                dispatch(
                                  removeAddressLine({ index: idx, lineIndex }),
                                )
                              }>
                              Delete address line
                              <TrashIcon />
                            </button>
                          </Row>
                        </>
                      </Col>
                    </Row>
                  ))
                : null}

              <Row gutter={[16, 16]}>
                {!addressLines.length ? (
                  <Col span={24}>
                    <button
                      className="add-common-btn"
                      onClick={() => dispatch(addNewAddressLine(idx))}>
                      <CrossIcon />
                      Add new address line
                    </button>
                  </Col>
                ) : null}
                <Col span={8}>
                  <Input
                    name="postCode"
                    label="Postcode"
                    value={postCode}
                    onChange={onChangeAddressByIndex(idx)}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses[${idx}].postCode`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses`,
                        true,
                      )
                    }
                  />
                </Col>
                <Col span={8}>
                  <Dropdown
                    options={countries}
                    allowClear={country?.length > 0}
                    label="Country"
                    placeholder="Type to search"
                    customClassName="dropdown-placeholder"
                    value={country || undefined}
                    showSearch
                    onChange={onChangeAddressByIndexField(idx, 'country')}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses[${idx}].country`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses`,
                        true,
                      )
                    }
                    isSearch={true}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="city"
                    label="City"
                    value={city}
                    onChange={onChangeAddressByIndex(idx)}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses[${idx}].city`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses`,
                        true,
                      )
                    }
                  />
                </Col>
                <Col span={24}>
                  <Input
                    name="vat"
                    label="VAT"
                    value={vat?.toString() || undefined}
                    onChange={onChangeAddressByIndex(idx)}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses[${idx}].vat`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].addresses`,
                        true,
                      )
                    }
                  />
                </Col>
              </Row>

              {addresses.length !== 1 && idx > 0 && (
                <Row gutter={[16, 16]} justify="end" align="middle">
                  <button
                    className="remove-child-btn"
                    onClick={onDeleteAddress(idx)}>
                    Delete address
                    <TrashIcon />
                  </button>
                </Row>
              )}
            </div>
          );
        },
      )}

      <button className="add-common-btn" onClick={onAddNewAddress}>
        <CrossIcon />
        Add address
      </button>
      {isAdmin && (
        <>
          <h4 style={{ margin: '36px 0 0 0' }}>Financials</h4>
          {isAdmin ? (
            <Row style={{ paddingRight: '20px' }}>
              <Col span={24}>
                <Dropdown
                  label="Bank account"
                  onChange={onChangeDropdown('bankAccount')}
                  options={
                    bankAccounts.length
                      ? bankAccounts.map((item: IBankAccount) => {
                          return {
                            value: item._id,
                            label: `${item.name} (${item.currency})`,
                          };
                        })
                      : []
                  }
                  showAsterisk
                  value={bankAccount}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].bankAccount`,
                  )}
                />
              </Col>
            </Row>
          ) : null}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InputNumber
                label="Pay rate"
                onChange={() => null}
                value={rate}
                name="rate"
                showIcons={false}
                disabled={true}
                showAsterisk
              />
            </Col>
            <Col span={12}>
              <Input
                name="billRate"
                label="Bill rate"
                value={billRate}
                onChange={onChange}
                showAsterisk
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].billRate`,
                )}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InputNumber
                label="AVS/AHV salary"
                onChange={() => null}
                value={salaryAmount}
                name="salaryAmount"
                showIcons={false}
                disabled={true}
                showAsterisk
              />
            </Col>
            <Col span={12}>
              <InputNumber
                name="agencyMargin"
                label="Agency margin"
                value={isAgencyInvolved ? agencyMargin : 0}
                onChange={onChangeNumber('agencyMargin')}
                disabled={!isAgencyInvolved}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].agencyMargin`,
                )}
                showIcons={false}
                showAsterisk
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Dropdown
                options={fees}
                label="Numeriq fees"
                value={numeriqFeesType}
                onChange={onChangeDropdown('numeriqFeesType')}
                showSearch={false}
                showAsterisk
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].numeriqFeesType`,
                )}
                allowClear
                onClear={() => onChangeDropdown('numeriqFeesType')('')}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputNumber
                label={nominalFeesLabel}
                onChange={onChangeNumber('numeriqFees')}
                value={numeriqFees}
                name="numeriqFees"
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].numeriqFees`,
                )}
                showIcons={false}
                showAsterisk
              />
            </Col>
          </Row>
          {FeesOptions.percentage === numeriqFeesType && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Dropdown
                    options={sourceOfFeesOptions}
                    label="Source of fees"
                    value={sourceOfFees}
                    onChange={onChangeDropdown('sourceOfFees')}
                    showSearch={false}
                    showAsterisk
                    errorMessage={getError(
                      `data.clients[${convertedSelectedIdx}].sourceOfFees`,
                    )}
                    allowClear
                    onClear={() => onChangeDropdown('sourceOfFees')('')}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <InputNumber
                    label="Minimum fee %"
                    onChange={onChangeNumber('minFee')}
                    value={minFee}
                    name="minFee"
                    errorMessage={getError(
                      `data.clients[${convertedSelectedIdx}].minFee`,
                    )}
                    showIcons={false}
                    showAsterisk
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    label="Maximum fee %"
                    onChange={onChangeNumber('maxFee')}
                    value={maxFee}
                    name="maxFee"
                    errorMessage={getError(
                      `data.clients[${convertedSelectedIdx}].maxFee`,
                    )}
                    showIcons={false}
                    showAsterisk
                  />
                </Col>
              </Row>
            </>
          )}
          <Row
            justify="space-between"
            align="middle"
            gutter={[16, 16]}
            style={{ marginTop: 45 }}>
            <Col span={10} style={{ padding: '10px', display: 'flex' }}>
              <h4>Purchase Order </h4>
              <span className="asterisk">*</span>
            </Col>

            <Col span={14}>
              <Radio.Group
                className="radio-group-space-around"
                name="enabledPurchaseOrders"
                value={enabledPurchaseOrders}
                onChange={onChange}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>
          </Row>

          {enabledPurchaseOrders &&
            purchaseOrders.map(
              (
                {
                  attachments = [],
                  endDate = '',
                  poComment = '',
                  poNumber = '',
                  startDate = '',
                  indefinite = false,
                },
                idx,
              ) => {
                const isAttachmentError = getError(
                  `data.clients[${convertedSelectedIdx}].purchaseOrders[${idx}].attachments`,
                );
                return (
                  <React.Fragment
                    key={`${clients[selectedClientIdx]._id}${idx}`}>
                    <Row gutter={[16, 16]}>
                      <Col span={12} style={{ height: '54px' }}>
                        <CommonDatePicker
                          label="Start date"
                          defaultValue={startDate || ''}
                          changeDate={(date) =>
                            onChangePurchaseOrderItem(
                              'startDate',
                              date?.toString() as string,
                              idx,
                            )
                          }
                          className="date-picker-form"
                          errorMessage={getError(
                            `data.clients[${convertedSelectedIdx}].purchaseOrders[${idx}].startDate`,
                          )}
                          calendarPosition="right-calendar-position"
                        />
                      </Col>
                      <Col span={12} style={{ height: '54px' }}>
                        <CommonDatePicker
                          label="End date"
                          defaultValue={endDate || ''}
                          changeDate={(date) =>
                            onChangePurchaseOrderItem(
                              'endDate',
                              date?.toString() as string,
                              idx,
                            )
                          }
                          disabled={!!indefinite}
                          className="date-picker-form"
                          errorMessage={getError(
                            `data.clients[${convertedSelectedIdx}].purchaseOrders[${idx}].endDate`,
                          )}
                        />
                      </Col>
                    </Row>
                    <Row
                      justify="space-between"
                      align="middle"
                      gutter={[16, 16]}
                      style={{ marginTop: '2rem' }}>
                      <Col span={12}>
                        <span>Indefinite</span>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Switch
                          checked={indefinite}
                          onChange={(value) =>
                            onChangePurchaseOrderItem('indefinite', value, idx)
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                      <Col span={12}>
                        <Input
                          label="PO number"
                          onChange={(value: any) =>
                            onChangePurchaseOrderItem(
                              'poNumber',
                              value.target.value as string,
                              idx,
                            )
                          }
                          value={poNumber || ''}
                          name="poNumber"
                          errorMessage={getError(
                            `data.clients[${convertedSelectedIdx}].purchaseOrders[${idx}].poNumber`,
                          )}
                        />
                      </Col>
                      <Col span={12} style={{ width: '100%' }}>
                        <Upload
                          onChange={(value) =>
                            onUploadFiles(value.fileList, idx)
                          }
                          beforeUpload={() => false}
                          fileList={[]}
                          className="upload-purchase"
                          multiple={false}>
                          <div
                            className="purchase-attachment"
                            style={{
                              backgroundColor: !isAttachmentError
                                ? '#f5f7fa'
                                : '#fff1f0',
                            }}>
                            <span>Attachment</span>
                            <UploadedFileIcon />
                          </div>
                          {isAttachmentError && (
                            <span className="error-message error-text">
                              <div className="icon-wrapper">
                                <CrossIcon />
                              </div>
                              {isAttachmentError}
                            </span>
                          )}
                        </Upload>
                      </Col>
                    </Row>

                    {attachments?.length ? (
                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {attachments.map((item) => {
                          return (
                            <div key={item.fileId} className="attachment-po">
                              <span
                                onClick={() =>
                                  fileApi.downloadFileByUrl(
                                    item.originalFilename,
                                  )
                                }>
                                {item.originalFilename.length > 35
                                  ? `${item.originalFilename.substring(
                                      0,
                                      35,
                                    )}...`
                                  : item.originalFilename}
                              </span>
                              <TrashIcon
                                onClick={() =>
                                  removeFile(item.originalFilename, idx)
                                }
                              />
                            </div>
                          );
                        })}
                      </Row>
                    ) : null}
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Input
                          name="poComment"
                          label="PO comment"
                          value={poComment}
                          onChange={(e: any) =>
                            onChangePurchaseOrderItem(
                              'poComment',
                              e.target.value,
                              idx,
                            )
                          }
                          errorMessage={getError(
                            `data.clients[${convertedSelectedIdx}].purchaseOrders[${idx}].poComment`,
                          )}
                        />
                      </Col>
                      {purchaseOrders?.length && purchaseOrders?.length > 1 && (
                        <Col
                          span={12}
                          onClick={() => dispatch(removePurchaseItem(idx))}>
                          <div className="delete-pi">
                            <span>
                              <TrashIcon />
                            </span>
                            <span style={{ color: '#c6372c' }}> Delete</span>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </React.Fragment>
                );
              },
            )}
          {enabledPurchaseOrders && (
            <div className="add-new-po">
              <span
                onClick={() => dispatch(addPurchaseItem())}
                style={{ color: '#0a71e0', marginTop: '10px' }}>
                +Add new PO
              </span>
            </div>
          )}
        </>
      )}
    </Col>
  );
};

export default ClientContractTabAgencySection;
