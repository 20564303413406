import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setClientContract,
  setClientContractModalOptions,
  setIsShowClientContractModal,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
  setOnboardingWorkContract,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { checkClientContract } from '../../../../common/utils/validateRequiredFields';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import {
  IClientContract,
  IClientContractModalOptions,
  IOnboardingSaveOptions,
} from './../types';
import { clientApi } from '../../../../common/api/services/ClientApi/clientApi';
import { getOnboardingProfile } from '../actionAsyncThunk';
import { compareEndDate } from '../../../../common/utils/compareEndDate';
import { applyClientOrder } from '../../../../common/utils/onboardingSortHelper';
import { openErrorNotification } from '../../../../components/notification/Notification';

export const saveDraftClientContractAsEmployee = createAsyncThunk(
  'onboarding/saveDraftClientContract',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const {
      clientContract: clientContractFromState,
      tabStatus,
      consultantId,
      initialClientContractOrder,
    } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[2] === OnboardingRequestStatuses.draft ||
      tabStatus[2] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    if (isDraft) {
      const clientContract = checkClientContract(clientContractFromState);
      const dataQuery: IQueryParameters<IClientContract> = {
        key: OnboardingRequestKeys.clientContract,
        status: OnboardingRequestStatuses.draft,
        type: OnboardingRequestTypes.required,
        data: {
          ...clientContract,
          clients: applyClientOrder(
            clientContract.clients,
            initialClientContractOrder,
          ),
        },
        // data: clientContract,
      };

      const { isSuccess, data } =
        await onboardingApi.putClientContractAsEmployee(
          consultantId,
          dataQuery,
        );
      if (isSuccess) {
        dispatch(
          setClientContract(
            data.consultantOnboarding.clientContract.clients
              .slice()
              .sort(compareEndDate),
          ),
        );
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 2, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        if (typeof data === 'string') {
          openErrorNotification(data);
        }
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'clientContract',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveDraftClientContractAsConsultant = createAsyncThunk(
  'onboarding/saveDraftClientContractAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { clientContract: clientContractFromState, tabStatus } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[2] === OnboardingRequestStatuses.draft ||
      tabStatus[2] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    if (isDraft) {
      const clientContract = checkClientContract(clientContractFromState);
      const dataQuery: IQueryParameters<IClientContract> = {
        key: OnboardingRequestKeys.clientContract,
        status: OnboardingRequestStatuses.draft,
        type: OnboardingRequestTypes.required,
        data: clientContract,
      };

      const { isSuccess, data } =
        await onboardingApi.putClientContractAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(
                tabStatus,
                2,
                redirectRootRout,
                false,
                true,
              ),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 2,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        if (typeof data === 'string') {
          openErrorNotification(data);
        }
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'clientContract',
            fieldValue: data,
          }),
        );
      }
    }
  },
);
export const savePublishClientContractAsEmployee = createAsyncThunk(
  'onboarding/savePublishClientContract',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const {
      clientContract: clientContractFromState,
      tabStatus,
      consultantId,
      workContract: workContractFromState,
      initialClientContractOrder,
    } = (getState() as IRootState).onboarding;

    const clientContract = checkClientContract(
      clientContractFromState,
      workContractFromState,
    );
    const dataQuery: IQueryParameters<IClientContract> = {
      key: OnboardingRequestKeys.clientContract,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: {
        ...clientContract,
        clients: applyClientOrder(
          clientContract.clients,
          initialClientContractOrder,
        ),
      },
      // data: clientContract,
    };

    const { isSuccess, data } = await onboardingApi.putClientContractAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      dispatch(setOnboardingWorkContract({ ...data }));
      dispatch(
        setClientContract(
          data.consultantOnboarding.clientContract.clients
            .slice()
            .sort(compareEndDate),
        ),
      );

      dispatch(push(getOnboardingRedirect(tabStatus, 2, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.published,
          isAdmin: true,
        }),
      );
    } else {
      if (typeof data === 'string') {
        openErrorNotification(data);
      }
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'clientContract',
          fieldValue: data,
        }),
      );
    }
  },
);

export const checkClientContractAsEmployee = createAsyncThunk(
  'onboarding/checkClientContractAsEmployee',
  async (redirectRootRout: string | undefined, { dispatch, getState }) => {
    const { clientContract, workContract } = (getState() as IRootState)
      .onboarding;

    const getAllClients = await Promise.all(
      workContract.companies.map((company, idx) => {
        const response = clientApi.getClientById(company.clientId ?? '');
        return response.then((res) => {
          if (!res?.paymentTerm || !res?.paymentTermRange) return null;

          const currentClientContract = clientContract.clients.find(
            (item) => item.clientId === company.clientId,
          );

          if (
            currentClientContract &&
            (currentClientContract.paymentTerm !== res.paymentTerm ||
              currentClientContract.paymentTermRange !== res.paymentTermRange)
          ) {
            return {
              name: res.name,
              before: {
                paymentTerm: res.paymentTerm,
                paymentTermRange: res.paymentTermRange,
              },
              after: {
                paymentTerm: currentClientContract.paymentTerm,
                paymentTermRange: currentClientContract.paymentTermRange,
              },
            };
          }

          return null;
        });
      }),
    );

    const clearArray = getAllClients.filter(
      Boolean,
    ) as IClientContractModalOptions[];

    if (clearArray.length > 0) {
      dispatch(setIsShowClientContractModal(true));
      dispatch(setClientContractModalOptions(clearArray));
    } else {
      dispatch(savePublishClientContractAsEmployee(redirectRootRout));
    }
  },
);

export const savePublishClientContractAsConsultant = createAsyncThunk(
  'onboarding/savePublishClientContractAsConsultant ',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { clientContract: clientContractFromState, tabStatus } = (
      getState() as IRootState
    ).onboarding;

    const clientContract = checkClientContract(clientContractFromState);
    const dataQuery: IQueryParameters<IClientContract> = {
      key: OnboardingRequestKeys.clientContract,
      status: OnboardingRequestStatuses.toVerify,
      type: OnboardingRequestTypes.required,
      data: clientContract,
    };

    const { isSuccess, data } =
      await onboardingApi.putClientContractAsConsultant(dataQuery);
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.toVerify,
        }),
      );
      dispatch(
        push(
          getOnboardingRedirect(tabStatus, 2, redirectRootRout, false, true),
        ),
      );
    } else {
      if (typeof data === 'string') {
        openErrorNotification(data);
      }
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'clientContract',
          fieldValue: data,
        }),
      );
    }
  },
);
