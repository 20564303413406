import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { useAppDispatch } from '../../../redux/store';
import { duplicatedLead } from '../../../redux/reducers/waiting-room/asyncThunkActions';
import { changeIsShowDetails } from '../../../redux/reducers/waiting-room/sliceReducer';

interface IProps {
  showModalArchive: () => void;
  leadId: string;
  status: string;
  sendInvite: (id: string) => void;
}

const { waitingRoom, edit, view } = ROUTER_PATHS;

const ActionMenu: FC<IProps> = ({
  showModalArchive,
  leadId,
  status,
  sendInvite,
}) => {
  const dispatch = useAppDispatch();

  const onDuplicated = () => {
    dispatch(duplicatedLead(leadId));
  };

  const items = [
    {
      key: '5',
      label: (
        <Link
          to={waitingRoom + view + `/${leadId}`}
          onClick={() => dispatch(changeIsShowDetails(true))}>
          View
        </Link>
      ),
    },
    {
      key: '1',
      label: (
        <Link
          to={waitingRoom + edit + `/${leadId}`}
          style={{ textDecoration: 'none' }}>
          Edit
        </Link>
      ),
    },
    {
      key: '2',
      label: <span onClick={() => sendInvite(leadId)}>Create</span>,
    },
    {
      key: '3',
      label: (
        <span onClick={showModalArchive} style={{ color: '#C33025' }}>
          Archive
        </span>
      ),
    },
    {
      key: '4',
      label: <span onClick={onDuplicated}>Duplicated</span>,
    },
  ];

  const newItemsList = items
    .map((item) => {
      if (['invited', 'confirmed'].includes(status) && item.key === '2') {
        return null;
      }
      if ((status === 'confirmed' || status === 'invited') && item.key === '1')
        return null;
      if (
        (status === 'confirmed' ||
          status === 'invited' ||
          status === 'archived') &&
        item.key === '3'
      )
        return null;
      if (status !== 'new' && item.key === '4') return null;
      if (
        (status === 'new' && item.key === '5') ||
        (status === 'archived' && item.key === '5') ||
        (status === 'duplicated' && item.key === '5') ||
        (status === 'failed' && item.key === '5')
      )
        return null;

      return item;
    })
    .filter(Boolean);

  return <Menu items={newItemsList} />;
};

export default ActionMenu;
