import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../common/api/services/FileApi/enums/RequestEnums';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import { IPaginationRequest, IRootState } from '../../types';
import {
  clearSelectedExpense,
  setErrorMessages,
  setExpensesList,
  setExpensesPagination,
  setExpensesSorting,
  setIsLoading,
  updateFilesSelectedExpense,
  updateIsUpLoadingExpense,
  updateOpenModalAddExpense,
  updateOpenModalEditExpense,
  updateSelectedExpense,
} from './sliceReducer';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import { batch } from 'react-redux';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { expensesApi } from '../../../common/api/services/ExpensesApi/expensesApi';
import { IConsultantExpenses } from '../../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import { INewExpense } from '../../../common/api/services/ExpensesApi/types/INewExpense';

export const saveAsDraft = createAsyncThunk(
  'expenses/saveAsDraft',
  async (_, { dispatch, getState }) => {
    const { selectedExpense } = (getState() as IRootState).expenses;

    const { _id: expenseId } = selectedExpense;

    const { isSuccess, data } = await consultantApi.putExpense(expenseId, {
      ...selectedExpense,
      status: ExpensesStatus.DRAFT,
    });

    if (isSuccess) {
      dispatch(updateOpenModalEditExpense(false));
      dispatch(getExpensesList());
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const submit = createAsyncThunk(
  'expenses/submit',
  async (_, { dispatch, getState }) => {
    const { selectedExpense } = (getState() as IRootState).expenses;

    const {
      _id: expenseId,
      hasExpensesInThisMonth,
      isSigned,
      approvalRequired,
      type,
    } = selectedExpense;
    if (
      [
        ExpensesType.EXPENSES_FOR_OPTIMISATION,
        ExpensesType.EXPENSES_MEAL_ALLOWANCE,
      ].includes(type) &&
      hasExpensesInThisMonth
    ) {
      const { isSuccess, data } = await consultantApi.putExpense(expenseId, {
        ...selectedExpense,
        status: ExpensesStatus.PRE_APPROVED,
        approvalRequired: false,
        isSigned: true,
      });

      if (isSuccess) {
        dispatch(updateOpenModalEditExpense(false));
        dispatch(getExpensesList());
        dispatch(clearSelectedExpense());
      } else {
        dispatch(setErrorMessages(data));
      }
    } else {
      const status = !hasExpensesInThisMonth
        ? ExpensesStatus.NO_EXPENSES
        : isSigned
        ? ExpensesStatus.PRE_APPROVED
        : ExpensesStatus.WAITING_FOR_APPROVAL;

      const { isSuccess, data } = await consultantApi.putExpense(expenseId, {
        ...selectedExpense,
        approvalRequired: type === 'MA' ? true : approvalRequired,
        status,
      });

      if (isSuccess) {
        dispatch(updateOpenModalEditExpense(false));
        dispatch(getExpensesList());
        dispatch(clearSelectedExpense());
      } else {
        dispatch(setErrorMessages(data));
      }
    }
  },
);

export const uploadFile = createAsyncThunk(
  'expenses/uploadFile',
  async (selectedFiles: FileList | File[], { dispatch }) => {
    if (selectedFiles.length === 0) return;

    dispatch(updateIsUpLoadingExpense(true));

    const files: File[] = [];
    for (let i = 0; i < selectedFiles.length; i += 1) {
      const file = selectedFiles[i];
      if (file) files.push(file);
    }

    const response = await fileApi.uploadFile(
      files,
      UploadEntityId.expensesFile,
      UploadEntity.consultantExpenses,
    );

    if (response) {
      dispatch(updateFilesSelectedExpense(response));
    }

    dispatch(updateIsUpLoadingExpense(false));
  },
);

export const getExpensesList = createAsyncThunk(
  'expenses/getExpensesList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    dispatch(setIsLoading(true));
    const currentPagination = (getState() as IRootState).expenses.expensesList
      .pagination;
    const currentFilter = (getState() as IRootState).expenses.expensesList
      .filter;
    const currentSort = (getState() as IRootState).expenses.expensesList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await consultantApi.getConsultantExpenses(
      page,
      perPage,
      currentFilter,
      currentSort,
    );
    dispatch(setIsLoading(false));
    if (response) {
      batch(() => {
        dispatch(setExpensesList(response));
        dispatch(setExpensesPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeExpensesSorting = createAsyncThunk(
  'expenses/changeExpensesSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).expenses.expensesList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setExpensesSorting([currentSortBy, 'asc']));
      dispatch(getExpensesList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setExpensesSorting([currentSortBy, 'desc']));
      dispatch(getExpensesList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setExpensesSorting(['', '']));
      dispatch(getExpensesList({ currentPage: 1 }));
      return;
    }
  },
);

export const getInternalExpenses = createAsyncThunk(
  'expenses/getInternalExpenses',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const currentPagination = (getState() as IRootState).expenses.expensesList
      .pagination;
    const currentFilter = (getState() as IRootState).expenses.expensesList
      .filter;
    const currentSort = (getState() as IRootState).expenses.expensesList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await expensesApi.fetchExpenses(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setExpensesList(response));
        dispatch(setExpensesPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeInternalExpensesSorting = createAsyncThunk(
  'expenses/changeInternalExpensesSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).expenses.expensesList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setExpensesSorting([currentSortBy, 'asc']));
      dispatch(getInternalExpenses({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setExpensesSorting([currentSortBy, 'desc']));
      dispatch(getInternalExpenses({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setExpensesSorting(['', '']));
      dispatch(getInternalExpenses({ currentPage: 1 }));
      return;
    }
  },
);

export const saveAsDraftInternalExpense = createAsyncThunk(
  'expenses/saveAsDraftInternalExpense',
  async (_, { dispatch, getState }) => {
    const { selectedExpense } = (getState() as IRootState).expenses;

    const { _id: expenseId, consultantId, total } = selectedExpense;
    const { isSuccess, data } = await consultantApi.putInternalExpense(
      expenseId,
      consultantId,
      {
        ...selectedExpense,
        total: total || 0,
        status: ExpensesStatus.DRAFT,
      },
    );

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(updateOpenModalAddExpense(false));
      dispatch(updateOpenModalEditExpense(false));
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const submitInternalExpense = createAsyncThunk(
  'expenses/submitInternalExpense',
  async (_, { dispatch, getState }) => {
    const { selectedExpense } = (getState() as IRootState).expenses;

    const {
      _id: expenseId,
      consultantId,
      hasExpensesInThisMonth,
      isSigned,
      approvalRequired,
    } = selectedExpense;

    const status = !hasExpensesInThisMonth
      ? ExpensesStatus.NO_EXPENSES
      : isSigned
      ? ExpensesStatus.PRE_APPROVED
      : ExpensesStatus.WAITING_FOR_APPROVAL;

    const { isSuccess, data } = await consultantApi.putInternalExpense(
      expenseId,
      consultantId,
      {
        ...selectedExpense,
        status,
        approvalRequired:
          status === ExpensesStatus.WAITING_FOR_APPROVAL
            ? true
            : approvalRequired,
      },
    );

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(updateOpenModalAddExpense(false));
      dispatch(updateOpenModalEditExpense(false));
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const publishInternalExpense = createAsyncThunk(
  'expenses/publishInternalExpense',
  async (payload: INewExpense, { dispatch }) => {
    const { isSuccess, data } = await expensesApi.newExpense(payload);

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(
        updateSelectedExpense({
          ...data,
          approvalRequired:
            data.type === ExpensesType.EXPENSES_FOR_OPTIMISATION,
        }),
      );
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const approveInternalExpense = createAsyncThunk(
  'expenses/approveInternalExpense',
  async (selectedExpense: Partial<IConsultantExpenses>, { dispatch }) => {
    const { _id: expenseId, consultantId } = selectedExpense;

    const body = {
      ...selectedExpense,
      status: ExpensesStatus.APPROVED,
    };

    if (selectedExpense.status === ExpensesStatus.WAITING_FOR_APPROVAL) {
      body.status =
        selectedExpense.type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
          ? ExpensesStatus.CLIENT_APPROVAL
          : ExpensesStatus.APPROVED;
    }

    if (selectedExpense?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }

    const { isSuccess, data } = await consultantApi.putInternalExpense(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      expenseId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      consultantId!,
      body,
    );

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const rejectInternalExpense = createAsyncThunk(
  'expenses/rejectInternalExpense',
  async (selectedExpense: Partial<IConsultantExpenses>, { dispatch }) => {
    const { _id: expenseId, consultantId } = selectedExpense;

    const body = {
      ...selectedExpense,
      status: ExpensesStatus.REJECTED,
    };

    if (selectedExpense?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }

    const { isSuccess, data } = await consultantApi.putInternalExpense(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      expenseId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      consultantId!,
      body,
    );

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);

export const expireInternalExpense = createAsyncThunk(
  'expenses/expireInternalExpense',
  async (selectedExpense: IConsultantExpenses, { dispatch }) => {
    const { _id: expenseId, consultantId } = selectedExpense;

    const { isSuccess, data } = await consultantApi.putInternalExpense(
      expenseId,
      consultantId,
      {
        status: ExpensesStatus.EXPIRED,
      },
    );

    if (isSuccess) {
      dispatch(getInternalExpenses());
      dispatch(clearSelectedExpense());
    } else {
      dispatch(setErrorMessages(data));
    }
  },
);
