import { ChangeEvent, FC } from 'react';
import { Col, Popover, Radio, RadioChangeEvent, Row } from 'antd';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Textarea from '../../../../components/textarea/Textarea';
import currencies from '../../../../common/constants/mockCurrencies';
import { IBankAccountRequest } from '../../../../common/api/services/OnboardingApi/types/IBankAccountRequest';
import WarningIcon from '../../../../assets/icons/WarningIcon';

type TValues =
  | 'bankName'
  | 'bankAdditionalAddress'
  | 'bankAddress'
  | 'bankCountry'
  | 'bankCity'
  | 'bankChangeOption'
  | 'dateOfBankChange'
  | 'npa';

interface IProps {
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => void;
  values: Omit<IBankAccountRequest, TValues>;
  getError: (fieldName: string) => string;
}

const BankAccountTabDetailsSection: FC<IProps> = ({
  onChange,
  onChangeDropdown,
  values,
  getError,
}) => {
  const {
    hasBankAccount,
    accountOwner,
    address,
    additionalAddress,
    comment,
    iban,
    swift,
    currency,
    hasIBAN,
    bankAccountDetails,
  } = values;

  return (
    <Col span={12}>
      <h4>Account details</h4>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Do you have a bank account?</span>
          <Popover
            className="info-pop-button"
            placement="rightTop"
            content={
              <p>
                If you click &quot;No&quot;, you can skip this step and come
                back later
              </p>
            }
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover>
        </Col>
        <Col>
          <Radio.Group
            name="hasBankAccount"
            value={hasBankAccount}
            onChange={onChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Do you have an IBAN number?</span>
        </Col>
        <Col>
          <Radio.Group
            name="hasIBAN"
            value={hasIBAN}
            onChange={onChange}
            disabled={!hasBankAccount}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      {hasIBAN && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input
              name="iban"
              label="IBAN number"
              placeholder="Number"
              value={iban}
              onChange={onChange}
              errorMessage={getError('data.iban')}
              disabled={!hasBankAccount}
            />
          </Col>
          <Col span={12}>
            <Input
              name="swift"
              label="Swift"
              placeholder="Code"
              value={swift}
              onChange={onChange}
              errorMessage={getError('data.swift')}
              disabled={!hasBankAccount}
            />
          </Col>
        </Row>
      )}

      {!hasIBAN && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="bankAccountDetails"
              label="Bank account details"
              placeholder="Number"
              value={bankAccountDetails}
              onChange={onChange}
              errorMessage={getError('data.bankAccountDetails')}
              disabled={!hasBankAccount}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={currencies}
            value={currency}
            onChange={onChangeDropdown('currency')}
            label="Currency"
            showSearch={false}
            errorMessage={getError('data.currency')}
            disabled={!hasBankAccount}
            allowClear
            onClear={() => onChangeDropdown('currency')('')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="accountOwner"
            label="Owner of the account"
            value={accountOwner}
            onChange={onChange}
            errorMessage={getError('data.accountOwner')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="address"
            label="Account’s address line 1"
            value={address}
            onChange={onChange}
            errorMessage={getError('data.address')}
            disabled={!hasBankAccount}
          />
        </Col>
        <Col span={12}>
          <Input
            name="additionalAddress"
            label="Account’s address line 2"
            value={additionalAddress}
            onChange={onChange}
            errorMessage={getError('data.additionalAddress')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            name="comment"
            value={comment}
            label="Comment"
            onChange={onChange}
            errorMessage={getError('data.comment')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default BankAccountTabDetailsSection;
