import { Col, Modal, Row, Typography } from 'antd';
import Button from '../../../../../components/button/Button';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteTaxModal = (props: IProps) => {
  const { isOpen, onClose, onSubmit } = props;
  return (
    <Modal
      open={isOpen}
      footer={null}
      centered
      destroyOnClose={true}
      onCancel={() => {
        onClose();
      }}
      wrapClassName="modal-window-wrapper">
      <div>
        <div style={{ padding: '30px 0' }}>
          <span style={{ fontSize: '16px' }}>
            Are you sure you want to delete a tax item? This action is
            irreversible.
          </span>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={onSubmit}>
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default DeleteTaxModal;
