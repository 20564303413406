import { useParams } from 'react-router-dom';
import '../../GenerateInvoicePage.less';
import { useEffect, useState } from 'react';
import { invoiceApi } from '../../../../common/api/services/InvoiceApi/invoiceApi';
import ExcelPreview from '../../../../components/file-preview/excel-preview/ExcelPreview';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import { RedoOutlined } from '@ant-design/icons';
import Button from '../../../../components/button/Button';

interface IProps {
  activeKey: string;
}

const PreviewContent = (props: IProps) => {
  const { activeKey } = props;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (activeKey === '3') {
      getFile();
    }
  }, [activeKey]);

  const getFile = () => {
    if (invoiceId) {
      invoiceApi.getPreview(invoiceId).then((resp: any) => {
        if (resp) {
          fileApi.getFileByUrl(resp.originalFilename).then((response: any) => {
            setFileUrl(response);
          });
        }
      });
    }
  };

  return (
    <div>
      <div style={{ margin: '20px' }}>
        <Button buttonSize="medium" buttonStyle="primary" onClick={getFile}>
          Refresh
        </Button>
      </div>
      {fileUrl && (
        <embed
          src={fileUrl}
          type="application/pdf"
          style={{ width: '100%', height: '815px' }}
        />
      )}
    </div>
  );
};

export default PreviewContent;
