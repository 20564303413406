import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import {
  changeSelectedClient,
  initialClientAddress,
  initialClientContact,
  initialClientEmail,
  initialClientExtraInfo,
  initialClientPhone,
  initialShipping,
  setClientsList,
  setClientsPagination,
  setClientsSorting,
  setCountryCodeOptions,
  setCurrentDeleteInfo,
  setErrorsMessages,
  setIsUpdateSelectedClient,
  toogleErrorModal,
  updateDeleteInfo,
} from './sliceReducer';
import { batch } from 'react-redux';
import { clientApi } from '../../../common/api/services/ClientApi/clientApi';
import { countryCodeApi } from '../../../common/api/services/CountryCodeApi/countryCodeApi';
import { push } from 'redux-first-history';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { ClientEntity } from '../../../common/api/services/ClientApi/enums/ClientEntity';
import { IDeleteClientEntityRequest } from '../../../common/api/services/ClientApi/types/IUpdateClientEntity';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { TEntityResponse } from '../../../common/api/services/ClientApi/types/IClientResponse';

const { clients } = ROUTER_PATHS;

export const getClientList = createAsyncThunk(
  'clients/getClientList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const state = getState() as IRootState;
    const currentPagination = state.clients.clientList.pagination;
    const currentFilter = state.clients.clientList.filter;
    const currentSort = state.clients.clientList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await clientApi.getClients(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setClientsList(response));
        dispatch(setClientsPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeClientsSorting = createAsyncThunk(
  'clients/changeClientsSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).clients.clientList.sort;

    if (currentSortBy !== sortBy) {
      dispatch(setClientsSorting([currentSortBy, 'asc']));
      dispatch(getClientList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setClientsSorting([currentSortBy, 'desc']));
      dispatch(getClientList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setClientsSorting(['', '']));
      dispatch(getClientList({ currentPage: 1 }));
      return;
    }
  },
);

export const fetchCountryCodeOptions = createAsyncThunk(
  'clients/fetchCountryCodeOptions',
  async (_, { dispatch }) => {
    const response = await countryCodeApi.getAllCountryCode();
    dispatch(setCountryCodeOptions(response));
  },
);

export const createClient = createAsyncThunk(
  'clients/createClient',
  async (_, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const { selectedClient } = state.clients;
    const { isSuccess, data } = await clientApi.createClient(selectedClient);
    if (isSuccess) {
      dispatch(getClientList({ currentPage: 1 }));
      dispatch(push(clients));
    } else {
      dispatch(setErrorsMessages(data));
      dispatch(setIsUpdateSelectedClient(true));
    }
  },
);

export const getClientById = createAsyncThunk(
  'clients/getClientById',
  async (id: string, { dispatch }) => {
    const response = await clientApi.getClientById(id);

    if (response) {
      const {
        emails,
        phones,
        addresses,
        signatories,
        invoiceries,
        reminders,
        clientContacts,
        shippings,
        ...rest
      } = response;

      const defaultEmails = emails.length > 0 ? emails : [initialClientEmail];
      const defaultPhones = phones.length > 0 ? phones : [initialClientPhone];
      const defaultAddresses =
        addresses.length > 0 ? addresses : [{ ...initialClientAddress }];
      const defaultSignatories =
        signatories.length > 0 ? signatories : [{ ...initialClientExtraInfo }];
      const defaultInvoiceries =
        invoiceries.length > 0 ? invoiceries : [{ ...initialClientExtraInfo }];
      const defaultReminders =
        reminders.length > 0 ? reminders : [{ ...initialClientExtraInfo }];
      const defaultClientContacts =
        clientContacts.length > 0
          ? clientContacts
          : [{ ...initialClientContact }];
      const defaultShippings =
        shippings.length > 0 ? shippings : [initialShipping];
      const query = {
        emails: defaultEmails,
        phones: defaultPhones,
        addresses: defaultAddresses,
        signatories: defaultSignatories,
        invoiceries: defaultInvoiceries,
        reminders: defaultReminders,
        clientContacts: defaultClientContacts,
        shippings: defaultShippings,
        ...rest,
      };
      dispatch(changeSelectedClient(query));
    }
  },
);

export const updateClientById = createAsyncThunk(
  'clients/updateClientById',
  async (_, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const query = state.clients.selectedClient;
    const pathName = state.router.location?.pathname ?? '';
    const pathNameArr = pathName.split('/');
    const clientId = pathNameArr[pathNameArr.length - 1];

    const { isSuccess, data } = await clientApi.updateClient(clientId, {
      ...query,
      vat: query?.vat?._id || query?.vat,
    });
    if (isSuccess) {
      dispatch(getClientList({ currentPage: 1 }));
      dispatch(push(clients));
    } else {
      dispatch(setErrorsMessages(data));
      dispatch(setIsUpdateSelectedClient(true));
    }
  },
);

export const addNewClientEntity = createAsyncThunk(
  'clients/addNewClientEntity',
  async (entity: ClientEntity, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const currentClient = state.clients.selectedClient;
    const response = await clientApi.addNewEntity(currentClient._id, {
      entity,
    });

    const newClient = {
      ...currentClient,
      [entity]: [...currentClient[entity], response],
    };

    if (typeof response !== 'string') {
      dispatch(changeSelectedClient(newClient));
      dispatch(setIsUpdateSelectedClient(true));
    } else {
      openErrorNotification(response);
    }
  },
);

export const deleteClientEntity = createAsyncThunk(
  'clients/deleteClientEntity',
  async (data: IDeleteClientEntityRequest, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const currentClient = state.clients.selectedClient;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await clientApi.deleteEntity(currentClient._id, data);
    if (response.error) {
      dispatch(setCurrentDeleteInfo(data));
      dispatch(updateDeleteInfo(response));
      dispatch(toogleErrorModal(true));
      return;
    }

    const newClient = {
      ...currentClient,
      [data.entity]: (currentClient[data.entity] as TEntityResponse[]).filter(
        (el) => el._id !== data.id,
      ),
    };

    if (typeof response !== 'string') {
      dispatch(changeSelectedClient(newClient));
      dispatch(setIsUpdateSelectedClient(true));
    } else {
      openErrorNotification(response);
    }
  },
);
export const deleteClientEntityWithUpdate = createAsyncThunk(
  'clients/deleteClientEntity',
  async (data: IDeleteClientEntityRequest, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const currentClient = state.clients.selectedClient;
    const requestData = {
      id: data.id,
      entity: data.entity,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await clientApi.deleteEntityWithUpdate(
      data.prevId as string,
      requestData,
      currentClient._id,
    );

    if (typeof response !== 'string') {
      dispatch(changeSelectedClient(response));
      dispatch(toogleErrorModal(false));
      openSuccessNotification(`${data.entity || ''} was update success`);
    } else {
      openErrorNotification(response);
    }
  },
);

export const exportToExelClient = createAsyncThunk(
  'clients/exportToExelMonitoring',
  async (_, { getState }) => {
    const { clientList, selectedClients } = (getState() as IRootState).clients;
    const { sort, filter } = clientList;

    await clientApi.exportToExel(filter, sort, selectedClients);
  },
);
