import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { Space } from 'antd';
import Button from '../../components/button/Button';
import FilterIcon from '../../assets/icons/FilterIcon';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, boolean>) => void;
  defaultValues: Record<TNameParser, boolean>;
}

type TNameParser =
  | 'consultantDetails.approvedByAdmin'
  | 'consultantDetails.approvedByFinance';

const nameParser: Record<TNameParser, string> = {
  'consultantDetails.approvedByAdmin': 'By Admin',
  'consultantDetails.approvedByFinance': 'By Finance',
};

const defaultState = {
  ['consultantDetails.approvedByAdmin']: false,
  ['consultantDetails.approvedByFinance']: false,
};

const checkboxNames = [
  'consultantDetails.approvedByAdmin',
  'consultantDetails.approvedByFinance',
];

export const useTableApprovedByFilter = ({
  fetchRequest,
  changeValues,
  defaultValues,
}: IArguments) => {
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (
      defaultValues['consultantDetails.approvedByAdmin'] ||
      defaultValues['consultantDetails.approvedByFinance']
    ) {
      setIsFiltered(true);
    }

    if (
      !defaultValues['consultantDetails.approvedByAdmin'] &&
      !defaultValues['consultantDetails.approvedByFinance']
    ) {
      setIsFiltered(false);
    }
  }, [defaultValues]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (
      defaultValues['consultantDetails.approvedByAdmin'] ||
      defaultValues['consultantDetails.approvedByFinance']
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    fetchRequest();
    confirm();
  };

  const onChange = ({ target }: CheckboxChangeEvent) => {
    changeValues({
      ...defaultValues,
      [target.name as TNameParser]: target.checked,
    });
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({ ...defaultState });
    fetchRequest();
    confirm();
    setIsFiltered(false);
  };

  const onSelectAll = (values: any) => {
    const newObj: any = {};
    values.forEach((item: string) => (newObj[item] = true));
    changeValues({ ...newObj });
  };

  return (): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-select-filter-dropdown">
        <span
          onClick={() => onSelectAll(checkboxNames)}
          className="select-all-filters">
          Select all
        </span>
        <div className="selected-checkbox-group">
          {checkboxNames.map((item) => {
            return (
              <Checkbox
                name={item}
                key={item}
                onChange={onChange}
                checked={!!defaultValues[item as TNameParser]}>
                {nameParser[item as TNameParser]}
              </Checkbox>
            );
          })}
        </div>

        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            onClick={() => onClear(confirm)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};
