import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import EditLeadFooter from './components/EditLeadFooter';

import './Footer.less';
import ViewLeadFooter from './components/ViewLeadFooter';
import MyInformationFooter from './components/MyInformationFooter';
import { useAppSelector } from '../../redux/store';
import { OnboardingRequestStatuses } from '../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import ModifyClientFooter from './components/ModifyClientFooter';
import {
  createClient,
  updateClientById,
} from '../../redux/reducers/clients/asyncThunkActions';

const {
  waitingRoom,
  view,
  edit,
  myInformation,
  workPermit,
  bankAccount,
  avsAhv,
  clients,
  modify,
} = ROUTER_PATHS;
const { Footer: AntFooter } = Layout;

const Footer = () => {
  const { tabStatus } = useAppSelector((state) => state.onboarding);
  return (
    <AntFooter className="main-footer">
      <Routes>
        <Route
          path={waitingRoom + edit + '/:leadId'}
          element={<EditLeadFooter />}
        />
        <Route
          path={waitingRoom + view + '/:leadId'}
          element={<ViewLeadFooter />}
        />
        <Route
          path={clients + modify}
          element={<ModifyClientFooter onSubmit={createClient} />}
        />
        <Route
          path={clients + modify + '/:clientId'}
          element={<ModifyClientFooter onSubmit={updateClientById} />}
        />
        {tabStatus[3] !== OnboardingRequestStatuses.published && (
          <Route
            path={myInformation + workPermit}
            element={<MyInformationFooter />}
          />
        )}
        {tabStatus[4] !== OnboardingRequestStatuses.published && (
          <Route
            path={myInformation + bankAccount}
            element={<MyInformationFooter />}
          />
        )}
        {tabStatus[6] !== OnboardingRequestStatuses.published && (
          <Route
            path={myInformation + avsAhv}
            element={<MyInformationFooter />}
          />
        )}
      </Routes>
    </AntFooter>
  );
};

export default Footer;
