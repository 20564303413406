import { ROUTER_PATHS } from '../../../../navigation/routerPaths';

const {
  onboarding,
  personalInformation,
  workPermit,
  workContract,
  clientContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
} = ROUTER_PATHS;

export interface INavigationItems {
  to: string;
  width: string;
  label: string;
  key: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

export const navigationItems: INavigationItems[] = [
  {
    to: onboarding + personalInformation,
    width: '14%',
    label: 'Personal information',
    key: 0,
  },
  {
    to: onboarding + workContract,
    width: '11%',
    label: 'Work contract',
    key: 1,
  },
  {
    to: onboarding + clientContract,
    width: '13%',
    label: 'Client contract',
    key: 2,
  },
  {
    to: onboarding + workPermit,
    width: '11%',
    label: 'Work permit',
    key: 3,
  },
  {
    to: onboarding + bankAccount,
    width: '13%',
    label: 'Bank account',
    key: 4,
  },
  {
    to: onboarding + childAllowance,
    width: '13%',
    label: 'Children',
    key: 5,
  },
  {
    to: onboarding + avsAhv,
    width: '10%',
    label: 'Pension',
    key: 6,
  },
  {
    to: onboarding + additionalInformation,
    width: '15%',
    label: 'Additional information',
    key: 7,
  },
];

export const consultantNavigationItems = (
  leadId: string,
): INavigationItems[] => [
  {
    to: onboarding + `/${leadId}` + personalInformation,
    width: '14%',
    label: 'Personal information',
    key: 0,
  },
  {
    to: onboarding + `/${leadId}` + workContract,
    width: '11%',
    label: 'Work contract',
    key: 1,
  },
  {
    to: onboarding + `/${leadId}` + clientContract,
    width: '13%',
    label: 'Client contract',
    key: 2,
  },
  {
    to: onboarding + `/${leadId}` + workPermit,
    width: '11%',
    label: 'Work permit',
    key: 3,
  },
  {
    to: onboarding + `/${leadId}` + bankAccount,
    width: '13%',
    label: 'Bank account',
    key: 4,
  },
  {
    to: onboarding + `/${leadId}` + childAllowance,
    width: '13%',
    label: 'Children',
    key: 5,
  },
  {
    to: onboarding + `/${leadId}` + avsAhv,
    width: '10%',
    label: 'Pension',
    key: 6,
  },
  {
    to: onboarding + `/${leadId}` + additionalInformation,
    width: '15%',
    label: 'Additional information',
    key: 7,
  },
];
