import { FC, ReactElement } from 'react';
import { Dropdown, Button } from 'antd';

import './SimpleDropdown.less';

interface IProps {
  options: JSX.Element;
  children: string | ReactElement;
}

const SimpleDropdown: FC<IProps> = ({ options, children }) => {
  return (
    <Dropdown
      className="simple-dropdown"
      overlayClassName="simple-dropdown-body"
      overlay={options}>
      <Button>{children}</Button>
    </Dropdown>
  );
};

export default SimpleDropdown;
