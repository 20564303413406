import CrossIcon from '../../../assets/icons/CrossIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import { clearWaitingRoomFiltersAndSorts } from '../../../redux/reducers/waiting-room/sliceReducer';
import { getLeadsList } from '../../../redux/reducers/waiting-room/asyncThunkActions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const WaitingRoomExtraSection = () => {
  const dispatch = useAppDispatch();

  const { sort: waitingRoomSorts, filter: waitingRoomFilters } = useAppSelector(
    (state) => state.waitingRoom.leadList,
  );
  const isResetWaitingRoomFilters =
    waitingRoomSorts.sortBy !== '' ||
    Object.values(waitingRoomFilters).some((el) => el.length !== 0);

  return (
    <>
      {isResetWaitingRoomFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearWaitingRoomFiltersAndSorts());
            dispatch(getLeadsList({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}
      <a
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={process.env.REACT_APP_LINK_TO_SIMULATION_FORM!}
        target="_blank"
        rel="noopener noreferrer">
        <Button
          buttonStyle="primary"
          buttonSize="medium"
          buttonWidth={183}
          rightIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
          Create a lead
        </Button>
      </a>
    </>
  );
};

export default WaitingRoomExtraSection;
