import CrossIcon from '../../../assets/icons/CrossIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import { getEmployees } from '../../../redux/reducers/employees/asyncThunkActions';
import {
  clearFiltersAndSorts,
  updateOpenModalNewEmployee,
} from '../../../redux/reducers/employees/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const EmloyeeExtraSection = () => {
  const dispatch = useAppDispatch();
  const { sort: employeeSorts, filter: employeeFilters } = useAppSelector(
    (state) => state.employees.employeesList,
  );
  const isResetEmployeeFilters =
    employeeSorts.sortBy !== '' ||
    Object.values(employeeFilters).some((el) => el.length !== 0);
  return (
    <>
      {isResetEmployeeFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearFiltersAndSorts());
            dispatch(getEmployees({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}
      <Button
        onClick={() => dispatch(updateOpenModalNewEmployee(true))}
        buttonStyle="primary"
        buttonSize="medium"
        buttonWidth={160}
        rightIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
        Add new
      </Button>
    </>
  );
};

export default EmloyeeExtraSection;
