import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IState } from './types';
import { TAnyObject } from '../../../common/types/TAnyObject';

const initialState: IState = {
  firstName: '',
  lastName: '',
  phone: '',
  _id: '',
  email: '',
  refreshToken: '',
  token: '',
  isAccess: checkExistAccess(),
  errorMassage: '',
  role: '',
  status: '',
  consultantOnboarding: null,
};

const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    putUserData(state, action: PayloadAction<TAnyObject>) {
      return { ...state, ...action.payload };
    },
    changeIsAccess(state, action: PayloadAction<boolean>) {
      state.isAccess = action.payload;
    },
    clearAuthState() {
      return initialState;
    },
    setAuthMessage(state, action: PayloadAction<string>) {
      state.errorMassage = action.payload;
    },
  },
});

export const { putUserData, changeIsAccess, clearAuthState, setAuthMessage } =
  counterSlice.actions;
export default counterSlice.reducer;

function checkExistAccess(): boolean {
  const data = localStorage.getItem('persist:root');

  if (data) {
    const { token } = JSON.parse(data);
    return token !== '""';
  }

  return false;
}
