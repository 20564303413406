import { ChangeEvent, FC } from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';
import { countries } from '../../../../common/constants/mockCountries';
import { IBankAccountRequest } from '../../../../common/api/services/OnboardingApi/types/IBankAccountRequest';
import '../../components/navigation-tabs/NavigationTabs.less';

type TValues =
  | 'iban'
  | 'swift'
  | 'currency'
  | 'accountOwner'
  | 'address'
  | 'additionalAddress'
  | 'comment'
  | 'hasIBAN'
  | 'bankAccountDetails';

interface IProps {
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  onChangeDate: (fieldName: string) => (date: Date | null) => void;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => void;
  values: Omit<IBankAccountRequest, TValues>;
  getError: (fieldName: string) => string;
}

const BankAccountTabBankDetailsSection: FC<IProps> = ({
  onChange,
  onChangeDate,
  values,
  getError,
  onChangeDropdown,
}) => {
  const {
    hasBankAccount,
    bankName,
    bankAdditionalAddress,
    bankAddress,
    bankCountry,
    bankCity,
    bankChangeOption,
    dateOfBankChange,
    npa,
  } = values;

  return (
    <Col span={12}>
      <h4 className="bank-details-title">Bank details</h4>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="bankName"
            label="Name of the bank"
            value={bankName}
            onChange={onChange}
            errorMessage={getError('data.bankName')}
            disabled={!hasBankAccount}
          />
        </Col>
        <Col span={12}>
          <Input
            name="npa"
            label="Postcode of the Bank"
            value={npa}
            onChange={onChange}
            errorMessage={getError('data.npa')}
            placeholder="Code"
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="bankAddress"
            label="Bank address line 1"
            value={bankAddress}
            onChange={onChange}
            errorMessage={getError('data.bankAddress')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="bankAdditionalAddress"
            label="Bank address line 2"
            value={bankAdditionalAddress}
            onChange={onChange}
            errorMessage={getError('data.bankAdditionalAddress')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Dropdown
            options={countries}
            value={bankCountry || undefined}
            allowClear
            onChange={onChangeDropdown('bankCountry')}
            customClassName="dropdown-placeholder"
            placeholder="Type to search"
            label="Country bank"
            errorMessage={getError('data.bankCountry')}
            disabled={!hasBankAccount}
            isSearch
          />
        </Col>
        <Col span={12}>
          <Input
            name="bankCity"
            value={bankCity}
            onChange={onChange}
            label="City bank"
            errorMessage={getError('data.bankCity')}
            disabled={!hasBankAccount}
          />
        </Col>
      </Row>

      {/*<Row*/}
      {/*  justify="space-between"*/}
      {/*  align="middle"*/}
      {/*  gutter={[16, 16]}*/}
      {/*  style={{ height: 54 }}>*/}
      {/*  <Col>*/}
      {/*    <span>Do you plan to change your bank?</span>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Radio.Group*/}
      {/*      name="bankChangeOption"*/}
      {/*      value={bankChangeOption}*/}
      {/*      onChange={onChange}>*/}
      {/*      <Radio value={true}>Yes</Radio>*/}
      {/*      <Radio value={false}>No</Radio>*/}
      {/*    </Radio.Group>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      {/*{bankChangeOption && (*/}
      {/*  <Row*/}
      {/*    justify="space-between"*/}
      {/*    align="middle"*/}
      {/*    gutter={[16, 16]}*/}
      {/*    style={{ height: 54 }}>*/}
      {/*    <Col span={12}>*/}
      {/*      <span>Please, set the expected date of changing the bank</span>*/}
      {/*    </Col>*/}
      {/*    <Col span={12}>*/}
      {/*      <DatePicker*/}
      {/*        label={'Date'}*/}
      {/*        onChangeDate={onChangeDate('dateOfBankChange')}*/}
      {/*        defaultValue={dateOfBankChange}*/}
      {/*        errorMessage={getError('dateOfBankChange')}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </Col>
  );
};

export default BankAccountTabBankDetailsSection;
