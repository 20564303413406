import { ChangeEvent, FC, useState } from 'react';
import { Col, Modal, RadioChangeEvent, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  addNewOnboardingChildAllowances,
  changeChildAllowanceComment,
  clearOnboardingChildAllowances,
  clearOnboardingErrorsValidation,
  removeOnboardingChildAllowances,
  updateOnboardingChildAllowances,
  updateOnboardingChildAllowancesChildArray,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import Button from '../../../../components/button/Button';
import ChildAllowanceDocumentSection from './ChildAllowanceDocumentSection';
import ChildAllowanceInformationSection from './ChildAllowanceInformationSection';
import { createMask } from '../../../../common/utils/inputMaskUtils';
import moment from '../../../../common/constants/moment';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';

const genderParser: Record<'boy' | 'girl', string> = {
  boy: 'man',
  girl: 'woman',
};

const ChildAllowance: FC = () => {
  const dispatch = useAppDispatch();
  const { hasChildren, children, documents, notes } = useAppSelector(
    (state) => state.onboarding.childAllowances,
  );
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.childAllowances,
  );

  const [selectedForRemoving, setSelectedForRemoving] = useState(0);

  const onChangeRadio = (event: RadioChangeEvent): void => {
    const fieldValue = event.target.value;

    if (!fieldValue) {
      dispatch(clearOnboardingChildAllowances());
      dispatch(clearOnboardingErrorsValidation());
    }

    dispatch(updateOnboardingChildAllowances({ hasChildren: fieldValue }));
  };

  const onAddNewChild = (): void => {
    dispatch(addNewOnboardingChildAllowances());
  };

  const removeChild = (id: number): void => {
    dispatch(removeOnboardingChildAllowances(id));
    setSelectedForRemoving(0);
  };

  const onChangeChildInput =
    (id: number) =>
    (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent): void => {
      const fieldName = event.target.name!;
      const fieldValue = event.target.value;

      let field;

      if (fieldName === 'SSNumber') {
        field = {
          [fieldName]: createMask(
            fieldValue,
            children?.find((item) => item.id === id)?.SSNumber ?? '',
          ),
        };
      } else {
        field = { [fieldName]: fieldValue };
      }

      dispatch(updateOnboardingChildAllowancesChildArray({ id, field }));
    };

  const onChangeChildDate =
    (id: number, fieldName: string) =>
    (date: Date | null): void => {
      const value = date ? moment(date)?.format(commonYearDateFormat) : '';
      const field = { [fieldName]: value };
      dispatch(updateOnboardingChildAllowancesChildArray({ id, field }));
    };

  const onChangeChildDropdown =
    (id: number, fieldName: string) =>
    (data: any): void => {
      const field =
        fieldName === 'gender'
          ? { [fieldName]: genderParser[data as 'boy' | 'girl'] }
          : { [fieldName]: data };
      dispatch(updateOnboardingChildAllowancesChildArray({ id, field }));
    };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    }
    return '';
  };

  const onChangeNotes = (text: string) => {
    dispatch(changeChildAllowanceComment(text));
  };

  const childNumber =
    (children?.findIndex((child) => child.id === selectedForRemoving) ?? 0) + 1;

  return (
    <Row gutter={[32, 0]}>
      <ChildAllowanceInformationSection
        hasChildren={hasChildren}
        getError={getError}
        onAddNewChild={onAddNewChild}
        onChangeRadio={onChangeRadio}
        onChangeChildInput={onChangeChildInput}
        onChangeChildDate={onChangeChildDate}
        onChangeChildDropdown={onChangeChildDropdown}
        onRemove={setSelectedForRemoving}
        children={children}
        onChangeNotes={onChangeNotes}
        notes={notes}
      />

      <ChildAllowanceDocumentSection
        hasChildren={hasChildren}
        documents={documents}
        documentError={errorMessages['data.documents'] ?? ''}
      />

      {!!selectedForRemoving && (
        <Modal
          width={490}
          open={!!selectedForRemoving}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <h4 style={{ marginBottom: 32 }}>Delete</h4>
          <p>
            Are you sure you want to delete <b>Child {childNumber}</b>? This
            action is irreversible.
          </p>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() => setSelectedForRemoving(0)}>
                Cancel
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => removeChild(selectedForRemoving)}>
                Yes, delete
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Row>
  );
};

export default ChildAllowance;
