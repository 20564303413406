import { FC } from 'react';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WarningIcon from '../../../assets/icons/WarningIcon';
import SuccessIcon from '../../../assets/icons/SuccessIcon';
import { useAppSelector } from '../../../redux/store';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { Roles } from '../../../common/enums/Roles';
import { Select } from 'antd';
import '../MyInformationPage.less';

const HeaderNavigation: FC = () => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const { tabStatus } = useAppSelector((state) => state.onboarding);
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  if (window.screen.width <= 768) {
    return (
      <Select
        className="navigation-tabs-mobile"
        onSelect={(value) => navigate(value)}
        value={
          navigationItems.find((item) => pathName.includes(item.to))?.label
        }>
        {navigationItems.map(({ to, label, key }) => {
          const isActive = pathName.includes(to);

          const tabState =
            tabStatus[key] === OnboardingRequestStatuses.published ||
            (!isAdmin && tabStatus[key] === OnboardingRequestStatuses.toVerify)
              ? '__publish'
              : tabStatus[key] === OnboardingRequestStatuses.draft
              ? '__draft'
              : tabStatus[key] === OnboardingRequestStatuses.toConfirm ||
                (isAdmin &&
                  tabStatus[key] === OnboardingRequestStatuses.toVerify)
              ? '__to-confirm'
              : '';

          return (
            <Select.Option
              key={to}
              className={`${
                isActive ? '__active' : tabState
              } navigation-tab-mobile`}>
              <Link
                to={to}
                className={isActive ? '__active' : tabState}
                style={{ width: '100%' }}>
                {label}
                {(tabStatus[key] === OnboardingRequestStatuses.draft ||
                  tabStatus[key] === OnboardingRequestStatuses.toConfirm ||
                  (isAdmin &&
                    tabStatus[key] === OnboardingRequestStatuses.toVerify)) && (
                  <WarningIcon />
                )}
                {(tabStatus[key] === OnboardingRequestStatuses.published ||
                  (!isAdmin &&
                    tabStatus[key] === OnboardingRequestStatuses.toVerify)) && (
                  <SuccessIcon className="publish-icon" />
                )}
              </Link>
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  return (
    <nav className="navigation-tabs">
      {navigationItems.map(({ to, width, label, key }) => {
        const isActive = pathName.includes(to);

        const tabState =
          tabStatus[key] === OnboardingRequestStatuses.published ||
          (!isAdmin && tabStatus[key] === OnboardingRequestStatuses.toVerify)
            ? '__publish'
            : tabStatus[key] === OnboardingRequestStatuses.draft
            ? '__draft'
            : tabStatus[key] === OnboardingRequestStatuses.toConfirm ||
              (isAdmin && tabStatus[key] === OnboardingRequestStatuses.toVerify)
            ? '__to-confirm'
            : '';

        return (
          <Link
            key={to}
            to={to}
            style={{ width }}
            className={isActive ? '__active' : tabState}>
            {label}
            {(tabStatus[key] === OnboardingRequestStatuses.draft ||
              tabStatus[key] === OnboardingRequestStatuses.toConfirm ||
              (isAdmin &&
                tabStatus[key] === OnboardingRequestStatuses.toVerify)) && (
              <WarningIcon />
            )}
            {(tabStatus[key] === OnboardingRequestStatuses.published ||
              (!isAdmin &&
                tabStatus[key] === OnboardingRequestStatuses.toVerify)) && (
              <SuccessIcon className="publish-icon" />
            )}
          </Link>
        );
      })}
    </nav>
  );
};

export default HeaderNavigation;

const {
  myInformation,
  personalInformation,
  workPermit,
  workContract,
  clientContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
} = ROUTER_PATHS;

interface INavigationItems {
  to: string;
  width: string;
  label: string;
  key: number;
}

export const navigationItems: INavigationItems[] = [
  {
    to: myInformation + personalInformation,
    width: '14%',
    label: 'Personal information',
    key: 0,
  },
  {
    to: myInformation + workContract,
    width: '11%',
    label: 'Work contract',
    key: 1,
  },
  {
    to: myInformation + clientContract,
    width: '13%',
    label: 'Client contract',
    key: 2,
  },
  {
    to: myInformation + workPermit,
    width: '11%',
    label: 'Work permit',
    key: 3,
  },
  {
    to: myInformation + bankAccount,
    width: '13%',
    label: 'Bank account',
    key: 4,
  },
  {
    to: myInformation + childAllowance,
    width: '13%',
    label: 'Children',
    key: 5,
  },
  {
    to: myInformation + avsAhv,
    width: '10%',
    label: 'Pension',
    key: 6,
  },
  {
    to: myInformation + additionalInformation,
    width: '15%',
    label: 'Additional information',
    key: 7,
  },
];
