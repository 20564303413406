import BankDetails from './DetailsContent/BankDetails';
import GeneralInformation from './DetailsContent/GeneralInformation';
import PaymentInformation from './DetailsContent/PaymentInformation';
import '../../GenerateInvoicePage.less';
import TimesheetExpenseTable from './DetailsContent/TimesheetExpenseTable';
import {
  IInvoice,
  InvoiceLayout,
} from '../../../../common/api/services/InvoiceApi/types/types';
import { Form, FormInstance } from 'antd';
import { IClient } from '../../../../common/api/services/OnboardingApi/types/IClientContract';
import { IVATs } from '../../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { useEffect } from 'react';

interface IProps {
  invoice: IInvoice | null;
  form: FormInstance<any>;
  errorMessages: any;
  client: IClient | null;
  onChangeLayout: (value: InvoiceLayout) => void;
  onChangeInvoiceDate: (value: string) => void;
  setInvoice: any;
  saveAsDraft: () => void;
  vats: IVATs;
}

const DetailsContent = (props: IProps) => {
  const {
    invoice,
    form,
    errorMessages,
    client,
    onChangeLayout,
    onChangeInvoiceDate,
    setInvoice,
    saveAsDraft,
    vats,
  } = props;

  useEffect(() => {
    let element;
    element = document.querySelector('.error-message');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    element = document.querySelector('.error-text');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [errorMessages]);

  return (
    <Form form={form}>
      <div className="details-main-container">
        <div className="generate-details-container">
          <GeneralInformation
            errorMessages={errorMessages}
            client={client}
            form={form}
            onChangeLayout={onChangeLayout}
            invoice={invoice}
            vats={vats}
            saveAsDraft={saveAsDraft}
            setInvoice={setInvoice}
          />
          <BankDetails invoice={invoice} />
        </div>
        <PaymentInformation
          errorMessages={errorMessages}
          form={form}
          onChangeInvoiceDate={onChangeInvoiceDate}
          invoice={invoice}
        />
        <TimesheetExpenseTable
          invoice={invoice}
          errorMessages={errorMessages}
          form={form}
          setInvoice={setInvoice}
          saveAsDraft={saveAsDraft}
        />
      </div>
    </Form>
  );
};

export default DetailsContent;
