import { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';
import { useAppDispatch } from '../../../redux/store';
import { archiveLead } from '../../../redux/reducers/waiting-room/asyncThunkActions';
import { ISelectedLead } from '../../../redux/reducers/waiting-room/types';
import { setErrorMessage } from '../../../redux/reducers/waiting-room/sliceReducer';

interface IProps {
  leadDetails: ISelectedLead;
  hideModal: () => void;
}

const ModalArchiveContent: FC<IProps> = ({ leadDetails, hideModal }) => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, _id } = leadDetails.leadDetails;

  useEffect(() => {
    return () => {
      dispatch(setErrorMessage(null));
    };
  }, []);

  const archiveHandler = () => {
    dispatch(archiveLead(_id));
    hideModal();
  };

  return (
    <>
      <p className="archive-text">
        Are you sure you want to archive{' '}
        <b>
          {firstName} {lastName}?
        </b>{' '}
        This action is irreversible.
      </p>
      <Row gutter={[16, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={hideModal}>
            Cancel
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={archiveHandler}>
            Yes, archive
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModalArchiveContent;
