import { Input } from 'antd';
import { useAppDispatch } from '../../../../../redux/store';
import { updateTaxItem } from '../../../../../redux/reducers/onboarding/sliceReducer';

interface IProps {
  keyValue: string;
  index: number;
  value: string;
  disabled: boolean;
}

const TaxInput = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { keyValue, index, value, disabled } = props;
  return (
    <Input
      bordered={false}
      value={value}
      style={{ fontSize: '12px' }}
      onChange={(e) => {
        if (keyValue === 'city') {
          dispatch(
            updateTaxItem({
              index,
              key: 'isCityUpdated',
              value: true,
            }),
          );
        }
        dispatch(
          updateTaxItem({
            index,
            key: keyValue,
            value: e.target.value,
          }),
        );
      }}
      disabled={disabled}
    />
  );
};

export default TaxInput;
