export const isDocFormat = (url: string) => {
  return (
    ['.docx', '.xlsx'].includes(url.slice(-5).toLowerCase()) ||
    ['.doc', '.xls', '.pdf'].includes(url.slice(-4).toLowerCase())
  );
};

export const isImgFormat = (url: string) => {
  return (
    ['.jpeg'].includes(url.slice(-5).toLowerCase()) ||
    ['.svg', '.png', '.jpg'].includes(url.slice(-4).toLowerCase())
  );
};
