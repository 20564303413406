import { ColumnsType } from 'antd/lib/table';
import CommonTable from '../../../../components/common-table/CommonTable';
import {
  TTimesheetStatusParserKey,
  timesheetStatusParser,
} from '../../../../common/constants/timesheetStatusParser';
import { ROUTER_PATHS } from '../../../../navigation/routerPaths';
import { IActivitiesResponse } from '../../../../common/api/services/ActivityApi/types/IActivityReportResponse';

interface IProps {
  activitiesInfo: Array<IActivitiesResponse>;
  isLoadingActivities: boolean;
}
const { mainActivities } = ROUTER_PATHS;

const ActivitiesTable = (props: IProps) => {
  const { activitiesInfo, isLoadingActivities } = props;
  const data = activitiesInfo?.map((item) => ({
    publicId: item.publicId,
    status: item.status,
    name: item.workContract?.name,
    docType: item.docType,
    _id: item._id,
  }));

  const columns: ColumnsType<IActivitiesResponse> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Client',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td`,
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={timesheetStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 150,
    },
  ];
  const onDoubleClick = (record: any) => {
    window.open(
      record.docType === 'T'
        ? `${mainActivities}?mainActivityId=${record._id}&type=T`
        : `${mainActivities}?mainActivityId=${record._id}&type=E`,
      '_blank',
    );
  };
  return (
    <div className="seconary-table-container">
      <span className="title-text" style={{ margin: '16px 0' }}>
        Other activities
      </span>
      <CommonTable
        data={data}
        columns={columns}
        hidePagination={true}
        onDoubleClick={onDoubleClick}
        loading={isLoadingActivities}
        height={160}
        tableHeight={190}
      />
    </div>
  );
};

export default ActivitiesTable;
