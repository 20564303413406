import React, { FC, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { getDefaultFormatDate } from '../../../common/utils/getFormatDate';
import { useAppSelector } from '../../../redux/store';
import { Link } from 'react-router-dom';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import '../MyInformationPage.less';

const { published } = OnboardingRequestStatuses;

const WorkContractTab: FC = () => {
  const [selectedClientIdx, setSelectedClientIdx] = useState(0);
  const { companies } = useAppSelector(
    (state) => state.onboarding.workContract,
  );
  const isPublished = useAppSelector(({ onboarding }) =>
    onboarding.tabStatus.every((status) => status === published),
  );

  const clientCount = companies.length;

  const onSelectCurrentClient = (idx: number) => (): void => {
    setSelectedClientIdx(idx);
  };

  const {
    name = '',
    email = '',
    phone = '',
    // addresses: addressesFromState,
    hasAdditionalAccidentInsurance = true,
    incomeLossInsurance = '',
    lossOfIncome = '',
    hasCBA = false,
    cbaOption = '',
    hasTreasury = false,
    hasSalaryAmount = false,
    salaryAmount = '',
    invoiceCurrency = '',
    paymentCurrency = '',
    jobTitle = '',
    projectDescription = '',
    startDate = '',
    endDate = '',
    indefinite = true,
    rate = '',
    type = '',
    workLocation: workLocationFromState,
    otherJob: otherJobFromState,
    overtimeAllowed = true,
    doubleTimeAllowed = true,
    professionalAccident = '',
    nonProfessionalAccident = '',
    numeriqOffice = '',
  } = companies[selectedClientIdx];

  // const addresses =
  //   addressesFromState.length > 0
  //     ? addressesFromState
  //     : [{ street: '', city: '', country: '', postCode: '' }];

  const workLocation = workLocationFromState || {
    type: '',
    hybridActivity: '',
    officeAddress: '',
    hybridAddress: '',
  };

  const {
    type: workLocationType,
    hybridActivity,
    officeAddress,
  } = workLocation;

  const otherJob = otherJobFromState || {
    active: true,
    isPrimaryJob: true,
    numeriqTime: 0,
  };

  const { active, isPrimaryJob, numeriqTime } = otherJob;

  return (
    <div className="tab-container my-information-tab">
      <h4>Information</h4>
      {clientCount !== 1 && (
        <div
          style={{
            marginBottom: '1rem',
            marginTop: '1rem',
            overflowX: clientCount > 4 ? 'auto' : 'visible',
            width: '100%',
          }}>
          <nav
            className="navigation-tabs"
            style={{ marginBottom: 0, justifyContent: 'flex-start' }}>
            {companies.map(({ _id, name = '' }, idx) => {
              const isActive = selectedClientIdx === idx;
              const tabName = name.length > 0 ? name : `Client ${idx + 1}`;
              return (
                <Col key={_id} span={6}>
                  <Popover
                    placement="top"
                    content={tabName}
                    trigger="hover"
                    overlayClassName="info-pop-content"
                    showArrow={false}>
                    <Link
                      to={'#'}
                      className={isActive ? '__active cut-text' : 'cut-text'}
                      onClick={onSelectCurrentClient(idx)}>
                      <span className="tab-label">{tabName}</span>
                    </Link>
                  </Popover>
                </Col>
              );
            })}
          </nav>
        </div>
      )}

      <div className="work-contract-my-info my-information-tab">
        <Col span={32} className="my-information-tab">
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Client company name</span>
                  <span className="item-value">{name}</span>
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <div className="personal-info">
                  <span className="item-label">Email</span>
                  <span className="item-value">{email}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Phone</span>
                  <span className="item-value">{phone}</span>
                </div>
              </Col>
            </Row>
          </Col>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">
                  Complementary accident insurance
                </span>
                <span className="item-value">
                  {hasAdditionalAccidentInsurance ? 'Yes' : 'No'}
                </span>
              </div>
            </Col>
          </Row>

          {/* {isPublished && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div className="personal-info">
                    <span className="item-label">Professional accident %</span>
                    <span className="item-value">
                      {professionalAccident || '-'}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="personal-info">
                    <span className="item-label">
                      Non-professional accident %
                    </span>
                    <span className="item-value">
                      {nonProfessionalAccident || '-'}
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          )} */}
          <h4 className="others-title ">Currency</h4>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <div className="personal-info">
                <span className="item-label">Invoice currency</span>
                <span className="item-value">{invoiceCurrency}</span>
              </div>
            </Col>

            <Col span={8}>
              <div className="personal-info">
                <span className="item-label">Payment currency</span>
                <span className="item-value">{paymentCurrency}</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={12} className="my-information-tab">
          <h4>Job details</h4>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Job title</span>
                <span className="item-value">{jobTitle}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Project description</span>
                <span className="item-value">{projectDescription}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Start date</span>
                <span className="item-value">
                  {getDefaultFormatDate(startDate)}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">End date</span>
                <span className="item-value">
                  {endDate ? getDefaultFormatDate(endDate) : '-'}
                </span>
              </div>
            </Col>
          </Row>

          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Indefinite</span>
                <span className="item-value">{indefinite ? 'Yes' : 'No'}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Rate</span>
                <span className="item-value">{rate}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Type</span>
                <span className="item-value">{type}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Work location</span>
                <span className="item-value">{workLocationType}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Numeriq Office</span>
                <span className="item-value">{numeriqOffice || '-'}</span>
              </div>
            </Col>
          </Row>

          {workLocationType === 'Hybrid' && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="personal-info">
                    <span className="item-label">
                      For hybrid % work from home
                    </span>
                    <span className="item-value">{hybridActivity}</span>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="personal-info">
                    <span className="item-label">Address of the office</span>
                    <span className="item-value">{officeAddress}</span>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {workLocationType === 'Office' && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Address of the office</span>
                  <span className="item-value">{officeAddress}</span>
                </div>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">
                  Do you have other job (not in Numeriq)?
                </span>
                <span className="item-value">{active ? 'Yes' : 'No'}</span>
              </div>
            </Col>
          </Row>

          {active && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="personal-info">
                    <span className="item-label">Is it a primary job?</span>
                    <span className="item-value">
                      {isPrimaryJob ? 'Yes' : 'No'}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="personal-info">
                    <span className="item-label">
                      % of time you work for Numeriq
                    </span>
                    <span className="item-value">{numeriqTime}</span>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/* {isPublished && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Overtime allowed:</span>
                  <span className="item-value">
                    {overtimeAllowed ? 'Yes' : 'No'}
                  </span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Double time allowed:</span>
                  <span className="item-value">
                    {doubleTimeAllowed ? 'Yes' : 'No'}
                  </span>
                </div>
              </Col>
            </Row>

            <Row justify="space-between" align="middle" gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Expenses for optimization</span>
                  <span className="item-value">
                    {useExpensesForOptimization ? 'Yes' : 'No'}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )} */}
        </Col>
      </div>
    </div>
  );
};

export default WorkContractTab;
