import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { push } from 'redux-first-history';
import { ILink } from './types/ILink';
import { IApiRouteParams } from './types/IApiRouteParams';
import { getApiRoute } from './utils/getApiRoute';
import { TAnyObject } from '../types/TAnyObject';
import { getBearerToken } from './utils/getBearerToken';
import { store } from '../../redux/store';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { clearAuthState } from '../../redux/reducers/auth/sliceReducer';

const { login } = ROUTER_PATHS;

export interface IAxiosRequestConfig
  extends Omit<AxiosRequestConfig, 'params'>,
    Omit<ILink, 'page' | 'href' | 'query'>,
    IApiRouteParams {}

const firstRefreshTokenMount = { flag: false };

const apiAxiosInstance: AxiosInstance = Axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
});

apiAxiosInstance.interceptors.request.use(
  (config) => {
    const tokenFromLocalStorage = getBearerToken();
    const tokenFromStore = store.getState().auth.token;

    if (!!tokenFromLocalStorage || tokenFromStore) {
      config.headers!['Authorization'] =
        tokenFromLocalStorage || tokenFromStore;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

apiAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config: originalRequest, response } = error;
    if (
      (!firstRefreshTokenMount.flag && response.status === 403) ||
      (response.status === 401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      const authState = store.getState().auth;

      if (!authState.token && !authState.refreshToken) {
        store.dispatch(clearAuthState());
        store.dispatch(push(login));

        return Promise.reject(error);
      }

      store.dispatch(clearAuthState());
      store.dispatch(push(login));
    }

    return Promise.reject(error);
  },
);

export abstract class ApiServiceBase {
  protected baseUrl = '';
  private readonly axios: AxiosInstance = apiAxiosInstance;

  private static async response<Response extends TAnyObject>(
    promise: AxiosPromise<Response>,
  ): Promise<Response> {
    const response = await promise;

    return response.data;
  }

  protected post<
    Request extends TAnyObject,
    Response extends TAnyObject = TAnyObject,
  >(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return ApiServiceBase.response<Response>(
      this.axios.post(
        getApiRoute(this.baseUrl + url, { params, query }),
        data,
        config,
      ),
    );
  }
  protected patch<
    Request extends TAnyObject,
    Response extends TAnyObject = TAnyObject,
  >(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return ApiServiceBase.response<Response>(
      this.axios.patch(
        getApiRoute(this.baseUrl + url, { params, query }),
        data,
        config,
      ),
    );
  }

  protected get<Response extends TAnyObject>(
    url = '',
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return ApiServiceBase.response<Response>(
      this.axios.get(
        getApiRoute(this.baseUrl + url, { params, query }),
        config,
      ),
    );
  }

  protected put<
    Request extends TAnyObject,
    Response extends TAnyObject = TAnyObject,
  >(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return ApiServiceBase.response<Response>(
      this.axios.put(
        getApiRoute(this.baseUrl + url, { params, query }),
        data,
        config,
      ),
    );
  }

  protected delete<Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: TAnyObject,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return ApiServiceBase.response<Response>(
      this.axios.delete(getApiRoute(this.baseUrl + url, { params, query }), {
        ...config,
        data,
      }),
    );
  }

  protected formatFormData<Data extends TAnyObject>(body: Data): FormData {
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      const item = body[key];

      if (Array.isArray(item)) {
        item.forEach((itemElement) => {
          formData.append(key, itemElement);
        });

        return;
      }

      formData.append(key, item);
    });

    return formData;
  }
}
