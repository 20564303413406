import { Modal } from 'antd';
import Button from '../../../../components/button/Button';
import { InvoiceSentOption } from '../../../../common/api/services/InvoiceApi/types/types';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sendInvoice: (type: InvoiceSentOption) => void;
}

const SendModal = (props: IProps) => {
  const { isOpen, setIsOpen, sendInvoice } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      centered
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div className="send-modal-container">
        <span style={{ fontSize: '16px' }}>How to send this invoice?</span>
        <div className="send-modal-btns">
          <Button
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={() => sendInvoice(InvoiceSentOption.DIRECTLY)}>
            Send directly
          </Button>
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => sendInvoice(InvoiceSentOption.DOUBLE_CHECK)}>
            Send for double check
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SendModal;
