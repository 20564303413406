import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const EditIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.673 3.08316C12.6161 2.13868 14.1456 2.1381 15.0894 3.08187L17.1659 5.15841C18.1017 6.09413 18.1114 7.60895 17.1877 8.55661L9.49345 16.451C8.88199 17.0783 8.04336 17.4321 7.16765 17.4321L4.87419 17.432C3.73708 17.4319 2.82898 16.484 2.87681 15.3471L2.97527 13.0062C3.0095 12.1924 3.34774 11.421 3.92307 10.8448L11.673 3.08316ZM14.0295 4.14333C13.6716 3.78535 13.0914 3.78557 12.7337 4.14382L11.4361 5.44337L14.8247 8.83201L16.1143 7.50884C16.4647 7.14939 16.461 6.5748 16.1061 6.21987L14.0295 4.14333ZM4.98373 11.9055L10.3762 6.50483L13.7777 9.90628L8.42007 15.4032C8.09082 15.741 7.63925 15.9315 7.16771 15.9315L4.87426 15.9314C4.58998 15.9314 4.36296 15.6944 4.37491 15.4102L4.47337 13.0693C4.49181 12.6311 4.67394 12.2158 4.98373 11.9055ZM17.5958 17.371C18.0099 17.371 18.3455 17.0351 18.3455 16.6207C18.3455 16.2063 18.0099 15.8704 17.5958 15.8704H12.4944C12.0803 15.8704 11.7447 16.2063 11.7447 16.6207C11.7447 17.0351 12.0803 17.371 12.4944 17.371H17.5958Z"
        fill="#ABBED1"
      />
    </svg>
  );
};

export default EditIcon;
