import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import * as XLSX from 'xlsx';
import { useWindowDimensions } from '../../../common/hooks/useWindowDimensions';

const DynamicExcelTable = ({ fileBlob }: any) => {
  const [columns, setColumns] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const { height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length > 0) {
        const headers = jsonData[0] as any;
        const dataRows = jsonData.slice(1) as any;
        const dynamicColumns = headers.map((header: any, index: number) => ({
          title: header,
          dataIndex: header,
          key: index,
          width: 150,
        }));
        const tableData = dataRows.map((row: any, index: number) => {
          const rowData: any = {};
          row.forEach((element: any, idx: any) => {
            rowData[headers[idx]] = element;
          });
          return { key: index, ...rowData };
        });

        setColumns(dynamicColumns);
        setData(tableData);
      }
    };

    reader.readAsArrayBuffer(fileBlob);
  }, [fileBlob]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      scroll={{ x: 400, y: windowHeight - 350 }}
    />
  );
};

export default DynamicExcelTable;
