import { FC } from 'react';
import { Col, Row } from 'antd';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import UploadedFileIcon from '../../../assets/icons/UploadedFileIcon';
import { IUploadFileResponse } from '../../../common/api/services/FileApi/types/IUploadFileResponse';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  documents: IUploadFileResponse[];
}

const DocumentsViewer: FC<IProps> = ({ documents }) => {
  return (
    <>
      {documents.map(({ originalFilename }) => {
        const nameArrayStr = originalFilename.split('.');
        return (
          <Row gutter={[16, 16]} key={originalFilename}>
            <Col span={12}>
              <div className="personal-info">
                <a
                  onClick={() => fileApi.downloadFileByUrl(originalFilename)}
                  className="file-field">
                  <span className="svg-wrapper">
                    <UploadedFileIcon />
                  </span>
                  <span className="cut-filename">
                    {nameArrayStr.slice(0, nameArrayStr.length - 1).join('.')}
                  </span>
                  .{nameArrayStr[nameArrayStr.length - 1]}
                </a>
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default DocumentsViewer;
