import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  putFileOnboardingWorkPermit,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../../common/api/services/FileApi/enums/RequestEnums';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IOnboardingSaveOptions, IWorkPermit } from './../types';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftWorkPermitAsEmployee = createAsyncThunk(
  'onboarding/saveDraftWorkPermit',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { workPermit, tabStatus, consultantId } = (getState() as IRootState)
      .onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const isDraft =
      tabStatus[3] === OnboardingRequestStatuses.empty ||
      tabStatus[3] === OnboardingRequestStatuses.draft;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: workPermit,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putWorkPermitAsEmployee(
        consultantId,
        dataQuery,
      );

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 3, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 3,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workPermit',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveToConfirmWorkPermitAsEmployee = createAsyncThunk(
  'onboarding/saveToConfirmWorkPermit',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { workPermit, tabStatus, consultantId } = (getState() as IRootState)
      .onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: workPermit,
    };

    const { isSuccess, data } = await onboardingApi.putWorkPermitAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 3, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workPermit',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftWorkPermitAsConsultant = createAsyncThunk(
  'onboarding/saveDraftWorkPermitAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { workPermit, tabStatus } = (getState() as IRootState).onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const isDraft =
      tabStatus[3] === OnboardingRequestStatuses.empty ||
      tabStatus[3] === OnboardingRequestStatuses.draft;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: workPermit,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putWorkPermitAsConsultant(
        dataQuery,
      );
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 3, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 3,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'workPermit',
            fieldValue: data,
          }),
        );
      }
    }
  },
);
export const savePublishWorkPermitAsEmployee = createAsyncThunk(
  'onboarding/savePublishWorkPermit',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { workPermit, personalInfo, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const prevCurrentTabStatus = tabStatus[3];

    const isSwiss = personalInfo.nationality === 'Switzerland';
    const isCondition =
      (!isSwiss &&
        !workPermit.hasWorkPermit &&
        !workPermit.selfHandledWorkPermit &&
        workPermit.basisOfLegalStay) ||
      isSwiss ||
      workPermit.hasWorkPermit;

    const requestStatus = isCondition
      ? OnboardingRequestStatuses.published
      : OnboardingRequestStatuses.toConfirm;

    const requestType = isCondition
      ? OnboardingRequestTypes.required
      : OnboardingRequestTypes.optional;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: requestStatus,
      type: requestType,
      data: workPermit,
    };

    const { isSuccess, data } = await onboardingApi.putWorkPermitAsEmployee(
      consultantId,
      dataQuery,
    );

    dispatch(
      setOnboardingTabStatus({
        key: 3,
        value: isCondition
          ? OnboardingRequestStatuses.published
          : OnboardingRequestStatuses.toConfirm,
        isAdmin: true,
      }),
    );

    if (isSuccess) {
      if (!isSwiss) {
        dispatch(push(getOnboardingRedirect(tabStatus, 3, redirectRootRout)));
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workPermit',
          fieldValue: data,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: prevCurrentTabStatus,
          isAdmin: true,
        }),
      );
    }
  },
);

export const savePublishWorkPermitAsConsultant = createAsyncThunk(
  'onboarding/savePublishWorkPermitAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { workPermit, personalInfo, tabStatus } = (getState() as IRootState)
      .onboarding;
    const prevCurrentTabStatus = tabStatus[3];

    const isSwiss = personalInfo.nationality === 'Switzerland';
    const isCondition =
      (!isSwiss &&
        !workPermit.hasWorkPermit &&
        !workPermit.selfHandledWorkPermit &&
        workPermit.basisOfLegalStay) ||
      isSwiss ||
      workPermit.hasWorkPermit;

    const requestStatus = isCondition
      ? OnboardingRequestStatuses.published
      : OnboardingRequestStatuses.toConfirm;

    const requestType = isCondition
      ? OnboardingRequestTypes.required
      : OnboardingRequestTypes.optional;

    const dataQuery: IQueryParameters<IWorkPermit> = {
      key: OnboardingRequestKeys.workPermit,
      status: requestStatus,
      type: requestType,
      data: workPermit,
    };

    const { isSuccess, data } = await onboardingApi.putWorkPermitAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    dispatch(
      setOnboardingTabStatus({
        key: 3,
        value: isCondition
          ? OnboardingRequestStatuses.published
          : OnboardingRequestStatuses.toConfirm,
      }),
    );

    if (isSuccess) {
      if (!isSwiss) {
        dispatch(push(getOnboardingRedirect(tabStatus, 3, redirectRootRout)));
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'workPermit',
          fieldValue: data,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: prevCurrentTabStatus,
        }),
      );
    }
  },
);
export const uploadFileOnboardingWorkPermit = createAsyncThunk(
  'onboarding/uploadFileOnboardingWorkPermit',
  async (files: File[], { dispatch }) => {
    const response = await fileApi.uploadFile(
      files,
      UploadEntityId.workPermit,
      UploadEntity.consultantOnboarding,
    );

    if (response) {
      dispatch(putFileOnboardingWorkPermit(response));
    }
  },
);
