import { IClientResponse } from '../../../../common/api/services/ClientApi/types/IClientResponse';
import { IAddress } from '../../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { IGetListDto } from '../../../../common/api/types/IGetListDto';
import ILabelInValue from '../../../../common/types/ILabelInValue';

export interface IInitWorkContract {
  isFetching: boolean;
  searchName: string;
  selectedName: ILabelInValue | null;
  listOfNames: ILabelInValue[];
  searchEmail: string;
  selectedEmail: string | null;
  listOfEmails: string[];
  searchPhone: string;
  selectedPhone: string | null;
  listOfPhones: string[];
  response: IGetListDto<IClientResponse> | null;
  addresses: IAddressCompany[];
  listOfAddresses: ILabelInValue[];
  fullAddresses: IAddress[];
}

export interface IAddressCompany {
  searchStreet: string;
  selectedStreet: ILabelInValue | null;
}

export const initWorkContract: IInitWorkContract = {
  isFetching: false,
  searchName: '',
  selectedName: null,
  listOfNames: [],
  searchEmail: '',
  selectedEmail: null,
  listOfEmails: [],
  searchPhone: '',
  selectedPhone: null,
  listOfPhones: [],
  response: null,
  addresses: [
    {
      searchStreet: '',
      selectedStreet: null,
    },
  ],
  listOfAddresses: [],
  fullAddresses: [],
};
