import { FC, Key, useEffect } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionMenu from './components/ActionsMenu';
import {
  changeClientsSorting,
  fetchCountryCodeOptions,
  getClientList,
} from '../../redux/reducers/clients/asyncThunkActions';
import {
  changeClientsFiltering,
  selectClientsList,
} from '../../redux/reducers/clients/sliceReducer';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { IClientRow } from './types';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { vatOptions } from '../../common/constants/vatOptions';
import TableTooltip from '../../components/common-table/table-tooltip';
import FormatPhoneNumber from '../../components/format-phone-number/FormatPhoneNumber';

const { clients, modify } = ROUTER_PATHS;

const ClientsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(fetchCountryCodeOptions());
  }, []);

  const { clientList, selectedClients } = useAppSelector(
    (state) => state.clients,
  );

  const openEditPage = (clientId: string) => () => {
    navigation(clients + modify + '/' + clientId);
  };

  useEffect(() => {
    dispatch(getClientList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { content, pagination, sort, filter } = clientList;
  const { totalElement, currentPage, perPage } = pagination;

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeClientsFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => dispatch(getClientList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeClientsSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getClientList({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectClientsList(newSelectedRowKeys as string[]));
  };

  const columns: ColumnsType<IClientRow> = [
    {
      title: 'Client #',
      dataIndex: 'publicId',
      key: 'publicId',
      width: 105,
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      fixed: 'left',
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      sortDirections: ['descend'],
      width: 220,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={220}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: "Client's invoicing address",
      dataIndex: 'street',
      key: 'street',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.street as string) ?? '',
      })('street'),
      width: 230,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={230}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Case postal',
      dataIndex: 'postCode',
      key: 'postCode',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.postCode as string) ?? '',
      })('postCode'),
      width: 130,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.city as string) ?? '',
      })('city'),
      width: 120,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={120}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.country as string) ?? '',
      })('country'),
      width: 125,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={125}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Country code',
      dataIndex: 'countryCode',
      key: 'countryCode',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.countryCode as string) ?? '',
      })('countryCode'),
      width: 150,
    },
    {
      title: 'Payment terms',
      dataIndex: 'paymentTermRange',
      key: 'paymentTermRange',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.paymentTermRange as string) ?? '',
      })('paymentTermRange'),
      width: 150,
    },
    {
      title: 'Shipping method',
      dataIndex: 'shippingMethod',
      key: 'shippingMethod',
      width: 140,
    },
    {
      title: 'Invoicing email',
      dataIndex: 'invoiceries',
      key: 'invoiceries',
      width: 135,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={135}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Reminder email',
      dataIndex: 'reminders',
      key: 'reminders',
      width: 190,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={190}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: "Client's contact",
      dataIndex: 'contact',
      key: 'contact',
      width: 175,
      render: (text: string, record: any) => {
        return (
          <TableTooltip
            tootltipTitle={
              record.clientContacts?.length > 0
                ? record.clientContacts[0].email
                : ''
            }
            columnWidth={175}>
            <span>
              {record.clientContacts?.length > 0
                ? record.clientContacts[0].email
                : ''}
            </span>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Phone number',
      dataIndex: 'phones',
      key: 'phones',
      width: 180,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={180}>
          <FormatPhoneNumber number={text} />
        </TableTooltip>
      ),
    },
    {
      title: 'TVA',
      dataIndex: 'tva',
      key: 'tva',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.tva as string[]) ?? [],
        fieldFilterName: 'tva',
      })(vatOptions),
      width: 120,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={120}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Factoring',
      dataIndex: 'isFactoring',
      key: 'isFactoring',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.isFactoring as string[]) ?? [],
        fieldFilterName: 'isFactoring',
      })(['Yes', 'No']),
      width: 120,
    },
    {
      title: 'Register in CS',
      dataIndex: 'isRegisteredAtCreditSuisse',
      key: 'isRegisteredAtCreditSuisse',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.isRegisteredAtCreditSuisse as string[]) ?? [],
        fieldFilterName: 'isRegisteredAtCreditSuisse',
      })(['Yes', 'No']),
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 75,
      render: (_, { id }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={<ActionMenu openEditPage={openEditPage(id)} />}>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  const data = content.map((client) => ({
    id: client._id,
    publicId: client?.publicId ?? '',
    clientContacts: client.clientContacts || [],
    clientName: client.name,
    street: client.addresses[0]?.street ?? 'No',
    postCode: client.addresses[0]?.postCode ?? 'No',
    city: client.addresses[0]?.city ?? 'No',
    country: client.addresses[0]?.country ?? 'No',
    countryCode: client.countryCode ?? 'No',
    paymentTermRange: client.paymentTermRange ?? 'No',
    shippingMethod: client.shippings[0]?.method ?? 'No',
    emails: client.emails[0]?.email ?? 'No',
    phones: client.phones[0]?.phone ?? 'No',
    invoiceries: client.invoiceries[0]?.email ?? 'No',
    reminders: client.reminders[0]?.email ?? 'No',
    tva: client.vat?.name ?? 'No',
    isFactoring: client.isFactoring ? 'Yes' : 'No',
    isRegisteredAtCreditSuisse: client.isRegisteredAtCreditSuisse
      ? 'Yes'
      : 'No',
  }));

  const onDoubleClick = (record: any) => {
    openEditPage(record.id)();
  };

  const rowSelection = {
    selectedRowKeys: selectedClients,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChangeSort}
        rowKey="id"
        onDoubleClick={onDoubleClick}
        rowSelection={rowSelection}
      />
    </>
  );
};

export default ClientsPage;
