import { ExpensesStatus } from '../api/services/ConsultantApi/enums/ResponseEnums';
import { TimesheetStatus } from '../api/services/TimesheetApi/enums/TimesheetStatus';

const baseResult = 'No';

export const getTimesheetsStatus = (statuses: string[]) => {
  if (statuses.length === 0) {
    return baseResult;
  }
  const totalExpired = statuses.filter(
    (s) => s === TimesheetStatus.EXPIRED,
  ).length;
  // NOTE: due to NP-863
  if (totalExpired === statuses.length) {
    return TimesheetStatus.EXPIRED;
  }

  if (statuses.includes(TimesheetStatus.NO_ACTIVITY)) {
    return TimesheetStatus.NO_ACTIVITY;
  }

  if (statuses.includes(TimesheetStatus.REJECTED)) {
    return TimesheetStatus.REJECTED;
  }

  if (statuses.includes(TimesheetStatus.DRAFT)) {
    return TimesheetStatus.DRAFT;
  }

  if (statuses.includes(TimesheetStatus.WAITING_FOR_APPROVAL)) {
    return TimesheetStatus.WAITING_FOR_APPROVAL;
  }

  if (statuses.includes(TimesheetStatus.CLIENT_APPROVAL)) {
    return TimesheetStatus.CLIENT_APPROVAL;
  }

  if (statuses.includes(TimesheetStatus.PRE_APPROVED)) {
    return TimesheetStatus.PRE_APPROVED;
  }

  if (statuses.includes(TimesheetStatus.APPROVED)) {
    return TimesheetStatus.APPROVED;
  }

  return baseResult;
};

export const getClientExpensesStatus = (statuses: string[]) => {
  if (statuses.length === 0) {
    return baseResult;
  }
  const totalExpired = statuses.filter(
    (s) => s === ExpensesStatus.EXPIRED,
  ).length;
  // NOTE: due to NP-863
  if (totalExpired === statuses.length) {
    return ExpensesStatus.EXPIRED;
  }

  if (statuses.includes(ExpensesStatus.NO_EXPENSES)) {
    return ExpensesStatus.NO_EXPENSES;
  }

  if (statuses.includes(ExpensesStatus.REJECTED)) {
    return ExpensesStatus.REJECTED;
  }

  if (statuses.includes(ExpensesStatus.DRAFT)) {
    return ExpensesStatus.DRAFT;
  }

  if (statuses.includes(ExpensesStatus.WAITING_FOR_APPROVAL)) {
    return ExpensesStatus.WAITING_FOR_APPROVAL;
  }

  if (statuses.includes(ExpensesStatus.CLIENT_APPROVAL)) {
    return ExpensesStatus.CLIENT_APPROVAL;
  }

  if (statuses.includes(ExpensesStatus.PRE_APPROVED)) {
    return ExpensesStatus.PRE_APPROVED;
  }

  if (statuses.includes(ExpensesStatus.APPROVED)) {
    return ExpensesStatus.APPROVED;
  }

  return baseResult;
};

export const getOptimisationExpensesStatus = (statuses: string[]) => {
  if (statuses.length === 0) {
    return baseResult;
  }

  const totalExpired = statuses.filter(
    (s) => s === ExpensesStatus.EXPIRED,
  ).length;
  // NOTE: due to NP-863
  if (totalExpired === statuses.length) {
    return ExpensesStatus.EXPIRED;
  }

  const totalNoExpenses = statuses.filter(
    (s) => s === ExpensesStatus.NO_EXPENSES,
  ).length;
  if (totalNoExpenses > 1 || totalNoExpenses === statuses.length) {
    return ExpensesStatus.NO_EXPENSES;
  }

  if (statuses.includes(ExpensesStatus.REJECTED)) {
    return ExpensesStatus.REJECTED;
  }

  if (statuses.includes(ExpensesStatus.DRAFT)) {
    return ExpensesStatus.DRAFT;
  }

  if (statuses.includes(ExpensesStatus.WAITING_FOR_APPROVAL)) {
    return ExpensesStatus.WAITING_FOR_APPROVAL;
  }

  if (statuses.includes(ExpensesStatus.APPROVED)) {
    return ExpensesStatus.APPROVED;
  }

  return baseResult;
};
