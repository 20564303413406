import { ColumnsType } from 'antd/lib/table';
import {
  TTimesheetStatusParserKey,
  timesheetStatusParser,
} from '../../../../common/constants/timesheetStatusParser';
import CommonTable from '../../../../components/common-table/CommonTable';
import { ROUTER_PATHS } from '../../../../navigation/routerPaths';

interface IProps {
  expencesInfo: any;
  isLoadingEO: boolean;
}
const { mainActivities } = ROUTER_PATHS;

const EOTable = (props: IProps) => {
  const { expencesInfo, isLoadingEO } = props;

  const data = expencesInfo?.map((item: any) => ({
    publicId: item.publicId,
    status: item.status,
    total: item.total,
    _id: item._id,
  }));

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 80,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td`,
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={timesheetStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 150,
    },
  ];

  const onDoubleClick = (record: any) => {
    window.open(
      `${mainActivities}?mainActivityId=${record._id}&type=E`,
      '_blank',
    );
  };

  return (
    <div className="seconary-table-container-report">
      <span className="title-text" style={{ margin: '16px 0' }}>
        Expenses optimization
      </span>
      <CommonTable
        data={data}
        columns={columns}
        hidePagination={true}
        onDoubleClick={onDoubleClick}
        loading={isLoadingEO}
        height={160}
        tableHeight={190}
      />
    </div>
  );
};

export default EOTable;
