import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const WorkPermitTrackingIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.333 12.3333L18.333 8.16659C18.333 6.32564 16.8406 4.83325 14.9997 4.83325L13.333 4.83325L4.99968 4.83325C3.15873 4.83325 1.66634 6.32563 1.66634 8.16658L1.66634 12.3333C1.66634 14.1742 3.15873 15.6666 4.99968 15.6666L14.9997 15.6666C16.8406 15.6666 18.333 14.1742 18.333 12.3333Z"
        strokeWidth="1.57895"
      />
      <path
        d="M13.333 4.83333L13.333 3.5C13.333 2.39543 12.4376 1.5 11.333 1.5V1.5L8.99967 1.5V1.5C7.71101 1.5 6.66634 2.54467 6.66634 3.83333L6.66634 4.83333"
        strokeWidth="1.57895"
      />
      <line
        x1="1.66602"
        y1="8.21053"
        x2="18.3327"
        y2="8.21053"
        strokeWidth="1.57895"
      />
      <line
        x1="6.62248"
        y1="8.95622"
        x2="6.62248"
        y2="9.87727"
        strokeWidth="1.57895"
        strokeLinecap="round"
      />
      <line
        x1="14.1225"
        y1="8.95622"
        x2="14.1225"
        y2="9.87727"
        strokeWidth="1.57895"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WorkPermitTrackingIcon;
