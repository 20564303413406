import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const BellIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33347 12.4043H2.08347H1.33347ZM3.6109 15.0695L3.50789 15.8124H3.50789L3.6109 15.0695ZM12.0995 15.0695L11.9965 14.3266L12.0995 15.0695ZM3.53283 6.73913C3.53283 4.3519 5.46806 2.41666 7.85529 2.41666V0.916664C4.63964 0.916664 2.03283 3.52347 2.03283 6.73913H3.53283ZM3.53283 8.48281V6.73913H2.03283V8.48281H3.53283ZM2.08347 12.4043C2.08347 12.0262 2.18682 11.6745 2.36636 11.3735L1.07807 10.6052C0.763816 11.1321 0.583475 11.7483 0.583475 12.4043H2.08347ZM3.71391 14.3266C2.7789 14.197 2.08347 13.3833 2.08347 12.4043H0.583475C0.583475 14.0968 1.79672 15.5751 3.50789 15.8124L3.71391 14.3266ZM7.85521 14.685C6.62096 14.685 5.02173 14.508 3.71391 14.3266L3.50789 15.8124C4.82788 15.9955 6.51222 16.185 7.85521 16.185V14.685ZM11.9965 14.3266C10.6887 14.508 9.08947 14.685 7.85521 14.685V16.185C9.1982 16.185 10.8825 15.9955 12.2025 15.8124L11.9965 14.3266ZM13.627 12.4043C13.627 13.3833 12.9315 14.197 11.9965 14.3266L12.2025 15.8124C13.9137 15.5751 15.127 14.0968 15.127 12.4043H13.627ZM13.3441 11.3735C13.5236 11.6746 13.627 12.0262 13.627 12.4043H15.127C15.127 11.7483 14.9466 11.1322 14.6324 10.6053L13.3441 11.3735ZM12.1778 6.73913V8.48301H13.6778V6.73913H12.1778ZM7.85529 2.41666C10.2425 2.41666 12.1778 4.3519 12.1778 6.73913H13.6778C13.6778 3.52347 11.071 0.916664 7.85529 0.916664V2.41666ZM14.6324 10.6053C14.3705 10.1661 14.143 9.8305 13.9529 9.44977C13.7727 9.08873 13.6778 8.7788 13.6778 8.48301H12.1778C12.1778 9.10414 12.3782 9.65364 12.6108 10.1197C12.8336 10.566 13.1364 11.0252 13.3441 11.3735L14.6324 10.6053ZM2.03283 8.48281C2.03283 8.77861 1.93785 9.08857 1.75761 9.44963C1.56755 9.83039 1.34001 10.1659 1.07807 10.6052L2.36636 11.3735C2.5741 11.0251 2.87687 10.5659 3.09969 10.1196C3.33235 9.65351 3.53283 9.10398 3.53283 8.48281H2.03283Z" />
      <path
        d="M9.6665 17.3616C9.27704 17.9474 8.61106 18.3335 7.85491 18.3335C7.09877 18.3335 6.43279 17.9474 6.04332 17.3616"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BellIcon;
