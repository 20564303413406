import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ArrowIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 9.00002L15.2928 5.70712C15.6834 5.3166 15.6834 4.68343 15.2928 4.29291L11.9999 1.00002M14.9999 5.00001L0.999939 5.00002"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowIcon;
