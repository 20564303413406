import { ApiServiceBase } from '../../ApiServiceBase';
import { OnboardingRoutes } from './enums/OnboardingRoutes';
import { IPersonalInformationRequest } from './types/IPersonalInformationRequest';
import {
  IPersonalInfo,
  IWorkContract,
  IClientContract,
} from '../../../../redux/reducers/onboarding/types';
import { IWorkPermitRequest } from './types/IWorkPermitRequest';
import { IWorkContractRequest } from './types/IWorkContractRequest';
import { IBankAccountRequest } from './types/IBankAccountRequest';
import { IChildAllowancesRequest } from './types/IChildAllowanceRequest';
import { IUpdateUnboardingProfile } from './types/IUpdateUnboardingProfile';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { IGetConsultantInfoResponse } from './types/IGetConsultantInfoResponse';
import { IClientContractRequest } from './types/IClientContract';
import { ISsnInformationRequest } from './types/IAvsAhvRequest';
import { IAdditionalInfoRequest } from './types/IAdditionalInfoRequest';
import { IQueryParameters } from './types/IQueryParameters';
import {
  openErrorNotification,
  openInfoNotification,
} from '../../../../components/notification/Notification';

const { workContract, additionalInformation, onboarding } = OnboardingRoutes;

class OnboardingApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/consultant`;

  putPersonalInformationAsEmployee(
    id: string,
    query: IQueryParameters<IPersonalInfo>,
  ): Promise<IUpdateUnboardingProfile> {
    const { data, ...restQuery } = query;

    return this.put<IQueryParameters<IPersonalInformationRequest>, any>(
      `/${id}/${onboarding}`,
      {
        ...restQuery,
        data: parsPersonalInformationBeforeRequest(data),
      },
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120008) {
          openErrorNotification(e.response.data.message);
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putPersonalInformationAsConsultant(
    query: IQueryParameters<IPersonalInfo>,
  ): Promise<IUpdateUnboardingProfile> {
    const { data, ...restQuery } = query;

    return this.put<IQueryParameters<IPersonalInformationRequest>, any>(
      `/${onboarding}`,
      {
        ...restQuery,
        data: parsPersonalInformationBeforeRequest(data),
      },
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120008) {
          openErrorNotification(e.response.data.message);
        }
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putWorkPermitAsEmployee(
    id: string,
    query: IQueryParameters<IWorkPermitRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IWorkPermitRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putWorkPermitAsConsultant(
    query: IQueryParameters<IWorkPermitRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IWorkPermitRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putWorkContractAsEmployee(
    id: string,
    query: IQueryParameters<IWorkContract>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IWorkContractRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putWorkContractAsConsultant(
    query: IQueryParameters<IWorkContract>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IWorkContractRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putClientContractAsEmployee(
    id: string,
    query: IQueryParameters<IClientContract>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IClientContractRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putClientContractAsConsultant(
    query: IQueryParameters<IClientContract>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IClientContractRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  addClientWorkContractAsEmployee(
    consultantId: string,
  ): Promise<IUpdateUnboardingProfile> {
    return this.post<IGetConsultantInfoResponse, any>(
      `/${consultantId}/${onboarding}/${workContract}/client`,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  addClientWorkContractAsConsultant(): Promise<IUpdateUnboardingProfile> {
    return this.post<IGetConsultantInfoResponse, any>(
      `/${onboarding}/${workContract}/client`,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  deleteClientWorkContractAsEmployee(
    consultantId: string,
    clientId: string,
  ): Promise<IUpdateUnboardingProfile> {
    return this.delete<IGetConsultantInfoResponse>(
      `/${consultantId}/${onboarding}/${workContract}/client/${clientId}`,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  deleteClientWorkContractAsConsultant(
    clientId: string,
  ): Promise<IUpdateUnboardingProfile> {
    return this.delete<IGetConsultantInfoResponse>(
      `/${onboarding}/${workContract}/client/${clientId}`,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putBankAccountAsEmployee(
    id: string,
    query: IQueryParameters<IBankAccountRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IBankAccountRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putBankAccountAsConsultant(
    query: IQueryParameters<IBankAccountRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IBankAccountRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putChildAllowancesAsEmployee(
    id: string,
    query: IQueryParameters<IChildAllowancesRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IChildAllowancesRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putChildAllowancesAsConsultant(
    query: IQueryParameters<IChildAllowancesRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IChildAllowancesRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putAvsAhvAsEmployee(
    id: string,
    query: IQueryParameters<ISsnInformationRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<ISsnInformationRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putAvsAhvAsConsultant(
    query: IQueryParameters<ISsnInformationRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<ISsnInformationRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  putAdditionalInfoAsEmployee(
    id: string,
    query: IQueryParameters<IAdditionalInfoRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IAdditionalInfoRequest>, any>(
      `/${id}/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  putAdditionalInfoAsConsultant(
    query: IQueryParameters<IAdditionalInfoRequest>,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IQueryParameters<IAdditionalInfoRequest>, any>(
      `/${onboarding}`,
      query,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => {
        if (e.response.data.code === 120009) {
          openInfoNotification(e.response.data.message);
          return {
            isSuccess: false,
            data: 120009,
          };
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  addNewContactToAdditionalInfoAsEmployee(
    id: string,
  ): Promise<IGetConsultantInfoResponse | null> {
    return this.post<any, IGetConsultantInfoResponse>(
      `/${id}/${onboarding}/${additionalInformation}/contact`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  addNewContactToAdditionalInfoAsConsultant(): Promise<IGetConsultantInfoResponse | null> {
    return this.post<any, IGetConsultantInfoResponse>(
      `/${onboarding}/${additionalInformation}/contact`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  deleteContactInAdditionalInfoAsEmployee(
    consultantId: string,
    contactId: string,
  ): Promise<IGetConsultantInfoResponse | null> {
    return this.delete<IGetConsultantInfoResponse>(
      `/${consultantId}/${onboarding}/${additionalInformation}/contact/${contactId}`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  deleteContactInAdditionalInfoAsConsultant(
    contactId: string,
  ): Promise<IGetConsultantInfoResponse | null> {
    return this.delete<IGetConsultantInfoResponse>(
      `/${onboarding}/${additionalInformation}/contact/${contactId}`,
    )
      .then((response) => response)
      .catch(() => null);
  }

  finishedOnboardingAsConsultant() {
    return this.post<any, IGetConsultantInfoResponse>('/' + onboarding)
      .then((response) => response)
      .catch(() => null);
  }

  finishedOnboardingAsEmployee(id: string) {
    return this.post<any, IGetConsultantInfoResponse>(`/${id}/` + onboarding)
      .then((response) => response)
      .catch(() => null);
  }
  //TODO
  getOnboardingLogs(consultantId: string, body: any) {
    return this.post(`/${consultantId}/onboarding/logs`, body);
  }
}

export const onboardingApi = new OnboardingApi();

function parsPersonalInformationBeforeRequest(
  obj: IPersonalInfo,
): IPersonalInformationRequest {
  const {
    maritalStatusDetailsMarriageDate,
    maritalStatusDetailsSpouseName,
    maritalStatusDetailsSpouseLastName,
    maritalStatusDetailsSpouseDateOfBirth,
    maritalStatusDetailsSpouseNationality,
    maritalStatusDetailsIsSpouseSwissResident,
    maritalStatusDetailsSpouseSSN,
    maritalStatusDetailsSpousalIncome,
    maritalStatusDetailsSpouseWorkCountry,
    maritalStatusDetailsSpouseWorkCity,
    maritalStatusDetailsSpouseWorkCanton,
    maritalStatusDetailsSpouseStartedWorkingFrom,
    swissAddressStreet,
    swissAddressPostCode,
    swissAddressCity,
    abroadAddressStreet,
    abroadAddressPostCode,
    abroadAddressCity,
    abroadAddressUseAsCorrespondenceLocation,
    abroadAddressCountry,
    abroadAddressTotalAttendance,
    ...restObj
  } = obj;

  return {
    ...restObj,
    maritalStatusDetails: {
      marriageDate: maritalStatusDetailsMarriageDate,
      spouseName: maritalStatusDetailsSpouseName,
      spouseLastName: maritalStatusDetailsSpouseLastName,
      spouseDateOfBirth: maritalStatusDetailsSpouseDateOfBirth,
      spouseNationality: maritalStatusDetailsSpouseNationality,
      isSpouseSwissResident: maritalStatusDetailsIsSpouseSwissResident,
      spouseSSN: maritalStatusDetailsSpouseSSN,
      spousalIncome: maritalStatusDetailsSpousalIncome,
      spouseWorkCountry: maritalStatusDetailsSpouseWorkCountry,
      spouseWorkCity: maritalStatusDetailsSpouseWorkCity,
      spouseWorkCanton: maritalStatusDetailsSpouseWorkCanton,
      spouseStartedWorkingFrom: maritalStatusDetailsSpouseStartedWorkingFrom,
    },
    swissAddress: {
      street: swissAddressStreet,
      postCode: swissAddressPostCode,
      city: swissAddressCity,
    },
    abroadAddress: {
      street: abroadAddressStreet,
      postCode: abroadAddressPostCode,
      city: abroadAddressCity,
      country: abroadAddressCountry,
      useAsCorrespondenceLocation: abroadAddressUseAsCorrespondenceLocation,
      totalAttendance: abroadAddressTotalAttendance,
    },
  };
}
