import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  mainActivitiesList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
  selectedMainActivities: [],
};

const reducer = createSlice({
  name: 'main-activities',
  initialState,
  reducers: {
    setMainActivitiesList(state, action: PayloadAction<IGetListDto<any>>) {
      state.mainActivitiesList.content = action.payload.result;
      state.mainActivitiesList.pagination.totalElement = action.payload.total;
    },
    changeMainActivitiesFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.mainActivitiesList.filter = {
        ...state.mainActivitiesList.filter,
        ...action.payload,
      };
      state.mainActivitiesList.pagination.currentPage = 1;
    },
    setMainActivitiesSorting(state, action: PayloadAction<any>) {
      state.mainActivitiesList.sort.sortBy = action.payload[0];
      state.mainActivitiesList.sort.sort = action.payload[1];
    },
    clearMainActivitiesFiltersAndSorts(state) {
      state.mainActivitiesList.sort = initialState.mainActivitiesList.sort;
      state.mainActivitiesList.filter = initialState.mainActivitiesList.filter;
    },
    setMainActivitiesPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.mainActivitiesList.pagination.currentPage =
        action.payload.currentPage;
      state.mainActivitiesList.pagination.perPage = action.payload.perPage;
    },
    selectMainActivityItem(state, action: PayloadAction<string[]>) {
      state.selectedMainActivities = action.payload;
    },
  },
});

export const {
  setMainActivitiesList,
  changeMainActivitiesFiltering,
  setMainActivitiesSorting,
  clearMainActivitiesFiltersAndSorts,
  setMainActivitiesPagination,
  selectMainActivityItem,
} = reducer.actions;

export default reducer.reducer;
