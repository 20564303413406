import './ErrorMessage.less';
import CrossIcon from '../../assets/icons/CrossIcon';

interface IProps {
  errorMessage: string;
}

const ErrorMessage = (props: IProps) => {
  const { errorMessage } = props;
  if (!errorMessage) {
    return null;
  }
  return (
    <span className="error-text">
      <div className="icon-wrapper">
        <CrossIcon />
      </div>
      {errorMessage}
    </span>
  );
};

export default ErrorMessage;
