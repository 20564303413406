import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ResetFilterIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 15.8333L8.706 17.7303C8.15192 18.0074 7.49999 17.6044 7.49999 16.985V11.9069C7.49999 11.4848 7.33979 11.0783 7.05175 10.7697L2.33902 5.72038C1.90695 5.25745 1.66666 4.64781 1.66666 4.01458V3.33332C1.66666 2.41285 2.41285 1.66666 3.33332 1.66666H16.6667C17.5871 1.66666 18.3333 2.41285 18.3333 3.33332V4.01458C18.3333 4.64781 18.093 5.25745 17.661 5.72038L15.4167 8.12499"
        stroke="#0E2552"
        strokeWidth="1.5"
      />
      <path
        d="M19.1668 10L14.1668 15"
        stroke="#C43025"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1668 10L19.1668 15"
        stroke="#C43025"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResetFilterIcon;
