import { ISort } from '../../../../redux/types';
import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto, IGetPayslipsListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';
import { IUploadFileResponse } from '../FileApi/types/IUploadFileResponse';
import {
  IChangePayslipPaymentDate,
  IChangePayslipPaymentStatus,
  IChangeSalaryNetPaid,
  IDeletePayslipsDTO,
  IDeletePublishedPayslipsDTO,
  IEmployeePayslipResponse,
  IPublishPayslipsDTO,
  IUpdatePayslip,
  IUploadResult,
  IViewPayslipResponse,
} from './types/types';

class EmployeePayslipsApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/payslip`;

  getEmployeePayslips(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<IEmployeePayslipResponse>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    });
  }
  getExcelByIteration(iterationId: string) {
    return this.get<IGetListDto<IUploadFileResponse[]>>(`/log/${iterationId}`, {
      responseType: 'blob',
    });
  }
  getUploadResultLogs(iterationId: string) {
    return this.get<IGetListDto<IUploadResult>>(`/log/${iterationId}/result`);
  }
  getUploadResultLogsStatus(iterationId: string) {
    return this.get<IGetListDto<IUploadResult>>(`/log/${iterationId}/status`);
  }
  getViewPayslipsExcel(consultantId: string) {
    return this.get<IGetListDto<IUploadFileResponse[]>>(
      `/consultant/${consultantId}/export`,
      {
        responseType: 'blob',
      },
    );
  }
  publishPayslips(payslips: IPublishPayslipsDTO) {
    return this.patch('/publish', payslips);
  }
  unpublishPayslips(payslips: IPublishPayslipsDTO) {
    return this.patch('/unpublish', payslips);
  }
  deletePayslips(payslips: IDeletePayslipsDTO) {
    return this.delete('', payslips);
  }
  deletePayslip(body: IDeletePublishedPayslipsDTO[]) {
    return this.delete('/published', body);
  }
  deletePayslipActivityReport(body: Array<string>) {
    return this.delete('/activity-report', body);
  }
  getPayslipsByConsultantId(
    consultantId: string,
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetPayslipsListDto<IViewPayslipResponse>>(
      `/consultant/${consultantId}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    );
  }
  updateSalaryNetPaid(payslipId: string, body: IChangeSalaryNetPaid) {
    return this.patch(`/${payslipId}`, body);
  }
  updatePayslipPaymentStatus(
    payslipId: string,
    body: IChangePayslipPaymentStatus,
  ) {
    return this.patch(`/${payslipId}`, body);
  }
  updatePayslipPaymentDate(payslipId: string, body: IChangePayslipPaymentDate) {
    return this.patch(`/${payslipId}`, body);
  }
  updatePayslip(body: IUpdatePayslip) {
    return this.post('', body);
  }
}

export const employeePayslipsApi = new EmployeePayslipsApi();
