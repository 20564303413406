import { FC } from 'react';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';

interface IProps {
  hideModal: () => void;
}

const ModalArchiveContent: FC<IProps> = ({ hideModal }) => {
  const archiveHandler = () => {
    // dispatch(archiveLead(_id));
    hideModal();
  };

  return (
    <>
      <p className="archive-text">
        Are you sure you want to archive{' '}
        <b>
          {'Yehor'} {'Domin'}?
        </b>{' '}
        This action is irreversible.
      </p>
      <Row gutter={[16, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={hideModal}>
            Cancel
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={archiveHandler}>
            Yes, archive
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModalArchiveContent;
