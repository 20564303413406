import { FC, useEffect, useState } from 'react';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';
import { leadApi } from '../../../common/api/services/LeadApi/leadApi';
import CopyLinkSection from '../../../components/copy-link-section/CopyLinkSection';

interface IProps {
  leadDetails: ILeadDto;
}

const ModalInviteContent: FC<IProps> = ({ leadDetails }) => {
  const [inviteUrl, setInviteUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { _id, email } = leadDetails;

  useEffect(() => {
    leadApi.sendInviteToLead(_id).then((response) => {
      if (typeof response === 'string') {
        setErrorMessage(response);
      } else {
        const inviteUrl = response?.inviteUrl ?? '';
        setInviteUrl(inviteUrl);
      }
    });
  }, [leadDetails, errorMessage]);

  if (errorMessage) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </div>
    );
  }

  return (
    <>
      <p>
        The invitation link was successfully sent to <span>{email}</span>
      </p>
      <p>You can also copy the link bellow.</p>

      <CopyLinkSection link={inviteUrl} />
    </>
  );
};

export default ModalInviteContent;
