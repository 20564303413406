import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Input as AntInput } from 'antd';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PasswordIcon from '../../assets/icons/PasswordIcon';
import LinkIcon from '../../assets/icons/LinkIcon';
import CrossIcon from '../../assets/icons/CrossIcon';

import './Input.less';
import { onCopyByClick } from '../../common/utils/onCopyByClick';

type TInputType = 'text' | 'password' | 'number' | 'email' | 'phone';

interface IProps {
  value?: string;
  onChange?:
    | ((e: ChangeEvent<HTMLInputElement>) => void)
    | ((value: string) => void);
  label: string;
  inputType?: TInputType;
  name?: string;
  errorMessage?: string;
  disabled?: boolean;
  placeholder?: string;
  isShowClipboardCopyLink?: boolean;
  showAsterisk?: boolean;
  onBlur?: () => void;
}

const Input: FC<IProps> = (props) => {
  const {
    label,
    inputType = 'text',
    name = '',
    value,
    onChange,
    errorMessage,
    disabled,
    placeholder = 'Text',
    isShowClipboardCopyLink = false,
    showAsterisk = false,
    onBlur,
  } = props;

  const [isFocusClass, setFocusClass] = useState(false);
  const [type, setType] = useState(inputType);
  const [phoneInputValue, setPhoneInputValue] = useState('');

  useEffect(() => {
    setPhoneInputValue(value || '');
  }, [value]);

  const changeType = () => {
    if (type === 'text') {
      setType('password');
    } else {
      setType('text');
    }
  };

  const isShowPasswordComponent = inputType === 'password';

  const focusClass = isFocusClass ? ' focus-input' : '';
  const asteriskClass = showAsterisk ? ' asterisk' : '';
  const inputClassName = !!value
    ? `typed input${focusClass}`
    : `input${focusClass}${asteriskClass}`;
  const placeholderText = !value && isFocusClass ? placeholder : label;
  const passwordIconClass =
    type !== 'password' ? 'password-icon __show' : 'password-icon';
  const inputStatus = errorMessage ? 'error' : '';
  const labelErrorClass = inputStatus ? ' input-label-error' : '';
  const labelClass = `input-label${focusClass}${labelErrorClass}${
    disabled ? ' __disabled' : ''
  }${isShowClipboardCopyLink ? ' __clipboard' : ''}`;

  const inputClassNameWithoutLabel = !label
    ? `${inputClassName} without-label`
    : inputClassName;

  const isPhone = inputType === 'phone';
  if (isPhone) {
    return (
      <div className="input-label-wrapper phone-input">
        <label className={labelClass}>
          <PhoneInput
            prefix="+"
            country="ch"
            onFocus={() => setFocusClass(false)}
            onBlur={() => setFocusClass(false)}
            inputClass={inputClassNameWithoutLabel}
            inputProps={{
              name,
              className: `${inputClassNameWithoutLabel}`,
            }}
            placeholder={placeholderText}
            value={phoneInputValue}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value, data: CountryData) => {
              setPhoneInputValue(value || '+');
              onChange &&
                onChange(
                  (data?.dialCode?.length < value.replace(/\+/g, '').length
                    ? '+' + value
                    : '') as any,
                );
            }}
            disabled={disabled}
          />
          <span className="label-phone">
            {label}
            {showAsterisk && <span className="asterisk">*</span>}
          </span>
          {isShowPasswordComponent && (
            <PasswordIcon className={passwordIconClass} onClick={changeType} />
          )}
        </label>
        {errorMessage && (
          <span className="error-message error-text">
            <div className="icon-wrapper">
              <CrossIcon />
            </div>
            {errorMessage}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="input-label-wrapper">
      <label className={labelClass}>
        {onChange ? (
          <AntInput
            status={inputStatus}
            name={name}
            type={type}
            onFocus={() => setFocusClass(true)}
            onBlur={() => {
              setFocusClass(false);
              onBlur && onBlur();
            }}
            className={inputClassNameWithoutLabel}
            bordered={false}
            placeholder={showAsterisk ? undefined : placeholderText}
            value={value}
            onChange={onChange as (e: ChangeEvent<HTMLInputElement>) => void}
            disabled={disabled}
          />
        ) : (
          <AntInput
            status={inputStatus}
            name={name}
            type={type}
            onFocus={() => setFocusClass(true)}
            onBlur={() => {
              setFocusClass(false);
              onBlur && onBlur();
            }}
            className={inputClassNameWithoutLabel}
            bordered={false}
            placeholder={showAsterisk ? undefined : placeholderText}
            value={value}
            disabled={disabled}
          />
        )}
        <span className="label">
          {label}
          {showAsterisk && <span className="asterisk">*</span>}
        </span>
        {isShowPasswordComponent && (
          <PasswordIcon className={passwordIconClass} onClick={changeType} />
        )}
        {isShowClipboardCopyLink && (
          <LinkIcon onClick={() => onCopyByClick(value || '')} />
        )}
      </label>
      {errorMessage && (
        <span className="error-message error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
