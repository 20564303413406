import { ApiServiceBase } from '../../ApiServiceBase';
import { ISort } from '../../../../redux/types';
import { IGetListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';
import { ActivityRoutes } from './enums/ActivityRoutes';
import {
  IARPayload,
  IActivityReportResponse,
  IPaymentDataDTO,
} from './types/IActivityReportResponse';

class ActivityApi extends ApiServiceBase {
  protected readonly baseUrl = '/api/v1/reports';

  getActivityReport(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IActivityReportResponse[]> | null> {
    return this.get<IGetListDto<IActivityReportResponse[]>>(
      `/${ActivityRoutes.consultants}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch(() => null);
  }

  exportToExel(
    filter: Record<string, string | string[]>,
    sort: ISort,
    selectedIds: string[],
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    return this.get<any>(
      `/${ActivityRoutes.consultants}/${ActivityRoutes.export}`,
      {
        query,
        responseType: 'blob',
      },
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => null);
  }
  updatePayslipPaymentDate(
    body: IPaymentDataDTO,
    consultantId: string,
  ): Promise<any> {
    return this.put<any>(
      `/consultants/${consultantId}/payslip-payment-date`,
      body,
    )
      .then((response) => response)
      .catch(() => null);
  }

  getARInfoMain(
    draft: Array<{
      consultantId?: string | null;
      clientId?: string | null;
      period?: string | null;
      projectId?: string | null;
    }>,
  ): Promise<any> {
    const body = {
      draft,
    };
    return this.post<any>(`/consultants/invoice/preview`, body).then(
      (response) => response,
    );
  }

  getARInfoPayload(body: IARPayload) {
    return this.post<any>(`/consultants/invoice/payload`, body)
      .then((response) => response)
      .catch(() => null);
  }
  getARInfoActivities(body: { consultantId: string; period: string }) {
    return this.post<any>(`/consultants/invoice/other-activities`, body)
      .then((response) => response)
      .catch(() => null);
  }
  getARInfoExpences(expenses: Array<string>) {
    const body = {
      expenses,
    };
    return this.post<any>(`/consultants/invoice/other-expenses`, body)
      .then((response) => response)
      .catch(() => null);
  }
  getARConsultants(clientId: string, commonPeriod: Date) {
    return this.get(
      `/consultants/invoice/preview?clientId=${clientId}&commonPeriod=${commonPeriod}`,
    )
      .then((response) => response)
      .catch(() => null);
  }
  getFinancialStructure(payloadInfo: IARPayload) {
    return this.post(
      `/consultants/invoice/financial-payload`,
      payloadInfo,
    ).then((response) => response);
  }
}

export const activityApi = new ActivityApi();
