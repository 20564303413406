import { ChangeEvent, FC } from 'react';
import { RadioChangeEvent, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

import AvsAhvTabInformationSection from './AvsAhvTabInformationSection';

import { updateOnboardingAvsAhv } from '../../../../redux/reducers/onboarding/sliceReducer';
import { createMask } from '../../../../common/utils/inputMaskUtils';

const AvsAhvTab: FC = () => {
  const dispatch = useAppDispatch();

  const { ssnInformation, personalInfo } = useAppSelector(
    (state) => state.onboarding,
  );
  const { workForm } = personalInfo;
  const isCorporation = workForm === 'corporation';
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.ssnInformation,
  );

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'comment' && fieldValue.length > 200) return;

    if (fieldName === 'ssn') {
      dispatch(
        updateOnboardingAvsAhv({
          [fieldName]: createMask(fieldValue, ssnInformation.ssn),
        }),
      );
      return;
    }
    dispatch(updateOnboardingAvsAhv({ [fieldName]: fieldValue }));
  };

  const onChangeDropdown =
    (fieldName: string) =>
    (fieldValue: string): void => {
      dispatch(updateOnboardingAvsAhv({ [fieldName]: fieldValue }));
    };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    }
    return '';
  };

  return (
    <Row gutter={[32, 0]} className="avs-ahv-tab">
      <AvsAhvTabInformationSection
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        ssnInformation={ssnInformation}
        getError={getError}
        isCorporation={isCorporation}
      />
    </Row>
  );
};

export default AvsAhvTab;
