import { FC, useEffect, useState } from 'react';
import { Layout } from 'antd';
import SidebarMenu from '../../components/sidebar-menu/SidebarMenu';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import Header from '../../components/header/Header';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import EmployeesPage from '../employees-page/EmployeesPage';
import Footer from '../../components/footer/Footer';

import './HomePage.less';
import WaitingRoomPage from '../waiting-room-page/WaitingRoomPage';
import EditLeadPage from '../edit-lead-page/EditLeadPage';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Roles } from '../../common/enums/Roles';
import { Statuses } from '../../common/enums/Statuses';
import { getUserProfile } from '../../redux/reducers/auth/asyncThunkActions';
import TimesheetsPage from '../timesheets-page/TimesheetsPage';
import ExpensesPage from '../expenses-page/ExpensesPage';
import MyInformationPage from '../my-information-page/MyInformationPage';
import ViewLeadPage from '../view-lead-page/ViewLeadPage';
import ConsultantsPage from '../consultants-page/ConsultantsPage';
import InternalTimesheetPage from '../internal-timesheet-page/InternalTimesheetPage';
import InternalExpensesPage from '../internal-expenses-page/InternalExpensesPage';
import MonitoringPage from '../monitoring-page/MonitoringPage';
import ActivityReportPage from '../activity-report-page/ActivityReportPage';
import ReportingPage from '../reporting-page/ReportingPage';
import PayslipsPage from '../payslips-page/PayslipsPage';
import ClientsPage from '../clients-page/ClientsPage';
import ModifyClientPage from '../modify-client-page/ModifyClientPage';
import { withUpdateClient } from '../../common/HOCs/withUpdateClient';
import { withCreateClient } from '../../common/HOCs/withCreateClient';
import EmployeePayslips from '../employee-payslips/EmployeePayslips';
import ConsultantPayslipsPage from '../consultant-payslips/ConsultantPayslipsPage';
import WaitingRoomExtraSection from '../waiting-room-page/components/WaitingRoomExtraSection';
import EmloyeeExtraSection from '../employees-page/components/EmloyeeExtraSection';
import TimesheetsExtraSection from '../timesheets-page/components/TimesheetsExtraSection';
import InternalTimesheetsExtraSection from '../internal-timesheet-page/components/InternalTimesheetsExtraSection';
import ExpensesExtraSection from '../expenses-page/components/ExpensesExtraSection';
import InternalExpensesExtraSection from '../internal-expenses-page/components/InternalExpensesExtraSection';
import ConsultantExtraSection from '../consultants-page/components/ConsultantsExtraSection';
import ActivityReportExtraSection from '../activity-report-page/components/ActivityReportExtraSection';
import ReportingExtraSection from '../reporting-page/components/ReportingExtraSection';
import MonitoringExtraSection from '../monitoring-page/components/MonitoringExtraSection';
import ClientsExtraSection from '../clients-page/components/ClientsExtraSection';
import '../../components/extra-section/ExtraSection.less';
import LogsPage from '../logs-page/LogsPage';
import DocumentsPage from '../documents-page/DocumentsPage';
import DocumentsConsultantPage from '../documents-consultant-page/DocumentsConsultantPage';
import ActivityReportInfo from '../activity-report-info/ActivityReportInfo';
import GenerateInvoicePage from '../generate-invoice-page/GenerateInvoicePage';
import SystemInfoPage from '../system-info-page/SystemInfoPage';
import MainActivitiesPage from '../main-activities-page/MainActivitiesPage';
import InvoicesPage from '../invoices/InvoicesPage';
import WorkPermitPage from '../work-permit-page/WorkPermitPage';

const { Content } = Layout;

const {
  timesheets,
  activityReport,
  reporting,
  employees,
  invoices,
  expenses,
  consultants,
  waitingRoom,
  monitoring,
  home,
  edit,
  view,
  payslips,
  documents,
  myInformation,
  clients,
  modify,
  consultantPayslips,
  employeePayslips,
  logs,
  activityReportInfo,
  generateInvoice,
  systemInfo,
  mainActivities,
  workPermit,
} = ROUTER_PATHS;

const HomePage: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [isOpenNavigation, setIsOpenNavigation] = useState(false);
  const { role, status } = useAppSelector((state) => state.auth);

  const isAdmin = role === Roles.ADMIN || Roles.MANAGER === role;
  const isCustomer = role === Roles.CONSULTANT && Statuses.active === status;

  const isARInfo = [activityReportInfo].includes(pathname);

  const isExtra = ![
    employeePayslips,
    consultantPayslips,
    documents,
    systemInfo,
    mainActivities,
    invoices,
    workPermit,
  ].includes(pathname);

  useEffect(() => {
    dispatch(getUserProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout style={{ height: '100%' }} className="home-page">
      <SidebarMenu
        isOpenNavigation={isOpenNavigation}
        setIsOpenNavigation={setIsOpenNavigation}
      />

      <Layout>
        <Header
          isOpenNavigation={isOpenNavigation}
          setIsOpenNavigation={setIsOpenNavigation}
        />

        {isExtra && (
          <div className="breadcrumb-section">
            <Breadcrumb />
            <div className="extra-bread-crumb-section">
              {setExtraSection(pathname, isAdmin)}
            </div>
          </div>
        )}

        <Content
          className={
            isExtra
              ? `content-wrapper ${isARInfo ? 'content-overflow' : ''}`
              : 'content-wrapper-no-extra'
          }>
          <Routes>
            <Route
              path={home}
              element={<Navigate to={isAdmin ? consultants : timesheets} />}
            />

            {isCustomer && (
              <Route path={timesheets} element={<TimesheetsPage />} />
            )}

            {isAdmin && (
              <Route path={timesheets} element={<InternalTimesheetPage />} />
            )}
            {isAdmin && <Route path={documents} element={<DocumentsPage />} />}

            {isAdmin && (
              <Route path={activityReport} element={<ActivityReportPage />} />
            )}
            {isAdmin && <Route path={reporting} element={<ReportingPage />} />}
            {isAdmin && <Route path={invoices} element={<InvoicesPage />} />}
            {isAdmin && (
              <Route path={expenses} element={<InternalExpensesPage />} />
            )}
            {isAdmin && (
              <Route path={workPermit} element={<WorkPermitPage />} />
            )}
            {isAdmin && (
              <Route path={systemInfo} element={<SystemInfoPage />} />
            )}
            {isAdmin && (
              <Route
                path={generateInvoice + '/:invoiceId'}
                element={<GenerateInvoicePage />}
              />
            )}
            {isAdmin && (
              <Route
                path={generateInvoice + '/:invoiceId' + '/deposit'}
                element={<GenerateInvoicePage />}
              />
            )}
            {isAdmin && (
              <Route
                path={activityReportInfo}
                element={<ActivityReportInfo />}
              />
            )}
            {isAdmin && (
              <Route path={employeePayslips} element={<EmployeePayslips />} />
            )}
            {isAdmin && (
              <Route path={mainActivities} element={<MainActivitiesPage />} />
            )}
            {isAdmin && <Route path={logs} element={<LogsPage />} />}
            {isCustomer && <Route path={expenses} element={<ExpensesPage />} />}
            {isAdmin && (
              <>
                <Route path={employees} element={<EmployeesPage />} />
                <Route path={consultants} element={<ConsultantsPage />} />
                <Route path={waitingRoom} element={<WaitingRoomPage />} />
                <Route
                  path={waitingRoom + edit + '/:leadId'}
                  element={<EditLeadPage />}
                />
                <Route
                  path={waitingRoom + view + '/:leadId'}
                  element={<ViewLeadPage />}
                />
                <Route path={monitoring} element={<MonitoringPage />} />
                <Route path={clients} element={<ClientsPage />} />
                <Route
                  path={clients + modify}
                  element={withCreateClient(ModifyClientPage)}
                />
                <Route
                  path={clients + modify + '/:clientId'}
                  element={withUpdateClient(ModifyClientPage)}
                />
              </>
            )}

            {isCustomer && <Route path={payslips} element={<PayslipsPage />} />}
            {isCustomer && (
              <Route
                path={consultantPayslips}
                element={<ConsultantPayslipsPage />}
              />
            )}
            {isCustomer && (
              <Route path={documents} element={<DocumentsConsultantPage />} />
            )}
            {isCustomer && (
              <Route
                path={myInformation + '/*'}
                element={<MyInformationPage />}
              />
            )}
          </Routes>
        </Content>
        {/* {pathname.includes(generateInvoice) && <GenerateInvoiceFooter />} */}
        <Footer />
      </Layout>
    </Layout>
  );
};

const setExtraSection = (pathname: string, isAdmin: boolean) => {
  switch (pathname) {
    case waitingRoom: {
      return <WaitingRoomExtraSection />;
    }
    case employees: {
      return <EmloyeeExtraSection />;
    }
    case timesheets: {
      return isAdmin ? (
        <InternalTimesheetsExtraSection />
      ) : (
        <TimesheetsExtraSection />
      );
    }
    case expenses: {
      return isAdmin ? (
        <InternalExpensesExtraSection />
      ) : (
        <ExpensesExtraSection />
      );
    }
    case consultants: {
      return <ConsultantExtraSection />;
    }

    case activityReport: {
      return <ActivityReportExtraSection />;
    }

    case reporting: {
      return <ReportingExtraSection />;
    }

    case monitoring: {
      return <MonitoringExtraSection />;
    }

    case clients: {
      return <ClientsExtraSection />;
    }

    default:
      return;
  }
};

export default HomePage;
