import { Checkbox, Input, Tooltip } from 'antd';
import TrashIcon from '../../../assets/icons/TrashIcon';
import { useEffect, useState } from 'react';
import '../ActivityReportInfo.less';
import { IComment } from '../../../common/api/services/ConsultantApi/types/IComments';
import moment from '../../../common/constants/moment';
import { commonDateSpaceFormat } from '../../../common/constants/dateConstants';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import {
  ChangeStatusComment,
  DeleteComment,
} from '../../../components/common-texts/CommonTexts';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import Button from '../../../components/button/Button';
const { TextArea } = Input;

interface IProps {
  setSelectedComment: (comment?: IComment | null) => void;
  consultantId: string | null;
  isEditModeOpen: boolean;
  getComments: () => void;
  sendComment: (value: string) => void;
  setIsOpenCreateComment: (isOpen: boolean) => void;
  setIsEditModeOpen: (isOpen: boolean) => void;
  isNewComment?: boolean;
  comment?: IComment;
  selectedComment?: IComment | null;
}
const CommentItem = (props: IProps) => {
  const {
    setSelectedComment,
    isEditModeOpen,
    isNewComment,
    sendComment,
    comment,
    consultantId,
    getComments,
    setIsOpenCreateComment,
    setIsEditModeOpen,
    selectedComment,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false);
  const isEditMode =
    (selectedComment?._id === comment?._id && isEditModeOpen) || isNewComment;

  useEffect(() => {
    if (comment) {
      setInputValue(comment.comment);
    }
  }, []);

  const deleteComment = () => {
    if (!consultantId || !comment) {
      return;
    }
    consultantApi
      .deleteComment(consultantId, comment._id)
      .then(() => {
        openSuccessNotification('Comment was deleted successfully');
        getComments();
      })
      .catch(() => {
        openErrorNotification('Comment deleted was failed');
      })
      .finally(() => setIsOpenDeleteModal(false));
  };

  const onClickDeleteBtn = () => {
    if (isNewComment) {
      setInputValue('');
      setIsOpenCreateComment(false);
      return;
    }
    setIsOpenDeleteModal(true);
  };

  const onClickChangeStatusBtn = () => {
    if (isNewComment) {
      return;
    }
    setIsOpenChangeStatusModal(true);
  };

  const changeStatus = () => {
    if (!consultantId || !comment) {
      return;
    }
    const newStatus = comment?.status === 'active' ? 'completed' : 'active';
    consultantApi
      .updateComment(consultantId, comment._id, newStatus)
      .then(() => {
        //TODO
        openSuccessNotification('Сomment was updated successfully');
        getComments();
      })
      .catch(() => {
        openErrorNotification('Error updating comment');
      })
      .finally(() => {
        setIsOpenChangeStatusModal(false);
      });
  };

  const onDoubleClick = () => {
    if (isEditMode || isNewComment) {
      return;
    }
    setSelectedComment(comment);
    setIsEditModeOpen(true);
  };

  return (
    <div className="comment-item-container">
      <div className="comment-left-side">
        <Checkbox
          style={{ marginRight: '25px' }}
          onClick={() => onClickChangeStatusBtn()}
          checked={comment?.status === 'active'}
        />
        {isEditMode ? (
          <div className="input-wrapper">
            <TextArea
              onChange={(value) => setInputValue(value.target.value)}
              value={inputValue}
              autoSize
              style={{
                width: '700px',
                borderRadius: '5px',
                marginRight: '5px',
                padding: '5px',
              }}
            />
            {inputValue.length > 0 && (
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="primary"
                onClick={() => sendComment(inputValue)}>
                confirm
              </Button>
            )}
            <div style={{ marginLeft: '5px' }}>
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="error"
                onClick={() => {
                  setIsOpenCreateComment(false);
                  setIsEditModeOpen(false);
                }}>
                reject
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {comment?.comment && comment?.comment?.length > 115 ? (
              <Tooltip title={comment?.comment} placement={'top'}>
                <span
                  className={`comment-text ${
                    comment?.status !== 'active' ? 'comment-non-active ' : ''
                  }`}
                  onDoubleClick={() => onDoubleClick()}>
                  {comment?.comment.slice(0, 115) || ''}
                </span>
              </Tooltip>
            ) : (
              <span
                className={`comment-text ${
                  comment?.status !== 'active' ? 'comment-non-active ' : ''
                }`}
                onDoubleClick={() => onDoubleClick()}>
                {comment?.comment || ''}
              </span>
            )}
          </div>
        )}
      </div>
      <div>
        {comment ? (
          <span className="comment-consultant">
            {`${comment.user?.firstName || ''} ${
              comment.user?.lastName || ''
            } ${
              comment.updatedAt
                ? `/${moment(comment.updatedAt).format(commonDateSpaceFormat)}`
                : ''
            }`}
          </span>
        ) : (
          ''
        )}
        <TrashIcon
          style={{ marginLeft: '25px', width: '18px', height: '18px' }}
          onClick={() => onClickDeleteBtn()}
        />
        {isOpenDeleteModal && (
          <AttentionModal
            cancelBtnTitle="Return"
            closeModal={() => setIsOpenDeleteModal(false)}
            confirmAction={deleteComment}
            confirmBtnTitle="Yes, delete"
            content={<DeleteComment comment={comment} />}
            isOpen={isOpenDeleteModal}
            isHideAttentionText
            hideCloseBtn
            wrapperClassName="comment-modal"
          />
        )}
        {isOpenChangeStatusModal && (
          <AttentionModal
            cancelBtnTitle="Return"
            closeModal={() => setIsOpenChangeStatusModal(false)}
            confirmAction={changeStatus}
            confirmBtnTitle={`Yes, mark as ${
              comment?.status === 'active' ? 'completed' : 'uncompleted'
            }`}
            content={<ChangeStatusComment comment={comment} />}
            isOpen={isOpenChangeStatusModal}
            isHideAttentionText
            hideCloseBtn
            btnWidth="250px"
            width={600}
            wrapperClassName="comment-modal"
          />
        )}
      </div>
    </div>
  );
};

export default CommentItem;
