export enum ConsultantRoute {
  timesheet = 'timesheet',
  expenses = 'expenses',
  lead = 'lead',
  approveAdmin = 'approve-admin',
  approveFinance = 'approve-finance',
  onboarding = 'onboarding',
  noExpenses = 'no-expenses',
  export = 'export',
  invite = 'invite',
}
