import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IPagination, TSortValue } from '../../types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';

const initialState: IState = {
  isOpenModalGenerateInvoice: false,
  activityReportList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
    selectedConsultant: [],
  },
  selectedPayslips: [],
  payslipsPayload: [],
  isLoading: false,
};

const reducer = createSlice({
  name: 'activity-report',
  initialState,
  reducers: {
    setActivityReportList(state, action: PayloadAction<IGetListDto<any>>) {
      state.activityReportList.content = action.payload.result;
      state.activityReportList.pagination.totalElement = action.payload.total;
    },
    changeActivityReportFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.activityReportList.filter = {
        ...state.activityReportList.filter,
        ...action.payload,
      };
    },
    setActivityReportSorting(
      state,
      action: PayloadAction<[string, TSortValue]>,
    ) {
      state.activityReportList.sort.sortBy = action.payload[0];
      state.activityReportList.sort.sort = action.payload[1];
    },
    clearActivityReportFiltersAndSorts(state) {
      state.activityReportList.sort = initialState.activityReportList.sort;
      state.activityReportList.filter = initialState.activityReportList.filter;
      state.activityReportList.selectedConsultant =
        initialState.activityReportList.selectedConsultant;
      state.selectedPayslips = initialState.selectedPayslips;
      state.payslipsPayload = initialState.payslipsPayload;
    },
    setActivityReportPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.activityReportList.pagination.currentPage =
        action.payload.currentPage;
      state.activityReportList.pagination.perPage = action.payload.perPage;
    },
    selectActivityReportItem(state, action: PayloadAction<string[]>) {
      state.activityReportList.selectedConsultant = action.payload;
    },
    setSelectedPayslips(state, action: PayloadAction<any[]>) {
      state.selectedPayslips = action.payload;
    },
    setIsOpenModalGenerateInvoice(state, action: PayloadAction<boolean>) {
      state.isOpenModalGenerateInvoice = action.payload;
    },
    setPayslipsPayload(
      state,
      action: PayloadAction<Array<{ consultantId: string; period: string }>>,
    ) {
      state.payslipsPayload = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setActivityReportList,
  selectActivityReportItem,
  setSelectedPayslips,
  clearActivityReportFiltersAndSorts,
  setActivityReportPagination,
  changeActivityReportFiltering,
  setActivityReportSorting,
  setIsOpenModalGenerateInvoice,
  setPayslipsPayload,
  setIsLoading,
} = reducer.actions;

export default reducer.reducer;
