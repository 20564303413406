import { IFXRates } from '../../SystemInfoApi/types/ISystemInfo';

export interface IData {
  publicId: string;
  period: string;
  consultant: {
    _id: string;
    firstName: string;
    lastName: string;
    publicId: number;
  };
  contractType: string;
  itemKind: string;
  time: string;
  quantity: number;
  billRateCurrency: string;
  rate: string;
  total: number;
  _id: string;
  description: string;
}

export interface IInvoiceFile {
  createdAt: string;
  fileId: string;
  itemId: string;
  level: string;
  originalFilename: string;
  publicUrl: string;
  updatedAt: string;
  _id: string;
}

export interface IInvoice {
  attachments: Array<IInvoiceFile>;
  publicId: string;
  clientId: string;
  layout: string;
  purchaseOrderNumber: string;
  items: Array<IData>;
  numeriqOffice: string;
  shippingMethod: string;
  address: {
    city: string;
    country: string;
    postCode: string;
    street: string;
    _id: string;
  };
  internalComment: string;
  description: string;
  commentOnInvoice: string;
  itemKind: string;
  vat: {
    name: string;
    percentage: number;
    isEditable: false;
    period: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
    id: string;
  };
  clientContractComment: Array<{
    _id: string;
    consultant: string;
    comment: string;
  }>;
  paymentTerm: string;
  paymentTermRange: number;
  invoiceDate: string;
  payByDate: string;
  isDeposite: boolean;
  fxRate: number;
  //TODO
  shippingMethodDetails: any;
  totalCalculations: {
    total: number;
    vat: number;
    totalWithVAT: number;
    currency: string;
  };
  bankDetails: {
    name: string;
    iban: string;
    swift: string;
    currency: string;
    credit: string;
    userId: string;
  };
  status: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  lastModification: {
    details: string;
    eventType: string;
    invoiceId: string;
    invoicePublicId: string;
    timestamp: string;
    _id: string;
    user: {
      email: string;
      firstName: string;
      lastName: string;
      publicId: number;
      role: string;
      _id: string;
    };
  };
  fxRates: Array<IFXRate>;
  origins: Array<{
    projectId: string;
    consultantId: string;
    clientId: string;
    period: string;
  }>;
}

export interface IInvoiceData {
  addresses: Array<{
    city: string;
    country: string;
    postCode: string;
    street: string;
    _id: string;
  }>;
  amountDeposit: number;
  billRates: Array<number>;
  clientName: string;
  clientPublicId: number;
  consultants: Array<{
    _id: string;
    firstName: string;
    lastName: string;
    publicId: number;
    workForm: string;
  }>;
  contractNumbers: Array<string | null>;
  daysLeftToPay: number;
  dblt: Array<number>;
  entity: string | null;
  expensesTotal: number;
  fxRates: IFXRates;
  treasuries: Array<{
    hasTreasury: boolean | null;
    treasuryPaymentDate: string | null;
  }>;
  internalId: string;
  invoiceDate: string;
  leftToPay: number;
  ovrt: Array<number>;
  paidByClient: number;
  payByDate: string;
  paymentComment: string | null;
  paymentDate: string | null;
  paymentTerm: string;
  paymentTermRange: number;
  periods: Array<string>;
  publicId: string;
  status: string;
  std: Array<number>;
  timesheetTotal: number;
  totalCalculations: {
    total: number;
    vat: number;
    totalWithVAT: number;
    currency: string;
  };
  treasuryPaymentDate: null;
  type: string;
  units: Array<string>;
  vat: {
    createdAt: string;
    isEditable: boolean;
    name: string;
    percentage: number;
    period: string;
    updatedAt: string;
    userId: string;
    _id: string;
  };
  _id: {
    clientId: string;
    contractNumber: string | null;
    contractType: string;
    period: string;
    publicId: string;
    rate: number;
  };
  isClientFactoring: boolean;
  factoringInvoiceUnpaid: number;
  fxRate: {
    createdAt: string;
    currencyPair: string;
    period: string;
    rate: number;
    updatedAt: string;
  } | null;
  workForms: Array<string>;
}

export type InvoiceDataType = Array<IInvoiceData>;

interface IFXRate {
  createdAt: string;
  currencyPair: string;
  period: string;
  rate: number;
  updatedAt: string;
  _id: string;
}

export interface IInvoiceDTODraft {
  layout: string;
  description: string;
  address: string;
  vat: string;
  shippingMethod: string;
  internalComment: string;
  commentOnInvoice: string;
  paymentTerm: string;
  paymentTermRange: string;
}
// export interface IInvoiceDTO extends IInvoiceDTODraft {
//   //TODO: send type
// }
export interface IInvoiceDTO {
  layout: string;
  description: string;
  address: string;
  vat: string;
  shippingMethod: string;
  internalComment: string;
  commentOnInvoice: string;
  paymentTerm: string;
  paymentTermRange: string;
  //TODO: send type
}

export enum InvoiceLayout {
  standard = 'Standard',
  simplified = 'Simplified',
}
export enum InvoiseStatuses {
  DRAFT = 'Draft',
  PARTIALLY_PAID = 'Partially paid',
  PAID = 'Paid',
  SENT = 'Sent',
}

export const invoiseStatusesArray = [
  InvoiseStatuses.DRAFT,
  InvoiseStatuses.SENT,
  InvoiseStatuses.PARTIALLY_PAID,
  InvoiseStatuses.PAID,
];
export enum InvoiceSentOption {
  DIRECTLY = 'directly',
  DOUBLE_CHECK = 'doubleCheck',
}
