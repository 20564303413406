import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { IConsultantTimesheet } from '../../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import Checkbox from '../../../components/checkbox/Checkbox';

import './ModalTimesheet.less';
import { useAppSelector } from '../../../redux/store';
import { Roles } from '../../../common/enums/Roles';
import PreviewImage from '../../../components/file-preview/preview-image/PreviewImage';
import { EyeOutlined } from '@ant-design/icons';
import ExcelPreview from '../../../components/file-preview/excel-preview/ExcelPreview';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  selectedTimesheet: IConsultantTimesheet;
}

const ModalViewTimesheet: FC<IProps> = (props) => {
  const { selectedTimesheet } = props;
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;
  const {
    workContract,
    type,
    isSigned,
    approvalRequired,
    period,
    hasActivityPerMonth,
    documents,
    comments,
    numeriqComments,
    standardTime,
    overtime,
    doubleTime,
    companyRepresentativeEmail,
    representativeFirstName,
    representativeLastName,
    visibility,
    consultant,
  } = selectedTimesheet;

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [previewId, setPreviewId] = useState<string | null>(null);

  const openPreview = (type: 'docs' | 'image', id: string) => {
    setPreviewType(type);
    setIsOpenPreview(true);
    setPreviewId(id);
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setPreviewType('');
    setPreviewId(null);
  };

  const isDocFormat = (url: string) => {
    return (
      ['.docx', '.xlsx'].includes(url.slice(-5).toLowerCase()) ||
      ['.doc', '.xls', '.pdf'].includes(url.slice(-4).toLowerCase())
    );
  };

  const isImgFormat = (url: string) => {
    return (
      ['.jpeg'].includes(url.slice(-5).toLowerCase()) ||
      ['.svg', '.png', '.jpg'].includes(url.slice(-4).toLowerCase())
    );
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <p className="info-title">{`Consultant's ID`}</p>
          <p className="info-content">{consultant?.publicId || ''}</p>
        </Col>
        <Col span={8}>
          <p className="info-title">{`Consultant's name`}</p>
          <p className="info-content">{consultant?.firstName || ''}</p>
        </Col>
        <Col span={8}>
          <p className="info-title">{`Consultant's last name`}</p>
          <p className="info-content">{consultant?.lastName || ''}</p>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p className="info-title">Client name</p>
          <p className="info-content">{workContract?.name}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Period</p>
          <p className="info-content">{getFormatDateWithMonth(period)}</p>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <p className="info-title">Type of timesheets</p>
          <p className="info-content">{type}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Type of contract</p>
          <p className="info-content">{workContract?.type}</p>
        </Col>
      </Row>
      {isAdmin && (
        <Row>
          <Col span={8}>
            <p className="info-title">Visibility</p>
            <p className="info-content">{visibility || 'public'}</p>
          </Col>
        </Row>
      )}

      {isSigned && (
        <Row justify="space-between">
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              label="My timesheet has been Signed/Approved."
              checked
              disabled
            />
          </Col>
        </Row>
      )}
      {approvalRequired && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              checked
              label="My timesheet requires manager's online approval."
              disabled
            />
          </Col>
        </Row>
      )}

      {hasActivityPerMonth && (
        <>
          {!!documents.length && (
            <Row>
              <Col span={24}>
                <p className="info-title">Attached file</p>
                <p className="info-content documents">
                  {documents.map(({ originalFilename }) => (
                    <span key={originalFilename}>
                      <span
                        className="view-file-link"
                        onClick={(event) => {
                          event.preventDefault();
                          fileApi.downloadFileByUrl(originalFilename);
                        }}>
                        {originalFilename}
                      </span>
                      {isImgFormat(originalFilename) && (
                        <span
                          onClick={() => openPreview('image', originalFilename)}
                          style={{ marginLeft: '1rem' }}>
                          <EyeOutlined className="preview-icon" />
                        </span>
                      )}
                      {isDocFormat(originalFilename) && (
                        <span
                          onClick={() => openPreview('docs', originalFilename)}
                          style={{ marginLeft: '1rem' }}>
                          <EyeOutlined className="preview-icon" />
                        </span>
                      )}
                    </span>
                  ))}
                </p>
              </Col>
            </Row>
          )}

          {!isSigned && (
            <>
              <Row>
                <Col span={24}>
                  <p className="info-title">Client representative email</p>
                  <p className="info-content">{companyRepresentativeEmail}</p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="info-title">Representative First Name</p>
                  <p className="info-content">{representativeFirstName}</p>
                </Col>
                <Col span={16}>
                  <p className="info-title">Representative Last Name</p>
                  <p className="info-content">{representativeLastName}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <p className="info-title">Comments for the Client</p>
                  <p className="info-content">{comments || '-'}</p>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col span={24}>
              <p className="info-title">Comments for Numeriq</p>
              <p className="info-content">{numeriqComments || '-'}</p>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <p className="info-title">Standard time</p>
              <p className="info-content">{standardTime}</p>
            </Col>
            {workContract?.overtimeAllowed && (
              <Col span={8} className="info-calc">
                <p className="info-title">Overtime</p>
                <p className="info-content">{overtime}</p>
              </Col>
            )}
            {workContract?.doubleTimeAllowed && (
              <Col span={8} className="info-calc">
                <p className="info-title">Double time</p>
                <p className="info-content">{doubleTime}</p>
              </Col>
            )}
          </Row>
        </>
      )}
      {isOpenPreview && previewType === 'image' && previewId && (
        <PreviewImage
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
      {isOpenPreview && previewType === 'docs' && previewId && (
        <ExcelPreview
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
    </>
  );
};

export default ModalViewTimesheet;
