import { useEffect, useState } from 'react';
import './GenerateInvoicePage.less';
import ContentWrapper from './components/ContentWrapper';
import GenerateInvoiceHeader from './components/GenerateInvoiceHeader';
import { useLocation, useParams } from 'react-router-dom';
import { invoiceApi } from '../../common/api/services/InvoiceApi/invoiceApi';
import {
  IInvoice,
  InvoiceLayout,
  InvoiceSentOption,
  InvoiseStatuses,
} from '../../common/api/services/InvoiceApi/types/types';
import { Form } from 'antd';
import { clientApi } from '../../common/api/services/ClientApi/clientApi';
import SendModal from './components/modals/SendModal';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import { systemInfoApi } from '../../common/api/services/SystemInfoApi/SystemInfoApi';
import {
  IVAT,
  IVATs,
} from '../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { fileApi } from '../../common/api/services/FileApi/FileApi';
import { compareStringArrays } from '../../common/utils/compareArrayOfString';
import { IAddress } from '../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { getPONumber } from './utils/setPONumber';

const GenerateInvoicePage = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const location = useLocation();
  const isDeposit = location.pathname.includes('deposit');
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [client, setClient] = useState<any | null>(null);
  const [vats, setVats] = useState<IVATs>([]);
  const [isOpenSend, setIsOpenSend] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [errorMessages, setErrorMessages] = useState<
    Array<{ message: string; property: string }>
  >([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = () => {
    if (invoiceId) {
      invoiceApi.getInvoiceById(invoiceId).then((resp: any) => {
        form.setFieldsValue({
          ...resp,
          vat: resp.vat?._id,
          address: JSON.stringify({
            street: resp.address.street,
            postCode: resp.address.postCode,
            city: resp.address.city,
            country: resp.address.country,
            _id: resp.address._id,
            addressLines: resp.address.addressLines,
            vat: resp.address.vat,
          }),
          purchaseOrderNumber: getPONumber(resp),
          shippingMethodPortalUrl: resp?.shippingMethodDetails?.portalUrl || '',
          shippingMethodPostalAddress:
            resp?.shippingMethodDetails?.postalAddress || '',
          shippingMethodFirstName: resp?.shippingMethodDetails?.firstName || '',
          shippingMethodLastName: resp?.shippingMethodDetails?.lastName || '',
          shippingMethodEmail: resp?.shippingMethodDetails?.email || '',
          amountDeposit: resp?.amountDeposit || '',
        });
        setInvoice(resp);
        getClient(resp.clientId);
        getVats(resp.invoiceDate, resp.vatByDefault);
      });
    }
  };

  const getVats = (period: string, vat: IVAT) => {
    systemInfoApi
      .getActualVAT(period)
      .then((resp: any) => {
        setVats(
          vat && !resp.find((item: any) => item._id === vat?._id)
            ? [...resp, vat]
            : [...resp],
        );
      })
      .catch((e) => {
        openErrorNotification('No VATs found');
      });
  };

  const getClient = (clientId: string) => {
    clientApi
      .getClientById(clientId)
      .then((resp: any) => {
        setClient(resp);
      })
      .catch((e) => console.log(e));
  };

  const saveAsDraft = (isHideNotification?: boolean) => {
    if (invoice) {
      const values = form.getFieldsValue();
      const body: any = {
        poNumber: values.purchaseOrderNumber,
        layout: values.layout || '',
        address: JSON.parse(values.address) || '',
        vat: values?.vat || '',
        shippingMethod: values.shippingMethod || '',
        internalComment: values.internalComment || '',
        description: values.description || '',
        commentOnInvoice: values.commentOnInvoice || '',
        paymentTerm: values.paymentTerm || '',
        paymentComment: values.paymentComment || '',
        paymentTermRange: values.paymentTermRange || '',
        isDeposit: !!isDeposit,
        ...(values.layout === InvoiceLayout.simplified
          ? { description: values.description }
          : {}),
        ...(isDeposit && values.amountDeposit
          ? { amountDeposit: values.amountDeposit }
          : {}),
      };
      if (values.shippingMethod !== 'self-billing') {
        body.shippingMethodDetails = setRequestShippingMethod(values);
      }
      invoiceApi
        .saveDraftInvoice(body, invoice._id)
        .then((resp: any) => {
          if (!isHideNotification) {
            openSuccessNotification('Invoice was saved successfully');
          }

          setErrorMessages([]);
          setInvoice(resp);
        })
        .catch((e: any) => {
          const messages: any = e?.response?.data?.messages;
          if (Array.isArray(messages)) {
            setErrorMessages(messages);
          }
          if (e?.response?.data?.message) {
            openErrorNotification(
              e?.response?.data?.message || 'Something went wrong',
            );
          }
        });
    }
  };

  const sendInvoice = (type: InvoiceSentOption | '') => {
    if (invoice) {
      const values = form.getFieldsValue();
      const body: any = {
        poNumber: values.purchaseOrderNumber,
        layout: values.layout || '',
        address: JSON.parse(values.address) || '',
        vat: values?.vat || '',
        shippingMethod: values.shippingMethod || '',
        internalComment: values.internalComment || '',
        commentOnInvoice: values.commentOnInvoice || '',
        paymentTerm: values.paymentTerm || '',
        paymentComment: values.paymentComment || '',
        paymentTermRange: values.paymentTermRange || '',
        isDeposit: !!isDeposit,

        ...(values.layout === InvoiceLayout.simplified
          ? { description: values.description }
          : {}),
        ...(isDeposit && values.amountDeposit
          ? { amountDeposit: values.amountDeposit }
          : {}),
      };
      if (values.shippingMethod !== 'self-billing') {
        body.shippingMethodDetails = setRequestShippingMethod(values);
      }
      if (values.shippingMethod === 'email' && type) {
        body.sentOption = type;
      }

      onSendInvoiceApi(body);
    }
  };

  const onSendInvoiceApi = (body: any) => {
    if (invoice) {
      invoiceApi
        .sendInvoice(body, invoice._id)
        .then((resp: any) => {
          openSuccessNotification('Invoice was sent successfully');
          setErrorMessages([]);
          downloadFiles();
          getInvoice();
        })
        .catch((e: any) => {
          const messages: any = e?.response?.data?.messages;
          if (Array.isArray(messages)) {
            setErrorMessages(messages);
            setActiveKey('1');
          }
          if (e?.response?.data?.message) {
            openErrorNotification(
              e?.response?.data?.message || 'Something went wrong',
            );
          }
        })
        .finally(() => {
          setIsOpenSend(false);
        });
    }
  };

  const downloadFiles = () => {
    //download attachments zip
    if (invoiceId) {
      invoiceApi.getInvoiceZIP(invoiceId).then((resp: any) => {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.zip`);
        document.body.appendChild(link);
        link.click();
      });
    }

    //download invoice
    if (invoiceId) {
      invoiceApi.getPreview(invoiceId).then((resp: any) => {
        if (resp) {
          fileApi.downloadFileByUrl(resp.originalFilename);
        }
      });
    }
  };

  const setRequestShippingMethod = (values: any) => {
    if (values.shippingMethod === 'email') {
      return {
        email: values.shippingMethodEmail,
      };
    }
    if (values.shippingMethod === 'portal') {
      return {
        portalUrl: values.shippingMethodPortalUrl,
      };
    }
    if (values.shippingMethod === 'by post') {
      return {
        postalAddress: values.shippingMethodPostalAddress,
        firstName: values.shippingMethodFirstName,
        lastName: values.shippingMethodLastName,
      };
    }
  };

  const onChangeLayout = (layout: InvoiceLayout) => {
    setInvoice((prevState) => ({ ...prevState, layout } as IInvoice));
  };

  const onChangeStatus = (status: string, prevStatus: string | undefined) => {
    if (status === InvoiseStatuses.PAID && invoice) {
      invoiceApi
        .paidInvoice(invoice._id, form.getFieldValue('paymentComment'))
        .then((resp: any) => {
          openSuccessNotification('Status was changed successfully');
          setInvoice(resp);
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Failed to change status',
          ),
            setInvoice((prevState: any) => ({
              ...prevState,
              status: prevStatus || 'Sent',
            }));
        });
    }
    if (status === InvoiseStatuses.PARTIALLY_PAID && invoice) {
      invoiceApi
        .partiallyPaidInvoice(invoice._id, form.getFieldValue('paymentComment'))
        .then((resp: any) => {
          openSuccessNotification('Status was changed successfully');
          setInvoice(resp);
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Failed to change status',
          );
          setInvoice((prevState: any) => ({
            ...prevState,
            status: prevStatus || 'Sent',
          }));
        });
    }
    if (status === InvoiseStatuses.DRAFT) {
      saveAsDraft();
    }
    if (status === InvoiseStatuses.SENT) {
      onSendInvoice();
    }
  };

  const onChangeInvoiceDate = (date: string) => {
    if (invoice) {
      invoiceApi
        .changeInvoiceDate(date, invoice?._id)
        .then((resp: any) => {
          setInvoice((prevState: any) => ({
            ...prevState,
            invoiceDate: resp.invoiceDate,
            payByDate: resp.payByDate,
            fxRates: resp.fxRates,
            totalCalculations: resp.totalCalculations,
          }));
          form.setFieldValue('vat', '');
          setErrorMessages((prev) => [
            ...prev,
            { message: 'Please, select VAT', property: 'vat' },
          ]);
          getVats(resp.invoiceDate, resp.vatByDefault);
        })
        .catch((e) =>
          openErrorNotification(
            e?.response?.data?.message || 'Failed to changing date',
          ),
        );
    }
  };

  const onSendInvoice = () => {
    const values = form.getFieldsValue();
    if (values.shippingMethod === 'email' && invoice) {
      // clientApi.getClientById(invoice.clientId).then((resp: any) => {
      // if (
      //   !compareStringArrays(
      //     invoice.shippingMethodDetails?.emails,
      //     resp.invoiceries.map((item: any) => item.email),
      //   )
      // ) {
      //   openErrorNotification(
      //     "Your shipping method emails are different from the client's emails. Please click the button Refresh invoicing emails",
      //   );
      // } else {
      setIsOpenSend(true);
      // }
      // });
    } else {
      sendInvoice('');
    }
  };

  return (
    <div className="generate-invoice-main-container">
      <GenerateInvoiceHeader
        invoice={invoice}
        saveAsDraft={saveAsDraft}
        onChangeStatus={onChangeStatus}
        onSendInvoice={onSendInvoice}
      />
      <ContentWrapper
        invoice={invoice}
        form={form}
        errorMessages={errorMessages}
        client={client}
        onChangeLayout={onChangeLayout}
        onChangeInvoiceDate={onChangeInvoiceDate}
        setInvoice={setInvoice}
        saveAsDraft={saveAsDraft}
        vats={vats}
        setActiveKey={setActiveKey}
        activeKey={activeKey}
      />
      {isOpenSend && (
        <SendModal
          isOpen={isOpenSend}
          setIsOpen={setIsOpenSend}
          sendInvoice={sendInvoice}
        />
      )}
    </div>
  );
};

export default GenerateInvoicePage;
