import { Col, Row } from 'antd';
import { FC } from 'react';
import { ExpensesType } from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { IConsultantExpenses } from '../../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import { expensesType } from '../../../common/utils/mockExpensesType';
import Checkbox from '../../../components/checkbox/Checkbox';

import './ModalExpense.less';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  selectedExpense: IConsultantExpenses;
}

const ModalViewExpense: FC<IProps> = (props) => {
  const { selectedExpense } = props;
  const {
    workContract,
    formType,
    type,
    isSigned,
    approvalRequired,
    period,
    total,
    documents,
    companyRepresentativeEmail,
    representativeFirstName,
    representativeLastName,
    comments,
    numeriqComments,
  } = selectedExpense;

  return (
    <div className="expence-modal-mobile">
      <p className="primary">
        As your Expenses was submitted, you are not permitted to edit it. If
        something is wrong, please contact Numeriq.
      </p>
      <Row>
        <Col span={8}>
          <p className="info-title">Expenses form</p>
          <p className="info-content">{formType}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Type of expense</p>
          <p className="info-content">{expensesType[type]}</p>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p className="info-title">Client name</p>
          <p className="info-content">
            {type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
              ? workContract?.name || ''
              : 'Numeriq'}
          </p>
        </Col>
        <Col span={16}>
          <p className="info-title">Period</p>
          <p className="info-content">{getFormatDateWithMonth(period)}</p>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <p className="info-title">Total sum</p>
          <p className="info-content">{total}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Currency</p>
          <p className="info-content">
            {type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
            type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
              ? 'CHF'
              : workContract?.invoiceCurrency || ''}
          </p>
        </Col>
      </Row>

      {isSigned && (
        <Row justify="space-between">
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              label="My expenses has been Signed/Approved."
              checked
              disabled
            />
          </Col>
        </Row>
      )}
      {approvalRequired && type !== ExpensesType.EXPENSES_FOR_OPTIMISATION && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              checked
              label="My expenses requires manager's online approval."
              disabled
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p className="info-title">Attached file</p>
          <p className="info-content documents">
            {documents?.length &&
              documents.map(({ originalFilename }) => (
                <span key={originalFilename}>
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      fileApi.downloadFileByUrl(originalFilename);
                    }}>
                    {originalFilename}
                  </a>
                </span>
              ))}
          </p>
        </Col>
      </Row>

      {type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT && approvalRequired && (
        <>
          <Row>
            <Col span={24}>
              <p className="info-title">Client representative email</p>
              <p className="info-content">{companyRepresentativeEmail}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <p className="info-title">Representative First Name</p>
              <p className="info-content">{representativeFirstName}</p>
            </Col>
            <Col span={14}>
              <p className="info-title">Representative Last Name</p>
              <p className="info-content">{representativeLastName}</p>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className="info-title">Comments for the Client</p>
              <p className="info-content">{comments || '-'}</p>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p className="info-title">Comments for Numeriq</p>
          <p className="info-content">{numeriqComments || '-'}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ModalViewExpense;
