import { ApiServiceBase } from '../../ApiServiceBase';
import { IAuthenticationDto } from './types/IAuthenticationDto';
import { AuthRoutes } from './enums/AuthRoutes';
import { ILoginRequest } from './types/ILoginRequest';
import { emptyAuthResponse } from './utils/emptyAuthResponse';
import { parsErrorMessage } from '../../utils/parsErrorMessage';
import { IIsAuthResponse } from './types/IIsAuthResponse';
import { TAnyObject } from '../../../types/TAnyObject';

class ApiAuth extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1`;

  isAuthenticated(email: string, password: string): Promise<IIsAuthResponse> {
    return this.post<ILoginRequest, IAuthenticationDto>(
      '/auth/' + AuthRoutes.LOGIN,
      { email, password },
    )
      .then((response) => ({
        data: response,
      }))
      .catch((e) => ({
        message: parsErrorMessage(e),
        data: emptyAuthResponse,
      }));
  }

  confirmLeadPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Promise<TAnyObject | string> {
    return this.post('/user/' + AuthRoutes.CONFIRM, {
      password,
      confirmPassword,
      token,
    })
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }

  confirmEmployeePassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Promise<TAnyObject | string> {
    return this.post('/user/employee/' + AuthRoutes.CONFIRM, {
      password,
      confirmPassword,
      token,
    })
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }

  requestForNewPassword(email: string): Promise<TAnyObject | string> {
    return this.post('/user/' + AuthRoutes.PASSWORD_RESET, { email })
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }

  resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Promise<TAnyObject | string> {
    return this.put('/user/' + AuthRoutes.PASSWORD_RESET, {
      password,
      confirmPassword,
      token,
    })
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }
}

export const apiAuth = new ApiAuth();
