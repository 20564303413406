import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEmployeeDto } from '../../../common/api/services/UserApi/types/IEmployeeDto';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IState } from './types';
import { IInviteEmployeeResponse } from '../../../common/api/services/UserApi/types/IInviteEmployeeResponse';
import { IPagination, TSortValue } from '../../types';

const initialState: IState = {
  employeesList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  inviteLink: {
    link: '',
    loading: false,
    userEmail: '',
  },
  isOpenModalNewEmployee: false,
  isOpenModalInvite: false,
  errorMessages: null,
};

const reducer = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    putEmployeeList(state, action: PayloadAction<IGetListDto<IEmployeeDto>>) {
      state.employeesList.content = action.payload.result;
      state.employeesList.pagination.totalElement = action.payload.total;
    },
    setEmployeesPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.employeesList.pagination.currentPage = action.payload.currentPage;
      state.employeesList.pagination.perPage = action.payload.perPage;
    },
    updateOpenModalNewEmployee(state, action: PayloadAction<boolean>) {
      state.isOpenModalNewEmployee = action.payload;
    },
    updateOpenModalInvite(state, action: PayloadAction<boolean>) {
      state.isOpenModalInvite = action.payload;
    },
    setErrorMessages(
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) {
      state.errorMessages = action.payload;
    },
    setEmployeeInviteData(
      state,
      action: PayloadAction<IInviteEmployeeResponse>,
    ) {
      state.inviteLink.link = action.payload.inviteUrl;
      state.inviteLink.userEmail = action.payload.email;
    },
    updateEmployeeInviteLoading(state, action: PayloadAction<boolean>) {
      state.inviteLink.loading = action.payload;
    },
    clearEmployeeInviteLink(state) {
      state.inviteLink = initialState.inviteLink;
    },
    changeEmployeeFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.employeesList.filter = {
        ...state.employeesList.filter,
        ...action.payload,
      };
    },
    setEmployeeSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.employeesList.sort.sortBy = action.payload[0];
      state.employeesList.sort.sort = action.payload[1];
    },
    clearFiltersAndSorts(state) {
      state.employeesList.sort = initialState.employeesList.sort;
      state.employeesList.filter = initialState.employeesList.filter;
    },
  },
});

export const {
  putEmployeeList,
  setEmployeesPagination,
  updateOpenModalNewEmployee,
  setErrorMessages,
  updateOpenModalInvite,
  setEmployeeInviteData,
  updateEmployeeInviteLoading,
  clearEmployeeInviteLink,
  changeEmployeeFiltering,
  setEmployeeSorting,
  clearFiltersAndSorts,
} = reducer.actions;
export default reducer.reducer;
