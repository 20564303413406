import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { ISort } from '../../../../redux/types';
import { getQueryParams } from '../../utils/getQueryParams';
import { ITimesheetResponse } from './types/ITimesheetResponse';
import { IConsultantTimesheet } from '../ConsultantApi/types/IConsultantTimesheet';
import { IResponse } from '../../types/IResponse';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { INewTimesheet } from './types/INewTimesheet';

class TimesheetApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/timesheet`;

  fetchTimesheets(
    currentPage: number,
    perPage: number,
    filter: Record<string, string>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<ITimesheetResponse>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  fetchTimesheetById(id: string) {
    return this.get<IGetListDto<ITimesheetResponse>>(`/${id}`, {})
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  fetchTimesheetBySelectedIds(selectedIds: string[]) {
    const query: { selectedIds?: string } = {};
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    return this.get<IGetListDto<ITimesheetResponse>>('', { query })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  newTimesheet(query: INewTimesheet): Promise<IResponse> {
    return this.post<INewTimesheet, IConsultantTimesheet>('', query)
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }
  generateTimesheets(year: number, month: number, perMonthOnly?: boolean) {
    return this.get(
      `/perform-automatic-generation/${year}/${month}?perMonthOnly=${(!!perMonthOnly).toString()}`,
    );
  }
  addTimesheetNotes(timesheetId: string, notes: string) {
    return this.put(`/${timesheetId}/notes`, { notes });
  }
  deleteTimesheet(timesheetId: string) {
    return this.delete(`/${timesheetId}`);
  }
}

export const timesheetApi = new TimesheetApi();
