import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { persistStore } from 'redux-persist';
import App from './App';
import { store, history } from './redux/store';

import './index.less';

import * as package_json from '../package.json';

const persistor = persistStore(store);

console.log('VERSION: -------->', package_json.version);

ReactDOM.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </PersistGate>,
  document.getElementById('root') as HTMLElement,
);
