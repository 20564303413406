import { ChangeEvent, FC, Fragment, SetStateAction, Dispatch } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import { IChildren } from '../../../../redux/reducers/onboarding/types';
import { childGenderParser } from '../../../../common/constants/childGenderParser';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import Textarea from '../../../../components/textarea/Textarea';
import { useAppSelector } from '../../../../redux/store';
import { Roles } from '../../../../common/enums/Roles';

const genders = ['boy', 'girl'];

interface IProps {
  children: IChildren[];
  hasChildren: boolean;
  getError: (fieldName: string) => string;
  onAddNewChild: () => void;
  onChangeRadio: (event: RadioChangeEvent) => void;
  onChangeChildInput: (
    id: number,
  ) => (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent) => void;
  onChangeChildDate: (
    id: number,
    fieldName: string,
  ) => (date: Date | null) => void;
  onChangeChildDropdown: (id: number, fieldName: string) => (data: any) => void;
  onRemove: Dispatch<SetStateAction<number>>;
  onChangeNotes: (text: string) => void;
  notes?: string;
}

const ChildAllowanceInformationSection: FC<IProps> = ({
  children,
  notes,
  hasChildren,
  getError,
  onChangeChildInput,
  onChangeChildDate,
  onChangeRadio,
  onRemove,
  onChangeChildDropdown,
  onAddNewChild,
  onChangeNotes,
}) => {
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;
  return (
    <Col span={12}>
      <h4>Child&apos;s information</h4>
      <br />
      <span>Mandatory if you are subject to tax at source</span>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col span={17}>
          <span>
            Do you have dependent children under 25 in full-time education?
          </span>
        </Col>
        <Col span={7}>
          <Radio.Group
            name="hasChildren"
            value={hasChildren}
            onChange={onChangeRadio}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      {children.map((child, idx) => {
        const {
          id,
          firstName,
          lastName,
          dateOfBirth,
          gender,
          SSNumber,
          useFamilyAllowance,
        } = child;

        return (
          <Fragment key={id}>
            {children.length > 1 && (
              <Row gutter={[16, 16]} justify="space-between" align="middle">
                <h4>Child {idx + 1}</h4>
                {idx > 0 && (
                  <button
                    onClick={() => onRemove(id)}
                    className="remove-child-btn">
                    Delete
                    <TrashIcon />
                  </button>
                )}
              </Row>
            )}

            {hasChildren && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Input
                    name="firstName"
                    label="First name"
                    value={firstName}
                    onChange={onChangeChildInput(id)}
                    disabled={!hasChildren}
                    errorMessage={getError(`data.children[${idx}].firstName`)}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="lastName"
                    label="Last name"
                    value={lastName}
                    onChange={onChangeChildInput(id)}
                    disabled={!hasChildren}
                    errorMessage={getError(`data.children[${idx}].lastName`)}
                  />
                </Col>
              </Row>
            )}

            {hasChildren && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <CommonDatePicker
                    label="Date of birth"
                    defaultValue={dateOfBirth || ''}
                    changeDate={onChangeChildDate(id, 'dateOfBirth')}
                    className="date-picker-form"
                    errorMessage={getError(`data.children[${idx}].dateOfBirth`)}
                    disabled={!hasChildren}
                    calendarPosition="right-calendar-position"
                  />
                </Col>
                <Col span={12}>
                  <Dropdown
                    options={genders}
                    value={childGenderParser[gender as 'man' | 'woman'] ?? ''}
                    onChange={onChangeChildDropdown(id, 'gender')}
                    label="Gender"
                    disabled={!hasChildren}
                    showSearch={false}
                    errorMessage={getError(`data.children[${idx}].gender`)}
                    allowClear
                    onClear={() => onChangeChildDropdown(id, 'gender')('')}
                  />
                </Col>
              </Row>
            )}

            {hasChildren && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    name="SSNumber"
                    label="AVS number"
                    value={SSNumber}
                    placeholder="756.XXXX.XXXX.XX"
                    onChange={onChangeChildInput(id)}
                    disabled={!hasChildren}
                    errorMessage={getError(`data.children[${idx}].SSNumber`)}
                  />
                </Col>
              </Row>
            )}

            {hasChildren && (
              <Row
                justify="space-between"
                align="middle"
                gutter={[16, 16]}
                style={{ height: 54 }}>
                <Col span={17}>
                  <span>
                    Do you want to receive the family allowance for this child?
                  </span>
                </Col>
                <Col span={7}>
                  <Radio.Group
                    onChange={onChangeChildInput(id)}
                    value={useFamilyAllowance}
                    name="useFamilyAllowance"
                    disabled={!hasChildren}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Fragment>
        );
      })}

      {hasChildren && (
        <button
          className="add-common-btn"
          disabled={!hasChildren}
          onClick={onAddNewChild}>
          <CrossIcon />
          Add a child
        </button>
      )}
      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Textarea
              name="comments"
              value={notes || ''}
              rows={3}
              label="Comment"
              onChange={(e) => onChangeNotes(e.target.value)}
              autoSize={false}
              showAsterik
            />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default ChildAllowanceInformationSection;
