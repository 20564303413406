import { FC, useEffect, useState } from 'react';
import { Col, Row, Switch } from 'antd';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';

import '../../internal-expenses-page/components/ModalExpense.less';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantsResponse } from '../../../common/api/services/ConsultantApi/types/IConsultantsResponse';
import useDebounce from '../../../common/hooks/useDebounce';
import ILabelInValue from '../../../common/types/ILabelInValue';
import moment from 'moment';
import { INewExpense } from '../../../common/api/services/ExpensesApi/types/INewExpense';
import { isMonthDisabled } from '../../../common/utils/isMonthDisabled';
import {
  ExpensesType,
  MainActivityType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import { commonYearDateFormat } from '../../../common/constants/dateConstants';
import { optionMainActivityTypes } from '../../../common/utils/mockExpensesType';
import { timesheetApi } from '../../../common/api/services/TimesheetApi/timesheetApi';
import { expensesApi } from '../../../common/api/services/ExpensesApi/expensesApi';
import { SalaryType } from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';

const initDropdownValue = { label: '', value: '' };
const initDate = { startDate: undefined, endDate: undefined };
interface IProps {
  handleCancel: () => void;
  getMainActivities: () => void;
}

const AddMainActivityModal: FC<IProps> = (props) => {
  const { handleCancel, getMainActivities } = props;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const [consultants, setConsultants] =
    useState<IGetListDto<IConsultantsResponse> | null>(null);
  const [optionConsultants, setOptionConsultants] = useState<ILabelInValue[]>(
    [],
  );
  const [selectedConsultant, setSelectedConsultant] = useState<ILabelInValue>({
    ...initDropdownValue,
    options: [],
  });
  const [selectedConsultantData, setSelectedConsultantData] = useState(null);
  const [searchConsultant, setSearchConsultant] = useState<string>('');
  const searchConsultantDeb = useDebounce(searchConsultant, 500);

  const [clients, setClients] = useState<ILabelInValue[]>([]);
  const [visibility, setVisibility] = useState(true);
  const [isDisabledVisibility, setIsDisabledVisibility] =
    useState<boolean>(false);

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';

      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const [selectedClient, setSelectedClient] =
    useState<ILabelInValue>(initDropdownValue);

  const [selectedType, setSelectedType] =
    useState<ILabelInValue>(initDropdownValue);

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [dateContract, setDateContract] =
    useState<Record<string, string | undefined>>(initDate);

  const [isShowPeriodWarning, setIsShowPeriodWarning] =
    useState<boolean>(false);

  const isEOType =
    selectedType.value === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
    selectedType.value === ExpensesType.EXPENSES_MEAL_ALLOWANCE;

  useEffect(() => {
    if (isEOType) {
      setVisibility(true);
    }
  }, [selectedType]);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const response = await consultantApi.getConsultants(
        1,
        0,
        {
          fullName: searchConsultantDeb,
          consultantOnboardingStatus: 'published,toConfirm,archived',
        },
        { sort: 'asc', sortBy: 'firstName' },
      );
      const data = response?.result.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item._id,
      }));
      if (data) {
        setConsultants(response);
        setOptionConsultants(data);
      }
      setIsFetching(false);
    };

    getData();
  }, [searchConsultantDeb]);

  const onSearchConsultant = (value: string) => {
    setSearchConsultant(value);
  };

  const onChangeConsultant = (item: ILabelInValue) => {
    const types: string[] = [];

    setSelectedClient(initDropdownValue);
    setSelectedType(initDropdownValue);
    setSelectedDate('');
    setDateContract(initDate);
    if (!item) {
      setSelectedConsultant(initDropdownValue);
      setClients([]);
      return;
    }

    const consultant = consultants?.result.find(
      (consultant) => consultant._id === item.value,
    )?.consultantOnboarding;

    if (consultant?.workContract?.useExpensesForOptimization) {
      types.push(MainActivityType.EXPENSES_FOR_OPTIMISATION);
    }

    if (consultant?.workContract?.useMealAllowance) {
      types.push(MainActivityType.EXPENSES_MEAL_ALLOWANCE);
    }

    if (
      consultant?.clientContract?.clients.some(
        (client) => client.reimbursableExpenses,
      )
    ) {
      types.push(MainActivityType.EXPENSES_REIMBURSED_BY_CLIENT);
    }
    types.push(MainActivityType.TIMESHEET);

    setSelectedConsultant({
      value: item.value,
      label: item.label,
      options: types,
    });

    const allClients =
      consultant?.workContract?.companies.map((company) => ({
        label: `${company.name} ${
          company.projectDescription ? `(${company.projectDescription})` : ''
        }`,
        value: company._id,
      })) || [];
    setClients(allClients);
    //TODO: on change type of document
  };

  const onChangeClient = (item: ILabelInValue) => {
    const consultant = consultants?.result.find(
      (consultant) => consultant._id === selectedConsultant.value,
    )?.consultantOnboarding;

    const clientContract = consultant?.clientContract?.clients.find(
      (client) => client.companyId === item.value,
    );

    const companies = consultant?.workContract?.companies.find(
      (client) => client._id === item.value,
    );
    const isSalaryPerMonth = companies?.type === SalaryType.PerMonth;

    setIsDisabledVisibility(isSalaryPerMonth);
    setVisibility(!isSalaryPerMonth);

    setSelectedClient({ value: item.value, label: item.label });

    const { startDate, endDate } = clientContract || {};
    setDateContract({ startDate, endDate });
    setSelectedDate('');
  };

  const onChangeType = (item: ILabelInValue) => {
    const consultant = consultants?.result.find(
      (consultant) => consultant._id === selectedConsultant.value,
    )?.consultantOnboarding;
    const allClients =
      consultant?.workContract?.companies.map((company) => ({
        label: `${company.name} ${
          company.projectDescription ? `(${company.projectDescription})` : ''
        }`,
        value: company._id,
      })) || [];
    const clientsWithReimbursableExpenses = allClients
      .filter((company) =>
        consultant?.clientContract?.clients.some(
          (client) =>
            client.companyId === company.value && client.reimbursableExpenses,
        ),
      )
      .sort((a, b) => a.label.localeCompare(b.label));
    setClients(
      item.value === MainActivityType.EXPENSES_REIMBURSED_BY_CLIENT
        ? clientsWithReimbursableExpenses
        : allClients,
    );

    setSelectedClient(initDropdownValue);
    setSelectedType({ value: item.value, label: item.label });
    setSelectedDate('');
  };

  const onClear = () => {
    setSearchConsultant('');
  };

  const isExistExpensesByPeriod = (period: string, type: string): void => {
    const checkPeriod = async () => {
      const response = await consultantApi.getConsultantExpensesByConsultantId(
        selectedConsultant.value,
        1,
        1,
        {
          period,
          type,
          clientName: selectedClient.label,
        },
        { sort: '', sortBy: '' },
      );
      const data = response?.total;
      setIsShowPeriodWarning(!!data);
    };
    checkPeriod();
  };

  const onChangeDate = (value: Date | null) => {
    setSelectedDate(value ? moment(value)?.format(commonYearDateFormat) : '');
    if (value) {
      isExistExpensesByPeriod(
        moment(value).format(commonYearDateFormat),
        selectedType.value,
      );
    }
  };

  const hasReimbursedByClient = selectedConsultant.options?.includes(
    ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
  );

  const listTypes = optionMainActivityTypes.filter((item) =>
    selectedConsultant.options?.includes(item.value),
  );

  const publishMainActivity = async () => {
    const body: any = {
      consultantId: selectedConsultant.value,
      companyId: selectedClient.value,
      period: selectedDate,
      visibility: visibility ? 'public' : 'private',
    };
    if (selectedType.value === MainActivityType.TIMESHEET) {
      const { isSuccess, data } = await timesheetApi.newTimesheet(body);
      if (isSuccess) {
        handleCancel();
        getMainActivities();
      } else {
        setErrorMessages(data);
      }
      return;
    }
    if (
      selectedType.value === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
      selectedType.value === ExpensesType.EXPENSES_MEAL_ALLOWANCE
    ) {
      delete body.companyId;
    }
    body.type = selectedType.value;
    const { isSuccess, data } = await expensesApi.newExpense(body);

    if (isSuccess) {
      handleCancel();
      getMainActivities();
    } else {
      setErrorMessages(data);
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 24 }}>
        <Col span={24}>
          <Dropdown
            allowClear={!!selectedConsultant.value}
            labelInValue={true}
            isSearch={false}
            options={optionConsultants}
            value={selectedConsultant}
            onChange={onChangeConsultant}
            onSearch={onSearchConsultant}
            onClear={onClear}
            label="Consultant"
            isFetching={isFetching}
            errorMessage={getError(`consultantId`)}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Dropdown
            labelInValue={true}
            options={listTypes}
            value={selectedType}
            onChange={onChangeType}
            label="Type of document"
            showSearch={false}
            disabled={listTypes.length === 0}
          />
        </Col>
      </Row>

      {selectedConsultant.value &&
        (selectedType.value === MainActivityType.TIMESHEET ||
          !isEOType ||
          (hasReimbursedByClient &&
            selectedType.value ===
              MainActivityType.EXPENSES_REIMBURSED_BY_CLIENT)) && (
          <Row>
            <Col span={24}>
              <Dropdown
                options={clients}
                labelInValue={true}
                value={selectedClient}
                disabled={clients.length === 0}
                onChange={onChangeClient}
                label="Client"
                showSearch={false}
                errorMessage={getError(`companyId`)}
              />
            </Col>
          </Row>
        )}

      <Row style={{ height: '75px' }}>
        <Col span={24}>
          <CommonDatePicker
            picker="month"
            label="Period"
            defaultValue={selectedDate || ''}
            changeDate={onChangeDate}
            className="date-picker-form"
            errorMessage={getError(`period`)}
            disabledDate={
              isEOType ? () => false : isMonthDisabled(dateContract)
            }
            disabled={!selectedClient.value && !isEOType}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="switch-container">
          <span>Visible for consultant</span>
          <Switch
            onChange={setVisibility}
            checked={visibility}
            disabled={isEOType || isDisabledVisibility}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Button
            buttonStyle="secondary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            buttonStyle="primary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={publishMainActivity}>
            Publish
          </Button>
        </Col>
      </Row>

      {getError('') && (
        <p className="error" style={{ maxWidth: '400px' }}>
          {getError('')}.
        </p>
      )}

      {isShowPeriodWarning && (
        <p className="error" style={{ maxWidth: '375px' }}>
          You already have the Expenses for picked month. Are you sure you want
          to create another one?
        </p>
      )}
      {selectedConsultant.value && selectedConsultant.options?.length === 0 && (
        <p className="error" style={{ maxWidth: '375px' }}>
          Both types of the expenses are not activated for this Consultant
        </p>
      )}
    </>
  );
};

export default AddMainActivityModal;
