import { FC } from 'react';
import InfoIcon from '../../assets/icons/InfoIcon';
import './InfoMessages.less';

const OnboardingWorkPermitMessage: FC = () => (
  <div className="information-message">
    <div className="icon-wrapper">
      <InfoIcon />
    </div>
    <p>
      As you are a citizen of Switzerland you don’t need to fill information for
      a work permit. You can skip this step.
    </p>
  </div>
);

export default OnboardingWorkPermitMessage;
