import { Modal } from 'antd';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import Button from '../../../components/button/Button';
import moment from '../../../common/constants/moment';
import { useState } from 'react';
import {
  commonDateFormat,
  commonDateMonthFormat,
  commonMonthTimeFormat,
  commonYearDateFormat,
} from '../../../common/constants/dateConstants';

interface IProps {
  isOpen: boolean;
  payslipsCount: number;
  closePublishModal: () => void;
  onPublishPayslips: (date: string) => void;
}

const PublishPayslipsModal = (props: IProps) => {
  const { isOpen, payslipsCount, onPublishPayslips, closePublishModal } = props;
  const [date, setDate] = useState('');

  const onChangeDate = (dateFromPicker: Date | null) => {
    setDate(moment(dateFromPicker).format(commonDateMonthFormat));
  };

  const confirmAction = () => {
    onPublishPayslips(date ? moment(date).format(commonYearDateFormat) : '');
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '2rem 1rem 0 1rem',
          gap: '20px',
        }}>
        <span style={{ fontSize: '16px' }}>
          Are you sure you want to publish these {payslipsCount || ''} files?
        </span>
        <span style={{ fontSize: '16px' }}>
          You can set up a payslip payment date for all files below
        </span>
        <div style={{ height: '43px' }}>
          <CommonDatePicker
            defaultValue={date}
            changeDate={(date) => onChangeDate(date)}
            className="date-picker-form"
            placeholder=""
          />
        </div>
        <div className="btns-wrapper">
          <Button
            buttonWidth={'200px'}
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={closePublishModal}>
            Cancel
          </Button>
          <Button
            buttonWidth={'200px'}
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => confirmAction()}>
            Yes, publish
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PublishPayslipsModal;
