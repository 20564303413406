import { useEffect, useRef, useState } from 'react';
import { postCodeApi } from '../../../common/api/services/PostCodeApi/postCodeApi';
import {
  IHouse,
  IHouses,
} from '../../../common/api/services/PostCodeApi/types';
import Dropdown from '../../../components/dropdown/Dropdown';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  street: string;
  idx: number;
  onChangeDropdownAddress: (
    idx: number,
    extraName: string,
  ) => (value: any) => void;
  getError: (fileName: string) => string;
  onSelectStreet: (
    idx: number,
    postcode: string,
    street: string,
    city: string,
  ) => () => void;
}

const parseAddressLabel = (address: IHouse) => {
  return `${address.street.streetName}, (${address.street.zip.zip}), ${address.street.zip.city18}`;
};

const getValueFromAddress = (address: string) => {
  return address ? address.split(',') : [];
};

const parsePostCode = (code: string) => {
  return code ? code.substring(1, code.length - 1) : '';
};

const StreetDropdown = (props: IProps) => {
  const { street, onChangeDropdownAddress, idx, getError, onSelectStreet } =
    props;

  const [searchedAddresses, setSearchedAddresses] = useState<IHouses>([]);
  const [searchStreet, setSearchStreet] = useState('');
  const refPrevInput = useRef<string>('');

  useEffect(() => {
    if (street !== searchStreet) {
      setSearchStreet(street);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [street]);

  const getAddresses = (streetValue: string) => {
    if (streetValue === '' && refPrevInput.current?.length !== 1) return;
    refPrevInput.current = streetValue;
    setSearchStreet(streetValue);
    onChangeDropdownAddress(idx, 'street')(streetValue);
    postCodeApi
      .getFullAddress(streetValue.toLowerCase(), '')
      .then((value: any) => setSearchedAddresses(value));
  };

  const selectAddress = (e: string, idx: any) => {
    const selectedStreet = getValueFromAddress(e)[0]?.trim();
    if (selectedStreet === '' && refPrevInput.current?.length !== 1) return;
    refPrevInput.current = getValueFromAddress(e)[0]?.trim();
    setSearchStreet(selectedStreet || '');
    onSelectStreet(
      idx,
      parsePostCode(getValueFromAddress(e)[1]?.trim()) || '',
      selectedStreet || '',
      getValueFromAddress(e)[2]?.trim() || '',
    )();
  };

  return (
    <Dropdown
      options={searchedAddresses.map((a) => parseAddressLabel(a))}
      label="Street name and address"
      value={street}
      searchValue={searchStreet}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      onSearch={(e: string) => {
        getAddresses(e);
      }}
      errorMessage={getError(`addresses[${idx}].street`)}
      isSearch={true}
      onSelect={(e: string) => {
        selectAddress(e, idx);
      }}
    />
  );
};

export default StreetDropdown;
