import { DatePicker, Input } from 'antd';
import { useEffect, useState } from 'react';
import DatePickerIcon from '../../assets/icons/DatePickerIcon';
import { openErrorNotification } from '../notification/Notification';
import { convertStringToDate } from '../../common/utils/getFormatDate';
import {
  commonDateFormat,
  commonMonthFormat,
} from '../../common/constants/dateConstants';
import CrossIcon from '../../assets/icons/CrossIcon';
import './DatePicker.less';
import moment from '../../common/constants/moment';

interface IProps {
  allowClear?: boolean;
  defaultValue: string;
  changeDate: (date: Date | null) => void;
  className?: string;
  label?: string;
  errorMessage?: string;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  disabled?: boolean;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  disabledDate?: (current: moment.Moment) => boolean;
  placeholder?: string;
  calendarPosition?: 'right-calendar-position' | 'left-calendar-position';
}
interface IInputProps {
  value: string | undefined;
  onChangeDate: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  setDate: (value: string | undefined) => void;
  label?: string;
  disabled?: boolean;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: string;
}

const CustomInput = (props: IInputProps) => {
  const {
    value,
    onChangeDate,
    setIsOpen,
    isOpen,
    setDate,
    label,
    disabled,
    picker,
    placeholder,
  } = props;
  const [inputValue, setInputValue] = useState<string | undefined>('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChangeInputValue = (inputValue: string) => {
    if (picker === 'month') {
      return;
    }
    if (!inputValue) {
      onChangeDate('');
      return;
    }
    const input = inputValue.replace(/\D/g, ''); // remove non-numeric characters
    const formattedInput = input.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3'); // format the input with slashes
    setInputValue(formattedInput);
    const date = moment(formattedInput, 'DD/MM/YYYY');
    if (!date.isValid() && input.length === 8) {
      openErrorNotification('Invalid date');
      setDate(value);
      setInputValue(value);
      setIsOpen(false);
      return;
    }
    if (input.length === 8) {
      onChangeDate(formattedInput);
    }
  };

  return (
    <>
      {label && <label className="date-picker-label">{label}</label>}
      <Input
        value={inputValue || ''}
        onChange={(e) => onChangeInputValue(e.target.value)}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
        disabled={!!disabled}
        placeholder={placeholder}
      />
    </>
  );
};

const CommonDatePicker = (props: IProps) => {
  const {
    allowClear,
    defaultValue,
    changeDate,
    className,
    label,
    errorMessage,
    disabled,
    picker,
    disabledDate,
    placeholder,
    placement,
    calendarPosition,
  } = props;
  const [date, setDate] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const format = picker === 'month' ? commonMonthFormat : commonDateFormat;

  useEffect(() => {
    setDate(defaultValue ? moment(defaultValue).format(format) : undefined);
  }, [defaultValue, format]);

  const onChangeDate = (value: string) => {
    setDate(value || undefined);
    changeDate(value ? convertStringToDate(value, format) : null);
    setIsOpen(false);
  };
  const onDatePickerSelect = (value: any) => {
    setDate(value.format(format));
    changeDate(convertStringToDate(value, format));
    setIsOpen(false);
  };

  return (
    <div
      className={`common-date-picker ${
        errorMessage ? 'error-form form-date-picker' : ''
      }`}
      tabIndex={0}
      onBlur={() => setIsOpen(false)}>
      <DatePicker
        inputReadOnly
        format={format}
        disabled={!!disabled}
        value={date ? moment(date, format) : undefined}
        allowClear={!!allowClear}
        open={isOpen}
        popupClassName={calendarPosition || ''}
        onClick={() => setIsOpen(!isOpen)}
        picker={picker || 'date'}
        placement={placement || 'bottomLeft'}
        disabledDate={disabledDate}
        onFocus={() => setIsOpen(false)}
        getPopupContainer={(trigger: any) => trigger.parentElement}
        onSelect={onDatePickerSelect}
        className={className || ''}
        suffixIcon={<DatePickerIcon />}
        inputRender={() => (
          <CustomInput
            value={date}
            onChangeDate={onChangeDate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setDate={setDate}
            label={label}
            disabled={disabled}
            picker={picker}
            placeholder={placeholder}
          />
        )}
      />
      {errorMessage && (
        <span className="error-message error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default CommonDatePicker;
