import { ApiServiceBase } from '../../ApiServiceBase';
import { IUploadFileResponse } from './types/IUploadFileResponse';
import { UploadEntity, UploadEntityId } from './enums/RequestEnums';
import { IUploadActivityReportFileRequest } from './types/IUploadActivityReportFileRequest';
import { openErrorNotification } from '../../../../components/notification/Notification';

class FileApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/file`;

  downloadFileByUrl(fileName: string) {
    return this.get(`/${fileName}`, { responseType: 'blob' })
      .then((res: any) => {
        return res.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer]);
        const tempUrl = URL.createObjectURL(blob);
        const aTag = document.createElement('a');
        aTag.href = tempUrl;
        aTag.download = fileName;
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      })
      .catch((error) => {
        console.log(error);
        openErrorNotification(
          error?.response?.data?.message || 'Something went wrong',
        );
        return null;
      });
  }
  uploadFile(
    files: File[],
    entityId: UploadEntityId,
    entity: UploadEntity,
  ): Promise<IUploadFileResponse[] | null> {
    const formData = this.formatFormData({ files });

    return this.post<FormData, IUploadFileResponse[]>('', formData, {
      query: { entityId, entity },
    })
      .then((response) => response)
      .catch((error) => {
        console.log(error);
        openErrorNotification(
          error?.response?.data?.message || 'Something went wrong',
        );
        return null;
      });
  }

  uploadFileByUploadFile(
    files: File[],
    entityId: UploadEntityId,
    entity: UploadEntity,
  ): Promise<IUploadFileResponse[] | null> {
    const formData = new FormData();
    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('files', data.originFileObj);
      });

    return this.post<FormData, IUploadFileResponse[]>('', formData, {
      query: { entityId, entity },
    })
      .then((response) => response)
      .catch((error) => {
        console.log(error);
        openErrorNotification(
          error?.response?.data?.message || 'Something went wrong',
        );
        return null;
      });
  }

  removeFileByName(originalFilename: string) {
    return this.delete(`/${originalFilename}`)
      .then((response) => response)
      .catch(() => null);
  }

  getFileByUrl(fileName: string) {
    return this.get(`/${fileName}`, { responseType: 'blob' })
      .then((res: any) => {
        return res.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      })
      .catch((error) => {
        console.log(error);
        openErrorNotification(
          error?.response?.data?.message || 'Something went wrong',
        );
        return null;
      });
  }

  getExcelFileByUrl(fileName: string) {
    return this.get(`/${fileName}`, { responseType: 'blob' })
      .then((res: any) => {
        return res.arrayBuffer();
      })
      .then((arrayBuffer) => {
        return new Blob([arrayBuffer]);
      })
      .catch((error) => {
        console.log(error);
        openErrorNotification(
          error?.response?.data?.message || 'Something went wrong',
        );
        return null;
      });
  }

  uploadActivityReportFile(query: IUploadActivityReportFileRequest) {
    const formData = this.formatFormData(query);

    return this.post<FormData, IUploadFileResponse[]>('', formData, {
      query: { entityId: 'payslipFile', entity: 'consultantOnboarding' },
    })
      .then((response) => response)
      .catch(() => null);
  }

  uploadEmployeePayslips(files: any, period: string, type: string) {
    const formData = new FormData();
    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('files', data.originFileObj);
      });
    formData.append('period', period);
    formData.append('type', type);

    return this.post<FormData, IUploadFileResponse[]>('/payslip', formData);
  }
  uploadDocuments(files: any, consultantId: string, notes?: string) {
    const formData = new FormData();
    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('files', data.originFileObj);
      });

    formData.append('consultantId', consultantId);

    if (notes) {
      formData.append('notes', notes);
    }
    return this.post<any, IUploadFileResponse[]>('/document', formData);
  }
  uploadEmployeePayslipsReport(files: any, publicId: number, period: string) {
    const formData = new FormData();
    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('files', data.originFileObj);
      });
    formData.append('period', period);
    formData.append('type', 'payslip');
    return this.post<FormData, IUploadFileResponse[]>(
      `/payslip/report?publicId=${publicId}`,
      formData,
    );
  }

  uploadClientDocuments(files: any, clientId: string, type: string) {
    const formData = this.formatFormData({ files });
    formData.append('clientId', clientId);
    formData.append('type', type);
    return this.post<FormData, IUploadFileResponse[]>(`/client`, formData);
  }
  uploadInvoiceAttachments(file: any, invoicePublicId: string) {
    const formData = new FormData();
    file
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('files', data.originFileObj);
      });
    formData.append('invoicePublicId', invoicePublicId);
    return this.post<FormData, IUploadFileResponse[]>(
      `?entityId=attachment&entity=invoice`,
      formData,
    );
  }
}

export const fileApi = new FileApi();
