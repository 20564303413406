import { FC } from 'react';
import { Col, Row } from 'antd';
import Button from '../../button/Button';
import { useAppDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { changeIsShowDetails } from '../../../redux/reducers/waiting-room/sliceReducer';

const { waitingRoom } = ROUTER_PATHS;

const EditLeadFooter: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const goBack = () => {
    dispatch(changeIsShowDetails(false));
    navigate(waitingRoom);
  };

  return (
    <Row
      className="waiting-room-footer"
      justify="end"
      align="middle"
      style={{ padding: '13px 40px' }}>
      <Row gutter={[16, 0]}>
        <Col>
          <Button buttonSize="normal" buttonStyle="secondary" onClick={goBack}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default EditLeadFooter;
