import React from 'react';
import { IMobileFilter } from '../../types';
import Input from '../../../input/Input';
import MagnifierIcon from '../../../../assets/icons/MagnifierIcon';

interface IProps {
  filter: any;
  onChangeFilter: (value: string, key: string) => void;
}

const SearchFilter = (props: IProps) => {
  const { filter, onChangeFilter } = props;
  return (
    <div className="icon-wrapper search-mobile-filter">
      <h4>{filter.title}</h4>
      <Input
        placeholder="Search"
        value={filter.defaultFilter}
        onChange={(e: any) => onChangeFilter(e.target.value, filter.dataIndex)}
        label={filter.title}
      />
      <MagnifierIcon />
    </div>
  );
};

export default SearchFilter;
