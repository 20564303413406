import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOnboardingSaveOptions } from '../types';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { ITaxSource } from '../../../../common/api/services/OnboardingApi/types/ITaxSource';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IRootState } from '../../../types';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { push } from 'redux-first-history';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import {
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
  setOnboardingTaxAtSouce,
} from '../sliceReducer';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const confirmLaterTaxAtSourceAsEmployee = createAsyncThunk(
  'onboarding/confirmLaterTaxAtSource',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { moveToNextPage, redirectRootRout } = options;
    const { taxAtSource, consultantId, tabStatus } = (getState() as IRootState)
      .onboarding;

    const dataQuery: IQueryParameters<ITaxSource> = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: taxAtSource,
    };
    const { isSuccess, data } = await onboardingApi.putTaxAtSource(
      consultantId,
      dataQuery,
    );
    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'taxAtSource',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveTaxAtSourceAsEmployee = createAsyncThunk(
  'onboarding/taxAtSource',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { taxAtSource, consultantId, tabStatus } = (getState() as IRootState)
      .onboarding;

    const dataQuery: IQueryParameters<ITaxSource> = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.optional,
      data: taxAtSource,
    };
    const { isSuccess, data } = await onboardingApi.putTaxAtSource(
      consultantId,
      dataQuery,
    );
    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'taxAtSource',
          fieldValue: data,
        }),
      );
    }
  },
);

export const confirmLaterTaxAtSource = createAsyncThunk(
  'onboarding/confirmLaterTaxAtSourceEmployee',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { moveToNextPage, redirectRootRout } = options;
    const { taxAtSource, tabStatus } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<ITaxSource> = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: taxAtSource,
    };
    const { isSuccess, data } = await onboardingApi.putTaxAtSourceAsEmployee(
      dataQuery,
    );
    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'taxAtSource',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveTaxAtSourceById = createAsyncThunk(
  'onboarding/taxAtSource',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { taxAtSource, consultantId, tabStatus } = (getState() as IRootState)
      .onboarding;

    const dataQuery: IQueryParameters<ITaxSource> = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.optional,
      data: taxAtSource,
    };
    const { isSuccess, data } = await onboardingApi.putTaxAtSource(
      consultantId,
      dataQuery,
    );
    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'taxAtSource',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveTaxAtSource = createAsyncThunk(
  'onboarding/taxAtSourceEmployee',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { taxAtSource, tabStatus } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<ITaxSource> = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.optional,
      data: taxAtSource,
    };
    const { isSuccess, data } = await onboardingApi.putTaxAtSourceAsEmployee(
      dataQuery,
    );
    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'taxAtSource',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftTaxAtSourceAsConsultant = createAsyncThunk(
  'onboarding/saveDraftTaxAtSourceAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { taxAtSource, tabStatus } = (getState() as IRootState).onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const isDraft =
      tabStatus[7] === OnboardingRequestStatuses.empty ||
      tabStatus[7] === OnboardingRequestStatuses.draft;

    const dataQuery: any = {
      key: OnboardingRequestKeys.taxAtSource,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: taxAtSource,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putTaxAtSourceAsEmployee(
        dataQuery,
      );
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 8, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(
          setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'taxAtSource',
            fieldValue: data,
          }),
        );
      }
    }
  },
);
