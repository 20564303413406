import { LeadStatuses } from '../enums/Statuses';

export const leadStatusesArray = [
  LeadStatuses.new,
  LeadStatuses.duplicated,
  LeadStatuses.invited,
  LeadStatuses.confirmed,
  LeadStatuses.failed,
  LeadStatuses.archived,
];
