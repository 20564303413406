import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const MagnifierIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="6.18919"
        cy="6.35984"
        rx="5.18919"
        ry="5.18919"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75684 10.2246L13.0001 13.4679"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MagnifierIcon;
