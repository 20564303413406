import axios from 'axios';
import { ICountryCodeResponse } from './types';

class CountryCodeApi {
  getAllCountryCode(): Promise<string[]> {
    return axios
      .get<ICountryCodeResponse[]>('/countryCode/countryMenu')
      .then((res) => {
        return res.data.map((code) => code.code.toUpperCase()).sort();
      })
      .catch((res) => {
        console.error(res);
        return [];
      });
  }
}

export const countryCodeApi = new CountryCodeApi();
