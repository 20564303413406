import { Key, useEffect, useState } from 'react';
import { IWorkPermits } from '../../redux/reducers/work-permit/type';
import { reportingApi } from '../../common/api/services/ReportingApi/reportingApi';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ISort, TSortValue } from '../../redux/types';
import {
  changeWorkPermitFiltering,
  clearWorkPermitFiltersAndSorts,
  selectWorkPermitItem,
  setWorkPermitList,
  setWorkPermitPagination,
  setWorkPermitSorting,
} from '../../redux/reducers/work-permit/sliceReducer';
import { openErrorNotification } from '../../components/notification/Notification';
import { Spin, TableProps } from 'antd';
import CommonTable from '../../components/common-table/CommonTable';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import Button from '../../components/button/Button';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { OnboardingRequestStatuses } from '../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import moment from '../../common/constants/moment';
import {
  commonDateDots,
  commonDateFormat,
} from '../../common/constants/dateConstants';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import {
  documentTypes,
  permitTypes,
} from '../../common/constants/dropdownTypes';
import ExitIcon from '../../assets/icons/ExitIcon';
import { downloadExel } from '../../common/api/utils/downloadExel';
import { ROUTER_PATHS } from '../../navigation/routerPaths';

//TODO: helper
const { draft, published, toConfirm, empty, toVerify, archived } =
  OnboardingRequestStatuses;

type TStatusParserKey =
  | OnboardingRequestStatuses.draft
  | OnboardingRequestStatuses.published
  | OnboardingRequestStatuses.toConfirm
  | OnboardingRequestStatuses.empty
  | OnboardingRequestStatuses.toVerify
  | OnboardingRequestStatuses.archived;

const statusParser: Record<TStatusParserKey, string> = {
  [draft]: 'DRAFT',
  [empty]: 'DRAFT',
  [published]: 'PUBLISHED',
  [toConfirm]: 'TO CONFIRM',
  [toVerify]: 'TO VERIFY',
  [archived]: 'ARCHIVED',
};

const { onboarding, personalInformation } = ROUTER_PATHS;

const filterNames = [draft, published, toConfirm, toVerify, archived];

const WorkPermitPage = () => {
  const dispatch: any = useAppDispatch();
  const { workPermitList, selectedWorkPermits } = useAppSelector(
    (state) => state.workPermit,
  );
  const { pagination, sort, filter } = workPermitList;
  const { currentPage, perPage, totalElement } = pagination;

  const [isLoading, setIsLoading] = useState(false);
  const [workPermitData, setWorkPermitData] = useState<IWorkPermits>([]);

  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  useEffect(() => {
    getWorkPermit();
  }, [currentPage, perPage, filter]);

  const getWorkPermit = (currentSort?: ISort, currentFilter?: any) => {
    setIsLoading(true);
    reportingApi
      .getWorkPermitList(
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      .then((resp: any) => {
        dispatch(setWorkPermitList(resp));
        setWorkPermitData(resp?.result || []);
      })
      .catch((e) => openErrorNotification('Failed to get work permit list'))
      .finally(() => setIsLoading(false));
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectWorkPermitItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedWorkPermits,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeWorkPermitFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setWorkPermitSorting(newSort));
      getWorkPermit({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setWorkPermitPagination({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const exportToExcel = () => {
    reportingApi
      .exportToExelWorkPermit(filter, sort, selectedWorkPermits)
      .then((response: any) => {
        downloadExel(
          response,
          `Work_permit_reporting_${moment().format(commonDateDots)}`,
        );
      })
      .catch((e) => openErrorNotification('Failed to download excel'));
  };

  const onDoubleClick = (record: any) => {
    if (record._id) {
      window.open(
        `${onboarding}/${record._id}${personalInformation}`,
        '_blank',
      );
    }
  };

  const data = workPermitData.map((item) => {
    const { _id, id, firstName, lastName } = item;
    const startDate = item?.startDate;
    const endDate = item?.endDate;
    return {
      _id,
      id,
      firstName,
      lastName,
      documentType: item?.documentType,
      permitType: item?.permitType,
      startDate: startDate
        ? moment(startDate).format(commonDateFormat)
        : 'No data',
      endDate: endDate ? moment(endDate).format(commonDateFormat) : 'No data',
      processOngoing: item?.processOngoing || 'No data',
      proof: item?.proof || 'No data',
      comment: item?.comment,
      onboardingStatus: item?.onboardingStatus,
      email: item?.email,
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.id as string) ?? '',
        searchAsString: false,
      })('id'),
      className: sortClassName('id'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.firstName as string) ?? '',
        searchAsString: false,
      })('firstName'),
      className: sortClassName('firstName'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.lastName as string) ?? '',
        searchAsString: false,
      })('lastName'),
      className: sortClassName('lastName'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Document type',
      dataIndex: 'documentType',
      key: 'documentType',
      className: sortClassName('documentType'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.documentType as string[]) ?? '',
        fieldFilterName: 'documentType',
      })([...documentTypes, 'No data']),
    },
    {
      title: 'Permit type',
      dataIndex: 'permitType',
      key: 'permitType',
      className: sortClassName('permitType'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.permitType as string[]) ?? [],
        fieldFilterName: 'permitType',
      })([...permitTypes, 'CH passport', 'No data']),
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      className: sortClassName('startDate'),
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'startDate',
        values: (filter?.startDate as string) ?? '',
      })(),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      className: sortClassName('endDate'),
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'endDate',
        values: (filter?.endDate as string) ?? '',
      })(),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Process ongoing',
      dataIndex: 'processOngoing',
      key: 'processOngoing',
      className: sortClassName('processOngoing'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.processOngoing as string[]) ?? '',
        fieldFilterName: 'processOngoing',
      })(['Yes', 'No', 'No data']),
    },
    {
      title: 'Proof',
      dataIndex: 'proof',
      key: 'proof',
      className: sortClassName('proof'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.proof as string[]) ?? '',
        fieldFilterName: 'proof',
      })(['Yes', 'No', 'No data', 'N/A']),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      className: sortClassName('comment'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'onboardingStatus',
      key: 'onboardingStatus',
      className: `status-td ${sortClassName('onboardingStatus')}`,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.onboardingStatus as string[]) ?? [],
        fieldFilterName: 'onboardingStatus',
      })(filterNames),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: TStatusParserKey) => {
        const className = empty === text ? draft : text;
        return (
          <span
            className={
              className === 'archived' ? 'archivedConsultant' : className
            }>
            {statusParser[text]}
          </span>
        );
      },
    },
    {
      title: "Consultant's email",
      dataIndex: 'email',
      key: 'email',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.email as string) ?? '',
        searchAsString: false,
      })('email'),
      className: sortClassName('email'),
      sorter: true,
      width: 280,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
  ];

  return (
    <div style={{ height: '90%' }}>
      <div className="header-section">
        <Breadcrumb />
        <div className="right-header-section">
          {(isHasFilters || selectedWorkPermits.length > 0) && (
            <button
              className="rest-filters-button"
              onClick={() => {
                onSelectChange([]);
                dispatch(clearWorkPermitFiltersAndSorts());
                getWorkPermit({ sort: '', sortBy: '' }, []);
              }}>
              <ResetFilterIcon />
            </button>
          )}
          <Button
            onClick={() => exportToExcel()}
            buttonStyle="secondary"
            buttonSize="medium"
            buttonWidth={200}
            leftIcon={<ExitIcon className="export-icon" />}>
            Export to Excel
          </Button>
        </div>
      </div>
      <div className="container-payslips">
        <Spin spinning={isLoading}>
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            rowSelection={rowSelection}
            onChange={onChangeSort}
            rowKey="_id"
            onDoubleClick={onDoubleClick}
          />
        </Spin>
      </div>
    </div>
  );
};

export default WorkPermitPage;
