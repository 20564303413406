import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const EditActionIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 15 4"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.50019 4.32593e-08C7.28029 3.86252e-08 7.06254 0.0433131 6.85937 0.127467C6.65621 0.21162 6.47161 0.334966 6.31611 0.490461C6.16062 0.645956 6.03727 0.830555 5.95312 1.03372C5.86897 1.23688 5.82565 1.45463 5.82565 1.67454C5.82565 1.89444 5.86897 2.11219 5.95312 2.31536C6.03727 2.51852 6.16062 2.70312 6.31611 2.85861C6.47161 3.01411 6.65621 3.13745 6.85937 3.22161C7.06254 3.30576 7.28029 3.34907 7.50019 3.34907C7.9443 3.34897 8.37019 3.17245 8.68416 2.85835C8.99812 2.54424 9.17445 2.11827 9.17435 1.67416C9.17425 1.23004 8.99772 0.804157 8.68362 0.490192C8.36951 0.176226 7.94354 -0.000100833 7.49943 4.32593e-08H7.50019ZM2.17454 4.32593e-08C1.95463 3.86252e-08 1.73688 0.0433131 1.53372 0.127467C1.33056 0.21162 1.14596 0.334966 0.990461 0.490461C0.834965 0.645956 0.71162 0.830555 0.627467 1.03372C0.543313 1.23688 0.5 1.45463 0.5 1.67454C0.5 1.89444 0.543313 2.11219 0.627467 2.31536C0.71162 2.51852 0.834965 2.70312 0.990461 2.85861C1.14596 3.01411 1.33056 3.13745 1.53372 3.22161C1.73688 3.30576 1.95463 3.34907 2.17454 3.34907C2.61865 3.34897 3.04454 3.17245 3.3585 2.85835C3.67247 2.54424 3.84879 2.11827 3.84869 1.67416C3.84859 1.23004 3.67207 0.804157 3.35796 0.490192C3.04386 0.176226 2.61789 -0.000100833 2.17378 4.32593e-08H2.17454ZM12.8258 4.32593e-08C12.6059 3.86252e-08 12.3882 0.0433131 12.185 0.127467C11.9819 0.21162 11.7973 0.334966 11.6418 0.490461C11.4863 0.645956 11.3629 0.830555 11.2788 1.03372C11.1946 1.23688 11.1513 1.45463 11.1513 1.67454C11.1513 1.89444 11.1946 2.11219 11.2788 2.31536C11.3629 2.51852 11.4863 2.70312 11.6418 2.85861C11.7973 3.01411 11.9819 3.13745 12.185 3.22161C12.3882 3.30576 12.6059 3.34907 12.8258 3.34907C13.27 3.34897 13.6958 3.17245 14.0098 2.85835C14.3238 2.54424 14.5001 2.11827 14.5 1.67416C14.4999 1.23004 14.3234 0.804157 14.0093 0.490192C13.6952 0.176226 13.2692 -0.000100833 12.8251 4.32593e-08H12.8258Z" />
    </svg>
  );
};

export default EditActionIcon;
