import './ModifyClientPage.less';
import { RadioChangeEvent, Row } from 'antd';
import { ChangeEvent, FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchCountryCodeOptions } from '../../redux/reducers/clients/asyncThunkActions';
import {
  changeSelectedClient,
  clearSelectedClient,
  resetShippingDetails,
  setIsUpdateSelectedClient,
  updateAddress,
  updateClientContacts,
  updateEmailEntity,
  updateInvoiceSection,
  updatePhoneEntity,
  updateShippingDetails,
  updateShippingMethod,
} from '../../redux/reducers/clients/sliceReducer';
import { TSelectedClientInvoiceNames } from '../../redux/reducers/clients/type';
import GeneralInformation from './components/GeneralInformation';
import InvoiceInformation from './components/InvoiceInformation';
import {
  IEventForAddressAndInvoiceSection,
  IEventsForPhoneAndEmail,
} from './types';
import DeleteInfoModal from './components/DeleteInfoModal';

interface IProps
  extends IEventsForPhoneAndEmail,
    IEventForAddressAndInvoiceSection {}

const ModifyClientPage: FC<IProps> = (props) => {
  const {
    onAddEmailEntity,
    onAddPhoneEntity,
    onDeleteEmailEntity,
    onDeletePhoneEntity,
    onAddNewInvoiceSection,
    onDeleteInvoiceSection,
    onAddNewAddress,
    onDeleteAddress,
  } = props;
  const dispatch = useAppDispatch();
  const {
    selectedClient,
    countryCodeOptions,
    errorMessages,
    isOpenDeleteModal,
    deleteInfo,
  } = useAppSelector((state) => state.clients);

  const {
    name,
    addresses,
    notes,
    vat,
    countryCode,
    isFactoring,
    isRegisteredAtCreditSuisse,
    emails,
    phones,
    signatories,
    invoiceries,
    reminders,
    paymentTerm,
    paymentTermRange,
    shippings,
    clientContacts,
    files,
    mergeInvoiceFiles,
  } = selectedClient;

  useEffect(() => {
    dispatch(fetchCountryCodeOptions());

    return () => {
      dispatch(clearSelectedClient());
    };
  }, []);

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => {
    const { name, value } = event.target;
    dispatch(changeSelectedClient({ [name as string]: value }));
    dispatch(setIsUpdateSelectedClient(true));
  };

  const onChangeDropdown = (name: string) => (value: string) => {
    dispatch(changeSelectedClient({ [name]: value }));
    dispatch(setIsUpdateSelectedClient(true));
  };

  const onChangeAddress =
    (idx: number) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const extraName = event.target.name;
      const value = event.target.value;
      dispatch(updateAddress({ index: idx, value, extraName }));
    };

  const onSelectStreet =
    (idx: number, postcode: string, street: string, city: string) =>
    (): void => {
      const postCodeInfo = {
        index: idx,
        value: postcode,
        extraName: 'postCode',
      };
      const streetInfo = { index: idx, value: street, extraName: 'street' };
      const cityInfo = { index: idx, value: city, extraName: 'city' };
      dispatch(updateAddress(postCodeInfo));
      dispatch(updateAddress(streetInfo));
      dispatch(updateAddress(cityInfo));
    };

  const onChangeDropdownAddress =
    (idx: number, extraName: string) => (value: any) => {
      dispatch(updateAddress({ index: idx, value, extraName }));
    };

  const onChangeInvoiceSection =
    (idx: number, name: TSelectedClientInvoiceNames) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      const extraName = event.target.name;
      const payload = { name, index: idx, value, extraName };
      dispatch(updateInvoiceSection(payload));
    };

  const onChangeArrayElement =
    (idx: number) =>
    (event: ChangeEvent<HTMLInputElement> | string): void => {
      if (typeof event === 'string') {
        dispatch(updatePhoneEntity({ index: idx, value: event }));
        return;
      }
      const value = event.target.value;
      dispatch(updateEmailEntity({ index: idx, value }));
    };

  const onChangeClientContacts =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement> | string): void => {
      if (typeof event === 'string') {
        dispatch(
          updateClientContacts({ index, extraName: 'phone', value: event }),
        );
        return;
      }
      const extraName = event.target.name;
      const value = event.target.value;
      dispatch(updateClientContacts({ index, extraName, value }));
    };

  const onChangeShippingDetails = (detail: string, method: string) => {
    dispatch(updateShippingDetails({ method, detail }));
  };

  const onChangeShippingMethod = (method: string) => {
    dispatch(updateShippingMethod(method));
    dispatch(resetShippingDetails());
  };
  const getError = (fileName: string): string => {
    return errorMessages[fileName];
  };

  return (
    <div className="modify-client-page">
      <Row gutter={[32, 0]}>
        <GeneralInformation
          {...{
            onChangeArrayElement,
            onAddEmailEntity,
            onAddPhoneEntity,
            onDeleteEmailEntity,
            onDeletePhoneEntity,
            countryCodeOptions,
            onChangeDropdown,
            onChange,
            onChangeShippingDetails,
            onChangeShippingMethod,
            getError,
          }}
          values={{
            name,
            notes,
            vat,
            countryCode,
            isFactoring,
            isRegisteredAtCreditSuisse,
            emails,
            phones,
            paymentTerm,
            paymentTermRange,
            shippings,
            mergeInvoiceFiles,
          }}
        />

        <InvoiceInformation
          {...{
            onAddNewAddress,
            onDeleteAddress,
            onChangeAddress,
            onChangeDropdownAddress,
            onChangeInvoiceSection,
            onAddNewInvoiceSection,
            onDeleteInvoiceSection,
            onSelectStreet,
            getError,
            onChangeClientContacts,
          }}
          values={{
            signatories,
            invoiceries,
            reminders,
            addresses,
            clientContacts,
            files,
          }}
        />
        {isOpenDeleteModal && (
          <DeleteInfoModal isOpen={isOpenDeleteModal} data={deleteInfo} />
        )}
      </Row>
    </div>
  );
};
export default ModifyClientPage;
