import { ExpensesStatus } from '../../ConsultantApi/enums/ResponseEnums';

export enum TimesheetStatus {
  DRAFT = 'Draft',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  PRE_APPROVED = 'Pre-approved',
  APPROVED = 'Approved',
  NO_ACTIVITY = 'No activity',
  EXPIRED = 'Expired',
  REJECTED = 'Rejected',
  CLIENT_APPROVAL = 'Client Approval',
}
export const TimesheetsStatusesArray = [
  TimesheetStatus.APPROVED,
  TimesheetStatus.CLIENT_APPROVAL,
  TimesheetStatus.DRAFT,
  TimesheetStatus.EXPIRED,
  TimesheetStatus.NO_ACTIVITY,
  TimesheetStatus.PRE_APPROVED,
  TimesheetStatus.REJECTED,
  TimesheetStatus.WAITING_FOR_APPROVAL,
];

export const MainActivityArray = [
  TimesheetStatus.APPROVED,
  TimesheetStatus.CLIENT_APPROVAL,
  TimesheetStatus.DRAFT,
  TimesheetStatus.EXPIRED,
  TimesheetStatus.NO_ACTIVITY,
  TimesheetStatus.PRE_APPROVED,
  TimesheetStatus.REJECTED,
  TimesheetStatus.WAITING_FOR_APPROVAL,
  ExpensesStatus.NO_EXPENSES,
];
export enum TimesheetStatusValues {
  'Approved',
  'Client Approval',
  'Pre-approved',
  'Waiting for approval',
  'Draft',
  'Rejected',
  'No activity',
  'Expired',
  'No expenses',
}

export const numberOfTimesheetStatus: Record<string, number> = {
  ['Approved']: 0,
  ['Client Approval']: 1,
  ['Pre-approved']: 2,
  ['Waiting for approval']: 3,
  ['Draft']: 4,
  ['Rejected']: 5,
  ['No activity']: 6,
  ['Expired']: 7,
  ['No expenses']: 8,
};
