import { ColumnsType } from 'antd/lib/table';
import CommonTable from '../../../../../components/common-table/CommonTable';
import {
  IData,
  IInvoice,
  InvoiseStatuses,
} from '../../../../../common/api/services/InvoiceApi/types/types';
import moment from '../../../../../common/constants/moment';
import { commonDTOPeriod } from '../../../../../common/constants/dateConstants';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import { invoiceApi } from '../../../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../../../components/notification/Notification';
import { useState } from 'react';
import AttentionModal from '../../../../../components/attention-modal/AttentionModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormInstance } from 'antd';
import Input from '../../../../../components/input/Input';
import { getError } from '../../../../../common/api/utils/getError';
import { ROUTER_PATHS } from '../../../../../navigation/routerPaths';
import { formatAmount } from '../../../../../common/utils/formatAmount';
import DescriptionModal from '../../DescriptionModal';
import EditIcon from '../../../../../assets/icons/EditIcon';

const { activityReport } = ROUTER_PATHS;

interface IProps {
  invoice: IInvoice | null;
  errorMessages: any;
  form: FormInstance<any>;
  setInvoice: any;
  saveAsDraft: () => void;
}
const TimesheetExpenseTable = (props: IProps) => {
  const { invoice, errorMessages, form, setInvoice, saveAsDraft } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const isDeposit = location.pathname.includes('deposit');
  const [selectedItem, setSelectedItem] = useState<IInvoice | null>(null);
  //TODO
  const [selectedDescription, setSelectedDescription] = useState<any | null>(
    null,
  );
  const vat = form.getFieldValue('vat');
  const isDraft = invoice?.status === InvoiseStatuses.DRAFT;

  const onDeleteItem = () => {
    if (invoice && selectedItem) {
      invoiceApi
        .deleteInvoiceItem(invoice?._id, selectedItem?._id)
        .then((resp) => {
          openSuccessNotification('Item was deleted successfully');
          if (resp) {
            setInvoice((prev: any) => ({
              ...prev,
              items: resp.items,
              totalCalculations: resp.totalCalculations,
            }));
          } else {
            navigate(activityReport);
          }
        })
        .catch((e) =>
          openErrorNotification(
            e?.response?.data?.message || 'Failed to delete invoice item',
          ),
        )
        .finally(() => setSelectedItem(null));
    }
  };

  const onCloseDescription = (items?: Array<IData>) => {
    setSelectedDescription(null);
    if (items) {
      setInvoice((prevState: any) => ({ ...prevState, items }));
    }
  };

  const data = invoice?.items
    ? invoice.items.map((item: any) => {
        return {
          id: item.publicId,
          period: item.period
            ? moment(item.period).format(commonDTOPeriod)
            : '',
          consultant: `${item.consultant?.firstName || ''} ${
            item.consultant?.lastName
          }`,
          contractType:
            item.itemKind === 'expenses' ? 'Expences' : item.contractType,
          time: item.time,
          quantity: item.quantity,
          billRate: item.billRateCurrency,
          total: formatAmount(item.total),
          _id: item._id,
          rate: formatAmount(item.rate) || 'No',
          description: item.description,
        };
      })
    : [];

  const columns: ColumnsType<any> = [
    {
      title: 'Timesheet/Expenses #',
      dataIndex: 'id',
      key: 'id',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 180,
      render: (text: string, data) => {
        if (text) {
          return (
            <div
              className="edit-description-generate"
              onClick={() => setSelectedDescription(data)}>
              <span>{text.length > 13 ? `${text.slice(0, 13)}...` : text}</span>
              <EditIcon />
            </div>
          );
        }
        return (
          <div
            className="edit-description-generate"
            onClick={() => setSelectedDescription(data)}>
            <span style={{ color: '#0772e0' }}>+ add a description</span>
            <EditIcon />
          </div>
        );
      },
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      width: 150,
    },
    {
      title: 'Consultant',
      dataIndex: 'consultant',
      key: 'consultant',
      width: 150,
    },
    {
      title: 'Contract type',
      dataIndex: 'contractType',
      key: 'contractType',
      width: 150,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: 100,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
    },
    {
      title: 'Bill rate currency',
      dataIndex: 'billRate',
      key: 'billRate',
      width: 150,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 150,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (item, data) => {
        return (
          <div>
            <TrashIcon onClick={() => setSelectedItem(data)} />
          </div>
        );
      },
    },
  ];

  const updatedColumns =
    invoice?.layout === 'Simplified'
      ? [...columns]
      : [...columns.slice(0, 1), ...columns.slice(2)];

  return (
    <div className={`${isDeposit ? 'generate-invoice-footer' : ''}`}>
      {!isDeposit && <h4>Timesheets/Expenses</h4>}
      {!isDeposit && (
        <div style={{ marginTop: '20px' }}>
          <CommonTable
            paginationCurrentPage={1}
            paginationPageSize={10}
            paginationOnChange={() => null}
            paginationTotalElement={10}
            data={data}
            columns={updatedColumns}
            onChange={() => null}
            hidePagination
            tableHeight={200}
            height={200}
          />
        </div>
      )}
      <div style={{ marginTop: '4rem' }}>
        {isDeposit && (
          <div className="general-finance-info-row-end general-amount">
            <Form.Item name="amountDeposit">
              <Input
                label="Amount"
                onBlur={saveAsDraft}
                errorMessage={getError('amountDeposit', errorMessages)}
                disabled={!isDraft}
                inputType="number"
              />
            </Form.Item>
          </div>
        )}
        {vat && (
          <div className="general-finance-info-row-end">
            <div className="calculations-container">
              <span className="general-information-title">Total:</span>
              <span className="row-middle-finance general-information-title">
                {invoice?.totalCalculations?.currency || ''}
              </span>
              <span className="general-information-title">
                {formatAmount(invoice?.totalCalculations?.total) || 0}
              </span>
            </div>
          </div>
        )}
        {vat && (
          <div className="general-finance-info-row-end">
            <div className="calculations-container">
              <span className="general-information-title">
                VAT ( {invoice?.vat?.percentage || 0}%):
              </span>
              <span className="row-middle-finance general-information-title">
                {invoice?.totalCalculations?.currency || ''}
              </span>
              <span className="general-information-title">
                {formatAmount(invoice?.totalCalculations?.vat) || 0}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="general-finance-info-row">
        <div className="general-finance-currency">
          {invoice?.fxRates?.length
            ? invoice?.fxRates.map((item) => (
                <div className="finance-currency-container" key={item._id}>
                  <span className="general-information-title">
                    {item.currencyPair}
                  </span>
                  <span>{item.rate}</span>
                </div>
              ))
            : null}
        </div>
        {vat && (
          <div className="calculations-container">
            <span className="general-information-title">Total with VAT:</span>
            <span className="row-middle-finance general-information-title">
              {invoice?.totalCalculations?.currency || 0}
            </span>
            <span className="general-information-title">
              {formatAmount(invoice?.totalCalculations?.totalWithVAT) || 0}
            </span>
          </div>
        )}
      </div>
      {selectedItem && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={() => setSelectedItem(null)}
          confirmAction={onDeleteItem}
          confirmBtnTitle="Yes, delete"
          content={
            invoice?.items?.length === 1 ? (
              <span>
                Are you sure you want to delete it? The invoice will be deleted
                as well as no documents will be added to the invoice
              </span>
            ) : (
              <span>
                Are you sure you want to delete this entity? If you delete it
                the Timesheet/Expenses cannot be added back
              </span>
            )
          }
          isOpen={!!selectedItem}
          isHideAttentionText
          hideCloseBtn
        />
      )}
      {selectedDescription && (
        <DescriptionModal
          isOpen={!!selectedDescription}
          onClose={onCloseDescription}
          selectedDescription={selectedDescription}
          invoiceId={invoice?._id}
        />
      )}
    </div>
  );
};

export default TimesheetExpenseTable;
