import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUploadFileResponse } from '../../../common/api/services/FileApi/types/IUploadFileResponse';

import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination, TSortValue } from '../../types';
import { IConsultantExpenses } from '../../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import {
  ExpensesFormType,
  ExpensesStatus,
  ExpensesType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';

const initialState: IState = {
  isOpenModalAddExpense: false,
  isOpenModalEditExpense: false,
  isOpenModalViewExpense: false,
  isUpLoading: false,
  isLoading: false,
  expensesList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  selectedExpense: {
    _id: '',
    id: '',
    clientApprovalDate: '',
    consultantId: '',
    timesheetId: '',
    hasExpensesInThisMonth: true,
    isSigned: false,
    approvalRequired: false,
    formType: ExpensesFormType.SIMPLIFIED,
    type: ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
    period: '',
    companyRepresentativeEmail: '',
    representativeFirstName: '',
    clientFiles: [],
    representativeLastName: '',
    workContract: null,
    clientContract: null,
    documents: [],
    comments: '',
    numeriqComments: '',
    total: 0,
    status: ExpensesStatus.DRAFT,
    createdAt: '',
    updatedAt: '',
    visibility: 'public',
    consultant: null,
  },
  errorMessages: null,
};

const reducer = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setExpensesList(
      state,
      action: PayloadAction<IGetListDto<IConsultantExpenses>>,
    ) {
      state.expensesList.content = action.payload.result;
      state.expensesList.pagination.totalElement = action.payload.total;
    },
    changeExpensesFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.expensesList.filter = {
        ...state.expensesList.filter,
        ...action.payload,
      };
    },
    setExpensesSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.expensesList.sort.sortBy = action.payload[0];
      state.expensesList.sort.sort = action.payload[1];
    },
    clearExpensesFiltersAndSorts(state) {
      state.expensesList.sort = initialState.expensesList.sort;
      state.expensesList.filter = initialState.expensesList.filter;
    },
    setExpensesPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.expensesList.pagination.currentPage = action.payload.currentPage;
      state.expensesList.pagination.perPage = action.payload.perPage;
    },
    clearSelectedExpense(state) {
      state.selectedExpense = initialState.selectedExpense;
      state.errorMessages = null;
    },
    setErrorMessages(
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) {
      state.errorMessages = action.payload;
    },
    updateOpenModalAddExpense(state, action: PayloadAction<boolean>) {
      state.isOpenModalAddExpense = action.payload;
    },
    updateOpenModalEditExpense(state, action: PayloadAction<boolean>) {
      state.isOpenModalEditExpense = action.payload;
    },
    updateOpenModalViewExpense(state, action: PayloadAction<boolean>) {
      state.isOpenModalViewExpense = action.payload;
    },
    updateIsUpLoadingExpense(state, action: PayloadAction<boolean>) {
      state.isUpLoading = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSelectedExpense(
      state,
      action: PayloadAction<Record<string, string | boolean | null | number>>,
    ) {
      state.selectedExpense = {
        ...state.selectedExpense,
        ...action.payload,
      };
    },
    updateSelectedExpenseCheckbox(
      state,
      action: PayloadAction<'isSigned' | 'approvalRequired'>,
    ) {
      const { payload } = action;
      const { [payload]: value } = state.selectedExpense;
      state.selectedExpense[payload] = !value;
    },
    resetFilesSelectedExpense(state) {
      state.selectedExpense.documents = [];
    },
    updateFilesSelectedExpense(
      state,
      action: PayloadAction<IUploadFileResponse[]>,
    ) {
      state.selectedExpense.documents = [
        ...state.selectedExpense.documents,
        ...action.payload,
      ];
    },
    removeFileSelectedExpense(state, action: PayloadAction<string>) {
      state.selectedExpense.documents = state.selectedExpense.documents.filter(
        (el) => el.originalFilename !== action.payload,
      );
    },
    updateByIdSelectedExpense(state, action: PayloadAction<string>) {
      const selectedExpense = state.expensesList.content.find(
        (item: IConsultantExpenses) => item._id === action.payload,
      );

      if (!selectedExpense) return;

      state.selectedExpense = selectedExpense;

      if (
        state.selectedExpense.status === ExpensesStatus.DRAFT &&
        state.selectedExpense.type === ExpensesType.EXPENSES_FOR_OPTIMISATION
      ) {
        state.selectedExpense.approvalRequired = true;
      }
    },
  },
});

export const {
  clearSelectedExpense,
  setErrorMessages,
  updateOpenModalAddExpense,
  updateOpenModalEditExpense,
  updateOpenModalViewExpense,
  updateIsUpLoadingExpense,
  updateSelectedExpense,
  updateSelectedExpenseCheckbox,
  resetFilesSelectedExpense,
  updateFilesSelectedExpense,
  removeFileSelectedExpense,
  updateByIdSelectedExpense,
  setExpensesList,
  changeExpensesFiltering,
  setExpensesSorting,
  clearExpensesFiltersAndSorts,
  setExpensesPagination,
  setIsLoading,
} = reducer.actions;

export default reducer.reducer;
