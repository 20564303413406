import ExitIcon from '../../../assets/icons/ExitIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import {
  exportToExelMonitoring,
  getMonitoring,
} from '../../../redux/reducers/monitoring/actionAsyncThunk';
import { clearMonitoringFiltersAndSorts } from '../../../redux/reducers/monitoring/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const MonitoringExtraSection = () => {
  const dispatch = useAppDispatch();

  const {
    sort: monitoringSorts,
    filter: monitoringFilters,
    selectedElement,
  } = useAppSelector((state) => state.monitoring.monitoringList);

  const isResetMonitoringFilters =
    monitoringSorts.sortBy !== '' ||
    Object.values(monitoringFilters).some((el) => el.length !== 0) ||
    selectedElement.length;

  return (
    <>
      {isResetMonitoringFilters ? (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearMonitoringFiltersAndSorts());
            dispatch(getMonitoring({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      ) : null}
      <Button
        onClick={() => dispatch(exportToExelMonitoring())}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={200}
        leftIcon={<ExitIcon className="export-icon" />}>
        Export to Excel
      </Button>
    </>
  );
};

export default MonitoringExtraSection;
