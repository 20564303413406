import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const SaveIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.00021 4.33333C1.00021 2.49238 2.4926 1 4.33355 1H11.0002H13.9647C14.6277 1 15.2636 1.26339 15.7324 1.73223L20.268 6.26777C20.7368 6.73661 21.0002 7.37249 21.0002 8.03553V11V17.6667C21.0002 19.5076 19.5078 21 17.6669 21H4.33355C2.4926 21 1.00021 19.5076 1.00021 17.6667V4.33333Z"
        stroke="#0671E0"
        strokeWidth="1.8"
      />
      <path
        d="M4.99982 13.6667C4.99982 12.7462 5.74601 12 6.66648 12H15.3332C16.2536 12 16.9998 12.7462 16.9998 13.6667V21H4.99982V13.6667Z"
        stroke="#0671E0"
        strokeWidth="1.8"
      />
      <path
        d="M7.00003 1.00002V5.16668C7.00003 5.62692 7.37313 6.00002 7.83336 6.00002H14.1667C14.6269 6.00002 15 5.62692 15 5.16668V1.00002"
        stroke="#0671E0"
        strokeWidth="1.8"
      />
    </svg>
  );
};

export default SaveIcon;
