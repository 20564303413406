import { Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import useDebounce from '../../../common/hooks/useDebounce';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantsResponse } from '../../../common/api/services/ConsultantApi/types/IConsultantsResponse';
import ILabelInValue from '../../../common/types/ILabelInValue';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import { activityApi } from '../../../common/api/services/ActivityApi/activityApi';
import Input from '../../../components/input/Input';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';
import './../../internal-timesheet-page/InternalTimesheetPage.less';
import { IActivityReportResponse } from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';
import { compareByMonthYear } from '../../../common/utils/compareEndDate';
import { openErrorNotification } from '../../../components/notification/Notification';

interface IProps {
  clientId: string | null | undefined;
  clientName: string | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  consultantId: string;
  getActivityReport: (
    selectedConsultantId?: string | null,
    selectedProjectId?: string | null,
    selectedPeriod?: string | null,
    changeIndex?: boolean,
  ) => void;
  mainInfo: Array<IActivityReportResponse>;
  commonPeriod: string | null;
  activityReports: any;
}
const initDropdownValue = { label: '', value: '' };

const AddActivityReportModal = (props: IProps) => {
  const {
    clientId,
    isOpen,
    clientName,
    onClose,
    getActivityReport,
    mainInfo,
    activityReports,
  } = props;
  const [consultantsList, setConsultantsList] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchConsultant, setSearchConsultant] = useState<string>('');
  const [optionConsultants, setOptionConsultants] = useState<ILabelInValue[]>(
    [],
  );
  const [period, setPeriod] = useState<Date | null>(null);
  const [selectedConsultant, setSelectedConsultant] =
    useState<ILabelInValue>(initDropdownValue);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const searchConsultantDeb = useDebounce(searchConsultant, 500);

  useEffect(() => {
    const getData = async () => {
      if (!clientId || !period) {
        return;
      }
      setIsFetching(true);

      const response: any = await activityApi.getARConsultants(
        clientId,
        period,
      );

      const removedCurrentConsultant = response?.result.filter(
        (item: any) =>
          !mainInfo.find(
            (value: any) =>
              value.consultantId === item.consultantId &&
              compareByMonthYear(
                item.commonPeriod,
                value.commonPeriod as string,
              ) &&
              item?._id?.projectId === value?._id?.projectId,
          ),
      );

      const data = removedCurrentConsultant.map((item: any) => ({
        label: `${item.firstName || ''} ${item.lastName || ''} (${
          item.projectDescription || ''
        })`,
        value: `${item.consultantId},${item._id?.projectId},${item._id?.period}`,
      }));
      if (data) {
        setConsultantsList({ ...response, result: removedCurrentConsultant });
        setOptionConsultants(data);
      }
      setIsFetching(false);
    };

    getData();
  }, [searchConsultantDeb, period]);

  const onChangeDate = (value: Date | null) => {
    setSelectedConsultant(initDropdownValue);
    setPeriod(value);
  };

  const onSearchConsultant = (value: string) => {
    setSearchConsultant(value);
  };

  const onClear = () => {
    setSearchConsultant('');
  };

  const onChangeConsultant = (item: ILabelInValue) => {
    setSelectedConsultant({ value: item.value, label: item.label });
    const projectId = consultantsList
      ? consultantsList.result.find(
          (consultant: any) =>
            consultant.consultantId === item.value.split(',')[0],
        )._id.projectId
      : '';
    setSelectedProjectId(projectId);
  };

  const onCheckAR = () => {
    setIsFetching(true);
    activityApi
      .getARInfoMain([
        ...activityReports,
        {
          clientId,
          consultantId: selectedConsultant.value.split(',')[0],
          projectId: selectedProjectId,
          period,
        },
      ])
      .then((resp) => {
        getActivityReport(
          selectedConsultant.value.split(',')[0],
          selectedProjectId,
          period as any,
          true,
        );
      })
      .catch((e) => {
        if (e?.response?.data?.messages) {
          e?.response?.data?.messages.forEach((item: any) =>
            openErrorNotification(item.message),
          );
          return;
        }
        openErrorNotification(
          e?.response?.data?.message || 'No approval timesheets found.',
        );
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      destroyOnClose={true}
      onCancel={() => {
        if (isFetching) {
          return;
        }
        onClose();
      }}
      wrapClassName="modal-window-wrapper">
      <div>
        <div style={{ padding: '30px 0' }}>
          <h4>Add activity report</h4>
          <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Input
                name="client"
                label="Client"
                value={clientName || ''}
                disabled
                onChange={() => false}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: 24 }}>
            <Col span={24}>
              <CommonDatePicker
                picker="month"
                label="Period"
                defaultValue={''}
                changeDate={onChangeDate}
                className="date-picker-form period-form"
              />
            </Col>
          </Row>
          {period && (
            <Row style={{ paddingTop: 24 }}>
              <Col span={24}>
                <Dropdown
                  labelInValue={true}
                  isSearch={false}
                  options={optionConsultants}
                  value={selectedConsultant}
                  onChange={onChangeConsultant}
                  onSearch={onSearchConsultant}
                  onClear={onClear}
                  label="Consultant"
                  isFetching={isFetching}
                />
              </Col>
            </Row>
          )}
        </div>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              disable={isFetching}
              onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              buttonWidth="100%"
              disable={isFetching}
              onClick={() => onCheckAR()}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddActivityReportModal;
