export interface IEmployeePayslipResponse {
  consultant: {
    email: string;
    _id: string;
    publicId: 373;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  originalFilename: string;
  period: string;
  status: string;
  updatedAt: string;
  uploadDate: string;
  uploadedBy: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  _id: string;
}

export type IEmployeePayslipsResponse = Array<IEmployeePayslipResponse>;

export interface IViewPayslipResponse {
  consultant: {
    email: string;
    firstName: string;
    lastName: string;
    publicId: number;
    _id: string;
  };
  createdAt: string;
  originalFilename: string;
  period: string;
  paymentDate?: string;
  status: string;
  updatedAt: string;
  uploadDate: string;
  salaryNetPaid: number;
  uploadedBy: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  _id: string;
  paymentStatus: string;
  payslip: {
    consultantId: string;
    createdAt: string;
    iterationId: string;
    originalFilename: string;
    paymentStatus: string;
    period: string;
    status: string;
    type: string;
    updatedAt: string;
    uploadDate: string;
    userId: string;
    _id: string;
  };
  salarySimulation: {
    consultantId: string;
    createdAt: string;
    iterationId: string;
    originalFilename: string;
    paymentStatus: string;
    period: string;
    status: string;
    type: string;
    updatedAt: string;
    uploadDate: string;
    userId: string;
    _id: string;
  };
}

export type IViewPayslipsResponse = Array<IViewPayslipResponse>;

export interface IPublishPayslipsDTO {
  payslipsIds: Array<string>;
}
export interface IDeletePayslipsDTO {
  ids: Array<string>;
}
export interface IDeletePublishedPayslipsDTO {
  consultantId: string;
  ids: Array<string>;
}

export interface IConsultantInfo {
  email: string;
  firstName: string;
  lastName: string;
  publicId: number;
  _id: string;
}

export enum PaymentStatuses {
  TO_PAY = 'To pay',
  PARTIALLY_PAID = 'Partially paid',
  PAID = 'Paid',
}

export const PaymentStatusesArray = [
  PaymentStatuses.PAID,
  PaymentStatuses.PARTIALLY_PAID,
  PaymentStatuses.TO_PAY,
];

export interface IUploadResult {
  total: number;
  failed: number;
  succcess: number;
}
export interface IChangeSalaryNetPaid {
  salaryNetPaid: number;
}
export interface IChangePayslipPaymentStatus {
  paymentStatus: PaymentStatuses;
}
export interface IChangePayslipPaymentDate {
  paymentDate: Date;
}

export interface IUpdatePayslip {
  paymentDate?: Date;
  paymentStatus?: string;
  salaryNetPaid?: number;

  consultantId: string;
  period: string;
}
