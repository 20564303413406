import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { Space } from 'antd';
import Button from '../../components/button/Button';
import FilterIcon from '../../assets/icons/FilterIcon';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, string[]>) => void;
  defaultValues: string[];
  fieldFilterName: string;
}

export const useTableSelectFilter = ({
  fetchRequest,
  changeValues,
  defaultValues,
  fieldFilterName,
}: IArguments) => {
  const [selectedValue, setSelectedValue] = useState<string[]>(
    defaultValues ? defaultValues : [],
  );
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (defaultValues.length > 0) {
      setIsFiltered(true);
    }

    if (defaultValues.length === 0) {
      setIsFiltered(false);
      setSelectedValue([]);
    }
  }, [defaultValues]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (selectedValue.length > 0) {
      setIsFiltered(true);
      changeValues({ [fieldFilterName]: selectedValue });
      fetchRequest();
    } else {
      setIsFiltered(false);
      changeValues({ [fieldFilterName]: [] });
      fetchRequest();
    }
    confirm();
  };

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedValue((prevState) => [...prevState, e.target.value]);
    } else {
      setSelectedValue((prevState) =>
        prevState.filter((el) => el !== e.target.value),
      );
    }
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({ [fieldFilterName]: [] });
    fetchRequest();
    confirm();
    setSelectedValue([]);
    setIsFiltered(false);
  };

  const onSelectAll = (values: string[]) => {
    setSelectedValue(values);
    changeValues({ [fieldFilterName]: values });
  };

  return (dataIndex: string[]): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-select-filter-dropdown">
        <span
          onClick={() => onSelectAll(dataIndex)}
          className="select-all-filters">
          Select all
        </span>
        <div className="selected-checkbox-group">
          {dataIndex.map((item) => {
            return (
              <Checkbox
                name={item}
                key={item}
                value={item}
                onChange={onChange}
                checked={selectedValue.includes(item)}>
                {item[0].toUpperCase() + item.slice(1)}
              </Checkbox>
            );
          })}
        </div>

        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            onClick={() => onClear(confirm)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};
