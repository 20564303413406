import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const DownloadCloudIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6874 13.7949H11.2401V9.0625C11.2401 8.97656 11.1698 8.90625 11.0839 8.90625H9.912C9.82606 8.90625 9.75575 8.97656 9.75575 9.0625V13.7949H8.31239C8.18153 13.7949 8.10926 13.9453 8.18934 14.0469L10.3768 16.8145C10.3915 16.8331 10.4101 16.8482 10.4315 16.8586C10.4528 16.869 10.4762 16.8744 10.4999 16.8744C10.5236 16.8744 10.547 16.869 10.5683 16.8586C10.5896 16.8482 10.6083 16.8331 10.6229 16.8145L12.8104 14.0469C12.8905 13.9453 12.8182 13.7949 12.6874 13.7949Z" />
      <path d="M16.3477 7.16211C15.4531 4.80273 13.1738 3.125 10.5039 3.125C7.83398 3.125 5.55469 4.80078 4.66016 7.16016C2.98633 7.59961 1.75 9.125 1.75 10.9375C1.75 13.0957 3.49805 14.8438 5.6543 14.8438H6.4375C6.52344 14.8438 6.59375 14.7734 6.59375 14.6875V13.5156C6.59375 13.4297 6.52344 13.3594 6.4375 13.3594H5.6543C4.99609 13.3594 4.37695 13.0977 3.91602 12.623C3.45703 12.1504 3.21289 11.5137 3.23438 10.8535C3.25195 10.3379 3.42773 9.85352 3.74609 9.44531C4.07227 9.0293 4.5293 8.72656 5.03711 8.5918L5.77734 8.39844L6.04883 7.68359C6.2168 7.23828 6.45117 6.82227 6.74609 6.44531C7.03725 6.07169 7.38214 5.74326 7.76953 5.4707C8.57227 4.90625 9.51758 4.60742 10.5039 4.60742C11.4902 4.60742 12.4355 4.90625 13.2383 5.4707C13.627 5.74414 13.9707 6.07227 14.2617 6.44531C14.5566 6.82227 14.791 7.24023 14.959 7.68359L15.2285 8.39648L15.9668 8.5918C17.0254 8.87695 17.7656 9.83984 17.7656 10.9375C17.7656 11.584 17.5137 12.1934 17.0566 12.6504C16.8325 12.8758 16.5659 13.0546 16.2722 13.1763C15.9785 13.298 15.6636 13.3602 15.3457 13.3594H14.5625C14.4766 13.3594 14.4062 13.4297 14.4062 13.5156V14.6875C14.4062 14.7734 14.4766 14.8438 14.5625 14.8438H15.3457C17.502 14.8438 19.25 13.0957 19.25 10.9375C19.25 9.12695 18.0176 7.60352 16.3477 7.16211Z" />
    </svg>
  );
};

export default DownloadCloudIcon;
