import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const AngleWithBorderIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8337 7.49984L8.92291 9.41058C8.59748 9.73602 8.59748 10.2637 8.92291 10.5891L10.8337 12.4998M5.83366 18.3332H14.167C16.4682 18.3332 18.3337 16.4677 18.3337 14.1665V5.83317C18.3337 3.53198 16.4682 1.6665 14.167 1.6665H5.83366C3.53247 1.6665 1.66699 3.53198 1.66699 5.83317V14.1665C1.66699 16.4677 3.53247 18.3332 5.83366 18.3332Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AngleWithBorderIcon;
