import { useAppDispatch } from '../../redux/store';
import {
  addInvoiceSection,
  addNewAddress,
  addNewEmailEntity,
  addNewPhoneEntity,
  deleteAddress,
  deleteEmailEntity,
  deleteInvoiceSection,
  deletePhoneEntity,
} from '../../redux/reducers/clients/sliceReducer';
import { TSelectedClientInvoiceNames } from '../../redux/reducers/clients/type';
import {
  IEventForAddressAndInvoiceSection,
  IEventsForPhoneAndEmail,
} from '../../pages/modify-client-page/types';
import { ComponentType } from 'react';

interface IProps
  extends IEventsForPhoneAndEmail,
    IEventForAddressAndInvoiceSection {}
export const withCreateClient = (WrappedComponent: ComponentType<IProps>) => {
  const Component = () => {
    const dispatch = useAppDispatch();

    const onAddNewAddress = (): void => {
      dispatch(addNewAddress());
    };

    const onDeleteAddress = (idx: number | string) => (): void => {
      dispatch(deleteAddress(idx as number));
    };

    const onAddEmailEntity = (): void => {
      dispatch(addNewEmailEntity());
    };

    const onAddPhoneEntity = (): void => {
      dispatch(addNewPhoneEntity());
    };

    const onDeleteEmailEntity = (idx: number | string) => (): void => {
      dispatch(deleteEmailEntity(idx as number));
    };

    const onDeletePhoneEntity = (idx: number | string) => (): void => {
      dispatch(deletePhoneEntity(idx as number));
    };

    const onAddNewInvoiceSection =
      (name: TSelectedClientInvoiceNames) => (): void => {
        dispatch(addInvoiceSection(name));
      };

    const onDeleteInvoiceSection =
      (idx: number | string, name: TSelectedClientInvoiceNames) => (): void => {
        dispatch(deleteInvoiceSection({ name, index: idx as number }));
      };

    return (
      <WrappedComponent
        {...{
          onAddEmailEntity,
          onAddPhoneEntity,
          onDeleteEmailEntity,
          onDeletePhoneEntity,
          onAddNewInvoiceSection,
          onDeleteInvoiceSection,
          onAddNewAddress,
          onDeleteAddress,
        }}
      />
    );
  };

  return <Component />;
};
