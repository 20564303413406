import { Col, Form, FormInstance, Row } from 'antd';
import Input from '../../../../../components/input/Input';
import '../../../GenerateInvoicePage.less';
import Dropdown from '../../../../../components/dropdown/Dropdown';
import CommonDatePicker from '../../../../../components/date-picker/CommonDatePicker';
import {
  IInvoice,
  InvoiseStatuses,
} from '../../../../../common/api/services/InvoiceApi/types/types';
import { paymentTermsList } from '../../../../../common/constants/paymentTermsList';
import { getError } from '../../../../../common/api/utils/getError';
import moment from '../../../../../common/constants/moment';
import {
  commonDateMonthFormat,
  commonMonthDateYearSpaceFormat,
  commonYearDateFormat,
} from '../../../../../common/constants/dateConstants';

interface IProps {
  errorMessages: any;
  form: FormInstance<any>;
  onChangeInvoiceDate: (value: string) => void;
  invoice: IInvoice | null;
}

const PaymentInformation = (props: IProps) => {
  const { errorMessages, form, onChangeInvoiceDate, invoice } = props;
  const isDraft = invoice?.status === InvoiseStatuses.DRAFT;
  return (
    <div className="payment-info-container">
      <h4>Payment Information</h4>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={16}>
          <Form.Item name="paymentTerm">
            <Dropdown
              label="Payment term"
              options={paymentTermsList}
              errorMessage={getError('paymentTerm', errorMessages)}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="paymentTermRange">
            <Input
              label="№ of days for payment terms"
              errorMessage={getError('paymentTermRange', errorMessages)}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={12}>
          <Form.Item name="invoiceDate" className="date-picker-ant-form">
            <CommonDatePicker
              label="Invoice date"
              changeDate={(date) => {
                if (date) {
                  onChangeInvoiceDate(
                    moment(date).format(commonYearDateFormat),
                  );
                }
              }}
              disabled={!isDraft}
              defaultValue={form.getFieldValue('invoiceDate') || ''}
              className="date-picker-form"
              placeholder="Invoice date"
              errorMessage={getError('invoiceDate', errorMessages)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="payByDate" className="date-picker-ant-form">
            <CommonDatePicker
              label="Pay by date"
              changeDate={() => null}
              defaultValue={invoice?.payByDate || ''}
              className="date-picker-form"
              placeholder="Pay by date"
              disabled
              errorMessage={getError('payByDate', errorMessages)}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className="modifier-title">
        <span>Will be calculated automatically.</span>
      </div>
    </div>
  );
};

export default PaymentInformation;
