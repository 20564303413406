import { Col, Form, FormInstance, Row } from 'antd';
import Input from '../../../../../components/input/Input';
import Dropdown from '../../../../../components/dropdown/Dropdown';
import {
  IInvoice,
  InvoiceLayout,
  InvoiseStatuses,
} from '../../../../../common/api/services/InvoiceApi/types/types';
import { getError } from '../../../../../common/api/utils/getError';
import { IClient } from '../../../../../common/api/services/OnboardingApi/types/IClientContract';
import { useEffect, useState } from 'react';
import CommentsModal from '../../modals/CommentsModal';
import { useLocation } from 'react-router-dom';
import { IVATs } from '../../../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import ShippingMethodComponent from '../../ShippingMethodComponent';
import Textarea from '../../../../../components/textarea/Textarea';
import { invoiceApi } from '../../../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../../../components/notification/Notification';
import { IAddress } from '../../../../../common/api/services/OnboardingApi/types/IWorkContractRequest';

interface IProps {
  errorMessages: any;
  client: any;
  form: FormInstance<any>;
  onChangeLayout: (value: InvoiceLayout) => void;
  invoice: IInvoice | null;
  vats: IVATs;
  saveAsDraft: (isHideNotification?: boolean) => void;
  setInvoice: any;
}

const GeneralInformation = (props: IProps) => {
  const {
    errorMessages,
    client,
    form,
    onChangeLayout,
    invoice,
    vats,
    saveAsDraft,
    setInvoice,
  } = props;
  const location = useLocation();
  const isDeposit = location.pathname.includes('deposit');
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [addresses, setAddresses] = useState<IAddress[]>([]);

  const isDraft = invoice?.status === InvoiseStatuses.DRAFT;

  useEffect(() => {
    if (invoice?._id) {
      invoiceApi
        .getInvoiceAddresses(invoice._id)
        .then((resp: any) => {
          setAddresses(
            resp
              ? resp.map((address: IAddress) => {
                  return {
                    street: address.street,
                    postCode: address.postCode,
                    city: address.city,
                    country: address.country,
                    _id: address._id,
                    addressLines: address.addressLines,
                    vat: address.vat,
                  };
                })
              : [],
          );
        })
        .catch(() => {
          openErrorNotification('Failed to get addresses');
        });
    }
  }, [invoice?._id, invoice?.status]);

  // const addresses = client?.addresses
  //   ? client?.addresses
  //       // .filter(
  //       //   (item: any) =>
  //       //     item.street && item.postCode && item.city && item.country,
  //       // )
  //       .map((item: any) => {
  //         return {
  //           value: item._id,
  //           label: `${item.street}, ${item.postCode}, ${item.city}, ${
  //             item.country
  //           }, ${item.vat || ''}`,
  //         };
  //       })
  //   : [];

  const onSavePaymentComment = () => {
    if (invoice?._id) {
      invoiceApi
        .updateInvoicePaymentComment(
          invoice._id,
          form.getFieldValue('paymentComment'),
        )
        .then(() => {
          openSuccessNotification('Payment comment was successfully updated');
        })
        .catch((e) => {
          openErrorNotification(
            e.response.data.message || 'Failed to update paid by client',
          );
        });
    }
  };

  return (
    <div className="general-information-wrapper">
      <h4>General information</h4>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={8}>
          <Form.Item>
            <Input
              label="Client name"
              onChange={() => false}
              disabled
              errorMessage={getError('clientName', errorMessages)}
              value={client?.name}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchaseOrderNumber">
            <Input
              label="PO number"
              onChange={() => false}
              disabled={!isDraft}
              errorMessage={getError('purchaseOrderNumber', errorMessages)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="layout" valuePropName="value">
            <Dropdown
              label="Invoice layout"
              onChange={(value) => onChangeLayout(value)}
              options={[InvoiceLayout.standard, InvoiceLayout.simplified]}
              errorMessage={getError('layout', errorMessages)}
              disabled={!!isDeposit || !isDraft}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={16}>
          <Form.Item name="address">
            <Dropdown
              label="Invoice address"
              onChange={() => null}
              options={addresses.map((item: IAddress, index) => {
                return {
                  value: `${JSON.stringify(item)}`,
                  label: `${item.street}, ${
                    item.addressLines?.length
                      ? item.addressLines.map((value) => `${value},`)
                      : ''
                  }${item.postCode}, ${item.city}, ${item.country}, ${
                    item.vat || ''
                  }`,
                };
              })}
              errorMessage={getError('address', errorMessages)}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* //TODO */}
          <Form.Item name="vat">
            <Dropdown
              label="VAT"
              options={
                vats.length
                  ? vats.map((vat) => {
                      return {
                        label: `${vat?.name || ''} (${vat?.percentage || 0}%)`,
                        value: vat?._id,
                      };
                    })
                  : []
              }
              errorMessage={getError('vat', errorMessages)}
              onSelect={saveAsDraft}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
      </Row>
      {form.getFieldValue('layout') === InvoiceLayout.simplified && isDeposit && (
        <Row style={{ marginTop: '16px' }}>
          <Form.Item name="description">
            <Input
              label="Description"
              errorMessage={getError('description', errorMessages)}
              disabled={!isDraft}
            />
          </Form.Item>
        </Row>
      )}
      <Row
        style={{ margin: '16px 0' }}
        className={
          invoice?.clientContractComment.length
            ? 'client-contract-comment-textarea'
            : ''
        }>
        <Textarea
          label=""
          value={
            invoice?.clientContractComment.length
              ? invoice?.clientContractComment[0].comment || ''
              : 'Comment from client or contract'
          }
          disabled
          autoSize
        />
      </Row>
      {invoice?.clientContractComment?.length &&
      invoice?.clientContractComment?.length > 1 ? (
        <span
          className="show-comments-title"
          onClick={() => setIsOpenComments(true)}>
          Show all comments({invoice?.clientContractComment?.length})
        </span>
      ) : null}
      <Row style={{ marginTop: '32px' }}>
        <Form.Item name="commentOnInvoice">
          <Input
            label="Comment on invoice"
            errorMessage={getError('commentOnInvoice', errorMessages)}
            disabled={!isDraft}
          />
        </Form.Item>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        <Form.Item name="internalComment">
          <Input
            label="Internal comment"
            errorMessage={getError('internalComment', errorMessages)}
            disabled={!isDraft}
          />
        </Form.Item>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        <Form.Item name="paymentComment">
          <Input
            label="Payment comment"
            onChange={() => null}
            value=""
            onBlur={onSavePaymentComment}
          />
        </Form.Item>
      </Row>
      <ShippingMethodComponent
        errorMessages={errorMessages}
        form={form}
        setInvoice={setInvoice}
        invoice={invoice}
        saveAsDraft={saveAsDraft}
      />
      {isOpenComments && (
        <CommentsModal
          isOpen={isOpenComments}
          setIsOpen={setIsOpenComments}
          comments={invoice?.clientContractComment}
        />
      )}
    </div>
  );
};

export default GeneralInformation;
