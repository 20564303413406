import { Modal, Image } from 'antd';
import '../FilePreview.less';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import { useEffect, useState } from 'react';

interface IProps {
  url: string;
  isOpen: boolean;
  setClose: () => void;
}

const PreviewImage = (props: IProps) => {
  const { url, isOpen, setClose } = props;

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fileApi.getFileByUrl(url);
        setImageUrl(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImage();
  }, [url]);

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      width={900}
      onCancel={setClose}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div className="modal-wrapper">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image src={imageUrl || ''} />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewImage;
