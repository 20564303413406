import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import {
  setActivityReportList,
  setActivityReportPagination,
  setActivityReportSorting,
  setIsLoading,
} from './sliceReducer';
import { activityApi } from '../../../common/api/services/ActivityApi/activityApi';
import { batch } from 'react-redux';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import { IUploadActivityReportFileRequest } from '../../../common/api/services/FileApi/types/IUploadActivityReportFileRequest';

export const getActivityReportList = createAsyncThunk(
  'activity-report/getActivityReportList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const state = getState() as IRootState;
    const currentPagination =
      state.activityReport.activityReportList.pagination;
    const currentFilter = state.activityReport.activityReportList.filter;
    const currentSort = state.activityReport.activityReportList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;
    dispatch(setIsLoading(true));
    const response = await activityApi
      .getActivityReport(page, perPage, currentFilter, currentSort)
      .finally(() => dispatch(setIsLoading(false)));

    if (response) {
      batch(() => {
        dispatch(setActivityReportList(response));
        dispatch(setActivityReportPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeActivityReportSorting = createAsyncThunk(
  'activity-report/changeActivityReportSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).activityReport
      .activityReportList.sort;

    if (currentSortBy !== sortBy) {
      dispatch(setActivityReportSorting([currentSortBy, 'asc']));
      dispatch(getActivityReportList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setActivityReportSorting([currentSortBy, 'desc']));
      dispatch(getActivityReportList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setActivityReportSorting(['', '']));
      dispatch(getActivityReportList({ currentPage: 1 }));
      return;
    }
  },
);

export const exportToExelActivityReport = createAsyncThunk(
  'activity-report/exportToExelActivityReport',
  async (_, { getState }) => {
    const { activityReportList } = (getState() as IRootState).activityReport;
    const { sort, filter, selectedConsultant } = activityReportList;

    await activityApi.exportToExel(filter, sort, selectedConsultant);
  },
);

export const uploadPayslipFile = createAsyncThunk(
  'activity-report/uploadPayslipFile',
  async (query: IUploadActivityReportFileRequest) => {
    await fileApi.uploadActivityReportFile(query);
  },
);
