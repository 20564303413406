import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const InvoicesIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33301 5.00008C1.33301 3.15913 2.82539 1.66675 4.66634 1.66675H7.99967H9.71905C10.2137 1.66675 10.6828 1.88645 10.9994 2.26644L14.28 6.20319C14.5296 6.50272 14.6663 6.88027 14.6663 7.27016V10.0001V15.0001C14.6663 16.841 13.174 18.3334 11.333 18.3334H4.66634C2.82539 18.3334 1.33301 16.841 1.33301 15.0001V5.00008Z"
        strokeWidth="1.5"
      />
      <path
        d="M10.5 2.0835V5.00016C10.5 5.92064 11.2462 6.66683 12.1667 6.66683H14.25"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M4.66602 10H11.3327" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M4.66602 14.1667H7.99935"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InvoicesIcon;
