import CrossIcon from '../../../assets/icons/CrossIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import { getInternalExpenses } from '../../../redux/reducers/expenses/asyncThunkActions';
import {
  clearExpensesFiltersAndSorts,
  setErrorMessages,
  updateOpenModalAddExpense,
} from '../../../redux/reducers/expenses/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const InternalExpensesExtraSection = () => {
  const dispatch = useAppDispatch();

  const { sort: expensesSorts, filter: expensesFilters } = useAppSelector(
    (state) => state.expenses.expensesList,
  );
  const isResetExpensesFilters =
    expensesSorts.sortBy !== '' ||
    Object.values(expensesFilters).some((el) => el.length !== 0);

  return (
    <>
      {isResetExpensesFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearExpensesFiltersAndSorts());
            dispatch(getInternalExpenses({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}
      <Button
        onClick={() => {
          dispatch(updateOpenModalAddExpense(true));
          dispatch(setErrorMessages(null));
        }}
        buttonStyle="primary"
        buttonSize="medium"
        buttonWidth={160}
        leftIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
        Add new
      </Button>
    </>
  );
};

export default InternalExpensesExtraSection;
