import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  clearOnboardingErrorsValidation,
  setConsultantEmail,
  setIsFirstRender,
  setIsOpenOnboardingLastStepModal,
  setOnboardingChildAllowances,
  setOnboardingClientContract,
  setOnboardingConsultantInternalInfo,
  setOnboardingTabStatus,
  setOnboardingWorkContract,
  setPublicId,
  setUserNationality,
  updateOnboardingAdditionalInfo,
  updateOnboardingAvsAhv,
  updateOnboardingBankAccount,
  updateOnboardingPersonalInfo,
  updateOnboardingWorkPermit,
} from './sliceReducer';
import { onboardingApi } from '../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../types';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { userApi } from '../../../common/api/services/UserApi/userApi';
import { push } from 'redux-first-history';
import { putUserData } from '../auth/sliceReducer';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import { IGetConsultantInfoResponse } from '../../../common/api/services/OnboardingApi/types/IGetConsultantInfoResponse';
import {
  saveDraftPersonalInformationAsConsultant,
  saveDraftPersonalInformationAsEmployee,
  savePublishPersonalInformationAsConsultant,
  savePublishPersonalInformationAsEmployee,
  saveToConfirmPersonalInformationAsEmployee,
} from './tabs/personalInformationAsyncThunk';
import {
  saveDraftWorkContractAsConsultant,
  saveDraftWorkContractAsEmployee,
  savePublishWorkContractAsConsultant,
  savePublishWorkContractAsEmployee,
} from './tabs/workContractAsyncThunk';
import {
  checkClientContractAsEmployee,
  saveDraftClientContractAsConsultant,
  saveDraftClientContractAsEmployee,
  savePublishClientContractAsConsultant,
} from './tabs/clientContractAsyncThunk';
import {
  saveDraftWorkPermitAsConsultant,
  saveDraftWorkPermitAsEmployee,
  savePublishWorkPermitAsConsultant,
  savePublishWorkPermitAsEmployee,
  saveToConfirmWorkPermitAsEmployee,
} from './tabs/workPermitAsyncThunk';
import {
  saveDraftBankAccountAsConsultant,
  saveDraftBankAccountAsEmployee,
  savePublishBankAccountAsConsultant,
  savePublishBankAccountAsEmployee,
  saveToConfirmBankAccountAsEmployee,
} from './tabs/bankAccountAsyncThunk';
import {
  saveDraftChildAllowancesAsConsultant,
  saveDraftChildAllowancesAsEmployee,
  savePublishChildAllowancesAsConsultant,
  savePublishChildAllowancesAsEmployee,
  saveToConfirmChildAllowancesAsEmployee,
} from './tabs/childAllowancesAsyncThunk';
import {
  saveDraftAvsAhvAsConsultant,
  saveDraftAvsAhvAsEmployee,
  savePublishAvsAhvAsConsultant,
  savePublishAvsAhvAsEmployee,
  saveToConfirmAvsAhvAsEmployee,
} from './tabs/avsAhvAsyncThunk';
import {
  saveDraftAdditionalInfoAsConsultant,
  saveDraftAdditionalInfoAsEmployee,
  savePublishAdditionalInfoAsConsultant,
  savePublishAdditionalInfoAsEmployee,
} from './tabs/additionalInfoAsyncThunk';

const {
  personalInformation,
  workPermit,
  clientContract,
  workContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
  home,
  onboarding,
  consultants,
} = ROUTER_PATHS;

export const setDefaulOnboardingValues = createAsyncThunk(
  'onboarding/setDefaulOnboardingValues',
  async (profile: IGetConsultantInfoResponse, { dispatch }) => {
    const {
      firstName,
      lastName,
      phone,
      email,
      consultantOnboarding,
      _id,
      status,
      publicId,
    } = profile;

    //TODO Default state because of consultantOnboarding can be undefined
    dispatch(setPublicId(publicId));
    dispatch(updateOnboardingPersonalInfo({ firstName, lastName }));
    dispatch(setOnboardingConsultantInternalInfo({ id: _id, status }));
    dispatch(setConsultantEmail(email));

    if (consultantOnboarding) {
      const {
        personalInformation,
        workPermit,
        workContract,
        clientContract,
        bankAccount,
        childAllowances,
        ssnInformation,
        additionalInformation,
      } = consultantOnboarding;

      if (personalInformation) {
        const {
          tab,
          abroadAddress,
          maritalStatusDetails,
          swissAddress,
          ...restPersonalInfo
        } = personalInformation;

        const obj = {
          firstName,
          lastName,
          phone,
          email,
          maritalStatusDetailsMarriageDate:
            maritalStatusDetails?.marriageDate ?? '',
          maritalStatusDetailsSpouseName:
            maritalStatusDetails?.spouseName ?? '',
          maritalStatusDetailsSpouseLastName:
            maritalStatusDetails?.spouseLastName ?? '',
          maritalStatusDetailsSpouseDateOfBirth:
            maritalStatusDetails?.spouseDateOfBirth ?? '',
          maritalStatusDetailsSpouseNationality:
            maritalStatusDetails?.spouseNationality ?? '',
          maritalStatusDetailsIsSpouseSwissResident:
            maritalStatusDetails?.isSpouseSwissResident ?? true,
          maritalStatusDetailsSpouseSSN: maritalStatusDetails?.spouseSSN ?? '',
          maritalStatusDetailsSpousalIncome:
            maritalStatusDetails?.spousalIncome ?? true,
          maritalStatusDetailsSpouseWorkCountry:
            maritalStatusDetails?.spouseWorkCountry ?? '',
          maritalStatusDetailsSpouseWorkCity:
            maritalStatusDetails?.spouseWorkCity ?? '',
          maritalStatusDetailsSpouseWorkCanton:
            maritalStatusDetails?.spouseWorkCanton ?? '',
          maritalStatusDetailsSpouseStartedWorkingFrom:
            maritalStatusDetails?.spouseStartedWorkingFrom ?? '',
          swissAddressStreet: swissAddress?.street ?? '',
          swissAddressPostCode: swissAddress?.postCode ?? '',
          swissAddressCity: swissAddress?.city ?? '',
          abroadAddressStreet: abroadAddress?.street ?? '',
          abroadAddressPostCode: abroadAddress?.postCode ?? '',
          abroadAddressCity: abroadAddress?.city ?? '',
          abroadAddressCountry: abroadAddress?.country ?? '',
          abroadAddressUseAsCorrespondenceLocation:
            abroadAddress?.useAsCorrespondenceLocation ?? true,
          abroadAddressTotalAttendance: abroadAddress?.totalAttendance ?? '',
          ...restPersonalInfo,
        };
        dispatch(updateOnboardingPersonalInfo(obj));
        dispatch(setOnboardingTabStatus({ key: 0, value: tab.status }));
      }

      if (workContract) {
        const { tab } = workContract;
        dispatch(setOnboardingWorkContract(profile));
        dispatch(setOnboardingTabStatus({ key: 1, value: tab.status }));
      }

      if (clientContract) {
        const { tab } = clientContract;
        dispatch(setOnboardingClientContract(profile));
        dispatch(setOnboardingTabStatus({ key: 2, value: tab.status }));
      }

      if (workPermit) {
        const { tab, ...rest } = workPermit;
        dispatch(updateOnboardingWorkPermit(rest));
        dispatch(setOnboardingTabStatus({ key: 3, value: tab.status }));
      }

      if (bankAccount) {
        const { tab, ...rest } = bankAccount;

        dispatch(updateOnboardingBankAccount(rest));
        dispatch(setOnboardingTabStatus({ key: 4, value: tab.status }));
      }

      if (childAllowances) {
        const { tab, ...rest } = childAllowances;

        dispatch(setOnboardingChildAllowances(rest));
        dispatch(setOnboardingTabStatus({ key: 5, value: tab.status }));
      }

      if (ssnInformation) {
        const { tab, ...rest } = ssnInformation;

        dispatch(setOnboardingTabStatus({ key: 6, value: tab.status }));
        dispatch(updateOnboardingAvsAhv(rest));
      }

      if (additionalInformation) {
        const { tab, contacts } = additionalInformation;

        dispatch(setOnboardingTabStatus({ key: 7, value: tab.status }));
        const newContacts = contacts.map(
          ({ _id, phone, firstName, email, lastName, relationshipStatus }) => ({
            _id,
            phone: phone ?? '',
            firstName: firstName ?? '',
            email: email ?? '',
            lastName: lastName ?? '',
            relationshipStatus: relationshipStatus ?? '',
          }),
        );
        dispatch(updateOnboardingAdditionalInfo({ contacts: newContacts }));
      }

      dispatch(setIsFirstRender(false));
    }
  },
);

export const getOnboardingProfile = createAsyncThunk(
  'onboarding/getOnboardingProfile',
  async (_, { dispatch }) => {
    const response = await userApi.getUserProfile();
    if (response) {
      dispatch(setDefaulOnboardingValues(response));
      dispatch(
        setUserNationality(
          response?.consultantOnboarding?.personalInformation?.nationality ||
            '',
        ),
      );
      return response;
    }
  },
);

export const saveAsDraft = createAsyncThunk(
  'onboarding/saveAsDraft',
  async (_, { dispatch, getState }) => {
    const pathName = (getState() as IRootState).router.location?.pathname ?? '';
    const options = { moveToNextPage: true };
    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(personalInformation)) {
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftPersonalInformationAsConsultant(options));
    }

    if (pathName.includes(workContract)) {
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftWorkContractAsConsultant(options));
    }

    if (pathName.includes(clientContract)) {
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftClientContractAsConsultant(options));
    }

    if (pathName.includes(workPermit)) {
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftWorkPermitAsConsultant(options));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftBankAccountAsConsultant(options));
    }

    if (pathName.includes(childAllowance)) {
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftChildAllowancesAsConsultant(options));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftAvsAhvAsConsultant(options));
    }

    if (pathName.includes(additionalInformation)) {
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftAdditionalInfoAsConsultant(options));
    }
  },
);

export const saveAsPublish = createAsyncThunk(
  'onboarding/saveAsPublish',
  async (_, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName = state.router.location?.pathname ?? '';

    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(personalInformation)) {
      dispatch(savePublishPersonalInformationAsConsultant());
    }

    if (pathName.includes(workContract)) {
      dispatch(savePublishWorkContractAsConsultant());
    }

    if (pathName.includes(clientContract)) {
      dispatch(savePublishClientContractAsConsultant());
    }

    if (pathName.includes(workPermit)) {
      dispatch(savePublishWorkPermitAsConsultant());
    }

    if (pathName.includes(bankAccount)) {
      dispatch(savePublishBankAccountAsConsultant());
    }

    if (pathName.includes(childAllowance)) {
      dispatch(savePublishChildAllowancesAsConsultant());
    }

    if (pathName.includes(avsAhv)) {
      dispatch(savePublishAvsAhvAsConsultant());
    }

    if (pathName.includes(additionalInformation)) {
      dispatch(savePublishAdditionalInfoAsConsultant());
    }
  },
);

export const finishedOnboarding = createAsyncThunk(
  'onboarding/finishedOnboarding',
  async (_, { dispatch }) => {
    const response = await onboardingApi.finishedOnboardingAsConsultant();

    if (response) {
      dispatch(putUserData(response));
      dispatch(setIsOpenOnboardingLastStepModal(false));
      dispatch(push(home));
    }
  },
);

export const finishedConsultantOnboarding = createAsyncThunk(
  'onboarding/finishedConsultantOnboarding',
  async (_, { dispatch, getState }) => {
    const { consultantId, consultantStatus } = (getState() as IRootState)
      .onboarding;

    if (consultantStatus === 'inactive') {
      const response = await onboardingApi.finishedOnboardingAsEmployee(
        consultantId,
      );

      if (response) {
        dispatch(setIsOpenOnboardingLastStepModal(false));
        dispatch(push(consultants));
      }
    } else {
      dispatch(setIsOpenOnboardingLastStepModal(false));
      dispatch(push(consultants));
    }
  },
);

export const getOnboardingProfilByLeadId = createAsyncThunk(
  'onboarding/getOnboardingProfilByLeadId',
  async (consultantId: string, { dispatch }) => {
    const response = await consultantApi.getConsultantByConsultantId(
      consultantId,
    );

    if (response) {
      dispatch(setDefaulOnboardingValues(response));
      dispatch(
        setUserNationality(
          response?.consultantOnboarding?.personalInformation?.nationality ||
            '',
        ),
      );
    }
  },
);

export const saveAsDraftByConsultantId = createAsyncThunk(
  'onboarding/saveAsDraftByConsultantId',
  async (consultantId: string, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName = state.router.location?.pathname ?? '';
    const redirectRootRout = onboarding + `/${consultantId}`;
    const useNewEmail = state.onboarding.useNewEmail;
    const options: any = {
      moveToNextPage: true,
      redirectRootRout,
    };
    if (useNewEmail !== null) {
      options.useNewEmail = useNewEmail;
    }

    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(personalInformation)) {
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftPersonalInformationAsEmployee(options));
    }

    if (pathName.includes(workContract)) {
      dispatch(
        setOnboardingTabStatus({
          key: 1,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftWorkContractAsEmployee(options));
    }

    if (pathName.includes(clientContract)) {
      dispatch(
        setOnboardingTabStatus({
          key: 2,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftClientContractAsEmployee(options));
    }

    if (pathName.includes(workPermit)) {
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftWorkPermitAsEmployee(options));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftBankAccountAsEmployee(options));
    }

    if (pathName.includes(childAllowance)) {
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftChildAllowancesAsEmployee(options));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftAvsAhvAsEmployee(options));
    }

    if (pathName.includes(additionalInformation)) {
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.draft,
        }),
      );
      dispatch(saveDraftAdditionalInfoAsEmployee(options));
    }
  },
);
export const saveToConfirmByConsultantId = createAsyncThunk(
  'onboarding/saveToConfirmByConsultantId',
  async (consultantId: string, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName = state.router.location?.pathname ?? '';
    const redirectRootRout = onboarding + `/${consultantId}`;
    const useNewEmail = state.onboarding.useNewEmail;
    const options: any = {
      moveToNextPage: true,
      redirectRootRout,
    };

    if (useNewEmail !== null) {
      options.useNewEmail = useNewEmail;
    }

    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(personalInformation)) {
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(saveToConfirmPersonalInformationAsEmployee(options));
    }

    if (pathName.includes(workPermit)) {
      dispatch(
        setOnboardingTabStatus({
          key: 3,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(saveToConfirmWorkPermitAsEmployee(options));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(saveToConfirmBankAccountAsEmployee(options));
    }

    if (pathName.includes(childAllowance)) {
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(saveToConfirmChildAllowancesAsEmployee(options));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(saveToConfirmAvsAhvAsEmployee(options));
    }
  },
);

export const saveAsPublishByConsultantId = createAsyncThunk(
  'onboarding/saveAsPublishByConsultantId',
  async (consultantId: string, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName = state.router.location?.pathname ?? '';
    const redirectRootRout = onboarding + `/${consultantId}`;

    dispatch(clearOnboardingErrorsValidation());

    if (pathName.includes(personalInformation)) {
      dispatch(savePublishPersonalInformationAsEmployee(redirectRootRout));
    }

    if (pathName.includes(workContract)) {
      dispatch(savePublishWorkContractAsEmployee(redirectRootRout));
    }

    if (pathName.includes(clientContract)) {
      dispatch(checkClientContractAsEmployee(redirectRootRout));
    }

    if (pathName.includes(workPermit)) {
      dispatch(savePublishWorkPermitAsEmployee(redirectRootRout));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(savePublishBankAccountAsEmployee(redirectRootRout));
    }

    if (pathName.includes(childAllowance)) {
      dispatch(savePublishChildAllowancesAsEmployee(redirectRootRout));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(savePublishAvsAhvAsEmployee(redirectRootRout));
    }

    if (pathName.includes(additionalInformation)) {
      dispatch(savePublishAdditionalInfoAsEmployee(redirectRootRout));
    }
  },
);

export const saveAsDraftByMoveByConsultantId = createAsyncThunk(
  'onboarding/saveAsDraftByMoveByConsultantId',
  async (consultantId: string, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const pathName =
      (state.router.location?.state as { prevLocation: string })
        ?.prevLocation ?? '';

    if (pathName.includes(personalInformation)) {
      dispatch(saveDraftPersonalInformationAsEmployee({}));
    }

    if (pathName.includes(workContract)) {
      dispatch(saveDraftWorkContractAsEmployee({}));
    }

    if (pathName.includes(clientContract)) {
      dispatch(saveDraftClientContractAsEmployee({}));
    }

    if (pathName.includes(workPermit)) {
      dispatch(saveDraftWorkPermitAsEmployee({}));
    }

    if (pathName.includes(bankAccount)) {
      dispatch(saveDraftBankAccountAsEmployee({}));
    }

    if (pathName.includes(childAllowance)) {
      dispatch(saveDraftChildAllowancesAsEmployee({}));
    }

    if (pathName.includes(avsAhv)) {
      dispatch(saveDraftAvsAhvAsEmployee({}));
    }

    if (pathName.includes(additionalInformation)) {
      dispatch(saveDraftAdditionalInfoAsEmployee({}));
    }
  },
);
