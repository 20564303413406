import { Key, useEffect, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Spin, Upload } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import {
  IEmployeePayslipResponse,
  IEmployeePayslipsResponse,
  IUploadResult,
} from '../../common/api/services/EmployeePayslips/types/types';
import moment from '../../common/constants/moment';
import {
  commonDTOPeriod,
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import UploadLoadingModal from './components/UploadLoadingModal';
import UploadErrorModal from './components/UploadErrorModal';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { DeletePayslipsText } from '../../components/common-texts/CommonTexts';
import { employeePayslipsApi } from '../../common/api/services/EmployeePayslips/employeePayslipsApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Button from '../../components/button/Button';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { fileApi } from '../../common/api/services/FileApi/FileApi';
import { downloadExel } from '../../common/api/utils/downloadExel';
import {
  changeEmployeePayslipsFiltering,
  clearEmployeePayslipsFiltersAndSorts,
  selectEmployeePayslipsItem,
  setEmployeePayslipsList,
  setEmployeePayslipsPagination,
  setEmployeePayslipsSorting,
} from '../../redux/reducers/employee-payslips/sliceReducer';
import { SorterResult } from 'antd/lib/table/interface';
import { ISort, TSortValue } from '../../redux/types';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import UploadFilesModal from './components/UploadFilesModal';
import ILabelInValue from '../../common/types/ILabelInValue';
import PublishPayslipsModal from './components/PublishPayslipsModal';

const EmployeePayslips = () => {
  const dispatch: any = useAppDispatch();
  const { employeePayslipsList, selectedPayslips } = useAppSelector(
    (state) => state.employeePayslips,
  );
  const { pagination, sort, filter } = employeePayslipsList;
  const { currentPage, perPage, totalElement } = pagination;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  //NOTE: remove if will not be needed
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [payslips, setPayslips] = useState<IEmployeePayslipsResponse>([]);
  const [errorInfo, setErrorInfo] = useState<IUploadResult | null>(null);
  const [iterationId, setIterationId] = useState<string | null>(null);
  //upload files
  const [fileList, setFileList] = useState<any>([]);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [selectedType, setSelectedType] = useState({ label: '', value: '' });
  const [selectedPeriod, setSelectedPeriod] = useState<Date | null>(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  useEffect(() => {
    getPayslips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, filter]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPayslips = (currentSort?: ISort, currentFilter?: any) => {
    setIsLoading(true);
    employeePayslipsApi
      .getEmployeePayslips(
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      .then((response: any) => {
        dispatch(setEmployeePayslipsList(response));
        setPayslips(response.result);
      })
      .catch((e) => openErrorNotification('Failed to get payslips'))
      .finally(() => setIsLoading(false));
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectEmployeePayslipsItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedPayslips,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeEmployeePayslipsFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setEmployeePayslipsSorting(newSort));
      getPayslips({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setEmployeePayslipsPagination({ perPage, currentPage }));
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };
  const closePublishModal = () => {
    setIsOpenPublishModal(false);
  };

  const onDeletePayslips = () => {
    const body = { ids: selectedPayslips };
    employeePayslipsApi
      .deletePayslips(body)
      .then(() => {
        openSuccessNotification('Payslips were deleted successfully');
        dispatch(selectEmployeePayslipsItem([]));
        getPayslips();
      })
      .catch(() => {
        openErrorNotification('Paylips deleting was failed');
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpenDeleteModal(false);
      });
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const confirmUpload = () => {
    // if (!selectedPeriod || fileList.length === 0 || !selectedType.value) {
    //   return;
    // }
    setIsLoadingUpload(true);
    fileApi
      .uploadEmployeePayslips(
        fileList,
        selectedPeriod ? moment(selectedPeriod).format(commonDTOPeriod) : '',
        selectedType.value,
      )
      .then((response: any) => {
        if (response.iterationId) {
          setIsOpenUploadFiles(false);
          setIsOpenUploadModal(true);
          employeePayslipsApi
            .getUploadResultLogs(response.iterationId)
            .then((resp: any) => {
              if (resp.failed) {
                setIterationId(response.iterationId);
                setErrorInfo(resp);
                setIsOpenErrorModal(true);
              } else {
                openSuccessNotification(
                  `${
                    selectedType.value === 'payslip'
                      ? 'Payslip'
                      : 'Salary simulation'
                  } have been uploaded successfully`,
                );
              }
              setSelectedPeriod(null);
              setSelectedType({ label: '', value: '' });
              setFileList([]);
            })
            .catch(() => {
              openErrorNotification('Failed with downloading upload result');
            })
            .finally(() => {
              employeePayslipsApi
                .getExcelByIteration(response.iterationId)
                .then((resp: any) => {
                  downloadExel(resp);
                  getPayslips();
                })
                .catch(() =>
                  openErrorNotification('Failed to download excel report'),
                )
                .finally(() => {
                  setIsLoadingUpload(false);
                });
            });
        }
      })
      .catch((e) => {
        setIsLoadingUpload(false);
        openErrorNotification('Files were not uploaded');
        setErrorMessages(e?.response?.data?.messages || []);
      })
      .finally(() => {
        setIsOpenUploadModal(false);
      });
  };

  const onCloseErrorModal = () => {
    setIterationId(null);
    setErrorInfo(null);
    setIsOpenErrorModal(false);
  };

  const uploadFiles = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
  };

  const onPublishPayslips = (date?: string) => {
    const body = { payslipsIds: selectedPayslips, paymentDate: date };
    setIsLoading(true);
    employeePayslipsApi
      .publishPayslips(body)
      .then(() => {
        openSuccessNotification('Payslips were published successfully');
        dispatch(selectEmployeePayslipsItem([]));
        getPayslips();
      })
      .catch(() => {
        openErrorNotification('Paylips publishing was failed');
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpenPublishModal(false);
      });
  };

  const closeUploadModal = () => {
    setIsOpenUploadFiles(false);
    setSelectedType({ label: '', value: '' });
    setFileList([]);
    setSelectedPeriod(null);
    setErrorMessages([]);
  };
  const onChangeType = (value: ILabelInValue) => {
    setSelectedType(value);
  };

  const onChangeDate = (date: Date | null) => {
    setSelectedPeriod(date);
  };

  const data = payslips.map((item) => {
    const { period, uploadDate, consultant, _id, originalFilename } = item;
    const { firstName, lastName, publicId } = consultant;
    return {
      _id,
      id: publicId,
      firstName,
      lastName,
      period: period ? moment(period).format(commonMonthYearSpaceFormat) : '',
      uploadDate: period
        ? moment(uploadDate).format(commonMonthTimeFormat)
        : '',
      fileName: originalFilename,
    };
  });

  const columns: ColumnsType<IEmployeePayslipResponse> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.id as string) ?? '',
        searchAsString: false,
      })('id'),
      className: sortClassName('id'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.firstName as string) ?? '',
        searchAsString: false,
      })('firstName'),
      className: sortClassName('firstName'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.lastName as string) ?? '',
        searchAsString: false,
      })('lastName'),
      className: sortClassName('lastName'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'period',
        placeholder: 'Period',
        defaultValue: (filter?.period as string) ?? '',
      })(),
      className: sortClassName('period'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'uploadDate',
        values: (filter?.uploadDate as string) ?? '',
      })(),
      className: sortClassName('uploadDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'File name',
      dataIndex: 'fileName',
      key: 'fileName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.fileName as string) ?? '',
        searchAsString: false,
      })('fileName'),
      className: sortClassName('fileName'),
      sorter: true,
      width: 250,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: string, data: any) => {
        if (!data.fileName) {
          return null;
        }
        return (
          <span
            onClick={() => fileApi.downloadFileByUrl(data.fileName)}
            className="download-link-file">
            {data.fileName || ''}
          </span>
        );
      },
    },
  ];

  return (
    <div style={{ height: '90%' }}>
      <div className="header-section">
        <Breadcrumb />
        <div className="right-header-section">
          {(isHasFilters || selectedPayslips.length > 0) && (
            <button
              className="rest-filters-button"
              onClick={() => {
                onSelectChange([]);
                dispatch(clearEmployeePayslipsFiltersAndSorts());
                getPayslips({ sort: '', sortBy: '' }, []);
              }}>
              <ResetFilterIcon />
            </button>
          )}
          {selectedPayslips.length > 0 ? (
            <div className="btn-header-container">
              <div style={{ padding: '10px' }}>
                <Button
                  buttonStyle="error"
                  buttonSize="medium"
                  buttonWidth={183}
                  onClick={() => setIsOpenDeleteModal(true)}>
                  {`Delete selected (${selectedPayslips.length})`}
                </Button>
              </div>
              <Button
                buttonStyle="primary"
                buttonSize="medium"
                buttonWidth={183}
                onClick={() => setIsOpenPublishModal(true)}>
                {`Publish selected (${selectedPayslips.length})`}
              </Button>
            </div>
          ) : (
            <Button
              buttonStyle="primary"
              buttonSize="medium"
              buttonWidth={183}
              onClick={() => setIsOpenUploadFiles(true)}>
              + Upload files
            </Button>
          )}
        </div>
      </div>
      <div className="container-payslips">
        <Spin spinning={isLoading}>
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            rowSelection={rowSelection}
            onChange={onChangeSort}
            rowKey="_id"
          />
        </Spin>
        {isOpenUploadModal && <UploadLoadingModal isOpen={isOpenUploadModal} />}
        {isOpenErrorModal && (
          <UploadErrorModal
            isOpen={isOpenErrorModal}
            errorInfo={errorInfo}
            iterationId={iterationId}
            onClose={onCloseErrorModal}
            isLoading={isLoadingUpload}
          />
        )}
        {isOpenDeleteModal && (
          <AttentionModal
            isOpen={isOpenDeleteModal}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Yes, delete"
            closeModal={closeDeleteModal}
            confirmAction={onDeletePayslips}
            content={
              <DeletePayslipsText
                payslipsCount={selectedPayslips.length || 1}
                type="delete"
              />
            }
            isHideAttentionText
          />
        )}
        {/* {isOpenPublishModal && (
          <AttentionModal
            isOpen={isOpenPublishModal}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Yes, publish"
            closeModal={closePublishModal}
            confirmAction={onPublishPayslips}
            content={
              <DeletePayslipsText
                payslipsCount={selectedPayslips.length || 1}
                type="publish"
              />
            }
            isHideAttentionText
          />
        )} */}
        {isOpenPublishModal && (
          <PublishPayslipsModal
            isOpen={!!isOpenPublishModal}
            payslipsCount={selectedPayslips.length || 1}
            closePublishModal={closePublishModal}
            onPublishPayslips={onPublishPayslips}
          />
        )}
        {isOpenUploadFiles && (
          <UploadFilesModal
            isOpen={isOpenUploadFiles}
            onClose={closeUploadModal}
            fileList={fileList}
            uploadFiles={uploadFiles}
            selectedType={selectedType}
            onChangeType={onChangeType}
            onChangeDate={onChangeDate}
            onSubmit={confirmUpload}
            errorMessages={errorMessages}
            isLoading={isLoadingUpload}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeePayslips;
