import { FC, useState } from 'react';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';
import Input from '../../../components/input/Input';
import Textarea from '../../../components/textarea/Textarea';

export interface ISelectedNode {
  consultantId: string;
  clientId: string;
  name: string;
  notesValue: string;
  clientFullName?: string;
  period?: string;
  id?: string;
  type?: 'Timesheet' | 'Expenses';
  publicId?: string;
}

interface Props {
  note: ISelectedNode | null;
  onHide: () => void;
  onChangeNotes: any;
  isMainActivity?: boolean;
}

const NodeEdit: FC<Props> = ({
  note,
  onHide,
  onChangeNotes,
  isMainActivity,
}) => {
  const {
    notesValue = '',
    consultantId = '',
    clientId = '',
    name = '',
    clientFullName,
    period,
    id,
    type,
    publicId,
  } = note ?? {};
  const defaultValue = notesValue ? notesValue : '-';

  const [notes, setNotes] = useState(defaultValue);
  const onChangeNote = () => {
    if (type === 'Timesheet' || isMainActivity) {
      onChangeNotes(id, notes);
      onHide();
      return;
    }
    onChangeNotes(consultantId, clientId, notes);
    onHide();
  };

  return (
    <>
      {publicId && type && id && (
        <Row gutter={[16, 16]} className="monitoring-modal-name-row">
          <Col span={24}>
            <Input
              name="id"
              label={`${type} ID`}
              value={publicId}
              onChange={() => null}
              disabled={true}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} className="monitoring-modal-name-row">
        <Col span={24}>
          <Input
            name="Name"
            label="Name"
            value={name}
            onChange={() => null}
            disabled={true}
          />
        </Col>
      </Row>
      {period && (
        <Row gutter={[16, 16]} className="monitoring-modal-name-row">
          <Col span={24}>
            <Input
              name="period"
              label="Period"
              value={period}
              onChange={() => null}
              disabled={true}
            />
          </Col>
        </Row>
      )}
      {clientFullName && (
        <Row gutter={[16, 16]} className="monitoring-modal-name-row">
          <Col span={24}>
            <Input
              name="clientFullName"
              label="Client"
              value={clientFullName}
              onChange={() => null}
              disabled={true}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            rows={4}
            label="Notes"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            autoSize={false}
          />
          {/*<div className="monitoring-textarea-max-text">{notes.length}/200</div>*/}
        </Col>
      </Row>

      <Row gutter={[16, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={onHide}>
            Cancel
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onChangeNote}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NodeEdit;
