import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination, TSortValue } from '../../types';
import { IState } from './types';
import { IGetMonitoringListRequest } from '../../../common/api/services/MonitorngApi/types/IGetMonitoringListRequest';

const initialState: IState = {
  monitoringList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
    selectedElement: [],
  },
};

const reducer = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setMonitoringList(
      state,
      action: PayloadAction<IGetListDto<IGetMonitoringListRequest>>,
    ) {
      state.monitoringList.content = action.payload.result;
      state.monitoringList.pagination.totalElement = action.payload.total;
    },
    changeMonitoringFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.monitoringList.filter = {
        ...state.monitoringList.filter,
        ...action.payload,
      };
    },
    setMonitoringSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.monitoringList.sort.sortBy = action.payload[0];
      state.monitoringList.sort.sort = action.payload[1];
    },
    clearMonitoringFiltersAndSorts(state) {
      state.monitoringList.sort = initialState.monitoringList.sort;
      state.monitoringList.filter = initialState.monitoringList.filter;
      state.monitoringList.selectedElement =
        initialState.monitoringList.selectedElement;
    },
    setMonitoringPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.monitoringList.pagination.currentPage = action.payload.currentPage;
      state.monitoringList.pagination.perPage = action.payload.perPage;
    },
    selectMonitoringListItem(state, action: PayloadAction<string[]>) {
      state.monitoringList.selectedElement = action.payload;
    },
  },
});

export const {
  setMonitoringList,
  changeMonitoringFiltering,
  setMonitoringPagination,
  setMonitoringSorting,
  clearMonitoringFiltersAndSorts,
  selectMonitoringListItem,
} = reducer.actions;

export default reducer.reducer;
