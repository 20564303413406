import { useState } from 'react';
import CommentItem from './CommentItem';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import {
  IComment,
  IComments,
} from '../../../common/api/services/ConsultantApi/types/IComments';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { Spin } from 'antd';

interface IProps {
  consultantId: string | null;
  commentsList: IComments;
  getComments: () => void;
  isLoadingComments: boolean;
}

const CommentsSection = (props: IProps) => {
  const { consultantId, commentsList, getComments, isLoadingComments } = props;
  const [isOpenCreateComment, setIsOpenCreateComment] = useState(false);
  const [isEditModeOpen, setIsEditModeOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<
    IComment | undefined | null
  >(null);

  const addNewComment = () => {
    if (isOpenCreateComment || isEditModeOpen) {
      return;
    }
    setIsOpenCreateComment(true);
  };
  const sendComment = (value: string) => {
    if (!consultantId) {
      return;
    }
    if (isOpenCreateComment) {
      consultantApi
        .addComment(consultantId, {
          source: 'invoice',
          comment: value,
        })
        .then(() => {
          openSuccessNotification('Your comment was added successfully');
          getComments();
          setIsOpenCreateComment(false);
        })
        .catch(() => openErrorNotification('Failed to send a comments'));
    } else if (selectedComment) {
      consultantApi
        .updateComment(consultantId, selectedComment._id, '', value)
        .then(() => {
          openSuccessNotification('Your comment was added successfully');
          getComments();
          setIsEditModeOpen(false);
          setSelectedComment(null);
        })
        .catch(() => openErrorNotification('Failed to send a comments'));
    }
  };

  return (
    <div className="comments-container">
      <div className="comments-header">
        <span className="title-text">Comments</span>
        <span
          className="title-text add-new-btn-comment"
          onClick={() => addNewComment()}>
          + Add new
        </span>
      </div>
      <div
        className={`comments-list ${
          isLoadingComments ? 'comments-loader' : ''
        }`}>
        {isOpenCreateComment && (
          <CommentItem
            setSelectedComment={setSelectedComment}
            isNewComment={true}
            isEditModeOpen={isEditModeOpen}
            sendComment={sendComment}
            getComments={getComments}
            consultantId={consultantId}
            setIsOpenCreateComment={setIsOpenCreateComment}
            setIsEditModeOpen={setIsEditModeOpen}
            selectedComment={selectedComment}
          />
        )}
        {isLoadingComments ? (
          <Spin spinning={isLoadingComments}></Spin>
        ) : (
          commentsList.length > 0 &&
          commentsList.map((item) => (
            <CommentItem
              setSelectedComment={setSelectedComment}
              isEditModeOpen={isEditModeOpen}
              sendComment={sendComment}
              key={item._id}
              comment={item}
              consultantId={consultantId}
              getComments={getComments}
              setIsOpenCreateComment={setIsOpenCreateComment}
              setIsEditModeOpen={setIsEditModeOpen}
              selectedComment={selectedComment}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default CommentsSection;
