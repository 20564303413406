import { FC, useState } from 'react';
import { InputNumber as AntInputNumber } from 'antd';

import CrossIcon from '../../assets/icons/CrossIcon';

import './Input.less';

type TInputType = 'number';

interface IProps {
  value: number;
  onChange: (value: string | number | null) => void;
  label: string;
  inputType?: TInputType;
  name?: string;
  errorMessage?: string;
  disabled?: boolean;
  placeholder?: string;
  showIcons?: boolean;
  showAsterisk?: boolean;
}

const ArrowSmallUpIcon = (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.427246 5.06249L4.94808 0.468741L9.46892 5.06249C9.61475 5.20833 9.68767 5.42708 9.68767 5.57291C9.68767 5.71874 9.61475 5.93749 9.46892 6.08333C9.32309 6.22916 9.17725 6.30208 8.9585 6.30208H0.937663C0.718913 6.30208 0.57308 6.22916 0.427246 6.08333C0.281413 5.93749 0.208496 5.79166 0.208496 5.57291C0.208496 5.35416 0.281413 5.20833 0.427246 5.06249Z"
      fill="#0E2552"
    />
  </svg>
);

const ArrowSmallDownIcon = (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.427246 2.20833L4.94808 6.80208L9.46891 2.20833C9.61475 2.0625 9.68766 1.84375 9.68766 1.69792C9.68766 1.55208 9.61475 1.33333 9.46891 1.1875C9.32308 1.04167 9.17725 0.96875 8.9585 0.96875H0.937663C0.718913 0.96875 0.57308 1.04167 0.427246 1.1875C0.281413 1.33333 0.208496 1.47917 0.208496 1.69792C0.208496 1.91667 0.281413 2.0625 0.427246 2.20833Z"
      fill="#0E2552"
    />
  </svg>
);

const InputNumber: FC<IProps> = (props) => {
  const {
    label,
    name = '',
    inputType = 'number',
    value,
    onChange,
    errorMessage,
    disabled,
    showIcons = true,
    placeholder = 'Number',
    showAsterisk = false,
  } = props;

  const [isFocusClass, setFocusClass] = useState(false);

  const focusClass = isFocusClass ? 'focus-input' : '';
  const inputClassName = `typed input ${focusClass}`;
  const inputStatus = errorMessage ? 'error' : '';
  const labelErrorClass = inputStatus ? 'input-label-error' : '';
  const labelClass = `input-label ${inputType} ${focusClass} ${labelErrorClass} ${
    disabled ? '__disabled' : ''
  }`;

  const inputClassNameWithoutLabel = !label
    ? `${inputClassName} without-label`
    : inputClassName;

  return (
    <div className="input-label-wrapper">
      <label className={labelClass}>
        <AntInputNumber
          status={inputStatus}
          controls={
            showIcons
              ? { upIcon: ArrowSmallUpIcon, downIcon: ArrowSmallDownIcon }
              : false
          }
          name={name}
          onFocus={() => setFocusClass(true)}
          onBlur={() => setFocusClass(false)}
          className={inputClassNameWithoutLabel}
          bordered={false}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="label">
          {label}
          {showAsterisk && <span className="asterisk">*</span>}
        </span>
      </label>
      {errorMessage && (
        <span className="error-message error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default InputNumber;
