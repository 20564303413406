import { Modal } from 'antd';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  comments:
    | Array<{
        _id: string;
        consultant: string;
        comment: string;
      }>
    | undefined;
}

const CommentsModal = (props: IProps) => {
  const { isOpen, setIsOpen, comments } = props;
  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      centered
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div className="comments-generate-container">
        <h4>Comments</h4>
        {comments?.length &&
          comments.map((item) => (
            <div key={item._id} className="comments-generate-item">
              <span style={{ color: '#2582e4' }}>{item.consultant}</span>
              <span>{item.comment}</span>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default CommentsModal;
