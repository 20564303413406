import { ChangeEvent, FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AuthWrapperBackground from '../../components/auth-wrapper-background/AuthWrapperBackground';
import AuthFormHead from '../../components/auth-form-head/AuthFormHead';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { ISetupPasswordRequest } from '../../common/api/services/AuthApi/types/ISetupPasswordRequest';
import { validation } from './validation';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setAuthMessage } from '../../redux/reducers/auth/sliceReducer';

import './SetupPasswordPage.less';

interface IProps {
  onSubmit: (data: ISetupPasswordRequest) => void;
}

const SetupPasswordPage: FC<IProps> = ({ onSubmit }) => {
  const dispatch = useAppDispatch();
  const serverValidationErrorMessage = useAppSelector(
    (state) => state.auth.errorMassage,
  );

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<ISetupPasswordRequest>({
    defaultValues: { confirmPassword: '', password: '' },
    resolver: validation,
  });

  useEffect(() => {
    if (serverValidationErrorMessage) {
      clearErrors();
      setError('password', { message: serverValidationErrorMessage });
    }

    return () => {
      dispatch(setAuthMessage(''));
    };
  }, [serverValidationErrorMessage]);

  const passwordValue = watch().password;
  const confirmPasswordValue = watch().confirmPassword;
  const passwordErrorMessage = errors.password?.message;
  const passwordConfirmPasswordMessage = errors.confirmPassword?.message;

  const onChangeLoginInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldName = e.target.name as 'confirmPassword' | 'password';
    const fieldValue = e.target.value;
    setValue(fieldName, fieldValue);
  };

  return (
    <AuthWrapperBackground>
      <form className="setup-password-page" onSubmit={handleSubmit(onSubmit)}>
        <AuthFormHead
          title="Set up new password"
          description="Your password should contain at least 8 characters, 1 capital letter, 1 number."
        />

        <Input
          onChange={onChangeLoginInput}
          value={passwordValue}
          label="Password"
          name="password"
          inputType="password"
          errorMessage={passwordErrorMessage}
        />

        <Input
          onChange={onChangeLoginInput}
          value={confirmPasswordValue}
          label="Confirm password"
          name="confirmPassword"
          inputType="password"
          errorMessage={passwordConfirmPasswordMessage}
        />

        <Button type="submit" buttonSize="normal" buttonStyle="primary">
          Submit
        </Button>
      </form>
    </AuthWrapperBackground>
  );
};

export default SetupPasswordPage;
