import { FC, useEffect, useState } from 'react';
import { Menu, Layout } from 'antd';

import './SidebarMenu.less';
import SidebarLogoIcon from '../../assets/icons/SidebarLogoIcon';
import AngleWithBorderIcon from '../../assets/icons/AngleWithBorderIcon';
import { menuItems } from './manuItems';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { Roles } from '../../common/enums/Roles';
import LogoutBtn from '../header/conponents/logout-btn/LogoutBtn';

interface IProps {
  isOpenNavigation: boolean;
  setIsOpenNavigation: (isOpen: boolean) => void;
}

const { Sider } = Layout;

const {
  activityReport,
  reporting,
  invoices,
  payslips,
  documents,
  myInformation,
  monitoring,
  timesheets,
  employeePayslips,
  consultantPayslips,
  systemInfo,
  mainActivities,
  expenses,
  workPermit,
} = ROUTER_PATHS;

const SidebarMenu = (props: IProps) => {
  const { isOpenNavigation, setIsOpenNavigation } = props;
  const { role, firstName, lastName, email } = useAppSelector(
    (state) => state.auth,
  );
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(timesheets);
  const { pathname } = useLocation();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const arrayPath = pathname.split('/');
    const currentPage = Object.values(ROUTER_PATHS).find((el) =>
      el.includes(arrayPath[1]),
    );
    setDefaultSelectedKeys(currentPage ?? '');
  }, [pathname]);

  const isAdmin = role === Roles.ADMIN || role === Roles.MANAGER;
  const isCustomer = role === Roles.CONSULTANT;

  const collapseClass = collapsed ? '__collapsed' : '';
  const viewBoxIcon = !collapsed ? '0 0 120 85' : '0 0 120 32';

  const newMenuItems = menuItems.filter((item) => {
    if (!isAdmin && item!.key === activityReport) return false;
    if (!isAdmin && item!.key === reporting) return false;
    if (!isAdmin && item!.key === 'sub1') return false;
    if (!isAdmin && item!.key === monitoring) return false;
    if (!isAdmin && item!.key === invoices) return false;
    if (!isAdmin && item!.key === employeePayslips) return false;
    if (!isAdmin && item!.key === systemInfo) return false;
    if (!isAdmin && item!.key === mainActivities) return false;
    // if (!isAdmin && item!.key === documents) return false;
    if (!isAdmin && item!.key === workPermit) return false;
    if (!isCustomer && item!.key === payslips) return false;
    if (!isCustomer && item!.key === myInformation) return false;
    if (!isCustomer && item!.key === consultantPayslips) return false;
    if (!isCustomer && item!.key === timesheets) return false;
    if (!isCustomer && item!.key === expenses) return false;
    //NOTE: remove next 2 lines for add Payslip and Document pages for consultant
    if (isCustomer && item!.key === payslips) return false;
    return true;
  });

  return (
    <>
      <Sider
        className={`sidebar-wrapper ${collapseClass}`}
        collapsed={collapsed}>
        <div className="sidebar-menu">
          <div>
            <SidebarLogoIcon className="logo-icon" viewBox={viewBoxIcon} />
            <Menu
              selectedKeys={[defaultSelectedKeys]}
              defaultOpenKeys={['sub1']}
              mode="inline"
              items={newMenuItems}
            />
          </div>
          <p className="hide-wrapper">
            <button onClick={toggleCollapsed}>
              <AngleWithBorderIcon />
              <span>Hide menu</span>
            </button>
          </p>
        </div>
      </Sider>
      {isOpenNavigation && (
        <div className="mobile-sidebar">
          <Menu
            selectedKeys={[defaultSelectedKeys]}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={newMenuItems}
            onClick={() => setIsOpenNavigation(false)}
          />
          <div className="mobile-sidebar-footer">
            <div className="user-details">
              <span className="user-details-name">
                {firstName} {lastName}
              </span>
              <span className="user-details-email">{email}</span>
            </div>
            <LogoutBtn />
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarMenu;
