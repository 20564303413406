import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Moment } from 'moment';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import FilterIcon from '../../assets/icons/FilterIcon';
import Button from '../../components/button/Button';
import CommonDatePicker from '../../components/date-picker/CommonDatePicker';
import {
  commonDateMonthFormat,
  commonYearDateFormat,
} from '../constants/dateConstants';
import moment from '../../common/constants/moment';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, string>) => void;
  values: Record<string, string>;
}

export const useTableDateFilter = ({
  fetchRequest,
  changeValues,
  values,
}: IArguments) => {
  const createdBetween = values?.createdBetween
    ? values.createdBetween.split('|')
    : '';
  const after = createdBetween
    ? moment(createdBetween[0])
    : values?.after
    ? moment(values.after)
    : '';
  const before = createdBetween
    ? moment(createdBetween[1])
    : values?.createdBetween
    ? moment(values.createdBetween)
    : '';

  const [fromValue, setFromValue] = useState<Moment | string>(after);
  const [toValue, setToValue] = useState<Moment | string>(before);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (createdBetween || after || before) {
      setIsFiltered(true);
    }

    if (!createdBetween && !after && !before) {
      setIsFiltered(false);
      setFromValue('');
      setToValue('');
    }
  }, [values]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (!!fromValue || !!toValue) {
      if (!!fromValue && !!toValue) {
        const from = (fromValue as Moment).format(commonYearDateFormat);
        const to = (toValue as Moment).format(commonYearDateFormat);
        changeValues({
          createdBetween: from + '|' + to,
          before: '',
          after: '',
        });
      }

      if (fromValue && !toValue) {
        const from = (fromValue as Moment).format(commonYearDateFormat);

        changeValues({
          before: '',
          createdBetween: '',
          after: from,
        });
      }

      if (!fromValue && toValue) {
        const to = (toValue as Moment).format(commonYearDateFormat);

        changeValues({
          after: '',
          before: to,
          createdBetween: '',
        });
      }

      setIsFiltered(true);
      fetchRequest();
    } else {
      setIsFiltered(false);
    }
    confirm();
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({
      after: '',
      before: '',
      createdBetween: '',
    });
    fetchRequest();
    confirm();
    setFromValue('');
    setToValue('');
    setIsFiltered(false);
  };

  return (): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-date-filter-dropdown">
        <div className="filter-date">
          <CommonDatePicker
            defaultValue={
              fromValue ? moment(fromValue).format(commonDateMonthFormat) : ''
            }
            changeDate={(date) => setFromValue(date ? moment(date) : '')}
            className="date-picker-form"
            placeholder="From"
          />
        </div>

        <div className="filter-date">
          <CommonDatePicker
            defaultValue={
              toValue ? moment(toValue).format(commonDateMonthFormat) : ''
            }
            changeDate={(date) => {
              setToValue(date ? moment(date) : '');
            }}
            className="date-picker-form"
            placeholder="To"
          />
        </div>
        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            onClick={() => onClear(confirm)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};
