import { Modal, Progress } from 'antd';
import { useEffect, useState } from 'react';
import './../../waiting-room-page/WaitingRoomPage.less';
import '../EmployeePayslips.less';

interface IProps {
  isOpen: boolean;
}

const UploadLoadingModal = (props: IProps) => {
  const [progress, setProgress] = useState(0);
  const { isOpen } = props;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (progress < 100) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          return newProgress >= 100 ? 0 : newProgress;
        });
      }, 500);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div className="upload-modal-container">
        <b style={{ fontSize: '16px' }}>
          The files are uploading. Please don&apos;t close the window.
        </b>
        <Progress
          showInfo={false}
          percent={progress}
          style={{ marginTop: '1rem' }}
        />
      </div>
    </Modal>
  );
};

export default UploadLoadingModal;
