import { TBtnSize, TBtnStyle } from '../../components/button/types';

export function getBtnStyle(btnStyle: TBtnStyle): string {
  if (btnStyle === 'secondary') return 'btn-secondary';

  if (btnStyle === 'tertiary') return 'btn-tertiary';

  if (btnStyle === 'error') return 'btn-error';

  return 'btn-primary';
}

export function getBtnSize(btnSize: TBtnSize): string {
  if (btnSize === 'medium') return 'btn-medium';

  if (btnSize === 'small') return 'btn-small';

  return 'btn-normal';
}
