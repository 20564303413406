import {
  IBankAccountResponse,
  IChildAllowancesResponse,
  IPersonalInfoResponse,
  ISsnInformationResponse,
  IWorkPermitResponse,
} from '../../OnboardingApi/types/IGetConsultantInfoResponse';
import { IOnboardingTabDto } from '../../OnboardingApi/types/IOnboardingTabDto';
import { ICompany } from '../../OnboardingApi/types/IWorkContractRequest';
import { IClient } from '../../OnboardingApi/types/IClientContract';
import { IUploadFileResponse } from '../../FileApi/types/IUploadFileResponse';
import { PaymentStatuses } from '../../EmployeePayslips/types/types';

interface IActivityCompanies extends ICompany {
  payslipFiles?: IUploadFileResponse[];
}

export interface IExpensesOptimization {
  approvalRequired: boolean;
  consultantId: string;
  createdAt: string;
  creationMode: string;
  documents: Array<any>;
  formType: string;
  hasExpensesInThisMonth: boolean;
  isSigned: boolean;
  period: string;
  publicId: string;
  status: string;
  total: number;
  type: string;
  updatedAt: string;
  _id: string;
}

export interface IActivityReportPayslips {
  consultantId: string;
  createdAt: string;
  originalFilename: string;
  paymentStatus: string;
  period: string;
  status: PaymentStatuses;
  updatedAt: string;
  salaryNetPaid?: number;
  uploadDate: string;
  userId: string;
  paymentDate: string;
  _id: string;
}

interface IWorkContractResponse {
  useExpensesForOptimization: boolean;
  tab: IOnboardingTabDto;
  companies: IActivityCompanies;
}

interface IClientContractResponse {
  tab: IOnboardingTabDto;
  clients: IClient;
}

interface IExpenseMA {
  clientId: string;
  consultantId: string;
  creationMode: string;
  period: string;
  publicId: string;
  status: string;
  total: number;
  type: string;
  visibility: string;
  _id: string;
}

export interface IActivityReportResponse {
  _id: {
    month: number;
    year: number;
    clientId: string;
    period: string;
    projectId: string;
  };
  consultantId: string;
  publicId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  confirmedAccount: boolean;
  role: string;
  status: string;
  consultantDetails: {
    numberOfDependantChildren: boolean;
    numberOfDependantTeenagers: boolean;
    manager: {
      userId: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
    };
    status: string;
    createdAt: string;
    updatedAt: string;
  };
  consultantOnboarding: {
    personalInformation: IPersonalInfoResponse;
    workContract: IWorkContractResponse;
    clientContract: IClientContractResponse;
    workPermit: IWorkPermitResponse;
    bankAccount: IBankAccountResponse;
    childAllowances: IChildAllowancesResponse;
    ssnInformation: ISsnInformationResponse;
  };
  createdAt: string;
  updatedAt: string;
  timesheets: IActivityReportResponseTimesheet[];
  commonPeriod: string;
  expensesOptimization: Array<IExpensesOptimization>;
  payslips: IActivityReportPayslips[];
  seller: {
    email: string;
    firstName: string;
    lastName: string;
    publicId: number;
    role: string;
    status: string;
    _id: string;
  };
  salarySimulations?: Array<ISalarySimulations>;
  timesheetsTotalPayRate: number;
  mealAllowanceExpenses: Array<IExpenseMA>;
  invoicedTimesheets: string;
  invoicedExpenses: string;
}

export interface ISalarySimulations {
  consultantId: string;
  createdAt: string;
  iterationId: string;
  originalFilename: string;
  paymentStatus: string;
  period: string;
  status: string;
  type: string;
  updatedAt: string;
  uploadDate: string;
  userId: string;
  _id: string;
}

export interface IActivityReportResponseExpenses {
  _id: string;
  creationMode: string;
  consultantId: string;
  timesheetId: string;
  workContract: {
    name: string;
    email: string;
    phone: string;
    addresses: [
      {
        street: string;
        postCode: string;
        city: string;
        country: string;
      },
    ];
    hasAdditionalAccidentInsurance: boolean;
    incomeLossInsurance: string;
    invoiceCurrency: string;
    paymentCurrency: string;
    jobTitle: string;
    startDate: string;
    endDate: null | string;
    indefinite: boolean;
    rate: string;
    type: string;
    workLocation: {
      type: string;
      officeAddress: string;
      hybridActivity: string;
      hybridAddress: string;
    };
    otherJob: {
      active: boolean;
      isPrimaryJob: boolean;
      numeriqTime: number;
    };
    hasTreasury: boolean;
    hasSalaryAmount: boolean;
    overtimeAllowed: boolean;
    doubleTimeAllowed: boolean;
    receivedWorkContract: boolean;
    treasuryPaymentDate: string;
    _id: string;
  };
  clientContract: {
    companyId: string;
    shippingMethod: string;
    shippingMethodDetails: {
      postalAddress: string;
      firstName: string;
      lastName: string;
      portalUrl: string;
    };
    workType: string;
    paymentTerm: string;
    reimbursableExpenses: boolean;
    paymentTermRange: string;
    isAgencyInvolved: boolean;
    receivedClientContract: boolean;
    agencyConfirmed: boolean;
    startDate: string;
    indefinite: boolean;
    billRate: string;
    agencyMargin: string;
    _id: string;
  };
  status: string;
  type: string;
  formType: string;
  period: string;
  hasExpensesInThisMonth: boolean;
  isSigned: boolean;
  approvalRequired: boolean;
  total: number;
  employee: string;
  documents: {
    originalFilename: string;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
  comments: string;
  companyRepresentativeEmail: string;
  numeriqComments: string;
  representativeFirstName: string;
  representativeLastName: string;
  clientApprovalDate: string;
}

export interface IActivityReportResponseTimesheet {
  _id: string;
  creationMode: string;
  consultantId: string;
  workContract: {
    name: string;
    email: string;
    phone: string;
    addresses: {
      street: string;
      postCode: string;
      city: string;
      country: string;
    }[];
    hasAdditionalAccidentInsurance: boolean;
    incomeLossInsurance: string;
    invoiceCurrency: string;
    paymentCurrency: string;
    jobTitle: string;
    startDate: string;
    endDate: null | string;
    indefinite: boolean;
    rate: string;
    type: string;
    workLocation: {
      type: string;
      officeAddress: string;
      hybridActivity: string;
      hybridAddress: string;
    };
    otherJob: {
      active: boolean;
      isPrimaryJob: boolean;
      numeriqTime: number;
    };
    hasTreasury: boolean;
    hasSalaryAmount: boolean;
    overtimeAllowed: boolean;
    doubleTimeAllowed: boolean;
    receivedWorkContract: boolean;
    treasuryPaymentDate: string;
    _id: string;
  };
  clientContract: {
    companyId: string;
    shippingMethod: string;
    shippingMethodDetails: {
      postalAddress: string;
      firstName: string;
      lastName: string;
      portalUrl: string;
    };
    workType: string;
    paymentTerm: string;
    reimbursableExpenses: boolean;
    paymentTermRange: string;
    isAgencyInvolved: boolean;
    receivedClientContract: boolean;
    agencyConfirmed: boolean;
    startDate: string;
    indefinite: boolean;
    billRate: string;
    agencyMargin: string;
    _id: string;
  };
  status: string;
  type: string;
  isSigned: boolean;
  approvalRequired: boolean;
  period: string;
  hasActivityPerMonth: boolean;
  standardTime: number;
  overtime: number;
  doubleTime: number;
  employee: string;
  documents: {
    originalFilename: string;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
  comments: string;
  companyRepresentativeEmail: string;
  numeriqComments: string;
  representativeFirstName: string;
  representativeLastName: string;
  expenses: IActivityReportResponseExpenses[];
}

export interface IPaymentDataDTO {
  payslipPaymentDate: Date;
  timesheetIds: Array<string>;
}

export interface IARPayload {
  timesheets: Array<string>;
  expenses: Array<string>;
}

export interface IARPAyloadDTO {
  _id: string;
  creationMode: string;
  visibility: string;
  consultantId: string;
  clientId: string;
  status: string;
  type: string;
  isSigned: boolean;
  approvalRequired: boolean;
  period: string;
  hasActivityPerMonth: boolean;
  standardTime: number;
  overtime: number;
  doubleTime: number;
  publicId: string;
  documents: Array<{
    originalFilename: string;
    _id: string;
  }>;
  createdAt: string;
  clientContract: IClient;
  updatedAt: string;
  companyRepresentativeEmail: string | null;
  representativeFirstName: string | null;
  representativeLastName: string | null;
  submissionDate: string;
  docType: string;
  actualRate: number;
  actualHasSalaryAmount: boolean;
  actualBillRate: string;
  totalBillRate: number;
  totalPayRate: number;
  totalSsnRate: string;
  workContract: ICompany;
  linkToInvoice?: string;
}

export interface IActivitiesResponse {
  _id: string;
  creationMode: string;
  visibility: string;
  consultantId: string;
  clientId: string;
  timesheetId: string;
  workContract: ICompany;
  clientContract: IClient;
  status: string;
  type: string;
  formType: string;
  period: string;
  hasExpensesInThisMonth: boolean;
  isSigned: boolean;
  approvalRequired: boolean;
  total: number;
  publicId: string;
  documents: Array<{
    originalFilename: string;
    _id: string;
  }>;
  createdAt: string;
  updatedAt: string;
  docType: string;
}

export interface IExpenseResponse {
  _id: string;
  creationMode: string;
  visibility: string;
  consultantId: string;
  clientId: string;
  status: string;
  type: string;
  formType: string;
  period: string;
  hasExpensesInThisMonth: boolean;
  isSigned: boolean;
  approvalRequired: boolean;
  total: number;
  publicId: string;
  documents: Array<{
    originalFilename: string;
    _id: string;
  }>;
  createdAt: string;
  updatedAt: string;
}

export interface IConsultantResponse {
  _id: {
    month: number;
    year: number;
    clientId: string;
    projectId: string;
    period: string;
  };
  consultantId: string;
  publicId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  confirmedAccount: boolean;
  role: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  commonPeriod: string;
  projectTitle: string;
  projectDescription: string;
}

export enum LinkedStatuses {
  NO = 'No',
  TO_INVOICE = 'To invoice',
  DRAFT = 'Draft',
  SENT = 'Sent',
  PARTIALLY_PAID = 'Partially paid',
  PAID = 'Paid',
}
export type TLinkedStatusParserKey =
  | LinkedStatuses.DRAFT
  | LinkedStatuses.NO
  | LinkedStatuses.PAID
  | LinkedStatuses.PARTIALLY_PAID
  | LinkedStatuses.SENT
  | LinkedStatuses.TO_INVOICE;

export const TLinkedStatusParser = {
  [LinkedStatuses.DRAFT]: 'draft',
  [LinkedStatuses.NO]: 'no',
  [LinkedStatuses.PAID]: 'paid',
  [LinkedStatuses.PARTIALLY_PAID]: 'partially-paid',
  [LinkedStatuses.SENT]: 'sent',
  [LinkedStatuses.TO_INVOICE]: 'to-invoice',
};
