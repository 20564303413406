import { ChangeEvent, FC } from 'react';
import { RadioChangeEvent, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  clearOnboardinBankAccount,
  clearOnboardingErrorsValidation,
  updateOnboardingBankAccount,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import BankAccountTabBankDetailsSection from './BankAccountTabBankDetailsSection';
import BankAccountTabDetailsSection from './BankAccountTabDetailsSection';
import moment from '../../../../common/constants/moment';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';
import '../../../my-information-page/MyInformationPage.less';

const BankAccountTab: FC = () => {
  const dispatch = useAppDispatch();

  const {
    hasBankAccount,
    hasIBAN,
    bankAccountDetails,
    iban,
    swift,
    currency,
    npa,
    accountOwner,
    address,
    additionalAddress,
    comment,
    bankName,
    bankAdditionalAddress,
    bankAddress,
    bankCountry,
    bankCity,
    bankChangeOption,
    dateOfBankChange,
  } = useAppSelector((state) => state.onboarding.bankAccount);
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.bankAccount,
  );

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | RadioChangeEvent,
  ): void => {
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;

    if (fieldName === 'hasBankAccount' && !fieldValue) {
      dispatch(clearOnboardinBankAccount());
      dispatch(clearOnboardingErrorsValidation());
    }

    if (fieldName === 'hasIBAN' && !fieldValue) {
      dispatch(updateOnboardingBankAccount({ bankAccountDetails: '' }));
      dispatch(updateOnboardingBankAccount({ iban: '' }));
      dispatch(updateOnboardingBankAccount({ swift: '' }));
    }

    if (fieldName === 'bankChangeOption' && !fieldValue) {
      dispatch(updateOnboardingBankAccount({ dateOfBankChange: '' }));
      dispatch(clearOnboardingErrorsValidation());
    }

    dispatch(updateOnboardingBankAccount({ [fieldName]: fieldValue }));
  };

  const onChangeDropdown =
    (fieldName: string) =>
    (data: any): void => {
      dispatch(updateOnboardingBankAccount({ [fieldName]: data }));
    };

  const onChangeDate =
    (fieldName: string) =>
    (date: Date | null): void => {
      const value = date ? moment(date)?.format(commonYearDateFormat) : '';
      dispatch(updateOnboardingBankAccount({ [fieldName]: value }));
    };

  const getError = (fieldName: string): string =>
    errorMessages[fieldName] ?? '';

  return (
    <Row gutter={[32, 0]} className="bank-account-tab my-information-tab">
      <BankAccountTabDetailsSection
        onChangeDropdown={onChangeDropdown}
        onChange={onChange}
        getError={getError}
        values={{
          hasIBAN,
          bankAccountDetails,
          iban,
          swift,
          currency,
          accountOwner,
          address,
          additionalAddress,
          comment,
          hasBankAccount,
        }}
      />

      <BankAccountTabBankDetailsSection
        onChangeDate={onChangeDate}
        onChangeDropdown={onChangeDropdown}
        onChange={onChange}
        getError={getError}
        values={{
          bankName,
          bankAdditionalAddress,
          bankAddress,
          bankCountry,
          npa,
          bankCity,
          bankChangeOption,
          dateOfBankChange,
          hasBankAccount,
        }}
      />
    </Row>
  );
};

export default BankAccountTab;
