import { FC, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getLeadById } from '../../redux/reducers/waiting-room/asyncThunkActions';

import './ViewLeadPage.less';
import WorkInfoViewComponent from './components/WorkInfoViewComponent';
import { clearSelectedLead } from '../../redux/reducers/waiting-room/sliceReducer';
import PersonalInfoViewerComponent from './components/PersonalInfoViewerComponent';

const ViewLeadPage: FC = () => {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();

  const { leadDetails } = useAppSelector(
    (state) => state.waitingRoom.selectedLead,
  );

  const {
    swissWorkPermit,
    permitType,
    salaryRateNoVAT,
    salaryType,
    currency,
    workingDaysHours,
    comments,
    notes,
    firstName,
    lastName,
    email,
    phone,
    howOld,
    countryResident,
    countryWorkResident,
    cantonLiveResident,
    cantonWorkResident,
    maritalStatus,
    isSpouseWork,
    numberOfDependantChildren,
    nationality,
    numberOfDependantTeenagers,
    dateOfBirth,
  } = leadDetails;

  useEffect(() => {
    dispatch(getLeadById(leadId!));

    return () => {
      dispatch(clearSelectedLead());
    };
  }, []);

  return (
    <div className="view-lead-page">
      <Row gutter={[32, 0]}>
        <PersonalInfoViewerComponent
          inputValues={{
            firstName,
            lastName,
            email,
            phone,
            howOld,
            countryResident,
            countryWorkResident,
            cantonLiveResident,
            cantonWorkResident,
            maritalStatus,
            isSpouseWork,
            numberOfDependantChildren,
            nationality,
            numberOfDependantTeenagers,
            dateOfBirth,
          }}
        />
        <WorkInfoViewComponent
          inputValues={{
            swissWorkPermit,
            permitType,
            salaryRateNoVAT,
            salaryType,
            currency,
            workingDaysHours,
            comments,
            notes,
          }}
        />
      </Row>
    </div>
  );
};

export default ViewLeadPage;
