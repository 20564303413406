import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { useEffect, useState } from 'react';

interface IProps {
  tootltipTitle: string;
  children: React.ReactNode;
  columnWidth: number;
  placement?: TooltipPlacement;
}

const TableTooltip = (props: IProps) => {
  const { tootltipTitle, children, columnWidth, placement } = props;
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    //NOTE: change 8 for changing font size (8 for 14px)
    setIsOverflowing(8 * tootltipTitle?.length > columnWidth - 20);
  }, []);

  if (!isOverflowing) return <>{children}</>;

  return (
    <Tooltip title={tootltipTitle} placement={placement || 'top'}>
      {children}
    </Tooltip>
  );
};

export default TableTooltip;
