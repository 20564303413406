import { FC, useEffect } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { Modal, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionMenu from './components/ActionMenu';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  changeEmployeeSorting,
  getEmployees,
} from '../../redux/reducers/employees/asyncThunkActions';
import {
  changeEmployeeFiltering,
  updateOpenModalInvite,
  updateOpenModalNewEmployee,
} from '../../redux/reducers/employees/sliceReducer';
import AddNewEmployeesModalContent from './components/AddNewEmployeesModalContent';

import './EmployeesPage.less';
import ModalDuplicateInviteContent from './components/ModalDuplicateInviteContent';
import { IEmployeeDto } from '../../common/api/services/UserApi/types/IEmployeeDto';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import TableTooltip from '../../components/common-table/table-tooltip';
import FormatPhoneNumber from '../../components/format-phone-number/FormatPhoneNumber';

const EmployeesPage: FC = () => {
  const {
    content: employeesList,
    pagination,
    filter,
    sort,
  } = useAppSelector((state) => state.employees.employeesList);
  const { isOpenModalNewEmployee, inviteLink, isOpenModalInvite } =
    useAppSelector((state) => state.employees);
  const { totalElement, currentPage, perPage } = pagination;
  const { link: invitedUrl, userEmail } = inviteLink;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getEmployees({ currentPage, perPage }));
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeEmployeeFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => dispatch(getEmployees({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const onChange: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeEmployeeSorting(sortBy as string));
    }
  };

  const columns: ColumnsType<IEmployeeDto> = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.firstName as string) ?? '',
      })('firstName'),
      sorter: true,
      width: 250,
      sortDirections: ['descend'],
      className: sortClassName('firstName'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={250}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.lastName as string) ?? '',
      })('lastName'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      className: sortClassName('lastName'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={250}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.email as string) ?? '',
      })('email'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={250}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phone',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.phone as string) ?? '',
      })('phone'),
      sorter: true,
      width: 250,
      sortDirections: ['descend'],
      className: sortClassName('phone'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={250}>
          <FormatPhoneNumber number={text} />
        </TableTooltip>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.role as string[]) ?? '',
        fieldFilterName: 'role',
      })(['admin', 'manager']),
      className: `role-td ${sortClassName('role')}`,
      key: 'role',
      width: 200,
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td ${sortClassName('status')}`,
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      render: (text: string) => {
        return <span className={text}>{text}</span>;
      },
    },
    // {
    //     title: 'Last log in',
    //     dataIndex: 'logIn',
    //     key: 'logIn',
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 80,
      render: (_, { id, status }) => {
        return (
          <SimpleDropdown
            options={<ActionMenu employeeId={id} status={status} />}>
            <EditActionIcon className="edit-action-icon" />
          </SimpleDropdown>
        );
      },
    },
  ];

  const newList = employeesList.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={newList}
        columns={columns}
        onChange={onChange}
      />

      {isOpenModalNewEmployee && (
        <Modal
          width={400}
          title="Invitation link"
          open={isOpenModalNewEmployee}
          onCancel={() => dispatch(updateOpenModalNewEmployee(false))}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="employees-modal-window-wrapper">
          <AddNewEmployeesModalContent />
        </Modal>
      )}

      {isOpenModalInvite && (
        <Modal
          width={600}
          title="Invitation link"
          open={isOpenModalInvite}
          onCancel={() => dispatch(updateOpenModalInvite(false))}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="employees-modal-window-wrapper">
          <ModalDuplicateInviteContent
            email={userEmail}
            invitedUrl={invitedUrl}
          />
        </Modal>
      )}
    </>
  );
};

export default EmployeesPage;
