import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import { batch } from 'react-redux';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import {
  setConsultantList,
  setConsultantsListPagination,
  setConsultantsListSorting,
  setIsShowConsultantModal,
  updateConsultantById,
} from './sliceReducer';
import {
  clearOnboardingErrorsValidation,
  clearOnboardingState,
  setOnboardingErrorValidation,
} from '../onboarding/sliceReducer';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';

export const getConsultantsList = createAsyncThunk(
  'consultants/getConsultantList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const currentPagination = (getState() as IRootState).consultants
      .consultantsList.pagination;
    const currentFilter = (getState() as IRootState).consultants.consultantsList
      .filter;
    const currentSort = (getState() as IRootState).consultants.consultantsList
      .sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await consultantApi.getConsultants(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setConsultantList(response));
        dispatch(setConsultantsListPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeConsultantListSorting = createAsyncThunk(
  'consultants/changeConsultantListSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).consultants
      .consultantsList.sort;

    if (currentSortBy !== sortBy) {
      dispatch(setConsultantsListSorting([currentSortBy, 'asc']));
      dispatch(getConsultantsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setConsultantsListSorting([currentSortBy, 'desc']));
      dispatch(getConsultantsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setConsultantsListSorting(['', '']));
      dispatch(getConsultantsList({ currentPage: 1 }));
      return;
    }
  },
);

export const createConsultant = createAsyncThunk(
  'consultants/createConsultant',
  async (_, { dispatch, getState }) => {
    dispatch(clearOnboardingErrorsValidation());
    const { firstName, lastName, phone, email } = (getState() as IRootState)
      .onboarding.personalInfo;

    const query = { firstName, lastName, phone, email };

    const { isSuccess, data } = await consultantApi.createConsultant(query);

    if (isSuccess) {
      const { id, ...restData } = data;
      dispatch(setIsShowConsultantModal(false));
      dispatch(clearOnboardingState());
      dispatch(getConsultantsList({ currentPage: 1 }));
      openSuccessNotification('Consultant was created');
    } else {
      if (typeof data === 'string') {
        openErrorNotification(data);
      }
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'personalInfo',
          fieldValue: data as Record<string, string>,
        }),
      );
    }
  },
);

export const updateApproveFinanceConsultant = createAsyncThunk(
  'consultants/updateApproveFinanceConsultant',
  async (consultantId: string, { dispatch }) => {
    const response = await consultantApi.approveFinanceConsultantByConsultantId(
      consultantId,
    );

    if (response) {
      dispatch(updateConsultantById(response));
    }
  },
);

export const updateApproveAdminConsultant = createAsyncThunk(
  'consultants/updateApproveAdminConsultant',
  async (consultantId: string, { dispatch }) => {
    const response = await consultantApi.approveAdminConsultantByConsultantId(
      consultantId,
    );

    if (response) {
      dispatch(updateConsultantById(response));
    }
  },
);

export const exportToExelConsultant = createAsyncThunk(
  'consultants/exportToExelMonitoring',
  async (_, { getState }) => {
    const { consultantsList } = (getState() as IRootState).consultants;
    const { sort, filter, selectedConsultants } = consultantsList;

    await consultantApi.exportToExel(filter, sort, selectedConsultants);
  },
);
