import { ApiServiceBase } from '../../ApiServiceBase';
import { MonitoringRoutes } from './enums/MonitoringRoutes';
import { IGetMonitoringListRequest } from './types/IGetMonitoringListRequest';
import { ISort } from '../../../../redux/types';
import { getQueryParams } from '../../utils/getQueryParams';
import { IGetListDto } from '../../types/IGetListDto';
import { downloadExel } from '../../utils/downloadExel';

const { consultants, notes: notesPath, client } = MonitoringRoutes;

class MonitoringApi extends ApiServiceBase {
  baseUrl = '/api/v1/monitoring';

  getMonitoringList(
    currentPage: number,
    perPage: number,
    filter: Record<string, string[] | string | boolean>,
    sort: ISort,
  ): Promise<IGetListDto<IGetMonitoringListRequest> | null> {
    return this.get<IGetListDto<IGetMonitoringListRequest>>(`/${consultants}`, {
      query: {
        page: currentPage,
        perPage,
        ...getQueryParams(filter, sort),
      },
    })
      .then((response) => response)
      .catch(() => null);
  }

  saveMonitoringNotes(consultantId: string, clientId: string, notes: string) {
    return this.put<{ notes: string }>(
      `/${consultants}/${consultantId}/${client}/${clientId}/${notesPath}`,
      { notes },
    )
      .then((response) => response)
      .catch(() => null);
  }

  finishContract(consultantId: string, companyId: string) {
    return this.put(
      `/${consultants}/${consultantId}/project/${companyId}/finished`,
    );
  }

  renewContract(consultantId: string, companyId: string) {
    return this.put(
      `/${consultants}/${consultantId}/project/${companyId}/renew`,
    );
  }

  exportToExel(
    filter: Record<string, string | string[]>,
    sort: ISort,
    selectedIds: string[],
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    return this.get<any>(`/${consultants}/${MonitoringRoutes.export}`, {
      query,
      responseType: 'blob',
    })
      .then((response) => downloadExel(response))
      .catch(() => null);
  }
}

export const monitoringApi = new MonitoringApi();
