import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const validation = yupResolver(
  yup.object({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
);
