import { ChangeEvent, useEffect, useState } from 'react';
import { Input, Space } from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import MagnifierIcon from '../../assets/icons/MagnifierIcon';
import Button from '../../components/button/Button';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (payload: Record<string, string>) => void;
  defaultValue: string;
  searchAsString?: boolean;
}

export const useTableLocalSearchFilter = ({
  fetchRequest,
  changeValues,
  defaultValue,
  searchAsString = true,
}: IArguments) => {
  const [searchValue, setSearchValue] = useState(defaultValue.replace('~', ''));
  const [isFiltered, setIsFiltered] = useState(false);
  const [newValue, setNewValue] = useState({});

  useEffect(() => {
    if (!!defaultValue) {
      setIsFiltered(true);
    }

    if (!defaultValue) {
      setIsFiltered(false);
      setSearchValue('');
    }
  }, [defaultValue]);

  const onChange =
    (dataIndex: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSearchValue(event.target.value);
      if (
        dataIndex === 'saleName' ||
        dataIndex === 'clientName' ||
        dataIndex === 'agency' ||
        dataIndex === 'salary' ||
        dataIndex === 'billRate' ||
        dataIndex === 'timesheetsStandardTimeTotal' ||
        dataIndex === 'timesheetsOvertimeTotal' ||
        dataIndex === 'timesheetsDoubleTimeTotal' ||
        dataIndex === 'expensesClientTotal' ||
        dataIndex === 'expensesOptimizationTotal' ||
        dataIndex === 'ssn' ||
        dataIndex === 'annualSalaryAmount' ||
        dataIndex === 'lppLevel' ||
        dataIndex === 'projectDescription' ||
        dataIndex === 'name' ||
        dataIndex === 'role' ||
        dataIndex === 'emailApproval' ||
        dataIndex === 'publicId' ||
        dataIndex === 'street' || //TODO
        dataIndex === 'postCode' || //TODO
        dataIndex === 'city' || //TODO
        dataIndex === 'country' || //TODO
        dataIndex === 'countryCode' || //TODO
        dataIndex === 'paymentTermRange' || //TODO
        dataIndex === 'consultant' || //TODO
        dataIndex === 'documentName' || //TODO
        dataIndex === 'uploadedBy' || //TODO
        dataIndex === 'notes' || //TODO
        !searchAsString
      ) {
        setNewValue({ [dataIndex]: event.target.value.trim() });
      } else {
        setNewValue({ [dataIndex]: '~' + event.target.value.trim() });
      }
    };

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (searchValue !== '') {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    changeValues(newValue);
    confirm();
    fetchRequest();
  };

  const onClear = (
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string,
  ): void => {
    changeValues({ [dataIndex]: '' });
    fetchRequest();
    confirm();
    setSearchValue('');
    setIsFiltered(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dataIndex: string): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-search-filter-dropdown">
        <div className="icon-wrapper">
          <Input
            placeholder="Search"
            value={searchValue}
            onChange={onChange(dataIndex)}
            onPressEnter={() => onSubmit(confirm)}
          />
          <MagnifierIcon />
        </div>
        <Space className="button-wrapper">
          <button
            className={`${
              isFiltered ? '__filtered reset-filters' : 'reset-filters'
            }`}
            onClick={() => onClear(confirm, dataIndex)}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <MagnifierIcon className={`${isFiltered ? '__filtered' : ''}`} />
    ),
  });
};
