import { FC } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { getDefaultFormatDate } from '../../../common/utils/getFormatDate';
import DocumentsViewer from '../components/DocumentsViewer';
const PersonalInfoTab: FC = () => {
  const {
    firstName,
    lastName,
    phone,
    email,
    dateOfBirth,
    gender,
    language,
    nationality,
    maritalStatus,
    maritalStatusDetailsSpouseStartedWorkingFrom,
    maritalStatusDetailsSpouseWorkCanton,
    maritalStatusDetailsSpouseWorkCity,
    maritalStatusDetailsSpouseWorkCountry,
    maritalStatusDetailsSpousalIncome,
    maritalStatusDetailsSpouseSSN,
    maritalStatusDetailsIsSpouseSwissResident,
    maritalStatusDetailsSpouseNationality,
    maritalStatusDetailsSpouseDateOfBirth,
    maritalStatusDetailsSpouseLastName,
    maritalStatusDetailsSpouseName,
    maritalStatusDetailsMarriageDate,
    hasSwissAddress,
    swissAddressStreet,
    swissAddressCity,
    swissAddressPostCode,
    hasAbroadAddress,
    abroadAddressStreet,
    abroadAddressPostCode,
    abroadAddressCity,
    abroadAddressCountry,
    abroadAddressTotalAttendance,
    documents,
    workForm,
  } = useAppSelector((state) => state.onboarding.personalInfo);
  return (
    <Row className="my-information-tab">
      <Col span={12}>
        <h4>Personal details</h4>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">First name</span>
              <span className="item-value">{firstName}</span>
            </div>
          </Col>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Last name</span>
              <span className="item-value">{lastName}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Phone number</span>
              <span className="item-value">{phone}</span>
            </div>
          </Col>
          <Col span={16}>
            <div className="personal-info">
              <span className="item-label">Email</span>
              <span className="item-value">{email}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Date of birth</span>
              <span className="item-value">
                {getDefaultFormatDate(dateOfBirth)}
              </span>
            </div>
          </Col>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Gender</span>
              <span className="item-value">{gender}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Language</span>
              <span className="item-value">{language}</span>
            </div>
          </Col>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Nationality</span>
              <span className="item-value">{nationality}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="personal-info">
              <span className="item-label">Marital status</span>
              <span className="item-value">{maritalStatus}</span>
            </div>
          </Col>
          {maritalStatus === 'Married' && (
            <Col span={8}>
              <div className="personal-info">
                <span className="item-label">Marriage date</span>
                <span className="item-value">
                  {getDefaultFormatDate(maritalStatusDetailsMarriageDate)}
                </span>
              </div>
            </Col>
          )}
        </Row>

        {maritalStatus === 'Married' && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Spouse name</span>
                  <span className="item-value">
                    {maritalStatusDetailsSpouseName}
                  </span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Spouse last name</span>
                  <span className="item-value">
                    {maritalStatusDetailsSpouseLastName}
                  </span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Spouse date of birth</span>
                  <span className="item-value">
                    {getDefaultFormatDate(
                      maritalStatusDetailsSpouseDateOfBirth,
                    )}
                  </span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Spouse nationality</span>
                  <span className="item-value">
                    {maritalStatusDetailsSpouseNationality}
                  </span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">
                    Does your spouse live in Switzerland?
                  </span>
                  <span className="item-value">
                    {maritalStatusDetailsIsSpouseSwissResident ? 'Yes' : 'No'}
                  </span>
                </div>
              </Col>
            </Row>

            {maritalStatusDetailsIsSpouseSwissResident && (
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Spouse AVS number</span>
                    <span className="item-value">
                      {maritalStatusDetailsSpouseSSN
                        ? maritalStatusDetailsSpouseSSN
                        : '-'}
                    </span>
                  </div>
                </Col>
              </Row>
            )}

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">
                    Does your spouse receive an income?
                  </span>
                  <span className="item-value">
                    {maritalStatusDetailsSpousalIncome ? 'Yes' : 'No'}
                  </span>
                </div>
              </Col>
            </Row>

            {maritalStatusDetailsSpousalIncome && (
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Country of working</span>
                    <span className="item-value">
                      {maritalStatusDetailsSpouseWorkCountry}
                    </span>
                  </div>
                </Col>

                {maritalStatusDetailsSpouseWorkCountry === 'Switzerland' && (
                  <Col span={8}>
                    <div className="personal-info">
                      <span className="item-label">Working canton</span>
                      <span className="item-value">
                        {maritalStatusDetailsSpouseWorkCanton}
                      </span>
                    </div>
                  </Col>
                )}

                {maritalStatusDetailsSpouseWorkCountry !== 'Switzerland' && (
                  <Col span={8}>
                    <div className="personal-info">
                      <span className="item-label">Working city</span>
                      <span className="item-value">
                        {maritalStatusDetailsSpouseWorkCity}
                      </span>
                    </div>
                  </Col>
                )}

                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Start date</span>
                    <span className="item-value">
                      {getDefaultFormatDate(
                        maritalStatusDetailsSpouseStartedWorkingFrom,
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="personal-info">
              <span className="item-label">Work Type</span>
              <span className="item-value capitalize">{workForm}</span>
            </div>
          </Col>
        </Row>

        <h4 className="others-title">Address</h4>

        {hasSwissAddress && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Street name and number</span>
                  <span className="item-value">{swissAddressStreet}</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Postcode</span>
                  <span className="item-value">{swissAddressPostCode}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">City</span>
                  <span className="item-value">{swissAddressCity}</span>
                </div>
              </Col>
            </Row>
          </>
        )}

        {hasAbroadAddress && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Street name and number</span>
                  <span className="item-value">{abroadAddressStreet}</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Postcode</span>
                  <span className="item-value">{abroadAddressPostCode}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">City</span>
                  <span className="item-value">{abroadAddressCity}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Country</span>
                  <span className="item-value">{abroadAddressCountry}</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">
                    How often do you visit this address?
                  </span>
                  <span className="item-value">
                    {abroadAddressTotalAttendance}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}

        <h4 className="others-title">Documents</h4>

        <DocumentsViewer documents={documents} />
      </Col>
    </Row>
  );
};

export default PersonalInfoTab;
