import { FC, useEffect, useState } from 'react';
import { Modal, Select, TableProps } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  assignManagerOnLead,
  changeLeadListSorting,
  getLeadsList,
  getSalesList,
} from '../../redux/reducers/waiting-room/asyncThunkActions';
import CommonTable from '../../components/common-table/CommonTable';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import { useTableDateFilter } from '../../common/hooks/useTableDateFilter';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import ActionMenu from './components/ActionMenu';
import ModalInviteContent from './components/ModalInviteContent';
import { ILeadDetails, TActionOption } from './types';
import {
  changeIsShowDetails,
  changeLeadListFiltering,
  clearSelectedLead,
  putSelectedLeadDetails,
} from '../../redux/reducers/waiting-room/sliceReducer';

import './WaitingRoomPage.less';
import ModalArchiveContent from './components/ModalArchiveContent';
import { getFormatDateWithTime } from '../../common/utils/getFormatDate';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import TableTooltip from '../../components/common-table/table-tooltip';
import { Statuses } from '../../common/enums/Statuses';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { leadStatusesArray } from '../../common/constants/statuses';
import { leadApi } from '../../common/api/services/LeadApi/leadApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import moment from '../../common/constants/moment';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';

const { Option } = Select;
const { waitingRoom, edit, view } = ROUTER_PATHS;

const WaitingRoomPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpenModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<TActionOption>('archive');
  const { leadList, selectedLead } = useAppSelector(
    (state) => state.waitingRoom,
  );
  const { saleList } = useAppSelector((state) => state.waitingRoom.leadList);
  const { content: contentLeadList, pagination, sort, filter } = leadList;
  const { totalElement, currentPage, perPage } = pagination;

  const isModalArchive = 'archive' === modalType;

  useEffect(() => {
    dispatch(getLeadsList());
    dispatch(getSalesList());
  }, [dispatch]);

  const sendInvite = (leadId: string) => {
    leadApi.sendInviteToLead(leadId).then((response) => {
      dispatch(getLeadsList());
      if (typeof response === 'string') {
        openErrorNotification(response);
      } else {
        openSuccessNotification('Consultant was created');
      }
    });
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getLeadsList({ currentPage, perPage }));
  };

  const showModal = (leadId: string, action: TActionOption) => (): void => {
    const leadDetails = contentLeadList.find((lead) => lead._id === leadId);

    if (leadDetails && action) {
      dispatch(putSelectedLeadDetails(leadDetails));
    }

    setOpenModal(true);
    setModalType(action);
  };

  const handleCancel = () => {
    setOpenModal(false);
    dispatch(getLeadsList());
    dispatch(clearSelectedLead());
  };

  const handleChange = (currentLeadId: string) => (managerId: string) => {
    dispatch(assignManagerOnLead([currentLeadId, managerId]));
  };

  const onChange: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      const sortBy = (params[2] as SorterResult<any>).field;
      const sortName = sortBy === 'saleName' ? 'manager.firstName' : sortBy;
      dispatch(changeLeadListSorting(sortName as string));
    }
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const changeFilter = (date: any): void => {
    dispatch(changeLeadListFiltering(date));
  };

  const dataFilter = useTableDateFilter({
    fetchRequest: () => dispatch(getLeadsList({ currentPage: 1 })),
    changeValues: changeFilter,
    values: filter,
  })();
  const searchFilterArg = {
    fetchRequest: () => dispatch(getLeadsList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  const columns: ColumnsType<ILeadDetails> = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.firstName ?? '',
      })('firstName'),
      sorter: true,
      sortDirections: ['descend'],
      className: sortClassName('firstName'),
      width: 150,
      fixed: 'left',
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.lastName ?? '',
      })('lastName'),
      sorter: true,
      className: sortClassName('lastName'),
      sortDirections: ['descend'],
      width: 150,
      showSorterTooltip: false,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td ${sortClassName('status')}`,
      sorter: true,
      sortDirections: ['descend'],
      width: 133,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.status as unknown as string[]) ?? [],
        fieldFilterName: 'status',
      })(leadStatusesArray),
      showSorterTooltip: false,
      render: (text: string) => {
        return <span className={text}>{text}</span>;
      },
    },
    {
      title: 'Sales',
      dataIndex: 'saleName',
      key: 'saleName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.saleName ?? '',
      })('saleName'),
      sorter: true,
      className: sortClassName('manager.firstName'),
      sortDirections: ['descend'],
      showSorterTooltip: false,
      width: 180,
      render: (text, { key }) => {
        return (
          <Select
            value={text}
            className="sales-dropdown"
            onChange={handleChange(key)}
            bordered={false}
            popupClassName="sales-dropdown-body">
            {saleList.map(({ id, firstName, lastName }) => {
              return (
                <Option key={id} value={id}>
                  {firstName} {lastName}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: 'Date and time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 170,
      ...dataFilter,
      sorter: true,
      className: sortClassName('createdAt'),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={170}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'countryResident',
      key: 'countryResident',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.countryResident ?? '',
      })('countryResident'),
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      className: `cut-text ${sortClassName('countryResident')}`,
      width: 170,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={170}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Permit',
      dataIndex: 'permitType',
      key: 'permitType',
      className: `cut-text ${sortClassName('permitType')}`,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      width: 107,
    },
    {
      title: 'Rate',
      dataIndex: 'salaryRateNoVAT',
      key: 'rate',
      sorter: true,
      className: sortClassName('salaryRateNoVAT'),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      width: 123,
      render: (text: string, { currency }) => (
        <TableTooltip tootltipTitle={text} columnWidth={123}>
          {`${currency} ${text}`}
        </TableTooltip>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      className: 'cut-text',
      width: 216,
      render: (text: string) => (
        <TableTooltip
          tootltipTitle={text}
          columnWidth={216}
          placement="bottomLeft">
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Consultant created',
      dataIndex: 'consultantCreated',
      key: 'consultantCreated',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['consultantCreated']
          ? (filter['consultantCreated'] as string)
          : '',
        field: 'consultantCreated',
      })(),
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      className: sortClassName('consultantCreated'),
      width: 200,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 80,
      fixed: 'right',
      render: (_, { key: leadId, status }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionMenu
                  leadId={leadId}
                  showModalArchive={showModal(leadId, 'archive')}
                  status={status}
                  sendInvite={sendInvite}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
    },
  ];

  const data = contentLeadList.map((item) => ({
    key: item._id,
    firstName: item.firstName,
    lastName: item.lastName,
    status: item.status,
    createdAt: getFormatDateWithTime(item.createdAt),
    saleName: item?.manager?.userId ?? 'No',
    countryResident: item.countryResident,
    permitType: item.permitType,
    salaryRateNoVAT: item.salaryRateNoVAT,
    currency: item.currency,
    invitationDate: getFormatDateWithTime(item.invitationDate ?? ''),
    comment: item.comments,
    consultantCreated: item.consultantCreated
      ? moment(item.consultantCreated).format(commonMonthTimeFormat)
      : 'No',
  }));

  const onDoubleClick = (record: any) => {
    if (
      [
        Statuses.new,
        Statuses.archived,
        Statuses.failed,
        Statuses.duplicated,
      ].includes(record.status)
    ) {
      navigate(waitingRoom + edit + `/${record.key}`);
      return;
    }
    navigate(waitingRoom + view + `/${record.key}`);
    dispatch(changeIsShowDetails(true));
  };

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChange}
        onDoubleClick={onDoubleClick}
      />
      {isOpenModal && (
        <Modal
          width={600}
          title={isModalArchive ? 'Archive lead' : 'Invitation link'}
          open={isOpenModal}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper">
          {!isModalArchive && (
            <ModalInviteContent leadDetails={selectedLead.leadDetails!} />
          )}

          {isModalArchive && (
            <ModalArchiveContent
              leadDetails={selectedLead}
              hideModal={handleCancel}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default WaitingRoomPage;
