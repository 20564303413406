import { useState } from 'react';
import { IActivityReportColumn } from '../types';
import Input from '../../../components/input/Input';
import { employeePayslipsApi } from '../../../common/api/services/EmployeePayslips/employeePayslipsApi';
import { getActivityReportList } from '../../../redux/reducers/activity-report/asyncThunkActions';
import { useDispatch } from 'react-redux';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../ActivityReportPage.less';

interface IProps {
  salary: number | string;
  data: IActivityReportColumn;
}

const SalaryNetPaidRow = (props: IProps) => {
  const { salary, data } = props;
  const dispatch: any = useDispatch();
  const [isChangeMode, setIsChangeMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const changeModeOn = () => {
    setInputValue((salary || 0).toString());
    setIsChangeMode(true);
  };

  const onCancel = () => {
    if (isLoading) {
      return;
    }
    setIsChangeMode(false);
  };

  const onConfirm = () => {
    if (isLoading) {
      return;
    }
    if (!data.payslipsId) {
      const body = {
        salaryNetPaid: +inputValue || 0,
        consultantId: data.item.consultantId,
        period: data.period,
      };
      setIsLoading(true);
      employeePayslipsApi
        .updatePayslip(body)
        .then(() => {
          openSuccessNotification('Salary net paid was changed successful');
          dispatch(getActivityReportList());
        })
        .catch((e) => {
          openErrorNotification(e.message);
        })
        .finally(() => {
          setIsLoading(false);
          setIsChangeMode(false);
        });
      return;
    }
    const body = {
      salaryNetPaid: +inputValue || 0,
    };
    setIsLoading(true);
    employeePayslipsApi
      .updateSalaryNetPaid(data.payslipsId, body)
      .then(() => {
        openSuccessNotification('Salary net paid was changed successful');
        dispatch(getActivityReportList());
      })
      .catch((e) => {
        openErrorNotification(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        setIsChangeMode(false);
      });
  };

  if (isChangeMode) {
    return (
      <div className="change-mode-wrapper">
        <Input
          value={inputValue}
          onChange={onChangeInput}
          label=""
          inputType="number"
          disabled={isLoading}
          placeholder="Salary"
        />
        <CloseOutlined
          onClick={onCancel}
          style={{
            color: '#e02b1d',
            width: '10px',
            height: '10px',
            marginLeft: '10px',
          }}
        />
        <CheckOutlined
          onClick={onConfirm}
          style={{
            color: '#4e9262',
            width: '10px',
            height: '10px',
            marginLeft: '10px',
          }}
        />
      </div>
    );
  }

  return <span onClick={changeModeOn}>{salary || 'No'}</span>;
};

export default SalaryNetPaidRow;
