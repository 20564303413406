export const getPONumber = (invoice: any) => {
  const { origins, purchaseOrders } = invoice;

  const messages = {
    noPO: 'No PO was added',
    severalARs: 'Several ARs were added',
  };

  // Check if origins and purchaseOrders are empty
  if (
    !origins ||
    !purchaseOrders ||
    origins.length === 0 ||
    purchaseOrders.length === 0
  ) {
    return messages.noPO;
  }

  // High-priority rule: Check if purchaseOrders has only one object with only the poNumber property
  // NOTE: This rule is not mentioned in the requirements but it means that the PO number is directly added to the invoice
  if (
    purchaseOrders.length === 1 &&
    Object.keys(purchaseOrders[0]).length === 1
  ) {
    return purchaseOrders[0].poNumber;
  }

  const period = new Date(origins[0].period); // Assuming period is in "YYYY-MM-DD" format
  const periodMonth = period.getMonth();
  const periodYear = period.getFullYear();

  if (origins.length > 1) {
    // Case 5 to 9: If more than one AR is added to the invoice
    return messages.severalARs;
  } else if (origins.length === 1) {
    if (purchaseOrders.length === 0) {
      // Case 1: Only one AR and no PO numbers
      return messages.noPO;
    }

    // Filter the purchaseOrders to find relevant ones for the given period
    const relevantPOs = purchaseOrders.filter((po: any) => {
      const startDate = new Date(po.startDate);
      // If the purchase order is indefinite, set endDate to a far future date, otherwise convert it to a Date object
      const endDate =
        po.indefinite || !po.endDate
          ? new Date('9999-12-31')
          : new Date(po.endDate);
      // Check if the purchase order's period overlaps with the invoice period
      return (
        // Check if the purchase order starts before or within the invoice period
        (startDate.getFullYear() < periodYear ||
          (startDate.getFullYear() === periodYear &&
            startDate.getMonth() <= periodMonth)) &&
        // Check if the purchase order ends after or within the invoice period
        (endDate.getFullYear() > periodYear ||
          (endDate.getFullYear() === periodYear &&
            endDate.getMonth() >= periodMonth))
      );
    });

    if (relevantPOs.length === 0) {
      // Case 2 and 4: Only one AR and PO numbers but no relevant period
      return messages.noPO;
    }

    // Case 3: Only one AR and multiple PO numbers with relevant periods
    relevantPOs.sort(
      (a: any, b: any) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    );

    return relevantPOs[0].poNumber;
  }

  return messages.noPO;
};
