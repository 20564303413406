import { FC, useCallback, useState } from 'react';
import './UploadDropzone.less';
import DropzoneIcon from '../../assets/icons/DropzoneIcon';
import UploadedFileIcon from '../../assets/icons/UploadedFileIcon';
import CrossIcon from '../../assets/icons/CrossIcon';
import { useDropzone } from 'react-dropzone';
import { IUploadFileResponse } from '../../common/api/services/FileApi/types/IUploadFileResponse';
import { downloadFileByUrl } from '../../common/utils/downloadFileByUrl';
import { fileApi } from '../../common/api/services/FileApi/FileApi';

interface IProps {
  onSubmit: (files: File[]) => void;
  defaultFiles: IUploadFileResponse[];
  removeFile: (file: string) => void;
  disabled?: boolean;
  errorMessage?: string;
  isDownload?: boolean;
}

const UploadDropzone: FC<IProps> = ({
  onSubmit,
  defaultFiles,
  removeFile,
  disabled,
  errorMessage,
  isDownload,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onSubmit(acceptedFiles);
    setIsDragOver(false);
  }, []);

  const onDragOver = useCallback(() => {
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver,
    onDragLeave,
    disabled,
  });

  const downloadFile = (fileName: string) => {
    if (!isDownload || !fileName) {
      return;
    }
    fileApi.downloadFileByUrl(fileName);
  };

  return (
    <div className="dropzone-section">
      <div
        className="dropzone-wrapper"
        style={{ borderColor: isDragOver ? '#10132f' : '#abbed1' }}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="dropzone-content">
            <DropzoneIcon />

            <p className="main-text">
              To upload click or drag files to this area.
            </p>
            <p>Formats: PDF, JPG, JPEG, PNG.</p>
            <p>Max.size: 25 MB.</p>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className="error-message error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </div>
      )}

      {defaultFiles.map(({ originalFilename }) => (
        <div key={originalFilename} className="uploaded-file">
          <div>
            <UploadedFileIcon />
            <span
              onClick={() => fileApi.downloadFileByUrl(originalFilename)}
              className={`${isDownload ? 'pointer-link' : ''}`}>
              {originalFilename}
            </span>
          </div>

          <CrossIcon onClick={() => removeFile(originalFilename)} />
        </div>
      ))}
    </div>
  );
};

export default UploadDropzone;
