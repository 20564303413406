import { FC } from 'react';
import { Col, Layout, Row } from 'antd';
import SuccessMailActionIcon from '../../assets/icons/SuccessEmailActionIcon';
import './EmailResponsePage.less';
import FullLogoIcon from '../../assets/icons/FullLogoIcon';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import FailedMailActionIcon from '../../assets/icons/FailedMailActionIcon';

const { Footer } = Layout;
const { timesheets, approved, expired } = ROUTER_PATHS;

const EmailResponsePage: FC = () => {
  const [searchParams] = useSearchParams();
  const pathName = useLocation().pathname;
  const isExpired = pathName.includes(expired);

  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');

  const pageType = pathName.includes(timesheets) ? 'Timesheet' : 'Expenses';

  const actionType = pathName.includes(approved) ? 'approved' : 'rejected';

  const textElement = isExpired ? (
    <>
      <p>We cannot proceed your request.</p>
      <p>
        Please, check if you haven&rsquo;t clicked one of the buttons before.
      </p>
    </>
  ) : (
    <p>
      You have {actionType} the {pageType}{' '}
      {firstName && lastName ? `for ${firstName} ${lastName}` : ''}.
    </p>
  );

  const icon = isExpired ? <FailedMailActionIcon /> : <SuccessMailActionIcon />;

  return (
    <Layout className="email-response-page">
      <Layout>
        {icon}
        {textElement}
        <p style={{ marginTop: 50 }}>Thank you!</p>
        <p className="sub-text">
          If you have any questions, please,{' '}
          <a href="mailto:finance@numeriq.ch">contact Numeriq.</a>
        </p>
      </Layout>
      <Footer style={{ background: '#fff' }}>
        <Row>
          <Col span={8}>
            <p style={{ margin: '10px 0 15px 0' }}>
              <b>The independent Swiss payroll company</b>
            </p>
            <p>Phone: +41 44 506 4900 </p>
            <p>
              Address: Numeriq AG, Heinrichstrasse 267D, CH-8005 Zürich,
              Switzerland
            </p>
            <p>Company VAT: CHE-349.385.240</p>
          </Col>
          <Col span={8}>
            <Row justify="center">
              <FullLogoIcon className="full-logo-icon" />
            </Row>
          </Col>
          <Col span={8}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <p>Terms and conditions ◦ Privacy policy</p>
              <p>© 2022 Numeriq. All rights reserved</p>
            </div>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default EmailResponsePage;
