import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthWrapperBackground from '../../components/auth-wrapper-background/AuthWrapperBackground';
import AuthFormHead from '../../components/auth-form-head/AuthFormHead';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import AngleIcon from '../../assets/icons/AngleIcon';
import { useForm } from 'react-hook-form';

import './ForgotPasswordPage.less';

import { setAuthMessage } from '../../redux/reducers/auth/sliceReducer';
import { useAppDispatch } from '../../redux/store';
import { apiAuth } from '../../common/api/services/AuthApi/ApiAuth';

const { login } = ROUTER_PATHS;

const ForgotPasswordPage: FC = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [emailConfirmed, setEmailConfirmed] = useState('');
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    return () => {
      dispatch(setAuthMessage(''));
      setEmailConfirmed('');
    };
  }, []);

  const emailValue = watch().email;
  const emailErrorMessage = errors.email?.message;

  const onChangeEmailValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldValue = e.target.value;
    setValue('email', fieldValue);
  };

  const onSubmit = async ({ email }: { email: string }): Promise<void> => {
    const response = await apiAuth.requestForNewPassword(email);

    if (typeof response !== 'string') {
      setEmailConfirmed(email);
    } else {
      setErrorMessage('');
      setErrorMessage(response);
    }
  };

  const title = emailConfirmed ? 'Check your email' : 'Forgot password?';
  const description = emailConfirmed
    ? `We sent a reset link to ${emailConfirmed}`
    : 'Enter your email to receive a reset link.';

  return (
    <AuthWrapperBackground>
      <form className="forgot-password-page" onSubmit={handleSubmit(onSubmit)}>
        <AuthFormHead title={title} description={description} />
        {!emailConfirmed && (
          <>
            <Input
              value={emailValue}
              onChange={onChangeEmailValue}
              label="Email"
              inputType="email"
              name="email"
              errorMessage={emailErrorMessage || errorMessage}
            />

            <Button type="submit" buttonSize="normal" buttonStyle="primary">
              Reset password
            </Button>
          </>
        )}
        <div className="link-wrapper">
          <Link to={login} className="size-medium">
            <AngleIcon />
            Back to log in
          </Link>
        </div>
      </form>
    </AuthWrapperBackground>
  );
};

export default ForgotPasswordPage;
