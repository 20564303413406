import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IDocuments } from '../../common/api/services/DocumentsApi/types/IDocument';
import { documentsApi } from '../../common/api/services/DocumentsApi/documentsApi';
import {
  changeDocumentsFiltering,
  clearDocumentsFiltersAndSorts,
  setDocumentsList,
  setDocumentsPagination,
  setDocumentsSorting,
} from '../../redux/reducers/documents/sliceReducer';
import { openErrorNotification } from '../../components/notification/Notification';
import { ISort, TSortValue } from '../../redux/types';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { Spin, TableProps } from 'antd';
import moment from '../../common/constants/moment';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import DownloadCloudIcon from '../../assets/icons/DownloadCloudIcon';
import { downloadFileByUrl } from '../../common/utils/downloadFileByUrl';
import TableTooltip from '../../components/common-table/table-tooltip';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import CommonTable from '../../components/common-table/CommonTable';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import '../documents-page/DocumentsPage.less';
import { fileApi } from '../../common/api/services/FileApi/FileApi';
import { FilterMobileTypes } from '../../components/common-table/types';
import ResetFilterBtn from '../../components/resetFilterBtn/ResetFilterBtn';
import MobileLoader from '../../components/mobile-loader/MobileLoader';

const DocumentsConsultantPage = () => {
  const dispatch = useAppDispatch();
  const { documentsList } = useAppSelector((state) => state.documents);
  const { pagination, sort, filter } = documentsList;
  const { currentPage, perPage, totalElement } = pagination;
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el?.length !== 0);
  const [documents, setDocuments] = useState<IDocuments>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDocuments();
  }, [currentPage, perPage, filter]);

  const getDocuments = (currentSort?: ISort, currentFilter?: any) => {
    setIsLoading(true);
    documentsApi
      .getConsultantDocuments(
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      .then((response: any) => {
        dispatch(setDocumentsList(response));
        setDocuments(response.result);
      })
      .catch((e) => {
        openErrorNotification('Failed to getting documents');
      })
      .finally(() => setIsLoading(false));
  };
  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeDocumentsFiltering(date));
  };
  const changeMobileFilter = (
    date: Record<string, string | string[]>,
  ): void => {
    dispatch(changeDocumentsFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setDocumentsSorting(newSort));
      getDocuments({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };

  const onChangeMobileSort = (sort: { sortBy: string; sort: string }) => {
    getDocuments({ sortBy: sort.sortBy, sort: sort.sort as TSortValue });
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setDocumentsPagination({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };
  const data = documents.map((item) => {
    const { uploadDate, _id, notes, originalFilename, consultant } = item;
    const { _id: consultantId } = consultant;
    return {
      _id,
      uploadDate: uploadDate
        ? moment(uploadDate).format(commonMonthTimeFormat)
        : '',
      notes,
      documentName: originalFilename,
      originalFilename,
      consultantFileId: consultantId,
    };
  });

  const columns: any = [
    {
      title: 'Document name',
      dataIndex: 'documentName',
      key: 'documentName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.documentName as string) ?? '',
      })('documentName'),
      className: sortClassName('documentName'),
      sorter: true,
      width: 400,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          {text}
        </TableTooltip>
      ),
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.documentName,
    },
    {
      title: 'Upload date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'uploadDate',
        values: (filter?.uploadDate as string) ?? '',
      })(),
      className: sortClassName('uploadDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      filterType: FilterMobileTypes.dataRange,
      defaultFilter: filter?.uploadDate,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.notes as string) ?? '',
      })('notes'),
      className: sortClassName('notes'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip
          tootltipTitle={text}
          columnWidth={200}
          placement="bottomLeft">
          {text}
        </TableTooltip>
      ),
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.notes,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      className: sortClassName('action'),
      width: 170,
      render: (text: string, data: any) => {
        if (!data.originalFilename) {
          return null;
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              onClick={() =>
                fileApi.downloadFileByUrl(
                  `${data.consultantFileId}/${data.originalFilename}`,
                )
              }
              className="download-link">
              <DownloadCloudIcon style={{ width: '24px', height: '20px' }} />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="consultant-documents-page-container">
      <div className="header-section header-section-payslips">
        <Breadcrumb />
        <div className="right-header-section">
          {isHasFilters && (
            <button
              className="rest-filters-button"
              onClick={() => {
                dispatch(clearDocumentsFiltersAndSorts());
                getDocuments({ sort: '', sortBy: '' }, []);
              }}>
              <ResetFilterBtn />
            </button>
          )}
        </div>
      </div>
      <div className="container-payslips">
        <Spin spinning={isLoading} indicator={<MobileLoader />}>
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            onChange={onChangeSort}
            rowKey="_id"
            submitFilters={changeMobileFilter}
            sortBy={sort}
            onChangeMobileSort={onChangeMobileSort}
          />
        </Spin>
      </div>
    </div>
  );
};

export default DocumentsConsultantPage;
