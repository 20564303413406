import { Tabs } from 'antd';
import TimesheetExpensesTable from './tables/TimesheetExpensesTable';
import FinancialTable from './tables/FinancialTable';
import Button from '../../../components/button/Button';
import {
  IARPAyloadDTO,
  IARPayload,
} from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';

interface IProps {
  activityReportPayload: Array<IARPAyloadDTO>;
  isLoadingTimesheets: boolean;
  setIsOpenAddNew: (isOpen: boolean) => void;
  payloadInfo: IARPayload | null;
  setActiveTab: (tab: string) => void;
}

const MainTable = (props: IProps) => {
  const {
    activityReportPayload,
    isLoadingTimesheets,
    setIsOpenAddNew,
    payloadInfo,
    setActiveTab,
  } = props;
  const tabs = (
    <Tabs
      onTabClick={(tab) => setActiveTab(tab)}
      tabBarExtraContent={
        <Button
          buttonSize="small"
          buttonStyle="secondary"
          onClick={() => setIsOpenAddNew(true)}
          buttonWidth={'143px'}>
          + Create new
        </Button>
      }
      defaultActiveKey="1"
      items={[
        {
          label: `Timesheets and expenses`,
          key: '1',
          children: (
            <TimesheetExpensesTable
              activityReportPayload={activityReportPayload}
              isLoadingTimesheets={isLoadingTimesheets}
            />
          ),
        },
        {
          label: `Timesheet financial structure`,
          key: '2',
          disabled: !!!payloadInfo,
          children: <FinancialTable payloadInfo={payloadInfo} />,
        },
      ]}
    />
  );
  return <div className="main-table-container">{tabs}</div>;
};

export default MainTable;
