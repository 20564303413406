import { ChangeEvent, FC, useState, useRef } from 'react';
import CrossIcon from '../../assets/icons/CrossIcon';

import './Input.less';
import AttachFileIcon from '../../assets/icons/AttachFileIcon';

interface IProps {
  onChange:
    | ((e: ChangeEvent<HTMLInputElement>) => void)
    | ((value: string) => void);
  label: string;
  name?: string;
  errorMessage?: string;
  disabled?: boolean;
}

const Input: FC<IProps> = (props) => {
  const { label, name = '', onChange, errorMessage, disabled } = props;

  const [isFocusClass, setFocusClass] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const focusClass = isFocusClass ? 'focus-input' : '';
  const inputClassName = `typed input ${focusClass}`;
  const inputStatus = errorMessage ? 'error' : '';
  const labelErrorClass = inputStatus ? 'input-label-error' : '';
  const labelClass = `input-label file ${labelErrorClass} ${
    disabled ? '__disabled' : ''
  }`;

  const inputClassNameWithoutLabel = !label
    ? `${inputClassName} without-label`
    : inputClassName;

  return (
    <div className="input-label-wrapper">
      <label
        className={labelClass}
        htmlFor={`file_${disabled ? '_disabled' : name}`}>
        <span className={`attach-label${disabled ? ' disabled' : ''}`}>
          {label}
        </span>
        <input
          ref={ref}
          id={`file_${name}`}
          name={name}
          type="file"
          multiple
          onFocus={() => setFocusClass(true)}
          onBlur={() => setFocusClass(false)}
          onClick={() => {
            if (ref.current) ref.current.value = '';
          }}
          className={inputClassNameWithoutLabel}
          onChange={onChange as (e: ChangeEvent<HTMLInputElement>) => void}
          disabled={disabled}
          style={{ opacity: 0 }}
        />
        <AttachFileIcon style={{ opacity: disabled ? 0.3 : 1 }} />
      </label>
      {errorMessage && errorMessage !== ' ' && (
        <span className="error-message error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
