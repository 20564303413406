import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { ChangeEvent, FC } from 'react';
import Dropdown from '../../../components/dropdown/Dropdown';
import Input from '../../../components/input/Input';

import './ModalExpense.less';
import InputFile from '../../../components/input/InputFile';
import Textarea from '../../../components/textarea/Textarea';
import Button from '../../../components/button/Button';
import SaveIcon from '../../../assets/icons/SaveIcon';
import { expensesType } from '../../../common/utils/mockExpensesType';
import Checkbox from '../../../components/checkbox/Checkbox';
import UploadedFileIcon from '../../../assets/icons/UploadedFileIcon';
import CrossIcon from '../../../assets/icons/CrossIcon';
import { IConsultantExpenses } from '../../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import { ExpensesType } from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { SalaryType } from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import UploadDropzoneOverlay from '../../../components/upload-dropzone-overlay/UploadDropzoneOverlay';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  isUpLoading: boolean;
  selectedExpense: IConsultantExpenses;
  getError: (fieldName: string) => string;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onChangeCheckbox: (fieldName: 'isSigned' | 'approvalRequired') => () => void;
  onChangeFile: (e: ChangeEvent<HTMLInputElement> | File[]) => void;
  onRemoveAttachFile: (originalFilename: string) => void;
  onSaveAsDraft: () => void;
  onSubmit: () => void;
}
const ModalEditExpense: FC<IProps> = (props) => {
  const {
    isUpLoading,
    selectedExpense,
    getError,
    onChange,
    onChangeCheckbox,
    onChangeFile,
    onRemoveAttachFile,
    onSaveAsDraft,
    onSubmit,
  } = props;
  const {
    hasExpensesInThisMonth,
    workContract,
    formType,
    type,
    isSigned,
    approvalRequired,
    representativeFirstName,
    representativeLastName,
    period,
    total,
    documents,
    companyRepresentativeEmail,
    comments,
    clientFiles,
    numeriqComments,
  } = selectedExpense;

  const isShowInputBlock = isSigned || approvalRequired;

  const fileTemplate =
    type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
      ? 'Numeriq Monthly Meal allowance Report.xlsx'
      : type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
      ? 'Numeriq Expenses Report CHF.xlsx'
      : 'Numeriq Optimized Expenses Report CHF.xlsx';

  return (
    <div className="expence-modal-mobile">
      <UploadDropzoneOverlay onSubmit={onChangeFile} />

      <Row gutter={[16, 16]} justify="space-between">
        <Col span={16}>
          {type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
            ? 'Do you have any meal allowance expenses this month?'
            : type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
            ? 'Do you have any expenses this month?'
            : 'Do you have any expenses for optimization this month?'}
        </Col>
        <Col span={8}>
          <Radio.Group
            name="hasExpensesInThisMonth"
            value={hasExpensesInThisMonth}
            onChange={onChange}
            style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>

        {!hasExpensesInThisMonth && (
          <p className="info-message">
            If you don&apos;t have any expenses, you can skip the sections below
            and submit. After the submitting the status will be &quot;No
            expenses&quot;.
          </p>
        )}
      </Row>

      <Row gutter={[16, 16]} className="edit-timesheet-row">
        <Col span={12}>
          <Dropdown
            options={['Simplified', 'Detailed']}
            value={formType}
            onChange={() => null}
            label="Expenses form"
            showSearch={false}
            disabled
          />
        </Col>
        <Col span={12}>
          <Input
            label="Type of expenses"
            onChange={onChange}
            value={expensesType[type]}
            name="type"
            disabled
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="edit-timesheet-row">
        <Col span={12}>
          <Input
            label="Client name"
            onChange={onChange}
            value={
              type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
                ? workContract?.name || ''
                : 'Numeriq'
            }
            name="clientName"
            disabled
          />
        </Col>
        <Col span={12}>
          <Input
            label="Period"
            onChange={onChange}
            value={getFormatDateWithMonth(period)}
            name="period"
            disabled
          />
        </Col>
      </Row>

      <Row className="edit-timesheet-row">
        <Col span={12} style={{ padding: '0 8px 0 0' }}>
          <Input
            label="Total sum"
            onChange={onChange}
            value={`${total}`}
            name="total"
            errorMessage={getError('total')}
            disabled={!hasExpensesInThisMonth}
          />
        </Col>
        <Col span={12} style={{ padding: '0 8px' }}>
          <Input
            label="Currency"
            value={
              type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
              type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
                ? 'CHF'
                : workContract?.invoiceCurrency || ''
            }
            onChange={() => null}
            disabled
          />
        </Col>
      </Row>

      {type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT && (
        <>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
              <Checkbox
                className={approvalRequired ? 'checkbox-gray' : ''}
                label="My expenses has been Signed/Approved."
                checked={isSigned}
                onChange={onChangeCheckbox('isSigned')}
                disabled={!hasExpensesInThisMonth}
              />
            </Col>
          </Row>
          {SalaryType.PerMonth !== workContract?.type &&
            SalaryType.Project !== workContract?.type && (
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    className={isSigned ? 'checkbox-gray' : ''}
                    checked={approvalRequired}
                    label="My expenses requires manager's online approval."
                    onChange={onChangeCheckbox('approvalRequired')}
                    disabled={!hasExpensesInThisMonth}
                  />
                </Col>
              </Row>
            )}
        </>
      )}

      {(isShowInputBlock ||
        type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
        type === ExpensesType.EXPENSES_MEAL_ALLOWANCE) &&
        hasExpensesInThisMonth && (
          <>
            <Row gutter={[16, 16]} style={{ paddingBottom: 8 }}>
              <Col span={24}>
                <InputFile
                  label="Attach a file"
                  name="documents"
                  onChange={onChangeFile}
                  errorMessage={getError('documents') ? ' ' : ''}
                  disabled={!hasExpensesInThisMonth}
                />
              </Col>
            </Row>

            {!!documents.length && (
              <Row>
                <Col span={24}>
                  {documents.map(({ originalFilename }) => (
                    <div key={originalFilename} className="uploaded-file">
                      <div>
                        <UploadedFileIcon />
                        <span>{originalFilename}</span>
                      </div>

                      <CrossIcon
                        onClick={() => onRemoveAttachFile(originalFilename)}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            )}

            {fileTemplate &&
              clientFiles &&
              clientFiles.length > 0 &&
              clientFiles.map((file) => (
                <Row gutter={[16, 16]} key={file.originalFilename}>
                  <Col span={24}>
                    <p className="info-content">
                      <span
                        className="download-file-link"
                        onClick={() =>
                          fileApi.downloadFileByUrl(file.originalFilename)
                        }>
                        Download {file.originalFilename}
                      </span>
                    </p>
                  </Col>
                </Row>
              ))}

            {fileTemplate && (!clientFiles || clientFiles.length === 0) && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <p className="info-content">
                    <a
                      href={`/files/${fileTemplate}?v=1.0`}
                      download={`${fileTemplate}`}>
                      Download {fileTemplate}
                    </a>
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}

      {approvalRequired &&
        isShowInputBlock &&
        type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT && (
          <>
            <Row>
              <Col span={24}>
                <Input
                  label="Client representative email"
                  onChange={onChange}
                  value={companyRepresentativeEmail}
                  name="companyRepresentativeEmail"
                  errorMessage={getError('companyRepresentativeEmail')}
                  disabled={!hasExpensesInThisMonth}
                />
              </Col>
            </Row>

            <Row className="edit-timesheet-row">
              <Col span={12} style={{ paddingRight: 16 }}>
                <Input
                  label="Representative First Name"
                  onChange={onChange}
                  value={representativeFirstName}
                  name="representativeFirstName"
                  errorMessage={getError('representativeFirstName')}
                  disabled={!hasExpensesInThisMonth}
                />
              </Col>
              <Col span={12}>
                <Input
                  label="Representative Last Name"
                  onChange={onChange}
                  value={representativeLastName}
                  name="representativeLastName"
                  errorMessage={getError('representativeLastName')}
                  disabled={!hasExpensesInThisMonth}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Textarea
                  name="comments"
                  value={comments}
                  rows={3}
                  label="Comments for the Client (optional)"
                  onChange={onChange}
                  autoSize={false}
                  disabled={!hasExpensesInThisMonth}
                />
              </Col>
            </Row>
          </>
        )}

      {(isShowInputBlock ||
        type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
        type === ExpensesType.EXPENSES_MEAL_ALLOWANCE) &&
        hasExpensesInThisMonth && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Textarea
                name="numeriqComments"
                value={numeriqComments}
                rows={3}
                label="Comments for Numeriq (optional)"
                onChange={onChange}
                errorMessage={''}
                autoSize={false}
                disabled={!hasExpensesInThisMonth}
              />
            </Col>
          </Row>
        )}
      {type === ExpensesType.EXPENSES_MEAL_ALLOWANCE && (
        <Row>
          <Col span={24}>
            <b>
              Please refer to example to fill in correctly:{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href={`/files/Meal Allowance Report example.pdf?v=1.0`}
                download={`Meal Allowance Report example.pdf`}>
                Download Meal Allowance example
              </a>
            </b>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 0]} style={{ padding: '6px 0 8px 0' }}>
        {hasExpensesInThisMonth && (
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              leftIcon={
                <SaveIcon
                  className={`${
                    !isShowInputBlock &&
                    type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
                      ? 'disabled'
                      : ''
                  }`}
                />
              }
              onClick={onSaveAsDraft}
              disable={
                !isShowInputBlock &&
                type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
              }>
              Save as Draft
            </Button>
          </Col>
        )}
        <Col span={hasExpensesInThisMonth ? 12 : 24}>
          <Button
            buttonStyle="primary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={onSubmit}
            disable={
              (!isShowInputBlock &&
                type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT &&
                hasExpensesInThisMonth) ||
              isUpLoading
            }>
            Submit
          </Button>
        </Col>
      </Row>

      {getError('documents') && (
        <p className="error">
          You can&apos;t submit your Expenses if you don&apos;t attach any
          files.
        </p>
      )}
      {getError('') && <p className="error">{getError('')}</p>}
    </div>
  );
};

export default ModalEditExpense;
