export enum ExpensesStatus {
  DRAFT = 'Draft',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  PRE_APPROVED = 'Pre-approved',
  APPROVED = 'Approved',
  NO_EXPENSES = 'No expenses',
  EXPIRED = 'Expired',
  REJECTED = 'Rejected',
  CLIENT_APPROVAL = 'Client Approval',
}

export const ExpensesStatusesArray = [
  ExpensesStatus.APPROVED,
  ExpensesStatus.CLIENT_APPROVAL,
  ExpensesStatus.DRAFT,
  ExpensesStatus.EXPIRED,
  ExpensesStatus.NO_EXPENSES,
  ExpensesStatus.PRE_APPROVED,
  ExpensesStatus.REJECTED,
  ExpensesStatus.WAITING_FOR_APPROVAL,
];

export enum ExpensesType {
  EXPENSES_REIMBURSED_BY_CLIENT = 'EC',
  EXPENSES_FOR_OPTIMISATION = 'EO',
  EXPENSES_MEAL_ALLOWANCE = 'MA',
}

export enum ExpensesFormType {
  SIMPLIFIED = 'Simplified',
  DETAILED = 'Detailed',
}

export enum MainActivityType {
  EXPENSES_REIMBURSED_BY_CLIENT = 'EC',
  EXPENSES_FOR_OPTIMISATION = 'EO',
  EXPENSES_MEAL_ALLOWANCE = 'MA',
  TIMESHEET = 'T',
}
