import React from 'react';
import { IMobileFilter } from '../../types';
import Input from '../../../input/Input';
import MagnifierIcon from '../../../../assets/icons/MagnifierIcon';
import { commonDateMonthFormat } from '../../../../common/constants/dateConstants';
import CommonDatePicker from '../../../date-picker/CommonDatePicker';
import moment from '../../../../common/constants/moment';

interface IProps {
  filter: any;
  onChangeFilter: (value: string, key: string) => void;
}

const PeriodFilter = (props: IProps) => {
  const { filter, onChangeFilter } = props;
  return (
    <div className="icon-wrapper">
      <h4>{filter.title}</h4>
      <CommonDatePicker
        defaultValue={
          filter.defaultFilter
            ? moment(filter.defaultFilter).format(commonDateMonthFormat)
            : ''
        }
        changeDate={(date) =>
          onChangeFilter(
            moment(date).format(commonDateMonthFormat),
            filter.dataIndex,
          )
        }
        className="mobile-data"
        placeholder={'Period'}
        picker="month"
      />
    </div>
  );
};

export default PeriodFilter;
