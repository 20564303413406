import { store } from './store';
import { TAnyObject } from '../common/types/TAnyObject';

export type TSortValue = 'asc' | 'desc' | '' | string;

export interface IListDto<Content = [], Filters = TAnyObject> {
  content: Content[];
  pagination: IPagination;
  sort: ISort;
  filter: Filters;
}

export interface IPaginationRequest {
  currentPage?: number;
  perPage?: number;
}

export interface ISort {
  sort: TSortValue;
  sortBy: string;
}

export interface IPagination {
  totalElement: number;
  currentPage: number;
  perPage: number;
}

export type IRootState = ReturnType<typeof store.getState>;
export type IDispatch = typeof store.dispatch;

export enum ITableTypes {
  filter = 'filter',
  pagination = 'pagination',
  sort = 'sort',
}
