import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import {
  setIsLoading,
  setReportingList,
  setReportingPagination,
  setReportingSorting,
} from './sliceReducer';
import { reportingApi } from '../../../common/api/services/ReportingApi/reportingApi';
import { batch } from 'react-redux';

export const getReportingList = createAsyncThunk(
  'reporting/getReportingList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const state = getState() as IRootState;
    const currentPagination = state.reporting.reportingList.pagination;
    const currentFilter = state.reporting.reportingList.filter;
    const currentSort = state.reporting.reportingList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;
    dispatch(setIsLoading(true));
    const response = await reportingApi
      .getReporting(page, perPage, currentFilter, currentSort)
      .finally(() => dispatch(setIsLoading(false)));

    if (response) {
      batch(() => {
        dispatch(setReportingList(response));
        dispatch(setReportingPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const changeReportingSorting = createAsyncThunk(
  'reporting/changeReportingSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).reporting.reportingList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setReportingSorting([currentSortBy, 'asc']));
      dispatch(getReportingList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setReportingSorting([currentSortBy, 'desc']));
      dispatch(getReportingList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setReportingSorting(['', '']));
      dispatch(getReportingList({ currentPage: 1 }));
      return;
    }
  },
);

export const exportToExelReporting = createAsyncThunk(
  'reporting/exportToExelReporting',
  async (_, { getState }) => {
    const { reportingList, selectedToExport } = (getState() as IRootState)
      .reporting;
    const { sort, filter } = reportingList;
    await reportingApi.exportToExel(filter, sort, selectedToExport);
  },
);
