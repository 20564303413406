export const commonDateFormat = 'DD/MM/YYYY';
export const commonMonthFormat = 'MMMM/YYYY';
export const commonDateMonthFormat = 'MM/DD/YYYY';
export const commonYearDateFormat = 'YYYY-MM-DD';
export const commonMonthTimeFormat = 'DD MMM YYYY - HH:mm';
export const commonMonthYearSpaceFormat = 'MMMM YYYY';
export const commonMonthDateYearSpaceFormat = 'DD MMMM YYYY';
export const commonDateSpaceFormat = 'DD MMM YYYY';
export const commonDTOPeriod = 'YYYY-MM';
export const commonDateDotsFormat = 'DD.MM.YYYY';
export const commonDateDots = 'DD.MM.YY';
