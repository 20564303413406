import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import Dropdown from '../../../components/dropdown/Dropdown';
import currencies from '../../../common/constants/mockCurrencies';
import { systemInfoApi } from '../../../common/api/services/SystemInfoApi/SystemInfoApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { IBankAccount } from '../../../common/api/services/SystemInfoApi/types/ISystemInfo';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getBankAccounts: () => void;
  selectedBank?: IBankAccount;
  setSelectedBank?: (bank: IBankAccount | null) => void;
}

const AddBankAccountModal = (props: IProps) => {
  const { isOpen, setIsOpen, getBankAccounts, selectedBank, setSelectedBank } =
    props;
  const [data, setData] = useState({
    name: '',
    credit: '',
    iban: '',
    swift: '',
    currency: '',
  });
  const [errorMessages, setErrorMessages] = useState<any>(null);
  useEffect(() => {
    if (selectedBank) {
      setData(selectedBank);
    }
  }, []);

  const onChangeData = (value: string, key: string) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const getError = (errorField: string): string => {
    if (!errorMessages) {
      return '';
    }
    return errorMessages.find((item: any) => item?.property === errorField)
      ?.message;
  };

  const onSubmit = () => {
    selectedBank
      ? systemInfoApi
          .updateBankAccount(data as any)
          .then(() => {
            openSuccessNotification('Bank account updated successfully');
            setSelectedBank && setSelectedBank(null);
            getBankAccounts();
          })
          .catch((e) => {
            e?.response?.data?.messages?.length
              ? setErrorMessages(e?.response?.data?.messages)
              : openErrorNotification(e?.response?.data?.message);
          })
      : systemInfoApi
          .addBankAccount(data as any)
          .then(() => {
            openSuccessNotification('Bank account added successfully');
            setIsOpen(false);
            getBankAccounts();
          })
          .catch((e) => {
            e?.response?.data?.messages?.length
              ? setErrorMessages(e?.response?.data?.messages)
              : openErrorNotification(e?.response?.data?.message);
          });
  };
  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      width={700}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div className="bank-account-modal-container">
        <h4 style={{ marginBottom: '30px' }}>
          {selectedBank ? 'Edit' : 'Add'} bank account
        </h4>
        <Row>
          <Col span={24}>
            <Input
              label="Bank account name"
              onChange={(value: any) =>
                onChangeData(value.target.value, 'name')
              }
              value={data.name}
              errorMessage={getError('name')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              label="Bank credit"
              onChange={(value: any) =>
                onChangeData(value.target.value, 'credit')
              }
              value={data.credit}
              errorMessage={getError('credit')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              label="IBAN"
              onChange={(value: any) =>
                onChangeData(value.target.value, 'iban')
              }
              value={data.iban}
              errorMessage={getError('iban')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              label="BIC/SWIFT"
              onChange={(value: any) =>
                onChangeData(value.target.value, 'swift')
              }
              value={data.swift}
              errorMessage={getError('swift')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Dropdown
              label="Currency"
              onChange={(value) => onChangeData(value, 'currency')}
              options={currencies}
              errorMessage={getError('currency')}
              value={data.currency}
              disabled={!!selectedBank}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={() => {
                setIsOpen(false);
                setSelectedBank && setSelectedBank(null);
              }}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={onSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddBankAccountModal;
