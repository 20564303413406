import { FC } from 'react';
import { Col, Popover } from 'antd';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import DocumentIcon from '../../../../assets/icons/DocumentIcon';
import UploadDropzone from '../../../../components/upload-dropzone/UploadDropzone';
import { removeFileOnboardingChildAllowances } from '../../../../redux/reducers/onboarding/sliceReducer';
import { useAppDispatch } from '../../../../redux/store';
import { IUploadFileResponse } from '../../../../common/api/services/FileApi/types/IUploadFileResponse';
import { uploadFileOnboardingChildAllowances } from '../../../../redux/reducers/onboarding/tabs/childAllowancesAsyncThunk';

interface IProps {
  hasChildren: boolean;
  documents: IUploadFileResponse[];
  documentError: string;
}

const ChildAllowanceDocumentSection: FC<IProps> = ({
  hasChildren,
  documents,
  documentError,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Col span={12}>
      {hasChildren && (
        <div style={{ marginBottom: '1rem' }}>
          <h4>Documents</h4>
          <span>
            {' '}
            Birth certificate Mandatory (if child from 18-25 educational
            certificates)
          </span>
          {/* <Popover
            className="info-pop-button"
            placement="rightTop"
            content={<DocumentInfContent />}
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover> */}
        </div>
      )}

      {hasChildren && (
        <UploadDropzone
          defaultFiles={documents}
          onSubmit={(files) =>
            dispatch(uploadFileOnboardingChildAllowances(files))
          }
          removeFile={(file) =>
            dispatch(removeFileOnboardingChildAllowances(file))
          }
          disabled={!hasChildren}
          errorMessage={documentError}
          isDownload
        />
      )}

      {hasChildren && (
        <div className="list-download-files-wrapper">
          <h4>You can download templates for the family allowance:</h4>
          <div className="list-download-files">
            <Popover
              className="info-pop-button"
              placement="rightTop"
              content={<p>Click to download</p>}
              trigger="hover"
              overlayClassName="info-pop-content"
              showArrow={false}>
              <a href="/files/Demande_AF_Eng_01.22.pdf" download>
                <div>
                  <DocumentIcon className="download-icon" />
                </div>
                <span>English.pdf</span>
              </a>
            </Popover>

            <Popover
              className="info-pop-button"
              placement="rightTop"
              content={<p>Click to download</p>}
              trigger="hover"
              overlayClassName="info-pop-content"
              showArrow={false}>
              <a href="/files/Demande_AF_FR_01.22.pdf" download>
                <div>
                  <DocumentIcon className="download-icon" />
                </div>
                <span>French.pdf</span>
              </a>
            </Popover>

            <Popover
              className="info-pop-button"
              placement="rightTop"
              content={<p>Click to download</p>}
              trigger="hover"
              overlayClassName="info-pop-content"
              showArrow={false}>
              <a href="/files/Demande_AF_DE_01.22.pdf" download>
                <div>
                  <DocumentIcon className="download-icon" />
                </div>
                <span>German.pdf</span>
              </a>
            </Popover>

            <Popover
              className="info-pop-button"
              placement="rightTop"
              content={<p>Click to download</p>}
              trigger="hover"
              overlayClassName="info-pop-content"
              showArrow={false}>
              <a href="/files/Demande_AF_IT_01.22.pdf" download>
                <div>
                  <DocumentIcon className="download-icon" />
                </div>
                <span>Italian.pdf</span>
              </a>
            </Popover>
          </div>
        </div>
      )}
    </Col>
  );
};

export default ChildAllowanceDocumentSection;

const DocumentInfContent = () => (
  <p style={{ width: 400 }}>
    Birth certificate Mandatory (if child from 18-25 educational certificates)
  </p>
);
