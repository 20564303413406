import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const MyInfoIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        r="3.33333"
        transform="matrix(-1 0 0 1 10.0002 5.83333)"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M4.16675 14.1119C4.16675 13.3949 4.61746 12.7554 5.29266 12.5142V12.5142C8.33678 11.427 11.6634 11.427 14.7075 12.5142V12.5142C15.3827 12.7554 15.8334 13.3949 15.8334 14.1119V15.2082C15.8334 16.1977 14.957 16.9578 13.9774 16.8179L13.1821 16.7042C11.0715 16.4027 8.92871 16.4027 6.8181 16.7042L6.02275 16.8179C5.04317 16.9578 4.16675 16.1977 4.16675 15.2082V14.1119Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default MyInfoIcon;
