import { Modal } from 'antd';
import './AttentionModal.less';
import Button from '../button/Button';

interface IProps {
  isOpen: boolean;
  content: JSX.Element;
  cancelBtnTitle: string;
  confirmBtnTitle: string;
  closeModal: () => void;
  confirmAction: () => void;
  isHideAttentionText?: boolean;
  width?: number;
  btnWidth?: string;
  hideCloseBtn?: boolean;
  wrapperClassName?: string;
}

const AttentionModal = (props: IProps) => {
  const {
    isOpen,
    content,
    cancelBtnTitle,
    confirmBtnTitle,
    closeModal,
    confirmAction,
    isHideAttentionText,
    width,
    btnWidth,
    hideCloseBtn,
    wrapperClassName,
  } = props;

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      onCancel={() => closeModal()}
      closable={!hideCloseBtn}
      width={width || 450}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div className={`modal-wrapper ${wrapperClassName || ''}`}>
        {!isHideAttentionText && (
          <h4 className="attention-title">Attention!</h4>
        )}
        {!isHideAttentionText && <br />}
        {content}
        <div className="btns-wrapper">
          <Button
            buttonWidth={btnWidth || '150px'}
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={() => closeModal()}>
            {cancelBtnTitle}
          </Button>
          <Button
            buttonWidth={btnWidth || '150px'}
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => confirmAction()}>
            {confirmBtnTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AttentionModal;
