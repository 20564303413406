import { Col, Row } from 'antd';
import { FC } from 'react';
import { IConsultantTimesheet } from '../../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import Checkbox from '../../../components/checkbox/Checkbox';

import './ModalTimesheet.less';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  selectedTimesheet: IConsultantTimesheet;
}

const ModalViewTimesheet: FC<IProps> = (props) => {
  const { selectedTimesheet } = props;
  const {
    workContract,
    type,
    isSigned,
    approvalRequired,
    period,
    hasActivityPerMonth,
    documents,
    comments,
    numeriqComments,
    standardTime,
    overtime,
    doubleTime,
    companyRepresentativeEmail,
    representativeFirstName,
    representativeLastName,
  } = selectedTimesheet;

  return (
    <>
      <p className="primary">
        As your Timesheet was submitted, you are not permitted to edit it. If
        something is wrong, please contact Numeriq.
      </p>
      <Row>
        <Col span={8}>
          <p className="info-title">Client name</p>
          <p className="info-content">{workContract?.name}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Period</p>
          <p className="info-content">{getFormatDateWithMonth(period)}</p>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <p className="info-title">Type of timesheets</p>
          <p className="info-content">{type}</p>
        </Col>
        <Col span={16}>
          <p className="info-title">Type of contract</p>
          <p className="info-content">{workContract?.type}</p>
        </Col>
      </Row>

      {isSigned && (
        <Row justify="space-between">
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              label="My timesheet has been Signed/Approved."
              checked
              disabled
            />
          </Col>
        </Row>
      )}
      {approvalRequired && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox
              className={'checkbox-deep'}
              checked
              label="My timesheet requires manager's online approval."
              disabled
            />
          </Col>
        </Row>
      )}

      {hasActivityPerMonth && (
        <>
          {!!documents.length && (
            <Row>
              <Col span={24}>
                <p className="info-title">Attached file</p>
                <p className="info-content documents">
                  {documents.map(({ originalFilename }) => (
                    <span key={originalFilename}>
                      <span
                        className="view-file-link"
                        onClick={(event) => {
                          event.preventDefault();
                          fileApi.downloadFileByUrl(originalFilename);
                        }}>
                        {originalFilename}
                      </span>
                    </span>
                  ))}
                </p>
              </Col>
            </Row>
          )}

          {!isSigned && (
            <>
              <Row>
                <Col span={24}>
                  <p className="info-title">Client representative email</p>
                  <p className="info-content">{companyRepresentativeEmail}</p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="info-title">Representative First Name</p>
                  <p className="info-content">{representativeFirstName}</p>
                </Col>
                <Col span={16}>
                  <p className="info-title">Representative Last Name</p>
                  <p className="info-content">{representativeLastName}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <p className="info-title">Comments for the Client</p>
                  <p className="info-content">{comments || '-'}</p>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col span={24}>
              <p className="info-title">Comments for Numeriq</p>
              <p className="info-content">{numeriqComments || '-'}</p>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <p className="info-title">Standard time</p>
              <p className="info-content">{standardTime}</p>
            </Col>
            {workContract?.overtimeAllowed && (
              <Col span={8} className="info-calc">
                <p className="info-title">Overtime</p>
                <p className="info-content">{overtime}</p>
              </Col>
            )}
            {workContract?.doubleTimeAllowed && (
              <Col span={8} className="info-calc">
                <p className="info-title">Double time</p>
                <p className="info-content">{doubleTime}</p>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default ModalViewTimesheet;
