import { Col, Modal, Row, Switch } from 'antd';
import Dropdown from '../../../components/dropdown/Dropdown';
import { activityReportOptionTypes } from '../../../common/utils/mockExpensesType';
import { useEffect, useState } from 'react';
import ILabelInValue from '../../../common/types/ILabelInValue';
import { SalaryType } from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import Button from '../../../components/button/Button';
import { timesheetApi } from '../../../common/api/services/TimesheetApi/timesheetApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { expensesApi } from '../../../common/api/services/ExpensesApi/expensesApi';
import { IActivityReportResponse } from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';

interface IProps {
  isOpen: boolean;
  closeAddNew: () => void;
  mainInfo: IActivityReportResponse;
  period: string | null;
}
const { mainActivities } = ROUTER_PATHS;

const AddNewModal = (props: IProps) => {
  const { isOpen, closeAddNew, mainInfo, period } = props;
  const [selectedType, setSelectedType] = useState<ILabelInValue>({
    label: '',
    value: '',
  });
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (selectedType.value === 'EO') {
      setVisibility(false);
    }
  }, [selectedType.value]);

  const onChangeType = (value: ILabelInValue) => {
    if (
      (value.value === 'EO' &&
        mainInfo?.consultantOnboarding?.workContract
          ?.useExpensesForOptimization === false) ||
      (value.value === 'EC' &&
        mainInfo?.consultantOnboarding?.clientContract?.clients
          ?.reimbursableExpenses === false)
    ) {
      return;
    }
    setSelectedType(value);
  };
  const onSubmit = () => {
    if (!selectedType.value || !period) {
      return;
    }
    if (selectedType.value === 'Timesheet') {
      timesheetApi
        .newTimesheet({
          consultantId: mainInfo.consultantId,
          companyId: mainInfo.consultantOnboarding.workContract.companies._id,
          period,
          visibility: visibility ? 'public' : 'private',
        })
        .then((resp) => {
          window.open(
            `${mainActivities}?mainActivityId=${resp.data._id}&type=T`,
            '_blank',
          );
          closeAddNew();
          openSuccessNotification('Timesheet was added');
        })
        .catch((e) => {
          openErrorNotification('Failed to create timesheet');
        });

      return;
    }

    expensesApi
      .newExpense({
        consultantId: mainInfo.consultantId,
        companyId: mainInfo.consultantOnboarding.workContract.companies._id,
        period,
        visibility: visibility ? 'public' : 'private',
        type: selectedType.value,
      })
      .then((resp) => {
        window.open(
          `${mainActivities}?mainActivityId=${resp.data._id}&type=E`,
          '_blank',
        );
        closeAddNew();
        openSuccessNotification('Expence was added');
      })
      .catch((e) => {
        openErrorNotification('Failed to create expence');
      });
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div style={{ padding: '20px 0' }}>
        <h4>Add new</h4>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <Dropdown
              labelInValue={true}
              options={activityReportOptionTypes.filter(
                (item) =>
                  !(
                    (item.value === 'EO' &&
                      mainInfo?.consultantOnboarding?.workContract
                        ?.useExpensesForOptimization === false) ||
                    (item.value === 'EC' &&
                      mainInfo?.consultantOnboarding?.clientContract?.clients
                        ?.reimbursableExpenses === false)
                  ),
              )}
              value={selectedType}
              onChange={onChangeType}
              label="Type"
              showSearch={false}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="switch-container">
            <span>Visible for consultant</span>
            <Switch
              onChange={setVisibility}
              checked={visibility}
              disabled={
                mainInfo?.consultantOnboarding?.workContract?.companies.type ===
                  SalaryType.PerMonth || selectedType?.value === 'EO'
              }
            />
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Button
            buttonStyle="secondary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={closeAddNew}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            buttonStyle="primary"
            buttonSize="normal"
            buttonWidth="100%"
            onClick={() => onSubmit()}>
            Submit
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddNewModal;
