import { FC } from 'react';
import { Menu, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';

const { onboarding } = ROUTER_PATHS;

interface IProps {
  status: OnboardingRequestStatuses;
  showArchiveModal: () => void;
  onApproveFinance: (consultantId: string) => void;
  onApproveAdmin: (consultantId: string) => void;
  consultantId: string;
  approvedByFinance: boolean | undefined;
  approvedByAdmin: boolean | undefined;
  onInvite: (consultantId: string) => void;
}

const statuses: Record<OnboardingRequestStatuses, string[]> = {
  [OnboardingRequestStatuses.draft]: ['1', '5'],
  [OnboardingRequestStatuses.empty]: ['1'],
  [OnboardingRequestStatuses.toConfirm]: ['1', '2', '3', '5'],
  [OnboardingRequestStatuses.published]: ['1', '2', '3', '5'],
  [OnboardingRequestStatuses.toVerify]: ['1', '2', '3', '5'],
  [OnboardingRequestStatuses.archived]: ['1'],
};

const ActionMenu: FC<IProps> = ({
  showArchiveModal,
  consultantId,
  approvedByFinance,
  approvedByAdmin,
  onApproveFinance,
  onApproveAdmin,
  status,
  onInvite,
}) => {
  const allItems = [
    {
      key: '1',
      label: <Link to={onboarding + `/${consultantId}`}>Edit</Link>,
    },
    {
      key: '2',
      label: (
        <div>
          Finance{' '}
          <Switch
            checked={!!approvedByFinance}
            onChange={() => onApproveFinance(consultantId)}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div>
          Admin{' '}
          <Switch
            checked={!!approvedByAdmin}
            onChange={() => onApproveAdmin(consultantId)}
          />
        </div>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <span style={{ color: '#C33025' }} onClick={showArchiveModal}>
    //       Archive
    //     </span>
    //   ),
    // },
    {
      key: '5',
      label: <span onClick={() => onInvite(consultantId)}>Invite</span>,
    },
  ];

  const items = allItems
    .map((item) => {
      if (!statuses[status].includes(item.key)) return null;
      return item;
    })
    .filter(Boolean);

  return <Menu items={items} rootClassName="consultant-actions-menu" />;
};

export default ActionMenu;
