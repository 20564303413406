import { FC } from 'react';
import { Menu } from 'antd';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';

interface IProps {
  showModalEditExpense: () => void;
  showModalViewExpense: () => void;
  approveExpense: () => void;
  rejectExpense: () => void;
  expireExpense: () => void;
  status: ExpensesStatus;
  type: ExpensesType;
  id: string;
  publicId: string;
  firstName: string;
  lastName: string;
  setDeleteInfo: (info: any) => void;
}

const statuses: Record<string, string[]> = {
  [ExpensesStatus.DRAFT]: ['1', '4', '7'],
  [ExpensesStatus.NO_EXPENSES]: ['1', '7'],
  [ExpensesStatus.EXPIRED]: ['1', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_FOR_OPTIMISATION}`]:
    ['2', '6', '3', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_MEAL_ALLOWANCE}`]:
    ['2', '6', '3', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT}`]:
    ['2', '6', '7'],
  [ExpensesStatus.CLIENT_APPROVAL]: ['2', '6', '3', '7'],
  [ExpensesStatus.PRE_APPROVED]: ['2', '6', '3', '7'],
  [ExpensesStatus.APPROVED]: ['2', '6', '7'],
  [ExpensesStatus.REJECTED]: ['1', '7'],
};

const ActionMenu: FC<IProps> = ({
  showModalEditExpense,
  showModalViewExpense,
  approveExpense,
  rejectExpense,
  expireExpense,
  status,
  type,
  id,
  publicId,
  firstName,
  lastName,
  setDeleteInfo,
}) => {
  const allItems = [
    {
      key: '1',
      label: <span onClick={showModalEditExpense}>Edit</span>,
    },
    {
      key: '2',
      label: <span onClick={showModalViewExpense}>View</span>,
    },
    {
      key: '3',
      label: <span onClick={approveExpense}>Approve</span>,
    },
    {
      key: '4',
      label: <span onClick={expireExpense}>Close</span>,
    },
    {
      key: '6',
      label: <span onClick={rejectExpense}>Reject</span>,
    },
    {
      key: '7',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() => setDeleteInfo({ id, publicId, firstName, lastName })}>
          Delete
        </span>
      ),
    },
  ];

  const items = allItems
    .map((item) => {
      const statusKey =
        status +
        (status === ExpensesStatus.WAITING_FOR_APPROVAL ? `_${type}` : '');
      if (!statuses[statusKey].includes(item.key)) return null;
      return item;
    })
    .filter(Boolean);

  return <Menu items={items} />;
};

export default ActionMenu;
