import { ChangeEvent, FC, useEffect } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { Button, Modal, RadioChangeEvent, Spin, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { ILeadDetails } from '../waiting-room-page/types';
import EditIcon from '../../assets/icons/EditIcon';
import {
  getFormatDateWithMonth,
  getFormatDateWithTime,
} from '../../common/utils/getFormatDate';
import EyeIcon from '../../assets/icons/EyeIcon';
import {
  changeExpensesSorting,
  getExpensesList,
  saveAsDraft,
  submit,
  uploadFile,
} from '../../redux/reducers/expenses/asyncThunkActions';
import {
  changeExpensesFiltering,
  removeFileSelectedExpense,
  resetFilesSelectedExpense,
  setErrorMessages,
  setExpensesSorting,
  updateByIdSelectedExpense,
  updateOpenModalEditExpense,
  updateOpenModalViewExpense,
  updateSelectedExpense,
  updateSelectedExpenseCheckbox,
} from '../../redux/reducers/expenses/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';

import ModalEditExpense from './components/ModalEditExpense';
import ModalViewExpense from './components/ModalViewExpense';
import { useTableDateFilter } from '../../common/hooks/useTableDateFilter';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import {
  expensesStatusParser,
  TExpensesStatusParserKey,
} from '../../common/constants/expensesStatusParser';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../common/api/services/ConsultantApi/enums/ResponseEnums';
import TableTooltip from '../../components/common-table/table-tooltip';
import { expensesStatusesHovers } from '../../common/constants/tableStatusesHovers';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import {
  FilterMobileTypes,
  IMobileFilters,
} from '../../components/common-table/types';
import { TSortValue } from '../../redux/types';
import MobileLoader from '../../components/mobile-loader/MobileLoader';

const ExpensesPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    isUpLoading,
    isOpenModalEditExpense,
    isOpenModalViewExpense,
    expensesList,
    selectedExpense,
    errorMessages,
    isLoading,
  } = useAppSelector((state) => state.expenses);

  useEffect(() => {
    dispatch(getExpensesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { content, pagination, sort, filter } = expensesList;
  const { totalElement, currentPage, perPage } = pagination;
  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeExpensesFiltering(date));
  };

  const changeMobileFilter = (
    date: Record<string, string | string[]>,
  ): void => {
    dispatch(changeExpensesFiltering(date));
    dispatch(getExpensesList({ currentPage: 1 }));
  };

  const dataFilter = useTableMonthDate({
    fetchRequest: () => dispatch(getExpensesList({ currentPage: 1 })),
    changeValues: changeFilter,
    defaultValue: (filter?.period as string) ?? '',
    valueType: 'period',
  })();

  const searchFilterArg = {
    fetchRequest: () => dispatch(getExpensesList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeExpensesSorting(sortBy as string));
    }
  };

  const onChangeMobileSort = (sort: { sortBy: string; sort: string }) => {
    dispatch(setExpensesSorting([sort.sortBy, sort.sort as TSortValue]));
    dispatch(getExpensesList({ currentPage: 1 }));
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getExpensesList({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const showModalEditExpense = (expenseId: string) => {
    dispatch(updateByIdSelectedExpense(expenseId));
    dispatch(updateOpenModalEditExpense(true));
    dispatch(setErrorMessages(null));
  };

  const showModalViewExpense = (expenseId: string) => {
    dispatch(updateByIdSelectedExpense(expenseId));
    dispatch(updateOpenModalViewExpense(true));
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 120,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.publicId,
    },
    {
      title: 'Linked Timesheet',
      dataIndex: 'linkedTimesheet',
      width: 180,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter['timesheet.publicId'] as string) ?? '',
      })('timesheet.publicId'),
      sorter: true,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.search,
      defaultFilter: filter['timesheet.publicId'],
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...dataFilter,
      className: sortClassName('period'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      filterType: FilterMobileTypes.data,
      defaultFilter: filter?.period,
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.type as string[]) ?? '',
        fieldFilterName: 'type',
      })([
        ExpensesType.EXPENSES_FOR_OPTIMISATION,
        ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ExpensesType.EXPENSES_MEAL_ALLOWANCE,
      ]),
      className: sortClassName('type'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      width: 150,
      filterType: FilterMobileTypes.select,
      defaultFilter: filter?.type || [],
      selectArray: [
        ExpensesType.EXPENSES_FOR_OPTIMISATION,
        ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ExpensesType.EXPENSES_MEAL_ALLOWANCE,
      ],
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          {text}
        </TableTooltip>
      ),
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.clientName,
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo: any) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={150}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.projectDescription,
    },
    {
      title: 'Client approval date',
      dataIndex: 'approvalAt',
      key: 'approvalAt',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      className: `status-td ${sortClassName('status')}`,
      sortDirections: ['descend'],
      width: 250,
      showSorterTooltip: false,
      render: (text: TExpensesStatusParserKey) => (
        <TableTooltip
          placement="topLeft"
          tootltipTitle={expensesStatusesHovers[text]}
          columnWidth={0}>
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        </TableTooltip>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      className: 'edit-action-td',
      render: (_: any, data: any) => {
        if (data.status !== ExpensesStatus.DRAFT) {
          return (
            <>
              <EyeIcon
                className="eye-timesheets-icon action-table-btn"
                onClick={() => showModalViewExpense(data.key)}
              />
              <Button
                type="primary"
                className="action-mobile-btn"
                onClick={() => showModalViewExpense(data.key)}>
                View
              </Button>
            </>
          );
        }
        return (
          <>
            <EditIcon
              className="edit-timesheets-icon action-table-btn"
              onClick={() => showModalEditExpense(data.key)}
            />
            <Button
              type="primary"
              className="action-mobile-btn"
              onClick={() => showModalEditExpense(data.key)}>
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const data = content.map((item) => {
    const {
      _id,
      period,
      createdAt,
      type,
      workContract,
      status,
      clientApprovalDate,
    } = item;
    const clientName =
      ExpensesType.EXPENSES_FOR_OPTIMISATION === type ||
      ExpensesType.EXPENSES_MEAL_ALLOWANCE === type
        ? 'Numeriq'
        : workContract?.name;

    return {
      key: _id,
      publicId: item.publicId,
      period: getFormatDateWithMonth(period),
      projectDescription: item.workContract?.projectDescription
        ? item.workContract?.projectDescription
        : 'No',
      createdAt: item.createdAt
        ? moment(item.createdAt).format(commonMonthTimeFormat)
        : 'No',
      approvalAt: clientApprovalDate
        ? getFormatDateWithTime(clientApprovalDate)
        : 'No',
      type,
      clientName: clientName ? clientName : 'No',
      status,
      linkedTimesheet: item.timesheet?.publicId || 'No',
    };
  });

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';

      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const handleCancel = () => {
    dispatch(updateOpenModalEditExpense(false));
    dispatch(updateOpenModalViewExpense(false));
  };

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasExpensesInThisMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedExpense());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          total: 0,
        }),
      );
    }
    dispatch(
      updateSelectedExpense({
        [fieldName]: fieldValue,
      }),
    );
  };

  const onChangeCheckbox = (fieldName: 'isSigned' | 'approvalRequired') => {
    return () => {
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedExpenseCheckbox(fieldName));
    };
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };

  const onRemoveAttachFile = (originalFilename: string) => {
    dispatch(removeFileSelectedExpense(originalFilename));
  };

  const onSaveAsDraft = () => {
    dispatch(saveAsDraft());
  };

  const onSubmit = () => {
    dispatch(submit());
  };

  const onDoubleClick = (record: any) => {
    if (
      record.status === ExpensesStatus.DRAFT ||
      record.status === ExpensesStatus.REJECTED
    ) {
      showModalEditExpense(record.key);
      return;
    }
    showModalViewExpense(record.key);
  };

  return (
    <>
      <Spin spinning={isLoading} indicator={<MobileLoader />}>
        <CommonTable
          paginationCurrentPage={currentPage}
          paginationPageSize={perPage}
          paginationOnChange={onChangePagination}
          paginationTotalElement={totalElement}
          data={data}
          columns={columns}
          onChange={onChangeSort}
          onDoubleClick={onDoubleClick}
          submitFilters={changeMobileFilter}
          sortBy={sort}
          onChangeMobileSort={onChangeMobileSort}
        />
      </Spin>

      {isOpenModalEditExpense && (
        <Modal
          width={640}
          title={'Edit mode'}
          open={isOpenModalEditExpense}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper expense edit">
          <ModalEditExpense
            isUpLoading={isUpLoading}
            selectedExpense={selectedExpense}
            getError={getError}
            onChange={onChange}
            onChangeCheckbox={onChangeCheckbox}
            onChangeFile={onChangeFile}
            onRemoveAttachFile={onRemoveAttachFile}
            onSaveAsDraft={onSaveAsDraft}
            onSubmit={onSubmit}
          />
        </Modal>
      )}

      {isOpenModalViewExpense && (
        <Modal
          width={640}
          title={
            <div className="modal-view-title">
              View expenses{' '}
              <span className={expensesStatusParser[selectedExpense?.status]}>
                {selectedExpense?.status.toUpperCase() || ''}
              </span>
            </div>
          }
          open={isOpenModalViewExpense}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper expense view">
          <ModalViewExpense selectedExpense={selectedExpense} />
        </Modal>
      )}
    </>
  );
};

export default ExpensesPage;
