import {
  IARPAyloadDTO,
  IActivityReportResponse,
} from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';
import Button from '../../../components/button/Button';
import '../ActivityReportInfo.less';
import moment from '../../../common/constants/moment';
import {
  commonDateSpaceFormat,
  commonMonthYearSpaceFormat,
} from '../../../common/constants/dateConstants';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { useLocation } from 'react-router-dom';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import { openErrorNotification } from '../../../components/notification/Notification';
import { useState } from 'react';
import ViewInvoicesModal from '../modals/ViewInvoicesModal';

interface IProps {
  consultantInfo: Array<IActivityReportResponse>;
  selectedIndex: number;
  activityReports: Array<{
    consultantId: string | null;
    projectId: string | null;
    clientId: string | null;
    period: string | null;
  }>;
  payloadInfo: Array<IARPAyloadDTO>;
  getPayloadInfo: () => void;
}
const { generateInvoice } = ROUTER_PATHS;

const ConsultantInfo = (props: IProps) => {
  const location = useLocation();
  const { state } = location;

  const {
    consultantInfo,
    selectedIndex,
    activityReports,
    payloadInfo,
    getPayloadInfo,
  } = props;
  const info = consultantInfo[selectedIndex];

  const [invoices, setInvoices] = useState(null);

  const onGenerateInvoice = () => {
    invoiceApi
      .addInvoice(activityReports)
      .then((resp) => {
        if (resp?._id) {
          window.open(`${generateInvoice}/${resp._id}`, '_blank');
        }
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message || 'Failed to generate invoice',
        );
      });
  };
  const onGenerateDepositInvoice = () => {
    invoiceApi
      .addDepositInvoice(activityReports)
      .then((resp) => {
        if (resp?._id) {
          window.open(`${generateInvoice}/${resp._id}/deposit`, '_blank');
        }
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message || 'Failed to generate invoice',
        );
      });
  };
  const openViewInvoice = () => {
    if (payloadInfo) {
      const linkedInvoices = payloadInfo
        .map((item) => {
          return item.linkToInvoice || '';
        })
        .filter((item) => item);
      if (!linkedInvoices.length) {
        openErrorNotification('Linked invoice was not found');
        return;
      }
      invoiceApi.getInvoices(linkedInvoices).then((resp: any) => {
        setInvoices(resp.result);
      });
    }
  };

  const closeInvoiceModal = () => {
    setInvoices(null);
  };

  return (
    <div className="consultant-info">
      <div className="consultant-info-row">
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Period</span>
          <span className="consultant-info-item-value">
            {info?.commonPeriod
              ? moment(info.commonPeriod).format(commonMonthYearSpaceFormat)
              : state?.commonPeriod
              ? moment(state?.commonPeriod).format(commonMonthYearSpaceFormat)
              : '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Consultant</span>
          <span className="consultant-info-item-value">
            {`${info?.firstName || state?.firstName || ''} ${
              info?.lastName || state?.lastName || ''
            } ${
              info?.publicId
                ? `(${info.publicId})`
                : state?.publicId
                ? `(${state?.publicId})`
                : ''
            }`}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Client</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies?.name ||
              state?.companyName ||
              '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Agency</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.clientContract.clients.agencyName ||
              state?.agencyName ||
              '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Contract type</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies?.type ||
              state?.contractType ||
              '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Work as</span>
          <span
            className="consultant-info-item-value"
            style={{ color: '#009262' }}>
            {info?.consultantOnboarding.personalInformation.workForm ||
              state?.payrollCorp ||
              '-'}
          </span>
        </div>
      </div>
      <div className="consultant-info-row">
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Currency payment</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies
              ?.paymentCurrency ||
              state?.paymentCurrency ||
              '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">
            Consultant start date
          </span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies?.startDate
              ? moment(
                  info?.consultantOnboarding?.workContract?.companies
                    ?.startDate,
                ).format(commonDateSpaceFormat)
              : state?.startDate
              ? moment(state?.startDate).format(commonDateSpaceFormat)
              : '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Client start date</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies?.startDate
              ? moment(
                  info?.consultantOnboarding?.clientContract?.clients
                    ?.startDate,
                ).format(commonDateSpaceFormat)
              : state?.clientStartDate
              ? moment(state?.clientStartDate).format(commonDateSpaceFormat)
              : '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Seller</span>
          <span className="consultant-info-item-value">
            {info?.seller?.firstName || state?.sellerFirstName || '-'}{' '}
            {info?.seller?.lastName || state?.sellerLastName || ''}
          </span>
        </div>
        <div className="consultant-info-item">
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onGenerateInvoice}
            buttonWidth={'95%'}>
            Regular invoice
          </Button>
        </div>
        <div className="consultant-info-item last-btn">
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onGenerateDepositInvoice}
            buttonWidth={'95%'}>
            Deposit invoice
          </Button>
        </div>
      </div>
      <div className="consultant-info-row">
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Treasury</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies
              ?.treasuryPaymentDate ||
              state?.advancedTiming ||
              'No'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">
            Consultant end date
          </span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies?.endDate
              ? moment(
                  info?.consultantOnboarding?.workContract?.companies?.endDate,
                ).format(commonDateSpaceFormat)
              : state?.endDate
              ? moment(state?.endDate).format(commonDateSpaceFormat)
              : '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Client end date</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.clientContract?.clients?.endDate
              ? moment(
                  info?.consultantOnboarding?.clientContract?.clients?.endDate,
                ).format(commonDateSpaceFormat)
              : state?.clientEndDate
              ? moment(state?.clientEndDate).format(commonDateSpaceFormat)
              : '-'}
          </span>
        </div>
        <div className="consultant-info-item">
          <span className="consultant-info-item-title">Entity</span>
          <span className="consultant-info-item-value">
            {info?.consultantOnboarding?.workContract?.companies
              ?.numeriqOffice ||
              state?.numeriqOfficeCC ||
              ''}
          </span>
        </div>
        <div className="consultant-info-item">
          <Button
            buttonSize="medium"
            buttonStyle="secondary"
            onClick={openViewInvoice}
            buttonWidth={'95%'}>
            View invoices
          </Button>
        </div>
        <div className="consultant-info-item last-btn">
          <Button
            buttonSize="medium"
            buttonStyle="secondary"
            onClick={() =>
              window.open(
                `/onboarding/${info?.consultantId}/work-contract`,
                '_blank',
              )
            }
            buttonWidth={'95%'}>
            View contract
          </Button>
        </div>
      </div>
      {invoices && (
        <ViewInvoicesModal
          isOpen={!!invoices}
          closeModal={closeInvoiceModal}
          invoices={invoices}
          getPayloadInfo={getPayloadInfo}
        />
      )}
    </div>
  );
};

export default ConsultantInfo;
