import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const DocumentIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 29 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.3158"
        y="2.15771"
        width="25.2632"
        height="31.5789"
        rx="6.31579"
        stroke="#ABBED1"
        strokeWidth="2.84211"
      />
      <path
        d="M8.63126 10.0522H21.2628"
        stroke="#ABBED1"
        strokeWidth="2.84211"
        strokeLinecap="round"
      />
      <path
        d="M8.63126 17.9473H21.2628"
        stroke="#ABBED1"
        strokeWidth="2.84211"
        strokeLinecap="round"
      />
      <path
        d="M8.63126 25.8418H14.947"
        stroke="#ABBED1"
        strokeWidth="2.84211"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DocumentIcon;
