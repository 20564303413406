import React, { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ExpensesType } from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { Modal, RadioChangeEvent } from 'antd';
import ModalEditTimesheet from '../../internal-timesheet-page/components/ModalEditTimesheet';
import { timesheetStatusParser } from '../../../common/constants/timesheetStatusParser';
import ModalViewTimesheet from '../../internal-timesheet-page/components/ModalViewTimesheet';
import {
  removeFileSelectedTimesheet,
  resetFilesSelectedTimesheet,
  setErrorMessages,
  updateSelectedTimesheet,
  updateSelectedTimesheetCheckbox,
} from '../../../redux/reducers/timesheets/sliceReducer';
import {
  saveAsDraftInternalTimesheet,
  uploadFile,
} from '../../../redux/reducers/timesheets/asyncThunkActions';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';

interface IProps {
  selectedMainActivity: any;
  modalType: 'close' | '' | 'delete' | 'view' | 'edit' | 'add';
  closeAction: () => void;
  getMainActivities: () => void;
}

const TimesheetsComponent = (props: IProps) => {
  const { modalType, selectedMainActivity, closeAction, getMainActivities } =
    props;
  const dispatch = useAppDispatch();
  const {
    selectedTimesheet,
    errorMessages,
    isUpLoading: isUpLoadingTimesheet,
  } = useAppSelector((state) => state.timesheets);

  const getErrorTimesheet = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string') {
        return fieldName === '' ? errorMessages : '';
      } else if (typeof errorMessages === 'object' && errorMessages !== null) {
        const errorMessagesTimesheet = errorMessages as Record<string, string>;
        return errorMessagesTimesheet[fieldName] ?? '';
      }
    }
    return '';
  };

  const onChangeTimesheet = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasActivityPerMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedTimesheet());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          standardTime: 0,
          overtime: 0,
          doubleTime: 0,
        }),
      );
    }
    dispatch(updateSelectedTimesheet({ [fieldName]: fieldValue }));
  };

  const onChangeCheckboxTimesheet = (
    fieldName: 'isSigned' | 'approvalRequired',
  ) => {
    return () => {
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedTimesheetCheckbox(fieldName));
    };
  };

  const onChangeFileTimesheet = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };

  const onChangeNumberTimesheet =
    (fieldName: string) => (value: number | string | null) => {
      dispatch(
        updateSelectedTimesheet({
          [fieldName]: value,
        }),
      );
    };
  const onChangeVisibilityTimesheet = (visibility: boolean) => {
    dispatch(
      updateSelectedTimesheet({
        visibility: visibility ? 'public' : 'private',
      }),
    );
  };

  const onRemoveAttachFileTimesheet = (originalFilename: string) => {
    dispatch(removeFileSelectedTimesheet(originalFilename));
  };

  const onSaveAsDraftTimesheet = async () => {
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      timesheetId,
      consultantId,
      {
        ...selectedTimesheet,
        status: TimesheetStatus.DRAFT,
      },
    );
    if (isSuccess) {
      closeAction();
      getMainActivities();
    } else {
      dispatch(setErrorMessages(data));
    }
  };

  const onSubmitTimesheet = async () => {
    const {
      _id: timesheetId,
      consultantId,
      hasActivityPerMonth,
      approvalRequired,
      isSigned,
    } = selectedTimesheet;

    if (!approvalRequired && !isSigned && hasActivityPerMonth) return;

    const status = !hasActivityPerMonth
      ? TimesheetStatus.NO_ACTIVITY
      : isSigned
      ? TimesheetStatus.PRE_APPROVED
      : TimesheetStatus.WAITING_FOR_APPROVAL;
    const { isSuccess, data } = await consultantApi.putInternalTimesheet(
      timesheetId,
      consultantId,
      {
        ...selectedTimesheet,
        status,
      },
    );
    if (isSuccess) {
      closeAction();
      getMainActivities();
    } else {
      dispatch(setErrorMessages(data));
    }
  };

  return (
    <>
      {modalType === 'edit' &&
        selectedMainActivity &&
        ![
          ExpensesType.EXPENSES_FOR_OPTIMISATION,
          ExpensesType.EXPENSES_MEAL_ALLOWANCE,
          ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ].includes(selectedMainActivity.type) && (
          <Modal
            width={640}
            title={'Edit timesheet'}
            open={
              modalType === 'edit' &&
              ![
                ExpensesType.EXPENSES_FOR_OPTIMISATION,
                ExpensesType.EXPENSES_MEAL_ALLOWANCE,
                ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
              ].includes(selectedMainActivity?.type)
            }
            onCancel={closeAction}
            centered
            footer={null}
            destroyOnClose={true}
            wrapClassName="modal-window-wrapper i-timesheet">
            <ModalEditTimesheet
              isUpLoading={isUpLoadingTimesheet}
              selectedTimesheet={selectedTimesheet}
              getError={getErrorTimesheet}
              onChange={onChangeTimesheet}
              onChangeCheckbox={onChangeCheckboxTimesheet}
              onChangeFile={onChangeFileTimesheet}
              onChangeNumber={onChangeNumberTimesheet}
              onRemoveAttachFile={onRemoveAttachFileTimesheet}
              onSaveAsDraft={onSaveAsDraftTimesheet}
              onSubmit={onSubmitTimesheet}
              onChangeVisibility={onChangeVisibilityTimesheet}
            />
          </Modal>
        )}
      {modalType === 'view' &&
        selectedMainActivity &&
        ![
          ExpensesType.EXPENSES_FOR_OPTIMISATION,
          ExpensesType.EXPENSES_MEAL_ALLOWANCE,
          ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ].includes(selectedMainActivity.type) && (
          <Modal
            width={640}
            title={
              <div className="modal-view-title">
                View timesheet{' '}
                <span
                  className={
                    timesheetStatusParser[selectedMainActivity?.status]
                  }>
                  {selectedMainActivity?.status.toUpperCase() || ''}
                </span>
              </div>
            }
            open={
              modalType === 'view' &&
              ![
                ExpensesType.EXPENSES_FOR_OPTIMISATION,
                ExpensesType.EXPENSES_MEAL_ALLOWANCE,
                ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
              ].includes(selectedMainActivity?.type)
            }
            onCancel={closeAction}
            centered
            footer={null}
            destroyOnClose={true}
            wrapClassName="modal-window-wrapper timesheet">
            <ModalViewTimesheet selectedTimesheet={selectedMainActivity} />
          </Modal>
        )}
    </>
  );
};

export default TimesheetsComponent;
