import { IInvoice } from '../../../../../common/api/services/InvoiceApi/types/types';
import '../../../GenerateInvoicePage.less';

interface IProps {
  invoice: IInvoice | null;
}

const BankDetails = (props: IProps) => {
  const { invoice } = props;
  return (
    <div className="bank-details-wrapper">
      <h4 style={{ fontWeight: 'bold' }}>Bank details</h4>
      <div className="bank-details-item">
        <span>Account holder:</span>
        <span className="bank-details-value">
          {invoice?.bankDetails?.name || ''}
        </span>
      </div>
      <div className="bank-details-item">
        <span>Bank credit:</span>
        <span className="bank-details-value">
          {invoice?.bankDetails?.credit || ''}
        </span>
      </div>
      <div className="bank-details-item">
        <span>IBAN:</span>
        <span className="bank-details-value">
          {invoice?.bankDetails?.iban || ''}
        </span>
      </div>
      <div className="bank-details-item">
        <span>BIC/SWIFT:</span>
        <span className="bank-details-value">
          {invoice?.bankDetails?.swift || ''}
        </span>
      </div>
      <div className="bank-details-item">
        <span>Currency:</span>
        <span className="bank-details-value">
          {' '}
          {invoice?.bankDetails?.currency || ''}
        </span>
      </div>
    </div>
  );
};

export default BankDetails;
