import { useState } from 'react';
import { employeePayslipsApi } from '../../../common/api/services/EmployeePayslips/employeePayslipsApi';
import {
  PaymentStatuses,
  PaymentStatusesArray,
} from '../../../common/api/services/EmployeePayslips/types/types';
import { payslipPaymentStatusParser } from '../../../common/constants/payslipPaymentStatusParser';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import { getActivityReportList } from '../../../redux/reducers/activity-report/asyncThunkActions';
import { useDispatch } from 'react-redux';
import '../ActivityReportPage.less';

interface IProps {
  payslipId: string | undefined;
  consultantId: string;
  period: string;
}
const ChangeStatusDropdown = (props: IProps) => {
  const { payslipId, consultantId, period } = props;
  const dispatch: any = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSelectStatus = (value: PaymentStatuses) => {
    if (isLoading) {
      return;
    }
    if (!payslipId) {
      const body = {
        paymentStatus: value,
        consultantId,
        period,
      };
      setIsLoading(true);

      employeePayslipsApi
        .updatePayslip(body)
        .then(() => {
          dispatch(getActivityReportList());
          openSuccessNotification('Payslip status was updated successfully');
        })
        .catch((e) => {
          openErrorNotification(e?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    const body = {
      paymentStatus: value,
    };
    setIsLoading(true);

    employeePayslipsApi
      .updatePayslipPaymentStatus(payslipId, body)
      .then(() => {
        dispatch(getActivityReportList());
        openSuccessNotification('Payslip status was updated successfully');
      })
      .catch((e) => {
        openErrorNotification(e?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="tooltip-status-wrapper">
      {PaymentStatusesArray.map((option) => (
        <div
          className="status-wrapper"
          key={option}
          onClick={() => onSelectStatus(option)}>
          <span className={payslipPaymentStatusParser[option]}>{option}</span>
        </div>
      ))}
    </div>
  );
};

export default ChangeStatusDropdown;
