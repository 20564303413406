import { Modal, Spin } from 'antd';
import '../SystemInfoPage.less';
import { CloseOutlined } from '@ant-design/icons';
import {
  FXRateType,
  IFXRate,
  IFXRates,
  IVATs,
  VATType,
} from '../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { useEffect, useState } from 'react';
import { systemInfoApi } from '../../../common/api/services/SystemInfoApi/SystemInfoApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import moment from '../../../common/constants/moment';
import {
  commonDateDotsFormat,
  commonDateMonthFormat,
  commonMonthDateYearSpaceFormat,
  commonMonthYearSpaceFormat,
  commonYearDateFormat,
} from '../../../common/constants/dateConstants';
import Input from '../../../components/input/Input';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import Button from '../../../components/button/Button';
import TrashIcon from '../../../assets/icons/TrashIcon';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import { DeleteRate } from '../../../components/common-texts/CommonTexts';
import {
  compareBeforeDates,
  compareDates,
} from '../../../common/utils/compareEndDate';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedType: string | IFXRate;
}

const SystemInfoHistory = (props: IProps) => {
  const { isOpen, onClose, selectedType } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [infoList, setInfoList] = useState<IFXRates | IVATs>([]);
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [selectedId, setSelectedId] = useState('');
  const [deletedId, setDeletedId] = useState('');
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const isVAT = selectedType === 'VAT';

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return (
        errorMessages.find((item: any) => item?.property === fieldName)
          ?.message || ''
      );
    }

    return '';
  };

  useEffect(() => {
    getInfoList();
  }, []);

  const getInfoList = () => {
    setIsLoading(true);
    if (isVAT) {
      systemInfoApi
        .getVATHistory()
        .then((response: any) => {
          setInfoList(
            response?.result
              ? response.result.filter(
                  (item: any) => item.name === VATType.SwissVAT,
                )
              : [],
          );
        })
        .catch((e) => {
          openErrorNotification(e.message || 'Failed to get VAT history');
        })
        .finally(() => setIsLoading(false));
      return;
    }

    systemInfoApi
      .getFXRatesHistory(selectedType as FXRateType)
      .then((response: any) => {
        setInfoList(response?.result || []);
      })
      .catch((e) => {
        openErrorNotification(e.message || 'Failed to get FX rates history');
      })
      .finally(() => setIsLoading(false));
  };

  const openAddNew = () => {
    if (selectedId) {
      return;
    }
    setIsAddNewOpen(true);
  };

  const rejectAddNew = () => {
    setSelectedDate('');
    setInputValue('');
    setErrorMessages([]);
    setIsAddNewOpen(false);
    return;
  };

  const sendNewValue = () => {
    if (isVAT) {
      systemInfoApi
        .createVAT({
          name: 'Swiss VAT',
          percentage: +inputValue,
          period: selectedDate
            ? moment(selectedDate).format(commonYearDateFormat)
            : '',
        })
        .then(() => {
          openSuccessNotification('The Swiss VAT has been added successfully');
          rejectAddNew();
          getInfoList();
        })
        .catch((e) => {
          setErrorMessages(e?.response?.data.messages || []);
          openErrorNotification(
            e?.response?.data.message || 'Creation of Swiss VAT failed',
          );
        });
      return;
    }
    systemInfoApi
      .createRXRate({
        currencyPair: selectedType as string,
        rate: +inputValue,
        period: selectedDate
          ? moment(selectedDate).format(commonYearDateFormat)
          : '',
      })
      .then(() => {
        openSuccessNotification('The FX Rate has been added successfully');
        rejectAddNew();
        getInfoList();
      })
      .catch((e) => {
        setErrorMessages(e?.response?.data.messages || []);
        openErrorNotification(
          e?.response?.data.message || 'Creation of FX Rate failed',
        );
      });
    return;
  };

  const setEditMode = (period: string, id: string, value: string) => {
    if (isAddNewOpen || selectedId) {
      return;
    }
    if (compareDates(period) || !isVAT) {
      setSelectedId(id);
      setInputValue(value);
    }
  };

  const rejectEditMode = () => {
    setSelectedId('');
    setInputValue('');
  };

  const sendUpdateValue = () => {
    if (isVAT) {
      systemInfoApi
        .updateVAT(selectedId, +inputValue)
        .then(() => {
          openSuccessNotification(
            'The Swiss VAT has been updated successfully',
          );
          setSelectedId('');
          setInputValue('');
          getInfoList();
        })
        .catch((e) => {
          setErrorMessages(e?.response?.data.message || []);
          openErrorNotification(e.message || 'Update of Swiss VAT failed');
        });
      return;
    }
    systemInfoApi
      .updateRXRate(selectedId, +inputValue)
      .then(() => {
        openSuccessNotification('The FX Rate has been updated successfully');
        setSelectedId('');
        setInputValue('');
        getInfoList();
      })
      .catch((e) => {
        setErrorMessages(e?.response?.data.messages || []);
        openErrorNotification(
          e?.response?.data.message || 'Update of FX Rate failed',
        );
      });
    return;
  };

  const closeDeleteModal = () => {
    setDeletedId('');
  };

  const deleteItem = () => {
    if (isVAT) {
      systemInfoApi
        .deleteVAT(deletedId)
        .then(() => {
          openSuccessNotification(
            'The Swiss VAT has been deleted successfully',
          );
          setDeletedId('');
          getInfoList();
        })
        .catch((e) => {
          openErrorNotification(e.message || 'Delete of Swiss VAT failed');
        });
      return;
    }
    systemInfoApi
      .deleteRXRate(deletedId)
      .then(() => {
        openSuccessNotification('The FX Rate has been deleted successfully');
        setDeletedId('');
        getInfoList();
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data.message || 'Delete of FX Rate failed',
        );
      });
    return;
  };

  const disabledDate = (current: any) => {
    // Disable all dates before the start of the current month
    return isVAT && current && current < moment().startOf('month');
  };

  const onChangeDate = (date: Date | null) => {
    const newDate = date ? moment(date).format(commonDateMonthFormat) : '';
    setSelectedDate(newDate);
    if (compareBeforeDates(newDate) && isVAT) {
      openErrorNotification('Select a date no earlier than today');
      setIsDisabledConfirm(true);
      return;
    }
    setIsDisabledConfirm(false);
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      width={700}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div style={{ padding: '32px' }}>
        <Spin spinning={isLoading}>
          <div className="modal-header">
            <span>{selectedType as string}</span>
            <CloseOutlined onClick={onClose} />
          </div>
          {!selectedId && (
            <div className="system-info-add-new">
              <span onClick={openAddNew}>
                + Add {`${isVAT ? 'Swiss VAT' : 'fx rate'}`}
              </span>
            </div>
          )}
          {isAddNewOpen && (
            <div className="history-list-row add-new-rate">
              <Input
                label={isVAT ? 'Swiss VAT' : 'FX Rate'}
                value={inputValue}
                onChange={(value: any) => setInputValue(value.target.value)}
                inputType="number"
                errorMessage={getError('rate') || getError('percentage')}
              />
              <CommonDatePicker
                changeDate={(date) => onChangeDate(date)}
                defaultValue={selectedDate}
                className="date-picker-form"
                errorMessage={getError('period')}
                disabledDate={disabledDate}
              />
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="primary"
                disable={isDisabledConfirm}
                onClick={() => sendNewValue()}>
                confirm
              </Button>
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="error"
                onClick={rejectAddNew}>
                reject
              </Button>
            </div>
          )}
          {selectedId && (
            <div className="system-info-edit-header">
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="primary"
                onClick={sendUpdateValue}>
                confirm
              </Button>
              <Button
                buttonSize="small"
                buttonWidth={100}
                buttonStyle="error"
                onClick={rejectEditMode}>
                reject
              </Button>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.length > 0 &&
              errorMessages.map((item: any, index: number) => (
                <span key={index} style={{ color: '#e02b1d' }}>
                  {item.message}
                </span>
              ))}
          </div>
          <div className="history-list">
            {!isVAT && (
              <div className="history-list-label">
                <div className="table-label">Currency</div>
                <div className="table-label">Rate</div>
                <div className="table-label">Created at</div>
                <div className="table-label">Selected period</div>
              </div>
            )}
            {infoList.length > 0 &&
              infoList.map((item: any) => (
                <div
                  className="history-list-row edit-mode-system"
                  key={item._id}
                  onDoubleClick={() =>
                    setEditMode(
                      item.period,
                      item._id,
                      item.rate || item.percentage,
                    )
                  }>
                  <span>{item.name || item.currencyPair}</span>
                  {selectedId && selectedId === item._id ? (
                    <Input
                      label={isVAT ? 'Swiss VAT' : 'FX Rate'}
                      value={inputValue}
                      onChange={(value: any) =>
                        setInputValue(value.target.value)
                      }
                      inputType="number"
                      errorMessage={getError('rate') || getError('percentage')}
                    />
                  ) : (
                    <span>{item.rate || `${item.percentage}%`}</span>
                  )}
                  <span>
                    {moment(item.updatedAt || item.createdAt).format(
                      commonDateDotsFormat,
                    )}
                  </span>
                  <span>
                    {moment(item.period).format(
                      isVAT
                        ? commonMonthYearSpaceFormat
                        : commonMonthDateYearSpaceFormat,
                    )}
                  </span>
                  {compareDates(item.period) || !isVAT ? (
                    <span
                      className="remove-system-icon"
                      onClick={() => setDeletedId(item._id)}>
                      <TrashIcon />
                    </span>
                  ) : (
                    <span className="remove-system-icon"></span>
                  )}
                </div>
              ))}
          </div>
          {deletedId && (
            <AttentionModal
              cancelBtnTitle="Cancel"
              isHideAttentionText
              isOpen={!!deletedId}
              closeModal={closeDeleteModal}
              confirmAction={deleteItem}
              confirmBtnTitle="Delete"
              hideCloseBtn
              content={<DeleteRate />}
            />
          )}
        </Spin>
      </div>
    </Modal>
  );
};

export default SystemInfoHistory;
