import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ActivityReportIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 19 11"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0997 4.64362H15.6997C15.0933 4.64065 14.511 4.88076 14.083 5.31028L10.3997 8.99362V1.03528C10.3971 0.613821 10.1408 0.235457 9.75033 0.0767277C9.35989 -0.0820013 8.91228 0.0101882 8.61634 0.310282L4.59134 4.33528C4.39581 4.53387 4.12836 4.64506 3.84967 4.64362H1.45801C1.11283 4.64362 0.833008 4.92344 0.833008 5.26862C0.833008 5.61379 1.11283 5.89362 1.45801 5.89362H3.84967C4.47417 5.90605 5.0765 5.66211 5.51634 5.21862L9.13301 1.54362V9.50195C9.13215 9.92215 9.38583 10.301 9.77467 10.4603C9.9007 10.5158 10.037 10.5442 10.1747 10.5436C10.4507 10.5438 10.715 10.4326 10.908 10.2353L14.9663 6.19362C15.1617 6.00093 15.4253 5.89312 15.6997 5.89362H18.0997C18.4449 5.89362 18.7247 5.61379 18.7247 5.26862C18.7247 4.92344 18.4449 4.64362 18.0997 4.64362Z" />
    </svg>
  );
};

export default ActivityReportIcon;
