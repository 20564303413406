import moment from '../constants/moment';

// Function to compare endDate with the current date
export const compareEndDate = (a: any, b: any) => {
  const endDateA = a.endDate ? moment(a.endDate) : null;
  const endDateB = b.endDate ? moment(b.endDate) : null;
  const currentDate = moment();
  const todayStart = moment().startOf('day');

  if (!endDateA && !endDateB) {
    return 0; // No change in order
  } else if (
    endDateA &&
    endDateA.isBefore(currentDate) &&
    !endDateA.isSame(todayStart, 'day')
  ) {
    return 1; // Move items with null or endDate before today to the end
  } else if (
    endDateB &&
    endDateB.isBefore(currentDate) &&
    !endDateB.isSame(todayStart, 'day')
  ) {
    return -1; // Move items with null or endDate before today to the end
  } else {
    return 0; // No change in order
  }
};

export const compareDates = (date: string) => {
  if (!date) {
    return false;
  }

  const currentDate = moment();
  const inputDate = moment(date);
  return inputDate.isSameOrAfter(currentDate, 'month');
};
export const compareBeforeDates = (date: string) => {
  if (!date) {
    return false;
  }

  const currentDate = moment().startOf('day');
  const inputDate = moment(date).startOf('day');
  return inputDate.isBefore(currentDate);
};

export const compareByMonthYear = (date1: string, date2: string) => {
  const formattedDate1 = moment(date1).format('YYYY-MM');
  const formattedDate2 = moment(date2).format('YYYY-MM');
  return formattedDate1 === formattedDate2;
};
