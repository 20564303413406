import { Modal } from 'antd';
import Textarea from '../../../components/textarea/Textarea';
import { useState } from 'react';
import { IInvoiceData } from '../../../common/api/services/InvoiceApi/types/types';
import Button from '../../../components/button/Button';

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  selectedInvoice: IInvoiceData;
  onSubmit: (invoiceId: string, text: string) => void;
}

const PaymentCommentModal = (props: IProps) => {
  const { isOpen, onCloseModal, selectedInvoice, onSubmit } = props;
  const [inputValue, setInputValue] = useState(
    selectedInvoice.paymentComment || '',
  );
  return (
    <Modal
      open={isOpen}
      onCancel={onCloseModal}
      footer={null}
      centered
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <h4>Payment comment</h4>
        <Textarea
          label="Payment comment"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          autoSize
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            buttonSize="medium"
            buttonStyle="error"
            buttonWidth={200}
            onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            buttonWidth={200}
            onClick={() => onSubmit(selectedInvoice.internalId, inputValue)}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentCommentModal;
