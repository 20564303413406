import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setConfirmationUrl,
  clearOnboardingWorkPermit,
  putFileOnboardingPersonalInfo,
  setIsChangeNationality,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
  setUserNationality,
  updateOnboardingWorkPermit,
  setConsultantEmail,
  updateOnboardingAvsAhv,
  updateAvsAhvWorkForm,
  setOnboardingTaxAtSouce,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../../common/api/services/FileApi/enums/RequestEnums';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IOnboardingSaveOptions, IPersonalInfo } from './../types';
import {
  saveDraftWorkPermitAsConsultant,
  saveDraftWorkPermitAsEmployee,
  savePublishWorkPermitAsConsultant,
  savePublishWorkPermitAsEmployee,
} from './workPermitAsyncThunk';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftPersonalInformationAsEmployee = createAsyncThunk(
  'onboarding/sendToDraftPersonalInformation',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { personalInfo, tabStatus, consultantId, isChangeNationality } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[0] === OnboardingRequestStatuses.empty ||
      tabStatus[0] === OnboardingRequestStatuses.draft;
    const { moveToNextPage, redirectRootRout, useNewEmail } = options;

    const dataQuery: IQueryParameters<IPersonalInfo> = {
      key: OnboardingRequestKeys.personalInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: personalInfo,
    };

    if (useNewEmail !== null) {
      dataQuery.options = { useNewEmail };
    }

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putPersonalInformationAsEmployee(
          consultantId,
          dataQuery,
        );
      if (isSuccess) {
        dispatch(setConfirmationUrl(data.confirmationUrl || ''));
        dispatch(setConsultantEmail(data.email));
        dispatch(
          setOnboardingTabStatus({
            key: 6,
            value: data.consultantOnboarding?.ssnInformation?.tab?.status,
          }),
        );
        dispatch(
          updateAvsAhvWorkForm(
            data.consultantOnboarding?.ssnInformation?.workForm,
          ),
        );
        dispatch(
          setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: data.consultantOnboarding?.taxAtSource.tab.status,
          }),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 0,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        if (
          data?.consultantOnboarding?.personalInformation?.workForm ===
          'corporation'
        ) {
          dispatch(
            setOnboardingTabStatus({
              key: 7,
              value: OnboardingRequestStatuses.published,
            }),
          );
        }
        if (moveToNextPage) {
          if (personalInfo.nationality === 'Switzerland') {
            dispatch(clearOnboardingWorkPermit());
            dispatch(updateOnboardingWorkPermit({ hasWorkPermit: false }));
            dispatch(
              updateOnboardingWorkPermit({ selfHandledWorkPermit: false }),
            );
            dispatch(savePublishWorkPermitAsEmployee());
          } else if (
            personalInfo.nationality !== 'Switzerland' &&
            isChangeNationality
          ) {
            dispatch(
              setOnboardingTabStatus({
                key: 3,
                value: OnboardingRequestStatuses.draft,
              }),
            );
            dispatch(saveDraftWorkPermitAsEmployee({}));
          }
          dispatch(setIsChangeNationality(false));
          dispatch(push(getOnboardingRedirect(tabStatus, 0, redirectRootRout)));
        }
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'personalInfo',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveToConfirmPersonalInformationAsEmployee = createAsyncThunk(
  'onboarding/sendToConfirmPersonalInformation',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { personalInfo, tabStatus, consultantId, isChangeNationality } = (
      getState() as IRootState
    ).onboarding;
    const { moveToNextPage, redirectRootRout, useNewEmail } = options;

    const dataQuery: IQueryParameters<IPersonalInfo> = {
      key: OnboardingRequestKeys.personalInformation,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: personalInfo,
    };

    if (useNewEmail !== null) {
      dataQuery.options = { useNewEmail };
    }

    const { isSuccess, data } =
      await onboardingApi.putPersonalInformationAsEmployee(
        consultantId,
        dataQuery,
      );
    if (isSuccess) {
      dispatch(setConfirmationUrl(data.confirmationUrl || ''));
      dispatch(setConsultantEmail(data.email));
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: data.consultantOnboarding?.ssnInformation?.tab?.status,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
      dispatch(
        updateAvsAhvWorkForm(
          data.consultantOnboarding?.ssnInformation?.workForm,
        ),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: data.consultantOnboarding?.taxAtSource.tab.status,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
      if (
        data?.consultantOnboarding?.personalInformation?.workForm ===
        'corporation'
      ) {
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: OnboardingRequestStatuses.published,
          }),
        );
      }
      if (moveToNextPage) {
        if (personalInfo.nationality === 'Switzerland') {
          dispatch(clearOnboardingWorkPermit());
          dispatch(updateOnboardingWorkPermit({ hasWorkPermit: false }));
          dispatch(
            updateOnboardingWorkPermit({ selfHandledWorkPermit: false }),
          );
          dispatch(savePublishWorkPermitAsEmployee());
        } else if (
          personalInfo.nationality !== 'Switzerland' &&
          isChangeNationality
        ) {
          dispatch(
            setOnboardingTabStatus({
              key: 3,
              value: OnboardingRequestStatuses.toConfirm,
            }),
          );
          dispatch(saveDraftWorkPermitAsEmployee({}));
        }
        dispatch(setIsChangeNationality(false));
        dispatch(push(getOnboardingRedirect(tabStatus, 0, redirectRootRout)));
      }
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'personalInfo',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftPersonalInformationAsConsultant = createAsyncThunk(
  'onboarding/saveDraftPersonalInformationAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { personalInfo, tabStatus, isChangeNationality } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[0] === OnboardingRequestStatuses.empty ||
      tabStatus[0] === OnboardingRequestStatuses.draft;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IPersonalInfo> = {
      key: OnboardingRequestKeys.personalInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: personalInfo,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putPersonalInformationAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        dispatch(
          setOnboardingTabStatus({
            key: 6,
            value: data.consultantOnboarding?.ssnInformation?.tab?.status,
          }),
        );
        dispatch(
          updateAvsAhvWorkForm(
            data.consultantOnboarding?.ssnInformation?.workForm,
          ),
        );
        dispatch(
          setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: data.consultantOnboarding?.taxAtSource.tab.status,
          }),
        );
        dispatch(
          setOnboardingTabStatus({
            key: 0,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        if (moveToNextPage) {
          if (personalInfo.nationality === 'Switzerland') {
            dispatch(clearOnboardingWorkPermit());
            dispatch(updateOnboardingWorkPermit({ hasWorkPermit: false }));
            dispatch(
              updateOnboardingWorkPermit({ selfHandledWorkPermit: false }),
            );
            dispatch(savePublishWorkPermitAsConsultant());
          } else if (
            personalInfo.nationality !== 'Switzerland' &&
            isChangeNationality
          ) {
            dispatch(
              setOnboardingTabStatus({
                key: 3,
                value: OnboardingRequestStatuses.draft,
              }),
            );
            dispatch(saveDraftWorkPermitAsConsultant({}));
          }
          dispatch(setIsChangeNationality(false));
          dispatch(
            push(
              getOnboardingRedirect(
                tabStatus,
                0,
                redirectRootRout,
                false,
                true,
              ),
            ),
          );
        }
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'personalInfo',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const savePublishPersonalInformationAsEmployee = createAsyncThunk(
  'onboarding/savePublishPersonalInformation',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const {
      personalInfo,
      tabStatus,
      consultantId,
      isChangeNationality,
      userNationality,
      useNewEmail,
    } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<IPersonalInfo> = {
      key: OnboardingRequestKeys.personalInformation,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: personalInfo,
    };

    if (useNewEmail !== null) {
      dataQuery.options = { useNewEmail };
    }

    const { isSuccess, data } =
      await onboardingApi.putPersonalInformationAsEmployee(
        consultantId,
        dataQuery,
      );
    if (isSuccess) {
      dispatch(setConfirmationUrl(data.confirmationUrl || ''));
      dispatch(setConsultantEmail(data.email));
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: data.consultantOnboarding?.ssnInformation?.tab?.status,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
      if (
        data?.consultantOnboarding?.personalInformation?.workForm ===
        'corporation'
      ) {
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: OnboardingRequestStatuses.published,
          }),
        );
      }
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: data.consultantOnboarding?.taxAtSource.tab.status,
        }),
      );
      dispatch(
        updateAvsAhvWorkForm(
          data.consultantOnboarding?.ssnInformation?.workForm,
        ),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.published,
          isAdmin: true,
        }),
      );
      const isChangedFromSwitzerland =
        personalInfo.nationality !== 'Switzerland' &&
        tabStatus[3] === OnboardingRequestStatuses.published &&
        isChangeNationality &&
        userNationality === 'Switzerland';

      const isTabStatusesPublished = tabStatus.every(
        (item) => item === OnboardingRequestStatuses.published,
      );

      if (
        personalInfo.nationality === 'Switzerland' &&
        tabStatus[3] !== OnboardingRequestStatuses.published
      ) {
        dispatch(clearOnboardingWorkPermit());
        dispatch(updateOnboardingWorkPermit({ hasWorkPermit: false }));
        dispatch(updateOnboardingWorkPermit({ selfHandledWorkPermit: false }));
        dispatch(savePublishWorkPermitAsEmployee());
      } else if (isChangedFromSwitzerland) {
        dispatch(
          setOnboardingTabStatus({
            key: 3,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(saveDraftWorkPermitAsEmployee({}));
        if (isTabStatusesPublished) {
          dispatch(
            push(getOnboardingRedirect(tabStatus, 3, redirectRootRout, true)),
          );
        }
      }
      dispatch(setIsChangeNationality(false));
      dispatch(
        setUserNationality(
          data?.consultantOnboarding?.personalInformation?.nationality || '',
        ),
      );
      dispatch(push(getOnboardingRedirect(tabStatus, 0, redirectRootRout)));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'personalInfo',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishPersonalInformationAsConsultant = createAsyncThunk(
  'onboarding/savePublishPersonalInformationAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { personalInfo, tabStatus, isChangeNationality } = (
      getState() as IRootState
    ).onboarding;

    const dataQuery: IQueryParameters<IPersonalInfo> = {
      key: OnboardingRequestKeys.personalInformation,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: personalInfo,
    };

    const { isSuccess, data } =
      await onboardingApi.putPersonalInformationAsConsultant(dataQuery);
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: data.consultantOnboarding?.ssnInformation?.tab?.status,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 0,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: data.consultantOnboarding?.taxAtSource.tab.status,
        }),
      );
      dispatch(setOnboardingTaxAtSouce(data.consultantOnboarding?.taxAtSource));
      dispatch(
        updateAvsAhvWorkForm(
          data.consultantOnboarding?.ssnInformation?.workForm,
        ),
      );
      if (
        personalInfo.nationality === 'Switzerland' &&
        tabStatus[3] !== OnboardingRequestStatuses.published
      ) {
        dispatch(clearOnboardingWorkPermit());
        dispatch(updateOnboardingWorkPermit({ hasWorkPermit: false }));
        dispatch(updateOnboardingWorkPermit({ selfHandledWorkPermit: false }));
        dispatch(savePublishWorkPermitAsConsultant());
      } else if (
        personalInfo.nationality !== 'Switzerland' &&
        tabStatus[3] === OnboardingRequestStatuses.published &&
        isChangeNationality
      ) {
        dispatch(
          setOnboardingTabStatus({
            key: 3,
            value: OnboardingRequestStatuses.draft,
          }),
        );
        dispatch(saveDraftWorkPermitAsConsultant({}));
      }
      dispatch(setIsChangeNationality(false));
      dispatch(
        push(
          getOnboardingRedirect(tabStatus, 0, redirectRootRout, false, true),
        ),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'personalInfo',
          fieldValue: data,
        }),
      );
    }
  },
);

export const uploadFileOnboardingPersonalInfo = createAsyncThunk(
  'onboarding/uploadFileOnboardingPersonalInfo',
  async (files: File[], { dispatch }) => {
    const response = await fileApi.uploadFile(
      files,
      UploadEntityId.personalInformation,
      UploadEntity.consultantOnboarding,
    );

    if (response) {
      dispatch(putFileOnboardingPersonalInfo(response));
    }
  },
);
