import { ApiServiceBase } from '../../ApiServiceBase';
import { IEmployeeDto } from './types/IEmployeeDto';
import { UserRoutes } from './enums/UserRoutes';
import { IGetListDto } from '../../types/IGetListDto';
import { ICreateEmployeeRequest } from './types/ICreateEmployeeRequest';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { ICreateEmployeeResponse } from './types/ICreateEmployeeResponse';
import { IInviteEmployeeResponse } from './types/IInviteEmployeeResponse';
import { IGetConsultantInfoResponse } from '../OnboardingApi/types/IGetConsultantInfoResponse';
import { ISort } from '../../../../redux/types';
import { getQueryParams } from '../../utils/getQueryParams';

class UserApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1`;

  getEmployeesList(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IEmployeeDto> | null> {
    return this.get<IGetListDto<IEmployeeDto>>(
      `/${UserRoutes.user}/${UserRoutes.employee}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch(() => null);
  }

  createEmployee(
    requestParams: ICreateEmployeeRequest,
  ): Promise<ICreateEmployeeResponse> {
    return this.post<ICreateEmployeeRequest, IInviteEmployeeResponse>(
      '/' + UserRoutes.admin,
      requestParams,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  resendEmployee(id: string): Promise<ICreateEmployeeResponse> {
    return this.post<ICreateEmployeeRequest, IInviteEmployeeResponse>(
      '/' + UserRoutes.admin + `/${id}/reinvite`,
    )
      .then((response) => ({
        isSuccess: true,
        data: response,
      }))
      .catch((e) => ({
        isSuccess: false,
        data: parsErrorMessageObj(e),
      }));
  }

  getUserProfile(): Promise<IGetConsultantInfoResponse | null> {
    return this.get<IGetConsultantInfoResponse>(`/${UserRoutes.user}`)
      .then((response) => response)
      .catch(() => null);
  }

  getAllManagers(): Promise<IEmployeeDto[]> {
    return this.get<IGetListDto<IEmployeeDto>>(
      `/${UserRoutes.user}/${UserRoutes.employee}`,
      { query: { status: 'active', sort: 'asc', sortBy: 'firstName' } },
    )
      .then((res) => res.result)
      .catch(() => []);
  }
  getManagers(): Promise<IEmployeeDto[]> {
    return this.get<IGetListDto<IEmployeeDto>>(
      `/${UserRoutes.user}/${UserRoutes.employee}`,
      {
        query: {
          status: 'active',
          sort: 'asc',
          sortBy: 'firstName',
          role: ['manager', 'admin'],
        },
      },
    )
      .then((res) => res.result)
      .catch(() => []);
  }
}

export const userApi = new UserApi();
