import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IClientContractModalOptions,
  IState,
  TAdditionalInfo,
  TValidationErrorKeys,
} from './types';
import {
  IChildRequest,
  IChildAllowancesRequest,
} from '../../../common/api/services/OnboardingApi/types/IChildAllowanceRequest';
import {
  ICompany,
  IWorkContractRequest,
} from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { IGetConsultantInfoResponse } from '../../../common/api/services/OnboardingApi/types/IGetConsultantInfoResponse';
import {
  IClient,
  IClientContractRequest,
} from '../../../common/api/services/OnboardingApi/types/IClientContract';
import { IUploadFileResponse } from '../../../common/api/services/FileApi/types/IUploadFileResponse';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { FeesOptions } from '../../../common/constants/feesOptions';
import { SourceOfFeesOptions } from '../../../common/constants/sourceOfFeesOptions';
import { initialClientContact } from '../clients/sliceReducer';
import { compareEndDate } from '../../../common/utils/compareEndDate';
import { createIndexMapByKey } from '../../../common/utils/onboardingSortHelper';
import { ITaxItem } from '../../../common/api/services/OnboardingApi/types/ITaxSource';

let ID = 10;

const initialState: IState = {
  consultantId: '',
  consultantStatus: '',
  consultantEmail: '',
  publicId: 0,
  personalInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    dateOfBirth: '',
    passportExpirationDate: '',
    gender: '',
    nationality: '',
    language: '',
    maritalStatus: '',
    maritalStatusDetailsMarriageDate: '',
    maritalStatusDetailsSpouseName: '',
    maritalStatusDetailsSpouseLastName: '',
    maritalStatusDetailsSpouseDateOfBirth: '',
    maritalStatusDetailsSpouseNationality: '',
    maritalStatusDetailsIsSpouseSwissResident: true,
    maritalStatusDetailsSpouseSSN: '',
    maritalStatusDetailsSpousalIncome: true,
    maritalStatusDetailsSpouseWorkCountry: '',
    maritalStatusDetailsSpouseWorkCity: '',
    maritalStatusDetailsSpouseWorkCanton: '',
    maritalStatusDetailsSpouseStartedWorkingFrom: '',
    workForm: 'payroll',
    hasSwissAddress: true,
    swissAddressStreet: '',
    swissAddressNumber: '',
    swissAddressState: '',
    swissAddressPostCode: '',
    swissAddressCity: '',
    hasAbroadAddress: true,
    abroadAddressStreet: '',
    abroadAddressPostCode: '',
    abroadAddressNumber: '',
    abroadAddressState: '',
    abroadAddressCity: '',
    abroadAddressCountry: '',
    abroadAddressUseAsCorrespondenceLocation: true,
    abroadAddressTotalAttendance: '',
    documents: [],
  },
  workPermit: {
    hasWorkPermit: true,
    documentType: '',
    permitType: '',
    permitCanton: '',
    startDate: '',
    endDate: '',
    basisOfLegalStay: '',
    selfHandledWorkPermit: false,
    comment: '',
    documents: [],
    hasStartedWorkPermitProcess: false,
    hasProofOfOngoingProcess: false,
    isReligious: null,
  },
  workContract: {
    selectedClientIdx: 0,
    selectedDublicateIdx: 0,
    useExpensesForOptimization: false,
    useMealAllowance: false,
    companies: [
      {
        _id: '',
        name: '',
        email: '',
        phone: '',
        clientId: null,
        hasAdditionalAccidentInsurance: true,
        incomeLossInsurance: '',
        lossOfIncome: 0,
        hasCBA: false,
        cbaOption: null,
        hasTreasury: false,
        treasuryPaymentDate: '',
        hasSalaryAmount: false,
        salaryAmount: 0,
        invoiceCurrency: '',
        paymentCurrency: '',
        jobTitle: '',
        projectDescription: '',
        startDate: '',
        endDate: '',
        indefinite: true,
        rate: 0,
        type: '',
        currency: '',
        workLocation: {
          type: '',
          hybridActivity: '',
          officeAddress: '',
          hybridAddress: '',
        },
        otherJob: {
          active: true,
          isPrimaryJob: true,
          numeriqTime: 0,
        },
        overtimeAllowed: true,
        doubleTimeAllowed: true,
        receivedWorkContract: false,
        workContracLink: '',
        professionalAccident: 0.2,
        nonProfessionalAccident: 0.99,
        hasRepresentativeAllowance: false,
        representativeAmount: '',
        numeriqOffice: '',
        isReceived: false,
        isCctOfficial: false,
        seller: '',
      },
    ],
  },
  clientContract: {
    selectedClientIdx: 0,
    clients: [
      {
        _id: '',
        companyId: '',
        shippingMethod: '',
        shippingMethodDetails: {
          postalAddress: '',
          firstName: '',
          lastName: '',
          portalUrl: '',
        },
        signatories: [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ],
        invoiceries: [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ],
        reminders: [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ],
        agencyEmailsCC: [],
        clientContacts: [initialClientContact],
        startDate: '',
        endDate: '',
        indefinite: true,
        workType: 'Full time',
        workHoursPerWeek: '',
        paymentTermRange: '',
        paymentTerm: '',
        reimbursableExpenses: true,
        clientOrContractComment: '',
        isAgencyInvolved: false,
        agencyName: '',
        agencyEmail: '',
        agencyCountry: '',
        vatRate: '',
        receivedClientContract: false,
        clientContractLink: '',
        agencyConfirmed: false,
        addresses: [
          {
            street: '',
            postCode: '',
            city: '',
            country: '',
            addressId: null,
            vat: undefined,
            addressLines: [],
          },
        ],
        agencyLink: '',
        billRate: '',
        agencyMargin: 0,
        numeriqFeesType: FeesOptions.percentage,
        numeriqFees: 0,
        sourceOfFees: SourceOfFeesOptions.billRate,
        minFee: 0,
        maxFee: 0,
        contractNumber: '',
        wageType: '',
        numeriqOffice: '',
        isReceived: false,
        timesheetSubmission: '',
        enabledPurchaseOrders: false,
        purchaseOrders: [
          {
            attachments: [],
            endDate: '',
            poComment: '',
            poNumber: '',
            startDate: '',
            indefinite: false,
          },
        ],
        contractComment: '',
        bankAccount: '',
      },
    ],
  },
  bankAccount: {
    hasBankAccount: true,
    hasIBAN: true,
    bankAccountDetails: '',
    iban: '',
    swift: '',
    currency: '',
    npa: '',
    accountOwner: '',
    address: '',
    additionalAddress: '',
    comment: '',
    bankName: '',
    bankAddress: '',
    bankAdditionalAddress: '',
    bankCountry: '',
    bankCity: '',
    bankChangeOption: false,
    dateOfBankChange: '',
  },
  ssnInformation: {
    hasSSN: true,
    hasLppRegistration: false,
    ssn: '',
    lppLevel: '',
    lppAmount: 0,
    comment: '',
    annualSalaryAmount: 0,
    hasRegistration: false,
    workForm: 'corporation',
  },
  childAllowances: {
    hasChildren: true,
    children: [
      {
        id: 8,
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        SSNumber: '',
        useFamilyAllowance: true,
      },
    ],
    documents: [],
    notes: '',
  },
  taxAtSource: {
    isSubject: null,
    taxItems: [],
  },
  additionalInfo: {
    contacts: [],
  },
  validationError: {
    personalInfo: {},
    workPermit: {},
    workContract: {},
    clientContract: {},
    bankAccount: {},
    childAllowances: {},
    ssnInformation: {},
    additionalInfo: {},
    taxAtSource: {},
  },
  tabStatus: [
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.empty,
    OnboardingRequestStatuses.toConfirm,
    OnboardingRequestStatuses.empty,
  ],
  isOpenOnboardingLastStepModal: false,
  isChangeNationality: false,
  userNationality: '',
  isFirstRender: true,
  isShowClientContractModal: false,
  clientContractModalOptions: [],
  isPublishedBeforeNewClient: false,
  isHasDeletedClient: false,
  confirmationUrl: '',
  useNewEmail: null,
  oldConsultantEmail: '',
  initialClientContractOrder: {},
  initialWorkContractOrder: {},
};

const onboardingReducer = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setIsChangeNationality(state, action) {
      state.isChangeNationality = action.payload;
    },
    setUserNationality(state, action) {
      state.userNationality = action.payload;
    },
    setOnboardingConsultantInternalInfo(
      state,
      action: PayloadAction<{ id: string; status: string }>,
    ) {
      state.consultantId = action.payload.id;
      state.consultantStatus = action.payload.status;
    },
    setIsOpenOnboardingLastStepModal(state, action: PayloadAction<boolean>) {
      state.isOpenOnboardingLastStepModal = action.payload;
    },
    setOnboardingErrorValidation(
      state,
      action: PayloadAction<{
        fieldName: TValidationErrorKeys;
        fieldValue: Record<string, string>;
      }>,
    ) {
      state.validationError[action.payload.fieldName] =
        action.payload.fieldValue;
    },
    clearOnboardingErrorsValidation(state) {
      state.validationError = initialState.validationError;
    },
    clearOnboardingState: () => initialState,
    updateOnboardingPersonalInfo(
      state,
      action: PayloadAction<
        Record<string, string | boolean | IUploadFileResponse[] | number>
      >,
    ) {
      state.personalInfo = {
        ...state.personalInfo,
        ...action.payload,
      };
    },
    updateOnboardingWorkPermit(
      state,
      action: PayloadAction<
        Record<
          string,
          string | boolean | IChildRequest[] | IUploadFileResponse[]
        >
      >,
    ) {
      state.workPermit = {
        ...state.workPermit,
        ...action.payload,
      };
    },
    setOnboardingWorkContract(
      state,
      action: PayloadAction<
        Partial<IGetConsultantInfoResponse & { isNewClient: boolean }>
      >,
    ) {
      state.initialWorkContractOrder = createIndexMapByKey(
        action.payload?.consultantOnboarding?.workContract?.companies,
        '_id',
      );
      state.initialClientContractOrder = createIndexMapByKey(
        action.payload?.consultantOnboarding?.clientContract?.clients,
        '_id',
      );

      const companies =
        action.payload?.consultantOnboarding?.workContract?.companies
          .slice()
          .sort(compareEndDate);

      const clients =
        action.payload?.consultantOnboarding?.clientContract?.clients
          .slice()
          .sort(compareEndDate);

      const useExpensesForOptimization =
        action.payload?.consultantOnboarding?.workContract
          ?.useExpensesForOptimization ?? false;
      const useMealAllowance =
        action.payload?.consultantOnboarding?.workContract?.useMealAllowance ??
        false;
      if (clients) {
        state.clientContract.clients = clients;
        if (action.payload?.isNewClient) {
          state.clientContract.selectedClientIdx =
            state.clientContract.clients.length - 1;
        }
      }
      if (companies) {
        state.workContract.companies = companies;
        if (action.payload?.isNewClient) {
          state.workContract.selectedClientIdx =
            state.workContract.companies.length - 1;
        }
      }

      state.workContract.useExpensesForOptimization =
        useExpensesForOptimization;
      state.workContract.useMealAllowance = useMealAllowance;
    },
    updateOnboardingWorkContract(
      state,
      action: PayloadAction<
        Record<string, number | string | null | boolean> & {
          key?: keyof ICompany;
        } & Partial<IWorkContractRequest>
      >,
    ) {
      const { selectedClientIdx } = state.workContract;
      const {
        key,
        hasTreasury,
        hasSalaryAmount,
        hasCBA,
        hasRepresentativeAllowance,
      } = action.payload;
      if (key === 'otherJob') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, ...newValue } = action.payload;

        state.workContract.companies[selectedClientIdx].otherJob = {
          ...(state.workContract.companies[selectedClientIdx].otherJob || {
            active: true,
            isPrimaryJob: true,
            numeriqTime: 0,
          }),
          ...newValue,
        };
        return;
      }
      if (key === 'workLocation') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, ...rest } = action.payload;
        state.workContract.companies[selectedClientIdx].workLocation = {
          ...state.workContract.companies[selectedClientIdx].workLocation,
          ...rest,
        };
        return;
      }
      if (hasTreasury === false)
        state.workContract.companies[selectedClientIdx].treasuryPaymentDate =
          '';
      if (hasCBA === false)
        state.workContract.companies[selectedClientIdx].cbaOption = null;
      if (hasCBA === false)
        state.workContract.companies[selectedClientIdx].isCctOfficial = false;
      if (hasSalaryAmount === false)
        state.workContract.companies[selectedClientIdx].salaryAmount = 0;
      if (hasRepresentativeAllowance === false)
        state.workContract.companies[selectedClientIdx].representativeAmount =
          '0';
      state.workContract.companies[selectedClientIdx] = {
        ...state.workContract.companies[selectedClientIdx],
        ...action.payload,
      };
    },
    updateOptimizationOnboardingWorkContract(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.workContract.useExpensesForOptimization = action.payload;
    },
    updateUseMealAllowanceOnboardingWorkContract(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.workContract.useMealAllowance = action.payload;
    },
    updateOnboardingWorkContractCheckbox(
      state,
      action: PayloadAction<'receivedWorkContract'>,
    ) {
      const { selectedClientIdx } = state.workContract;
      const { payload } = action;
      const { [payload]: value } =
        state.workContract.companies[selectedClientIdx];
      if (payload === 'receivedWorkContract' && value) {
        state.workContract.companies[selectedClientIdx].workContracLink = '';
      }
      state.workContract.companies[selectedClientIdx][payload] = !value;
    },
    updateOnboardingClientContractAddress(state, action: PayloadAction<any>) {
      const { selectedClientIdx } = state.clientContract;
      const { index, ...restInfo } = action.payload;
      if (typeof index === 'number') {
        const addressId = restInfo.addressId
          ? restInfo.addressId.toString()
          : null;

        state.clientContract.clients[selectedClientIdx].addresses[index] = {
          ...state.clientContract.clients[selectedClientIdx].addresses[index],
          ...restInfo,
          addressId,
        };
      }
    },
    addNewAddressOnboardingClientContract(state) {
      const { selectedClientIdx } = state.workContract;
      state.clientContract.clients[selectedClientIdx].addresses.push(
        initialState.clientContract.clients[0].addresses[0],
      );
    },
    dublicateAddressOnBoardingClientContract(
      state,
      action: PayloadAction<{
        postCode: string;
        country: string;
        city: string;
      }>,
    ) {
      const { postCode, city, country } = action.payload;
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].addresses.push({
        street: '',
        postCode,
        city,
        country,
        addressId: null,
        vat: undefined,
      });
    },
    addNewAddressLine(state, action: PayloadAction<number>) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].addresses[
        action.payload
      ].addressLines = [
        ...(state.clientContract.clients[selectedClientIdx].addresses[
          action.payload
        ].addressLines || []),
        '',
      ];
    },
    changeAddressLine(
      state,
      action: PayloadAction<{
        index: number;
        lineIndex: number;
        value: string;
      }>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const client = state.clientContract.clients?.[selectedClientIdx];
      const address = client?.addresses?.[action.payload.index];
      const addressLines = address?.addressLines;

      if (addressLines) {
        addressLines[action.payload.lineIndex] = action.payload.value;
      }
    },
    removeAddressLine(
      state,
      action: PayloadAction<{
        index: number;
        lineIndex: number;
      }>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].addresses[
        action.payload.index
      ].addressLines = state.clientContract.clients[
        selectedClientIdx
      ].addresses[action.payload.index].addressLines?.filter(
        (item, index) => index !== action.payload.lineIndex,
      );
    },
    setPublicId(state, action: PayloadAction<number>) {
      state.publicId = action.payload;
    },
    resetAddresses(state) {
      const { selectedClientIdx } = state.workContract;
      state.clientContract.clients[selectedClientIdx].addresses = [];
    },
    resetOnboardingWorkContractWorkLocation(state) {
      const { selectedClientIdx } = state.clientContract;
      state.workContract.companies[selectedClientIdx].workLocation =
        initialState.workContract.companies[0].workLocation;
    },
    resetOnboardingWorkContractPaymentCurrency(state) {
      state.workContract.companies[
        state.workContract.selectedClientIdx
      ].paymentCurrency = '';
    },
    setCurrentClientOnboardingContract(state, action: PayloadAction<number>) {
      state.workContract.selectedClientIdx = action.payload;
      state.clientContract.selectedClientIdx = action.payload;
    },
    removeAddressOnboardingClientContract(
      state,
      action: PayloadAction<number>,
    ) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].addresses.splice(action.payload, 1);
    },
    setOnboardingClientContract(
      state,
      action: PayloadAction<Partial<IGetConsultantInfoResponse>>,
    ) {
      state.initialClientContractOrder = createIndexMapByKey(
        action.payload?.consultantOnboarding?.clientContract?.clients,
        '_id',
      );

      const clients =
        action.payload?.consultantOnboarding?.clientContract?.clients.map(
          (item) => {
            if (item.purchaseOrders && item.purchaseOrders.length) {
              return item;
            }
            return {
              ...item,
              purchaseOrders: [
                {
                  attachments: [],
                  endDate: '',
                  poComment: '',
                  poNumber: '',
                  startDate: '',
                  indefinite: false,
                },
              ],
            };
          },
        );
      if (clients)
        state.clientContract.clients = clients.slice().sort(compareEndDate);
    },
    setOnboardingTaxAtSouce(state, action: PayloadAction<Partial<any>>) {
      state.taxAtSource.isSubject = action.payload.isSubject;
      state.taxAtSource.taxItems = action.payload.taxItems;
    },
    updateOnboardingClientContract(
      state,
      action: PayloadAction<
        Record<string, number | string | boolean | null> & {
          key?: keyof IClient;
        } & Partial<IClientContractRequest>
      >,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { key } = action.payload;
      if (key === 'shippingMethodDetails') {
        state.clientContract.clients[selectedClientIdx].shippingMethodDetails =
          {
            ...state.clientContract.clients[selectedClientIdx]
              .shippingMethodDetails,
            ...action.payload,
          };
        return;
      }

      state.clientContract.clients[selectedClientIdx] = {
        ...state.clientContract.clients[selectedClientIdx],
        ...action.payload,
      };
    },
    addNewSignatoryOnboardingClientContract(state) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].signatories =
        state.clientContract.clients[selectedClientIdx]?.signatories ?? [];
      state.clientContract.clients[selectedClientIdx].signatories.push(
        initialState.clientContract.clients[0].signatories[0],
      );
    },
    addNewInvoiceryOnboardingClientContract(state) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].invoiceries =
        state.clientContract.clients[selectedClientIdx]?.invoiceries ?? [];
      state.clientContract.clients[selectedClientIdx].invoiceries.push(
        initialState.clientContract.clients[0].invoiceries[0],
      );
    },
    addNewReminderOnboardingClientContract(state) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].reminders =
        state.clientContract.clients[selectedClientIdx]?.reminders ?? [];
      state.clientContract.clients[selectedClientIdx].reminders.push(
        initialState.clientContract.clients[0].reminders[0],
      );
    },
    removeSignatoryOnboardingClientContract(
      state,
      action: PayloadAction<number>,
    ) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].signatories.splice(action.payload, 1);
    },
    removeInvoiceryOnboardingClientContract(
      state,
      action: PayloadAction<number>,
    ) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].invoiceries.splice(action.payload, 1);
    },
    removeReminderOnboardingClientContract(
      state,
      action: PayloadAction<number>,
    ) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].reminders.splice(action.payload, 1);
    },
    updateOnboardingClientContractSignatory(
      state,
      action: PayloadAction<Record<string, number | string>>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { index, ...restInfo } = action.payload;
      if (typeof index === 'number') {
        if (!state.clientContract.clients[selectedClientIdx].signatories)
          state.clientContract.clients[selectedClientIdx].signatories = Array(
            index,
          ).fill({});
        state.clientContract.clients[selectedClientIdx].signatories[index] = {
          ...state.clientContract.clients[selectedClientIdx].signatories[index],
          ...restInfo,
        };
      }
    },
    updateOnboardingClientContractInvoicery(
      state,
      action: PayloadAction<Record<string, number | string>>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { index, ...restInfo } = action.payload;
      if (typeof index === 'number') {
        if (!state.clientContract.clients[selectedClientIdx].invoiceries)
          state.clientContract.clients[selectedClientIdx].invoiceries = Array(
            index,
          ).fill({});
        state.clientContract.clients[selectedClientIdx].invoiceries[index] = {
          ...state.clientContract.clients[selectedClientIdx].invoiceries[index],
          ...restInfo,
        };
      }
    },
    updateOnboardingClientContractReminder(
      state,
      action: PayloadAction<Record<string, number | string>>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { index, ...restInfo } = action.payload;
      if (typeof index === 'number') {
        if (!state.clientContract.clients[selectedClientIdx].reminders)
          state.clientContract.clients[selectedClientIdx].reminders = Array(
            index,
          ).fill({});
        state.clientContract.clients[selectedClientIdx].reminders[index] = {
          ...state.clientContract.clients[selectedClientIdx].reminders[index],
          ...restInfo,
        };
      }
    },
    updateOnboardingClientContractContact(
      state,
      action: PayloadAction<Record<string, number | string>>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { index, ...restInfo } = action.payload;
      if (typeof index === 'number') {
        if (!state.clientContract.clients[selectedClientIdx].clientContacts)
          state.clientContract.clients[selectedClientIdx].clientContacts =
            Array(index).fill({});
        state.clientContract.clients[selectedClientIdx].clientContacts[index] =
          {
            ...state.clientContract.clients[selectedClientIdx].clientContacts[
              index
            ],
            ...restInfo,
          };
      }
    },
    updateOnboardingClientContractCheckbox(
      state,
      action: PayloadAction<'receivedClientContract' | 'agencyConfirmed'>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      const { payload } = action;
      const { [payload]: value } =
        state.clientContract.clients[selectedClientIdx];
      if (payload === 'receivedClientContract' && value) {
        state.clientContract.clients[selectedClientIdx].clientContractLink = '';
      }
      if (payload === 'agencyConfirmed' && value) {
        state.clientContract.clients[selectedClientIdx].agencyLink = '';
      }
      state.clientContract.clients[selectedClientIdx][payload] = !value;
    },
    updateOnboardingClientContractShippingDetails(
      state,
      action: PayloadAction<any>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].shippingMethodDetails = {
        ...state.clientContract.clients[selectedClientIdx]
          .shippingMethodDetails,
        ...action.payload,
      };
    },
    resetOnboardingClientContractShippingDetails(
      state,
      action: PayloadAction<any>,
    ) {
      const { selectedClientIdx } = state.clientContract;
      state.clientContract.clients[selectedClientIdx].shippingMethodDetails =
        action.payload;
    },
    updateOnboardingBankAccount(
      state,
      action: PayloadAction<Record<string, string | boolean>>,
    ) {
      state.bankAccount = {
        ...state.bankAccount,
        ...action.payload,
      };
    },
    updateOnboardingChildAllowances(
      state,
      action: PayloadAction<Record<string, string | boolean | IChildRequest[]>>,
    ) {
      state.childAllowances = {
        ...state.childAllowances,
        ...action.payload,
      };
    },
    setOnboardingChildAllowances(
      state,
      action: PayloadAction<Partial<IChildAllowancesRequest>>,
    ) {
      const { children, ...restInfo } = action.payload;
      const newChildren =
        (children ?? []).length > 0
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            children!.map((child) => ({ id: ++ID, ...child }))
          : state.childAllowances.children;

      state.childAllowances = {
        ...state.childAllowances,
        ...restInfo,
        children: newChildren,
      };
    },
    addNewOnboardingChildAllowances(state) {
      state.childAllowances.children.push({
        ...initialState.childAllowances.children[0],
        id: ++ID,
      });
    },
    removeOnboardingChildAllowances(state, action: PayloadAction<number>) {
      const idx = state.childAllowances.children.findIndex(
        (item) => item.id === action.payload,
      );
      state.childAllowances.children = [
        ...state.childAllowances.children.slice(0, idx),
        ...state.childAllowances.children.slice(idx + 1),
      ];
    },
    updateOnboardingChildAllowancesChildArray(
      state,
      action: PayloadAction<{ id: number; field: Record<string, string> }>,
    ) {
      const idx = state.childAllowances.children.findIndex(
        (item) => item.id === action.payload.id,
      );
      state.childAllowances.children[idx] = {
        ...state.childAllowances.children[idx],
        ...action.payload.field,
      };
    },
    updateOnboardingAvsAhv(
      state,
      action: PayloadAction<Record<string, string | boolean | number>>,
    ) {
      // if (action.payload.hasSSN === false) {
      //   state.ssnInformation = {
      //     ...state.ssnInformation,
      //     ssn: initialState.ssnInformation.ssn,
      //     hasSSN: false,
      //   };
      //   return;
      // }
      state.ssnInformation = {
        ...state.ssnInformation,
        ...action.payload,
      };
    },
    updateAvsAhvWorkForm(state, action: PayloadAction<string>) {
      state.ssnInformation.workForm = action.payload;
    },
    clearOnboardingWorkPermit(state) {
      state.workPermit = {
        ...initialState.workPermit,
      };
      state.validationError = initialState.validationError;
    },
    clearOnboardinBankAccount(state) {
      state.bankAccount = {
        ...initialState.bankAccount,
      };
      state.validationError = initialState.validationError;
    },
    clearOnboardingChildAllowances(state) {
      state.childAllowances = {
        ...initialState.childAllowances,
        hasChildren: false,
        notes: state.childAllowances.notes,
      };
      state.validationError = initialState.validationError;
    },
    setOnboardingTabStatus(
      state,
      action: PayloadAction<{
        key: number;
        value: OnboardingRequestStatuses;
        isAdmin?: boolean;
      }>,
    ) {
      const { isAdmin } = action.payload;
      state.tabStatus[action.payload.key] = action.payload.value;
      const isLastStep = state.tabStatus.every((el) => {
        const isPublish = el === OnboardingRequestStatuses.published;
        const isToConfirm = el === OnboardingRequestStatuses.toConfirm;
        const isToVerify =
          isAdmin === true ? false : el === OnboardingRequestStatuses.toVerify;
        return isPublish || isToConfirm || isToVerify;
      });
      state.isOpenOnboardingLastStepModal = isLastStep && !state.isFirstRender;
    },
    updateOnboardingAdditionalInfo(
      state,
      action: PayloadAction<TAdditionalInfo>,
    ) {
      state.additionalInfo = {
        ...state.additionalInfo,
        ...action.payload,
      };
    },
    updateOnboardingAdditionalInfoItem(
      state,
      action: PayloadAction<{ id: string; field: Record<string, string> }>,
    ) {
      const idx = state.additionalInfo.contacts.findIndex(
        (item) => item._id === action.payload.id,
      );
      state.additionalInfo.contacts[idx] = {
        ...state.additionalInfo.contacts[idx],
        ...action.payload.field,
      };
    },
    putFileOnboardingPersonalInfo(
      state,
      action: PayloadAction<IUploadFileResponse[]>,
    ) {
      state.personalInfo.documents = [
        ...state.personalInfo.documents,
        ...action.payload,
      ];
    },
    removeOnboardingPersonalInfo(state, action: PayloadAction<string>) {
      const idx = state.personalInfo.documents.findIndex(
        (el) => el.originalFilename === action.payload,
      );

      state.personalInfo.documents = [
        ...state.personalInfo.documents.slice(0, idx),
        ...state.personalInfo.documents.slice(idx + 1),
      ];
    },
    putFileOnboardingWorkPermit(
      state,
      action: PayloadAction<IUploadFileResponse[]>,
    ) {
      state.workPermit.documents = [
        ...state.workPermit.documents,
        ...action.payload,
      ];
    },
    removeOnboardingWorkPermit(state, action: PayloadAction<string>) {
      const idx = state.workPermit.documents.findIndex(
        (el) => el.originalFilename === action.payload,
      );

      state.workPermit.documents = [
        ...state.workPermit.documents.slice(0, idx),
        ...state.workPermit.documents.slice(idx + 1),
      ];
    },
    putFileOnboardingChildAllowances(
      state,
      action: PayloadAction<IUploadFileResponse[]>,
    ) {
      state.childAllowances.documents = [
        ...state.childAllowances.documents,
        ...action.payload,
      ];
    },
    removeFileOnboardingChildAllowances(state, action: PayloadAction<string>) {
      const idx = state.childAllowances.documents.findIndex(
        (el) => el.originalFilename === action.payload,
      );

      state.childAllowances.documents = [
        ...state.childAllowances.documents.slice(0, idx),
        ...state.childAllowances.documents.slice(idx + 1),
      ];
    },
    setIsFirstRender(state, action: PayloadAction<boolean>) {
      state.isFirstRender = action.payload;
    },
    setIsShowClientContractModal(state, action: PayloadAction<boolean>) {
      state.isShowClientContractModal = action.payload;
    },
    setClientContractModalOptions(
      state,
      action: PayloadAction<IClientContractModalOptions[]>,
    ) {
      state.clientContractModalOptions = action.payload;
    },
    setIsPublishedBeforeNewClient(state, action: PayloadAction<boolean>) {
      state.isPublishedBeforeNewClient = action.payload;
    },
    setIsHasDeletedClient(state, action: PayloadAction<boolean>) {
      state.isHasDeletedClient = action.payload;
    },
    changeChildAllowanceComment(state, action: PayloadAction<string>) {
      state.childAllowances.notes = action.payload;
    },
    setConfirmationUrl(state, action: PayloadAction<string>) {
      state.confirmationUrl = action.payload;
    },
    setConsultantEmail(state, action: PayloadAction<string>) {
      state.consultantEmail = action.payload;
    },
    setUseNewEmail(state, action: PayloadAction<boolean | null>) {
      state.useNewEmail = action.payload;
    },
    setOldConsultantEmail(state, action: PayloadAction<string>) {
      state.oldConsultantEmail = action.payload;
    },
    setSelectedDublicateIdx(state, action: PayloadAction<number>) {
      state.workContract.selectedDublicateIdx = action.payload;
    },
    setSeller(state, action: PayloadAction<string>) {
      state.workContract.companies[
        state.workContract.selectedClientIdx
      ].seller = action.payload;
    },
    setClientContract(state, action) {
      state.clientContract.clients = action.payload;
    },
    setWorkContract(state, action) {
      state.workContract.companies = action.payload;
    },
    changeCCEmail(state, action) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].agencyEmailsCC[action.payload.index] = action.payload.value;
    },
    addCCEmail(state) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].agencyEmailsCC = state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].agencyEmailsCC
        ? [
            ...state.clientContract.clients[
              state.clientContract.selectedClientIdx
            ].agencyEmailsCC,
            '',
          ]
        : [''];
    },
    removeCCEmail(state, action) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].agencyEmailsCC = state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].agencyEmailsCC.filter((item, index) => index !== action.payload);
    },
    changePurchaseItemValue(state, action) {
      const { payload } = action;
      const { fieldName, value, index } = payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders[index][fieldName as string] = value;
    },
    addPurchaseFile(state, action) {
      const { payload } = action;
      const { value, index } = payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders[index]['attachments'] = [
        ...state.clientContract.clients[state.clientContract.selectedClientIdx]
          .purchaseOrders[index]['attachments'],
        ...value,
      ];
    },
    removePurchaseFile(state, action) {
      const { payload } = action;
      const { originalFilename, index } = payload;
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders[index]['attachments'] = state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders[index]['attachments'].filter(
        (item) => item.originalFilename !== originalFilename,
      );
    },
    addPurchaseItem(state) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders = [
        ...state.clientContract.clients[state.clientContract.selectedClientIdx]
          .purchaseOrders,
        {
          attachments: [],
          endDate: '',
          poComment: '',
          poNumber: '',
          startDate: '',
          indefinite: false,
        },
      ];
    },
    removePurchaseItem(state, action) {
      state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders = state.clientContract.clients[
        state.clientContract.selectedClientIdx
      ].purchaseOrders.filter((item, index) => index !== action.payload);
    },
    //tax-at-source
    updateIsSubject(state, action) {
      const isNoSubjectValue = state.taxAtSource.isSubject === null;
      state.taxAtSource.taxItems = state.taxAtSource?.taxItems?.map((item) => {
        return {
          ...item,
          subjectToTaxAtSource: item.isManual
            ? !!item.subjectToTaxAtSource
            : isNoSubjectValue
            ? !!action.payload
            : !!item.subjectToTaxAtSource,
        };
      });
      state.taxAtSource.isSubject = action.payload;
    },
    addNewTaxItem(state, action: PayloadAction<ITaxItem>) {
      state.taxAtSource.taxItems = [
        action.payload,
        ...state.taxAtSource.taxItems,
      ];
    },
    removeTaxItem(state, action: PayloadAction<number>) {
      const newArray = state.taxAtSource.taxItems.filter(
        (item, index) => index !== action.payload,
      );
      state.taxAtSource.taxItems = newArray;
    },
    updateTaxItem(
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>,
    ) {
      const { index, key, value } = action.payload;
      state.taxAtSource.taxItems[index] = {
        ...state.taxAtSource.taxItems[index],
        [key]: value,
      };
    },
  },
});

export const {
  setOnboardingConsultantInternalInfo,
  setOnboardingErrorValidation,
  clearOnboardingErrorsValidation,
  updateOnboardingPersonalInfo,
  updateOnboardingWorkPermit,
  setOnboardingWorkContract,
  updateOnboardingWorkContract,
  updateOptimizationOnboardingWorkContract,
  updateOnboardingWorkContractCheckbox,
  updateOnboardingClientContractAddress,
  addNewAddressOnboardingClientContract,
  resetOnboardingWorkContractWorkLocation,
  resetOnboardingWorkContractPaymentCurrency,
  setCurrentClientOnboardingContract,
  removeAddressOnboardingClientContract,
  setOnboardingClientContract,
  updateOnboardingClientContract,
  updateOnboardingClientContractCheckbox,
  updateOnboardingClientContractShippingDetails,
  resetOnboardingClientContractShippingDetails,
  addNewSignatoryOnboardingClientContract,
  addNewInvoiceryOnboardingClientContract,
  addNewReminderOnboardingClientContract,
  removeSignatoryOnboardingClientContract,
  removeInvoiceryOnboardingClientContract,
  removeReminderOnboardingClientContract,
  updateOnboardingClientContractSignatory,
  updateOnboardingClientContractInvoicery,
  updateOnboardingClientContractReminder,
  updateOnboardingBankAccount,
  updateOnboardingChildAllowances,
  addNewOnboardingChildAllowances,
  removeOnboardingChildAllowances,
  removePurchaseItem,
  updateOnboardingChildAllowancesChildArray,
  setOnboardingChildAllowances,
  clearOnboardingWorkPermit,
  clearOnboardinBankAccount,
  clearOnboardingChildAllowances,
  updateOnboardingAvsAhv,
  setOnboardingTabStatus,
  updateOnboardingAdditionalInfo,
  updateOnboardingAdditionalInfoItem,
  setIsOpenOnboardingLastStepModal,
  putFileOnboardingPersonalInfo,
  removeOnboardingPersonalInfo,
  putFileOnboardingWorkPermit,
  removeOnboardingWorkPermit,
  putFileOnboardingChildAllowances,
  removeFileOnboardingChildAllowances,
  setIsChangeNationality,
  setIsFirstRender,
  clearOnboardingState,
  setIsShowClientContractModal,
  setClientContractModalOptions,
  updateOnboardingClientContractContact,
  removePurchaseFile,
  setUserNationality,
  resetAddresses,
  setIsPublishedBeforeNewClient,
  setIsHasDeletedClient,
  setPublicId,
  updateUseMealAllowanceOnboardingWorkContract,
  changeChildAllowanceComment,
  setConfirmationUrl,
  setConsultantEmail,
  setUseNewEmail,
  setOldConsultantEmail,
  setSelectedDublicateIdx,
  setSeller,
  setClientContract,
  setWorkContract,
  updateAvsAhvWorkForm,
  changeCCEmail,
  addCCEmail,
  removeCCEmail,
  changePurchaseItemValue,
  addPurchaseItem,
  addPurchaseFile,
  dublicateAddressOnBoardingClientContract,
  addNewAddressLine,
  changeAddressLine,
  removeAddressLine,
  updateIsSubject,
  addNewTaxItem,
  removeTaxItem,
  updateTaxItem,
  setOnboardingTaxAtSouce,
} = onboardingReducer.actions;
export default onboardingReducer.reducer;
