import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination, TSortValue } from '../../types';

const initialState: IState = {
  payslipsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
};

const reducer = createSlice({
  name: 'payslips',
  initialState,
  reducers: {
    //TODO
    setPayslipsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.payslipsList.content = action.payload.result;
      state.payslipsList.pagination.totalElement = action.payload.total;
    },
    changePayslipsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.payslipsList.filter = {
        ...state.payslipsList.filter,
        ...action.payload,
      };
    },
    setPayslipsSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.payslipsList.sort.sortBy = action.payload[0];
      state.payslipsList.sort.sort = action.payload[1];
    },
    clearPayslipsFiltersAndSorts(state) {
      state.payslipsList.sort = initialState.payslipsList.sort;
      state.payslipsList.filter = initialState.payslipsList.filter;
    },
    setPayslipsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.payslipsList.pagination.currentPage = action.payload.currentPage;
      state.payslipsList.pagination.perPage = action.payload.perPage;
    },
  },
});

export const {
  setPayslipsList,
  setPayslipsPagination,
  clearPayslipsFiltersAndSorts,
  changePayslipsFiltering,
  setPayslipsSorting,
} = reducer.actions;

export default reducer.reducer;
