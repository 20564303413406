import { Modal, Spin } from 'antd';
import './../../waiting-room-page/WaitingRoomPage.less';
import '../EmployeePayslips.less';
import { IUploadResult } from '../../../common/api/services/EmployeePayslips/types/types';
import { openErrorNotification } from '../../../components/notification/Notification';
import { employeePayslipsApi } from '../../../common/api/services/EmployeePayslips/employeePayslipsApi';
import { downloadExel } from '../../../common/api/utils/downloadExel';

interface IProps {
  isOpen: boolean;
  errorInfo: IUploadResult | null;
  iterationId: string | null;
  onClose: () => void;
  isLoading: boolean;
}

const UploadErrorModal = (props: IProps) => {
  const { isOpen, errorInfo, iterationId, onClose, isLoading } = props;

  const downloadFile = () => {
    if (!iterationId) {
      openErrorNotification('Failed to download excel report');
      return;
    }
    employeePayslipsApi
      .getExcelByIteration(iterationId)
      .then((resp: any) => {
        downloadExel(resp);
      })
      .catch(() => openErrorNotification('Failed to download excel report'));
  };

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      onCancel={onClose}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <Spin spinning={isLoading}>
        <div className="error-modal-container">
          <b style={{ fontSize: '16px' }}>
            You have uploaded {errorInfo?.succcess || 0} out of{' '}
            {errorInfo?.total || 0} files.
          </b>
          <span>
            Please check the excel report that was automatically downloaded to
            your computer to see the reasons why files were not uploaded. If the
            download hasn’t started, click the button below to download the
            report.
          </span>
          <span onClick={() => downloadFile()} className="download-link">
            Download the report
          </span>
        </div>
      </Spin>
    </Modal>
  );
};

export default UploadErrorModal;
