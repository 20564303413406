import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { ILoginRequest } from '../../../common/api/services/AuthApi/types/ILoginRequest';
import { apiAuth } from '../../../common/api/services/AuthApi/ApiAuth';
import { emptyAuthResponse } from '../../../common/api/services/AuthApi/utils/emptyAuthResponse';
import { changeIsAccess, putUserData, setAuthMessage } from './sliceReducer';
import { ISetupPasswordRequest } from '../../../common/api/services/AuthApi/types/ISetupPasswordRequest';
import { IRootState } from '../../types';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { getOnboardingProfile } from '../onboarding/actionAsyncThunk';
import { userApi } from '../../../common/api/services/UserApi/userApi';
import { TAnyObject } from '../../../common/types/TAnyObject';
import { openErrorNotification } from '../../../components/notification/Notification';

const { home, login } = ROUTER_PATHS;

export const getCurrentUserData = createAsyncThunk(
  'auth/getCurrentUserData',
  async (loginData: ILoginRequest, { dispatch }) => {
    const { email, password } = loginData;

    const { data, message } = await apiAuth.isAuthenticated(email, password);
    dispatch(putUserData(data));

    const isAccess = data !== emptyAuthResponse;
    dispatch(changeIsAccess(isAccess));

    if (isAccess) {
      dispatch(push(home));
      dispatch(setAuthMessage(''));
    }

    if (message) {
      dispatch(setAuthMessage(''));
      dispatch(setAuthMessage(message));
    }

    if (data?.consultantOnboarding) {
      dispatch(getOnboardingProfile());
    }
  },
);

export const confirmLeadPassword = createAsyncThunk(
  'auth/confirmLeadPassword',
  async (
    { password, confirmPassword }: ISetupPasswordRequest,
    { dispatch, getState },
  ) => {
    const pathName = (getState() as IRootState).router.location?.pathname ?? '';
    const separatedPathName = pathName.split('/');
    const token = separatedPathName[separatedPathName.length - 1];

    const response = await apiAuth.confirmLeadPassword(
      password,
      confirmPassword,
      token,
    );

    if (typeof response !== 'string') {
      dispatch(push(login));
      dispatch(setAuthMessage(''));
    } else {
      dispatch(setAuthMessage(''));
      dispatch(setAuthMessage(response));
      openErrorNotification(response);
    }
  },
);

export const confirmEmployeePassword = createAsyncThunk(
  'auth/confirmEmployeePassword',
  async (
    { password, confirmPassword }: ISetupPasswordRequest,
    { dispatch, getState },
  ) => {
    const pathName = (getState() as IRootState).router.location?.pathname ?? '';
    const separatedPathName = pathName.split('/');
    const token = separatedPathName[separatedPathName.length - 1];

    const response = await apiAuth.confirmEmployeePassword(
      password,
      confirmPassword,
      token,
    );

    if (typeof response !== 'string') {
      dispatch(push(login));
      dispatch(setAuthMessage(''));
    } else {
      dispatch(setAuthMessage(''));
      dispatch(setAuthMessage(response));
      openErrorNotification(response);
    }
  },
);

export const getUserProfile = createAsyncThunk(
  'auth/getUserProfile',
  async (_, { dispatch }) => {
    const response = (await userApi.getUserProfile()) as TAnyObject;

    if (response) {
      dispatch(putUserData(response));
    }
  },
);

// export const requestForNewPassword = createAsyncThunk(
//   'auth/requestForNewPassword',
//   async (email: string, { dispatch }) => {
//     const response = await apiAuth.requestForNewPassword(email);
//
//     if (typeof response !== 'string') {
//       // dispatch(push(login));
//     } else {
//       dispatch(setAuthMessage(''));
//       dispatch(setAuthMessage(response));
//     }
//   },
// );

export const resetNewPassword = createAsyncThunk(
  'auth/resetNewPassword',
  async (
    { password, confirmPassword }: ISetupPasswordRequest,
    { dispatch, getState },
  ) => {
    const pathName = (getState() as IRootState).router.location?.pathname ?? '';
    const separatedPathName = pathName.split('/');
    const token = separatedPathName[separatedPathName.length - 1];

    const response = await apiAuth.resetPassword(
      password,
      confirmPassword,
      token,
    );

    if (typeof response !== 'string') {
      dispatch(push(login));
      dispatch(setAuthMessage(''));
    } else {
      dispatch(setAuthMessage(''));
      dispatch(setAuthMessage(response));
      openErrorNotification(response);
    }
  },
);
