import { FC } from 'react';
import { Button as AntButton } from 'antd';
import { IBtnProps } from './types';
import { getBtnSize, getBtnStyle } from '../../common/utils/commonButtonUtils';

import './Button.less';

const Button: FC<IBtnProps> = (props) => {
  const {
    children,
    buttonStyle,
    buttonSize,
    disable,
    type = 'button',
    onClick,
    rightIcon,
    leftIcon,
    buttonWidth = 'auto',
  } = props;
  const btnSize = getBtnSize(buttonSize);

  const btnStyle = getBtnStyle(buttonStyle);
  const buttonClassName = `button ${btnStyle} ${btnSize}`;

  return (
    <AntButton
      htmlType={type}
      className={buttonClassName}
      disabled={disable}
      onClick={onClick}
      style={{ width: buttonWidth }}>
      {leftIcon}
      {children}
      {rightIcon}
    </AntButton>
  );
};

export default Button;
