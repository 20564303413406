import { FC } from 'react';
import { Row, Col, Modal } from 'antd';
import Button from '../../button/Button';
import SaveIcon from '../../../assets/icons/SaveIcon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  finishedOnboarding,
  saveAsDraft,
  saveAsPublish,
} from '../../../redux/reducers/onboarding/actionAsyncThunk';
import { useLocation } from 'react-router-dom';

import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { setIsOpenOnboardingLastStepModal } from '../../../redux/reducers/onboarding/sliceReducer';
import WarningIcon from '../../../assets/icons/WarningIcon';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { getIsHideDraftBtn } from '../../../common/utils/getIsHideDraftBtn';

const {
  workPermit,
  workContract,
  bankAccount,
  childAllowance,
  personalInformation,
  onboarding,
  clientContract,
  avsAhv,
  additionalInformation,
} = ROUTER_PATHS;

const OnboardingFooter: FC = () => {
  const pathName = useLocation().pathname;
  const dispatch = useAppDispatch();

  const { tabStatus } = useAppSelector((state) => state.onboarding);
  const isHideDraftBtn = getIsHideDraftBtn(pathName, tabStatus);
  const { hasWorkPermit, selfHandledWorkPermit, basisOfLegalStay } =
    useAppSelector((state) => state.onboarding.workPermit);
  const hasBankAccount = useAppSelector(
    (state) => state.onboarding.bankAccount.hasBankAccount,
  );
  const hasSsn = useAppSelector(
    (state) => state.onboarding.ssnInformation.hasSSN,
  );
  const isOpen = useAppSelector(
    (state) => state.onboarding.isOpenOnboardingLastStepModal,
  );
  const isSwissNationality = useAppSelector(
    (state) => state.onboarding.personalInfo.nationality === 'Switzerland',
  );
  const personalInfo = useAppSelector((state) => state.onboarding.personalInfo);
  const { workForm } = personalInfo;
  const isCorporation = workForm === 'corporation';

  const isSkipConditions =
    (pathName.includes(workPermit) &&
      (!isSwissNationality &&
      !hasWorkPermit &&
      !selfHandledWorkPermit &&
      basisOfLegalStay
        ? false
        : !hasWorkPermit)) ||
    (pathName.includes(bankAccount) && !hasBankAccount) ||
    (pathName.includes(avsAhv) && (!hasSsn || isCorporation));

  const buttonText = isSkipConditions ? 'Skip and go next' : 'Save and publish';

  const saveAsPublishHandler = () => {
    dispatch(saveAsPublish());
  };

  const isWarningText = tabStatus.some(
    (el) => el === OnboardingRequestStatuses.toConfirm,
  );

  return (
    <Row align="middle" className="onboarding-footer" justify="space-between">
      <Col span={15}>
        {isHideDraftBtn && (
          <p>
            By clicking &quot;Save as Draft&quot;, you will be redirected to the
            next step and the information filled in will be saved and visible
            only to you. By clicking &quot;Save and Publish&quot;, the
            information will be saved and visible for Numeriq.
          </p>
        )}
      </Col>
      <Col span={9}>
        <Row gutter={[16, 0]} justify="end">
          {!isSkipConditions && isHideDraftBtn && (
            <Col>
              <Button
                buttonStyle="secondary"
                buttonSize="normal"
                leftIcon={<SaveIcon />}
                onClick={() => dispatch(saveAsDraft())}>
                Save as Draft
              </Button>
            </Col>
          )}
          <Col>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              rightIcon={<ArrowIcon />}
              onClick={saveAsPublishHandler}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>

      {isOpen && (
        <Modal
          width={490}
          open={isOpen}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p>
            Congratulations! You have successfully covered the Onboarding. Do
            you want to finish?
          </p>

          {isWarningText && (
            <div className="onboarding-warning">
              <div style={{ display: 'flex' }}>
                <WarningIcon />
              </div>
              <p>
                You need to update the portal within 15 days if you have changes
                in family status, address, birth of a child, spouse’s working
                status.
              </p>
            </div>
          )}
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() =>
                  dispatch(setIsOpenOnboardingLastStepModal(false))
                }>
                No, stay here
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => {
                  dispatch(finishedOnboarding());
                }}>
                Yes, finish
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Row>
  );
};

export default OnboardingFooter;

const getBackRoute = (path: string): string => {
  if (path.includes(workPermit)) {
    return onboarding + personalInformation;
  }

  if (path.includes(workContract)) {
    return onboarding + workPermit;
  }

  if (path.includes(clientContract)) {
    return onboarding + workContract;
  }

  if (path.includes(bankAccount)) {
    return onboarding + clientContract;
  }

  if (path.includes(childAllowance)) {
    return onboarding + bankAccount;
  }

  if (path.includes(avsAhv)) {
    return onboarding + childAllowance;
  }

  if (path.includes(additionalInformation)) {
    return onboarding + avsAhv;
  }

  return path;
};
