import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ErrorIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66602 13.9384C1.66602 13.4323 1.79501 12.9345 2.04081 12.492L7.19574 3.21317C7.65268 2.39068 8.44774 1.81032 9.37036 1.6258V1.6258C9.78557 1.54275 10.2131 1.54275 10.6283 1.6258V1.6258C11.551 1.81032 12.346 2.39068 12.803 3.21317L17.9579 12.492C18.2037 12.9345 18.3327 13.4323 18.3327 13.9384V13.9384C18.3327 15.5833 16.9993 16.9167 15.3544 16.9167H4.64427C2.99943 16.9167 1.66602 15.5833 1.66602 13.9384V13.9384Z"
        stroke="#C43025"
        strokeWidth="1.5"
      />
      <path
        d="M10 6.49998L10 9.83331"
        stroke="#C43025"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.3333L10 12.75"
        stroke="#C43025"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIcon;
