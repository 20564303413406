import { FC } from 'react';
import { passProps, IPassProps } from './passProps';

const PasswordIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6785 11.4401L15.4463 9.67233C17.0735 8.04515 17.0735 5.40696 15.4463 3.77977V3.77977C13.8191 2.15259 11.1809 2.15259 9.55372 3.77977L7.78595 5.54754M11.3215 13.7971L9.55372 15.5649C7.92654 17.1921 5.28835 17.1921 3.66117 15.5649V15.5649C2.03398 13.9377 2.03398 11.2995 3.66117 9.67233L5.42893 7.90456"
        stroke="#0E2552"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.3217 7.90465L7.78613 11.4402"
        stroke="#0E2552"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordIcon;
