export const documentTypes = ['No work in CH', 'ID', 'Passport', 'Permit'];

export const permitTypes = [
  '90 day work permit',
  'L',
  'B',
  'C',
  'G',
  'Ci',
  'F',
  'N',
  'S',
  '120J',
  'HOME',
  'N/A',
  'Passport',
];

export const cantons = [
  'Aargau',
  'Appenzell Innerrhoden',
  'Appenzell Ausserrhoden',
  'Bern',
  'Basel-Land',
  'Basel-Stadt',
  'Freiburg',
  'Geneva',
  'Glarus',
  'Graubünden',
  'Jura',
  'Luzern',
  'Neuenburg',
  'Nidwalden',
  'Obwalden',
  'St. Gallen',
  'Schaffhausen',
  'Solothurn',
  'Schwyz',
  'Thurgau',
  'Ticino',
  'Uri',
  'Vaud',
  'Valais',
  'Zug',
  'Zürich',
  'N/A',
];
