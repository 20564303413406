import { Col, Radio, RadioChangeEvent, Row, Switch } from 'antd';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import CrossIcon from '../../../assets/icons/CrossIcon';
import SaveIcon from '../../../assets/icons/SaveIcon';
import UploadedFileIcon from '../../../assets/icons/UploadedFileIcon';
import { IConsultantTimesheet } from '../../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { getFormatDateWithMonth } from '../../../common/utils/getFormatDate';
import Button from '../../../components/button/Button';
import Checkbox from '../../../components/checkbox/Checkbox';
import Input from '../../../components/input/Input';
import InputFile from '../../../components/input/InputFile';
import InputNumber from '../../../components/input/InputNumber';
import Textarea from '../../../components/textarea/Textarea';

import './ModalTimesheet.less';
import { SalaryType } from '../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { createTimesheetsTemplateName } from '../../../common/utils/createTimesheetsTemplateName';
import UploadDropzoneOverlay from '../../../components/upload-dropzone-overlay/UploadDropzoneOverlay';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { EyeOutlined } from '@ant-design/icons';
import {
  isDocFormat,
  isImgFormat,
} from '../../../common/utils/checkFileFormat';
import PreviewImage from '../../../components/file-preview/preview-image/PreviewImage';
import ExcelPreview from '../../../components/file-preview/excel-preview/ExcelPreview';
import { downloadFileByUrl } from '../../../common/utils/downloadFileByUrl';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';

interface IProps {
  isUpLoading: boolean;
  selectedTimesheet: IConsultantTimesheet;
  getError: (fieldName: string) => string;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onChangeCheckbox: (fieldName: 'isSigned' | 'approvalRequired') => () => void;
  onChangeFile: (e: ChangeEvent<HTMLInputElement> | File[]) => void;
  onChangeNumber: (
    fieldName: string,
  ) => (fileName: number | string | null) => void;
  onRemoveAttachFile: (file: string) => void;
  onSaveAsDraft: () => void;
  onSubmit: () => void;
  onChangeVisibility: (visibility: boolean) => void;
}

const ModalEditTimesheet: FC<IProps> = (props) => {
  const {
    isUpLoading,
    selectedTimesheet,
    getError,
    onChange,
    onChangeCheckbox,
    onChangeFile,
    onChangeNumber,
    onRemoveAttachFile,
    onSaveAsDraft,
    onChangeVisibility,
    onSubmit,
  } = props;
  const {
    workContract,
    type,
    isSigned,
    approvalRequired,
    period,
    hasActivityPerMonth,
    companyRepresentativeEmail,
    representativeFirstName,
    representativeLastName,
    documents,
    comments,
    numeriqComments,
    standardTime,
    overtime,
    doubleTime,
    visibility,
    status,
    consultant,
    clientFiles,
  } = selectedTimesheet;

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [previewId, setPreviewId] = useState<string | null>(null);

  const openPreview = (type: 'docs' | 'image', id: string) => {
    setPreviewType(type);
    setIsOpenPreview(true);
    setPreviewId(id);
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setPreviewType('');
    setPreviewId(null);
  };

  const isShowInputBlock = isSigned || approvalRequired;

  const fileTemplate = createTimesheetsTemplateName(
    (workContract?.type || '') as SalaryType,
    !!workContract?.overtimeAllowed,
    !!workContract?.doubleTimeAllowed,
  );

  const isDisabledSaveDraft =
    (!isSigned &&
      !approvalRequired &&
      ![TimesheetStatus.EXPIRED, TimesheetStatus.NO_ACTIVITY].includes(
        status,
      )) ||
    isUpLoading;

  return (
    <>
      <UploadDropzoneOverlay onSubmit={onChangeFile} />

      <Row
        gutter={[16, 16]}
        justify="space-between"
        style={{ paddingTop: !isShowInputBlock ? 24 : 0 }}>
        <Col span={16}>
          <span className="questions">Was there any activity this month?</span>
        </Col>
        <Col span={8}>
          <Radio.Group
            name="hasActivityPerMonth"
            value={hasActivityPerMonth}
            onChange={onChange}
            style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>

        {!hasActivityPerMonth && (
          <p className="info-message">
            If Consultant doesn&apos;t have any activity, you can skip the
            sections below and submit. After the submitting the status will be
            &quot;No activity&quot;.
          </p>
        )}
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Input
            label="Consultant's ID"
            value={consultant?.publicId?.toString() || ''}
            name="publicId"
            disabled
            onChange={() => null}
          />
        </Col>
        <Col span={8}>
          <Input
            label="Consultant's name"
            value={consultant?.firstName || ''}
            name="consultantName"
            disabled
            onChange={() => null}
          />
        </Col>
        <Col span={8}>
          <Input
            label="Consultant's last name"
            value={consultant?.lastName || ''}
            name="consultantLastName"
            disabled
            onChange={() => null}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8} style={{ paddingRight: 8 }}>
          <Input
            label="Client name"
            onChange={onChange}
            value={workContract?.name || ''}
            name="clientName"
            disabled
          />
        </Col>
        <Col span={8} style={{ paddingRight: 4, paddingLeft: 4 }}>
          <Input
            label="Period"
            onChange={onChange}
            value={getFormatDateWithMonth(period)}
            name="period"
            disabled
          />
        </Col>
        <Col span={8} style={{ paddingLeft: 8 }}>
          <Input
            label="Type of contract"
            onChange={onChange}
            value={workContract?.type || ''}
            name="typeContract"
            disabled
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox
            className={approvalRequired ? 'checkbox-gray' : ''}
            label="Timesheet has been Signed/Approved."
            checked={isSigned}
            onChange={onChangeCheckbox('isSigned')}
            disabled={!hasActivityPerMonth}
          />
        </Col>
      </Row>

      {SalaryType.PerMonth !== workContract?.type &&
        SalaryType.Project !== workContract?.type && (
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
              <Checkbox
                className={isSigned ? 'checkbox-gray' : ''}
                checked={approvalRequired}
                label="Timesheet requires manager's online approval."
                onChange={onChangeCheckbox('approvalRequired')}
                disabled={!hasActivityPerMonth}
              />
            </Col>
          </Row>
        )}

      {isShowInputBlock && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputFile
                label="Attach a file"
                name="documents"
                onChange={onChangeFile}
                errorMessage={getError('documents') ? ' ' : ''}
                disabled={!hasActivityPerMonth}
              />
            </Col>
          </Row>

          {!!documents.length && (
            <Row>
              <Col span={24}>
                {documents.map(({ originalFilename }) => (
                  <div key={originalFilename} className="uploaded-file">
                    <div>
                      <UploadedFileIcon />
                      <span
                        className="view-file-link"
                        onClick={(event) => {
                          event?.preventDefault();
                          fileApi.downloadFileByUrl(originalFilename);
                        }}>
                        <span>{originalFilename}</span>
                      </span>
                      {isImgFormat(originalFilename) && (
                        <span
                          onClick={() => openPreview('image', originalFilename)}
                          style={{ marginLeft: '1rem' }}>
                          <EyeOutlined className="preview-icon" />
                        </span>
                      )}
                      {isDocFormat(originalFilename) && (
                        <span
                          onClick={() => openPreview('docs', originalFilename)}
                          style={{ marginLeft: '1rem' }}>
                          <EyeOutlined className="preview-icon" />
                        </span>
                      )}
                    </div>

                    <CrossIcon
                      onClick={() => onRemoveAttachFile(originalFilename)}
                    />
                  </div>
                ))}
              </Col>
            </Row>
          )}
          {fileTemplate &&
            clientFiles &&
            clientFiles.length > 0 &&
            clientFiles.map((file) => (
              <Row gutter={[16, 16]} key={file.originalFilename}>
                <Col span={24}>
                  <p className="info-content">
                    <span
                      className="download-file-link"
                      onClick={() =>
                        fileApi.downloadFileByUrl(file.originalFilename)
                      }>
                      Download {file.originalFilename}
                    </span>
                  </p>
                </Col>
              </Row>
            ))}

          {fileTemplate && (!clientFiles || clientFiles.length === 0) && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <p className="info-content">
                  <a
                    href={`/files/${fileTemplate}?v=1.0`}
                    download={`${fileTemplate}`}>
                    Download {fileTemplate}
                  </a>
                </p>
              </Col>
            </Row>
          )}

          {!isSigned && (
            <>
              <Row>
                <Col span={24}>
                  <Input
                    label="Client representative email"
                    onChange={onChange}
                    value={companyRepresentativeEmail}
                    name="companyRepresentativeEmail"
                    errorMessage={getError('companyRepresentativeEmail')}
                    disabled={!hasActivityPerMonth}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={12} style={{ paddingRight: 16 }}>
                  <Input
                    label="Representative First Name"
                    onChange={onChange}
                    value={representativeFirstName}
                    name="representativeFirstName"
                    errorMessage={getError('representativeFirstName')}
                    disabled={!hasActivityPerMonth}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    label="Representative Last Name"
                    onChange={onChange}
                    value={representativeLastName}
                    name="representativeLastName"
                    errorMessage={getError('representativeLastName')}
                    disabled={!hasActivityPerMonth}
                  />
                </Col>
              </Row>
            </>
          )}

          {!isSigned && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Textarea
                  name="comments"
                  value={comments}
                  rows={3}
                  label="Comments for the Client (optional)"
                  onChange={onChange}
                  autoSize={false}
                  disabled={!hasActivityPerMonth}
                />
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Textarea
                name="numeriqComments"
                value={numeriqComments}
                rows={3}
                label="Comments for Numeriq (optional)"
                onChange={onChange}
                errorMessage={''}
                autoSize={false}
                disabled={!hasActivityPerMonth}
              />
            </Col>
          </Row>
          {/* NOTE: uncommit if new types of timesheets will be added */}
          {/* <Row gutter={[16, 16]}>
            <Col span={24}>
              <Dropdown
                options={[TimesheetType.SIMPLIFIED, TimesheetType.DETAILED]}
                value={type}
                onChange={() => null}
                label="Type of timesheets"
                showSearch={false}
                disabled={true}
              />
            </Col>
          </Row> */}

          <Row>
            <Col span={8} style={{ padding: '0 8px 0 0' }}>
              <InputNumber
                label="Standard time"
                onChange={onChangeNumber('standardTime')}
                value={standardTime}
                name="standardTime"
                errorMessage={getError('standardTime')}
                disabled={!hasActivityPerMonth}
              />
            </Col>
            <Col span={8} style={{ padding: '0 8px' }}>
              <InputNumber
                label="Overtime"
                onChange={onChangeNumber('overtime')}
                value={overtime}
                name="overtime"
                errorMessage={getError('overtime')}
                disabled={
                  !hasActivityPerMonth || !workContract?.overtimeAllowed
                }
              />
            </Col>
            <Col span={8} style={{ padding: '0 0 0 8px' }}>
              <InputNumber
                label="Double time"
                onChange={onChangeNumber('doubleTime')}
                value={doubleTime}
                name="doubleTime"
                errorMessage={getError('doubleTime')}
                disabled={
                  !hasActivityPerMonth || !workContract?.doubleTimeAllowed
                }
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={24} className="switch-container">
          <span>Visible for consultant</span>
          <Switch
            onChange={onChangeVisibility}
            checked={visibility === 'public'}
            disabled={workContract?.type === SalaryType.PerMonth}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ padding: '16px 0 0 0' }}>
        {hasActivityPerMonth && (
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              leftIcon={
                <SaveIcon
                  className={`${
                    !isSigned && !approvalRequired ? 'disabled' : ''
                  }`}
                />
              }
              onClick={onSaveAsDraft}
              disable={isDisabledSaveDraft}>
              Save as Draft
            </Button>
          </Col>
        )}
        <Col span={hasActivityPerMonth ? 12 : 24}>
          <Button
            buttonStyle="primary"
            buttonSize="normal"
            buttonWidth="100%"
            // rightIcon={<ArrowIcon />}
            onClick={onSubmit}
            disable={(hasActivityPerMonth && !isShowInputBlock) || isUpLoading}>
            Submit
          </Button>
        </Col>
      </Row>

      {getError('documents') && (
        <p className="error">
          You can&apos;t submit your Timesheet if you don&apos;t attach any
          files.
        </p>
      )}
      {getError('') && <p className="error">{getError('')}</p>}
      {isOpenPreview && previewType === 'image' && previewId && (
        <PreviewImage
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
      {isOpenPreview && previewType === 'docs' && previewId && (
        <ExcelPreview
          url={previewId}
          isOpen={isOpenPreview}
          setClose={closePreview}
        />
      )}
    </>
  );
};

export default ModalEditTimesheet;
