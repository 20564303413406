import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ExitIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 13.3333V15C13.3333 16.8409 11.8409 18.3333 9.99998 18.3333H5.83331C3.99236 18.3333 2.49998 16.8409 2.49998 15V4.99999C2.49998 3.15904 3.99236 1.66666 5.83331 1.66666H9.99998C11.8409 1.66666 13.3333 3.15904 13.3333 4.99999V6.66666"
        stroke="#C43025"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8331 12.5L17.7439 10.5893C18.0693 10.2638 18.0693 9.73618 17.7439 9.41074L15.8331 7.5"
        stroke="#C43025"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.4998 10L7.49982 10"
        stroke="#C43025"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ExitIcon;
