import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import Input from '../../../components/input/Input';
import Textarea from '../../../components/textarea/Textarea';
import Dropdown from '../../../components/dropdown/Dropdown';
import { vatOptions } from '../../../common/constants/vatOptions';
import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import TrashIcon from '../../../assets/icons/TrashIcon';
import CrossIcon from '../../../assets/icons/CrossIcon';
import { IClientRequest } from '../../../common/api/services/ClientApi/types/IClientRequest';
import { paymentTermsList } from '../../../common/constants/paymentTermsList';
import { shippingMethodsList } from '../../../common/constants/shippingMethodsList';
import { IVATs } from '../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { systemInfoApi } from '../../../common/api/services/SystemInfoApi/SystemInfoApi';
import {
  commonMonthYearSpaceFormat,
  commonYearDateFormat,
} from '../../../common/constants/dateConstants';
import { openErrorNotification } from '../../../components/notification/Notification';
import moment from '../../../common/constants/moment';

interface IProps {
  values: Pick<
    IClientRequest,
    | 'name'
    | 'notes'
    | 'vat'
    | 'countryCode'
    | 'isFactoring'
    | 'isRegisteredAtCreditSuisse'
    | 'emails'
    | 'phones'
    | 'paymentTerm'
    | 'paymentTermRange'
    | 'shippings'
    | 'mergeInvoiceFiles'
  >;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => void;
  onChangeDropdown: (name: string) => (value: string) => void;
  onAddPhoneEntity: () => void;
  onAddEmailEntity: () => void;
  onDeleteEmailEntity: (idx: number | string) => () => void;
  onDeletePhoneEntity: (idx: number | string) => () => void;
  onChangeArrayElement: (
    idx: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  getError: (fileName: string) => string;
  onChangeShippingDetails: (value: string, method: string) => void;
  onChangeShippingMethod: (value: string) => void;
  countryCodeOptions: string[];
}
const GeneralInformation: FC<IProps> = (props) => {
  const {
    values,
    onChange,
    onChangeDropdown,
    onAddEmailEntity,
    onAddPhoneEntity,
    onDeletePhoneEntity,
    onDeleteEmailEntity,
    onChangeArrayElement,
    onChangeShippingDetails,
    onChangeShippingMethod,
    getError,
    countryCodeOptions,
  } = props;

  const {
    name,
    notes,
    vat,
    countryCode,
    isFactoring,
    isRegisteredAtCreditSuisse,
    emails,
    phones,
    paymentTerm,
    paymentTermRange,
    shippings,
    mergeInvoiceFiles,
  } = values;

  const [vatRates, setVatRates] = useState<IVATs>([]);

  useEffect(() => {
    systemInfoApi
      .getActualVAT(moment().format(commonYearDateFormat))
      .then((response: any) => {
        setVatRates(response);
      })
      .catch(() => openErrorNotification('Failed to get VAT'));
  }, []);

  const shippingMethod = shippings?.length > 0 ? shippings[0].method || '' : '';

  const isShowShippingMethodDetails =
    shippingMethod === 'by post' || shippingMethod === 'portal';

  const shippingMethodDetails = (shippings?.length > 0 &&
    shippings[0].details) || {
    postalAddress: '',
    firstName: '',
    lastName: '',
    portalUrl: '',
  };

  const {
    postalAddress = '',
    firstName = '',
    lastName = '',
    portalUrl = '',
  } = shippingMethodDetails;

  return (
    <Col span={12}>
      <h4>General information</h4>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="name"
            value={name}
            onChange={onChange}
            label="Client company name"
            errorMessage={getError('name')}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            name="notes"
            value={notes}
            onChange={onChange}
            label="Client notes"
            errorMessage={getError('notes')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={vatRates.map((item) => {
              return {
                value: item._id,
                label: `${item.name || ''}   ${item.percentage || '0'}%`,
              };
            })}
            label="VAT"
            value={vat?._id || vat}
            onChange={onChangeDropdown('vat')}
            errorMessage={getError('vat')}
            isSearch={false}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={countryCodeOptions}
            label="Country code"
            value={countryCode}
            onChange={onChangeDropdown('countryCode')}
            errorMessage={getError('countryCode')}
            isSearch={true}
          />
        </Col>
      </Row>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Is it factoring?</span>
        </Col>
        <Col>
          <Radio.Group
            name="isFactoring"
            value={isFactoring}
            onChange={onChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {isFactoring && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span>Registered in Credit Suisse:</span>
          </Col>
          <Col>
            <Radio.Group
              name="isRegisteredAtCreditSuisse"
              value={isRegisteredAtCreditSuisse}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={paymentTermsList}
            label="Payment term"
            value={paymentTerm}
            onChange={onChangeDropdown('paymentTerm')}
            showSearch={false}
            errorMessage={getError('paymentTerm')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={14}>
          <div style={{ marginTop: 18 }}>
            <span>№ of days for payment terms</span>
          </div>
        </Col>
        <Col span={10}>
          <Input
            name="paymentTermRange"
            label="days"
            value={paymentTermRange}
            onChange={onChange}
            errorMessage={getError('paymentTermRange')}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24 / (isShowShippingMethodDetails ? 2 : 1)}>
          <Dropdown
            options={shippingMethodsList}
            label="Invoice shipping method"
            value={
              shippingMethod.charAt(0).toUpperCase() + shippingMethod.slice(1)
            }
            onChange={onChangeShippingMethod}
            showSearch={false}
            errorMessage={getError(`shippings[0].method`)}
          />
        </Col>
        {shippingMethod === 'portal' && (
          <Col span={12}>
            <Input
              name="portalUrl"
              label="Portal URL"
              value={portalUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeShippingDetails(e.target.value, 'portalUrl')
              }
              errorMessage={getError(`shippings[0].details.portalUrl`)}
            />
          </Col>
        )}
        {shippingMethod === 'by post' && (
          <Col span={12}>
            <Input
              name="postalAddress"
              label="Postal address"
              value={postalAddress}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeShippingDetails(e.target.value, 'postalAddress')
              }
              errorMessage={getError(`shippings[0].details.postalAddress`)}
            />
          </Col>
        )}
      </Row>
      {shippingMethod === 'by post' && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input
              name="firstName"
              label="First name"
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeShippingDetails(e.target.value, 'firstName')
              }
              errorMessage={getError(`shippings[0].details.firstName`)}
            />
          </Col>
          <Col span={12}>
            <Input
              name="lastName"
              label="Last name"
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeShippingDetails(e.target.value, 'lastName')
              }
              errorMessage={getError(`shippings[0].details.lastName`)}
            />
          </Col>
        </Row>
      )}
      <h4 className="title-section">Emails</h4>
      {emails.map(({ email, _id }, idx) => (
        <Fragment key={_id === '' ? idx : _id}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
              <Input
                value={email || ''}
                onChange={onChangeArrayElement(idx)}
                label="Email"
                errorMessage={getError(`emails[${idx}].email`)}
              />
            </Col>
          </Row>
          {emails.length > 1 && (
            <Row gutter={[16, 16]} justify="end" align="middle">
              <button
                className="remove-child-btn"
                onClick={onDeleteEmailEntity(!_id || _id === '' ? idx : _id)}>
                Delete email
                <TrashIcon />
              </button>
            </Row>
          )}
        </Fragment>
      ))}
      <button className="add-common-btn" onClick={onAddEmailEntity}>
        <CrossIcon />
        Add email
      </button>

      <h4 className="title-section">Phone information</h4>
      {phones.map(({ phone, _id }, idx) => (
        <Fragment key={_id === '' ? idx : _id}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
              <Input
                inputType="phone"
                value={phone || ''}
                onChange={onChangeArrayElement(idx)}
                label="Phone number"
                errorMessage={getError(`phones[${idx}].phone`)}
              />
            </Col>
          </Row>
          {phones.length > 1 && (
            <Row gutter={[16, 16]} justify="end" align="middle">
              <button
                className="remove-child-btn"
                onClick={onDeletePhoneEntity(!_id || _id === '' ? idx : _id)}>
                Delete phone number
                <TrashIcon />
              </button>
            </Row>
          )}
        </Fragment>
      ))}
      <button className="add-common-btn" onClick={onAddPhoneEntity}>
        <CrossIcon />
        Add phone number
      </button>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span className="title-section">Do you want to merge files</span>
        </Col>
        <Col>
          <Radio.Group
            name="mergeInvoiceFiles"
            value={mergeInvoiceFiles}
            onChange={onChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </Col>
  );
};

export default GeneralInformation;
