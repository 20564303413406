import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';
import { IEmployeeDto } from '../../../common/api/services/UserApi/types/IEmployeeDto';
import { IPagination, TSortValue } from '../../types';

const initialState: IState = {
  leadList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    saleList: [],
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  selectedLead: {
    leadDetails: {
      numberOfDependantTeenagers: '',
      swissWorkPermit: '',
      permitType: '',
      salaryRateNoVAT: '',
      salaryType: '',
      currency: '',
      workingDaysHours: '',
      comments: '',
      notes: '',
      _id: '',
      cantonLiveResident: '',
      cantonWorkResident: '',
      countryResident: '',
      countryWorkResident: '',
      createdAt: '',
      email: '',
      firstName: '',
      howOld: '',
      isSpouseWork: '',
      lastName: '',
      maritalStatus: '',
      nationality: '',
      numberOfDependantChildren: '',
      phone: '',
      status: '',
      updatedAt: '',
      dateOfBirth: null,
    },
    isUpdateLeadDetails: false,
    errorMessages: null,
    isShowUserDetails: false,
  },
  isLoading: false,
};

const waitingRoomReducer = createSlice({
  name: 'waitingRoom',
  initialState,
  reducers: {
    setLoadList(state, action: PayloadAction<IGetListDto<ILeadDto>>) {
      state.leadList.content = action.payload.result;
      state.leadList.pagination.totalElement = action.payload.total;
    },
    putLeadToList(state, action: PayloadAction<ILeadDto>) {
      const leadIdx = state.leadList.content.findIndex(
        (lead) => lead._id === action.payload._id,
      );
      state.leadList.content[leadIdx] = action.payload;
    },
    setLeadListPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.leadList.pagination.currentPage = action.payload.currentPage;
      state.leadList.pagination.perPage = action.payload.perPage;
    },
    putSelectedLeadDetails(state, action: PayloadAction<ILeadDto>) {
      state.selectedLead.leadDetails = action.payload;
    },
    changeLeadDetails(state, action: PayloadAction<Record<string, string>>) {
      state.selectedLead.leadDetails = {
        ...state.selectedLead.leadDetails,
        ...action.payload,
      };
    },
    setErrorMessage(
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) {
      state.selectedLead.errorMessages = action.payload;
    },
    clearSelectedLead(state) {
      state.selectedLead = initialState.selectedLead;
    },
    updateIsUpdateLeadDetails(state, action: PayloadAction<boolean>) {
      state.selectedLead.isUpdateLeadDetails = action.payload;
    },
    updateLeadBirthDate(state, action: PayloadAction<null | string>) {
      state.selectedLead.leadDetails.dateOfBirth = action.payload;
    },
    setDataLeadLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSaleList(state, action: PayloadAction<IEmployeeDto[]>) {
      state.leadList.saleList = action.payload;
    },
    changeLeadListFiltering(state, action: any) {
      state.leadList.filter = {
        ...state.leadList.filter,
        ...action.payload,
      };
    },
    setLeadListSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.leadList.sort.sortBy = action.payload[0];
      state.leadList.sort.sort = action.payload[1];
    },
    clearWaitingRoomFiltersAndSorts(state) {
      state.leadList.sort = initialState.leadList.sort;
      state.leadList.filter = initialState.leadList.filter;
    },
    changeIsShowDetails(state, action: PayloadAction<boolean>) {
      state.selectedLead.isShowUserDetails = action.payload;
    },
  },
});

export const {
  setLoadList,
  setLeadListPagination,
  putSelectedLeadDetails,
  changeLeadDetails,
  setErrorMessage,
  clearSelectedLead,
  putLeadToList,
  updateIsUpdateLeadDetails,
  updateLeadBirthDate,
  setDataLeadLoading,
  setSaleList,
  changeLeadListFiltering,
  setLeadListSorting,
  clearWaitingRoomFiltersAndSorts,
  changeIsShowDetails,
} = waitingRoomReducer.actions;
export default waitingRoomReducer.reducer;
