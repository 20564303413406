import { ChangeEvent, FC, useEffect, useState } from 'react';
import Input from '../../../components/input/Input';
import Dropdown from '../../../components/dropdown/Dropdown';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createEmployee } from '../../../redux/reducers/employees/asyncThunkActions';
import {
  setErrorMessages,
  updateOpenModalNewEmployee,
} from '../../../redux/reducers/employees/sliceReducer';
import { Roles } from '../../../common/enums/Roles';

const roles = [Roles.ADMIN, Roles.MANAGER];

const AddNewEmployeesModalContent: FC = () => {
  const dispatch = useAppDispatch();
  const { errorMessages, inviteLink } = useAppSelector(
    (state) => state.employees,
  );
  const invitedLinkLoading = inviteLink.loading;
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
  });

  useEffect(() => {
    return () => {
      dispatch(setErrorMessages(null));
    };
  }, []);

  const onHide = () => {
    dispatch(updateOpenModalNewEmployee(false));
  };

  const onSubmit = () => {
    dispatch(createEmployee(fields));
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangePhone = (value: string) => {
    setFields((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const onChangeSelect = (value: string) => {
    setFields((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const getError = (errorField: string): string => {
    return errorMessages ? errorMessages[errorField] ?? '' : '';
  };

  return (
    <>
      <Input
        label="First name"
        value={fields.firstName}
        name="firstName"
        onChange={onChangeInput}
        errorMessage={getError('firstName')}
      />
      <Input
        errorMessage={getError('lastName')}
        label="Last name"
        value={fields.lastName}
        name="lastName"
        onChange={onChangeInput}
      />
      <Input
        label="Email"
        value={fields.email}
        name="email"
        onChange={onChangeInput}
        errorMessage={getError('email')}
      />
      <Input
        inputType="phone"
        value={fields.phone}
        onChange={onChangePhone}
        label="Phone number"
        errorMessage={getError('phone')}
        placeholder="+XX XXX XXX XX XX"
      />
      <Dropdown
        label="Role"
        errorMessage={getError('role')}
        value={fields.role}
        options={roles}
        showSearch={false}
        onChange={onChangeSelect}
      />
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonStyle="secondary"
            buttonSize="normal"
            onClick={onHide}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonStyle="primary"
            buttonSize="normal"
            onClick={onSubmit}
            disable={invitedLinkLoading}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddNewEmployeesModalContent;
