import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Col, Popover, Radio, RadioChangeEvent, Row, Switch } from 'antd';

import { IWorkContract } from '../../../../redux/reducers/onboarding/types';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import Checkbox from '../../../../components/checkbox/Checkbox';
import {
  SalaryType,
  TreasuryPaymentDate,
} from '../../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import InputNumber from '../../../../components/input/InputNumber';
import { numeriqOfficeOptions } from '../../../../common/constants/numeriqOfficeOptions';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import { IEmployeeDto } from '../../../../common/api/services/UserApi/types/IEmployeeDto';
import { userApi } from '../../../../common/api/services/UserApi/userApi';
import { openErrorNotification } from '../../../../components/notification/Notification';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setSeller } from '../../../../redux/reducers/onboarding/sliceReducer';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';

const locations = ['Office', 'Hybrid', 'Remote'];

type workPaymentDate = TreasuryPaymentDate | '';
const types = (treasuryPaymentDate: workPaymentDate) => {
  if (treasuryPaymentDate === '') {
    return Object.values(SalaryType);
  }
  if (treasuryPaymentDate === TreasuryPaymentDate.IN_DAYS) {
    return [SalaryType.PerMonth];
  }
  return Object.values(SalaryType).filter(
    (type) => type !== SalaryType.PerMonth,
  );
};
interface IProps {
  getError: (fieldName: string, isGroup?: boolean) => string;
  workContract: IWorkContract;
  onChange: (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent) => void;
  onChangeCheckbox: (fieldName: 'receivedWorkContract') => () => void;
  onChangeLocation: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeOtherJob: (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => void;
  onChangeDate: (fieldName: string) => (date: Date | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdownLocation: (fieldName: string) => (data: any) => void;
  onChangeSwitch: (fieldName: 'indefinite') => void;
  onChangeSwitchCommon: (
    fieldName: 'hasRepresentativeAllowance',
    defaultValue: boolean,
  ) => void;
  onChangeNumber: (
    fieldName: string,
  ) => (value: number | string | null) => void;
  onChangeSwitchOptimization: () => void;
  isAdmin: boolean;
  onChangeUseMealAllowance: () => void;
}

const WorkContractTabJobDetailsSection: FC<IProps> = (props) => {
  const {
    workContract,
    getError,
    onChange,
    onChangeCheckbox,
    onChangeDate,
    onChangeDropdown,
    onChangeSwitch,
    onChangeSwitchOptimization,
    onChangeSwitchCommon,
    onChangeLocation,
    onChangeOtherJob,
    onChangeDropdownLocation,
    onChangeNumber,
    onChangeUseMealAllowance,
    isAdmin,
  } = props;
  const dispatch = useAppDispatch();
  const { initialWorkContractOrder } = useAppSelector(
    (state) => state.onboarding,
  );

  const {
    selectedClientIdx,
    companies,
    useExpensesForOptimization = false,
    useMealAllowance = false,
  } = workContract;

  const convertedSelectedIdx = convertSelectedIdx(
    companies,
    initialWorkContractOrder,
    selectedClientIdx,
  );

  const {
    jobTitle = '',
    projectDescription = '',
    startDate = '',
    endDate = '',
    indefinite = true,
    rate = 0,
    type = '',
    workLocation: workLocationFromState,
    otherJob: otherJobFromState,
    receivedWorkContract = false,
    workContracLink = '',
    overtimeAllowed = isAdmin ? true : undefined,
    doubleTimeAllowed = isAdmin ? true : undefined,
    treasuryPaymentDate = '',
    hasRepresentativeAllowance = false,
    representativeAmount = '',
    numeriqOffice,
    seller = '',
  } = companies[selectedClientIdx];

  const workLocation = workLocationFromState || {
    type: '',
    hybridActivity: '',
    officeAddress: '',
    hybridAddress: '',
  };

  const {
    type: workLocationType,
    hybridActivity,
    officeAddress,
    hybridAddress,
  } = workLocation;

  const otherJob = otherJobFromState || {
    active: true,
    isPrimaryJob: true,
    numeriqTime: 0,
  };

  const { active, isPrimaryJob, numeriqTime } = otherJob;
  const [sellersList, setSellersList] = useState<Array<IEmployeeDto>>([]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    userApi
      .getManagers()
      .then((resp) => {
        setSellersList(resp);
      })
      .catch((e) => {
        openErrorNotification('Error when getting managers');
      });
  }, []);

  const onChangeSeller = (value: any) => {
    dispatch(setSeller(value));
  };

  return (
    <Col span={12}>
      <h4>Job details</h4>
      {isAdmin && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={numeriqOfficeOptions}
              value={numeriqOffice}
              onChange={onChangeDropdown('numeriqOffice')}
              label="Numeriq Office"
              showSearch={false}
              allowClear={!!numeriqOffice}
              errorMessage={getError(
                `data.companies[${convertedSelectedIdx}].numeriqOffice`,
              )}
              showAsterisk
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="jobTitle"
            label="Job title"
            value={jobTitle}
            onChange={onChange}
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].jobTitle`,
            )}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="projectDescription"
            label="Project description"
            value={projectDescription}
            onChange={onChange}
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].projectDescription`,
            )}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ height: '54px' }}>
        <Col span={12}>
          <CommonDatePicker
            label="Start date"
            defaultValue={startDate}
            changeDate={onChangeDate('startDate')}
            className="date-picker-form"
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].startDate`,
            )}
            calendarPosition="left-calendar-position"
          />
        </Col>
        <Col span={12}>
          <CommonDatePicker
            label="End date"
            defaultValue={endDate}
            changeDate={onChangeDate('endDate')}
            className="date-picker-form"
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].endDate`,
            )}
            disabled={indefinite}
            calendarPosition="left-calendar-position"
          />
        </Col>
      </Row>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginTop: '3rem' }}>
        <Col span={12}>
          <span>Indefinite</span>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Switch
            checked={indefinite}
            onChange={() => onChangeSwitch('indefinite')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InputNumber
            label="Rate"
            onChange={onChangeNumber('rate')}
            value={rate}
            name="rate"
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].rate`,
            )}
            showIcons={false}
          />
        </Col>
        <Col span={12}>
          <Dropdown
            options={types(treasuryPaymentDate as workPaymentDate)}
            value={type}
            onChange={onChangeDropdown('type')}
            label="Type"
            showSearch={false}
            allowClear={!!type}
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].type`,
            )}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={locations}
            value={workLocationType}
            onChange={onChangeDropdownLocation('type')}
            label="Work location"
            showSearch={false}
            errorMessage={
              getError(
                `data.companies[${convertedSelectedIdx}].workLocation.type`,
              ) ||
              getError(
                `data.companies[${convertedSelectedIdx}].workLocation`,
                true,
              )
            }
            allowClear
            onClear={() => onChangeDropdownLocation('type')}
          />
        </Col>
      </Row>
      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={sellersList.map((item) => {
                return {
                  label: `${item.firstName || ''} ${item.lastName || ''}`,
                  value: item._id,
                };
              })}
              value={seller}
              onChange={onChangeSeller}
              label="Seller"
              showSearch={false}
              errorMessage={
                getError(`data.companies[${convertedSelectedIdx}].seller`) ||
                getError(`data.companies[${convertedSelectedIdx}].seller`, true)
              }
              allowClear
              onClear={() => onChangeSeller('')}
              showAsterisk
            />
          </Col>
        </Row>
      )}
      {workLocationType === 'Hybrid' && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12} style={{ marginTop: 19 }}>
              For hybrid % work from home
            </Col>
            <Col span={12}>
              <Input
                name="hybridActivity"
                label="%"
                value={hybridActivity}
                onChange={onChangeLocation}
                errorMessage={
                  getError(
                    `data.companies[${convertedSelectedIdx}].workLocation.hybridActivity`,
                  ) ||
                  getError(
                    `data.companies[${convertedSelectedIdx}].workLocation`,
                    true,
                  )
                }
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Input
                name="hybridAddress"
                label="Address of the office"
                value={hybridAddress}
                onChange={onChangeLocation}
                errorMessage={
                  getError(
                    `data.companies[${convertedSelectedIdx}].workLocation.hybridAddress`,
                  ) ||
                  getError(
                    `data.companies[${convertedSelectedIdx}].workLocation`,
                    true,
                  )
                }
              />
            </Col>
          </Row>
        </>
      )}

      {workLocationType === 'Office' && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="officeAddress"
              label="Address of the office"
              value={officeAddress}
              onChange={onChangeLocation}
              errorMessage={
                getError(
                  `data.companies[${convertedSelectedIdx}].workLocation.officeAddress`,
                ) ||
                getError(
                  `data.companies[${convertedSelectedIdx}].workLocation`,
                  true,
                )
              }
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={16}>Do you have other job (not in Numeriq)?</Col>
        <Col span={8}>
          <Radio.Group name="active" value={active} onChange={onChangeOtherJob}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      {active && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={16}>Is it a primary job?</Col>
            <Col span={8}>
              <Radio.Group
                name="isPrimaryJob"
                value={isPrimaryJob}
                onChange={onChangeOtherJob}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[16, 16]}>
        <Col span={12} style={{ marginTop: 19 }}>
          % of time you work for Numeriq
        </Col>
        <Col span={12}>
          <InputNumber
            label="%"
            onChange={onChangeNumber('numeriqTime')}
            value={numeriqTime}
            name="numeriqTime"
            errorMessage={getError(
              `data.companies[${convertedSelectedIdx}].otherJob.numeriqTime`,
            )}
            showIcons={false}
          />
        </Col>
      </Row>

      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={16}>
            Overtime allowed:<span className="asterisk">*</span>
          </Col>
          <Col span={8}>
            <Radio.Group
              name="overtimeAllowed"
              value={isAdmin ? overtimeAllowed : undefined}
              onChange={onChange}>
              <Radio value={true} disabled={!isAdmin}>
                Yes
              </Radio>
              <Radio value={false} disabled={!isAdmin}>
                No
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={16}>
            Double time allowed:<span className="asterisk">*</span>
          </Col>
          <Col span={8}>
            <Radio.Group
              name="doubleTimeAllowed"
              value={isAdmin ? doubleTimeAllowed : undefined}
              onChange={onChange}>
              <Radio value={true} disabled={!isAdmin}>
                Yes
              </Radio>
              <Radio value={false} disabled={!isAdmin}>
                No
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
      {isAdmin && <h4 style={{ margin: '45px 0 0 0' }}>Expenses</h4>}

      {isAdmin && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col span={12}>
            <span>Expenses for optimization</span>
            <span className="asterisk">*</span>
            <Popover
              className="info-pop-button"
              placement="rightTop"
              content={<p>Receipt mandatory</p>}
              trigger="hover"
              overlayClassName="info-pop-content"
              showArrow={false}>
              <button>
                <WarningIcon />
              </button>
            </Popover>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Switch
              defaultChecked={useExpensesForOptimization}
              onChange={() => onChangeSwitchOptimization()}
              disabled={!isAdmin}
            />
          </Col>
        </Row>
      )}
      {isAdmin && (
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col span={12}>
            <span>
              Meal Allowance
              <span className="asterisk">*</span>
            </span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Switch
              checked={useMealAllowance}
              onChange={() => onChangeUseMealAllowance()}
            />
          </Col>
        </Row>
      )}

      {isAdmin && (
        <>
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col span={12}>
              <span>
                Representative allowance
                <span className="asterisk">*</span>
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Switch
                checked={hasRepresentativeAllowance}
                onChange={() =>
                  onChangeSwitchCommon('hasRepresentativeAllowance', false)
                }
              />
            </Col>
          </Row>

          {hasRepresentativeAllowance && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="representativeAmount"
                  label="Representative amount CHF"
                  value={`${representativeAmount}`}
                  placeholder="Number"
                  onChange={onChange}
                  showAsterisk
                  errorMessage={getError(
                    `data.companies[${convertedSelectedIdx}].representativeAmount`,
                  )}
                />
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Checkbox
                label="Received Work contract."
                checked={receivedWorkContract}
                onChange={onChangeCheckbox('receivedWorkContract')}
                showAsterisk
              />
            </Col>
          </Row>

          {receivedWorkContract && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="workContracLink"
                  label="Work contract link"
                  value={workContracLink}
                  onChange={onChange}
                  showAsterisk
                  errorMessage={getError(
                    `data.companies[${convertedSelectedIdx}].workContracLink`,
                  )}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Col>
  );
};

export default WorkContractTabJobDetailsSection;
