import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const FilterIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2.16667C1 1.52233 1.52233 1 2.16667 1H10.8333C11.4777 1 12 1.52233 12 2.16667V2.51021C12 2.95348 11.8318 3.38022 11.5293 3.70427L8.46377 6.98882C8.26214 7.20485 8.15 7.48935 8.15 7.78486V9.98948C8.15 10.2104 8.02517 10.4124 7.82754 10.5112L5.69421 11.5779C5.30635 11.7718 4.85 11.4898 4.85 11.0561V7.78486C4.85 7.48935 4.73786 7.20485 4.53623 6.98882L1.47065 3.70427C1.16821 3.38022 1 2.95348 1 2.51021V2.16667Z"
        strokeWidth="1.05"
        fill="#a9bed3"
      />
    </svg>
  );
};

export default FilterIcon;
