import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination, TSortValue } from '../../types';

const initialState: IState = {
  logsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
};

const reducer = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    //TODO
    setLogsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.logsList.content = action.payload.result;
      state.logsList.pagination.totalElement = action.payload.total;
    },
    changeLogsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.logsList.pagination.currentPage = 1;
      state.logsList.filter = {
        ...state.logsList.filter,
        ...action.payload,
      };
    },
    setLogsSorting(state, action: PayloadAction<any>) {
      state.logsList.sort.sortBy = action.payload[0];
      state.logsList.sort.sort = action.payload[1];
    },
    clearLogsFiltersAndSorts(state) {
      state.logsList.sort = initialState.logsList.sort;
      state.logsList.filter = initialState.logsList.filter;
    },
    setLogsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.logsList.pagination.currentPage = action.payload.currentPage;
      state.logsList.pagination.perPage = action.payload.perPage;
    },
  },
});

export const {
  setLogsList,
  changeLogsFiltering,
  clearLogsFiltersAndSorts,
  setLogsPagination,
  setLogsSorting,
} = reducer.actions;

export default reducer.reducer;
