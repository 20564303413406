import { FC, useEffect, Key, ChangeEvent, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Modal from 'antd/lib/modal/Modal';
import {
  approveInternalTimesheet,
  changeInternalTimesheetSorting,
  expireInternalTimesheet,
  getInternalTimesheet,
  publishInternalTimesheet,
  rejectInternalTimesheet,
  saveAsDraftInternalTimesheet,
  submitInternalTimesheet,
  uploadFile,
} from '../../redux/reducers/timesheets/asyncThunkActions';
import {
  changeTimesheetFiltering,
  clearSelectedTimesheet,
  removeFileSelectedTimesheet,
  resetFilesSelectedTimesheet,
  selectInternalTimesheetItem,
  setErrorMessages,
  updateByIdSelectedTimesheet,
  updateOpenModalAddTimesheet,
  updateOpenModalEditTimesheet,
  updateOpenModalViewTimesheet,
  updateSelectedTimesheet,
  updateSelectedTimesheetCheckbox,
} from '../../redux/reducers/timesheets/sliceReducer';
import { RadioChangeEvent, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import {
  timesheetStatusParser,
  TTimesheetStatusParserKey,
} from '../../common/constants/timesheetStatusParser';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionsMenu from './components/ActionsMenu';
import {
  getFormatDateWithMonth,
  getFormatDateWithTime,
} from '../../common/utils/getFormatDate';
import {
  TimesheetStatus,
  TimesheetsStatusesArray,
} from '../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { ITimesheetResponse } from '../../common/api/services/TimesheetApi/types/ITimesheetResponse';
import ModalEditTimesheet from './components/ModalEditTimesheet';
import { IConsultantTimesheet } from '../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import ModalAddTimesheet from './components/ModalAddTimesheet';
import ModalViewTimesheet from './components/ModalViewTimesheet';
import { INewTimesheet } from '../../common/api/services/TimesheetApi/types/INewTimesheet';
import { useTablePeriodFilter } from '../../common/hooks/useTablePerionFilter';
import { SalaryType } from '../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import TableTooltip from '../../components/common-table/table-tooltip';
import {
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import EditIcon from '../../assets/icons/EditIcon';
import './InternalTimesheetPage.less';
import { ISelectedNode } from '../monitoring-page/components/NodeEdit';
import EditNoteModal from '../../components/edit-note-modal/EditNoteModal';
import { timesheetApi } from '../../common/api/services/TimesheetApi/timesheetApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { DeleteTimesheetText } from '../../components/common-texts/CommonTexts';
import { useLocation } from 'react-router-dom';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';

const InternalTimesheetPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    isOpenModalEditTimesheet,
    isOpenModalAddTimesheet,
    isOpenModalViewTimesheet,
    isUpLoading,
    selectedTimesheet,
    errorMessages,
    timesheetList,
  } = useAppSelector((state) => state.timesheets);

  const urlParams = new URLSearchParams(location.search);
  const publicId = urlParams.get('publicId');
  const timesheetId = urlParams.get('timesheetId');
  const { content, pagination, sort, filter, selectedConsultant } =
    timesheetList;
  const { totalElement, currentPage, perPage } = pagination;
  const [selectedNote, setSelectedNote] = useState<ISelectedNode | null>(null);
  const [deleteInfo, setDeleteInfo] = useState<any>(null);

  useEffect(() => {
    if (publicId) {
      changeFilter({ consultantId: publicId });
    }
    dispatch(getInternalTimesheet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timesheetId) {
      timesheetApi
        .fetchTimesheetById(timesheetId)
        .then((response: any) => {
          dispatch(updateSelectedTimesheet(response));
          if (
            response &&
            [
              TimesheetStatus.DRAFT,
              TimesheetStatus.NO_ACTIVITY,
              TimesheetStatus.EXPIRED,
              TimesheetStatus.REJECTED,
            ].includes(response.status)
          ) {
            showModalEditTimesheet(response._id)();
            return;
          }
          showModalViewTimesheet(response._id)();
        })
        .catch((e) => {
          openErrorNotification('Timesheet was not found');
        });
    }
  }, []);

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeTimesheetFiltering(date));
  };

  const dataFilter = useTableMonthDate({
    fetchRequest: () => dispatch(getInternalTimesheet({ currentPage: 1 })),
    changeValues: changeFilter,
    defaultValue: filter?.period ?? '',
    valueType: 'period',
  })();

  const searchFilterArg = {
    fetchRequest: () => dispatch(getInternalTimesheet({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeInternalTimesheetSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getInternalTimesheet({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const showModalEditTimesheet = (id: string) => (): void => {
    dispatch(updateByIdSelectedTimesheet(id));
    dispatch(updateOpenModalEditTimesheet(true));
    dispatch(setErrorMessages(null));
  };

  const showModalViewTimesheet = (id: string) => (): void => {
    dispatch(updateByIdSelectedTimesheet(id));
    dispatch(updateOpenModalViewTimesheet(true));
  };

  const approveTimesheet =
    (selectedTimesheet: IConsultantTimesheet) => (): void => {
      dispatch(approveInternalTimesheet(selectedTimesheet));
    };

  const rejectTimesheet =
    (selectedTimesheet: IConsultantTimesheet) => (): void => {
      dispatch(rejectInternalTimesheet(selectedTimesheet));
    };

  const expireTimesheet =
    (selectedTimesheet: IConsultantTimesheet) => (): void => {
      dispatch(expireInternalTimesheet(selectedTimesheet));
    };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectInternalTimesheetItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedConsultant,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';

      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const onHideNote = () => {
    setSelectedNote(null);
  };

  const onChangeNotes = (id: string, notes: string) => {
    if (!id) {
      return;
    }
    timesheetApi
      .addTimesheetNotes(id, notes)
      .then(() => {
        openSuccessNotification('Note was added successfully');
        dispatch(getInternalTimesheet());
      })
      .catch((e) => {
        openErrorNotification('Failed to add note');
      });
  };

  const handleCancel = () => {
    dispatch(updateOpenModalAddTimesheet(false));
    dispatch(updateOpenModalEditTimesheet(false));
    dispatch(updateOpenModalViewTimesheet(false));
    dispatch(clearSelectedTimesheet());
  };

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasActivityPerMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedTimesheet());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          standardTime: 0,
          overtime: 0,
          doubleTime: 0,
        }),
      );
    }
    dispatch(updateSelectedTimesheet({ [fieldName]: fieldValue }));
  };

  const onChangeCheckbox = (fieldName: 'isSigned' | 'approvalRequired') => {
    return () => {
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedTimesheetCheckbox(fieldName));
    };
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };

  const onChangeNumber =
    (fieldName: string) => (value: number | string | null) => {
      dispatch(
        updateSelectedTimesheet({
          [fieldName]: value,
        }),
      );
    };
  const onChangeVisibility = (visibility: boolean) => {
    dispatch(
      updateSelectedTimesheet({
        visibility: visibility ? 'public' : 'private',
      }),
    );
  };

  const onRemoveAttachFile = (originalFilename: string) => {
    dispatch(removeFileSelectedTimesheet(originalFilename));
  };

  const onSaveAsDraft = () => {
    dispatch(saveAsDraftInternalTimesheet());
  };

  const onSubmit = () => {
    dispatch(submitInternalTimesheet());
  };

  const onPublish = (params: INewTimesheet) => (): void => {
    dispatch(publishInternalTimesheet(params));
  };

  const deleteTimesheet = () => {
    if (!deleteInfo) {
      return;
    }
    timesheetApi
      .deleteTimesheet(deleteInfo.id)
      .then(() => {
        openSuccessNotification('Timesheet was deleted successfully');
        dispatch(getInternalTimesheet());
      })
      .catch((e) => {
        openErrorNotification('Failed to delete');
      })
      .finally(() => {
        onCloseDeleteModal();
      });
  };

  const onCloseDeleteModal = () => {
    setDeleteInfo(null);
  };

  const onDoubleClick = (record: any) => {
    if (
      [
        TimesheetStatus.DRAFT,
        TimesheetStatus.NO_ACTIVITY,
        TimesheetStatus.EXPIRED,
        TimesheetStatus.REJECTED,
      ].includes(record.status)
    ) {
      showModalEditTimesheet(record.item._id)();
      return;
    }
    showModalViewTimesheet(record.item._id)();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Consultant ID',
      dataIndex: 'consultantId',
      width: 160,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.consultantId as string) ?? '',
      })('consultantId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Consultant first name',
      dataIndex: 'consultant.firstName',
      key: 'consultant.firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['consultant.firstName']
          ? filter['consultant.firstName']
          : '',
      })('consultant.firstName'),
      className: sortClassName('consultant.firstName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 216,
      fixed: 'left',
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={216}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Consultant last name',
      dataIndex: 'consultant.lastName',
      key: 'consultant.lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['consultant.lastName']
          ? filter['consultant.lastName']
          : '',
      })('consultant.lastName'),
      className: sortClassName('consultant.lastName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 216,
      fixed: 'left',
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={216}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.clientName ?? '',
      })('clientName'),
      className: sortClassName('clientName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={155}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={150}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...dataFilter,
      className: sortClassName('period'),
      sorter: true,
      sortDirections: ['descend'],
      width: 135,
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={135}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Comments',
      dataIndex: 'numeriqComments',
      key: 'numeriqComments',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.numeriqComments as string) ?? '',
      })('numeriqComments'),
      className: sortClassName('numeriqComments'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (numeriqComments) => {
        return (
          <TableTooltip tootltipTitle={numeriqComments} columnWidth={150}>
            <div className="cut-text">{numeriqComments}</div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Submission date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      sorter: true,
      sortDirections: ['descend'],
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['submissionDate']
          ? (filter['submissionDate'] as string)
          : '',
        field: 'submissionDate',
      })(),
      width: 200,
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Client approval date',
      dataIndex: 'approvalDate',
      key: 'approvalDate',
      width: 180,
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={180}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.status as unknown as string[]) ?? [],
        fieldFilterName: 'status',
      })(TimesheetsStatusesArray),
      className: `status-td ${sortClassName('status')}`,
      sorter: true,
      sortDirections: ['descend'],
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={timesheetStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.notes as string) ?? '',
      })('notes'),
      className: sortClassName('notes'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
      render: (notes, { item }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableTooltip tootltipTitle={notes} columnWidth={180}>
              <div className="cut-text" style={{ width: '130px' }}>
                {notes}
              </div>
            </TableTooltip>
            <EditIcon
              style={{ position: 'absolute', right: '10px' }}
              onClick={() => {
                setSelectedNote({
                  clientId: item._id,
                  consultantId: item._id,
                  name: `${item.consultant.firstName} ${item.consultant.lastName}`,
                  notesValue: item.notes,
                  clientFullName: item?.workContract?.name || '',
                  id: item._id,
                  publicId: item.publicId,
                  period: moment(item.period).format(
                    commonMonthYearSpaceFormat,
                  ),
                  type: 'Timesheet',
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 80,
      fixed: 'right',
      render: (_, { item }) => {
        return (
          <div
            className="action-container"
            style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionsMenu
                  showModalEditTimesheet={showModalEditTimesheet(item._id)}
                  showModalViewTimesheet={showModalViewTimesheet(item._id)}
                  approveTimesheet={approveTimesheet(item)}
                  rejectTimesheet={rejectTimesheet(item)}
                  expireTimesheet={expireTimesheet(item)}
                  status={item.status as TimesheetStatus}
                  id={item._id}
                  publicId={item.publicId}
                  firstName={(item as ITimesheetResponse).consultant.firstName}
                  lastName={(item as ITimesheetResponse).consultant.lastName}
                  setDeleteInfo={setDeleteInfo}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
    },
  ];

  const data = content.map((item) => ({
    key: item._id,
    submissionDate: item.submissionDate
      ? moment(item.submissionDate).format(commonMonthTimeFormat)
      : '',
    item,
    publicId: item.publicId,
    ['consultant.firstName']: (item as ITimesheetResponse).consultant.firstName,
    ['consultant.lastName']: (item as ITimesheetResponse).consultant.lastName,
    clientName: item.workContract?.name ? item.workContract.name : 'No',
    projectDescription: item.workContract?.projectDescription
      ? item.workContract?.projectDescription
      : 'No',
    period: getFormatDateWithMonth(item.period),
    approvalDate: item.clientApprovalDate
      ? getFormatDateWithTime(item.clientApprovalDate)
      : 'No',
    status: item.status,
    isStandardTime: getIsStandardTime(
      item.workContract?.type ?? '',
      item.standardTime,
    ),
    consultantId: item?.consultant?.publicId || '',
    numeriqComments: item.numeriqComments,
    notes: item.notes || 'No',
  }));

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChangeSort}
        rowSelection={rowSelection}
        onDoubleClick={onDoubleClick}
      />

      {isOpenModalEditTimesheet && (
        <Modal
          width={640}
          title={'Edit timesheet'}
          open={isOpenModalEditTimesheet}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-timesheet">
          <ModalEditTimesheet
            isUpLoading={isUpLoading}
            selectedTimesheet={selectedTimesheet}
            getError={getError}
            onChange={onChange}
            onChangeCheckbox={onChangeCheckbox}
            onChangeFile={onChangeFile}
            onChangeNumber={onChangeNumber}
            onRemoveAttachFile={onRemoveAttachFile}
            onSaveAsDraft={onSaveAsDraft}
            onSubmit={onSubmit}
            onChangeVisibility={onChangeVisibility}
          />
        </Modal>
      )}
      {!!selectedNote && (
        <EditNoteModal
          isOpen={!!selectedNote}
          onCancel={onHideNote}
          onChangeNotes={onChangeNotes}
          selectedNote={selectedNote}
        />
      )}

      {isOpenModalViewTimesheet && (
        <Modal
          width={640}
          title={
            <div className="modal-view-title">
              View timesheet{' '}
              <span
                className={timesheetStatusParser[selectedTimesheet?.status]}>
                {selectedTimesheet?.status.toUpperCase() || ''}
              </span>
            </div>
          }
          open={isOpenModalViewTimesheet}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper timesheet">
          <ModalViewTimesheet selectedTimesheet={selectedTimesheet} />
        </Modal>
      )}
      {deleteInfo && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={onCloseDeleteModal}
          confirmAction={deleteTimesheet}
          confirmBtnTitle="Yes, delete"
          content={
            <DeleteTimesheetText
              firstName={deleteInfo.firstName}
              lastName={deleteInfo.lastName}
              publicId={deleteInfo.publicId}
              type="Timesheet"
            />
          }
          isOpen={deleteInfo}
          isHideAttentionText
          width={550}
          btnWidth={'230px'}
        />
      )}

      {isOpenModalAddTimesheet && (
        <Modal
          width={selectedTimesheet?._id === '' ? 468 : 640}
          title="Add new timesheet"
          open={isOpenModalAddTimesheet}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-timesheet">
          {selectedTimesheet?._id === '' ? (
            <ModalAddTimesheet
              getError={getError}
              handleCancel={handleCancel}
              onPublish={onPublish}
            />
          ) : (
            <ModalEditTimesheet
              isUpLoading={isUpLoading}
              selectedTimesheet={selectedTimesheet}
              getError={getError}
              onChange={onChange}
              onChangeCheckbox={onChangeCheckbox}
              onChangeFile={onChangeFile}
              onChangeNumber={onChangeNumber}
              onRemoveAttachFile={onRemoveAttachFile}
              onSaveAsDraft={onSaveAsDraft}
              onSubmit={onSubmit}
              onChangeVisibility={onChangeVisibility}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default InternalTimesheetPage;

const getIsStandardTime = (type: string, standardTime: number): boolean => {
  return (
    (type === SalaryType.PerHour && standardTime > 200) ||
    (type === SalaryType.PerDay && standardTime > 25) ||
    (type === SalaryType.PerMonth && standardTime > 1)
  );
};
