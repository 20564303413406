import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationRequest, IRootState } from '../../types';
import { setPayslipsSorting } from './sliceReducer';

export const getPayslipsList = createAsyncThunk(
  'payslips/getPayslipsList',
  async (pagination: IPaginationRequest | undefined, { getState }) => {
    const currentPagination = (getState() as IRootState).payslips.payslipsList
      .pagination;
    const currentFilter = (getState() as IRootState).payslips.payslipsList
      .filter;
    const currentSort = (getState() as IRootState).payslips.payslipsList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    // const response = await consultantApi.getConsultantExpenses(
    //   page,
    //   perPage,
    //   currentFilter,
    //   currentSort,
    // );
    //
    // if (response) {
    //   batch(() => {
    //     dispatch(setPayslipsList(response));
    //     dispatch(setPayslipsPagination({ currentPage: page, perPage }));
    //   });
    // }
    return Promise.resolve([]);
  },
);

export const changePayslipsSorting = createAsyncThunk(
  'payslips/changePayslipsSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).payslips.payslipsList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setPayslipsSorting([currentSortBy, 'asc']));
      dispatch(getPayslipsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setPayslipsSorting([currentSortBy, 'desc']));
      dispatch(getPayslipsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setPayslipsSorting(['', '']));
      dispatch(getPayslipsList({ currentPage: 1 }));
      return;
    }
  },
);
