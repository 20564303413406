import moment from '../constants/moment';
import { TAnyObject } from '../types/TAnyObject';

const smallMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const fullMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
//TODO: replace all functions to moment
export const getFormatDateWithTime = (currentDate: string): string => {
  if (!currentDate) return 'No';

  const t = new Date(currentDate);
  const date = ('0' + t.getDate()).slice(-2);
  const month = smallMonths[t.getMonth()];
  const year = t.getFullYear();
  const hours = ('0' + t.getHours()).slice(-2);
  const minutes = ('0' + t.getMinutes()).slice(-2);
  return `${date} ${month} ${year} - ${hours}:${minutes}`;
};

export const getFormatDateWithSmallMonth = (currentDate: string): string => {
  if (!currentDate) return 'No';

  const t = new Date(currentDate);
  const date = ('0' + t.getDate()).slice(-2);
  const month = smallMonths[t.getMonth()];
  const year = t.getFullYear();
  return `${date} ${month} ${year}`;
};

export const getFormatDateWithMonth = (currentDate: string): string => {
  if (!currentDate) return 'No';

  const t = new Date(currentDate);
  const month = fullMonth[t.getMonth()];
  const year = t.getFullYear();
  return `${month} ${year}`;
};

export const getDefaultFormatDate = (currentDate: string): string => {
  if (!currentDate) return 'No';

  const t = new Date(currentDate);
  const date = ('0' + t.getDate()).slice(-2);
  const month = ('0' + (t.getMonth() + 1)).slice(-2);
  const year = t.getFullYear();

  if (isNaN(+date) || isNaN(+month) || isNaN(+year)) return '';

  return `${date}/${month}/${year}`;
};

export const getDateByGreenwich = (currentDate: string): string => {
  if (!currentDate) return 'No';

  const d = new Date(currentDate);
  const options: TAnyObject = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'Europe/London',
  };
  const newDate = new Intl.DateTimeFormat('en-US', options)
    .format(d)
    .split('/');

  const date = ('0' + newDate[1]).slice(-2);
  const month = smallMonths[+newDate[0] - 1];
  const year = newDate[2];
  return `${date} ${month} ${year}`;
};

export const convertStringToDate = (date: string, format: string): Date => {
  return moment(date, format).toDate();
};
