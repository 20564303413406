import { FC } from 'react';
import { Menu } from 'antd';

interface IProps {
  openEditPage: () => void;
}

const ActionMenu: FC<IProps> = ({ openEditPage }) => {
  const allItems = [
    {
      key: '1',
      label: <span onClick={openEditPage}>Edit</span>,
    },
    {
      key: '2',
      label: <span style={{ color: '#C33025' }}>Archieve</span>,
    },
  ];

  return <Menu items={allItems} />;
};

export default ActionMenu;
