import { FC } from 'react';
import { Menu } from 'antd';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { IMainActivity } from '../../../common/api/services/MainActivitiesApi/types/types';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { expensesApi } from '../../../common/api/services/ExpensesApi/expensesApi';
import { openErrorNotification } from '../../../components/notification/Notification';
import { timesheetApi } from '../../../common/api/services/TimesheetApi/timesheetApi';
import { updateSelectedExpense } from '../../../redux/reducers/expenses/sliceReducer';
import { useAppDispatch } from '../../../redux/store';
import { updateSelectedTimesheet } from '../../../redux/reducers/timesheets/sliceReducer';

interface IProps {
  mainActivity: IMainActivity;
  setModalType: (type: 'close' | '' | 'delete' | 'view' | 'edit') => void;
  status: any;
  setSelectedMainActivity: (mainActivity: IMainActivity) => void;
  expireExpence: (mainActivity: any) => void;
  onApprove: (mainActivity: any) => void;
  onReject: (mainActivity: any) => void;
}

const statuses: Record<string, string[]> = {
  [ExpensesStatus.DRAFT]: ['1', '4', '7'],
  [ExpensesStatus.NO_EXPENSES]: ['1', '7'],
  [TimesheetStatus.NO_ACTIVITY]: ['1', '7'],
  [ExpensesStatus.EXPIRED]: ['1', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_FOR_OPTIMISATION}`]:
    ['2', '6', '3', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_MEAL_ALLOWANCE}`]:
    ['2', '6', '3', '7'],
  [`${ExpensesStatus.WAITING_FOR_APPROVAL}_${ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT}`]:
    ['2', '6', '7'],
  [TimesheetStatus.WAITING_FOR_APPROVAL]: ['2', '6', '7'],
  [ExpensesStatus.CLIENT_APPROVAL]: ['2', '6', '3', '7'],
  [ExpensesStatus.PRE_APPROVED]: ['2', '6', '3', '7'],
  [ExpensesStatus.APPROVED]: ['2', '6', '7'],
  [ExpensesStatus.REJECTED]: ['1', '7'],
};

const ActionMenu: FC<IProps> = ({
  mainActivity,
  setModalType,
  status,
  setSelectedMainActivity,
  expireExpence,
  onApprove,
  onReject,
}) => {
  const dispatch = useAppDispatch();
  const getExpence = (type: 'close' | '' | 'delete' | 'view' | 'edit') => {
    expensesApi
      .fetchExpenseById(mainActivity._id)
      .then((resp: any) => {
        setSelectedMainActivity(resp);
        setModalType(type);
        dispatch(updateSelectedExpense(resp));
      })
      .catch((e) => {
        openErrorNotification('Expence not found');
      });
  };
  const getTimesheet = (type: 'close' | '' | 'delete' | 'view' | 'edit') => {
    timesheetApi
      .fetchTimesheetById(mainActivity._id)
      .then((resp: any) => {
        setSelectedMainActivity(resp);
        setModalType(type);
        dispatch(updateSelectedTimesheet(resp));
      })
      .catch((e) => {
        openErrorNotification('Timesheet not found');
      });
  };
  const allItems = [
    {
      key: '1',
      label: (
        <span
          onClick={() => {
            mainActivity.kind === 'T'
              ? getTimesheet('edit')
              : getExpence('edit');
          }}>
          Edit
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span
          onClick={() => {
            mainActivity.kind === 'T'
              ? getTimesheet('view')
              : getExpence('view');
          }}>
          View
        </span>
      ),
    },
    {
      key: '3',
      label: (
        <span
          onClick={() => {
            setSelectedMainActivity(mainActivity);
            onApprove(mainActivity);
          }}>
          Approve
        </span>
      ),
    },
    {
      key: '4',
      label: (
        <span
          onClick={() => {
            expireExpence(mainActivity);
          }}>
          Close
        </span>
      ),
    },
    {
      key: '6',
      label: <span onClick={() => onReject(mainActivity)}>Reject</span>,
    },
    {
      key: '7',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() => {
            setModalType('delete');
            setSelectedMainActivity(mainActivity);
          }}>
          Delete
        </span>
      ),
    },
  ];

  const items = allItems
    .filter((item) => (mainActivity.kind === 'T' ? item.key !== '4' : item))
    .map((item) => {
      const statusKey =
        status +
        (status === ExpensesStatus.WAITING_FOR_APPROVAL &&
        mainActivity.kind !== 'T'
          ? `_${mainActivity.kind}`
          : '');
      if (!statuses[statusKey].includes(item.key)) return null;
      return item;
    })
    .filter(Boolean);

  return <Menu items={items} />;
};

export default ActionMenu;
