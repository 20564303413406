import { FC, useCallback, useEffect, useRef, useState } from 'react';
import DropzoneIcon from '../../assets/icons/DropzoneIcon';
import { useDropzone } from 'react-dropzone';
import './UploadDropzoneOverlay.less';

interface IProps {
  onSubmit: (e: File[]) => void;
}
const UploadDropzoneOverlay: FC<IProps> = ({ onSubmit }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = () => {
    setIsDragOver(true);
  };

  useEffect(() => {
    document.body.addEventListener('dragover', handleDragOver);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
    };
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onSubmit(acceptedFiles);
    setIsDragOver(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragLeave: () => setIsDragOver(false),
  });

  return (
    <div
      ref={ref}
      className="upload-dropzone-overlay"
      style={{ display: isDragOver ? 'block' : 'none' }}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="dropzone-content">
          <DropzoneIcon />

          <p className="main-text">
            To upload click or drag files to this area.
          </p>
          <p>Formats: PDF, JPG, JPEG, PNG.</p>
          <p>Max.size: 25 MB.</p>
        </div>
      </div>
    </div>
  );
};

export default UploadDropzoneOverlay;
