import { IApiRouteParams } from '../types/IApiRouteParams';
import qs from 'qs';

export const getApiRoute = (
  route: string,
  { query, params = {} }: IApiRouteParams = {},
): string => {
  let formattedRoute = route;

  for (const paramKey of Object.keys(params)) {
    formattedRoute = formattedRoute.replace(`:${paramKey}`, params[paramKey]);
  }

  if (query) {
    const queryString = qs.stringify(query, { arrayFormat: 'repeat' });
    formattedRoute = `${formattedRoute}?${queryString}`;
  }

  return formattedRoute;
};
