import { ExpensesStatus } from '../api/services/ConsultantApi/enums/ResponseEnums';
import { TimesheetStatus } from '../api/services/TimesheetApi/enums/TimesheetStatus';

export const timesheetsStatusesHovers = {
  [TimesheetStatus.DRAFT]:
    'Your Timesheet needs to be filled out and submitted',
  [TimesheetStatus.WAITING_FOR_APPROVAL]:
    'Your Timesheet is waiting to be approved by the Client',
  [TimesheetStatus.PRE_APPROVED]:
    'Your Timesheet has already been approved by the Client and needs approval from Numeriq',
  [TimesheetStatus.APPROVED]:
    'Your Timesheet has been approved by the Client and Numeriq',
  [TimesheetStatus.NO_ACTIVITY]:
    'You did not have any activities during the month',
  [TimesheetStatus.EXPIRED]:
    'Your Timesheet has not been submitted for the long time and expired',
  [TimesheetStatus.REJECTED]:
    'Your Timesheet has been rejected. Please contact Numeriq',
  [TimesheetStatus.CLIENT_APPROVAL]:
    'Your Timesheet has been approved by the Client and needs approval from Numeriq',
};

export const expensesStatusesHovers = {
  [ExpensesStatus.DRAFT]: 'Your Expenses need to be filled out and submitted',
  [ExpensesStatus.WAITING_FOR_APPROVAL]:
    'Your Expenses are waiting to be approved by the Client',
  [ExpensesStatus.PRE_APPROVED]:
    'Your Expenses have already been approved by the Client and need approval from Numeriq',
  [ExpensesStatus.APPROVED]:
    'Your Expenses have been approved by the Client and Numeriq',
  [ExpensesStatus.NO_EXPENSES]:
    'You did not have any expenses during the month',
  [ExpensesStatus.EXPIRED]:
    'Your Expenses have not been submitted for the long time and expired',
  [ExpensesStatus.REJECTED]:
    'Your Expenses have been rejected. Please contact Numeriq',
  [ExpensesStatus.CLIENT_APPROVAL]:
    'Your Expenses have been approved by the Client and need approval from Numeriq',
};
