import { FC } from 'react';
import { Col, Row } from 'antd';
import Button from '../../../components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  clearSelectedTimesheet,
  setIsOpenModalHasExpenses,
} from '../../../redux/reducers/timesheets/sliceReducer';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';

const { expenses } = ROUTER_PATHS;

const ModalExpenses: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const timesheetId = useAppSelector(
    (state) => state.timesheets.selectedTimesheet._id,
  );

  const clickNoHandler = async (): Promise<void> => {
    await consultantApi.fetchConsultantNoExpenses(timesheetId);
    dispatch(clearSelectedTimesheet());
    dispatch(setIsOpenModalHasExpenses(false));
  };

  const clickYesHandler = (): void => {
    dispatch(setIsOpenModalHasExpenses(false));
    dispatch(clearSelectedTimesheet());
    navigation(expenses);
  };

  return (
    <>
      <p>Do you have any expenses related to the submitted Timesheet?</p>
      <Row gutter={[16, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={clickNoHandler}>
            No
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={clickYesHandler}>
            Yes
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModalExpenses;
