import { ISort } from '../../../redux/types';
import { OnboardingRequestStatuses } from '../services/OnboardingApi/enums/OnboardingRequestStatuses';

export const getQueryParams = (
  filter: Record<string, string | boolean | string[]>,
  sort: ISort,
): Record<string, string | string[]> => {
  const queries: Record<string, string | string[]> = {};

  Object.entries({ ...filter, ...sort }).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length <= 1) {
      value.forEach((item) => {
        queries[key] = item;
      });
    }

    if (
      Array.isArray(value) &&
      value.length !== 0 &&
      key === 'consultantOnboardingStatus'
    ) {
      const filtersOptions: string[] = [];
      value.forEach((item) => {
        filtersOptions.push(
          item === OnboardingRequestStatuses.draft
            ? `${item},${OnboardingRequestStatuses.empty}`
            : item,
        );
      });
      queries[key] = filtersOptions.toString();
    }

    if (
      Array.isArray(value) &&
      value.length !== 0 &&
      key !== 'consultantOnboardingStatus'
    ) {
      const filtersOptions: string[] = [];
      value.forEach((item) => {
        filtersOptions.push(item);
      });
      queries[key] = filtersOptions.toString();
    }

    if (value !== '' && !Array.isArray(value)) {
      queries[key] = value;
    }
  });

  return queries;
};
