import { Modal } from 'antd';
import NodeEdit from '../../pages/monitoring-page/components/NodeEdit';

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  //TODO
  onChangeNotes: any;
  selectedNote: any;
  isMainActivity?: boolean;
}

const EditNoteModal = (props: IProps) => {
  const { isOpen, onCancel, onChangeNotes, selectedNote, isMainActivity } =
    props;
  return (
    <Modal
      width={600}
      title={'Edit notes'}
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={null}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <NodeEdit
        onChangeNotes={onChangeNotes}
        note={selectedNote}
        onHide={onCancel}
        isMainActivity={isMainActivity}
      />
    </Modal>
  );
};

export default EditNoteModal;
