import { ISort } from '../../../../redux/types';
import { ApiServiceBase } from '../../ApiServiceBase';
import { getQueryParams } from '../../utils/getQueryParams';
import { IInvoiceDTODraft } from './types/types';

class InvoiceApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/invoices`;
  addInvoice(
    source: Array<{
      consultantId?: string | null;
      clientId?: string | null;
      period?: string | null;
      projectId?: string | null;
    }>,
  ): Promise<any> {
    const body = {
      source,
    };
    return this.post<any>(``, body).then((response) => response);
  }
  addDepositInvoice(
    source: Array<{
      consultantId?: string | null;
      clientId?: string | null;
      period?: string | null;
      projectId?: string | null;
    }>,
  ): Promise<any> {
    const body = {
      source,
    };
    return this.post<any>(`/deposit`, body).then((response) => response);
  }
  getInvoiceById(id: string): Promise<any> {
    return this.get<any>(`/${id}`).then((response) => response);
  }
  deleteInvoice(id: string): Promise<any> {
    return this.delete<any>(`/${id}`).then((response) => response);
  }
  deleteInvoiceItem(invoiceId: string, itemId: string): Promise<any> {
    return this.delete<any>(`/${invoiceId}/items/${itemId}`).then(
      (response) => response,
    );
  }
  saveDraftInvoice(body: IInvoiceDTODraft, invoiceId: string) {
    return this.patch<any>(`/${invoiceId}/draft`, body).then(
      (response) => response,
    );
  }
  sendInvoice(body: IInvoiceDTODraft, invoiceId: string) {
    return this.patch<any>(`/${invoiceId}/sent`, body).then(
      (response) => response,
    );
  }
  partiallyPaidInvoice(invoiceId: string, paymentComment: string) {
    return this.patch<any>(`/${invoiceId}/partially-paid`, {
      paymentComment,
    }).then((response) => response);
  }
  paidInvoice(invoiceId: string, paymentComment: string) {
    return this.patch<any>(`/${invoiceId}/paid`, {
      paymentComment,
    }).then((response) => response);
  }
  changeInvoiceDate(date: string, invoiceId: string) {
    return this.patch<any>(`/${invoiceId}/date`, {
      date,
    }).then((response) => response);
  }
  getInvoices(ids: Array<string>) {
    return this.get(
      `?${ids
        .map((id, index) => {
          return `ids[]=${id}${index + 1 === ids.length ? '' : '&'}`;
        })
        .join('')}`,
    ).then((response) => response);
  }
  getInvoicesList(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get(``, {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    }).then((response) => response);
  }
  getInvoiceAttachments(publicId: string) {
    return this.get(`/${publicId}/attachments`).then((response) => response);
  }
  deleteInvoiceAttachment(invoiceId: string, id: string) {
    return this.delete(`/${invoiceId}/attachments/${id}`).then(
      (response) => response,
    );
  }
  getPreview(invoiceId: string) {
    return this.get(`/${invoiceId}/asset`);
  }
  getInvoiceZIP(invoiceId: string) {
    return this.get(`/${invoiceId}/zip`, { responseType: 'blob' });
  }
  updateInvoicePaymentDate(ids: Array<string>, date: string) {
    return this.patch(`/payment-date`, { date, ids });
  }
  updateInvoicePaymentComment(id: string, paymentComment: string) {
    return this.patch(`/${id}/payment-comment`, { paymentComment });
  }
  updateInvoicePayByClient(id: string, amount: number) {
    return this.patch(`/${id}/paid-by-client`, { amount });
  }
  updateInvoiceStatus(id: string, status: string) {
    return this.put(`/${id}/${status}`);
  }
  downloadInvoiceExcel(
    filter: Record<string, string | string[]>,
    sort: ISort,
    ids: string[],
  ) {
    const query = { ...getQueryParams(filter, sort) };

    const queryString = Object.entries(query)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value
            .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    const idsQueryString = ids
      .map((id) => `ids[]=${encodeURIComponent(id)}`)
      .join('&');

    const url = `/excel${idsQueryString ? `?${idsQueryString}` : ''}${
      queryString ? `${idsQueryString ? '&' : '?'}${queryString}` : ''
    }`;

    return this.get(url, { responseType: 'blob' }).then((response) => response);
  }

  getInvoiceAddresses(invoiceId: string) {
    return this.get(`/${invoiceId}/addresses`);
  }
  updateItemDescription(
    invoiceId: string,
    itemId: string,
    description: string,
  ) {
    return this.put(`/${invoiceId}/items/${itemId}/description`, {
      description,
    });
  }
}

export const invoiceApi = new InvoiceApi();
