import { ColumnsType } from 'antd/lib/table';
import CommonTable from '../../../../components/common-table/CommonTable';
import {
  TViewInvoiceStatusParserKey,
  viewIncoiceStatusParser,
} from '../../../../common/constants/timesheetStatusParser';
import { ROUTER_PATHS } from '../../../../navigation/routerPaths';
import TableTooltip from '../../../../components/common-table/table-tooltip';
import { formatAmount } from '../../../../common/utils/formatAmount';
import { TableColorStatusParser } from '../../../../components/common-table/common/tableColorConstants';

interface IProps {
  activityReportPayload: any;
  isLoadingTimesheets: boolean;
}
const { mainActivities } = ROUTER_PATHS;

const TimesheetExpensesTable = (props: IProps) => {
  const { activityReportPayload, isLoadingTimesheets } = props;

  const data = activityReportPayload?.map((item: any) => ({
    publicId: item.publicId,
    standardTime: item.standardTime || 'No',
    overtime: item.overtime || 'No',
    doubleTime: item.doubleTime || 'No',
    invoiceCurrency: item.workContract?.invoiceCurrency,
    billRate: item.docType === 'E' ? 'No' : item?.clientContract?.billRate,
    status: item.invoiceStatus,
    salaryAmount: item.workContract?.salaryAmount,
    docType: item.docType,
    totalBillRate: formatAmount(item.totalBillRate) || 'No',
    totalPayRate: formatAmount(item.totalPayRate) || 'No',
    totalSsnRate: item.totalSsnRate,
    invoiceId: item.invoicePublicId,
    _id: item._id,
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={100} placement="right">
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Standart time',
      dataIndex: 'standardTime',
      width: 120,
    },
    {
      title: 'Overtime',
      dataIndex: 'overtime',
      width: 100,
    },
    {
      title: 'Double time',
      dataIndex: 'doubleTime',
      width: 120,
    },
    {
      title: 'Currency',
      dataIndex: 'invoiceCurrency',
      width: 100,
    },
    {
      title: 'Rate',
      dataIndex: 'billRate',
      width: 100,
    },
    {
      title: 'Total bill rate',
      dataIndex: 'totalBillRate',
      width: 140,
    },
    {
      title: 'Total pay rate',
      dataIndex: 'totalPayRate',
      width: 140,
    },
    {
      title: 'Total AHV salary',
      dataIndex: 'totalSsnRate',
      width: 140,
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoiceId',
      width: 140,
    },

    {
      title: 'Invoice status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td`,
      render: (text: TViewInvoiceStatusParserKey) => {
        if (!text) {
          return null;
        }
        return (
          <div className="status-wrapper">
            <span className={TableColorStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 150,
    },
  ];

  const onDoubleClick = (record: any) => {
    window.open(
      record.docType === 'T'
        ? `${mainActivities}?mainActivityId=${record._id}&type=T`
        : `${mainActivities}?mainActivityId=${record._id}&type=E`,
      '_blank',
    );
  };

  return (
    <CommonTable
      data={data}
      columns={columns}
      hidePagination={true}
      onDoubleClick={onDoubleClick}
      loading={isLoadingTimesheets}
      height={190}
      tableHeight={230}
    />
  );
};

export default TimesheetExpensesTable;
