import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IBankAccountRequest } from '../../../../common/api/services/OnboardingApi/types/IBankAccountRequest';
import { IOnboardingSaveOptions } from './../types';
import { getStringToIBAN } from '../../../../common/utils/getStringToIBAN';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftBankAccountAsEmployee = createAsyncThunk(
  'onboarding/saveDraftBankAccount',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { bankAccount, tabStatus, consultantId } = (getState() as IRootState)
      .onboarding;
    const isDraft =
      tabStatus[4] === OnboardingRequestStatuses.draft ||
      tabStatus[4] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putBankAccountAsEmployee(
        consultantId,
        dataQuery,
      );

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 4, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 4,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'bankAccount',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveToConfirmBankAccountAsEmployee = createAsyncThunk(
  'onboarding/saveToConfirmBankAccount',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { bankAccount, tabStatus, consultantId } = (getState() as IRootState)
      .onboarding;

    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    const { isSuccess, data } = await onboardingApi.putBankAccountAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 4, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'bankAccount',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftBankAccountAsConsultant = createAsyncThunk(
  'onboarding/saveDraftBankAccountAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { bankAccount, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[4] === OnboardingRequestStatuses.draft ||
      tabStatus[4] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: bankAccount,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putBankAccountAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(
                tabStatus,
                4,
                redirectRootRout,
                false,
                true,
              ),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 4,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'bankAccount',
            fieldValue: data,
          }),
        );
      }
    }
  },
);
export const savePublishBankAccountAsEmployee = createAsyncThunk(
  'onboarding/savePublishBankAccount',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { bankAccount, tabStatus, consultantId } = (getState() as IRootState)
      .onboarding;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: bankAccount.hasBankAccount
        ? OnboardingRequestStatuses.published
        : OnboardingRequestStatuses.toConfirm,
      type: bankAccount.hasBankAccount
        ? OnboardingRequestTypes.required
        : OnboardingRequestTypes.optional,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    const { isSuccess, data } = await onboardingApi.putBankAccountAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 4, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: bankAccount.hasBankAccount
            ? OnboardingRequestStatuses.published
            : OnboardingRequestStatuses.toConfirm,
          isAdmin: true,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'bankAccount',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishBankAccountAsConsultant = createAsyncThunk(
  'onboarding/savePublishBankAccountAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { bankAccount, tabStatus } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<IBankAccountRequest> = {
      key: OnboardingRequestKeys.bankAccount,
      status: bankAccount.hasBankAccount
        ? OnboardingRequestStatuses.published
        : OnboardingRequestStatuses.toConfirm,
      type: bankAccount.hasBankAccount
        ? OnboardingRequestTypes.required
        : OnboardingRequestTypes.optional,
      data: {
        ...bankAccount,
        iban: getStringToIBAN(bankAccount.iban),
      },
    };

    const { isSuccess, data } = await onboardingApi.putBankAccountAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }

    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 4,
          value: bankAccount.hasBankAccount
            ? OnboardingRequestStatuses.published
            : OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(
        push(
          getOnboardingRedirect(tabStatus, 4, redirectRootRout, false, true),
        ),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'bankAccount',
          fieldValue: data,
        }),
      );
    }
  },
);
