import { FC } from 'react';
import { Col, Row } from 'antd';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';

interface IProps {
  inputValues: Pick<
    ILeadDto,
    | 'swissWorkPermit'
    | 'permitType'
    | 'salaryRateNoVAT'
    | 'salaryType'
    | 'currency'
    | 'workingDaysHours'
    | 'comments'
    | 'notes'
  >;
}

const WorkInfoViewComponent: FC<IProps> = ({ inputValues }) => {
  const {
    swissWorkPermit,
    permitType,
    salaryRateNoVAT,
    salaryType,
    currency,
    workingDaysHours,
    comments,
    notes = '',
  } = inputValues;

  return (
    <Col span={9} className="work-info-section">
      <h3>Work information</h3>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="personal-info">
            <span className="item-label">Swiss work permit:</span>
            <span className="item-value">{swissWorkPermit}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <div className="personal-info">
            <span className="item-label">Work permit type</span>
            <span className="item-value">{permitType}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Bill rate</span>
            <span className="item-value">{salaryRateNoVAT}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Salary type</span>
            <span className="item-value">{salaryType}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Salary type</span>
            <span className="item-value">{currency}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col span={24}>
          <div className="personal-info">
            <span className="item-label">Work schedule</span>
            <span className="item-value">{workingDaysHours}</span>
          </div>
        </Col>
      </Row>

      <h3 className="comment-section-title">Comments section</h3>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <div className="personal-info">
            <span className="item-label">Consultant Comments</span>
            <span className="item-value">{comments}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <div className="personal-info">
            <span className="item-label">Numeriq Notes</span>
            <span className="item-value">{notes}</span>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default WorkInfoViewComponent;
