import { FC } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { getDefaultFormatDate } from '../../../common/utils/getFormatDate';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import OnboardingWorkPermitTab from '../../onboarding-page/tabs/work-permit-tab/WorkPermitTab';
import OnboardingWorkPermitMessage from '../../../components/info-messages/OnboardingWorkPermitMessage';
import DocumentsViewer from '../components/DocumentsViewer';

const WorkPermitTab: FC = () => {
  const {
    documentType,
    permitType,
    permitCanton,
    startDate,
    endDate,
    basisOfLegalStay,
    comment,
    documents,
  } = useAppSelector((state) => state.onboarding.workPermit);
  const isToConfirm = useAppSelector(
    (state) =>
      state.onboarding.tabStatus[3] === OnboardingRequestStatuses.toConfirm ||
      state.onboarding.tabStatus[3] === OnboardingRequestStatuses.draft,
  );
  const isSwissNationality = useAppSelector(
    (state) => state.onboarding.personalInfo.nationality === 'Switzerland',
  );

  if (isToConfirm) {
    return <OnboardingWorkPermitTab />;
  }

  if (isSwissNationality) {
    return (
      <Row>
        <Col span={12}>
          <OnboardingWorkPermitMessage />
        </Col>
      </Row>
    );
  }

  return (
    <Row className="my-information-tab">
      <Col span={12}>
        <h4>Information</h4>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Document type</span>
                <span className="item-value">{documentType}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Permit type</span>
                <span className="item-value">{permitType}</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Permit canton</span>
                <span className="item-value">{permitCanton}</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Start date</span>
                <span className="item-value">
                  {startDate ? getDefaultFormatDate(startDate) : ''}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">End date</span>
                <span className="item-value">
                  {endDate ? getDefaultFormatDate(endDate) : ''}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Basis of legal stay</span>
                <span className="item-value">{basisOfLegalStay}</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Comment</span>
                <span className="item-value">{!!comment ? comment : '-'}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>

      <Col span={12}>
        <h4>Documents</h4>

        <DocumentsViewer documents={documents} />
      </Col>
    </Row>
  );
};

export default WorkPermitTab;
