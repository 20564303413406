import React from 'react';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import './ResetFilterBtn.less';

const ResetFilterBtn = () => {
  //TODO: hook useMedia
  if (window.screen.width <= 768) {
    return <span className="reset-mobile-btn">Reset</span>;
  }
  return <ResetFilterIcon />;
};

export default ResetFilterBtn;
