import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IChangeClientArrayElement,
  IChangeInvoiceArrayElement,
  IClientEntity,
  ICurrentDeleteInfo,
  IState,
  TSelectedClientInvoiceNames,
} from './type';
import { IPagination, TSortValue } from '../../types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IClientResponse } from '../../../common/api/services/ClientApi/types/IClientResponse';
import { TAnyObject } from '../../../common/types/TAnyObject';

export const initialClientAddress = {
  _id: '',
  street: '',
  city: '',
  country: '',
  postCode: '',
  createdAt: '',
  updatedAt: '',
  vat: '',
  addressLines: [],
};

export const initialShipping = {
  method: '',
  details: {
    email: '',
    lastName: '',
    firstName: '',
    portalUrl: '',
    postalAddress: '',
    id: '',
  },
};

export const initialClientExtraInfo = {
  email: '',
  name: '',
  lastName: '',
  _id: '',
  createdAt: '',
  updatedAt: '',
};

export const initialClientContact = {
  email: '',
  name: '',
  lastName: '',
  phone: '',
  _id: '',
  createdAt: '',
  updatedAt: '',
};

export const initialClientPhone = {
  phone: '',
  _id: '',
  createdAt: '',
  updatedAt: '',
};
export const initialClientEmail = {
  email: '',
  _id: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: IState = {
  clientList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  selectedClients: [],
  selectedClient: {
    _id: '',
    countryCode: '',
    vat: '',
    isFactoring: false,
    isRegisteredAtCreditSuisse: false,
    name: '',
    emails: [initialClientEmail],
    phones: [initialClientPhone],
    addresses: [initialClientAddress],
    signatories: [initialClientExtraInfo],
    invoiceries: [initialClientExtraInfo],
    reminders: [initialClientExtraInfo],
    additionalInvoiceries: [],
    shippings: [initialShipping],
    clientContacts: [initialClientContact],
    notes: '',
    createdAt: '',
    updatedAt: '',
    status: '',
    visibility: '',
    files: [],
    publicId: 0,
    paymentTerm: null,
    paymentTermRange: '',
    agencyEmailsCC: [],
    mergeInvoiceFiles: false,
  },
  currentDeleteInfo: null,
  deleteInfo: null,
  isOpenDeleteModal: false,
  errorMessages: {},
  countryCodeOptions: [],
  isUpdateSelectedClient: false,
};

const reducer = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    changeSelectedClient(state, action: PayloadAction<TAnyObject>) {
      state.selectedClient = {
        ...state.selectedClient,
        ...action.payload,
      };
    },
    setClientsList(state, action: PayloadAction<IGetListDto<IClientResponse>>) {
      state.clientList.content = action.payload.result;
      state.clientList.pagination.totalElement = action.payload.total;
    },
    changeClientsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.clientList.filter = {
        ...state.clientList.filter,
        ...action.payload,
      };
    },
    setClientsSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.clientList.sort.sortBy = action.payload[0];
      state.clientList.sort.sort = action.payload[1];
    },
    clearClientsFiltersAndSorts(state) {
      state.clientList.sort = initialState.clientList.sort;
      state.clientList.filter = initialState.clientList.filter;
      state.selectedClients = initialState.selectedClients;
    },
    setClientsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.clientList.pagination.currentPage = action.payload.currentPage;
      state.clientList.pagination.perPage = action.payload.perPage;
    },
    setCountryCodeOptions(state, action: PayloadAction<string[]>) {
      state.countryCodeOptions = action.payload;
    },
    addNewEmailEntity(state) {
      state.selectedClient.emails = [
        ...state.selectedClient.emails,
        initialClientEmail,
      ];
      state.isUpdateSelectedClient = true;
    },
    addNewPhoneEntity(state) {
      state.selectedClient.phones = [
        ...state.selectedClient.phones,
        initialClientPhone,
      ];
      state.isUpdateSelectedClient = true;
    },
    updateEmailEntity(
      state,
      action: PayloadAction<
        Omit<IChangeClientArrayElement, 'extraName' | 'name'>
      >,
    ) {
      const { value, index } = action.payload;
      state.selectedClient.emails[index].email = value;
      state.isUpdateSelectedClient = true;
    },
    updatePhoneEntity(
      state,
      action: PayloadAction<
        Omit<IChangeClientArrayElement, 'extraName' | 'name'>
      >,
    ) {
      const { value, index } = action.payload;
      state.selectedClient.phones[index].phone = value;
      state.isUpdateSelectedClient = true;
    },
    deleteEmailEntity(state, action: PayloadAction<number>) {
      state.selectedClient.emails = state.selectedClient.emails.filter(
        (_, idx) => idx !== action.payload,
      );
      state.isUpdateSelectedClient = true;
    },
    deletePhoneEntity(state, action: PayloadAction<number>) {
      state.selectedClient.phones = state.selectedClient.phones.filter(
        (_, idx) => idx !== action.payload,
      );
      state.isUpdateSelectedClient = true;
    },
    addNewAddress(state) {
      const addresses = state.selectedClient.addresses;
      state.selectedClient.addresses = [...addresses, initialClientAddress];
    },
    updateAddress(
      state,
      action: PayloadAction<Omit<IChangeClientArrayElement, 'name'>>,
    ) {
      const { value, index, extraName } = action.payload;

      state.selectedClient.addresses[index] = {
        ...state.selectedClient.addresses[index],
        [extraName as string]: value,
      };
      state.isUpdateSelectedClient = true;
    },
    deleteAddress(state, action: PayloadAction<number>) {
      state.selectedClient.addresses = state.selectedClient.addresses.filter(
        (_, idx) => idx !== action.payload,
      );
      state.isUpdateSelectedClient = true;
    },
    addNewAddressLineClient(state, action: PayloadAction<number>) {
      state.selectedClient.addresses[action.payload].addressLines = [
        ...(state.selectedClient.addresses[action.payload].addressLines || []),
        '',
      ];
      state.isUpdateSelectedClient = true;
    },
    changeAddressLineClient(
      state,
      action: PayloadAction<{
        index: number;
        lineIndex: number;
        value: string;
      }>,
    ) {
      const { index, lineIndex, value } = action.payload;
      const addressLines =
        state.selectedClient?.addresses?.[index]?.addressLines;

      if (addressLines && addressLines[lineIndex] !== undefined) {
        addressLines[lineIndex] = value;
      }
      state.isUpdateSelectedClient = true;
    },
    removeAddressLineClient(
      state,
      action: PayloadAction<{
        index: number;
        lineIndex: number;
      }>,
    ) {
      state.selectedClient.addresses[action.payload.index].addressLines =
        state.selectedClient.addresses[
          action.payload.index
        ].addressLines?.filter(
          (item, index) => index !== action.payload.lineIndex,
        );
      state.isUpdateSelectedClient = true;
    },
    addInvoiceSection(
      state,
      action: PayloadAction<TSelectedClientInvoiceNames>,
    ) {
      const array = state.selectedClient[action.payload];
      state.selectedClient[action.payload] = [...array, initialClientExtraInfo];
      state.isUpdateSelectedClient = true;
    },
    updateInvoiceSection(
      state,
      action: PayloadAction<IChangeInvoiceArrayElement>,
    ) {
      const { value, index, extraName, name } = action.payload;

      state.selectedClient[name][index] = {
        ...state.selectedClient[name][index],
        [extraName as string]: value,
      };
      state.isUpdateSelectedClient = true;
    },
    updateClientContacts(
      state,
      action: PayloadAction<Omit<IChangeInvoiceArrayElement, 'name'>>,
    ) {
      const { value, index, extraName } = action.payload;

      state.selectedClient.clientContacts[index] = {
        ...state.selectedClient?.clientContacts[index],
        [extraName as string]: value,
      };
      state.isUpdateSelectedClient = true;
    },
    deleteInvoiceSection(
      state,
      action: PayloadAction<
        Omit<IChangeInvoiceArrayElement, 'value' | 'extraName'>
      >,
    ) {
      const { index, name } = action.payload;
      state.selectedClient[name] = state.selectedClient[name].filter(
        (_, idx) => idx !== index,
      );
      state.isUpdateSelectedClient = true;
    },
    setIsUpdateSelectedClient(state, action: PayloadAction<boolean>) {
      state.isUpdateSelectedClient = action.payload;
    },
    setErrorsMessages(state, action: PayloadAction<Record<string, string>>) {
      state.errorMessages = action.payload;
    },
    clearSelectedClient(state) {
      state.selectedClient = initialState.selectedClient;
      state.isUpdateSelectedClient = initialState.isUpdateSelectedClient;
      state.errorMessages = initialState.errorMessages;
    },
    toogleErrorModal(state, action: PayloadAction<boolean>) {
      state.isOpenDeleteModal = action.payload;
    },
    updateDeleteInfo(state, action: PayloadAction<IClientEntity | null>) {
      state.deleteInfo = action.payload;
    },
    setCurrentDeleteInfo(
      state,
      action: PayloadAction<ICurrentDeleteInfo | null>,
    ) {
      state.currentDeleteInfo = action.payload;
    },
    updateShippingMethod(state, action: PayloadAction<string>) {
      const shipping = state.selectedClient.shippings;
      shipping[0].method = action.payload;
      state.selectedClient.shippings = shipping;
    },
    updateShippingDetails(
      state,
      action: PayloadAction<{ method: string; detail: string }>,
    ) {
      const { method, detail } = action.payload;
      const shipping: any = state.selectedClient.shippings;
      shipping[0].details[method] = detail;
      state.selectedClient.shippings = shipping;
    },
    selectClientsList(state, action: PayloadAction<string[]>) {
      state.selectedClients = action.payload;
    },
    addFile(state, action: any) {
      state.selectedClient.files = [
        ...state.selectedClient.files,
        ...action.payload,
      ];
    },
    removeFile(state, action: PayloadAction<string>) {
      state.selectedClient.files = state.selectedClient.files.filter(
        (item: any) => item.originalFilename !== action.payload,
      );
    },
    resetShippingDetails(state) {
      const shipping: any = state.selectedClient.shippings;
      shipping[0].details = {
        ...shipping[0].details,
        email: '',
        lastName: '',
        firstName: '',
        portalUrl: '',
        postalAddress: '',
      };
      state.selectedClient.shippings = shipping;
    },
  },
});

export const {
  setClientsList,
  clearClientsFiltersAndSorts,
  setClientsPagination,
  changeClientsFiltering,
  setClientsSorting,
  setCountryCodeOptions,
  addNewPhoneEntity,
  addNewEmailEntity,
  deletePhoneEntity,
  deleteEmailEntity,
  updatePhoneEntity,
  updateEmailEntity,
  updateAddress,
  addNewAddress,
  deleteAddress,
  changeSelectedClient,
  addInvoiceSection,
  updateInvoiceSection,
  deleteInvoiceSection,
  setIsUpdateSelectedClient,
  setErrorsMessages,
  clearSelectedClient,
  updateClientContacts,
  toogleErrorModal,
  updateDeleteInfo,
  setCurrentDeleteInfo,
  updateShippingMethod,
  updateShippingDetails,
  resetShippingDetails,
  selectClientsList,
  addFile,
  removeFile,
  changeAddressLineClient,
  addNewAddressLineClient,
  removeAddressLineClient,
} = reducer.actions;

export default reducer.reducer;
