import { FC, useEffect, useRef } from 'react';
import { Col, Layout, Modal, Row } from 'antd';
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import FullLogoIcon from '../../assets/icons/FullLogoIcon';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import PersonalInfoTab from './tabs/personal-info-tab/PersonalInfoTab';
import NavigationTabs from './components/navigation-tabs/NavigationTabs';

import './OnboardingPage.less';
import WorkContractTab from './tabs/work-contact-tab/WorkContractTab';
import WorkPermitTab from './tabs/work-permit-tab/WorkPermitTab';
import BankAccountTab from './tabs/bank-accont-tab/BankAccountTab';
import ChildAllowance from './tabs/child-allowance-tab/ChildAllowance';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  finishedConsultantOnboarding,
  getOnboardingProfilByLeadId,
  saveAsDraftByMoveByConsultantId,
} from '../../redux/reducers/onboarding/actionAsyncThunk';
import ClientContractTab from './tabs/client-contract-tab/ClientContractTab';
import AvsAhvTab from './tabs/avs-ahv/AvsAhvTab';
import AdditionalInfoTab from './tabs/additional-info-tab/AdditionalInfoTab';
import ExitIcon from '../../assets/icons/ExitIcon';
import { consultantNavigationItems } from './components/navigation-tabs/navigationItems';
import OnboardingEditConsultantFooter from '../../components/footer/components/OnboardingEditConsultantFooter';
import {
  clearOnboardingState,
  setIsOpenOnboardingLastStepModal,
} from '../../redux/reducers/onboarding/sliceReducer';
import {
  addClientWorkContractAsEmployee,
  deleteClientWorkContractAsEmployee,
} from '../../redux/reducers/onboarding/tabs/workContractAsyncThunk';
import {
  addNewContactToAdditionalInfo,
  deleteContactInAdditionalInfo,
} from '../../redux/reducers/onboarding/tabs/additionalInfoAsyncThunk';
import Button from '../../components/button/Button';
import { OnboardingRequestStatuses } from '../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import ChangedEmailModal from './components/ChangedEmailModal';

const { Content, Header, Footer } = Layout;
const {
  onboarding,
  personalInformation,
  home,
  workPermit,
  workContract,
  clientContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
  consultants,
  viewPayslipsPage,
  timesheets,
  expenses,
  logs,
  invoices,
} = ROUTER_PATHS;

const OnboardingEditConsultantPage: FC = () => {
  const dispatch = useAppDispatch();
  const { consultantId } = useParams<{ consultantId: string }>();
  const divRef: any = useRef(null);
  const { isFirstRender, validationError, personalInfo, publicId } =
    useAppSelector((state) => state.onboarding);
  const { firstName, lastName } = personalInfo;
  const location = useLocation().pathname;
  const navigation = useNavigate();
  const { tabStatus } = useAppSelector((state) => state.onboarding);

  const { consultantStatus, confirmationUrl } = useAppSelector(
    (state) => state.onboarding,
  );

  const isInactiveFinishedOnboarding =
    tabStatus.every((status) =>
      [
        OnboardingRequestStatuses.published,
        OnboardingRequestStatuses.toConfirm,
      ].includes(status),
    ) && consultantStatus === 'inactive';

  const isOpen = useAppSelector(
    (state) => state.onboarding.isOpenOnboardingLastStepModal,
  );

  useEffect(() => {
    if (divRef?.current) {
      divRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  useEffect(() => {
    checkIsFinalStep();
  }, [consultantStatus, tabStatus]);

  const checkIsFinalStep = () => {
    if (isInactiveFinishedOnboarding) {
      dispatch(setIsOpenOnboardingLastStepModal(true));
    }
  };

  useEffect(() => {
    dispatch(getOnboardingProfilByLeadId(consultantId ?? ''));

    return () => {
      dispatch(clearOnboardingState());
    };
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(saveAsDraftByMoveByConsultantId(consultantId ?? ''));
    }
  }, [location]);

  useEffect(() => {
    const el = document.querySelector('.error-text');
    if (el?.parentElement) {
      el.parentElement.scrollIntoView();
    }
  }, [validationError]);

  const onExit = () => {
    navigation(consultants, { state: { prevLocation: location } });
  };

  return (
    <Layout
      className={`onboarding-page ${
        location.includes(workContract) || location.includes(clientContract)
          ? 'work-contract-tab'
          : ''
      }`}>
      <Header style={{ position: 'relative' }}>
        <FullLogoIcon className="full-logo-icon" />

        <span onClick={() => onExit()} className="exit-button">
          <ExitIcon />
          Exit
        </span>

        <div className="header-info-consultant">
          <span>
            Consultant:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {publicId || ''} {firstName || ''} {lastName || ''}
            </span>
          </span>
          <div>
            {consultantId && (
              <div className="consultant-links">
                <Link
                  to={`${viewPayslipsPage}/${consultantId}`}
                  target="_blank"
                  className="new-tab-link">
                  Payslips
                </Link>
                <span className="vertical-line">|</span>
                <Link
                  to={`${timesheets}?publicId=${publicId}`}
                  target="_blank"
                  className="new-tab-link">
                  Timesheets
                </Link>
                <span className="vertical-line">|</span>
                <Link
                  to={`${expenses}?publicId=${publicId}`}
                  target="_blank"
                  className="new-tab-link">
                  Expenses
                </Link>
                <span className="vertical-line">|</span>
                <Link
                  to={`${invoices}?publicId=${publicId}`}
                  target="_blank"
                  className="new-tab-link">
                  Invoices
                </Link>
                <span className="vertical-line">|</span>
                <Link
                  to={`${logs}/${consultantId}`}
                  target="_blank"
                  className="new-tab-link">
                  History
                </Link>
              </div>
            )}
          </div>
        </div>

        <NavigationTabs
          navigationItems={consultantNavigationItems(consultantId ?? '')}
        />
      </Header>

      <Content ref={divRef}>
        <Routes>
          <Route
            path={home}
            element={
              <Navigate
                to={onboarding + `/${consultantId}` + personalInformation}
              />
            }
          />
          <Route path={personalInformation} element={<PersonalInfoTab />} />
          <Route path={workPermit} element={<WorkPermitTab />} />
          <Route
            path={workContract}
            element={
              <WorkContractTab
                deleteClientWorkContract={() => {
                  dispatch(deleteClientWorkContractAsEmployee());
                }}
                onAddNewClient={() => {
                  dispatch(addClientWorkContractAsEmployee());
                }}
              />
            }
          />
          <Route path={clientContract} element={<ClientContractTab />} />
          <Route path={bankAccount} element={<BankAccountTab />} />
          <Route path={childAllowance} element={<ChildAllowance />} />
          <Route path={avsAhv} element={<AvsAhvTab />} />
          <Route
            path={additionalInformation}
            element={
              <AdditionalInfoTab
                addContact={() => {
                  dispatch(addNewContactToAdditionalInfo());
                }}
                deleteContact={(id: string) => {
                  dispatch(deleteContactInAdditionalInfo(id));
                }}
              />
            }
          />
        </Routes>
      </Content>
      <Footer>
        <OnboardingEditConsultantFooter />
      </Footer>
      {isOpen && (
        <Modal
          width={490}
          open={isOpen}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p>
            You have updated and saved the information. Do you want to finish?
          </p>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() =>
                  dispatch(setIsOpenOnboardingLastStepModal(false))
                }>
                No, stay here
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => dispatch(finishedConsultantOnboarding())}>
                Yes, finish
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
      {confirmationUrl && (
        <ChangedEmailModal isOpen={!!confirmationUrl} url={confirmationUrl} />
      )}
    </Layout>
  );
};

export default OnboardingEditConsultantPage;
