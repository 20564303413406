import { Popover } from 'antd';
import WarningIcon from '../../assets/icons/WarningIcon';
import { TooltipPlacement } from 'antd/es/tooltip';
import './PopoverHelpInfo.less';

interface IProps {
  title: JSX.Element;
  placement?: TooltipPlacement;
}

const PopoverHelpInfo = (props: IProps) => {
  const { title, placement } = props;
  return (
    <Popover
      className="info-pop-button"
      placement={placement || 'rightTop'}
      content={title}
      trigger="hover"
      overlayClassName="info-pop-content"
      showArrow={false}>
      <button>
        <WarningIcon />
      </button>
    </Popover>
  );
};

export default PopoverHelpInfo;
