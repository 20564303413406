import { FC, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import Button from '../../button/Button';
import SaveIcon from '../../../assets/icons/SaveIcon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';

import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import {
  saveAsDraftByConsultantId,
  saveAsPublishByConsultantId,
  saveToConfirmByConsultantId,
} from '../../../redux/reducers/onboarding/actionAsyncThunk';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import {
  setIsShowClientContractModal,
  setOldConsultantEmail,
} from '../../../redux/reducers/onboarding/sliceReducer';
import AttentionModal from '../../attention-modal/AttentionModal';
import { ConfirmChangingClientData } from '../../common-texts/CommonTexts';
import { clientApi } from '../../../common/api/services/ClientApi/clientApi';
import {
  compareAddresses,
  compareClientContacts,
  compareDynamicFields,
} from '../../../common/utils/compareOnBoardingArrays';
import { IClientResponse } from '../../../common/api/services/ClientApi/types/IClientResponse';
import { savePublishClientContractAsEmployee } from '../../../redux/reducers/onboarding/tabs/clientContractAsyncThunk';
import LaterIcon from '../../../assets/icons/LaterIcon';
import ChooseEmailModal from '../../../pages/onboarding-page/components/ChooseEmailModal';
import { getIsHideDraftBtn } from '../../../common/utils/getIsHideDraftBtn';

const {
  personalInformation,
  clientContract: clientContractPath,
  workContract: workContractPath,
  workPermit,
  bankAccount,
  childAllowance,
  avsAhv,
  onboarding,
} = ROUTER_PATHS;

const OnboardingEditConsultantFooter: FC = () => {
  const pathName = useLocation().pathname;
  const { consultantId } = useParams<{ consultantId: string }>();
  const { hasWorkPermit, selfHandledWorkPermit, basisOfLegalStay } =
    useAppSelector((state) => state.onboarding.workPermit);
  const isPublishedBeforeNewClient = useAppSelector(
    (state) => state.onboarding.isPublishedBeforeNewClient,
  );
  const isHasDeletedClient = useAppSelector(
    (state) => state.onboarding.isHasDeletedClient,
  );
  const hasBankAccount = useAppSelector(
    (state) => state.onboarding.bankAccount.hasBankAccount,
  );
  const hasSsn = useAppSelector(
    (state) => state.onboarding.ssnInformation.hasSSN,
  );
  const isSwissNationality = useAppSelector(
    (state) => state.onboarding.personalInfo.nationality === 'Switzerland',
  );
  const { workContract, clientContract, consultantEmail, personalInfo } =
    useAppSelector((state) => state.onboarding);
  const { email } = personalInfo;
  const { companies } = workContract;
  const { clients } = clientContract;

  const [isOpenNewDataModal, setIsOpenNewDataModal] = useState(false);
  const [newClientsList, setNewClientsList] = useState<string[]>([]);
  const [changedFields, setChangedFields] = useState<Array<string[]>>([]);
  const [changedNames, setChangedNames] = useState<string[]>([]);
  const [isOpenChooseEmail, setIsOpenChooseEmail] = useState(false);
  const [requestType, setRequestType] = useState<
    'draft' | 'toConfirm' | 'publish'
  >('draft');

  const { tabStatus, isShowClientContractModal, clientContractModalOptions } =
    useAppSelector((state) => state.onboarding);

  const dispatch = useAppDispatch();

  const { workForm } = personalInfo;
  const isCorporation = workForm === 'corporation';

  const isSkipConditions =
    (pathName.includes(workPermit) &&
      (!isSwissNationality &&
      !hasWorkPermit &&
      !selfHandledWorkPermit &&
      basisOfLegalStay
        ? false
        : !hasWorkPermit)) ||
    (pathName.includes(bankAccount) && !hasBankAccount) ||
    (pathName.includes(avsAhv) && (!hasSsn || isCorporation));

  const isWorkContractPage = pathName.includes(workContractPath);
  const isClientContractPage = pathName.includes(clientContractPath);
  const isPersonalInfoPage = pathName.includes(personalInformation);

  const isHideDraftBtn = getIsHideDraftBtn(pathName, tabStatus);
  const isShowToConfirmBtn = [
    personalInformation,
    workPermit,
    bankAccount,
    childAllowance,
    avsAhv,
  ].some((path) => pathName.includes(path));

  const buttonText = isSkipConditions ? 'Skip and go next' : 'Save and publish';

  const saveAsPublishHandler = () => {
    const companyNames = companies.map((company) => company.name);
    if (
      (isWorkContractPage || isClientContractPage) &&
      companyNames.every((item) => item)
    ) {
      const promises = companyNames.map((companyName) =>
        clientApi.getClients(
          1,
          20,
          { name: companyName },
          { sort: 'asc', sortBy: 'name' },
        ),
      );
      Promise.all(promises).then((responses) => {
        //NOTE: that means that new client name was added
        let resultNames = [];
        if (
          responses.find((resp) => resp?.result.length === 0) &&
          isWorkContractPage
        ) {
          resultNames = companyNames.filter((name) =>
            responses.every(
              (resp) => !resp?.result[0] || resp?.result[0].name !== name,
            ),
          );

          setNewClientsList(resultNames);
        }
        const responseResult = responses.map((response) => {
          const result = comparingContract(response?.result[0]);
          if (result.length > 0) {
            if (response?.result && response?.result.length > 0) {
              //NOTE: which clients have changed fields
              setChangedNames((prevState) => [
                ...prevState,
                response?.result[0].name || '',
              ]);
            }
            setChangedFields((prevState) => [...prevState, result]);
          }
          return result;
        });
        if (
          (responseResult.length > 0 &&
            responseResult.find((item) => item.length > 0)) ||
          resultNames.length > 0
        ) {
          setIsOpenNewDataModal(true);
        } else {
          dispatch(saveAsPublishByConsultantId(consultantId ?? ''));
        }
      });
    } else {
      dispatch(saveAsPublishByConsultantId(consultantId ?? ''));
    }
  };
  //NOTE: which fields was changed
  const comparingContract = (resultClient: IClientResponse | undefined) => {
    const newFields: string[] = [];
    const comparedCompany = companies.find(
      (company) => company.clientId === resultClient?._id,
    );
    const comparedClient = clients.find(
      (client) => client.clientId === resultClient?._id,
    );
    if (
      (!comparedClient && isClientContractPage) ||
      (!comparedCompany && isWorkContractPage)
    ) {
      return [];
    }

    const addToChangedFields = (title: string) => {
      newFields.push(title);
    };

    const comparedEmail =
      comparedCompany &&
      resultClient?.emails.find((data) => data.email === comparedCompany.email);
    const comparedPhone =
      comparedCompany &&
      resultClient?.phones.find((data) => data.phone === comparedCompany.phone);
    // const comparedAddresses =
    //   comparedCompany &&
    //   compareAddresses(
    //     comparedCompany.addresses || [],
    //     resultClient?.addresses || [],
    //   );
    const comparedSignatories =
      comparedClient &&
      compareDynamicFields(
        comparedClient.signatories || [],
        resultClient?.signatories || [],
      );
    const comparedInvoiceries =
      comparedClient &&
      compareDynamicFields(
        comparedClient.invoiceries || [],
        resultClient?.invoiceries || [],
      );
    const comparedReminders =
      comparedClient &&
      compareDynamicFields(
        comparedClient.reminders || [],
        resultClient?.reminders || [],
      );
    const comparedClientContacts =
      comparedClient &&
      compareClientContacts(
        comparedClient.clientContacts || [],
        resultClient?.clientContacts || [],
      );
    const isNoChanges =
      comparedEmail &&
      comparedPhone &&
      // comparedAddresses &&
      comparedSignatories &&
      comparedInvoiceries &&
      comparedReminders &&
      comparedClientContacts;
    //NOTE: compare fields if we change client data
    //TODO: add check if onboarding was published before added new client
    if (!isNoChanges) {
      if (
        isWorkContractPage &&
        (tabStatus[1] === OnboardingRequestStatuses.published ||
          isPublishedBeforeNewClient ||
          isHasDeletedClient)
      ) {
        if (!comparedEmail) {
          addToChangedFields('Email');
        }
        // if (!comparedAddresses) {
        //   addToChangedFields('Address');
        // }
        if (!comparedPhone) {
          addToChangedFields('Phone number');
        }
        return newFields;
      }
      if (
        isClientContractPage &&
        tabStatus[2] === OnboardingRequestStatuses.published
      ) {
        if (!comparedSignatories) {
          addToChangedFields('Signatories');
        }
        if (!comparedInvoiceries) {
          addToChangedFields('Invoiceries');
        }
        if (!comparedReminders) {
          addToChangedFields('Reminders');
        }
        if (!comparedClientContacts) {
          addToChangedFields('Client contact');
        }
        return newFields;
      }
    }
    return [];
  };

  const saveAsDraftHandler = () => {
    dispatch(saveAsDraftByConsultantId(consultantId ?? ''));
  };

  const saveToConfirmHadler = () => {
    dispatch(saveToConfirmByConsultantId(consultantId ?? ''));
  };

  const confirmNewClient = () => {
    dispatch(saveAsPublishByConsultantId(consultantId ?? ''));
    closeAttentionModal();
  };

  const saveToConfirmPreHadler = () => {
    if (isPersonalInfoPage) {
      if (consultantEmail === email) {
        saveToConfirmHadler();
        return;
      }
      setRequestType('toConfirm');
      setIsOpenChooseEmail(true);
      return;
    }
    saveToConfirmHadler();
  };

  const saveDraftPreHadler = () => {
    if (isPersonalInfoPage) {
      if (consultantEmail === email) {
        saveAsDraftHandler();
        return;
      }
      setRequestType('draft');
      setIsOpenChooseEmail(true);
      return;
    }
    saveAsDraftHandler();
  };

  const savePublishPreHadler = () => {
    if (isPersonalInfoPage) {
      if (consultantEmail === email) {
        saveAsPublishHandler();
        return;
      }
      setRequestType('publish');
      setIsOpenChooseEmail(true);
      return;
    }
    saveAsPublishHandler();
  };

  const setRequest = () => {
    setIsOpenChooseEmail(false);
    dispatch(setOldConsultantEmail(consultantEmail));
    if (requestType === 'draft') {
      saveAsDraftHandler();
      return;
    }
    if (requestType === 'toConfirm') {
      saveToConfirmHadler();
      return;
    }
    saveAsPublishHandler();
  };

  const closeAttentionModal = () => {
    setChangedFields([]);
    setChangedNames([]);
    setNewClientsList([]);
    setIsOpenNewDataModal(false);
  };

  const closeModal = () => {
    setIsOpenChooseEmail(false);
  };
  return (
    <Row align="middle" className="onboarding-footer" justify="end">
      <Col span={24}>
        <Row gutter={[16, 0]} justify="end">
          {isShowToConfirmBtn && (
            <Col>
              <Button
                buttonStyle="secondary"
                buttonSize="normal"
                leftIcon={<LaterIcon />}
                onClick={saveToConfirmPreHadler}>
                Confirm later
              </Button>
            </Col>
          )}
          {!isSkipConditions && isHideDraftBtn && (
            <Col>
              <Button
                buttonStyle="secondary"
                buttonSize="normal"
                leftIcon={<SaveIcon />}
                onClick={saveDraftPreHadler}>
                Save as Draft
              </Button>
            </Col>
          )}
          <Col>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              rightIcon={<ArrowIcon />}
              onClick={savePublishPreHadler}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
      {isShowClientContractModal && (
        <Modal
          width={clientContractModalOptions.length > 2 ? 600 : 520}
          open={isShowClientContractModal}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p>
            The values are different from what was saved before in client
            database. Are you sure you want to change it?
          </p>

          <div className="client-contract-modal-window">
            {clientContractModalOptions.map((client, idx) => {
              return (
                <div key={idx} className="client-contract-modal-window-item">
                  <h4>{client.name}</h4>
                  <h5>Before: </h5>
                  <span>Payment term: {client.before.paymentTerm}</span>
                  <span>
                    № of days for payment terms:{' '}
                    {client.before.paymentTermRange}
                  </span>
                  <h5>After: </h5>
                  <span>Payment term: {client.after.paymentTerm}</span>
                  <span>
                    № of days for payment terms: {client.after.paymentTermRange}
                  </span>
                </div>
              );
            })}
          </div>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() => {
                  dispatch(setIsShowClientContractModal(false));
                }}>
                Cancel
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => {
                  dispatch(
                    savePublishClientContractAsEmployee(
                      onboarding + `/${consultantId}`,
                    ),
                  );
                  dispatch(setIsShowClientContractModal(false));
                }}>
                Yes, change
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
      {isOpenNewDataModal && (
        <AttentionModal
          cancelBtnTitle="Return"
          closeModal={closeAttentionModal}
          confirmAction={confirmNewClient}
          confirmBtnTitle="Yes, proceed"
          content={
            <ConfirmChangingClientData
              newClientsList={newClientsList}
              names={changedNames}
              fields={changedFields}
            />
          }
          isOpen={isOpenNewDataModal}
        />
      )}
      {isOpenChooseEmail && (
        <ChooseEmailModal
          isOpen={isOpenChooseEmail}
          confirmAction={setRequest}
          closeModal={closeModal}
        />
      )}
    </Row>
  );
};

export default OnboardingEditConsultantFooter;
