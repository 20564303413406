import { FC } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
import { transformRoutToBreadcrumbItem } from '../../common/utils/transformRoutToBreadcrumbItem';
import { ROUTER_PATHS } from '../../navigation/routerPaths';

import './Breadcrumb.less';
import { useAppSelector } from '../../redux/store';

const {
  employees,
  consultants,
  waitingRoom,
  clients,
  employeePayslips,
  consultantPayslips,
  activityReportInfo,
  generateInvoice,
  monitoring,
} = ROUTER_PATHS;

const Breadcrumb: FC = () => {
  const { pathname } = useLocation();
  const { lastName, firstName } = useAppSelector(
    (state) => state.waitingRoom.selectedLead.leadDetails,
  );
  const clientsList = useAppSelector(
    (state) => state.clients.clientList.content,
  );
  const isWaitingRoom = pathname.includes(waitingRoom);
  const isClient = pathname.includes(clients);
  const pathNameArray = pathname.split('/').filter((item) => item !== '');
  const isEmployeePayslips = pathname.includes(employeePayslips);
  const isConsultantPaylips = pathname.includes(consultantPayslips);

  const isPeopleElementBreadcrumb =
    pathname.includes(employees) ||
    pathname.includes(consultants) ||
    isClient ||
    isWaitingRoom;

  return (
    <AntBreadcrumb className="breadcrumb">
      <AntBreadcrumb.Item>Home</AntBreadcrumb.Item>
      {isPeopleElementBreadcrumb && (
        <AntBreadcrumb.Item>People</AntBreadcrumb.Item>
      )}

      {pathNameArray.map((path, index) => {
        const newPath = transformRoutToBreadcrumbItem(path);

        if (path.includes('avs-ahv')) {
          return <AntBreadcrumb.Item key={newPath}>Pension</AntBreadcrumb.Item>;
        }

        // Waiting room | Edit lead page
        if (
          isWaitingRoom &&
          pathNameArray.length - 1 === index &&
          pathNameArray.length > 1
        ) {
          return (
            <AntBreadcrumb.Item key={newPath}>
              Profile of {firstName} {lastName}
            </AntBreadcrumb.Item>
          );
        }
        if (pathname.includes(activityReportInfo)) {
          return (
            <AntBreadcrumb.Item key={newPath}>
              Activity report / Invoicing page
            </AntBreadcrumb.Item>
          );
        }
        if (pathname.includes(monitoring)) {
          return (
            <AntBreadcrumb.Item key={newPath}>Admin Report</AntBreadcrumb.Item>
          );
        }

        // Clients | Edit Client
        if (
          isClient &&
          pathNameArray.length - 1 === index &&
          pathNameArray.length > 2 &&
          pathNameArray.length > 1
        ) {
          return (
            <AntBreadcrumb.Item key={newPath}>
              {clientsList.find((client) => client._id === path)?.name ?? ''}
            </AntBreadcrumb.Item>
          );
        }
        if (isEmployeePayslips || isConsultantPaylips) {
          return (
            <AntBreadcrumb.Item key={newPath}>Payslips</AntBreadcrumb.Item>
          );
        }
        return <AntBreadcrumb.Item key={newPath}>{newPath}</AntBreadcrumb.Item>;
      })}
    </AntBreadcrumb>
  );
};

export default Breadcrumb;
