import './Dropdown.less';
import AngleIcon from '../../assets/icons/AngleIcon';
import { Select, Spin } from 'antd';
import { FC } from 'react';
import CrossIcon from '../../assets/icons/CrossIcon';
import ILabelInValue from '../../common/types/ILabelInValue';

const { Option } = Select;

interface IProps {
  allowClear?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: string[] | { label: string; value: string; info?: any }[] | any;
  value?: string | ILabelInValue | null | undefined;
  searchValue?: string;
  onBlur?: () => void | undefined;
  onFocus?: () => void | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDropdownVisibleChange?: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch?: (e: any) => void | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (e: any) => void;
  onClear?: () => void;
  errorMessage?: string;
  label: string;
  disabled?: boolean;
  isSearch?: boolean;
  labelInValue?: boolean;
  isFetching?: boolean;
  showArrow?: boolean;
  showAsterisk?: boolean;
  showSearch?: boolean;
  customOption?: JSX.Element[] | null;
  hideCursorBlink?: boolean;
  placeholder?: string;
  customClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterOption = (input: string, option: any): boolean => {
  return ((option?.value ?? '') as string)
    .toLowerCase()
    .includes(input.toLowerCase());
};

const Dropdown: FC<IProps> = ({
  value,
  searchValue,
  options,
  onBlur,
  onFocus,
  onChange,
  onSearch = undefined,
  onClear = () => null,
  errorMessage,
  label,
  disabled,
  isSearch,
  labelInValue = false,
  isFetching = false,
  allowClear = false,
  showSearch = true,
  showAsterisk = false,
  placeholder,
  customClassName = '',
  showArrow = true,
  onDropdownVisibleChange,
  onClick,
  customOption = null,
  onSelect,
  hideCursorBlink,
}) => {
  const classNameLabel = !!errorMessage
    ? 'dropdown-label dropdown-label-error'
    : 'dropdown-label';
  const className = !!errorMessage ? 'dropdown dropdown-error' : 'dropdown';
  const classNameDisable = disabled
    ? `${classNameLabel} __disable`
    : classNameLabel;
  const disableSearchBlink = hideCursorBlink ? ' hide-blink-cursor' : '';

  return (
    <div className={classNameDisable}>
      <span className="label">
        {label}
        {showAsterisk && <span className="asterisk">*</span>}
      </span>
      {onChange ? (
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          showArrow={showArrow}
          labelInValue={labelInValue}
          disabled={disabled}
          className={className + disableSearchBlink + ` ${customClassName}`}
          suffixIcon={<AngleIcon />}
          value={value}
          searchValue={searchValue}
          bordered={false}
          onSelect={onSelect}
          onClear={onClear}
          placeholder={placeholder}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onChange={onChange}
          onSearch={onSearch}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          popupClassName="dropdown-body"
          filterOption={isSearch ? filterOption : false}
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          getPopupContainer={(trigger) => trigger.parentNode}>
          {customOption
            ? customOption
            : options
                .filter((o: any) => !!o)
                .map((option: any) =>
                  typeof option === 'string' ? (
                    <Option value={option} key={option}>
                      {option[0].toUpperCase() + option.slice(1)}
                    </Option>
                  ) : (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ),
                )}
        </Select>
      ) : (
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          showArrow={showArrow}
          labelInValue={labelInValue}
          disabled={disabled}
          className={className + disableSearchBlink + ` ${customClassName}`}
          suffixIcon={<AngleIcon />}
          value={value}
          searchValue={searchValue}
          bordered={false}
          onSelect={onSelect}
          onClear={onClear}
          placeholder={placeholder}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onSearch={onSearch}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          popupClassName="dropdown-body"
          filterOption={isSearch ? filterOption : false}
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          getPopupContainer={(trigger) => trigger.parentNode}>
          {customOption
            ? customOption
            : options
                .filter((o: any) => !!o)
                .map((option: any) =>
                  typeof option === 'string' ? (
                    <Option value={option} key={option}>
                      {option[0].toUpperCase() + option.slice(1)}
                    </Option>
                  ) : (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ),
                )}
        </Select>
      )}
      {!!errorMessage && (
        <span className="error-text">
          <div className="icon-wrapper">
            <CrossIcon />
          </div>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Dropdown;
