import { Key, useEffect, useState } from 'react';
import { mainActivitiesApi } from '../../common/api/services/MainActivitiesApi/mainActivitiesApi';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ISort, TSortValue } from '../../redux/types';
import {
  changeMainActivitiesFiltering,
  clearMainActivitiesFiltersAndSorts,
  selectMainActivityItem,
  setMainActivitiesList,
  setMainActivitiesPagination,
  setMainActivitiesSorting,
} from '../../redux/reducers/main-activities/sliceReducer';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import { Modal, Spin, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import {
  MainActivitiesType,
  mainActivitiesTypes,
} from '../../common/api/services/MainActivitiesApi/types/types';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import CommonTable from '../../components/common-table/CommonTable';
import {
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import TableTooltip from '../../components/common-table/table-tooltip';
import { timesheetsStatusesHovers } from '../../common/constants/tableStatusesHovers';
import {
  TTimesheetStatusParserKey,
  mainActivitiesStatusParser,
  timesheetStatusParser,
} from '../../common/constants/timesheetStatusParser';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import EditNoteModal from '../../components/edit-note-modal/EditNoteModal';
import EditIcon from '../../assets/icons/EditIcon';
import { ISelectedNode } from '../monitoring-page/components/NodeEdit';
import './MainActivitiesPage.less';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import {
  ConfirmApprove,
  DeleteTimesheetText,
  GenerateTimesheetsText,
} from '../../components/common-texts/CommonTexts';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import ActionMenu from './components/ActionMenu';
import { expensesApi } from '../../common/api/services/ExpensesApi/expensesApi';
import { timesheetApi } from '../../common/api/services/TimesheetApi/timesheetApi';
import { consultantApi } from '../../common/api/services/ConsultantApi/consultantApi';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { IConsultantTimesheet } from '../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import {
  MainActivityArray,
  TimesheetStatus,
} from '../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { IConsultantExpenses } from '../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import ExpensesComponent from './components/ExpensesComponent';
import TimesheetsComponent from './components/TimesheetsComponent';
import Button from '../../components/button/Button';
import AddMainActivityModal from './components/AddMainActivity';
import {
  clearSelectedTimesheet,
  setErrorMessages,
  updateSelectedTimesheet,
} from '../../redux/reducers/timesheets/sliceReducer';
import {
  updateSelectedExpense,
  setErrorMessages as setErrorMessagesExpence,
  clearSelectedExpense,
} from '../../redux/reducers/expenses/sliceReducer';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

const MainActivitiesPage = () => {
  const dispatch: any = useAppDispatch();
  const urlParams = new URLSearchParams(location.search);
  const mainActivityId = urlParams.get('mainActivityId');
  const type = urlParams.get('type');
  const { mainActivitiesList, selectedMainActivities } = useAppSelector(
    (state) => state.mainActivities,
  );
  const { pagination, sort, filter } = mainActivitiesList;
  const { currentPage, perPage, totalElement } = pagination;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState<ISelectedNode | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenGenerateTimesheetsModal, setIsOpenGenerateTimesheetsModal] =
    useState(false);
  const [generateDate, setGenerateDate] = useState<{
    month: number;
    year: number;
  } | null>(null);
  const [perMonthOnly, setPerMonthOnly] = useState(false);
  const [selectedMainActivity, setSelectedMainActivity] = useState<any | null>(
    null,
  );
  const [modalType, setModalType] = useState<
    'close' | '' | 'delete' | 'view' | 'edit' | 'add'
  >('');
  const [mainActivities, setMainActivities] = useState([]);
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  useEffect(() => {
    getMainActivities();
  }, [currentPage, perPage, filter]);

  useEffect(() => {
    if (mainActivityId && type) {
      onDoubleClick({ _id: mainActivityId, kind: type });
    }
  }, []);

  const getMainActivities = (currentSort?: ISort, currentFilter?: any) => {
    setIsLoading(true);
    mainActivitiesApi
      .getMainActivities(
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      .then((response: any) => {
        dispatch(setMainActivitiesList(response));
        setMainActivities(response.result);
      })
      .catch((e) => openErrorNotification('Failed to get main activities'))
      .finally(() => setIsLoading(false));
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectMainActivityItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedMainActivities,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeMainActivitiesFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setMainActivitiesSorting(newSort));
      getMainActivities({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setMainActivitiesPagination({ perPage, currentPage }));
  };

  const onHideNote = () => {
    setSelectedNote(null);
  };

  const onChangeNotes = (id: string, notes: string) => {
    if (!id) {
      return;
    }
    mainActivitiesApi
      .addNotes(id, notes)
      .then(() => {
        openSuccessNotification('Note was added successfully');
        getMainActivities();
      })
      .catch((e) => {
        openErrorNotification('Failed to add note');
      });
  };

  const closeAction = () => {
    setModalType('');
    setSelectedMainActivity(null);
    setIsOpenConfirmModal(false);
    dispatch(setErrorMessages(null));
    dispatch(setErrorMessagesExpence(null));
    dispatch(clearSelectedTimesheet());
    dispatch(clearSelectedExpense());
  };

  const deleteMainActivity = () => {
    if (!selectedMainActivity) {
      return;
    }
    selectedMainActivity.kind === 'T'
      ? timesheetApi
          .deleteTimesheet(selectedMainActivity._id)
          .then(() => {
            openSuccessNotification('Timesheet was deleted successfully');
            getMainActivities();
          })
          .catch((e) => {
            openErrorNotification('Failed to delete');
          })
          .finally(() => {
            closeAction();
          })
      : expensesApi
          .deleteExpense(selectedMainActivity._id)
          .then(() => {
            openSuccessNotification('Expense was deleted successfully');
            getMainActivities();
          })
          .catch((e) => {
            openErrorNotification('Failed to delete');
          })
          .finally(() => {
            closeAction();
          });
  };

  const expireExpence = (mainActivity: any) => {
    consultantApi
      .putInternalExpense(mainActivity._id, mainActivity.id, {
        status: ExpensesStatus.EXPIRED,
      })
      .then(() => {
        openSuccessNotification('Expense was expired successfully');
        getMainActivities();
      })
      .catch((e) => {
        openErrorNotification('Failed to expire');
      })
      .finally(() => {
        closeAction();
      });
  };

  const approveTimesheet = (timesheet: IConsultantTimesheet) => {
    const selectedTimesheet = timesheet;
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const body: any = {
      ...selectedTimesheet,
      status: TimesheetStatus.APPROVED,
    };

    if (selectedTimesheet.status === TimesheetStatus.WAITING_FOR_APPROVAL) {
      body.status = TimesheetStatus.CLIENT_APPROVAL;
    }

    if (selectedTimesheet?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }
    consultantApi
      .putInternalTimesheet(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        timesheetId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        consultantId!,
        body,
      )
      .then(() => {
        openSuccessNotification('Timesheet was approved successfully');
        setIsOpenConfirmModal(false);
      })
      .catch((e) => {
        openErrorNotification('Failed to approve timesheet');
      })
      .finally(() => getMainActivities());
  };

  const approveExpence = (expence: IConsultantExpenses) => {
    const selectedExpense = expence;
    const { _id: expenseId, consultantId } = selectedExpense;

    const body: any = {
      ...selectedExpense,
      status: ExpensesStatus.APPROVED,
    };

    if (selectedExpense.status === ExpensesStatus.WAITING_FOR_APPROVAL) {
      body.status =
        selectedExpense.type === ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT
          ? ExpensesStatus.CLIENT_APPROVAL
          : ExpensesStatus.APPROVED;
    }

    if (selectedExpense?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }
    consultantApi
      .putInternalExpense(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        expenseId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        consultantId!,
        body,
      )
      .then(() => {
        openSuccessNotification('Expense was approved successfully');
        setIsOpenConfirmModal(false);
      })
      .catch((e) => {
        openErrorNotification('Failed to approve expense');
      })
      .finally(() => getMainActivities());
  };

  const rejectTimesheet = (timesheet: IConsultantTimesheet) => {
    const selectedTimesheet = timesheet;
    const { _id: timesheetId, consultantId } = selectedTimesheet;

    const body: any = {
      ...selectedTimesheet,
      status: TimesheetStatus.REJECTED,
    };

    if (selectedTimesheet?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }
    consultantApi
      .putInternalTimesheet(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        timesheetId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        consultantId!,
        body,
      )
      .then(() => {
        openSuccessNotification('Timesheet was rejected successfully');
      })
      .catch((e) => {
        openErrorNotification('Failed to reject timesheet');
      })
      .finally(() => getMainActivities());
  };

  const rejectExpense = (expence: IConsultantExpenses) => {
    const selectedExpense = expence;
    const { _id: expenseId, consultantId } = selectedExpense;

    const body: any = {
      ...selectedExpense,
      status: ExpensesStatus.REJECTED,
    };

    if (selectedExpense?.isSigned) {
      delete body.companyRepresentativeEmail;
      delete body.representativeFirstName;
      delete body.representativeLastName;
    }
    consultantApi
      .putInternalExpense(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        expenseId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        consultantId!,
        body,
      )
      .then(() => {
        openSuccessNotification('Expense was rejected successfully');
      })
      .catch((e) => {
        openErrorNotification('Failed to reject expense');
      })
      .finally(() => getMainActivities());
  };

  const validateApprove = (mainActivity: any) => {
    if (!mainActivity) {
      return;
    }
    mainActivity.kind === 'T'
      ? timesheetApi
          .fetchTimesheetById(mainActivity._id)
          .then((resp: any) => {
            if (resp.hasPublishedPayslip) {
              setIsOpenConfirmModal(true);
              return;
            }
            approveTimesheet(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to approve timesheet');
          })
      : expensesApi
          .fetchExpenseById(mainActivity._id)
          .then((resp: any) => {
            if (resp.hasPublishedPayslip) {
              setIsOpenConfirmModal(true);
              return;
            }
            approveExpence(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to approve expense');
          });
  };

  const onApprove = () => {
    if (!selectedMainActivity) {
      return;
    }
    selectedMainActivity.kind === 'T'
      ? timesheetApi
          .fetchTimesheetById(selectedMainActivity._id)
          .then((resp: any) => {
            approveTimesheet(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to approve timesheet');
          })
      : expensesApi
          .fetchExpenseById(selectedMainActivity._id)
          .then((resp: any) => {
            approveExpence(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to approve expense');
          });
  };

  const onReject = (mainActivity: any) => {
    if (!mainActivity) {
      return;
    }
    mainActivity.kind === 'T'
      ? timesheetApi
          .fetchTimesheetById(mainActivity._id)
          .then((resp: any) => {
            rejectTimesheet(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to reject timesheet');
          })
      : expensesApi
          .fetchExpenseById(mainActivity._id)
          .then((resp: any) => {
            rejectExpense(resp);
          })
          .catch((e) => {
            openErrorNotification('Failed to approve expense');
          });
  };

  const generateTimesheets = () => {
    const year = generateDate?.year || moment().year();
    const month = generateDate?.month || moment().month() + 1;
    timesheetApi
      .generateTimesheets(year, month, perMonthOnly)
      .then(() => {
        setIsOpenGenerateTimesheetsModal(false);
        openSuccessNotification('Timesheets were generated successfully');
        getMainActivities();
        setPerMonthOnly(false);
      })
      .catch((error) => {
        openErrorNotification(
          error?.response?.data?.message || 'Failed to generate timesheets',
        );
      });
  };

  const data = mainActivities.map((item) => {
    const {
      consultant,
      publicId,
      clientName,
      projectInfo,
      period,
      kind,
      numeriqComments,
      projectDescription,
      submissionDate,
      clientApprovalDate,
      status,
      notes,
      _id,
    } = item;
    const { firstName, lastName, publicId: consultantId, _id: id } = consultant;
    return {
      publicId,
      consultantId,
      ['consultant.firstName']: firstName,
      ['consultant.lastName']: lastName,
      clientName,
      projectDescription,
      period: period ? moment(period).format(commonMonthYearSpaceFormat) : '',
      kind,
      numeriqComments,
      submissionDate: submissionDate
        ? moment(submissionDate).format(commonMonthTimeFormat)
        : '',
      clientApprovalDate: clientApprovalDate
        ? moment(clientApprovalDate).format(commonMonthTimeFormat)
        : '',
      status,
      _id,
      notes,
      id,
    };
  });
  const columns: ColumnsType<MainActivitiesType> = [
    {
      title: 'Consultant ID',
      dataIndex: 'consultantId',
      key: 'consultantId',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.consultantId as string) ?? '',
        searchAsString: false,
      })('consultantId'),
      className: sortClassName('consultantId'),
      sorter: true,
      width: 130,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      fixed: 'left',
    },
    {
      title: 'Consultant first name',
      dataIndex: 'consultant.firstName',
      key: 'consultant.firstName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.['consultant.firstName'] as string) ?? '',
      })('consultant.firstName'),
      className: sortClassName('consultant.firstName'),
      sorter: true,
      width: 170,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      fixed: 'left',
      render: (text) => {
        return (
          <TableTooltip tootltipTitle={text} columnWidth={170}>
            {text}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Consultant last name',
      dataIndex: 'consultant.lastName',
      key: 'consultant.lastName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.['consultant.lastName'] as string) ?? '',
      })('consultant.lastName'),
      className: sortClassName('consultant.lastName'),
      sorter: true,
      width: 170,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      fixed: 'left',
      render: (text) => {
        return (
          <TableTooltip tootltipTitle={text} columnWidth={170}>
            {text}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
        searchAsString: false,
      })('clientName'),
      className: sortClassName('clientName'),
      sorter: true,
      width: 170,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text) => {
        return (
          <TableTooltip tootltipTitle={text} columnWidth={170}>
            {text}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
        searchAsString: false,
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      width: 170,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text) => {
        return (
          <TableTooltip tootltipTitle={text} columnWidth={170}>
            {text}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'period',
        placeholder: 'Period',
        defaultValue: (filter?.period as string) ?? '',
      })(),
      className: sortClassName('period'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'kind',
      key: 'kind',
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.kind as string[]) ?? '',
        fieldFilterName: 'kind',
      })(mainActivitiesTypes),
      className: sortClassName('kind'),
      sorter: true,
      width: 100,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Comments',
      dataIndex: 'numeriqComments',
      key: 'numeriqComments',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.numeriqComments as string) ?? '',
        searchAsString: false,
      })('numeriqComments'),
      className: sortClassName('numeriqComments'),
      sorter: true,
      width: 170,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text) => {
        return (
          <TableTooltip tootltipTitle={text} columnWidth={170}>
            {text}
          </TableTooltip>
        );
      },
    },
    {
      title: 'Submission Date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'submissionDate',
        values: (filter?.submissionDate as string) ?? '',
      })(),
      className: sortClassName('submissionDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Client approval Date',
      dataIndex: 'clientApprovalDate',
      key: 'clientApprovalDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'clientApprovalDate',
        values: (filter?.clientApprovalDate as string) ?? '',
      })(),
      className: sortClassName('clientApprovalDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.status as unknown as string[]) ?? [],
        fieldFilterName: 'status',
      })(MainActivityArray),
      className: `status-td ${sortClassName('status')}`,
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <TableTooltip
            placement="topLeft"
            tootltipTitle={timesheetsStatusesHovers[text]}
            columnWidth={0}>
            <div className="status-wrapper">
              <span className={mainActivitiesStatusParser[text]}>{text}</span>
            </div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.notes as string) ?? '',
      })('notes'),
      className: sortClassName('notes'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
      render: (notes, item: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableTooltip tootltipTitle={notes} columnWidth={180}>
              <div className="cut-text" style={{ width: '130px' }}>
                {notes}
              </div>
            </TableTooltip>
            <EditIcon
              style={{ position: 'absolute', right: '10px' }}
              onClick={() => {
                setSelectedNote({
                  clientId: item._id,
                  consultantId: item._id,
                  name: `${item['consultant.firstName']} ${item['consultant.lastName']}`,
                  notesValue: item.notes,
                  clientFullName: item?.clientName || '',
                  id: item._id,
                  publicId: item.publicId,
                  period: moment(item.period).format(
                    commonMonthYearSpaceFormat,
                  ),
                  type: item.kind === 'T' ? 'Timesheet' : 'Expenses',
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'edit-action-td',
      render: (_, item: any) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionMenu
                  mainActivity={item}
                  setModalType={setModalType}
                  setSelectedMainActivity={setSelectedMainActivity}
                  status={item.status}
                  expireExpence={expireExpence}
                  onApprove={validateApprove}
                  onReject={onReject}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
      fixed: 'right',
      width: 80,
    },
  ];

  const onDoubleClick = (record: any) => {
    if (record.kind === 'T') {
      timesheetApi
        .fetchTimesheetById(record._id)
        .then((resp: any) => {
          setSelectedMainActivity(resp);
          dispatch(updateSelectedTimesheet(resp));
          if (
            [
              TimesheetStatus.DRAFT,
              TimesheetStatus.NO_ACTIVITY,
              TimesheetStatus.EXPIRED,
              TimesheetStatus.REJECTED,
            ].includes(resp.status)
          ) {
            setModalType('edit');
            return;
          }
          setModalType('view');
        })
        .catch((e) => {
          openErrorNotification('Timesheet not found');
        });
    } else {
      expensesApi
        .fetchExpenseById(record._id)
        .then((resp: any) => {
          setSelectedMainActivity(resp);
          dispatch(updateSelectedExpense(resp));
          if (
            resp.status === ExpensesStatus.DRAFT ||
            resp.status === ExpensesStatus.REJECTED ||
            resp.status === ExpensesStatus.NO_EXPENSES
          ) {
            setModalType('edit');
            return;
          }
          setModalType('view');
        })
        .catch((e) => {
          openErrorNotification('Expence not found');
        });
    }
  };

  const onChangePerMonthOnly = (value: any) => {
    setPerMonthOnly(value.target.checked);
  };
  return (
    <div style={{ height: '90%' }}>
      <div
        className="header-section main-activities-header"
        style={{ margin: 0 }}>
        <div className="header-section">
          <Breadcrumb />
          <div className="right-header-section">
            {(isHasFilters || selectedMainActivities.length > 0) && (
              <button
                className="rest-filters-button"
                onClick={() => {
                  onSelectChange([]);
                  dispatch(clearMainActivitiesFiltersAndSorts());
                  getMainActivities({ sort: '', sortBy: '' }, []);
                }}>
                <ResetFilterIcon />
              </button>
            )}
            <div style={{ marginRight: '10px' }}>
              <Button
                buttonStyle="primary"
                buttonSize="medium"
                buttonWidth={183}
                onClick={() => setModalType('add')}>
                + Add new
              </Button>
            </div>
            <Button
              onClick={() => {
                setIsOpenGenerateTimesheetsModal(true);
              }}
              buttonStyle="secondary"
              buttonSize="medium"
              buttonWidth={180}>
              Generate documents
            </Button>
          </div>
        </div>
      </div>
      <div className="container-payslips">
        <Spin spinning={isLoading}>
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            rowSelection={rowSelection}
            onChange={onChangeSort}
            onDoubleClick={onDoubleClick}
            rowKey="_id"
          />
        </Spin>
      </div>
      {!!selectedNote && (
        <EditNoteModal
          isOpen={!!selectedNote}
          onCancel={onHideNote}
          onChangeNotes={onChangeNotes}
          selectedNote={selectedNote}
          isMainActivity={true}
        />
      )}
      {modalType === 'delete' && selectedMainActivity && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeAction}
          confirmAction={deleteMainActivity}
          confirmBtnTitle="Yes, delete"
          content={
            <DeleteTimesheetText
              firstName={selectedMainActivity['consultant.firstName']}
              lastName={selectedMainActivity['consultant.lastName']}
              publicId={selectedMainActivity.publicId}
              type={
                selectedMainActivity.kind === 'T' ? 'Timesheet' : 'Expenses'
              }
            />
          }
          isOpen={modalType === 'delete' && !!selectedMainActivity}
          isHideAttentionText
          width={550}
          btnWidth={'230px'}
        />
      )}
      {selectedMainActivity && isOpenConfirmModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeAction}
          confirmAction={onApprove}
          confirmBtnTitle="Confirm"
          content={<ConfirmApprove mainActivity={selectedMainActivity} />}
          isOpen={selectedMainActivity && isOpenConfirmModal}
          hideCloseBtn
        />
      )}
      <ExpensesComponent
        closeAction={closeAction}
        modalType={modalType}
        selectedMainActivity={selectedMainActivity}
        getMainActivities={getMainActivities}
      />
      <TimesheetsComponent
        closeAction={closeAction}
        modalType={modalType}
        selectedMainActivity={selectedMainActivity}
        getMainActivities={getMainActivities}
      />
      {modalType === 'add' && (
        <Modal
          width={640}
          title="Add new main activity"
          open={modalType === 'add'}
          onCancel={closeAction}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-timesheet">
          <AddMainActivityModal
            handleCancel={closeAction}
            getMainActivities={getMainActivities}
          />
        </Modal>
      )}
      {isOpenGenerateTimesheetsModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={() => setIsOpenGenerateTimesheetsModal(false)}
          confirmAction={() => generateTimesheets()}
          confirmBtnTitle="Yes, generate"
          content={
            <GenerateTimesheetsText
              onChangeDate={setGenerateDate}
              perMonthOnly={perMonthOnly}
              onChangePerMonthOnly={onChangePerMonthOnly}
            />
          }
          isOpen={isOpenGenerateTimesheetsModal}
          isHideAttentionText
          width={580}
          btnWidth={'250px'}
        />
      )}
    </div>
  );
};

export default MainActivitiesPage;
