import React from 'react';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';
import moment from '../../../../common/constants/moment';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

interface IProps {
  filter: any;
  onChangeFilter: (value: string, key: string) => void;
}

const PeriodFilter = (props: IProps) => {
  const { filter, onChangeFilter } = props;

  const createdBetween = filter?.defaultFilter
    ? filter?.defaultFilter.split('|')
    : null;
  const from = createdBetween
    ? moment(createdBetween[0], commonYearDateFormat)
    : null;
  const to = createdBetween
    ? moment(createdBetween[1], commonYearDateFormat)
    : null;

  return (
    <div className="icon-wrapper">
      <h4>{filter.title}</h4>
      <RangePicker
        defaultValue={[from, to]}
        onChange={(dates) => {
          if (dates && dates.length === 2 && dates[0] && dates[1]) {
            onChangeFilter(
              `${dates[0].format(commonYearDateFormat)}|${dates[1].format(
                commonYearDateFormat,
              )}`,
              filter.dataIndex,
            );
          }
        }}
        className="mobile-data"
        placeholder={['From', 'To']}
        picker="month"
      />
    </div>
  );
};

export default PeriodFilter;
