import { Modal } from 'antd';
import CopyLinkSection from '../../../components/copy-link-section/CopyLinkSection';
import './ChangedEmailModal.less';
import { useAppSelector } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import {
  setConfirmationUrl,
  setUseNewEmail,
} from '../../../redux/reducers/onboarding/sliceReducer';

interface IProps {
  isOpen: boolean;
  url: string;
}

const ChangedEmailModal = (props: IProps) => {
  const { isOpen, url } = props;
  const dispatch = useDispatch();
  const { consultantEmail, useNewEmail, oldConsultantEmail } = useAppSelector(
    (state) => state.onboarding,
  );

  const closeModal = () => {
    dispatch(setConfirmationUrl(''));
    dispatch(setUseNewEmail(null));
  };

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      width={500}
      destroyOnClose={true}
      onCancel={closeModal}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div>
        <h4>Invitation link</h4>
        <div style={{ margin: '1.5rem 0' }}>
          <span>
            The invitation link was successfully sent to{' '}
            <b>{useNewEmail ? consultantEmail : oldConsultantEmail}</b>.
            <br />
            You can also copy the link bellow.
          </span>
        </div>
        <CopyLinkSection link={url} />
      </div>
    </Modal>
  );
};

export default ChangedEmailModal;
