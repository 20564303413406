import { FC, useEffect } from 'react';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { Routes, Route, Navigate } from 'react-router-dom';
import './MyInformationPage.less';
import PersonalInfoTab from './tabs/PersonalInfoTab';
import WorkPermitTab from './tabs/WorkPermitTab';
import WorkContractTab from './tabs/WorkContractTab';
import ClientContractTab from './tabs/ClientContractTab';
import BankAccountTab from './tabs/BankAccountTab';
import ChildAllowance from './tabs/ChildAllowance';
import AvsAhvTab from './tabs/AvsAhvTab';
import AdditionalInfoTab from './tabs/AdditionalInfoTab';
import { getOnboardingProfile } from '../../redux/reducers/onboarding/actionAsyncThunk';
import { useAppDispatch } from '../../redux/store';
import HeaderNavigation from './components/HeaderNavigation';

const MyInformationPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOnboardingProfile());
  }, []);

  return (
    <div className="my-information-page">
      <HeaderNavigation />

      <Routes>
        <Route
          path={home}
          element={<Navigate to={myInformation + personalInformation} />}
        />
        <Route path={personalInformation} element={<PersonalInfoTab />} />
        <Route path={workPermit} element={<WorkPermitTab />} />
        <Route path={workContract} element={<WorkContractTab />} />
        <Route path={clientContract} element={<ClientContractTab />} />
        <Route path={bankAccount} element={<BankAccountTab />} />
        <Route path={childAllowance} element={<ChildAllowance />} />
        <Route path={avsAhv} element={<AvsAhvTab />} />
        <Route path={additionalInformation} element={<AdditionalInfoTab />} />
      </Routes>
    </div>
  );
};

export default MyInformationPage;

const {
  myInformation,
  personalInformation,
  workPermit,
  workContract,
  clientContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
  home,
} = ROUTER_PATHS;
