import { FC, useEffect, Key } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Spin, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import {
  changeReportingFiltering,
  selectReportingArray,
  selectReportingItem,
  setSelectedToExport,
} from '../../redux/reducers/reporting/sliceReducer';
import {
  changeReportingSorting,
  getReportingList,
} from '../../redux/reducers/reporting/asyncThunkActions';
import { getFormatDateWithMonth } from '../../common/utils/getFormatDate';
import { SalaryType } from '../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import TableTooltip from '../../components/common-table/table-tooltip';
import { IReportingResponse } from '../../common/api/services/ReportingApi/types/IReportingResponse';

const types = Object.values(SalaryType);

const ReportingPage: FC = () => {
  const dispatch = useAppDispatch();

  const { reportingList, isLoading, selectedArray } = useAppSelector(
    (state) => state.reporting,
  );
  useEffect(() => {
    dispatch(selectReportingArray(null));
    dispatch(selectReportingItem([]));
    dispatch(getReportingList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { content, pagination, sort, filter, selectedConsultant } =
    reportingList;
  const { totalElement, currentPage, perPage } = pagination;

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeReportingFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => dispatch(getReportingList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeReportingSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getReportingList({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
        searchAsString: false,
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['firstName']
          ? (filter['firstName'] as string)
          : '',
      })('firstName'),
      className: sortClassName('firstName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['lastName'] ? (filter['lastName'] as string) : '',
      })('lastName'),
      className: sortClassName('lastName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Contract Type',
      dataIndex: 'contractType',
      key: 'contractType',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.contractType as string[]) ?? [],
        fieldFilterName: 'contractType',
      })(types),
      className: sortClassName('contractType'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
    },
    {
      title: 'Period',
      dataIndex: 'commonPeriod',
      key: 'commonPeriod',
      ...useTableMonthDate({
        ...searchFilterArg,
        defaultValue: filter['commonPeriod']
          ? (filter['commonPeriod'] as string)
          : '',
        valueType: 'commonPeriod',
        placeholder: 'Period',
      })(),
      className: sortClassName('commonPeriod'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={180}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Project info',
      dataIndex: 'projectInfo',
      key: 'projectInfo',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectInfo as string) ?? '',
      })('projectInfo'),
      className: sortClassName('client.name'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (text: string) => (
        <TableTooltip
          tootltipTitle={text}
          columnWidth={150}
          placement="leftTop">
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Email approval',
      dataIndex: 'emailApproval',
      key: 'emailApproval',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['emailApproval']
          ? (filter['emailApproval'] as string)
          : '',
      })('emailApproval'),
      width: 150,
    },
    {
      title: 'Standard time',
      dataIndex: 'timesheetsStandardTimeTotal',
      key: 'timesheetsStandardTimeTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.timesheetsStandardTimeTotal as string) ?? '',
      })('timesheetsStandardTimeTotal'),
      className: sortClassName('timesheetsStandardTimeTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 160,
    },
    {
      title: 'Overtime',
      dataIndex: 'timesheetsOvertimeTotal',
      key: 'timesheetsOvertimeTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.timesheetsOvertimeTotal as string) ?? '',
      })('timesheetsOvertimeTotal'),
      className: sortClassName('timesheetsOvertimeTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 140,
    },
    {
      title: 'Double Time',
      dataIndex: 'timesheetsDoubleTimeTotal',
      key: 'timesheetsDoubleTimeTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.timesheetsDoubleTimeTotal as string) ?? '',
      })('timesheetsDoubleTimeTotal'),
      className: sortClassName('timesheetsDoubleTimeTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
    },
    {
      title: 'EC',
      dataIndex: 'expensesClientTotal',
      key: 'expensesClientTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['expensesClientTotal']
          ? (filter['expensesClientTotal'] as string)
          : '',
      })('expensesClientTotal'),
      className: sortClassName('expensesClientTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 120,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={120}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'EO',
      dataIndex: 'expensesOptimizationTotal',
      key: 'expensesOptimizationTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['expensesOptimizationTotal']
          ? (filter['expensesOptimizationTotal'] as string)
          : '',
      })('expensesOptimizationTotal'),
      className: sortClassName('expensesOptimizationTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 120,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={120}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'MA',
      dataIndex: 'mealAllowanceExpensesTotal',
      key: 'mealAllowanceExpensesTotal',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['mealAllowanceExpensesTotal']
          ? (filter['mealAllowanceExpensesTotal'] as string)
          : '',
      })('mealAllowanceExpensesTotal'),
      className: sortClassName('mealAllowanceExpensesTotal'),
      sorter: true,
      sortDirections: ['descend'],
      width: 120,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={120}>
          {text}
        </TableTooltip>
      ),
    },
  ];

  const data = content.map((item) => {
    return {
      id: item.timesheets[0]?._id,
      item,
      publicId: item.publicId,
      firstName: item.firstName,
      lastName: item.lastName,
      emailApproval: item.emailApproval ? item.emailApproval : 'No',
      contractType: item.consultantOnboarding.workContract.companies.type,
      commonPeriod: getFormatDateWithMonth(item.commonPeriod),
      clientName: item.consultantOnboarding.workContract.companies.name,
      timesheetsStandardTimeTotal: item.timesheetsStandardTimeTotal,
      timesheetsOvertimeTotal: item.timesheetsOvertimeTotal,
      timesheetsDoubleTimeTotal: item.timesheetsDoubleTimeTotal,
      expensesClientTotal: `${item.expensesClientTotal} ${item.consultantOnboarding.workContract.companies.invoiceCurrency}`,
      expensesOptimizationTotal: `${item.expensesOptimizationTotal} CHF`,
      expensesOptimization: item.expensesOptimization,
      expensesClient: item.expensesClient,
      timesheets: item.timesheets,
      mealAllowanceExpensesTotal: `${item.mealAllowanceExpensesTotal} CHF`,
      projectInfo:
        item?.consultantOnboarding?.workContract?.companies
          ?.projectDescription || '',
      uniqId: `${item.consultantId}${item._id.period}${item.consultantOnboarding.workContract.companies.clientId}${item.timesheets[0]?._id}`,
    };
  });

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedObjects: IReportingResponse[],
  ): void => {
    const timesheetsIds = selectedObjects
      .filter((item) => item.id)
      .flatMap((item) => item.timesheets.map((i) => i._id));
    const expensesIds = selectedObjects.flatMap((item) => [
      ...item.expensesClient.map((i) => i._id),
      ...item.expensesOptimization.map((i) => i._id),
    ]);
    const expensesIdsNoTimesheet = selectedObjects
      .filter((item) => !item.timesheets || item.timesheets.length === 0)
      .flatMap((item) => [
        ...item.expensesClient.map((i) => i._id),
        ...item.expensesOptimization.map((i) => i._id),
      ]);
    const selectedToExport = [...timesheetsIds, ...expensesIdsNoTimesheet];
    dispatch(setSelectedToExport(selectedToExport));
    dispatch(selectReportingArray({ timesheetsIds, expensesIds }));
    dispatch(selectReportingItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedConsultant,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return (
    <Spin spinning={isLoading}>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChangeSort}
        rowSelection={rowSelection}
        rowKey="uniqId"
      />
    </Spin>
  );
};

export default ReportingPage;
