import { FC, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { Link } from 'react-router-dom';
import React from 'react';
import { getDefaultFormatDate } from '../../../common/utils/getFormatDate';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { Roles } from '../../../common/enums/Roles';

const { published } = OnboardingRequestStatuses;

const ClientContractTab: FC = () => {
  const [selectedClientIdx, setSelectedClientIdx] = useState(0);
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;
  const { clients } = useAppSelector(
    (state) => state.onboarding.clientContract,
  );
  const { workContract } = useAppSelector((state) => state.onboarding);
  const isPublished = useAppSelector((state) =>
    state.onboarding.tabStatus.every((status) => status === published),
  );
  const clientCount = clients.length;
  const {
    signatories = [],
    invoiceries = [],
    reminders = [],
    shippingMethod = '',
    shippingMethodDetails: shippingMethods = '',
    workType = 'Full time',
    paymentTerm = '',
    paymentTermRange = '',
    reimbursableExpenses = true,
    isAgencyInvolved = false,
    agencyName = '',
    agencyEmail = '',
    agencyCountry = '',
    startDate,
    endDate,
    numeriqOffice,
    clientContacts,
    wageType,
    addresses,
    timesheetSubmission,
  } = clients[selectedClientIdx];

  const onSelectCurrentClient = (idx: number) => (): void => {
    setSelectedClientIdx(idx);
  };

  const isShowShippingMethodDetails =
    shippingMethod === 'by post' || shippingMethod === 'portal';

  const shippingMethodDetails = shippingMethods || {
    postalAddress: '',
    firstName: '',
    lastName: '',
    portalUrl: '',
  };

  const {
    postalAddress = '',
    firstName = '',
    lastName = '',
    portalUrl = '',
  } = shippingMethodDetails;

  return (
    <div style={{ overflow: 'hidden auto' }} className="my-information-tab">
      <h4>Information</h4>

      <div>
        <Col span={32}>
          {clientCount !== 1 && (
            <div
              style={{
                marginBottom: '1rem',
                overflowX: 'auto',
                width: '100%',
                marginTop: '1rem',
              }}>
              <nav
                className="navigation-tabs"
                style={{ marginBottom: 0, justifyContent: 'flex-start' }}>
                {clients.map(({ _id }, idx) => {
                  const isActive = selectedClientIdx === idx;
                  const isWorkContractName =
                    workContract.companies[idx]?.name?.length > 0;
                  const tabName = isWorkContractName
                    ? workContract.companies[idx].name
                    : `Client ${idx + 1}`;
                  return (
                    <Col key={_id} span={6}>
                      <Popover
                        placement="top"
                        content={tabName}
                        trigger="hover"
                        overlayClassName="info-pop-content"
                        showArrow={false}>
                        <Link
                          to={'#'}
                          className={
                            isActive ? '__active cut-text' : 'cut-text'
                          }
                          onClick={onSelectCurrentClient(idx)}>
                          <span className="tab-label">{tabName}</span>
                        </Link>
                      </Popover>
                    </Col>
                  );
                })}
              </nav>
            </div>
          )}

          {signatories.map(({ name, lastName, email }, idx) => (
            <React.Fragment key={`signatory${idx}`}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Signatory Name</span>
                    <span className="item-value">{name}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Signatory Last Name</span>
                    <span className="item-value">{lastName}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Signatory email</span>
                    <span className="item-value">{email}</span>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          ))}

          {invoiceries.map(({ name, lastName, email }, idx) => (
            <React.Fragment key={`invoicing${idx}`}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Invoicing Name</span>
                    <span className="item-value">{name}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Invoicing Last Name</span>
                    <span className="item-value">{lastName}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Invoicing email</span>
                    <span className="item-value">{email}</span>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          ))}

          {reminders.map(({ name, lastName, email }, idx) => (
            <React.Fragment key={`remainder${idx}`}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Reminder Name</span>
                    <span className="item-value">{name}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Reminder Last Name</span>
                    <span className="item-value">{lastName}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Reminder email</span>
                    <span className="item-value">{email}</span>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          ))}

          {isAdmin &&
            isPublished &&
            clientContacts &&
            clientContacts.map(({ name, lastName, email, phone }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Client contact Name</span>
                      <span className="item-value">{name}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">
                        Client contact Last Name
                      </span>
                      <span className="item-value">{lastName}</span>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Client contact Email</span>
                      <span className="item-value">{email}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">
                        Client contact Phone number
                      </span>
                      <span className="item-value">{phone}</span>
                    </div>
                  </Col>
                </Row>
              </>
            ))}

          <Row gutter={[16, 16]}>
            <Col span={24 / (isShowShippingMethodDetails ? 2 : 1)}>
              <div className="personal-info">
                <span className="item-label">Invoice shipping method</span>
                <span className="item-value">
                  {shippingMethod.charAt(0).toUpperCase() +
                    shippingMethod.slice(1)}
                </span>
              </div>
            </Col>
            {shippingMethod === 'portal' && (
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Portal URL</span>
                  <span className="item-value">{portalUrl}</span>
                </div>
              </Col>
            )}
            {shippingMethod === 'by post' && (
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Postal address</span>
                  <span className="item-value">{postalAddress}</span>
                </div>
              </Col>
            )}
          </Row>

          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Start date</span>
                  <span className="item-value">
                    {startDate ? getDefaultFormatDate(startDate) : '-'}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">End date</span>
                  <span className="item-value">
                    {endDate ? getDefaultFormatDate(endDate) : '-'}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>

          {shippingMethod === 'by post' && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">First name</span>
                  <span className="item-value">{firstName}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Last name</span>
                  <span className="item-value">{lastName}</span>
                </div>
              </Col>
            </Row>
          )}
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Work Type:</span>
                  <span className="item-value">{workType}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Wage type:</span>
                  <span className="item-value">{wageType || '-'}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Timesheet submission:</span>
                <span className="item-value">{timesheetSubmission || '-'}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">№ of days for payment terms</span>
                <span className="item-value">{paymentTermRange}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Payment term</span>
                <span className="item-value">{paymentTerm}</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Reimbursable expenses:</span>
                <span className="item-value">
                  {reimbursableExpenses ? 'Yes' : 'No'}
                </span>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Numeriq Office</span>
                <span className="item-value">{numeriqOffice || '-'}</span>
              </div>
            </Col>
          </Row>
        </Col>
        {addresses.map(
          (
            {
              street = '',
              postCode = '',
              country = '',
              city = '',
              vat = '',
              addressLines = [],
            },
            idx,
          ) => (
            <React.Fragment key={idx}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="personal-info">
                    <span className="item-label">
                      Address (street name and number)
                    </span>
                    <span className="item-value">{street}</span>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {addressLines.length > 0
                  ? addressLines.map((item, index) => (
                      <Col key={index} span={24}>
                        {item}
                      </Col>
                    ))
                  : null}
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Postcode</span>
                    <span className="item-value">{postCode}</span>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">Country</span>
                    <span className="item-value">{country}</span>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">City</span>
                    <span className="item-value">{city}</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="personal-info">
                    <span className="item-label">VAT</span>
                    <span className="item-value">{vat}</span>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          ),
        )}

        {/* <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="personal-info">
              <span className="item-label">Is agency involved?</span>
              <span className="item-value">
                {isAgencyInvolved ? 'Yes' : 'No'}
              </span>
            </div>
          </Col>
        </Row> */}

        {/* {isAgencyInvolved && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Agency name</span>
                  <span className="item-value">{agencyName}</span>
                </div>
              </Col>

              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Agency email</span>
                  <span className="item-value">{agencyEmail}</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">Agency country</span>
                  <span className="item-value">{agencyCountry}</span>
                </div>
              </Col>
            </Row>
          </>
        )} */}
      </div>
    </div>
  );
};

export default ClientContractTab;
