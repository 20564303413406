import { Modal } from 'antd';
import '../FilePreview.less';
import { useEffect, useState } from 'react';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import DynamicExcelTable from './TableExcel';

interface IProps {
  url: string;
  isOpen: boolean;
  setClose: () => void;
}

const ExcelPreview = (props: IProps) => {
  const { url, isOpen, setClose } = props;
  const [fileUrl, setFileUrl] = useState<any>(null);
  const isExcel =
    ['.xlsx'].includes(url.slice(-5).toLowerCase()) ||
    ['.xls'].includes(url.slice(-4).toLowerCase());

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await (isExcel
          ? fileApi.getExcelFileByUrl(url)
          : fileApi.getFileByUrl(url));
        setFileUrl(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFile();
  }, []);

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      width={'70%'}
      onCancel={setClose}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div className="modal-wrapper" style={{ height: '815px' }}>
        {fileUrl && !isExcel && (
          <embed
            src={fileUrl}
            type="application/pdf"
            style={{ height: '815px' }}
          />
        )}
        {fileUrl && isExcel && <DynamicExcelTable fileBlob={fileUrl} />}
      </div>
    </Modal>
  );
};

export default ExcelPreview;
