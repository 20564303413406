export type TPaymentTermsList = 'End of the month' | 'NET';
export const paymentTermsList: TPaymentTermsList[] = [
  'End of the month',
  'NET',
];

// export const paymentTermsListAsObject: TPaymentTermsList[] = [
//   {'End of the month'},
//   {'NET'},
// ];
