export enum Statuses {
  //Lead statuses
  new = 'new',
  duplicated = 'duplicated',
  invited = 'invited',
  confirmed = 'confirmed',
  failed = 'failed',
  archived = 'archived',
  //Customer statuses
  active = 'active',
  inactive = 'inactive',
}

export enum LeadStatuses {
  new = Statuses.new,
  duplicated = Statuses.duplicated,
  invited = Statuses.invited,
  confirmed = Statuses.confirmed,
  failed = Statuses.failed,
  archived = Statuses.archived,
}
