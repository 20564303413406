import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  putFileOnboardingChildAllowances,
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import {
  UploadEntity,
  UploadEntityId,
} from '../../../../common/api/services/FileApi/enums/RequestEnums';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { IChildAllowancesRequest } from '../../../../common/api/services/OnboardingApi/types/IChildAllowanceRequest';
import { IOnboardingSaveOptions } from './../types';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftChildAllowancesAsEmployee = createAsyncThunk(
  'onboarding/saveDraftChildAllowances',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { childAllowances, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[5] === OnboardingRequestStatuses.draft ||
      tabStatus[5] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IChildAllowancesRequest> = {
      key: OnboardingRequestKeys.childAllowances,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: childAllowances,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putChildAllowancesAsEmployee(
          consultantId,
          dataQuery,
        );

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 5, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 5,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'childAllowances',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveToConfirmChildAllowancesAsEmployee = createAsyncThunk(
  'onboarding/saveToConfirmChildAllowances',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { childAllowances, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IChildAllowancesRequest> = {
      key: OnboardingRequestKeys.childAllowances,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: childAllowances,
    };

    const { isSuccess, data } =
      await onboardingApi.putChildAllowancesAsEmployee(consultantId, dataQuery);

    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 5, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'childAllowances',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftChildAllowancesAsConsultant = createAsyncThunk(
  'onboarding/saveDraftChildAllowancesAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { childAllowances, tabStatus } = (getState() as IRootState)
      .onboarding;
    const isDraft =
      tabStatus[5] === OnboardingRequestStatuses.draft ||
      tabStatus[5] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IChildAllowancesRequest> = {
      key: OnboardingRequestKeys.childAllowances,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: childAllowances,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putChildAllowancesAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(
            push(
              getOnboardingRedirect(
                tabStatus,
                5,
                redirectRootRout,
                false,
                true,
              ),
            ),
          );
        }
        dispatch(
          setOnboardingTabStatus({
            key: 5,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'childAllowances',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const savePublishChildAllowancesAsEmployee = createAsyncThunk(
  'onboarding/savePublishChildAllowances',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { childAllowances, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;

    const dataQuery: IQueryParameters<IChildAllowancesRequest> = {
      key: OnboardingRequestKeys.childAllowances,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: childAllowances,
    };

    const { isSuccess, data } =
      await onboardingApi.putChildAllowancesAsEmployee(consultantId, dataQuery);

    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 5, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.published,
          isAdmin: true,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'childAllowances',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishChildAllowancesAsConsultant = createAsyncThunk(
  'onboarding/savePublishChildAllowancesAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { childAllowances, tabStatus } = (getState() as IRootState)
      .onboarding;

    const dataQuery: IQueryParameters<IChildAllowancesRequest> = {
      key: OnboardingRequestKeys.childAllowances,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: childAllowances,
    };

    const { isSuccess, data } =
      await onboardingApi.putChildAllowancesAsConsultant(dataQuery);
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 5,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(
        push(
          getOnboardingRedirect(tabStatus, 5, redirectRootRout, false, true),
        ),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'childAllowances',
          fieldValue: data,
        }),
      );
    }
  },
);

export const uploadFileOnboardingChildAllowances = createAsyncThunk(
  'onboarding/uploadFileOnboardingChildAllowances',
  async (files: File[], { dispatch }) => {
    const response = await fileApi.uploadFile(
      files,
      UploadEntityId.childAllowances,
      UploadEntity.consultantOnboarding,
    );

    if (response) {
      dispatch(putFileOnboardingChildAllowances(response));
    }
  },
);
