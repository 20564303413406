import { useEffect, useState } from 'react';
import { IARPayload } from '../../../../common/api/services/ActivityApi/types/IActivityReportResponse';
import { activityApi } from '../../../../common/api/services/ActivityApi/activityApi';
import { openErrorNotification } from '../../../../components/notification/Notification';
import { ColumnsType } from 'antd/lib/table';
import CommonTable from '../../../../components/common-table/CommonTable';
import { formatAmount } from '../../../../common/utils/formatAmount';

interface IProps {
  payloadInfo: IARPayload | null;
}

interface IFinancialData {
  _id: {
    monthYear: string;
  };
  numeriqFeesType: string;
  invoiceCurrency: string;
  period: string;
  hasSalaryAmount: boolean;
  timesheetIds: Array<string>;
  amountLC: {
    billRate: 592;
    payRate: 3200;
    agencyMargin: 0;
    numeriqFees: 416;
    ahvSalary: 500.36;
  };
  amountCHF: {
    billRateCHF: 8288;
    payRateCHF: 44800;
    agencyMarginCHF: 0;
    numeriqFeesCHF: 5824;
    ahvSalaryCHF: 7005.04;
  };
  fxRate: {
    _id: string;
    currencyPair: string;
    rate: 14;
    period: string;
    createdAt: string;
    updatedAt: string;
    user: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
      role: string;
    };
  };
}

const FinancialTable = (props: IProps) => {
  const { payloadInfo } = props;
  const [financialData, setFinancialData] = useState<IFinancialData | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!payloadInfo) {
      return;
    }
    setIsLoading(true);
    activityApi
      .getFinancialStructure(payloadInfo)
      .then((resp: any) => setFinancialData(resp))
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message || 'Failed to get financial structure',
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const data = financialData
    ? [
        {
          name: 'Bill rate',
          currency: financialData.invoiceCurrency || '',
          amountLc: formatAmount(financialData.amountLC?.billRate) || 0,
          fxRate: financialData.fxRate?.rate || 0,
          amounCHF: formatAmount(financialData.amountCHF?.billRateCHF) || 0,
        },
        {
          name: 'Pay rate',
          currency: financialData.invoiceCurrency || '',
          amountLc: formatAmount(financialData.amountLC?.payRate) || 0,
          fxRate: financialData.fxRate?.rate || 0,
          amounCHF: formatAmount(financialData.amountCHF?.payRateCHF) || 0,
        },
        {
          name: 'AHV salary',
          currency: financialData.invoiceCurrency || '',
          amountLc: formatAmount(financialData.amountLC?.ahvSalary) || 0,
          fxRate: financialData.fxRate?.rate || 0,
          amounCHF: formatAmount(financialData.amountCHF?.ahvSalaryCHF) || 0,
        },
        {
          name: 'Agency margin',
          currency: financialData.invoiceCurrency || '',
          amountLc: formatAmount(financialData.amountLC?.agencyMargin) || 0,
          fxRate: financialData.fxRate?.rate || 0,
          amounCHF: formatAmount(financialData.amountCHF?.agencyMarginCHF) || 0,
        },
        {
          name: 'Numeriq fees (Nominal/Percentage)',
          currency: financialData.invoiceCurrency || '',
          amountLc: formatAmount(financialData.amountLC?.numeriqFees) || 0,
          fxRate: financialData.fxRate?.rate || 0,
          amounCHF: formatAmount(financialData.amountCHF?.numeriqFeesCHF) || 0,
        },
      ]
    : [];

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: 150,
    },
    {
      title: 'Amount LC',
      dataIndex: 'amountLc',
      width: 150,
    },
    {
      title: 'FX',
      dataIndex: 'fxRate',
      width: 150,
    },
    {
      title: 'Amount CHF',
      dataIndex: 'amounCHF',
      width: 150,
    },
  ];

  return (
    <div>
      <CommonTable
        data={data}
        columns={columns}
        hidePagination={true}
        loading={isLoading}
        height={220}
        tableHeight={250}
      />
    </div>
  );
};

export default FinancialTable;
