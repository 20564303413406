import { FC } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import OnboardingAvsAhvTab from '../../onboarding-page/tabs/avs-ahv/AvsAhvTab';
import InfoIcon from '../../../assets/icons/InfoIcon';

const AvsAhvTab: FC = () => {
  const { ssn, lppLevel } = useAppSelector(
    (state) => state.onboarding.ssnInformation,
  );

  const { workForm } = useAppSelector((state) => state.onboarding.personalInfo);

  const isToConfirm = useAppSelector(
    (state) =>
      state.onboarding.tabStatus[6] === OnboardingRequestStatuses.toConfirm ||
      state.onboarding.tabStatus[6] === OnboardingRequestStatuses.draft,
  );

  if (isToConfirm) {
    return <OnboardingAvsAhvTab />;
  }
  if (workForm === 'corporation') {
    <div className="information-message">
      <div className="icon-wrapper">
        <InfoIcon />
      </div>
      <p>
        As you work as a Corporation you don’t need to fill information for a
        pension. You can skip this step.
      </p>
    </div>;
  }

  return (
    <div className="my-information-tab">
      <h4>Information</h4>
      <Row>
        <Col span={12}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">AVS/AHV number</span>
                  <span className="item-value">{ssn}</span>
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">LPP level</span>
                  <span className="item-value">{lppLevel}</span>
                </div>
              </Col>
            </Row>
          </Col>

          {/* <Col>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">LPP amount CHF</span>
                  <span className="item-value">{lppAmount}</span>
                </div>
              </Col>
            </Row>
          </Col> */}

          {/* <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="personal-info">
                  <span className="item-label">Comment</span>
                  <span className="item-value">{comment ? comment : '-'}</span>
                </div>
              </Col>
            </Row>
          </Col> */}
        </Col>
        <Col>
          {/* <Col span={24}>
            <div className="personal-info">
              <span className="item-label">Registration</span>
              <span className="item-value">
                {hasRegistration ? 'Yes' : 'No'}
              </span>
            </div>
          </Col> */}
          {/* <Col span={24}>
            <div className="personal-info">
              <span className="item-label">LPP Registration</span>
              <span className="item-value">
                {hasLppRegistration ? 'Yes' : 'No'}
              </span>
            </div>
          </Col> */}
          {/* <Col span={24}>
            <div className="personal-info">
              <span className="item-label">Annual AVS/AHV salary amount</span>
              <span className="item-value">{annualSalaryAmount || 0}</span>
            </div>
          </Col> */}
        </Col>
      </Row>
    </div>
  );
};

export default AvsAhvTab;
