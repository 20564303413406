import { useEffect, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Spin } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import downloadIcon from '../../assets/images/download-icon.svg';
import { IConsultantPayslip } from '../../common/api/services/ConsultantPayslipsApi/types/IConsultantPayslips';
import { consultantPayslipsApi } from '../../common/api/services/ConsultantPayslipsApi/consultantPayslipsApi';
import { ISort, TSortValue } from '../../redux/types';
import {
  changeConsultantPayslipsFiltering,
  clearConsultantPayslipsFiltersAndSorts,
  setConsultantPayslipsList,
  setConsultantPayslipsPagination,
  setConsultantPayslipsSorting,
} from '../../redux/reducers/consultant-payslips/sliceReducer';
import { openErrorNotification } from '../../components/notification/Notification';
import { SorterResult } from 'antd/lib/table/interface';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import moment from '../../common/constants/moment';
import {
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import { Link } from 'react-router-dom';
import './ConsultantPaylips.less';
import { downloadFileByUrl } from '../../common/utils/downloadFileByUrl';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { fileApi } from '../../common/api/services/FileApi/FileApi';
import SidebarLogoIcon from '../../assets/icons/SidebarLogoIcon';
import { LoadingOutlined } from '@ant-design/icons';
import LogoIcon from '../../assets/images/logo.png';
import { FilterMobileTypes } from '../../components/common-table/types';
import ResetFilterBtn from '../../components/resetFilterBtn/ResetFilterBtn';
import MobileLoader from '../../components/mobile-loader/MobileLoader';

const ConsultantPayslipsPage = () => {
  const dispatch = useAppDispatch();
  const { consultantPayslipsList } = useAppSelector(
    (state) => state.consultantPayslips,
  );
  const { pagination, sort, filter } = consultantPayslipsList;
  const { totalElement, currentPage, perPage } = pagination;
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el?.length !== 0);

  const [payslips, setPayslips] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPayslips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, filter]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPayslips = (currentSort?: ISort, currentFilter?: any) => {
    setIsLoading(true);
    consultantPayslipsApi
      .getConsultantPayslips(
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        dispatch(setConsultantPayslipsList(response));
        setPayslips(response.result);
      })
      .catch((e) => openErrorNotification('Failed to get payslips'))
      .finally(() => setIsLoading(false));
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeConsultantPayslipsFiltering(date));
  };
  const changeMobileFilter = (
    date: Record<string, string | string[]>,
  ): void => {
    dispatch(changeConsultantPayslipsFiltering(date));
    dispatch(setConsultantPayslipsPagination({ perPage, currentPage: 1 }));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setConsultantPayslipsSorting(newSort));
      getPayslips({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };

  const onChangeMobileSort = (sort: { sortBy: string; sort: string }) => {
    dispatch(
      setConsultantPayslipsSorting([sort.sortBy, sort.sort as TSortValue]),
    );
    getPayslips({ sortBy: sort.sortBy, sort: sort.sort as TSortValue });
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setConsultantPayslipsPagination({ perPage, currentPage }));
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const data = payslips.map((item) => {
    const { period, uploadDate, originalFilename, payslip, salarySimulation } =
      item;
    return {
      period: period ? moment(period).format(commonMonthYearSpaceFormat) : '',
      uploadDate: uploadDate
        ? moment(uploadDate).format(commonMonthTimeFormat)
        : '',
      originalFilename,
      payslip,
      salarySimulation,
      simulation: salarySimulation,
    };
  });

  const columns: any = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'period',
        placeholder: 'Period',
        defaultValue: (filter?.period as string) ?? '',
      })(),
      className: sortClassName('period'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.data,
      defaultFilter: filter?.period,
      showSorterTooltip: false,
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'uploadDate',
        values: (filter?.uploadDate as string) ?? '',
      })(),
      className: sortClassName('uploadDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.dataRange,
      defaultFilter: filter?.uploadDate,
      showSorterTooltip: false,
    },
    {
      title: 'Download payslip',
      dataIndex: 'payslip',
      key: 'payslip',
      className: sortClassName('payslip'),
      width: 200,
      showSorterTooltip: false,
      render: (item: any, data: any) => {
        if (!data.payslip) {
          return null;
        }
        return (
          <span
            onClick={() =>
              fileApi.downloadFileByUrl(data.payslip.originalFilename)
            }
            className="download-link-file">
            {data.payslip.originalFilename || ''}
          </span>
        );
      },
    },
    {
      title: 'Download simulation',
      dataIndex: 'simulation',
      key: 'simulation',
      className: sortClassName('simulation'),
      width: 200,
      showSorterTooltip: false,
      render: (item: any, data: any) => {
        if (!data.salarySimulation) {
          return null;
        }
        return (
          <span
            onClick={() =>
              fileApi.downloadFileByUrl(data.salarySimulation.originalFilename)
            }
            className="download-link-file">
            {data.salarySimulation.originalFilename || ''}
          </span>
        );
      },
    },
  ];

  return (
    <div style={{ height: '90%' }}>
      <div className="header-section header-section-payslips">
        <Breadcrumb />
        <div className="right-header-section">
          {isHasFilters && (
            <button
              className="rest-filters-button"
              onClick={() => {
                dispatch(clearConsultantPayslipsFiltersAndSorts());
                getPayslips({ sort: '', sortBy: '' }, []);
              }}>
              <ResetFilterBtn />
            </button>
          )}
        </div>
      </div>
      <div className="container-payslips">
        <Spin spinning={isLoading} indicator={<MobileLoader />}>
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            onChange={onChangeSort}
            submitFilters={changeMobileFilter}
            sortBy={sort}
            onChangeMobileSort={onChangeMobileSort}
          />
        </Spin>
      </div>
    </div>
  );
};

export default ConsultantPayslipsPage;
