import { Key, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { employeePayslipsApi } from '../../common/api/services/EmployeePayslips/employeePayslipsApi';
import { Spin, TableProps } from 'antd';
import {
  IConsultantInfo,
  IViewPayslipResponse,
  IViewPayslipsResponse,
  PaymentStatuses,
  PaymentStatusesArray,
} from '../../common/api/services/EmployeePayslips/types/types';
import {
  commonDateFormat,
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ISort, TSortValue } from '../../redux/types';
import {
  changeViewPayslipsFiltering,
  clearViewPayslipsFiltersAndSorts,
  selectViewPayslipsItem,
  setSelectedIds,
  setViewPayslipsList,
  setViewPayslipsPagination,
  setViewPayslipsSorting,
} from '../../redux/reducers/view-payslips/sliceReducer';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import CommonTable from '../../components/common-table/CommonTable';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import './ViewPayslipsPage.less';
import Button from '../../components/button/Button';
import ExitIcon from '../../assets/icons/ExitIcon';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { DeletePayslipsText } from '../../components/common-texts/CommonTexts';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import PayslipsIcon from '../../assets/icons/PayslipsIcon';
import { downloadExel } from '../../common/api/utils/downloadExel';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { payslipPaymentStatusParser } from '../../common/constants/payslipPaymentStatusParser';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { downloadFileByUrl } from '../../common/utils/downloadFileByUrl';
import { fileApi } from '../../common/api/services/FileApi/FileApi';

const ViewPayslipsPage = () => {
  const { consultantId } = useParams<{ consultantId: string }>();
  const dispatch: any = useAppDispatch();
  const { viewPayslipsList, selectedPayslips, selectedIds } = useAppSelector(
    (state) => state.viewPayslips,
  );
  const { pagination, sort, filter } = viewPayslipsList;
  const { currentPage, perPage, totalElement } = pagination;
  const [isLoading, setIsLoading] = useState(false);
  const [payslips, setPayslips] = useState<IViewPayslipsResponse>([]);
  const [consultantInfo, setConsultantInfo] = useState<IConsultantInfo | null>(
    null,
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  useEffect(() => {
    getPayslips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, filter]);

  const getPayslips = (currentSort?: ISort, currentFilter?: any) => {
    if (!consultantId) {
      return;
    }
    setIsLoading(true);
    employeePayslipsApi
      .getPayslipsByConsultantId(
        consultantId,
        currentPage,
        perPage,
        currentFilter || filter,
        currentSort || sort,
      )
      .then((response) => {
        dispatch(setViewPayslipsList(response));
        setConsultantInfo(response.consultant);
        setPayslips(response.result);
      })
      .catch((e) => openErrorNotification('Failed to get payslips'))
      .finally(() => setIsLoading(false));
  };

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    newSelectedElements: any[],
  ): void => {
    dispatch(selectViewPayslipsItem(newSelectedRowKeys as string[]));
    const newSelectedItems: Array<string> = [];
    newSelectedElements.forEach((item) => {
      if (item?.salarySimulation?.originalFilename) {
        newSelectedItems.push(item?.salarySimulation._id);
      }
      if (item?.payslip?.originalFilename) {
        newSelectedItems.push(item?.payslip._id);
      }
    });
    dispatch(setSelectedIds(newSelectedItems));
  };

  const rowSelection = {
    selectedRowKeys: selectedPayslips,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };
  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeViewPayslipsFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setViewPayslipsSorting(newSort));
      getPayslips({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setViewPayslipsPagination({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const onDeletePayslips = () => {
    if (!consultantId) {
      return;
    }
    const body = [{ consultantId, ids: selectedIds }];
    employeePayslipsApi
      .deletePayslip(body)
      .then(() => {
        openSuccessNotification('Payslips were deleted successfully');
        dispatch(selectViewPayslipsItem([]));
        dispatch(setSelectedIds([]));
        getPayslips();
      })
      .catch(() => {
        openErrorNotification('Paylips deleting was failed');
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpenDeleteModal(false);
      });
  };

  const downloadExcel = () => {
    if (payslips.length === 0) {
      openErrorNotification('No payslips for download');
      return;
    }
    if (!consultantId) {
      return;
    }
    employeePayslipsApi
      .getViewPayslipsExcel(consultantId)
      .then((response: any) => {
        downloadExel(response);
      })
      .catch((e) => openErrorNotification('Failed to download excel'));
  };

  const data = payslips.map((item) => {
    const {
      period,
      uploadDate,
      _id,
      paymentDate,
      salaryNetPaid,
      paymentStatus,
      originalFilename,
      payslip,
      salarySimulation,
    } = item;
    return {
      _id,
      period: period ? moment(period).format(commonMonthYearSpaceFormat) : '',
      uploadDate: uploadDate
        ? moment(uploadDate).format(commonMonthTimeFormat)
        : '',
      paymentDate: paymentDate
        ? moment(paymentDate).format(commonDateFormat)
        : '',
      salaryNetPaid,
      paymentStatus,
      originalFilename,
      payslip,
      salarySimulation,
    };
  });

  const columns: ColumnsType<IViewPayslipResponse> = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'period',
        values: (filter?.period as string) ?? '',
      })(),
      className: sortClassName('period'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'uploadDate',
        values: (filter?.uploadDate as string) ?? '',
      })(),
      className: sortClassName('uploadDate'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },

    {
      title: 'Payslip status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      className: `status-td ${sortClassName('paymentStatus')}`,
      sorter: true,
      sortDirections: ['descend'],
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.paymentStatus as string[]) ?? [],
        fieldFilterName: 'paymentStatus',
      })(PaymentStatusesArray),
      render: (text: PaymentStatuses) => {
        if (!text) {
          return <span>No</span>;
        }
        return (
          <div className="status-wrapper">
            <span className={payslipPaymentStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 160,
    },
    {
      title: 'Salary net paid',
      dataIndex: 'salaryNetPaid',
      width: 180,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.salaryNetPaid as string) ?? '',
        searchAsString: false,
      })('salaryNetPaid'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Payslip payment date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      className: sortClassName('uploadDate'),
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'paymentDate',
        values: (filter?.paymentDate as string) ?? '',
      })(),
      width: 200,
    },
    {
      title: 'Download payslip',
      dataIndex: 'download',
      key: 'download',
      className: sortClassName('download'),
      width: 200,
      render: (text: string, data) => {
        if (!data.payslip) {
          return null;
        }
        return (
          <span
            onClick={() =>
              fileApi.downloadFileByUrl(data.payslip.originalFilename)
            }
            className="download-link-file">
            {data.payslip.originalFilename || ''}
          </span>
        );
      },
    },
    {
      title: 'Download simulation',
      dataIndex: 'downloadSimulation',
      key: 'downloadSimulation',
      className: sortClassName('downloadSimulation'),
      width: 240,
      render: (text: string, data) => {
        if (!data.salarySimulation) {
          return null;
        }
        return (
          <span
            onClick={() =>
              fileApi.downloadFileByUrl(data.salarySimulation.originalFilename)
            }
            className="download-link-file">
            {data.salarySimulation.originalFilename || ''}
          </span>
        );
      },
    },
  ];

  return (
    <div className="container">
      <div className="view-content">
        <div className="header-view">
          <span className="consultant-info-payslips">
            <PayslipsIcon
              style={{ stroke: '#0e2552', width: '20px', height: '20px' }}
            />
            {consultantInfo && (
              <span>
                {consultantInfo.firstName || ''} {consultantInfo.lastName || ''}
              </span>
            )}
            <span style={{ marginLeft: '6px' }}>Payslips</span>
          </span>

          <div className="right-header-section">
            {(isHasFilters || selectedPayslips.length > 0) && (
              <button
                className="rest-filters-button"
                onClick={() => {
                  onSelectChange([], []);
                  dispatch(clearViewPayslipsFiltersAndSorts());
                  getPayslips({ sort: '', sortBy: '' }, []);
                }}>
                <ResetFilterIcon />
              </button>
            )}
            {selectedIds.length === 0 ? (
              <Button
                onClick={() => {
                  downloadExcel();
                }}
                buttonStyle="secondary"
                buttonSize="medium"
                buttonWidth={200}
                leftIcon={<ExitIcon className="export-icon" />}>
                Export to Excel
              </Button>
            ) : (
              <div className="btn-header-container">
                <div style={{ padding: '10px' }}>
                  <Button
                    buttonStyle="error"
                    buttonSize="medium"
                    buttonWidth={183}
                    onClick={() => setIsOpenDeleteModal(true)}>
                    {`Delete selected (${selectedPayslips.length})`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <Spin spinning={isLoading}>
            <CommonTable
              paginationCurrentPage={currentPage}
              paginationPageSize={perPage}
              paginationOnChange={onChangePagination}
              paginationTotalElement={totalElement}
              data={data}
              columns={columns}
              rowSelection={rowSelection}
              onChange={onChangeSort}
              rowKey="period"
            />
          </Spin>
        </div>
      </div>

      {isOpenDeleteModal && (
        <AttentionModal
          isOpen={isOpenDeleteModal}
          cancelBtnTitle="Cancel"
          confirmBtnTitle="Yes, delete"
          closeModal={closeDeleteModal}
          confirmAction={onDeletePayslips}
          content={
            <DeletePayslipsText
              payslipsCount={selectedIds.length || 1}
              type="delete"
            />
          }
          isHideAttentionText
        />
      )}
    </div>
  );
};

export default ViewPayslipsPage;
