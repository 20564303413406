import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUploadFileResponse } from '../../../common/api/services/FileApi/types/IUploadFileResponse';

import { IState } from './type';
import { IPagination, TSortValue } from '../../types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantTimesheet } from '../../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';

const initialState: IState = {
  isOpenModalEditTimesheet: false,
  isOpenModalViewTimesheet: false,
  isOpenModalAddTimesheet: false,
  isOpenModalHasExpenses: false,
  isLoading: false,
  isUpLoading: false,
  timesheetList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
    selectedConsultant: [],
  },
  selectedTimesheet: {
    _id: '',
    id: '',
    clientApprovalDate: '',
    consultantId: '',
    hasActivityPerMonth: true,
    isSigned: false,
    approvalRequired: false,
    workContract: null,
    clientContract: null,
    period: '',
    type: '',
    companyRepresentativeEmail: '',
    representativeFirstName: '',
    representativeLastName: '',
    documents: [],
    clientFiles: [],
    comments: '',
    numeriqComments: '',
    standardTime: 0,
    overtime: 0,
    doubleTime: 0,
    status: TimesheetStatus.DRAFT,
    createdAt: '',
    updatedAt: '',
    visibility: 'public',
    consultant: null,
  },
  errorMessages: null,
};

const reducer = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    setTimesheetList(
      state,
      action: PayloadAction<IGetListDto<IConsultantTimesheet>>,
    ) {
      state.timesheetList.content = action.payload.result;
      state.timesheetList.pagination.totalElement = action.payload.total;
    },
    changeTimesheetFiltering(state, action: PayloadAction<any>) {
      state.timesheetList.filter = {
        ...state.timesheetList.filter,
        ...action.payload,
      };
    },
    setTimesheetSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.timesheetList.sort.sortBy = action.payload[0];
      state.timesheetList.sort.sort = action.payload[1];
    },
    clearTimesheetFiltersAndSorts(state) {
      state.timesheetList.sort = initialState.timesheetList.sort;
      state.timesheetList.filter = initialState.timesheetList.filter;
      state.timesheetList.selectedConsultant =
        initialState.timesheetList.selectedConsultant;
    },
    setTimesheetPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.timesheetList.pagination.currentPage = action.payload.currentPage;
      state.timesheetList.pagination.perPage = action.payload.perPage;
    },
    clearSelectedTimesheet(state) {
      state.selectedTimesheet = initialState.selectedTimesheet;
      state.errorMessages = null;
    },
    setErrorMessages(
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) {
      state.errorMessages = action.payload;
    },
    updateOpenModalAddTimesheet(state, action: PayloadAction<boolean>) {
      state.isOpenModalAddTimesheet = action.payload;
    },
    updateOpenModalEditTimesheet(state, action: PayloadAction<boolean>) {
      state.isOpenModalEditTimesheet = action.payload;
    },
    updateOpenModalViewTimesheet(state, action: PayloadAction<boolean>) {
      state.isOpenModalViewTimesheet = action.payload;
    },
    updateIsUpLoadingEditTimesheet(state, action: PayloadAction<boolean>) {
      state.isUpLoading = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSelectedTimesheet(
      state,
      action: PayloadAction<Record<string, string | boolean | null | number>>,
    ) {
      state.selectedTimesheet = {
        ...state.selectedTimesheet,
        ...action.payload,
      };
    },
    updateByIdSelectedTimesheet(state, action: PayloadAction<string>) {
      const selectedTimesheet = state.timesheetList.content.find(
        (item: IConsultantTimesheet) => item._id === action.payload,
      );

      if (!selectedTimesheet) return;

      state.selectedTimesheet = selectedTimesheet;
    },
    updateSelectedTimesheetCheckbox(
      state,
      action: PayloadAction<'isSigned' | 'approvalRequired'>,
    ) {
      const { payload } = action;
      const { [payload]: value } = state.selectedTimesheet;
      state.selectedTimesheet[payload] = !value;
    },
    updateFilesSelectedTimesheet(
      state,
      action: PayloadAction<IUploadFileResponse[]>,
    ) {
      state.selectedTimesheet.documents = [
        ...state.selectedTimesheet.documents,
        ...action.payload,
      ];
    },
    removeFileSelectedTimesheet(state, action: PayloadAction<string>) {
      state.selectedTimesheet.documents =
        state.selectedTimesheet.documents.filter(
          (el) => el.originalFilename !== action.payload,
        );
    },
    resetFilesSelectedTimesheet(state) {
      state.selectedTimesheet.documents = [];
    },
    selectInternalTimesheetItem(state, action: PayloadAction<string[]>) {
      state.timesheetList.selectedConsultant = action.payload;
    },
    setIsOpenModalHasExpenses(state, action: PayloadAction<boolean>) {
      state.isOpenModalHasExpenses = action.payload;
    },
  },
});

export const {
  setTimesheetList,
  setTimesheetPagination,
  changeTimesheetFiltering,
  setTimesheetSorting,
  clearTimesheetFiltersAndSorts,
  clearSelectedTimesheet,
  setErrorMessages,
  updateIsUpLoadingEditTimesheet,
  updateOpenModalAddTimesheet,
  updateOpenModalEditTimesheet,
  updateOpenModalViewTimesheet,
  updateByIdSelectedTimesheet,
  updateSelectedTimesheet,
  updateSelectedTimesheetCheckbox,
  updateFilesSelectedTimesheet,
  removeFileSelectedTimesheet,
  resetFilesSelectedTimesheet,
  selectInternalTimesheetItem,
  setIsOpenModalHasExpenses,
  setIsLoading,
} = reducer.actions;

export default reducer.reducer;
