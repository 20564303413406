import { FC, MouseEvent } from 'react';
import CopyIcon from '../../assets/icons/CopyIcon';
import { onCopyByClick } from '../../common/utils/onCopyByClick';

import './CopyLinkSection.less';

interface IProps {
  link: string;
}

const CopyLinkSection: FC<IProps> = ({ link }) => {
  return (
    <div className="copy-link-section" onDoubleClick={onDoubleClick}>
      {link}
      <CopyIcon onClick={() => onCopyByClick(link)} />
    </div>
  );
};

export default CopyLinkSection;

function onDoubleClick(event: MouseEvent<HTMLDivElement>): void {
  const range = document.createRange();
  range.selectNode(event.target as Node);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  window.getSelection()!.removeAllRanges();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  window.getSelection()!.addRange(range);
  document.execCommand('copy');
}
