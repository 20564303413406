export const transformRoutToBreadcrumbItem = (rout: string): string => {
  const routArr = rout.split('-');

  const newRoutArr = routArr.map((str, idx) => {
    if (idx === 0) {
      return (str[0] ?? '').toUpperCase() + str.slice(1);
    }
    return str;
  });

  return newRoutArr.join(' ');
};
