import { useState } from 'react';
import ExitIcon from '../../../assets/icons/ExitIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import {
  exportToExelActivityReport,
  getActivityReportList,
} from '../../../redux/reducers/activity-report/asyncThunkActions';
import {
  clearActivityReportFiltersAndSorts,
  selectActivityReportItem,
  setSelectedPayslips,
} from '../../../redux/reducers/activity-report/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import {
  DeleteActivityReportPayslipsText,
  UploadDatePayslips,
} from '../../../components/common-texts/CommonTexts';
import { employeePayslipsApi } from '../../../common/api/services/EmployeePayslips/employeePayslipsApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import '../../../components/extra-section/ExtraSection.less';
import moment from 'moment';
import { commonYearDateFormat } from '../../../common/constants/dateConstants';
import { consultantPayslipsApi } from '../../../common/api/services/ConsultantPayslipsApi/consultantPayslipsApi';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';

const ActivityReportExtraSection = () => {
  const dispatch = useAppDispatch();
  const [isOpenDeletePayslips, setIsOpenDeletePayslips] = useState(false);
  const [isOpenUploadDate, setIsOpenUploadDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const {
    sort: activityReportSorts,
    filter: activityReportFilters,
    selectedConsultant: activityReportSelectedConsultant,
  } = useAppSelector((state) => state.activityReport.activityReportList);

  const { selectedPayslips: activityReportPayslips } = useAppSelector(
    (state) => state.activityReport,
  );

  const { selectedPayslips, payslipsPayload } = useAppSelector(
    (state) => state.activityReport,
  );

  const isResetActivityReportFilters =
    activityReportSorts.sortBy !== '' ||
    Object.values(activityReportFilters).some((el) => el.length !== 0) ||
    activityReportSelectedConsultant.length > 0;

  const deletePayslips = () => {
    employeePayslipsApi
      .deletePayslipActivityReport(activityReportSelectedConsultant)
      .then(() => {
        openSuccessNotification('Payslips was deleted successfully');
        setIsOpenDeletePayslips(false);
        dispatch(selectActivityReportItem([]));
        dispatch(setSelectedPayslips([]));
        dispatch(getActivityReportList());
      })
      .catch(() => {
        openErrorNotification('Failed to delete payslips');
      });
  };

  const onChangeDate = (date: moment.Moment) => {
    setSelectedDate(moment(date).format(commonYearDateFormat));
  };

  const updateDate = () => {
    const body = {
      paymentDate: selectedDate,
      payslipsPayload,
    };
    consultantPayslipsApi
      .updatePaylipsDate(body)
      .then(() => {
        openSuccessNotification('Date update was successful');
        dispatch(getActivityReportList());
      })
      .catch((e) => {
        openErrorNotification(e.message || 'Failed to update date');
      })
      .finally(() => setIsOpenUploadDate(false));
  };
  const downloadPayslip = () => {
    consultantApi.downloadCSVActivityReport(
      payslipsPayload.map((item) => item?.consultantId),
    );
    consultantApi.downloadCSVEmployee(
      payslipsPayload.map((item) => item?.consultantId),
    );
    consultantApi.downloadCSVChildren(
      payslipsPayload.map((item) => item?.consultantId),
    );
  };

  return (
    <>
      {payslipsPayload.length > 0 &&
        activityReportSelectedConsultant.length > 0 && (
          <Button
            onClick={downloadPayslip}
            buttonStyle="primary"
            buttonSize="medium"
            buttonWidth={200}>
            Generate Payslips
          </Button>
        )}
      {isResetActivityReportFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearActivityReportFiltersAndSorts());
            dispatch(getActivityReportList({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}

      <Button
        onClick={() => dispatch(exportToExelActivityReport())}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={200}
        leftIcon={<ExitIcon className="export-icon" />}>
        Export to Excel
      </Button>
      {selectedPayslips.length > 0 && (
        <Button
          onClick={() => setIsOpenDeletePayslips(true)}
          buttonStyle="error"
          buttonSize="medium"
          buttonWidth={200}>
          Delete files
        </Button>
      )}
      {payslipsPayload.length > 0 && (
        <Button
          onClick={() => setIsOpenUploadDate(true)}
          buttonStyle="primary"
          buttonSize="medium"
          buttonWidth={200}>
          Update date
        </Button>
      )}
      {isOpenDeletePayslips && (
        <AttentionModal
          content={
            <DeleteActivityReportPayslipsText
              payslipsCount={activityReportPayslips.length}
              type="delete"
              payslips={activityReportPayslips}
            />
          }
          cancelBtnTitle="Cancel"
          closeModal={() => setIsOpenDeletePayslips(false)}
          confirmBtnTitle="Delete"
          isOpen={isOpenDeletePayslips}
          isHideAttentionText
          confirmAction={deletePayslips}
        />
      )}
      {isOpenUploadDate && (
        <AttentionModal
          content={
            <UploadDatePayslips
              payslipsCount={activityReportSelectedConsultant.length}
              onChangeDate={onChangeDate}
            />
          }
          cancelBtnTitle="Cancel"
          hideCloseBtn
          closeModal={() => setIsOpenUploadDate(false)}
          confirmBtnTitle="Submit"
          isOpen={isOpenUploadDate}
          isHideAttentionText
          wrapperClassName={'upload-date-ar-modal'}
          confirmAction={updateDate}
          btnWidth="180px"
        />
      )}
    </>
  );
};

export default ActivityReportExtraSection;
