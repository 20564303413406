import { ComponentType } from 'react';
import { useAppDispatch } from '../../redux/store';
import {
  addNewContactToAdditionalInfoAsConsultant,
  deleteContactInAdditionalInfoAsConsultant,
} from '../../redux/reducers/onboarding/tabs/additionalInfoAsyncThunk';

interface IProps {
  addContact: () => void;
  deleteContact: (id: string) => void;
}

export const withConsultantAdditionalInfoDeletingAndAddingContract = (
  WrappedComponent: ComponentType<IProps>,
) => {
  const Component = () => {
    const dispatch = useAppDispatch();

    const onAdd = () => {
      dispatch(addNewContactToAdditionalInfoAsConsultant());
    };

    const onDelete = (id: string) => {
      dispatch(deleteContactInAdditionalInfoAsConsultant(id));
    };

    return <WrappedComponent deleteContact={onDelete} addContact={onAdd} />;
  };

  return Component;
};
