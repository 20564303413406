import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ExpensesIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 18 14"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1591 1.69871C15.1908 1.69871 15.9845 2.50193 15.9845 3.5461V10.4537C15.9845 11.4979 15.1908 12.3011 14.1591 12.3011H3.8416C2.80985 12.3011 2.0162 11.4979 2.0162 10.4537V3.5461C2.0162 2.50193 2.80985 1.69871 3.8416 1.69871H14.1591ZM14.1591 0.333252H3.8416C2.09556 0.333252 0.666992 1.77903 0.666992 3.5461V10.4537C0.666992 12.2208 2.09556 13.6666 3.8416 13.6666H14.1591C15.9051 13.6666 17.3337 12.2208 17.3337 10.4537V3.5461C17.3337 1.77903 15.9051 0.333252 14.1591 0.333252Z" />
      <path
        d="M16.4611 5.63452H14.2389C13.5246 5.63452 12.8896 6.19677 12.8896 6.99998C12.8896 7.72287 13.4452 8.36544 14.2389 8.36544H16.4611"
        strokeWidth="1.58567"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect transform="translate(0.666992 0.333252)" />
    </svg>
  );
};

export default ExpensesIcon;
