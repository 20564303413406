import { FC } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { OnboardingRequestStatuses } from '../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import OnboardingBankAccountTab from '../../onboarding-page/tabs/bank-accont-tab/BankAccountTab';

const BankAccountTab: FC = () => {
  const {
    hasIBAN,
    iban,
    swift,
    currency,
    npa,
    accountOwner,
    address,
    additionalAddress,
    comment,
    bankName,
    bankAddress,
    bankAdditionalAddress,
    bankCity,
    bankCountry,
    bankAccountDetails,
  } = useAppSelector((state) => state.onboarding.bankAccount);

  const isToConfirm = useAppSelector(
    (state) =>
      state.onboarding.tabStatus[4] === OnboardingRequestStatuses.toConfirm ||
      state.onboarding.tabStatus[4] === OnboardingRequestStatuses.draft,
  );

  if (isToConfirm) {
    return <OnboardingBankAccountTab />;
  }

  return (
    <Row className="my-information-tab">
      <Col span={12}>
        <h4>Account details</h4>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="personal-info">
                <span className="item-label">Do you have an IBAN number?</span>
                <span className="item-value">{hasIBAN ? 'Yes' : 'No'}</span>
              </div>
            </Col>
          </Row>
        </Col>

        {hasIBAN && (
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">IBAN number</span>
                  <span className="item-value">{iban}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="personal-info">
                  <span className="item-label">SWIFT</span>
                  <span className="item-value">{swift || '-'}</span>
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {!hasIBAN && (
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="personal-info">
                  <span className="item-label">Bank account details</span>
                  <span className="item-value">{bankAccountDetails}</span>
                </div>
              </Col>
            </Row>
          </Col>
        )}

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Currency</span>
                <span className="item-value">{currency}</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Owner of the account</span>
                <span className="item-value">{accountOwner}</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Account address line 1</span>
                <span className="item-value">{address}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Account address line 2</span>
                <span className="item-value">
                  {additionalAddress ? additionalAddress : '-'}
                </span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Comment</span>
                <span className="item-value">{comment ? comment : '-'}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>

      <Col span={12}>
        <h4>Bank details</h4>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Name of the bank</span>
                <span className="item-value">{bankName}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Postcode of the Bank</span>
                <span className="item-value">{npa ? npa : '-'}</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Bank address line 1</span>
                <span className="item-value">{bankAddress}</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Bank address line 2</span>
                <span className="item-value">
                  {bankAdditionalAddress ? bankAdditionalAddress : '-'}
                </span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">City bank</span>
                <span className="item-value">{bankCity}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="personal-info">
                <span className="item-label">Country</span>
                <span className="item-value">{bankCountry}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default BankAccountTab;
