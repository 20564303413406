import { Col, Radio, Row } from 'antd';
import TaxAtSourceItems from './components/TaxAtSourceItems';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateIsSubject } from '../../../../redux/reducers/onboarding/sliceReducer';
import { useEffect } from 'react';
import { openErrorNotification } from '../../../../components/notification/Notification';
import InfoIcon from '../../../../assets/icons/InfoIcon';

const options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TaxAtSourceTab = () => {
  const dispatch = useAppDispatch();
  const { workForm } = useAppSelector((state) => state.onboarding.personalInfo);
  const { isSubject } = useAppSelector((state) => state.onboarding.taxAtSource);
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.taxAtSource,
  );
  const isPayroll = workForm === 'payroll';

  useEffect(() => {
    if (errorMessages) {
      if (typeof errorMessages === 'string') {
        openErrorNotification(errorMessages);
        return;
      }
      Object.values(errorMessages).map((item) => openErrorNotification(item));
    }
  }, [errorMessages]);
  return (
    <div className="tax-container">
      {!isPayroll && (
        <div className="information-message" style={{ width: '50%' }}>
          <div className="icon-wrapper">
            <InfoIcon />
          </div>
          <p>
            {`As the option "Corporation" was picked on the tab "Pesronal
            Information", you don't need to fill out this tab. Please click the
            button "Skip and go next".`}
          </p>
        </div>
      )}
      <h4>Information</h4>
      <Row gutter={[32, 0]} className="tax-mark-container">
        <Col>
          <span>Consultant is subject to Tax at Source</span>
          <span className="asterisk">*</span>
        </Col>
        <Col>
          <Radio.Group
            name="hasBankAccount"
            value={isSubject}
            disabled={!isPayroll}
            options={options}
            onChange={(value) => dispatch(updateIsSubject(value.target.value))}
          />
        </Col>
      </Row>
      {isPayroll && isSubject !== null && <TaxAtSourceItems />}
    </div>
  );
};

export default TaxAtSourceTab;
