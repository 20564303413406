import { createAsyncThunk } from '@reduxjs/toolkit';
import { leadApi } from '../../../common/api/services/LeadApi/leadApi';
import {
  putLeadToList,
  putSelectedLeadDetails,
  setDataLeadLoading,
  setErrorMessage,
  setLeadListPagination,
  setLeadListSorting,
  setLoadList,
  setSaleList,
} from './sliceReducer';
import { IPaginationRequest, IRootState } from '../../types';
import { batch } from 'react-redux';
import { push } from 'redux-first-history';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { userApi } from '../../../common/api/services/UserApi/userApi';
import { openErrorNotification } from '../../../components/notification/Notification';

const { waitingRoom } = ROUTER_PATHS;

export const getLeadsList = createAsyncThunk(
  'waitingRoom/getLeadsList',
  async (
    pagination: IPaginationRequest | undefined,
    { dispatch, getState },
  ) => {
    const currentPagination = (getState() as IRootState).waitingRoom.leadList
      .pagination;
    const currentFilter = (getState() as IRootState).waitingRoom.leadList
      .filter;
    const currentSort = (getState() as IRootState).waitingRoom.leadList.sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await leadApi.fetchLeads(
      page,
      perPage,
      currentFilter,
      currentSort,
    );

    if (response) {
      batch(() => {
        dispatch(setLoadList(response));
        dispatch(setLeadListPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const getLeadById = createAsyncThunk(
  'waitingRoom/getLeadById',
  async (id: string, { dispatch }) => {
    const response = await leadApi.getLeadById(id);

    if (response) {
      dispatch(putSelectedLeadDetails(response));
    }
  },
);

export const changeLeadById = createAsyncThunk(
  'waitingRoom/changeLeadById',
  async (_, { dispatch, getState }) => {
    dispatch(setDataLeadLoading(true));

    const data = (getState() as IRootState).waitingRoom.selectedLead
      .leadDetails;
    const response = await leadApi.changeLeadDetails(data._id, data);

    if (typeof response === 'object') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setErrorMessage(null));
      dispatch(push(waitingRoom));
    }

    dispatch(setDataLeadLoading(false));
  },
);

export const archiveLead = createAsyncThunk(
  'waitingRoom/archiveLead',
  async (id: string, { dispatch }) => {
    const response = await leadApi.archiveLead(id);

    if (typeof response === 'object') {
      dispatch(putLeadToList(response));
    }
  },
);

export const duplicatedLead = createAsyncThunk(
  'waitingRoom/duplicatedLead',
  async (id: string, { dispatch }) => {
    const response = await leadApi.duplicatedLead(id);

    if (response) {
      dispatch(putLeadToList(response));
    } else {
      openErrorNotification('The current email is unique');
    }
  },
);

export const getSalesList = createAsyncThunk(
  'waitingRoom/getSalesList',
  async (_, { dispatch }) => {
    const response = await userApi.getAllManagers();

    dispatch(setSaleList(response));
  },
);

export const assignManagerOnLead = createAsyncThunk(
  'waitingRoom/assignManagerOnLead',
  async (
    [currentLeadId, managerId]: [string, string],
    { getState, dispatch },
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const manager = (
      getState() as IRootState
    ).waitingRoom.leadList.saleList.find((sale) => sale.id === managerId)!;

    const response = await leadApi.assignManager(currentLeadId, manager);

    if (response) {
      dispatch(putLeadToList(response));
    }
  },
);

export const changeLeadListSorting = createAsyncThunk(
  'waitingRoom/changeLeadListSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).waitingRoom.leadList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setLeadListSorting([currentSortBy, 'asc']));
      dispatch(getLeadsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setLeadListSorting([currentSortBy, 'desc']));
      dispatch(getLeadsList({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setLeadListSorting(['', '']));
      dispatch(getLeadsList({ currentPage: 1 }));
      return;
    }
  },
);
