import { FC, useEffect, Key, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Row, Spin, TableProps, Tooltip, UploadFile } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import {
  timesheetStatusParser,
  TTimesheetStatusParserKey,
} from '../../common/constants/timesheetStatusParser';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionsMenu from './components/ActionsMenu';
import {
  changeActivityReportSorting,
  getActivityReportList,
} from '../../redux/reducers/activity-report/asyncThunkActions';
import {
  changeActivityReportFiltering,
  selectActivityReportItem,
  setIsOpenModalGenerateInvoice,
  setPayslipsPayload,
  setSelectedPayslips,
} from '../../redux/reducers/activity-report/sliceReducer';
import ModalGenerateInvoice from './components/modal-generate-invoice/ModalGenerateInvoice';
import './ActivityReportPage.less';
import { getFormatDateWithMonth } from '../../common/utils/getFormatDate';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import { TimesheetStatus } from '../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import { ExpensesStatus } from '../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { paymentDates } from '../../common/constants/permitDays';
import { SalaryType } from '../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import currencies from '../../common/constants/mockCurrencies';
import { shippingMethodsList } from '../../common/constants/shippingMethodsList';
import { downloadFileByUrl } from '../../common/utils/downloadFileByUrl';
import { numeriqOfficeOptions } from '../../common/constants/numeriqOfficeOptions';
import { IActivityReportColumn } from './types';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import TableTooltip from '../../components/common-table/table-tooltip';
import CommonDatePicker from '../../components/date-picker/CommonDatePicker';
import {
  TExpensesStatusParserKey,
  expensesStatusParser,
} from '../../common/constants/expensesStatusParser';
import {
  getClientExpensesStatus,
  getOptimisationExpensesStatus,
  getTimesheetsStatus,
} from '../../common/utils/getActivityReportStatus';
import {
  PaymentStatuses,
  PaymentStatusesArray,
} from '../../common/api/services/EmployeePayslips/types/types';
import { payslipPaymentStatusParser } from '../../common/constants/payslipPaymentStatusParser';
import SalaryNetPaidRow from './components/SalaryNetPaidRow';
import ChangeStatusDropdown from './components/ChangeStatusTootltip';
import { employeePayslipsApi } from '../../common/api/services/EmployeePayslips/employeePayslipsApi';
import { fileApi } from '../../common/api/services/FileApi/FileApi';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import {
  DeleteFilesText,
  DeletePayslipText,
} from '../../components/common-texts/CommonTexts';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import {
  TLinkedStatusParser,
  TLinkedStatusParserKey,
} from '../../common/api/services/ActivityApi/types/IActivityReportResponse';

const types = Object.values(SalaryType);
const timesheetStatusesArray = [
  TimesheetStatus.DRAFT,
  TimesheetStatus.WAITING_FOR_APPROVAL,
  TimesheetStatus.PRE_APPROVED,
  TimesheetStatus.APPROVED,
  TimesheetStatus.NO_ACTIVITY,
  TimesheetStatus.EXPIRED,
  TimesheetStatus.REJECTED,
  TimesheetStatus.CLIENT_APPROVAL,
];

const ECStatusesArray = [
  TimesheetStatus.DRAFT,
  TimesheetStatus.WAITING_FOR_APPROVAL,
  TimesheetStatus.PRE_APPROVED,
  TimesheetStatus.APPROVED,
  ExpensesStatus.NO_EXPENSES,
  TimesheetStatus.EXPIRED,
  TimesheetStatus.REJECTED,
  TimesheetStatus.CLIENT_APPROVAL,
];

const expensesStatusesArray = [
  ExpensesStatus.DRAFT,
  ExpensesStatus.WAITING_FOR_APPROVAL,
  ExpensesStatus.APPROVED,
  ExpensesStatus.REJECTED,
  ExpensesStatus.NO_EXPENSES,
  ExpensesStatus.EXPIRED,
];

interface IDeleteInfo {
  consultantId: string;
  id: string;
  type: 'payslip' | 'simulation';
}

const { activityReportInfo } = ROUTER_PATHS;

const ActivityReportPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activityReportList, isLoading } = useAppSelector(
    (state) => state.activityReport,
  );
  const { content, pagination, sort, filter, selectedConsultant } =
    activityReportList;
  useEffect(() => {
    if (!content?.length) {
      dispatch(getActivityReportList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { totalElement, currentPage, perPage } = pagination;
  const { isOpenModalGenerateInvoice } = useAppSelector(
    (state) => state.activityReport,
  );
  const [isOpenDeletePayslipModal, setIsOpenDeletePayslipModal] =
    useState(false);
  const [isOpenUnpublishModal, setIsOpenUnpublishModal] = useState(false);
  const [selectedPayslip, setSelectedPayslip] = useState<string | null>(null);
  const [deleteInfo, setDeleteInfo] = useState<IDeleteInfo | null>(null);
  const [isOpenDeleteFiles, setIsOpenDeleteFiles] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState<Array<string>>([]);

  const changeFilter = (date: Record<string, string | string[]>): void => {
    const { treasury } = date;
    let values = { ...date };
    if (Array.isArray(treasury) && treasury?.length > 0) {
      const array = treasury.map((item) => {
        if (item === 'Yes' || item === 'No') {
          return item === 'Yes' ? 'true' : 'false';
        }
        return item;
      });

      values = {
        ...values,
        treasury: array,
      };
    }
    dispatch(changeActivityReportFiltering(values));
  };

  useEffect(() => {
    dispatch(selectActivityReportItem([]));
    dispatch(setSelectedPayslips([]));
  }, []);

  const searchFilterArg = {
    fetchRequest: () => dispatch(getActivityReportList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  useEffect(() => {
    dispatch(selectActivityReportItem([]));
    dispatch(setSelectedPayslips([]));
  }, []);

  const onChangeSort: TableProps<IActivityReportColumn>['onChange'] = (
    ...params
  ) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      const sortBy = (params[2] as SorterResult<IActivityReportColumn>).field;
      dispatch(changeActivityReportSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getActivityReportList({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const openModalGenerateInvoice = (consultantId: string) => (): void => {
    console.log(consultantId); //TODO
    dispatch(setIsOpenModalGenerateInvoice(true));
  };

  const uploadPayslip = (
    file: UploadFile<any>[],
    publicId: number,
    period: string,
  ) => {
    if (file) {
      fileApi
        .uploadEmployeePayslipsReport(file, publicId, period)
        .then((resp: any) => {
          employeePayslipsApi
            .getUploadResultLogsStatus(resp.iterationId)
            .then((response: any) => {
              if (response.status === 'failed') {
                openErrorNotification(response.error);
                return;
              }
              openSuccessNotification('File was uploaded successfully');
              dispatch(getActivityReportList(pagination));
            })
            .catch((e) => {
              openErrorNotification(e.message);
            });
        })
        .catch((e) => {
          openErrorNotification(e.message);
        });
    }
  };

  const openDeleteModal = (
    consultantId: string,
    id: string,
    type: 'payslip' | 'simulation',
  ) => {
    setDeleteInfo({ consultantId, id, type });
    setIsOpenDeletePayslipModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteInfo(null);
    setIsOpenDeletePayslipModal(false);
  };

  const closeDeleteFiles = () => {
    setIsOpenDeleteFiles(false);
    setDeletedFiles([]);
  };

  const deletePayslip = () => {
    if (!deleteInfo || !deleteInfo.consultantId || !deleteInfo.id) {
      openErrorNotification('Failed to delete payslip');
      return;
    }
    const body = [
      { consultantId: deleteInfo.consultantId, ids: [deleteInfo.id] },
    ];
    employeePayslipsApi
      .deletePayslip(body)
      .then(() => {
        openSuccessNotification('Payslip was deleted successfully');
        closeDeleteModal();
        dispatch(getActivityReportList(pagination));
      })
      .catch((e) => {
        openErrorNotification('Failed to delete payslip');
      });
  };
  const unpublishPayslips = () => {
    if (!selectedPayslip) {
      openErrorNotification('Failed to unpublish payslip');
      return;
    }
    const body = {
      payslipsIds: [selectedPayslip],
    };
    employeePayslipsApi
      .unpublishPayslips(body)
      .then(() => {
        openSuccessNotification('Payslip was unpublished successfully');
        dispatch(getActivityReportList(pagination));
      })
      .catch((e) => {
        openErrorNotification('Failed to unpublish payslip');
      });
  };

  const deleteFiles = () => {
    employeePayslipsApi
      .deletePayslipActivityReport(deletedFiles)
      .then(() => {
        openSuccessNotification('Files was deleted successfully');
        closeDeleteFiles();
        dispatch(getActivityReportList(pagination));
      })
      .catch((e) => {
        openErrorNotification('Failed to delete files');
      });
  };

  const updatePaymentDate = async (
    id: string | undefined,
    date: Date,
    consultantId: string,
    period: string,
  ) => {
    if (!id) {
      const body = {
        paymentDate: date,
        consultantId,
        period,
      };
      await employeePayslipsApi
        .updatePayslip(body)
        .then(() => {
          openSuccessNotification(
            'Payslip payment date was successfully updated',
          );
          dispatch(getActivityReportList());
        })
        .catch((e) => {
          openErrorNotification(e.message);
        });
      return;
    }

    const body = {
      paymentDate: date,
    };
    await employeePayslipsApi
      .updatePayslipPaymentDate(id, body)
      .then(() => {
        openSuccessNotification(
          'Payslip payment date was successfully updated',
        );
        dispatch(getActivityReportList());
      })
      .catch((e) => {
        openErrorNotification(e.message);
      });
  };

  const columns: ColumnsType<IActivityReportColumn> = [
    {
      title: 'Deal #',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
        searchAsString: false,
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['firstName']
          ? (filter['firstName'] as string)
          : '',
      })('firstName'),
      className: sortClassName('firstName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['lastName'] ? (filter['lastName'] as string) : '',
      })('lastName'),
      className: sortClassName('lastName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Period',
      dataIndex: 'commonPeriod',
      key: 'commonPeriod',
      fixed: 'left',
      ...useTableMonthDate({
        ...searchFilterArg,
        defaultValue: filter['commonPeriod']
          ? (filter['commonPeriod'] as string)
          : '',
        valueType: 'commonPeriod',
        placeholder: 'Period',
      })(),
      className: sortClassName('commonPeriod'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={180}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Payroll/Corp',
      dataIndex: 'payrollCorp',
      key: 'payrollCorp',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payrollCorp as string[]) ?? [],
        fieldFilterName: 'payrollCorp',
      })(['payroll', 'corporation']),
      className: sortClassName('payrollCorp'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['agency'] ? (filter['agency'] as string) : '',
      })('agency'),
      className: sortClassName('agency'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={150}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Numeriq office WC',
      dataIndex: 'numeriqOfficeWC',
      key: 'numeriqOfficeWC',
      width: 190,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.numeriqOfficeWC as string[]) ?? [],
        fieldFilterName: 'numeriqOfficeWC',
      })(numeriqOfficeOptions),
      className: sortClassName('numeriqOfficeWC'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Numeriq office CC',
      dataIndex: 'numeriqOfficeCC',
      key: 'numeriqOfficeCC',
      width: 190,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.numeriqOfficeCC as string[]) ?? [],
        fieldFilterName: 'numeriqOfficeCC',
      })(numeriqOfficeOptions),
      className: sortClassName('numeriqOfficeCC'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Shipping method',
      dataIndex: 'shippingMethod',
      key: 'shippingMethod',
      className: sortClassName('shippingMethod'),
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.shippingMethod as string[]) ?? [],
        fieldFilterName: 'shippingMethod',
      })(shippingMethodsList),
      width: 180,
    },
    {
      title: 'Treasury',
      dataIndex: 'treasury',
      key: 'treasury',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.treasury as string[]) ?? [],
        fieldFilterName: 'treasury',
      })(['Yes', 'No']),
      className: sortClassName('treasury'),
      sorter: true,
      sortDirections: ['descend'],
      width: 135,
    },
    {
      title: 'Advanced timing',
      dataIndex: 'advancedTiming',
      key: 'advancedTiming',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        className: 'advancedTiming',
        defaultValues: (filter?.advancedTiming as string[]) ?? [],
        fieldFilterName: 'advancedTiming',
      })(paymentDates),
      className: sortClassName('advancedTiming'),
      sorter: true,
      sortDirections: ['descend'],
      width: 190,
    },
    {
      title: 'Bill currency',
      dataIndex: 'billCurrency',
      key: 'billCurrency',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.billCurrency as string[]) ?? [],
        fieldFilterName: 'billCurrency',
      })(currencies),
      className: sortClassName('billCurrency'),
      sorter: true,
      sortDirections: ['descend'],
      width: 160,
    },
    {
      title: 'Bill type',
      dataIndex: 'billRateType',
      key: 'billRateType',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.billRateType as string[]) ?? [],
        fieldFilterName: 'billRateType',
      })(types),
      className: sortClassName('billRateType'),
      sorter: true,
      sortDirections: ['descend'],
      width: 160,
    },
    {
      title: 'Bill rate',
      dataIndex: 'billRate',
      key: 'billRate',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['billRate'] ? (filter['billRate'] as string) : '',
      })('billRate'),
      width: 160,
    },

    {
      title: 'Total pay rate',
      dataIndex: 'timesheetsTotalPayRate',
      key: 'timesheetsTotalPayRate',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['timesheetsTotalPayRate']
          ? (filter['timesheetsTotalPayRate'] as string)
          : '',
      })('timesheetsTotalPayRate'),
      width: 230,
      className: sortClassName('timesheetsTotalPayRate'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'AVS/AHV salary',
      dataIndex: 'salary',
      key: 'salary',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['salary'] ? (filter['salary'] as string) : '',
      })('salary'),
      width: 180,
    },
    {
      title: 'Pay/Corp rate',
      dataIndex: 'payCorpRate',
      key: 'payCorpRate',
      className: sortClassName('payCorpRate'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
    },
    {
      title: 'Agency rate',
      dataIndex: 'agencyRate',
      key: 'agencyRate',
      className: sortClassName('agencyRate'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
    },
    {
      title: 'Timesheet status',
      dataIndex: 'timesheetStatus',
      key: 'timesheetStatus',
      className: `status-td ${sortClassName('timesheetStatus')}`,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.timesheetStatus as string[]) ?? [],
        fieldFilterName: 'timesheetStatus',
      })(timesheetStatusesArray),
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={timesheetStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'Invoiced timesheets',
      dataIndex: 'invoicedTimesheets',
      key: 'invoicedTimesheets',
      className: `status-td`,
      sorter: true,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.invoicedTimesheets as string[]) ?? [],
        fieldFilterName: 'invoicedTimesheets',
        isNoValue: true,
      })(Object.keys(TLinkedStatusParser)),
      sortDirections: ['descend'],
      render: (text: TLinkedStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={TLinkedStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'EC status',
      dataIndex: 'ecStatus',
      key: 'ecStatus',
      className: `status-td ${sortClassName('ecStatus')}`,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.ecStatus as string[]) ?? [],
        fieldFilterName: 'ecStatus',
      })(ECStatusesArray),
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'EO status',
      dataIndex: 'eoStatus',
      key: 'eoStatus',
      className: `status-td ${sortClassName('eoStatus')}`,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        className: 'eoStatus',
        defaultValues: (filter?.eoStatus as string[]) ?? [],
        fieldFilterName: 'eoStatus',
      })(
        expensesStatusesArray.filter(
          (item) =>
            item !== ExpensesStatus.PRE_APPROVED &&
            item !== ExpensesStatus.CLIENT_APPROVAL,
        ),
      ),
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'MA status',
      dataIndex: 'maStatus',
      key: 'maStatus',
      className: `status-td ${sortClassName('maStatus')}`,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        className: 'maStatus',
        defaultValues: (filter?.maStatus as string[]) ?? [],
        fieldFilterName: 'maStatus',
      })(
        expensesStatusesArray.filter(
          (item) =>
            item !== ExpensesStatus.PRE_APPROVED &&
            item !== ExpensesStatus.CLIENT_APPROVAL,
        ),
      ),
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'Invoiced expenses',
      dataIndex: 'invoicedExpenses',
      key: 'invoicedExpenses',
      className: `status-td`,
      sorter: true,
      sortDirections: ['descend'],
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.invoicedExpenses as string[]) ?? [],
        isNoValue: true,
        fieldFilterName: 'invoicedExpenses',
      })(Object.keys(TLinkedStatusParser)),
      render: (text: TLinkedStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={TLinkedStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'Payslip status',
      dataIndex: 'payslipPaymentStatus',
      key: 'payslipPaymentStatus',
      className: `status-td ${sortClassName('payslipPaymentStatus')}`,
      sorter: true,
      sortDirections: ['descend'],
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payslipPaymentStatus as string[]) ?? [],
        fieldFilterName: 'payslipPaymentStatus',
      })([...PaymentStatusesArray, 'Empty']),
      render: (text: PaymentStatuses, data) => {
        return (
          <Tooltip
            title={
              <ChangeStatusDropdown
                payslipId={data.payslipsId}
                consultantId={data.item.consultantId}
                period={data.period}
              />
            }
            placement="left"
            overlayClassName="overlay-tooltip-status">
            <div className="status-wrapper">
              <span className={payslipPaymentStatusParser[text]}>
                {text || 'No'}
              </span>
            </div>
          </Tooltip>
        );
      },
      width: 250,
    },
    {
      title: 'Payment currency',
      dataIndex: 'paymentCurrency',
      key: 'paymentCurrency',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.paymentCurrency as string[]) ?? [],
        fieldFilterName: 'paymentCurrency',
      })(currencies),
      width: 230,
      className: sortClassName('paymentCurrency'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Salary net paid',
      dataIndex: 'payslipSalaryNetPaid',
      key: 'payslipSalaryNetPaid',
      className: `${sortClassName('payslipSalaryNetPaid')}`,
      width: 230,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payslipSalaryNetPaid as string[]) ?? [],
        fieldFilterName: 'payslipSalaryNetPaid',
      })(['Filled', 'Empty']),
      sorter: true,
      sortDirections: ['descend'],
      render: (text: number, data) => (
        <SalaryNetPaidRow salary={text} data={data} />
      ),
    },
    {
      title: 'Payslip payment date',
      dataIndex: 'payslipPaymentDate',
      key: 'payslipPaymentDate',
      className: 'date-picker-column',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payslipPaymentDate as string[]) ?? [],
        fieldFilterName: 'payslipPaymentDate',
      })(['Filled', 'Empty']),
      width: 200,
      render: (text: string, data) => {
        const onChangeDate = (date: any) => {
          updatePaymentDate(
            data.payslipsId,
            date,
            data.item.consultantId,
            data.period,
          );
        };
        const payslipPaymentDate =
          data.item.payslips.length > 0
            ? data.item.payslips[0].paymentDate
            : '';
        return (
          <Row align="middle" style={{ position: 'relative' }}>
            <CommonDatePicker
              defaultValue={payslipPaymentDate || ''}
              changeDate={onChangeDate}
            />
          </Row>
        );
      },
    },
    {
      title: 'Payslip file',
      dataIndex: 'payslipFilename',
      key: 'payslipFilename',
      className: `status-td ${sortClassName('payslipFilename')}`,
      sorter: true,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payslipFilename as string[]) ?? [],
        fieldFilterName: 'payslipFilename',
      })(['Filled', 'Empty']),
      sortDirections: ['descend'],
      width: 180,
      render: ({ originalFilename }: { originalFilename: string }) => {
        return (
          <div>
            <a
              download={originalFilename}
              onClick={(event) => {
                event.preventDefault();
                fileApi.downloadFileByUrl(originalFilename);
              }}>
              {originalFilename}
            </a>
          </div>
        );
      },
    },
    {
      title: 'Simulation file',
      dataIndex: 'salarySimulationsFilename',
      key: 'salarySimulationsFilename',
      className: `status-td ${sortClassName('salarySimulationsFilename')}`,
      sorter: true,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.salarySimulationsFilename as string[]) ?? [],
        fieldFilterName: 'salarySimulationsFilename',
      })(['Filled', 'Empty']),
      sortDirections: ['descend'],
      width: 180,
      render: ({ originalFilename }: { originalFilename: string }) => {
        return (
          <div>
            <a
              download={originalFilename}
              onClick={(event) => {
                event.preventDefault();
                fileApi.downloadFileByUrl(originalFilename);
              }}>
              {originalFilename}
            </a>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'edit-action-td',
      width: 80,
      fixed: 'right',
      render: (_, { item }) => {
        const salarySimulations = item.salarySimulations || [];
        const payslips = item.payslips || [];
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionsMenu
                  onOpenGenerateInvoice={() => onOpenInvoice(item)}
                  onUpload={uploadPayslip}
                  consultant={item}
                  payslips={payslips}
                  deletePayslip={openDeleteModal}
                  publicId={item.publicId}
                  period={item._id?.period}
                  salarySimulations={salarySimulations}
                  setDeletedFiles={setDeletedFiles}
                  setIsOpenDeleteFiles={setIsOpenDeleteFiles}
                  timesheetId={item.timesheets[0]._id}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
    },
  ];

  const data = content.map((item) => {
    const expenses = item.timesheets
      .map((timesheet) => timesheet.expenses)
      .flat();
    const expencesMA = item.mealAllowanceExpenses;
    const payslips = item.payslips;
    const salarySimulations = item.salarySimulations || [];

    const expencesEO = item.expensesOptimization.flat();

    const agency = item.consultantOnboarding.clientContract.clients.agencyName;
    const advancedTiming =
      item.consultantOnboarding.workContract.companies.treasuryPaymentDate;
    const salary =
      item.consultantOnboarding.workContract.companies.salaryAmount;
    const contractNumber =
      item.consultantOnboarding.clientContract.clients.contractNumber;
    const payCorpRate = item.consultantOnboarding.workContract.companies.rate;

    const agencyRate =
      item.consultantOnboarding.clientContract.clients.agencyMargin;
    const officeCC =
      item.consultantOnboarding.clientContract.clients.numeriqOffice;
    const officeWC =
      item.consultantOnboarding.workContract.companies.numeriqOffice;

    return {
      id: item.timesheets[0]._id,
      contractNumber: contractNumber ? contractNumber : 'No',
      publicId: item.publicId,
      firstName: item.firstName,
      item,
      lastName: item.lastName,
      payCorpRate,
      agencyRate,
      payrollCorp: item.consultantOnboarding.personalInformation.workForm,
      commonPeriod: getFormatDateWithMonth(item.commonPeriod),
      projectDescription:
        item.consultantOnboarding.workContract.companies.projectDescription ||
        'No',
      agency: agency?.length > 0 ? agency : 'No',
      clientName: item.consultantOnboarding.workContract.companies.name,
      clientId: item.consultantOnboarding.workContract.companies.clientId,
      numeriqOfficeWC: officeWC || 'No',
      numeriqOfficeCC: officeCC || 'No',
      invoicedTimesheets: item.invoicedTimesheets,
      invoicedExpenses: item.invoicedExpenses,
      treasury: item.consultantOnboarding.workContract.companies.hasTreasury
        ? 'Yes'
        : 'No',
      advancedTiming: advancedTiming?.length > 0 ? advancedTiming : 'No',
      billCurrency:
        item.consultantOnboarding.workContract.companies.invoiceCurrency,
      billRateType: item.consultantOnboarding.workContract.companies.type,
      billRate: item.consultantOnboarding.clientContract.clients.billRate,
      salary: salary > 0 ? salary : 'No',
      timesheetStatus: getTimesheetsStatus(
        item.timesheets.map((timesheet) => timesheet.status),
      ),
      payslipExpenses: 'payslipExpenses',
      ecStatus: getClientExpensesStatus(
        expenses.map((expence) => expence.status),
      ),
      eoStatus: getOptimisationExpensesStatus(
        expencesEO.map((eo) => eo.status),
      ),
      maStatus: getOptimisationExpensesStatus(
        expencesMA.map((expence) => expence.status),
      ),
      payslipPaymentStatus:
        payslips.length > 0 ? payslips[0].paymentStatus : false,
      payslipSalaryNetPaid:
        payslips.length > 0 ? payslips[0].salaryNetPaid : '',
      payslipFilename:
        payslips.length > 0 && payslips[0].originalFilename
          ? {
              originalFilename: payslips[0].originalFilename,
            }
          : 'No',
      salarySimulationsFilename:
        salarySimulations.length > 0 && salarySimulations[0].originalFilename
          ? {
              originalFilename: salarySimulations[0].originalFilename,
            }
          : 'No',
      shippingMethod:
        item.consultantOnboarding.clientContract.clients.shippingMethod,
      payslipsId: payslips.length > 0 ? payslips[0]._id : false,
      salarySimulationsId:
        salarySimulations.length > 0 ? salarySimulations[0]._id : false,
      period: item.commonPeriod,
      projectId: item._id.projectId,
      consultantId: item.consultantId,
      paymentCurrency:
        item.consultantOnboarding.workContract?.companies?.paymentCurrency,
      timesheetsTotalPayRate: item.timesheetsTotalPayRate,
      contractType: item.consultantOnboarding?.workContract?.companies?.type,
      agencyName: item.consultantOnboarding?.clientContract.clients.agencyName,
      startDate: item.consultantOnboarding?.workContract?.companies?.startDate,
      sellerFirstName: item.seller?.firstName,
      sellerLastName: item.seller?.lastName,
      endDate: item.consultantOnboarding?.workContract?.companies?.endDate,
      clientEndDate:
        item.consultantOnboarding?.clientContract?.clients?.endDate,
      clientStartDate:
        item.consultantOnboarding?.clientContract?.clients?.startDate,
    };
  });

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    newSelectedElements: any[],
  ): void => {
    dispatch(selectActivityReportItem(newSelectedRowKeys as string[]));
    dispatch(
      setSelectedPayslips(
        newSelectedElements.filter(
          (item) =>
            !(
              item.payslipFilename === 'No' &&
              item.salarySimulationsFilename === 'No'
            ),
        ),
      ),
    );
    dispatch(
      setPayslipsPayload(
        newSelectedElements.map((item) => {
          return {
            consultantId: item.consultantId,
            period: item.period,
            companyId: item?.item?._id.projectId,
            publicId: item?.publicId,
          };
        }),
      ),
    );
  };

  const rowSelection = {
    selectedRowKeys: selectedConsultant,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  const onDoubleClick = (record: any) => {
    navigate(
      {
        pathname: activityReportInfo,
        search: `?${createSearchParams({
          projectId: record.projectId,
          consultantId: record.consultantId,
          clientId: record.clientId,
          period: record.commonPeriod,
        })}`,
      },
      {
        state: {
          commonPeriod: record.commonPeriod,
          firstName: record.firstName,
          lastName: record.lastName,
          publicId: record.publicId,
          companyName: record.clientName,
          agencyName: record.agencyName,
          contractType: record.contractType,
          payrollCorp: record.payrollCorp,
          paymentCurrency: record.paymentCurrency,
          advancedTiming: record.advancedTiming,
          startDate: record.startDate,
          sellerFirstName: record.sellerFirstName,
          sellerLastName: record.sellerLastName,
          clientEndDate: record.clientEndDate,
          numeriqOfficeCC: record.numeriqOfficeCC,
          clientStartDate: record.clientStartDate,
          endDate: record.endDate,
        },
      },
    );
  };

  const onOpenInvoice = (item: any) => {
    const advancedTiming =
      item.consultantOnboarding.workContract.companies.treasuryPaymentDate;

    navigate(
      {
        pathname: activityReportInfo,
        search: `?${createSearchParams({
          projectId: item._id.projectId,
          consultantId: item.consultantId,
          clientId: item.consultantOnboarding.workContract.companies.clientId,
          period: item.commonPeriod,
        })}`,
      },
      {
        state: {
          commonPeriod: item.commonPeriod,
          firstName: item.firstName,
          lastName: item.lastName,
          publicId: item.publicId,
          companyName:
            item?.consultantOnboarding?.workContract?.companies?.name,
          agencyName:
            item.consultantOnboarding.clientContract.clients.agencyName,
          contractType:
            item.consultantOnboarding?.workContract?.companies?.type,
          payrollCorp: item.consultantOnboarding.personalInformation.workForm,
          paymentCurrency:
            item.consultantOnboarding.workContract?.companies?.paymentCurrency,
          advancedTiming: advancedTiming?.length > 0 ? advancedTiming : 'No',
          startDate:
            item.consultantOnboarding?.workContract?.companies?.startDate,
          sellerFirstName: item.seller?.firstName,
          sellerLastName: item.seller?.lastName,
          clientEndDate:
            item.consultantOnboarding?.clientContract?.clients?.endDate,
          clientStartDate:
            item.consultantOnboarding?.clientContract?.clients?.startDate,
          numeriqOfficeCC:
            item.consultantOnboarding.clientContract.clients.numeriqOffice,
          endDate: item.consultantOnboarding?.workContract?.companies?.endDate,
        },
      },
    );
  };

  return (
    <Spin spinning={isLoading}>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChangeSort}
        rowSelection={rowSelection}
        rowKey="id"
        onDoubleClick={onDoubleClick}
        hasRowDatePicker
      />
      {isOpenModalGenerateInvoice && <ModalGenerateInvoice />}
      {isOpenDeletePayslipModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeDeleteModal}
          confirmAction={deletePayslip}
          confirmBtnTitle="Delete"
          content={<DeletePayslipText type={deleteInfo?.type} />}
          isOpen={isOpenDeletePayslipModal}
          isHideAttentionText
        />
      )}
      {isOpenDeleteFiles && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeDeleteFiles}
          confirmAction={deleteFiles}
          confirmBtnTitle="Delete"
          content={<DeleteFilesText />}
          isOpen={isOpenDeleteFiles}
          isHideAttentionText
        />
      )}
    </Spin>
  );
};

export default ActivityReportPage;
