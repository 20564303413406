import { FC } from 'react';
import { Col, Modal, Row, Tabs } from 'antd';
import Button from '../../../../components/button/Button';
import TimesheetsAndExpenses from './TimesheetsAndExpenses';
import Steps from './Steps';
import FinancialStructure from './FinancialStructure';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setIsOpenModalGenerateInvoice } from '../../../../redux/reducers/activity-report/sliceReducer';

const ModalGenerateInvoice: FC = () => {
  const { isOpenModalGenerateInvoice } = useAppSelector(
    (state) => state.activityReport,
  );
  const onHide = (): void => {
    dispatch(setIsOpenModalGenerateInvoice(false));
  };
  const dispatch = useAppDispatch();
  const tabElement = (
    <Tabs
      defaultActiveKey="1"
      style={{ marginTop: 30 }}
      items={[
        {
          label: `Timesheets and expenses`,
          key: '1',
          children: <TimesheetsAndExpenses />,
        },
        {
          label: `Steps`,
          key: '2',
          children: <Steps />,
        },
        {
          label: `Financial structure`,
          key: '3',
          children: <FinancialStructure />,
        },
      ]}
    />
  );

  return (
    <Modal
      width="90%"
      title={`Activity report #${5689}`}
      open={isOpenModalGenerateInvoice}
      onCancel={onHide}
      centered
      footer={null}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper activity-report-modal-generate-invoice">
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Period</span>
            <span className="item-value">01 Aug 2022 - 31 Aug 2022</span>
          </div>
        </Col>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Consultant</span>
            <span className="item-value">Christina Thompson (ID 123456)</span>
          </div>
        </Col>
        <Col span={3}>
          <div className="personal-info">
            <span className="item-label">Client</span>
            <span className="item-value">Huspi</span>
          </div>
        </Col>
        <Col span={3}>
          <div className="personal-info">
            <span className="item-label">Agency</span>
            <span className="item-value">NLG Agency</span>
          </div>
        </Col>
        <Col span={8}>
          <Button buttonWidth="100%" buttonStyle="primary" buttonSize="normal">
            Generate invoice
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Currency payment</span>
            <span className="item-value">CFH</span>
          </div>
        </Col>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Consultant start date</span>
            <span className="item-value">01 Sep 2019</span>
          </div>
        </Col>
        <Col span={3}>
          <div className="personal-info">
            <span className="item-label">Client start date</span>
            <span className="item-value">01 Sep 2019</span>
          </div>
        </Col>
        <Col span={3}>
          <div className="personal-info">
            <span className="item-label">Seller</span>
            <span className="item-value">Mike Mansell</span>
          </div>
        </Col>
        <Col span={4}>
          <Button
            buttonWidth="100%"
            buttonStyle="secondary"
            buttonSize="normal">
            View invoices
          </Button>
        </Col>
        <Col span={4}>
          <Button
            buttonWidth="100%"
            buttonStyle="secondary"
            buttonSize="normal">
            View contract
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Cashbox</span>
            <span className="item-value">ASAP</span>
          </div>
        </Col>
        <Col span={5}>
          <div className="personal-info">
            <span className="item-label">Consultant end date</span>
            <span className="item-value">01 Sep 2019</span>
          </div>
        </Col>
        <Col span={3}>
          <div className="personal-info">
            <span className="item-label">Client end date</span>
            <span className="item-value">01 Sep 2019</span>
          </div>
        </Col>
      </Row>

      {tabElement}
    </Modal>
  );
};

export default ModalGenerateInvoice;
