import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const AngleIcon: FC<IPassProps> = (props) => (
  <svg
    {...passProps(props)}
    viewBox="0 0 16 8"
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <path
      d="M1 7L7.21905 1.66939C7.66844 1.2842 8.33156 1.2842 8.78095 1.66939L15 7"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default AngleIcon;
