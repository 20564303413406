import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  workPermitList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
  selectedWorkPermits: [],
};

const reducer = createSlice({
  name: 'work-permit',
  initialState,
  reducers: {
    setWorkPermitList(state, action: PayloadAction<IGetListDto<any>>) {
      state.workPermitList.content = action.payload.result;
      state.workPermitList.pagination.totalElement = action.payload.total;
    },
    changeWorkPermitFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.workPermitList.filter = {
        ...state.workPermitList.filter,
        ...action.payload,
      };
      state.workPermitList.pagination.currentPage = 1;
    },
    setWorkPermitSorting(state, action: PayloadAction<any>) {
      state.workPermitList.sort.sortBy = action.payload[0];
      state.workPermitList.sort.sort = action.payload[1];
    },
    clearWorkPermitFiltersAndSorts(state) {
      state.workPermitList.sort = initialState.workPermitList.sort;
      state.workPermitList.filter = initialState.workPermitList.filter;
    },
    setWorkPermitPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.workPermitList.pagination.currentPage = action.payload.currentPage;
      state.workPermitList.pagination.perPage = action.payload.perPage;
    },
    selectWorkPermitItem(state, action: PayloadAction<string[]>) {
      state.selectedWorkPermits = action.payload;
    },
  },
});

export const {
  setWorkPermitList,
  changeWorkPermitFiltering,
  setWorkPermitSorting,
  clearWorkPermitFiltersAndSorts,
  setWorkPermitPagination,
  selectWorkPermitItem,
} = reducer.actions;

export default reducer.reducer;
