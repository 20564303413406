import { SalaryType } from '../api/services/OnboardingApi/types/IWorkContractRequest';

export const createTimesheetsTemplateName = (
  type: SalaryType,
  overtimeAllowed: boolean,
  doubleTimeAllowed: boolean,
) => {
  if (type === SalaryType.PerDay || type === SalaryType.PerHour) {
    if (type === SalaryType.PerHour) {
      return overtimeAllowed && doubleTimeAllowed
        ? 'Numeriq AG Timesheet ovt dblt HOURS.xlsx'
        : 'Numeriq AG Timesheet Hours.xlsx';
    }
    if (type === SalaryType.PerDay) {
      return overtimeAllowed && doubleTimeAllowed
        ? 'Numeriq AG Timesheet ovt dblt DAYS.xlsx'
        : 'Numeriq AG Timesheet Days.xlsx';
    }
  }
  return '';
};
