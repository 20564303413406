import { FC, useEffect } from 'react';
import CopyLinkSection from '../../../components/copy-link-section/CopyLinkSection';
import { useAppDispatch } from '../../../redux/store';
import { clearEmployeeInviteLink } from '../../../redux/reducers/employees/sliceReducer';

interface IProps {
  invitedUrl: string;
  email: string;
}

const ModalDuplicateInviteContent: FC<IProps> = ({ invitedUrl, email }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearEmployeeInviteLink());
    };
  }, []);

  return (
    <>
      <p>
        The invitation link was successfully sent to <span>{email}</span>
      </p>
      <p>You can also copy the link bellow</p>

      <CopyLinkSection link={invitedUrl} />
    </>
  );
};

export default ModalDuplicateInviteContent;
