export enum OnboardingRoutes {
  draft = 'draft',
  onboarding = 'onboarding',
  workPermit = 'work-permit',
  workContract = 'work-contract',
  clientContract = 'client-contract',
  bankAccount = 'bank-account',
  childAllowances = 'child-allowances',
  additionalInformation = 'additional-information',
  taxAtSource = 'tax-at-source',
}
