import { ISort } from '../../../../redux/types';
import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';
import { IConsultantPayslip } from './types/IConsultantPayslips';

class ConsultantPayslipsApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/payslip`;

  getConsultantPayslips(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<IConsultantPayslip>>('/consultant', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    });
  }
  updatePaylipsDate(body: {
    paymentDate: string | null;
    payslipsPayload: Array<{ consultantId: string; period: string }>;
  }) {
    return this.patch('/payment-date', body);
  }
}

export const consultantPayslipsApi = new ConsultantPayslipsApi();
