import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import { timesheetStatusParser } from '../../common/constants/timesheetStatusParser';
import { useState } from 'react';
import MobileFilter from './components/MobileFilter';
import Button from '../button/Button';
import { Button as AntdButton } from 'antd';
import MobileSorting from './components/MobileSorting';
import { ISort } from '../../redux/types';

interface IProps {
  columns: any;
  data: any[];
  paginationTotalElement?: number;
  onChangePagination?: (page: number, size: number) => void;
  paginationPageSize?: number;
  paginationCurrentPage?: number;
  submitFilters?: any;
  sortBy?: ISort;
  onChangeMobileSort?: (sort: ISort) => void;
}

const TableMobile = (props: IProps) => {
  const {
    columns,
    data,
    paginationCurrentPage,
    onChangePagination,
    paginationPageSize,
    paginationTotalElement,
    submitFilters,
    sortBy,
    onChangeMobileSort,
  } = props;
  const [isOpenFiltering, setIsOpenFiltering] = useState(false);
  const [isOpenSorting, setIsOpenSorting] = useState(false);
  const actionRow = columns.find(
    (column: any) => column.dataIndex === 'action',
  );

  const isLastPage =
    paginationCurrentPage &&
    paginationPageSize &&
    paginationTotalElement &&
    paginationCurrentPage * paginationPageSize < paginationTotalElement;

  if (!data?.length) {
    return (
      <div className="no-data-mobile-container">
        <div className="mobile-filters-container">
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => setIsOpenSorting(true)}>
            Sorting
          </Button>
          <Button
            onClick={() => setIsOpenFiltering(true)}
            buttonSize="normal"
            buttonStyle="primary">
            Filters
          </Button>
        </div>
        <DatabaseOutlined className="no-data-icon-mobile" />
        <h4 style={{ fontSize: '20px' }}>No data</h4>
        {isOpenFiltering && columns && (
          <MobileFilter
            filters={columns}
            submitFilters={submitFilters}
            setIsOpenFilter={setIsOpenFiltering}
          />
        )}
        {isOpenSorting && columns && (
          <MobileSorting
            filters={columns}
            sortBy={sortBy}
            onChangeMobileSort={onChangeMobileSort}
            setIsOpenSorting={setIsOpenSorting}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {isOpenFiltering && columns && (
        <MobileFilter
          filters={columns}
          submitFilters={submitFilters}
          setIsOpenFilter={setIsOpenFiltering}
        />
      )}
      {isOpenSorting && columns && (
        <MobileSorting
          filters={columns}
          sortBy={sortBy}
          onChangeMobileSort={onChangeMobileSort}
          setIsOpenSorting={setIsOpenSorting}
        />
      )}
      <div className="table-mobile">
        <div className="mobile-filters-container">
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={() => setIsOpenSorting(true)}>
            Sorting
          </Button>
          <Button
            onClick={() => setIsOpenFiltering(true)}
            buttonSize="normal"
            buttonStyle="primary">
            Filters
          </Button>
        </div>
        {data?.length &&
          data.map((item) => {
            return (
              <div key={item.key} className="table-mobile-block">
                {Object.keys(item).map((value) => {
                  const isHasValue = columns.find(
                    (column: any) => column.dataIndex === value,
                  );

                  if (!isHasValue) {
                    return null;
                  }
                  if (typeof item[value] !== 'string' && isHasValue.render) {
                    return (
                      <div className="table-mobile-item" key={value}>
                        <span className="table-mobile-title">
                          {isHasValue?.title || ''}
                        </span>
                        <span>{isHasValue.render(false, item)}</span>
                      </div>
                    );
                  }
                  return (
                    <div key={value} className="table-mobile-item">
                      <span className="table-mobile-title">
                        {isHasValue?.title || ''}
                      </span>
                      {isHasValue && (
                        <span
                          className={`table-mobile-value ${
                            value === 'status'
                              ? timesheetStatusParser[item[value]]
                              : ''
                          }`}
                          style={{
                            fontWeight: value === 'status' ? 'bold' : 'normal',
                          }}>
                          {item[value]}
                        </span>
                      )}
                    </div>
                  );
                })}
                {actionRow && (
                  <div className="table-mobile-item">
                    <span className="table-mobile-title">Action</span>
                    <div>{actionRow.render(false, item)}</div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="mobile-pagination">
          {paginationCurrentPage !== 1 && onChangePagination ? (
            <AntdButton
              onClick={() =>
                onChangePagination(
                  paginationCurrentPage ? paginationCurrentPage - 1 : 1,
                  paginationPageSize || 10,
                )
              }
              type="primary"
              style={{ width: '100px' }}>
              <ArrowLeftOutlined style={{ fontSize: '20px' }} />
            </AntdButton>
          ) : (
            <div style={{ width: '100px' }}></div>
          )}
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {paginationCurrentPage || ''}
          </span>
          {onChangePagination && isLastPage ? (
            <AntdButton
              type="primary"
              style={{ width: '100px' }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              onClick={() =>
                onChangePagination(
                  paginationCurrentPage ? paginationCurrentPage + 1 : 1,
                  paginationPageSize || 10,
                )
              }>
              <ArrowRightOutlined style={{ fontSize: '20px' }} />
            </AntdButton>
          ) : (
            <div style={{ width: '100px' }}></div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableMobile;
