import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
  updateOnboardingAdditionalInfo,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import {
  IAdditionalInfoItem,
  IAdditionalInfoRequest,
} from '../../../../common/api/services/OnboardingApi/types/IAdditionalInfoRequest';
import { IOnboardingSaveOptions } from './../types';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftAdditionalInfoAsEmployee = createAsyncThunk(
  'onboarding/saveDraftAdditionalInfo',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { additionalInfo, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[7] === OnboardingRequestStatuses.draft ||
      tabStatus[7] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IAdditionalInfoRequest> = {
      key: OnboardingRequestKeys.additionalInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: additionalInfo,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putAdditionalInfoAsEmployee(
          consultantId,
          dataQuery,
        );

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'additionalInfo',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveDraftAdditionalInfoAsConsultant = createAsyncThunk(
  'onboarding/saveDraftAdditionalInfoAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { additionalInfo, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[7] === OnboardingRequestStatuses.draft ||
      tabStatus[7] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<IAdditionalInfoRequest> = {
      key: OnboardingRequestKeys.additionalInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: additionalInfo,
    };

    if (isDraft) {
      const { isSuccess, data } =
        await onboardingApi.putAdditionalInfoAsConsultant(dataQuery);
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 7,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'additionalInfo',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const savePublishAdditionalInfoAsEmployee = createAsyncThunk(
  'onboarding/savePublishAdditionalInfo',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { additionalInfo, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;

    const dataQuery: IQueryParameters<IAdditionalInfoRequest> = {
      key: OnboardingRequestKeys.additionalInformation,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: additionalInfo,
    };

    const { isSuccess, data } = await onboardingApi.putAdditionalInfoAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.published,
          isAdmin: true,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'additionalInfo',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishAdditionalInfoAsConsultant = createAsyncThunk(
  'onboarding/savePublishAdditionalInfoAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { additionalInfo, tabStatus } = (getState() as IRootState).onboarding;

    const dataQuery: IQueryParameters<IAdditionalInfoRequest> = {
      key: OnboardingRequestKeys.additionalInformation,
      status: OnboardingRequestStatuses.published,
      type: OnboardingRequestTypes.required,
      data: additionalInfo,
    };

    const { isSuccess, data } =
      await onboardingApi.putAdditionalInfoAsConsultant(dataQuery);
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 7,
          value: OnboardingRequestStatuses.published,
        }),
      );
      dispatch(push(getOnboardingRedirect(tabStatus, 7, redirectRootRout)));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'additionalInfo',
          fieldValue: data,
        }),
      );
    }
  },
);

export const addNewContactToAdditionalInfo = createAsyncThunk(
  'onboarding/addNewContactToAdditionalInfo',
  async (_, { getState, dispatch }) => {
    const { consultantId } = (getState() as IRootState).onboarding;
    const { contacts: currentContacts } = (getState() as IRootState).onboarding
      .additionalInfo;

    const response =
      await onboardingApi.addNewContactToAdditionalInfoAsEmployee(consultantId);

    if (response) {
      const newContacts =
        response.consultantOnboarding?.additionalInformation?.contacts ?? [];

      if (newContacts.length > 0) {
        const contacts = newContacts
          .map(
            ({
              _id,
              phone,
              firstName,
              email,
              lastName,
              relationshipStatus,
            }) => {
              const hasContact = !!currentContacts.find(
                (item) => item._id === _id,
              );

              if (hasContact) return null;

              return {
                _id,
                phone: phone ?? '',
                firstName: firstName ?? '',
                email: email ?? '',
                lastName: lastName ?? '',
                relationshipStatus: relationshipStatus ?? '',
              };
            },
          )
          .filter(Boolean) as Required<IAdditionalInfoItem>[];

        dispatch(
          updateOnboardingAdditionalInfo({
            contacts: [...currentContacts, ...contacts],
          }),
        );
      }
    }
  },
);

export const addNewContactToAdditionalInfoAsConsultant = createAsyncThunk(
  'onboarding/addNewContactToAdditionalInfoAsConsultant',
  async (_, { getState, dispatch }) => {
    const { contacts: currentContacts } = (getState() as IRootState).onboarding
      .additionalInfo;

    const response =
      await onboardingApi.addNewContactToAdditionalInfoAsConsultant();

    if (response) {
      const newContacts =
        response.consultantOnboarding?.additionalInformation?.contacts ?? [];

      if (newContacts.length > 0) {
        const contacts = newContacts
          .map(
            ({
              _id,
              phone,
              firstName,
              email,
              lastName,
              relationshipStatus,
            }) => {
              const hasContact = !!currentContacts.find(
                (item) => item._id === _id,
              );

              if (hasContact) return null;

              return {
                _id,
                phone: phone ?? '',
                firstName: firstName ?? '',
                email: email ?? '',
                lastName: lastName ?? '',
                relationshipStatus: relationshipStatus ?? '',
              };
            },
          )
          .filter(Boolean) as Required<IAdditionalInfoItem>[];

        dispatch(
          updateOnboardingAdditionalInfo({
            contacts: [...currentContacts, ...contacts],
          }),
        );
      }
    }
  },
);

export const deleteContactInAdditionalInfo = createAsyncThunk(
  'onboarding/deleteContactInAdditionalInfo',
  async (contactId: string, { getState, dispatch }) => {
    const { consultantId } = (getState() as IRootState).onboarding;
    const { contacts } = (getState() as IRootState).onboarding.additionalInfo;

    const response =
      await onboardingApi.deleteContactInAdditionalInfoAsEmployee(
        consultantId,
        contactId,
      );

    if (response) {
      const newContacts = contacts.filter((el) => el._id !== contactId);
      dispatch(updateOnboardingAdditionalInfo({ contacts: newContacts }));
    }
  },
);

export const deleteContactInAdditionalInfoAsConsultant = createAsyncThunk(
  'onboarding/deleteContactInAdditionalInfoAsConsultant',
  async (contactId: string, { getState, dispatch }) => {
    const { contacts } = (getState() as IRootState).onboarding.additionalInfo;

    const response =
      await onboardingApi.deleteContactInAdditionalInfoAsConsultant(contactId);

    if (response) {
      const newContacts = contacts.filter((el) => el._id !== contactId);
      dispatch(updateOnboardingAdditionalInfo({ contacts: newContacts }));
    }
  },
);
