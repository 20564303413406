export enum MonitoringStatuses {
  follow = 'to follow',
  action = 'to action',
  optimal = 'optimal',
  ok = 'ok',
  finished = 'finished',
  wlabroad = 'wl abroad',
  no_permit = 'no permit',
  ongoing = 'ongoing',
  partly_ongoing = 'partly ongoing',
}
