import InfoIcon from '../../assets/icons/InfoIcon';
import './InfoMessages.less';

interface IProps {
  message: string;
}

const InfoMessage = (props: IProps) => {
  const { message } = props;
  return (
    <div className="information-message">
      <div className="icon-wrapper">
        <InfoIcon />
      </div>
      <p>{message}</p>
    </div>
  );
};

export default InfoMessage;
