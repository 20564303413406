import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './FormatPhoneNumber.less';

interface IProps {
  number?: string;
}
const FormatPhoneNumber = (props: IProps) => {
  const { number } = props;
  if (!number) {
    return <span></span>;
  }
  return (
    <PhoneInput
      value={number}
      onChange={() => false}
      disabled
      countryCodeEditable={false}
      containerClass="phone-format-table"
    />
  );
};

export default FormatPhoneNumber;
