import { Menu, Modal } from 'antd';
import CommonTable from '../../../components/common-table/CommonTable';
import TrashIcon from '../../../assets/icons/TrashIcon';
import SimpleDropdown from '../../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../../assets/icons/EditActionIcon';
import { useState } from 'react';
import AttentionModal from '../../../components/attention-modal/AttentionModal';
import { DeleteInvoiceText } from '../../../components/common-texts/CommonTexts';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import {
  TTimesheetStatusParserKey,
  TViewInvoiceStatusParserKey,
  timesheetStatusParser,
  viewIncoiceStatusParser,
} from '../../../common/constants/timesheetStatusParser';
import '../../../components/common-table/CommonTable.less';
import { TableColorStatusParser } from '../../../components/common-table/common/tableColorConstants';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  invoices: any;
  getPayloadInfo: () => void;
}

const ViewInvoicesModal = (props: IProps) => {
  const { closeModal, isOpen, invoices, getPayloadInfo } = props;

  const [selectedDeleteItem, setSelectedDeleteItem] = useState('');

  const data = invoices.map((item: any) => {
    return {
      publicId: item.publicId,
      status: item.status,
      currency: item.totalCalculations.currency,
      amount: item.totalCalculations.total,
      _id: item._id,
      internalId: item.internalId,
    };
  });

  const columns = [
    {
      title: 'Invoice number',
      dataIndex: 'publicId',
      key: 'publicId',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: `status-td`,
      key: 'status',
      width: 150,
      render: (text: TViewInvoiceStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={TableColorStatusParser[text]}>{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: 150,
    },
    {
      title: 'Total amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (item: any, data: any) => {
        return (
          <div style={{ display: 'flex' }}>
            <SimpleDropdown
              options={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <span
                          onClick={() =>
                            window.open(
                              `generate-invoice/${data.internalId}`,
                              '_blank',
                            )
                          }>
                          Edit
                        </span>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <span
                          style={{ color: '#cc554d' }}
                          onClick={() =>
                            setSelectedDeleteItem(data.internalId)
                          }>
                          Delete
                        </span>
                      ),
                    },
                  ]}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
    },
  ];

  const closeDelete = () => {
    setSelectedDeleteItem('');
  };

  const onDelete = () => {
    invoiceApi
      .deleteInvoice(selectedDeleteItem)
      .then(() => {
        setSelectedDeleteItem('');
        openSuccessNotification('Invoice was deleted successfully');
        getPayloadInfo();
        closeModal();
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message || 'Failed to delete invoice',
        );
      });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      footer={null}
      centered
      width={1000}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div style={{ paddingTop: '30px' }}>
        <h4 style={{ marginBottom: '1rem' }}>View invoices</h4>
        <CommonTable
          paginationCurrentPage={1}
          paginationPageSize={10}
          paginationOnChange={() => null}
          paginationTotalElement={10}
          data={data}
          columns={columns}
          onChange={() => null}
          hidePagination
          tableHeight={300}
          height={300}
        />
      </div>
      {selectedDeleteItem && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeDelete}
          confirmAction={onDelete}
          confirmBtnTitle="Yes, delete"
          content={<DeleteInvoiceText />}
          isOpen={!!selectedDeleteItem}
          isHideAttentionText
        />
      )}
    </Modal>
  );
};

export default ViewInvoicesModal;
