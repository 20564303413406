import { LoadingOutlined } from '@ant-design/icons';
import LogoIcon from '../../assets/images/logo.png';
import './MobileLoader.less';

const MobileLoader = () => {
  return (
    <>
      {window.screen.width <= 768 ? (
        <div className="mobile-loader-container">
          <img
            src={LogoIcon}
            alt=""
            className="rotate-on-axis"
            style={{
              width: '180px',
              height: '150px',
              backgroundColor: '#fff',
            }}
          />
        </div>
      ) : (
        <LoadingOutlined
          style={{ fontSize: 24, position: 'absolute', top: '50%' }}
          spin
        />
      )}
    </>
  );
};
export default MobileLoader;
