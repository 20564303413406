import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const LaterIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0916 9.35496C9.74686 9.03371 9.20961 9.04319 8.8764 9.3764C8.54319 9.70961 8.53371 10.2469 8.85496 10.5916L13.265 15.0016V20.4733C13.265 20.9565 13.6567 21.3483 14.14 21.3483C14.6232 21.3483 15.015 20.9565 15.015 20.4733V14.64C15.0148 14.408 14.9224 14.1856 14.7583 14.0216L10.0916 9.35496Z"
        fill="#0671E0"
      />
      <rect
        x="2.33301"
        y="2.83325"
        width="23.3333"
        height="23.3333"
        rx="5.83333"
        stroke="#0671E0"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LaterIcon;
