import ILabelInValue from '../types/ILabelInValue';

export const expensesType: Record<string, string> = {
  EC: 'Reimbursed by the Client',
  EO: 'Expenses for optimization',
  MA: 'Meal Allowance expenses',
};

export const mainActivitiesTypes: Record<string, string> = {
  EC: 'Reimbursed by the Client',
  EO: 'Expenses for optimization',
  MA: 'Meal Allowance expenses',
  T: 'Timesheet',
};

export const optionTypes: ILabelInValue[] = Object.keys(expensesType).map(
  (key) => ({
    value: key,
    label: expensesType[key],
  }),
);
export const optionMainActivityTypes: ILabelInValue[] = Object.keys(
  mainActivitiesTypes,
).map((key) => ({
  value: key,
  label: mainActivitiesTypes[key],
}));

export const activityReportTypes: Record<string, string> = {
  EC: 'Reimbursed by the Client',
  EO: 'Expenses for optimization',
  Timesheet: 'Timesheet',
};

export const activityReportOptionTypes: ILabelInValue[] = Object.keys(
  activityReportTypes,
).map((key) => ({
  value: key,
  label: activityReportTypes[key],
}));
