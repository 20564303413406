import { createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../../common/api/services/UserApi/userApi';
import { IPaginationRequest, IRootState } from '../../types';
import {
  putEmployeeList,
  setEmployeeInviteData,
  setEmployeeSorting,
  setEmployeesPagination,
  setErrorMessages,
  updateEmployeeInviteLoading,
  updateOpenModalInvite,
  updateOpenModalNewEmployee,
} from './sliceReducer';
import { batch } from 'react-redux';
import { ICreateEmployeeRequest } from '../../../common/api/services/UserApi/types/ICreateEmployeeRequest';
import { IInviteEmployeeResponse } from '../../../common/api/services/UserApi/types/IInviteEmployeeResponse';
import { openErrorNotification } from '../../../components/notification/Notification';

export const getEmployees = createAsyncThunk(
  'employees/getEmployees',
  async (
    pagination: IPaginationRequest | undefined,
    { getState, dispatch },
  ) => {
    const currentPagination = (getState() as IRootState).employees.employeesList
      .pagination;
    const currentFilters = (getState() as IRootState).employees.employeesList
      .filter;
    const currentSorts = (getState() as IRootState).employees.employeesList
      .sort;

    const page = pagination?.currentPage
      ? pagination.currentPage
      : currentPagination.currentPage;

    const perPage = pagination?.perPage
      ? pagination.perPage
      : currentPagination.perPage;

    const response = await userApi.getEmployeesList(
      page,
      perPage,
      currentFilters,
      currentSorts,
    );

    if (response) {
      batch(() => {
        dispatch(putEmployeeList(response));
        dispatch(setEmployeesPagination({ currentPage: page, perPage }));
      });
    }
  },
);

export const createEmployee = createAsyncThunk(
  'employees/createEmployee',
  async (query: ICreateEmployeeRequest, { dispatch }) => {
    dispatch(updateEmployeeInviteLoading(true));
    const { isSuccess, data } = await userApi.createEmployee(query);

    if (isSuccess) {
      dispatch(getEmployees({ currentPage: 0 }));
      dispatch(setEmployeeInviteData(data as IInviteEmployeeResponse));
      dispatch(updateOpenModalNewEmployee(false));
      dispatch(updateOpenModalInvite(true));
    } else {
      if (typeof data !== 'string') {
        dispatch(setErrorMessages(data as Record<string, string>));
      } else {
        //TODO
        openErrorNotification(data);
      }
    }

    dispatch(updateEmployeeInviteLoading(false));
  },
);

export const resendInviteEmployee = createAsyncThunk(
  'employees/resendInviteEmployee',
  async (id: string, { dispatch }) => {
    const { isSuccess, data } = await userApi.resendEmployee(id);

    if (isSuccess) {
      dispatch(setEmployeeInviteData(data as IInviteEmployeeResponse));
    }
  },
);

export const changeEmployeeSorting = createAsyncThunk(
  'waitingRoom/changeEmployeeSorting',
  (currentSortBy: string, { dispatch, getState }) => {
    const { sort, sortBy } = (getState() as IRootState).employees.employeesList
      .sort;

    if (currentSortBy !== sortBy) {
      dispatch(setEmployeeSorting([currentSortBy, 'asc']));
      dispatch(getEmployees({ currentPage: 1 }));
      return;
    }

    if (sort === 'asc' && currentSortBy === sortBy) {
      dispatch(setEmployeeSorting([currentSortBy, 'desc']));
      dispatch(getEmployees({ currentPage: 1 }));
      return;
    }

    if (sort === 'desc' && currentSortBy === sortBy) {
      dispatch(setEmployeeSorting(['', '']));
      dispatch(getEmployees({ currentPage: 1 }));
      return;
    }
  },
);
