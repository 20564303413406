import { IRouterPaths } from './types';

export const ROUTER_PATHS: Readonly<IRouterPaths> = {
  login: '/login',
  edit: '/edit',
  view: '/view',
  expired: '/expired',
  approved: '/approved',
  rejected: '/rejected',
  setupNewPassword: '/setup-new-password',
  confirmLeadPassword: '/confirm-lead-password',
  confirmEmployeePassword: '/confirm-employee-password',
  home: '/',
  timesheets: '/timesheets',
  expenses: '/expenses',
  activityReport: '/activity-report',
  reporting: '/reporting',
  invoices: '/invoices',
  employees: '/employees',
  consultants: '/consultants',
  waitingRoom: '/waiting-room',
  monitoring: '/monitoring',
  onboarding: '/onboarding',
  personalInformation: '/personal-information',
  workPermit: '/work-permit-report',
  workContract: '/work-contract',
  clientContract: '/client-contract',
  bankAccount: '/bank-account',
  childAllowance: '/child-allowance',
  avsAhv: '/avs-ahv',
  additionalInformation: '/additional-information',
  payslips: '/payslips',
  documents: '/documents',
  myInformation: '/my-information',
  clients: '/clients',
  modify: '/modify',
  consultantPayslips: '/consultant-payslips',
  employeePayslips: '/employee-payslips',
  viewPayslipsPage: '/view-payslips',
  logs: '/logs',
  activityReportInfo: '/activity-report-info',
  generateInvoice: '/generate-invoice',
  systemInfo: '/system-info',
  mainActivities: '/main-activities',
  taxAtSource: '/tax-at-source',
};
