import { FC } from 'react';
import { Layout } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import BellIcon from '../../assets/icons/BellIcon';
import LogoutBtn from './conponents/logout-btn/LogoutBtn';

import './Header.less';
import TimesheetsIcon from '../../assets/icons/TimesheetsIcon';
import ExpensesIcon from '../../assets/icons/ExpensesIcon';
import ActivityReportIcon from '../../assets/icons/ActivityReportIcon';
import ReportingIcon from '../../assets/icons/ReportingIcon';
import InvoicesIcon from '../../assets/icons/InvoicesIcon';
import PeopleIcon from '../../assets/icons/PeopleIcon';
import WorkPermitTrackingIcon from '../../assets/icons/WorkPermitTrackingIcon';
import { useAppSelector } from '../../redux/store';
import MyInfoIcon from '../../assets/icons/MyInfoIcon';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import PayslipsIcon from '../../assets/icons/PayslipsIcon';
import SystemInfoIcon from '../../assets/icons/SystemInfoIcon';
import { CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

interface IProps {
  isOpenNavigation?: boolean;
  setIsOpenNavigation?: (isOpen: boolean) => void;
}

const { Header: AntHeader } = Layout;

const {
  timesheets,
  activityReport,
  reporting,
  employees,
  invoices,
  expenses,
  consultants,
  waitingRoom,
  monitoring,
  home,
  payslips,
  documents,
  myInformation,
  clients,
  employeePayslips,
  consultantPayslips,
  activityReportInfo,
  generateInvoice,
  systemInfo,
  mainActivities,
  workPermit,
} = ROUTER_PATHS;

const Header = (props: IProps) => {
  const { isOpenNavigation, setIsOpenNavigation } = props;
  const { firstName, lastName, email } = useAppSelector((state) => state.auth);

  return (
    <AntHeader className="main-header">
      <Routes>
        <Route path={home} element={<Navigate to={timesheets} />} />
        <Route
          path={timesheets}
          element={
            <h4>
              <TimesheetsIcon className="time-icon" />
              Timesheets
            </h4>
          }
        />
        <Route
          path={activityReport}
          element={
            <h4>
              <ActivityReportIcon className="report-icon" />
              Activity Report
            </h4>
          }
        />
        <Route
          path={workPermit}
          element={
            <h4>
              <DocumentIcon className="document-icon" />
              Work Permit Report
            </h4>
          }
        />
        <Route
          path={generateInvoice + '/*'}
          element={
            <h4>
              <ActivityReportIcon className="report-icon" />
              Generate invoice
            </h4>
          }
        />
        <Route
          path={activityReportInfo}
          element={
            <h4>
              <ActivityReportIcon className="report-icon" />
              Activity Report
            </h4>
          }
        />
        <Route
          path={mainActivities}
          element={
            <h4>
              <ActivityReportIcon className="report-icon" />
              Main activities
            </h4>
          }
        />
        <Route
          path={reporting}
          element={
            <h4>
              <ReportingIcon className="report-icon" />
              Reporting
            </h4>
          }
        />
        <Route
          path={systemInfo}
          element={
            <h4>
              <SystemInfoIcon className="system-info-icon" />
              System info
            </h4>
          }
        />
        <Route
          path={employees}
          element={
            <h4>
              <PeopleIcon className="people-icon" />
              Employees
            </h4>
          }
        />
        <Route
          path={invoices}
          element={
            <h4>
              <InvoicesIcon className="invoice-icon" />
              Invoices
            </h4>
          }
        />
        <Route
          path={expenses}
          element={
            <h4>
              <ExpensesIcon className="expenses-icon" />
              Expenses
            </h4>
          }
        />
        <Route
          path={consultants}
          element={
            <h4>
              <PeopleIcon className="people-icon" />
              Consultants
            </h4>
          }
        />
        <Route
          path={waitingRoom + '/*'}
          element={
            <h4>
              <PeopleIcon className="people-icon" />
              Waiting Room
            </h4>
          }
        />
        <Route
          path={clients + '/*'}
          element={
            <h4>
              <PeopleIcon className="people-icon" />
              Clients
            </h4>
          }
        />
        <Route
          path={monitoring}
          element={
            <h4>
              <WorkPermitTrackingIcon className="permit-icon" />
              Admin Report
            </h4>
          }
        />

        <Route
          path={payslips}
          element={
            <h4>
              <PayslipsIcon className="payslips-icon" />
              Payslips
            </h4>
          }
        />

        <Route
          path={documents}
          element={
            <h4>
              <DocumentIcon className="document-icon" />
              Documents
            </h4>
          }
        />

        <Route
          path={myInformation + '/*'}
          element={
            <h4>
              <MyInfoIcon className="my-info-icon" />
              My information
            </h4>
          }
        />

        <Route
          path={employeePayslips}
          element={
            <h4>
              <PayslipsIcon className="payslips-icon" />
              Payslips
            </h4>
          }
        />
        <Route
          path={consultantPayslips}
          element={
            <h4>
              <PayslipsIcon className="payslips-icon" />
              Payslips
            </h4>
          }
        />

        <Route path={'*'} element={<div></div>} />
      </Routes>
      {setIsOpenNavigation && (
        <div className="mobile-menu">
          {isOpenNavigation ? (
            <CloseOutlined onClick={() => setIsOpenNavigation(false)} />
          ) : (
            <MenuUnfoldOutlined onClick={() => setIsOpenNavigation(true)} />
          )}
        </div>
      )}

      <div className="management-section">
        <div className="notification-icon-wrapper">
          <BellIcon />
        </div>
        <div className="image-wrapper">
          {/*<img src={mockImg} alt="user preview" />*/}
        </div>
        <div className="user-details">
          <span className="user-details-name">
            {firstName} {lastName}
          </span>
          <span className="user-details-email">{email}</span>
        </div>

        <LogoutBtn />
      </div>
    </AntHeader>
  );
};

export default Header;
