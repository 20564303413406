export const getError = (fieldName: string, errorMessages: any): string => {
  if (errorMessages) {
    return (
      errorMessages.find((item: any) => item.property === fieldName)?.message ||
      ''
    );
  }

  return '';
};
