import { FC } from 'react';
import CommonTable from '../../../../components/common-table/CommonTable';
import { ColumnsType } from 'antd/es/table/interface';
import { Col, Menu, Row } from 'antd';
import EditIcon from '../../../../assets/icons/EditIcon';
import Button from '../../../../components/button/Button';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import SimpleDropdown from '../../../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../../../assets/icons/EditActionIcon';
import { TExpensesStatusParserKey } from '../../../../common/constants/expensesStatusParser';

const Steps: FC = () => {
  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'treasury',
      key: 'treasury',
    },
    {
      title: 'Step',
      dataIndex: 'advancedTiming',
      key: 'advancedTiming',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'status-td',
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={text}>{text}</span>
          </div>
        );
      },
    },
    {
      title: 'State',
      dataIndex: 'billRateType',
      key: 'billRateType',
    },
    {
      title: 'Requirement',
      dataIndex: 'billRate',
      key: 'billRate',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'edit-action-td',
      width: 80,
      render: () => {
        return (
          <SimpleDropdown
            options={
              <Menu
                items={[
                  {
                    key: '1',
                    label: <span>Edit</span>,
                  },
                  {
                    key: '3',
                    label: <span style={{ color: '#C33025' }}>Delete</span>,
                  },
                ]}
              />
            }>
            <EditActionIcon className="edit-action-icon" />
          </SimpleDropdown>
        );
      },
    },
  ];

  return (
    <div className="activity-report-page-staps-tab">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <span className="edit-mode-btn">
            <EditIcon />
            Edit mode
          </span>
        </Col>
        <Col>
          <Button
            buttonSize="small"
            buttonStyle="secondary"
            leftIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
            New stap
          </Button>
        </Col>
      </Row>
      <CommonTable data={[]} columns={columns} />
    </div>
  );
};

export default Steps;
