import { AxiosError } from 'axios';

const defaultError = { message: 'ERROR' };

export const parsErrorMessage = (error: AxiosError<any>): string => {
  const { message, messages } = error.response?.data;
  const errorMessage = message || messages;

  if (Array.isArray(errorMessage)) {
    const [error = defaultError] = errorMessage;

    return error.message;
  }

  return errorMessage;
};
