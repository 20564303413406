import { Spin, Upload } from 'antd';
import '../../GenerateInvoicePage.less';
import DropzoneIcon from '../../../../assets/icons/DropzoneIcon';
import { useEffect, useState } from 'react';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { IInvoiceFile } from '../../../../common/api/services/InvoiceApi/types/types';
import AttachFileIcon from '../../../../assets/icons/AttachFileIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import { fileApi } from '../../../../common/api/services/FileApi/FileApi';
import { invoiceApi } from '../../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../../components/notification/Notification';
import { useParams } from 'react-router-dom';
import AttentionModal from '../../../../components/attention-modal/AttentionModal';
import { DeleteFileText } from '../../../../components/common-texts/CommonTexts';
import { downloadFileByUrl } from '../../../../common/utils/downloadFileByUrl';

interface IProps {
  publicId: string | undefined;
  activeKey: string;
}

const AttachmentsContent = (props: IProps) => {
  const { publicId, activeKey } = props;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [attachments, setAttachments] = useState<Array<IInvoiceFile>>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeKey === '2') {
      getAttachments();
    }
  }, [activeKey]);

  const getAttachments = () => {
    if (invoiceId) {
      invoiceApi
        .getInvoiceAttachments(invoiceId)
        .then((resp: any) => {
          setAttachments(resp);
        })
        .catch((error) => {
          openErrorNotification(
            error?.response?.data?.message ||
              'Failed to get invoice attachments',
          );
        });
    }
  };

  const uploadFiles = (info: UploadChangeParam<UploadFile>) => {
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'image/png',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/plain',
      'application/vnd.ms-outlook',
      'message/rfc822',
    ];
    const allowedExtensions = ['msg'];
    const fileType = info.file.type || '';
    const fileExtension = info.file.name.split('.').pop()?.toLowerCase() || '';
    if (
      !allowedTypes.includes(fileType) &&
      !allowedExtensions.includes(fileExtension)
    ) {
      openErrorNotification(
        `${info.file.name || ''} is not a permitted file type.`,
      );
      return;
    }
    setFileList(info.fileList);
    if (publicId) {
      setIsLoading(true);
      fileApi
        .uploadInvoiceAttachments(info.fileList, publicId)
        .then(() => {
          getAttachments();
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Failed to upload file',
          );
        })
        .finally(() => {
          setFileList([]);
          setIsLoading(false);
        });
    }
  };

  const deleteAttachment = () => {
    if (invoiceId && selectedAttachment) {
      invoiceApi
        .deleteInvoiceAttachment(invoiceId, selectedAttachment)
        .then(() => {
          openSuccessNotification('File was deleted successfully');
          getAttachments();
        })
        .catch((error) => {
          openErrorNotification(
            error?.response?.data?.message ||
              'Failed to delete invoice attachments',
          );
        })
        .finally(() => removeSelected());
    }
  };

  const removeSelected = () => {
    setSelectedAttachment('');
  };

  return (
    <Spin spinning={isLoading}>
      <div className="invoice-attachments-content">
        {attachments?.length ? (
          <div className="invoice-attachments-container">
            {attachments.map((item) => (
              <div
                key={item._id}
                className="invoice-attachments-container-item">
                <div className="invoice-attachments-container-name">
                  <AttachFileIcon />
                  <span
                    className="invoice-attachment-title"
                    onClick={() =>
                      fileApi.downloadFileByUrl(item.originalFilename)
                    }>
                    {item.originalFilename || ''}
                  </span>
                </div>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <TrashIcon onClick={() => setSelectedAttachment(item._id)} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
        <Upload
          beforeUpload={() => false}
          multiple={false}
          style={{ width: '100%' }}
          showUploadList={false}
          className="upload-list-attachments"
          fileList={fileList}
          accept=".pdf,.docx,.doc,.png,.jpeg,.xlsx,.xls,.txt"
          onChange={uploadFiles}>
          <div
            className="dropzone-section"
            style={{ height: '230px', width: '100%' }}>
            <div className="dropzone-wrapper" style={{ color: '#10132f' }}>
              <div className="dropzone">
                <div className="dropzone-content">
                  <DropzoneIcon />

                  <p className="main-text">
                    To upload click or drag files to this area.
                    <br /> (pdf, docx,doc, png, jpeg, xlsx, xls, txt)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Upload>
        {selectedAttachment && (
          <AttentionModal
            cancelBtnTitle="Cancel"
            closeModal={removeSelected}
            confirmAction={deleteAttachment}
            confirmBtnTitle="Yes, delete"
            isOpen={!!selectedAttachment}
            isHideAttentionText
            hideCloseBtn
            content={<DeleteFileText />}
          />
        )}
      </div>
    </Spin>
  );
};

export default AttachmentsContent;
