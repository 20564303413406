import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { IDispatch, IRootState } from './types';
import authReducer from './reducers/auth/sliceReducer';
import waitingRoomReducer from './reducers/waiting-room/sliceReducer';
import employeesReducer from './reducers/employees/sliceReducer';
import onboardingReducer from './reducers/onboarding/sliceReducer';
import timesheetsReducer from './reducers/timesheets/sliceReducer';
import expensesReducer from './reducers/expenses/sliceReducer';
import consultantsReducer from './reducers/consultants/sliceReducer';
import monitoringReducer from './reducers/monitoring/sliceReducer';
import activityReportReducer from './reducers/activity-report/sliceReducer';
import reportingReducer from './reducers/reporting/sliceReducer';
import payslipsReducer from './reducers/payslips/sliceReducer';
import clientsReducer from './reducers/clients/sliceReducer';
import consultantPayslipsReducer from './reducers/consultant-payslips/sliceReducer';
import employeePayslipsReducer from './reducers/employee-payslips/sliceReducer';
import viewPayslipsReducer from './reducers/view-payslips/sliceReducer';
import logsReducer from './reducers/logs-reducer/sliceReducer';
import documentsReducer from './reducers/documents/sliceReducer';
import mainActivitiesReducer from './reducers/main-activities/sliceReducer';
import invoicesReducer from './reducers/invoices/sliceReducer';
import workPermitReducer from './reducers/work-permit/sliceReducer';

const authPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['isAccess', 'errorMassage', 'consultantOnboarding'],
};

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const combineReducer = combineReducers({
  router: routerReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  waitingRoom: waitingRoomReducer,
  employees: employeesReducer,
  onboarding: onboardingReducer,
  timesheets: timesheetsReducer,
  expenses: expensesReducer,
  consultants: consultantsReducer,
  monitoring: monitoringReducer,
  activityReport: activityReportReducer,
  payslips: payslipsReducer,
  reporting: reportingReducer,
  clients: clientsReducer,
  consultantPayslips: consultantPayslipsReducer,
  employeePayslips: employeePayslipsReducer,
  viewPayslips: viewPayslipsReducer,
  logs: logsReducer,
  documents: documentsReducer,
  mainActivities: mainActivitiesReducer,
  invoices: invoicesReducer,
  workPermit: workPermitReducer,
});

const rootReducer = (state: any, action: any) => {
  return combineReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const history = createReduxHistory(store);

export const useAppDispatch: () => IDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
