export enum UploadEntity {
  consultantOnboarding = 'consultantOnboarding',
  consultantTimesheet = 'consultantTimesheet',
  consultantExpenses = 'consultantExpenses',
}

export enum UploadEntityId {
  personalInformation = 'personalInformation',
  workPermit = 'workPermit',
  childAllowances = 'childAllowances',
  timesheetFile = 'timesheetFile',
  expensesFile = 'expensesFile',
  clientContract = 'clientContract',
}
