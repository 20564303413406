import { ChangeEvent, FC, useEffect } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import Modal from 'antd/lib/modal/Modal';
import EditIcon from '../../assets/icons/EditIcon';
import {
  getFormatDateWithMonth,
  getFormatDateWithTime,
} from '../../common/utils/getFormatDate';
import ModalEditTimesheet from './components/ModalEditTimesheet';
import ModalViewTimesheet from './components/ModalViewTimesheet';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  updateOpenModalEditTimesheet,
  updateSelectedTimesheet,
  clearSelectedTimesheet,
  updateOpenModalViewTimesheet,
  updateByIdSelectedTimesheet,
  updateSelectedTimesheetCheckbox,
  removeFileSelectedTimesheet,
  changeTimesheetFiltering,
  resetFilesSelectedTimesheet,
  setErrorMessages,
  setTimesheetSorting,
} from '../../redux/reducers/timesheets/sliceReducer';
import { Button, RadioChangeEvent, Spin, TableProps } from 'antd';
import {
  changeTimesheetSorting,
  getTimesheetList,
  saveAsDraft,
  submit,
  uploadFile,
} from '../../redux/reducers/timesheets/asyncThunkActions';
import EyeIcon from '../../assets/icons/EyeIcon';
import { useTableDateFilter } from '../../common/hooks/useTableDateFilter';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import {
  timesheetStatusParser,
  TTimesheetStatusParserKey,
} from '../../common/constants/timesheetStatusParser';
import { IConsultantTimesheet } from '../../common/api/services/ConsultantApi/types/IConsultantTimesheet';
import { TimesheetStatus } from '../../common/api/services/TimesheetApi/enums/TimesheetStatus';
import ModalExpenses from './components/ModalExpenses';
import TableTooltip from '../../components/common-table/table-tooltip';
import { timesheetsStatusesHovers } from '../../common/constants/tableStatusesHovers';
import moment from '../../common/constants/moment';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import { TSortValue } from '../../redux/types';
import { FilterMobileTypes } from '../../components/common-table/types';
import MobileLoader from '../../components/mobile-loader/MobileLoader';

const TimesheetsPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    isOpenModalEditTimesheet,
    isOpenModalViewTimesheet,
    isOpenModalHasExpenses,
    isUpLoading,
    selectedTimesheet,
    errorMessages,
    timesheetList,
    isLoading,
  } = useAppSelector((state) => state.timesheets);

  useEffect(() => {
    dispatch(getTimesheetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { content, pagination, sort, filter } = timesheetList;
  const { totalElement, currentPage, perPage } = pagination;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showModalEditTimesheet = (data: any) => {
    dispatch(updateByIdSelectedTimesheet(data.key));
    dispatch(updateOpenModalEditTimesheet(true));
    dispatch(setErrorMessages(null));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showModalViewTimesheet = (data: any) => {
    dispatch(updateByIdSelectedTimesheet(data.key));
    dispatch(updateOpenModalViewTimesheet(true));
  };

  const changeFilter = (date: Record<string, string>): void => {
    dispatch(changeTimesheetFiltering(date));
  };

  const changeMobileFilter = (
    date: Record<string, string | string[]>,
  ): void => {
    dispatch(changeTimesheetFiltering(date));
    dispatch(getTimesheetList({ currentPage: 1 }));
  };

  const dataFilter = useTableMonthDate({
    fetchRequest: () => dispatch(getTimesheetList({ currentPage: 1 })),
    changeValues: changeFilter,
    defaultValue: filter?.period ?? '',
    valueType: 'period',
  })();

  const searchFilterArg = {
    fetchRequest: () => dispatch(getTimesheetList({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeTimesheetSorting(sortBy as string));
    }
  };

  const onChangeMobileSort = (sort: { sortBy: string; sort: string }) => {
    dispatch(setTimesheetSorting([sort.sortBy, sort.sort as TSortValue]));
    dispatch(getTimesheetList({ currentPage: 1 }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.publicId,
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...dataFilter,
      width: 150,
      className: sortClassName('period'),
      sorter: true,
      sortDirections: ['descend'],
      filterType: FilterMobileTypes.data,
      defaultFilter: filter?.period,
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter?.clientName ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      width: 200,
      sortDirections: ['descend'],
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          {text}
        </TableTooltip>
      ),
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.clientName,
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo: any) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={200}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
      filterType: FilterMobileTypes.search,
      defaultFilter: filter?.projectDescription,
    },
    {
      title: 'Client approval date',
      dataIndex: 'approvalAt',
      key: 'approvalAt',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: `status-td ${sortClassName('status')}`,
      sorter: true,
      sortDirections: ['descend'],
      width: 250,
      render: (text: TTimesheetStatusParserKey) => {
        return (
          <TableTooltip
            placement="topLeft"
            tootltipTitle={timesheetsStatusesHovers[text]}
            columnWidth={0}>
            <div className="status-wrapper">
              <span className={timesheetStatusParser[text]}>{text}</span>
            </div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'edit-action-td',
      width: 150,
      render: (_: any, data: any) => {
        const actionIcon =
          data.status !== TimesheetStatus.DRAFT ? (
            <>
              <EyeIcon
                onClick={() => showModalViewTimesheet(data)}
                className="eye-timesheets-icon action-table-btn"
              />
              <Button
                type="primary"
                className="action-mobile-btn "
                onClick={() => showModalViewTimesheet(data)}>
                View
              </Button>
            </>
          ) : (
            <>
              <EditIcon
                onClick={() => showModalEditTimesheet(data)}
                className="edit-timesheets-icon action-table-btn"
              />
              <Button
                type="primary"
                className="action-mobile-btn"
                onClick={() => showModalEditTimesheet(data)}>
                Edit
              </Button>
            </>
          );

        return <div>{actionIcon}</div>;
      },
    },
  ];

  const data = content.map((item) => ({
    key: item._id,
    publicId: item.publicId,
    period: getFormatDateWithMonth(item.period),
    projectDescription: item.workContract?.projectDescription
      ? item.workContract?.projectDescription
      : 'No',
    createdAt: item.createdAt
      ? moment(item.createdAt).format(commonMonthTimeFormat)
      : 'No',
    approvalAt: item.clientApprovalDate
      ? getFormatDateWithTime(item.clientApprovalDate)
      : 'No',
    clientName: item.workContract?.name ? item.workContract.name : 'No',
    status: item.status,
  }));

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';
      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const handleCancel = () => {
    dispatch(updateOpenModalEditTimesheet(false));
    dispatch(updateOpenModalViewTimesheet(false));
    dispatch(clearSelectedTimesheet());
  };

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasActivityPerMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedTimesheet());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          standardTime: 0,
          overtime: 0,
          doubleTime: 0,
        }),
      );
    }
    dispatch(updateSelectedTimesheet({ [fieldName]: fieldValue }));
  };

  const onChangeCheckbox = (fieldName: 'isSigned' | 'approvalRequired') => {
    return () => {
      dispatch(
        updateSelectedTimesheet({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedTimesheetCheckbox(fieldName));
    };
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };

  const onChangeNumber =
    (fieldName: string) => (value: number | string | null) => {
      dispatch(
        updateSelectedTimesheet({
          [fieldName]: value,
        }),
      );
    };

  const onRemoveAttachFile = (originalFilename: string) => {
    dispatch(removeFileSelectedTimesheet(originalFilename));
  };

  const onSaveAsDraft = () => {
    dispatch(saveAsDraft());
  };

  const onSubmit = () => {
    dispatch(submit());
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getTimesheetList({ perPage, currentPage }));
  };

  const onDoubleClick = (record: any) => {
    if (record.status === TimesheetStatus.DRAFT) {
      showModalEditTimesheet(record);
      return;
    }
    showModalViewTimesheet(record);
  };

  return (
    <>
      <Spin spinning={isLoading} indicator={<MobileLoader />}>
        <CommonTable
          paginationCurrentPage={currentPage}
          paginationPageSize={perPage}
          paginationOnChange={onChangePagination}
          paginationTotalElement={totalElement}
          data={data}
          columns={columns}
          onChange={onChangeSort}
          onDoubleClick={onDoubleClick}
          submitFilters={changeMobileFilter}
          sortBy={sort}
          onChangeMobileSort={onChangeMobileSort}
        />
      </Spin>

      {isOpenModalEditTimesheet && (
        <Modal
          width={640}
          title={'Edit timesheet'}
          open={isOpenModalEditTimesheet}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper timesheet">
          <ModalEditTimesheet
            isUpLoading={isUpLoading}
            selectedTimesheet={selectedTimesheet}
            getError={getError}
            onChange={onChange}
            onChangeCheckbox={onChangeCheckbox}
            onChangeFile={onChangeFile}
            onChangeNumber={onChangeNumber}
            onRemoveAttachFile={onRemoveAttachFile}
            onSaveAsDraft={onSaveAsDraft}
            onSubmit={onSubmit}
          />
        </Modal>
      )}

      {isOpenModalViewTimesheet && (
        <Modal
          width={640}
          title={
            <div className="modal-view-title">
              View timesheet{' '}
              <span
                className={timesheetStatusParser[selectedTimesheet?.status]}>
                {selectedTimesheet?.status.toUpperCase() || ''}
              </span>
            </div>
          }
          open={isOpenModalViewTimesheet}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper timesheet">
          <ModalViewTimesheet selectedTimesheet={selectedTimesheet} />
        </Modal>
      )}

      {isOpenModalHasExpenses && (
        <Modal
          width={640}
          open={isOpenModalHasExpenses}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <ModalExpenses />
        </Modal>
      )}
    </>
  );
};

export default TimesheetsPage;
