import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  documentsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
};

const reducer = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.documentsList.content = action.payload.result;
      state.documentsList.pagination.totalElement = action.payload.total;
    },
    changeDocumentsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.documentsList.filter = {
        ...state.documentsList.filter,
        ...action.payload,
      };
      state.documentsList.pagination.currentPage = 1;
    },
    setDocumentsSorting(state, action: PayloadAction<any>) {
      state.documentsList.sort.sortBy = action.payload[0];
      state.documentsList.sort.sort = action.payload[1];
    },
    clearDocumentsFiltersAndSorts(state) {
      state.documentsList.sort = initialState.documentsList.sort;
      state.documentsList.filter = initialState.documentsList.filter;
    },
    setDocumentsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.documentsList.pagination.currentPage = action.payload.currentPage;
      state.documentsList.pagination.perPage = action.payload.perPage;
    },
  },
});

export const {
  setDocumentsList,
  changeDocumentsFiltering,
  setDocumentsSorting,
  clearDocumentsFiltersAndSorts,
  setDocumentsPagination,
} = reducer.actions;

export default reducer.reducer;
