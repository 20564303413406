import { ChangeEvent, FC } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Textarea from '../../../../components/textarea/Textarea';
import { IWorkPermit } from '../../../../redux/reducers/onboarding/types';
import OnboardingWorkPermitMessage from '../../../../components/info-messages/OnboardingWorkPermitMessage';
import {
  cantons,
  documentTypes,
  permitTypes,
} from '../../../../common/constants/dropdownTypes';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import { useAppSelector } from '../../../../redux/store';
import { Roles } from '../../../../common/enums/Roles';
import '../../OnboardingPage.less';

const basisOfLegalStayValues = [
  'Work Swiss & Live Swiss',
  'Work Swiss & Return Everyday Abroad',
  'Work Swiss & Return Weekly Abroad',
  'Work Swiss & Return Monthly Abroad',
  'Work Abroad & Live Swiss',
  'Work Abroad & Live Abroad',
];

interface IProps {
  isSwissNationality: boolean;
  workPermit: Omit<IWorkPermit, 'documents'>;
  errorMessages: Record<string, string>;
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  onChangeDate: (fieldName: string) => (date: Date | null) => void;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement> | RadioChangeEvent,
  ) => void;
}

const WorkPermitTabInformationSection: FC<IProps> = (props) => {
  const {
    workPermit,
    isSwissNationality,
    onChangeDate,
    onChange,
    onChangeDropdown,
    errorMessages,
  } = props;

  const {
    hasWorkPermit,
    documentType,
    permitType,
    permitCanton,
    startDate,
    endDate,
    basisOfLegalStay,
    selfHandledWorkPermit,
    comment,
    hasStartedWorkPermitProcess,
    hasProofOfOngoingProcess,
  } = workPermit;

  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    }
    return '';
  };

  const setDisabledBasis = () => {
    return !isSwissNationality && !hasWorkPermit && !selfHandledWorkPermit
      ? false
      : isSwissNationality || !hasWorkPermit;
  };

  return (
    <Col span={12}>
      {isSwissNationality && <OnboardingWorkPermitMessage />}

      <h4>Information</h4>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Do you have a work permit?</span>
        </Col>
        <Col>
          <Radio.Group
            name="hasWorkPermit"
            value={hasWorkPermit}
            onChange={onChange}
            disabled={isSwissNationality}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      {!hasWorkPermit && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          className="work-permit-row-section"
          style={{ height: 54 }}>
          <Col>
            <span style={{ fontSize: '14px' }}>
              Did you start the work permit process by yourself?
            </span>
          </Col>
          <Col>
            <Radio.Group
              name="selfHandledWorkPermit"
              onChange={onChange}
              value={selfHandledWorkPermit}
              disabled={isSwissNationality}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {!hasWorkPermit && !isSwissNationality && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          className="work-permit-row-section"
          style={{ height: 54 }}>
          <Col>
            <span style={{ fontSize: '14px' }}>
              Did you start the process with town hall/canton?
            </span>
          </Col>
          <Col>
            <Radio.Group
              name="hasStartedWorkPermitProcess"
              onChange={onChange}
              value={hasStartedWorkPermitProcess}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
      {isAdmin &&
        (hasStartedWorkPermitProcess || selfHandledWorkPermit) &&
        !hasWorkPermit &&
        !isSwissNationality && (
          <Row
            justify="space-between"
            align="middle"
            gutter={[16, 16]}
            style={{ height: 54 }}>
            <Col>
              <span style={{ fontSize: '14px' }}>
                Proof of ongoing process
                <span style={{ color: '#0671e0' }}>*</span>
              </span>
            </Col>
            <Col>
              <Radio.Group
                name="hasProofOfOngoingProcess"
                onChange={onChange}
                value={hasProofOfOngoingProcess}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>
          </Row>
        )}
      {hasStartedWorkPermitProcess && !hasWorkPermit && !isSwissNationality && (
        <span style={{ fontSize: '14px' }}>
          Please upload a proof of registration/application
        </span>
      )}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Dropdown
            options={documentTypes}
            value={documentType}
            onChange={onChangeDropdown('documentType')}
            label="Document type"
            showSearch={false}
            errorMessage={getError('data.documentType')}
            disabled={isSwissNationality || !hasWorkPermit}
            allowClear
            onClear={() => onChangeDropdown('documentType')('')}
          />
        </Col>
        <Col span={12}>
          <Dropdown
            options={permitTypes}
            value={permitType}
            onChange={onChangeDropdown('permitType')}
            label="Permit type"
            showSearch={false}
            errorMessage={getError('data.permitType')}
            disabled={isSwissNationality || !hasWorkPermit}
            allowClear
            onClear={() => onChangeDropdown('permitType')('')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={cantons}
            value={permitCanton}
            onChange={onChangeDropdown('permitCanton')}
            label="Permit canton (where you live or intend to live)"
            showSearch={false}
            errorMessage={getError('data.permitCanton')}
            disabled={isSwissNationality || !hasWorkPermit}
            allowClear
            onClear={() => onChangeDropdown('permitCanton')('')}
          />
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{ height: '66px' }}
        className="work-permit-date-pickers">
        <Col span={12}>
          <CommonDatePicker
            label="Start date"
            defaultValue={startDate || ''}
            changeDate={onChangeDate('startDate')}
            className="date-picker-form"
            errorMessage={getError('data.startDate')}
            disabled={isSwissNationality || !hasWorkPermit}
            calendarPosition="right-calendar-position"
          />
        </Col>
        <Col span={12}>
          <CommonDatePicker
            label="End date"
            defaultValue={endDate || ''}
            changeDate={onChangeDate('endDate')}
            className="date-picker-form"
            errorMessage={getError('data.endDate')}
            disabled={isSwissNationality || !hasWorkPermit}
            calendarPosition="right-calendar-position"
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={
              !isSwissNationality && !hasWorkPermit && !selfHandledWorkPermit
                ? basisOfLegalStayValues.filter(
                    (item) => item === 'Work Abroad & Live Abroad',
                  )
                : basisOfLegalStayValues
            }
            value={basisOfLegalStay}
            onChange={onChangeDropdown('basisOfLegalStay')}
            label="Basis of legal stay"
            showSearch={false}
            errorMessage={getError('data.basisOfLegalStay')}
            disabled={setDisabledBasis()}
            allowClear
            onClear={() => onChangeDropdown('basisOfLegalStay')('')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            name="comment"
            value={comment}
            onChange={onChange}
            label="Comment"
            errorMessage={getError('data.comment')}
            disabled={isSwissNationality}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default WorkPermitTabInformationSection;
