import { FC } from 'react';
import { Row, Col } from 'antd';
import Button from '../../button/Button';
import SaveIcon from '../../../assets/icons/SaveIcon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useLocation } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import {
  saveAsDraftMyInformation,
  saveAsPublishMyInformation,
} from '../../../redux/reducers/onboarding/tabs/myInformationAsyncThunk';

const { workPermit, bankAccount, avsAhv } = ROUTER_PATHS;

const MyInformationFooter: FC = () => {
  const pathName = useLocation().pathname;
  const dispatch = useAppDispatch();

  const hasWorkPermit = useAppSelector(
    (state) => state.onboarding.workPermit.hasWorkPermit,
  );
  const hasBankAccount = useAppSelector(
    (state) => state.onboarding.bankAccount.hasBankAccount,
  );
  const hasSsn = useAppSelector(
    (state) => state.onboarding.ssnInformation.hasSSN,
  );

  const saveAsPublishHandler = (): void => {
    dispatch(saveAsPublishMyInformation());
  };

  const saveAsDraftHandler = (): void => {
    dispatch(saveAsDraftMyInformation());
  };

  const isSkipConditions =
    (pathName.includes(workPermit) && !hasWorkPermit) ||
    (pathName.includes(bankAccount) && !hasBankAccount) ||
    (pathName.includes(avsAhv) && !hasSsn);

  const buttonText = isSkipConditions ? 'Skip and go next' : 'Save and publish';

  return (
    <Row
      align="middle"
      className="my-information-footer"
      justify="space-between">
      <Col span={24}>
        <Row gutter={[16, 0]} justify="end">
          {!isSkipConditions && (
            <Col>
              <Button
                buttonStyle="secondary"
                buttonSize="normal"
                leftIcon={<SaveIcon />}
                onClick={saveAsDraftHandler}>
                Save as Draft
              </Button>
            </Col>
          )}
          <Col>
            <Button
              buttonStyle="primary"
              buttonSize="normal"
              rightIcon={<ArrowIcon />}
              onClick={saveAsPublishHandler}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MyInformationFooter;
