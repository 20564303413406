export interface IMobileFilter {
  type: FilterMobileTypes;
  defaultValue: any;
  key: string;
  name: string;
}

export enum FilterMobileTypes {
  search = 'search',
  data = 'data',
  select = 'select',
  dataRange = 'dataRange',
}

export type IMobileFilters = Array<IMobileFilter>;
