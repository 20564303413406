import { Menu, Upload, UploadFile } from 'antd';
import {
  IActivityReportPayslips,
  IActivityReportResponse,
  ISalarySimulations,
} from '../../../common/api/services/ActivityApi/types/IActivityReportResponse';

interface IProps {
  onOpenGenerateInvoice: (item: any) => void;
  onUpload: (file: UploadFile<any>[], publicId: number, period: string) => void;
  consultant: IActivityReportResponse;
  period: string;
  deletePayslip: (
    consultantId: string,
    id: string,
    type: 'payslip' | 'simulation',
  ) => void;
  publicId: number;
  payslips: Array<IActivityReportPayslips>;
  salarySimulations: Array<ISalarySimulations>;
  setDeletedFiles: (files: Array<string>) => void;
  setIsOpenDeleteFiles: (isOpen: boolean) => void;
  timesheetId: string;
}

const ActionMenu = (props: IProps) => {
  const {
    onOpenGenerateInvoice,
    onUpload,
    consultant,
    deletePayslip,
    publicId,
    period,
    payslips,
    salarySimulations,
    setDeletedFiles,
    setIsOpenDeleteFiles,
    timesheetId,
  } = props;

  const allItems = [
    {
      key: '1',
      label: (
        <Upload
          onChange={(value) => onUpload(value.fileList, publicId, period)}
          beforeUpload={() => false}
          fileList={[]}
          multiple={false}>
          <span>Upload payslip</span>
        </Upload>
      ),
    },
    {
      key: '2',
      label: <span onClick={onOpenGenerateInvoice}>Generate invoice</span>,
    },
  ];

  const isHasPayslips = payslips.length > 0 && payslips[0].originalFilename;
  const ishasSimulation =
    salarySimulations.length > 0 && salarySimulations[0].originalFilename;

  if (isHasPayslips && !ishasSimulation) {
    allItems.push({
      key: '4',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() =>
            deletePayslip(payslips[0].consultantId, payslips[0]._id, 'payslip')
          }>
          Delete payslip
        </span>
      ),
    });
  }

  if (ishasSimulation && !isHasPayslips) {
    allItems.push({
      key: '5',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() =>
            deletePayslip(
              salarySimulations[0].consultantId,
              salarySimulations[0]._id,
              'simulation',
            )
          }>
          Delete simulation
        </span>
      ),
    });
  }

  if (isHasPayslips && ishasSimulation) {
    allItems.push({
      key: '6',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() => {
            setIsOpenDeleteFiles(true);
            setDeletedFiles([timesheetId]);
          }}>
          Delete files
        </span>
      ),
    });
  }

  return <Menu items={allItems} />;
};

export default ActionMenu;
