import { FC } from 'react';
import { Menu } from 'antd';
import { renderEndOfDateContract } from '../../../common/utils/monitoringPageUtils';

interface IProps {
  item: any;
  openFinishContract: (item: any) => void;
  onRenewContract: (item: any) => void;
}

const ActionMenu: FC<IProps> = ({
  item,
  openFinishContract,
  onRenewContract,
}) => {
  const workContractDaysToEnd = renderEndOfDateContract(
    item.workContractStartInDay,
    item.workContractEndInDays,
  );
  const disabledFinish =
    workContractDaysToEnd !== 'Contract finished' &&
    item.endOfService === 'Finished';
  const allItems = [
    {
      key: '1',
      label: (
        <span
          style={{ color: item.endOfService === 'Finished' ? '#abbed9' : '' }}
          onClick={() => {
            if (item.endOfService !== 'Finished') openFinishContract(item);
          }}>
          Finish Contract
        </span>
      ),
    },
  ];

  if (disabledFinish) {
    allItems.push({
      key: '2',
      label: <span onClick={() => onRenewContract(item)}>Renew Contract</span>,
    });
  }

  return <Menu items={allItems} />;
};

export default ActionMenu;
