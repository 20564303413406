import { ChangeEvent, FC, useEffect, useState } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { Modal, RadioChangeEvent, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import {
  getFormatDateWithMonth,
  getFormatDateWithTime,
} from '../../common/utils/getFormatDate';
import {
  approveInternalExpense,
  changeInternalExpensesSorting,
  expireInternalExpense,
  getInternalExpenses,
  publishInternalExpense,
  rejectInternalExpense,
  saveAsDraftInternalExpense,
  submitInternalExpense,
  uploadFile,
} from '../../redux/reducers/expenses/asyncThunkActions';
import {
  changeExpensesFiltering,
  clearSelectedExpense,
  removeFileSelectedExpense,
  resetFilesSelectedExpense,
  setErrorMessages,
  updateByIdSelectedExpense,
  updateOpenModalAddExpense,
  updateOpenModalEditExpense,
  updateOpenModalViewExpense,
  updateSelectedExpense,
  updateSelectedExpenseCheckbox,
} from '../../redux/reducers/expenses/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import {
  expensesStatusParser,
  TExpensesStatusParserKey,
} from '../../common/constants/expensesStatusParser';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import {
  ExpensesStatus,
  ExpensesStatusesArray,
  ExpensesType,
} from '../../common/api/services/ConsultantApi/enums/ResponseEnums';
import ActionsMenu from './components/ActionsMenu';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import { IExpensesResponse } from '../../common/api/services/ExpensesApi/types/IExpensesResponse';
import ModalEditExpense from './components/ModalEditExpense';
import ModalViewExpense from './components/ModalViewExpense';
import { IConsultantExpenses } from '../../common/api/services/ConsultantApi/types/IConsultantExpenses';
import { INewExpense } from '../../common/api/services/ExpensesApi/types/INewExpense';
import ModalAddExpense from './components/ModalAddExpense';
import { useTablePeriodFilter } from '../../common/hooks/useTablePerionFilter';
import TableTooltip from '../../components/common-table/table-tooltip';
import moment from '../../common/constants/moment';
import {
  commonMonthTimeFormat,
  commonMonthYearSpaceFormat,
} from '../../common/constants/dateConstants';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import EditIcon from '../../assets/icons/EditIcon';
import { ISelectedNode } from '../monitoring-page/components/NodeEdit';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import EditNoteModal from '../../components/edit-note-modal/EditNoteModal';
import { expensesApi } from '../../common/api/services/ExpensesApi/expensesApi';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { DeleteTimesheetText } from '../../components/common-texts/CommonTexts';
import { useLocation } from 'react-router-dom';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';

const InternalExpensesPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    isUpLoading,
    isOpenModalAddExpense,
    isOpenModalEditExpense,
    isOpenModalViewExpense,
    errorMessages,
    expensesList,
    selectedExpense,
  } = useAppSelector((state) => state.expenses);

  const [selectedNote, setSelectedNote] = useState<ISelectedNode | null>(null);
  const [deleteInfo, setDeleteInfo] = useState<any>(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const publicId = urlParams.get('publicId');
  const expenseId = urlParams.get('expenseId');

  useEffect(() => {
    if (publicId) {
      changeFilter({ consultantId: publicId });
    }
    dispatch(getInternalExpenses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expenseId) {
      expensesApi
        .fetchExpenseById(expenseId)
        .then((response: any) => {
          dispatch(updateSelectedExpense(response));
          if (
            (response && response.status === ExpensesStatus.DRAFT) ||
            response.status === ExpensesStatus.REJECTED ||
            response.status === ExpensesStatus.NO_EXPENSES
          ) {
            showModalEditExpense(response._id)();
            return;
          }
          showModalViewExpense(response._id)();
        })
        .catch((e) => {
          openErrorNotification('Timesheet was not found');
        });
    }
  }, []);

  const { content, pagination, sort, filter } = expensesList;
  const { totalElement, currentPage, perPage } = pagination;

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeExpensesFiltering(date));
  };

  const dataFilter = useTableMonthDate({
    fetchRequest: () => dispatch(getInternalExpenses({ currentPage: 1 })),
    changeValues: changeFilter,
    defaultValue: (filter?.period as string) ?? '',
    valueType: 'period',
  })();

  const searchFilterArg = {
    fetchRequest: () => dispatch(getInternalExpenses({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeInternalExpensesSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getInternalExpenses({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const showModalEditExpense = (id: string) => (): void => {
    dispatch(updateByIdSelectedExpense(id));
    dispatch(updateOpenModalEditExpense(true));
    dispatch(setErrorMessages(null));
  };

  const showModalViewExpense = (id: string) => (): void => {
    dispatch(updateByIdSelectedExpense(id));
    dispatch(updateOpenModalViewExpense(true));
  };

  const approveExpense = (selectedExpense: IConsultantExpenses) => (): void => {
    dispatch(approveInternalExpense(selectedExpense));
  };

  const rejectExpense = (selectedExpense: IConsultantExpenses) => (): void => {
    dispatch(rejectInternalExpense(selectedExpense));
  };

  const expireExpense = (selectedExpense: IConsultantExpenses) => (): void => {
    dispatch(expireInternalExpense(selectedExpense));
  };

  const onHideNote = () => {
    setSelectedNote(null);
  };

  const onChangeNotes = (id: string, notes: string) => {
    if (!id) {
      return;
    }
    expensesApi
      .addExpensesNotes(id, notes)
      .then(() => {
        openSuccessNotification('Note was added successfully');
        dispatch(getInternalExpenses());
      })
      .catch((e) => {
        openErrorNotification('Failed to add note');
      });
  };

  const deleteTimesheet = () => {
    if (!deleteInfo) {
      return;
    }
    expensesApi
      .deleteExpense(deleteInfo.id)
      .then(() => {
        openSuccessNotification('Expense was deleted successfully');
        dispatch(getInternalExpenses());
      })
      .catch((e) => {
        openErrorNotification('Failed to delete');
      })
      .finally(() => {
        onCloseDeleteModal();
      });
  };

  const onCloseDeleteModal = () => {
    setDeleteInfo(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 120,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Consultant ID',
      dataIndex: 'consultantId',
      width: 160,
      fixed: 'left',
      className: sortClassName('consultantId'),
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.consultantId as string) ?? '',
      })('consultantId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Linked Timesheet',
      dataIndex: 'linkedTimesheet',
      width: 180,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter['timesheet.publicId'] as string) ?? '',
      })('timesheet.publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Consultant first name',
      dataIndex: 'consultant.firstName',
      key: 'consultant.firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['consultant.firstName']
          ? (filter['consultant.firstName'] as string)
          : '',
      })('consultant.firstName'),
      className: sortClassName('consultant.firstName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 216,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={216}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Consultant last name',
      dataIndex: 'consultant.lastName',
      key: 'consultant.lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: filter['consultant.lastName']
          ? (filter['consultant.lastName'] as string)
          : '',
      })('consultant.lastName'),
      className: sortClassName('consultant.lastName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 216,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={216}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      ...dataFilter,
      className: sortClassName('period'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      width: 135,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={135}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.type as string[]) ?? '',
        fieldFilterName: 'type',
      })([
        ExpensesType.EXPENSES_FOR_OPTIMISATION,
        ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ExpensesType.EXPENSES_MEAL_ALLOWANCE,
      ]),
      className: sortClassName('type'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      width: 105,
    },
    {
      title: 'Submission date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      sorter: true,
      sortDirections: ['descend'],
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['submissionDate']
          ? (filter['submissionDate'] as string)
          : '',
        field: 'submissionDate',
      })(),
      width: 200,
      render: (text, { isStandardTime }) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          <span style={{ color: isStandardTime ? '#E48900' : '#10132F' }}>
            {text}
          </span>
        </TableTooltip>
      ),
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      className: sortClassName('client.name'),
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      width: 200,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={150}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Client approval date',
      dataIndex: 'approvalAt',
      key: 'approvalAt',
      width: 180,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={180}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.status as unknown as string[]) ?? [],
        fieldFilterName: 'status',
      })(ExpensesStatusesArray),
      className: `status-td ${sortClassName('status')}`,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        );
      },
      width: 275,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.notes as string) ?? '',
      })('notes'),
      className: sortClassName('notes'),
      sorter: true,
      sortDirections: ['descend'],
      width: 180,
      render: (notes, { item }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableTooltip tootltipTitle={notes} columnWidth={180}>
              <div className="cut-text" style={{ width: '130px' }}>
                {notes}
              </div>
            </TableTooltip>
            <EditIcon
              style={{ position: 'absolute', right: '10px' }}
              onClick={() => {
                setSelectedNote({
                  clientId: item._id,
                  consultantId: item._id,
                  name: `${item.consultant.firstName} ${item.consultant.lastName}`,
                  notesValue: item.notes,
                  clientFullName:
                    ExpensesType.EXPENSES_FOR_OPTIMISATION === item?.type
                      ? 'Numeriq'
                      : item?.workContract?.name,
                  id: item._id,
                  publicId: item.publicId,
                  period: moment(item.period).format(
                    commonMonthYearSpaceFormat,
                  ),
                  type: 'Timesheet',
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'edit-action-td',
      render: (_, { item }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionsMenu
                  showModalEditExpense={showModalEditExpense(item._id)}
                  showModalViewExpense={showModalViewExpense(item._id)}
                  approveExpense={approveExpense(item)}
                  rejectExpense={rejectExpense(item)}
                  expireExpense={expireExpense(item)}
                  status={item.status as ExpensesStatus}
                  type={item.type as ExpensesType}
                  id={item._id}
                  publicId={item.publicId}
                  firstName={(item as IExpensesResponse).consultant.firstName}
                  lastName={(item as IExpensesResponse).consultant.lastName}
                  setDeleteInfo={setDeleteInfo}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
      fixed: 'right',
      width: 80,
    },
  ];

  const data = (content as IExpensesResponse[]).map((item) => {
    const {
      _id,
      consultant,
      period,
      createdAt,
      type,
      status,
      workContract,
      clientApprovalDate,
    } = item;
    const clientName =
      ExpensesType.EXPENSES_FOR_OPTIMISATION === type ||
      type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
        ? 'Numeriq'
        : workContract?.name;
    return {
      key: _id,
      item,
      publicId: item.publicId,
      ['consultant.firstName']: consultant.firstName,
      ['consultant.lastName']: consultant.lastName,
      period: getFormatDateWithMonth(period),
      projectDescription: item.workContract?.projectDescription
        ? item.workContract?.projectDescription
        : 'No',
      submissionDate: item.submissionDate
        ? moment(item.submissionDate).format(commonMonthTimeFormat)
        : '',
      approvalAt: clientApprovalDate
        ? getFormatDateWithTime(clientApprovalDate)
        : 'No',
      type: type,
      clientName: clientName ? clientName : 'No',
      status: status,
      linkedTimesheet: item.timesheet?.publicId || 'No',
      consultantId: consultant.publicId || '',
      notes: item.notes || 'No',
    };
  });

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';

      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const handleCancel = () => {
    dispatch(updateOpenModalAddExpense(false));
    dispatch(updateOpenModalEditExpense(false));
    dispatch(updateOpenModalViewExpense(false));
    dispatch(clearSelectedExpense());
    dispatch(setErrorMessages(null));
  };

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasExpensesInThisMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedExpense());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          total: 0,
        }),
      );
    }
    dispatch(
      updateSelectedExpense({
        [fieldName]: fieldValue,
      }),
    );
  };

  const onChangeCheckbox = (fieldName: 'isSigned' | 'approvalRequired') => {
    return () => {
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedExpenseCheckbox(fieldName));
    };
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };
  const onChangeVisibility = (visibility: boolean) => {
    dispatch(
      updateSelectedExpense({
        visibility: visibility ? 'public' : 'private',
      }),
    );
  };

  const onRemoveAttachFile = (originalFilename: string) => {
    dispatch(removeFileSelectedExpense(originalFilename));
  };

  const onSaveAsDraft = () => {
    dispatch(saveAsDraftInternalExpense());
  };

  const onSubmit = () => {
    dispatch(submitInternalExpense());
  };

  const onPublish = (params: INewExpense) => (): void => {
    const body = { ...params };
    if (
      body.type === ExpensesType.EXPENSES_FOR_OPTIMISATION ||
      body.type === ExpensesType.EXPENSES_MEAL_ALLOWANCE
    ) {
      delete body.companyId;
    }
    dispatch(publishInternalExpense(body));
  };

  const onDoubleClick = (record: any) => {
    if (
      record.status === ExpensesStatus.DRAFT ||
      record.status === ExpensesStatus.REJECTED ||
      record.status === ExpensesStatus.NO_EXPENSES
    ) {
      showModalEditExpense(record.key)();
      return;
    }
    showModalViewExpense(record.key)();
  };

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChangeSort}
        onDoubleClick={onDoubleClick}
      />

      {isOpenModalEditExpense && (
        <Modal
          width={640}
          title={'Edit mode'}
          open={isOpenModalEditExpense}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-expense edit">
          <ModalEditExpense
            isUpLoading={isUpLoading}
            selectedExpense={selectedExpense}
            getError={getError}
            onChange={onChange}
            onChangeCheckbox={onChangeCheckbox}
            onChangeFile={onChangeFile}
            onRemoveAttachFile={onRemoveAttachFile}
            onSaveAsDraft={onSaveAsDraft}
            onSubmit={onSubmit}
            onChangeVisibility={onChangeVisibility}
          />
        </Modal>
      )}
      {!!selectedNote && (
        <EditNoteModal
          isOpen={!!selectedNote}
          onCancel={onHideNote}
          onChangeNotes={onChangeNotes}
          selectedNote={selectedNote}
        />
      )}

      {isOpenModalViewExpense && (
        <Modal
          width={640}
          title={
            <div className="modal-view-title">
              View expenses{' '}
              <span className={expensesStatusParser[selectedExpense?.status]}>
                {selectedExpense?.status.toUpperCase() || ''}
              </span>
            </div>
          }
          open={isOpenModalViewExpense}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-expense view">
          <ModalViewExpense selectedExpense={selectedExpense} />
        </Modal>
      )}
      {deleteInfo && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={onCloseDeleteModal}
          confirmAction={deleteTimesheet}
          confirmBtnTitle="Yes, delete"
          content={
            <DeleteTimesheetText
              firstName={deleteInfo.firstName}
              lastName={deleteInfo.lastName}
              publicId={deleteInfo.publicId}
              type="Expenses"
            />
          }
          isOpen={deleteInfo}
          isHideAttentionText
          width={550}
          btnWidth={'230px'}
        />
      )}

      {isOpenModalAddExpense && (
        <Modal
          width={selectedExpense?._id === '' ? 468 : 640}
          title="Add new expenses"
          open={isOpenModalAddExpense}
          onCancel={handleCancel}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="modal-window-wrapper i-expense add">
          {selectedExpense?._id === '' ? (
            <ModalAddExpense
              getError={getError}
              handleCancel={handleCancel}
              onPublish={onPublish}
            />
          ) : (
            <ModalEditExpense
              isUpLoading={isUpLoading}
              selectedExpense={selectedExpense}
              getError={getError}
              onChange={onChange}
              onChangeCheckbox={onChangeCheckbox}
              onChangeFile={onChangeFile}
              onRemoveAttachFile={onRemoveAttachFile}
              onSaveAsDraft={onSaveAsDraft}
              onSubmit={onSubmit}
              onChangeVisibility={onChangeVisibility}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default InternalExpensesPage;
