import ExitIcon from '../../../assets/icons/ExitIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import { reportingApi } from '../../../common/api/services/ReportingApi/reportingApi';
import Button from '../../../components/button/Button';
import {
  exportToExelReporting,
  getReportingList,
} from '../../../redux/reducers/reporting/asyncThunkActions';
import { clearReportingFiltersAndSorts } from '../../../redux/reducers/reporting/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const ReportingExtraSection = () => {
  const dispatch = useAppDispatch();

  const { selectedArray } = useAppSelector((state) => state.reporting);

  const {
    sort: reportingSorts,
    filter: reportingFilters,
    selectedConsultant: reportingSelectedConsultant,
  } = useAppSelector((state) => state.reporting.reportingList);

  const isResetReportingFilters =
    reportingSorts.sortBy !== '' ||
    Object.values(reportingFilters).some((el) => el.length !== 0) ||
    reportingSelectedConsultant.length > 0;

  const downloadReportingZip = () => {
    if (!selectedArray) {
      return;
    }
    const body: any = {};
    if (selectedArray.expensesIds.length > 0) {
      body.expensesIds = selectedArray.expensesIds;
    }
    if (selectedArray.timesheetsIds.length > 0) {
      body.timesheetsIds = selectedArray.timesheetsIds;
    }
    reportingApi
      .downloadReportingZip(body)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => null);
  };
  return (
    <>
      {isResetReportingFilters && (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearReportingFiltersAndSorts());
            dispatch(getReportingList({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      )}
      {selectedArray &&
        (selectedArray.expensesIds.length > 0 ||
          selectedArray.timesheetsIds.length > 0) && (
          <Button
            onClick={() => downloadReportingZip()}
            buttonStyle="secondary"
            buttonSize="medium"
            buttonWidth={200}
            leftIcon={<ExitIcon className="export-icon" />}>
            Download files
          </Button>
        )}
      <Button
        onClick={() => dispatch(exportToExelReporting())}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={200}
        leftIcon={<ExitIcon className="export-icon" />}>
        Export to Excel
      </Button>
    </>
  );
};

export default ReportingExtraSection;
