import { Modal } from 'antd';
import Textarea from '../../../components/textarea/Textarea';
import Button from '../../../components/button/Button';
import { useState } from 'react';
import { IData } from '../../../common/api/services/InvoiceApi/types/types';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
interface IProps {
  isOpen: boolean;
  onClose: (items?: Array<IData>) => void;
  selectedDescription: IData;
  invoiceId?: string;
}

const DescriptionModal = (props: IProps) => {
  const { isOpen, onClose, selectedDescription, invoiceId } = props;
  const [inputValue, setInputValue] = useState(
    selectedDescription?.description || '',
  );
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    if (invoiceId) {
      setIsLoading(true);
      invoiceApi
        .updateItemDescription(invoiceId, selectedDescription._id, inputValue)
        .then((resp) => {
          openSuccessNotification('Description was updated successfully');
          onClose(resp?.items || []);
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Something went wrong',
          );
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      onCancel={() => onClose()}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div className="upload-confirm-container">
        <h4>Description</h4>
        <div style={{ marginTop: '1rem' }}>
          <Textarea
            label="Text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <div className="btns-wrapper">
          <Button
            buttonWidth="200px"
            buttonSize="normal"
            buttonStyle="error"
            disable={isLoading}
            onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            buttonWidth="200px"
            buttonSize="normal"
            buttonStyle="primary"
            disable={isLoading}
            onClick={() => onConfirm()}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionModal;
