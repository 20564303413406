import { AxiosError } from 'axios';

export const parsErrorMessageObj = (
  error: AxiosError<any>,
): string | Record<string, string> => {
  const { message, messages } = error.response?.data;
  const errorMessage = message || messages;

  if (Array.isArray(errorMessage)) {
    const obj: Record<string, string> = {};

    errorMessage.forEach((item) => {
      obj[item.property] = item.message;
    });

    return obj;
  }

  return errorMessage;
};
