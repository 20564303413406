import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './types';
import { IPagination, TSortValue } from '../../types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantsResponse } from '../../../common/api/services/ConsultantApi/types/IConsultantsResponse';
import { ICreateConsultantResponseDto } from '../../../common/api/services/ConsultantApi/types/ICreateConsultantResponse';

const initialState: IState = {
  consultantsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
    selectedConsultants: [],
  },
  selectedConsultant: null,
  isOpenModalFinance: false,
  isOpenModalAdmin: false,
  isShowCreateConsultantModal: false,
  inviteData: null,
};

const reducer = createSlice({
  name: 'consultants',
  initialState,
  reducers: {
    updateModalFinanceByConsultantId(
      state,
      action: PayloadAction<string | null>,
    ) {
      if (action.payload) {
        const idx = state.consultantsList.content.findIndex(
          (consultant) => consultant._id === action.payload,
        );
        if (idx >= 0) {
          state.selectedConsultant = state.consultantsList.content[idx];
          state.isOpenModalFinance = true;
        }
      } else {
        state.selectedConsultant = null;
        state.isOpenModalFinance = false;
      }
    },
    updateModalAdminByConsultantId(
      state,
      action: PayloadAction<string | null>,
    ) {
      if (action.payload) {
        const idx = state.consultantsList.content.findIndex(
          (consultant) => consultant._id === action.payload,
        );
        if (idx >= 0) {
          state.selectedConsultant = state.consultantsList.content[idx];
          state.isOpenModalAdmin = true;
        }
      } else {
        state.selectedConsultant = null;
        state.isOpenModalAdmin = false;
      }
    },
    setIsShowConsultantModal(state, action: PayloadAction<boolean>) {
      state.isShowCreateConsultantModal = action.payload;
    },
    setConsultantList(
      state,
      action: PayloadAction<IGetListDto<IConsultantsResponse>>,
    ) {
      state.consultantsList.content = action.payload.result;
      state.consultantsList.pagination.totalElement = action.payload.total;
    },
    setConsultantsListPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.consultantsList.pagination.currentPage = action.payload.currentPage;
      state.consultantsList.pagination.perPage = action.payload.perPage;
    },
    setConsultantsListSorting(
      state,
      action: PayloadAction<[string, TSortValue]>,
    ) {
      state.consultantsList.sort.sortBy = action.payload[0];
      state.consultantsList.sort.sort = action.payload[1];
    },
    clearConsultantsFiltersAndSorts(state) {
      state.consultantsList.sort = initialState.consultantsList.sort;
      state.consultantsList.filter = initialState.consultantsList.filter;
      state.consultantsList.selectedConsultants =
        initialState.consultantsList.selectedConsultants;
    },
    changeConsultantsFiltering(
      state,
      action: PayloadAction<Record<string, string[] | string | boolean>>,
    ) {
      const obj = state.consultantsList.filter;
      if (
        action.payload['consultantDetails.approvedByAdmin'] === false &&
        action.payload['consultantDetails.approvedByFinance'] === false
      ) {
        delete obj['consultantDetails.approvedByAdmin'];
        delete obj['consultantDetails.approvedByFinance'];
        state.consultantsList.filter = obj;
      } else {
        state.consultantsList.filter = { ...obj, ...action.payload };
      }
    },
    setConsultantInviteData(
      state,
      action: PayloadAction<Omit<ICreateConsultantResponseDto, 'id'> | null>,
    ) {
      state.inviteData = action.payload;
    },
    updateConsultantById(state, action: PayloadAction<IConsultantsResponse>) {
      const currenConsultantIdx = state.consultantsList.content.findIndex(
        (consultant) => consultant._id === action.payload._id,
      );

      if (currenConsultantIdx >= 0) {
        state.consultantsList.content[currenConsultantIdx] = action.payload;
      }
    },
    selectConsultantListItem(state, action: PayloadAction<string[]>) {
      state.consultantsList.selectedConsultants = action.payload;
    },
  },
});

export const {
  setConsultantList,
  setConsultantsListPagination,
  setConsultantsListSorting,
  clearConsultantsFiltersAndSorts,
  changeConsultantsFiltering,
  setIsShowConsultantModal,
  setConsultantInviteData,
  updateConsultantById,
  updateModalFinanceByConsultantId,
  updateModalAdminByConsultantId,
  selectConsultantListItem,
} = reducer.actions;

export default reducer.reducer;
