import { ExpensesStatus } from '../api/services/ConsultantApi/enums/ResponseEnums';

export type TExpensesStatusParserKey =
  | ExpensesStatus.DRAFT
  | ExpensesStatus.WAITING_FOR_APPROVAL
  | ExpensesStatus.PRE_APPROVED
  | ExpensesStatus.APPROVED
  | ExpensesStatus.NO_EXPENSES
  | ExpensesStatus.EXPIRED
  | ExpensesStatus.REJECTED
  | ExpensesStatus.CLIENT_APPROVAL;

export const expensesStatusParser: Record<any, string> = {
  [ExpensesStatus.DRAFT]: 'draft',
  [ExpensesStatus.WAITING_FOR_APPROVAL]: 'waiting-for-approval',
  [ExpensesStatus.PRE_APPROVED]: 'pre-approved',
  [ExpensesStatus.APPROVED]: 'approved-consultant',
  [ExpensesStatus.NO_EXPENSES]: 'no-expenses',
  [ExpensesStatus.EXPIRED]: 'expired',
  [ExpensesStatus.REJECTED]: 'rejected',
  [ExpensesStatus.CLIENT_APPROVAL]: 'client-approval',
};
