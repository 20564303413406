import { FC } from 'react';

import './AuthFormHead.less';
import SmallLogoIcon from '../../assets/icons/SmallLogoIcon';

interface IProps {
  title: string;
  description?: string;
}

const AuthFormHead: FC<IProps> = ({ title, description }) => {
  return (
    <div className="auth-form-head">
      <div className="img-wrapper">
        <SmallLogoIcon />
      </div>
      <h3>{title}</h3>

      {description && <p>{description}</p>}
    </div>
  );
};

export default AuthFormHead;
