import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const AttachFileIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2198 8.91624L7.62189 14.5142C6.15742 15.9786 3.78305 15.9786 2.31859 14.5142V14.5142C0.85412 13.0497 0.85412 10.6753 2.31859 9.21087L9.09503 2.43443C10.0713 1.45812 11.6542 1.45812 12.6306 2.43443V2.43443C13.6069 3.41074 13.6069 4.99365 12.6306 5.96996L5.76994 12.8306C5.28179 13.3187 4.49033 13.3187 4.00217 12.8306V12.8306C3.51402 12.3424 3.51402 11.551 4.00217 11.0628L9.68428 5.38071"
        stroke="#0E2552"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AttachFileIcon;
