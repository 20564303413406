export interface IWorkContractRequest {
  companies: ICompany[];
  useExpensesForOptimization: boolean;
  useMealAllowance: boolean;
}

export interface IAddress {
  street: string;
  postCode: string;
  city: string;
  country: string;
  addressId?: string | null;
  vat: string | undefined;
  _id?: string;
  addressLines?: Array<string>;
}

export interface IWorkLocation {
  type: string;
  hybridActivity: string;
  officeAddress: string;
  hybridAddress: string;
}

export interface IOtherJob {
  active: boolean;
  isPrimaryJob: boolean;
  numeriqTime: number;
}

export enum SalaryType {
  PerHour = 'Per hour',
  PerDay = 'Per day',
  PerMonth = 'Per month (fixed)',
  Project = 'Project',
}

export enum TreasuryPaymentDate {
  ASAP = 'ASAP',
  IN_DAYS = '25th day of the month',
}

export interface ICompany {
  _id: string;
  clientId?: string | null;
  name: string;
  email: string;
  phone: string;
  hasAdditionalAccidentInsurance: boolean;
  incomeLossInsurance: string;
  lossOfIncome: number;
  hasTreasury: boolean;
  treasuryPaymentDate: string;
  hasSalaryAmount: boolean;
  salaryAmount: number;
  invoiceCurrency: string;
  paymentCurrency: string;
  jobTitle: string;
  projectDescription: string;
  startDate: string;
  endDate: string;
  indefinite: boolean;
  rate: number;
  type: string;
  currency: string;
  workLocation: IWorkLocation;
  otherJob: IOtherJob;
  overtimeAllowed: boolean;
  doubleTimeAllowed: boolean;
  receivedWorkContract: boolean;
  workContracLink: string;
  professionalAccident: number;
  nonProfessionalAccident: number;
  hasCBA: boolean;
  cbaOption: string | null;
  hasRepresentativeAllowance: boolean;
  representativeAmount: string | number;
  numeriqOffice: string;
  isReceived: boolean;
  isCctOfficial: boolean;
  seller: string;
  lossOfIncome5050?: boolean;
  accidentInsurance5050?: boolean;
}
