import { CSSProperties } from 'react';

export interface IPassProps {
  className?: string;
  onClick?: () => void;
  viewBox?: string;
  style?: CSSProperties;
}

export const passProps = (props: IPassProps): IPassProps => {
  const { className, onClick, viewBox, style } = props;

  const iconClassName = className ? `${className} svg-icon` : 'svg-icon';
  return {
    className: iconClassName,
    onClick,
    viewBox,
    style,
  };
};
