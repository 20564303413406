import { Key, useEffect, useState } from 'react';
import {
  IInvoice,
  IInvoiceData,
  InvoiceDataType,
  InvoiseStatuses,
} from '../../common/api/services/InvoiceApi/types/types';
import { invoiceApi } from '../../common/api/services/InvoiceApi/invoiceApi';
import CommonTable from '../../components/common-table/CommonTable';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  changeInvoicesFiltering,
  clearInvoicesFiltersAndSorts,
  selectInvoicesArray,
  selectInvoicesItem,
  setInvoicesList,
  setInvoicesPagination,
  setInvoicesSorting,
} from '../../redux/reducers/invoices/sliceReducer';
import { Row, Spin, TableProps, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { ISort, TSortValue } from '../../redux/types';
import {
  commonDateMonthFormat,
  commonDateSpaceFormat,
  commonMonthDateYearSpaceFormat,
  commonMonthYearSpaceFormat,
  commonYearDateFormat,
} from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import {
  TViewInvoiceStatusParserKey,
  viewIncoiceStatusParser,
} from '../../common/constants/timesheetStatusParser';
import { title } from 'process';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import { SalaryType } from '../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { formatAmount } from '../../common/utils/formatAmount';
import CommonDatePicker from '../../components/date-picker/CommonDatePicker';
import InputRowChange from './components/InputRowChange';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import PaymentCommentModal from './components/PaymentCommentModal';
import EditIcon from '../../assets/icons/EditIcon';
import '../../components/date-picker/DatePicker.less';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import MultipleDateModal from './components/MultipleDateModal';
import Button from '../../components/button/Button';
import ExitIcon from '../../assets/icons/ExitIcon';
import { downloadExel } from '../../common/api/utils/downloadExel';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import { useTablePeriodFilter } from '../../common/hooks/useTablePerionFilter';
import { numeriqOfficeOptions } from '../../common/constants/numeriqOfficeOptions';
import { VATType } from '../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { paymentTermsList } from '../../common/constants/paymentTermsList';
import { paymentDates } from '../../common/constants/permitDays';
import ChangeStatusTooltip from './components/ChangeStatusTooltip';
import {
  TableColorContants,
  TableColorStatusParser,
} from '../../components/common-table/common/tableColorConstants';

const InvoicesPage = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(location.search);
  const publicId = urlParams.get('publicId');
  const [invoices, setInvoices] = useState<InvoiceDataType>([]);
  const [selectedComment, setSelectedComment] = useState<IInvoiceData | null>(
    null,
  );
  const [isOpenMultipleDateModal, setIsOpenMultipleDateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { invoicesList, selectedInvoices, selectedInvoicesArray } =
    useAppSelector((state) => state.invoices);
  const { pagination, sort, filter } = invoicesList;
  const { currentPage, perPage, totalElement } = pagination;
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  const totalLeftToPayArray = selectedInvoicesArray.length
    ? selectedInvoicesArray
        .filter((item) => item && item.leftToPay)
        .map((item) =>
          item ? parseFloat(item.leftToPay.toString().replace("'", '')) : 0,
        )
    : [];

  const setTotalPay = () => {
    let totalPay = 0;
    totalLeftToPayArray.forEach((item) => {
      totalPay += item;
    });
    return formatAmount(totalPay.toFixed(2));
  };

  useEffect(() => {
    getInvoices();
  }, [currentPage, perPage, filter]);

  const getInvoices = (currentSort?: ISort, currentFilter?: any) => {
    const updatedCurrentFilter = publicId
      ? { ...currentFilter, consultantPublicId: publicId }
      : currentFilter;

    const updatedFilter = publicId
      ? { ...filter, consultantPublicId: publicId }
      : filter;

    setIsLoading(true);
    invoiceApi
      .getInvoicesList(
        currentPage,
        perPage,
        updatedCurrentFilter || updatedFilter,
        currentSort || sort,
      )
      .then((resp: any) => {
        setInvoices(resp?.result || []);
        //NP-1590 [FE] Invoices page. The "Total left to pay" value doesn't recalculate if change "Paid by client" value for selected invoice
        const newSelectedInvoicesArray = selectedInvoicesArray.length
          ? selectedInvoicesArray
              .filter((item) => item)
              .map((item) => {
                const selectedItem = resp?.result
                  ? resp?.result.find(
                      (respItem: any) =>
                        respItem.internalId === item.internalId,
                    )
                  : undefined;
                if (!selectedItem) {
                  return item;
                }
                return { ...item, leftToPay: selectedItem.leftToPay };
              })
          : [];
        dispatch(selectInvoicesArray(newSelectedInvoicesArray));
        dispatch(setInvoicesList(resp));
      })
      .catch((e) => openErrorNotification('Failed to get invoices'))
      .finally(() => setIsLoading(false));
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeInvoicesFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setInvoicesSorting(newSort));
      getInvoices({ sortBy: newSort[0], sort: newSort[1] as TSortValue });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setInvoicesPagination({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedObjects: InvoiceDataType,
  ): void => {
    dispatch(selectInvoicesItem(newSelectedRowKeys as string[]));
    dispatch(selectInvoicesArray(selectedObjects));
  };

  const rowSelection = {
    selectedRowKeys: selectedInvoices,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const checkByMultiple = (value: Array<string | null | number | boolean>) => {
    if (value?.length > 1) {
      return 'multiple';
    }
    if (!value?.length || !value[0]) {
      return '';
    }
    return value[0];
  };

  const onCloseCommentModal = () => {
    setSelectedComment(null);
  };

  const updatePaymentDate = (invoiceId: Array<string>, date: string) => {
    invoiceApi
      .updateInvoicePaymentDate(invoiceId, date)
      .then(() => {
        openSuccessNotification(
          'Invoice payment date was successfully updated',
        );
        setIsOpenMultipleDateModal(false);
      })
      .catch((e) => {
        openErrorNotification(
          e.response.data.message || 'Failed to update payment date',
        );
      })
      .finally(() => {
        onSelectChange([], []);
        getInvoices();
      });
  };

  const updateMultipleDate = (date: string) => {
    updatePaymentDate(selectedInvoices, date);
  };

  const onChangePaymentComment = (
    invoiceId: string,
    paymentComment: string | number,
  ) => {
    invoiceApi
      .updateInvoicePaymentComment(invoiceId, paymentComment.toString())
      .then(() => {
        openSuccessNotification(
          'Invoice payment comment was successfully updated',
        );
        setSelectedComment(null);
      })
      .catch((e) => {
        openErrorNotification(
          e.response.data.message || 'Failed to update payment comment',
        );
      })
      .finally(() => getInvoices(sort, filter));
  };

  const onChangePaidByClient = (invoiceId: string, amount: string | number) => {
    invoiceApi
      .updateInvoicePayByClient(invoiceId, +amount)
      .then(() => {
        openSuccessNotification(
          'Invoice paid by client was successfully updated',
        );
      })
      .catch((e) => {
        openErrorNotification(
          e.response.data.message || 'Failed to update paid by client',
        );
      })
      .finally(() => {
        onSelectChange([], []);
        getInvoices();
      });
  };

  const closeMultipleDate = () => {
    setIsOpenMultipleDateModal(false);
    getInvoices(sort, filter);
  };

  const checkIsOpenMultipleModal = (array: InvoiceDataType) => {
    return !array
      .slice()
      .every((item) =>
        [
          InvoiseStatuses.PAID,
          InvoiseStatuses.PARTIALLY_PAID,
          InvoiseStatuses.SENT,
        ].includes(item.status as any),
      );
  };

  const downloadExcel = () => {
    invoiceApi
      .downloadInvoiceExcel(filter, sort, selectedInvoices)
      .then((response: any) => {
        downloadExel(
          response,
          `Invoice_reporting_${moment().format(commonYearDateFormat)}`,
        );
      })
      .catch((e) => openErrorNotification('Failed to download excel'));
  };

  const getInvoicesByIds = () => {
    invoiceApi
      .getInvoices(selectedInvoices)
      .then((resp) => {
        if (resp.result)
          if (checkIsOpenMultipleModal(resp.result || [])) {
            openErrorNotification(
              'Not all picked invoices have status Partially paid, Paid or Sent',
            );
            return;
          }
        setIsOpenMultipleDateModal(true);
      })
      .catch((e) => {
        openErrorNotification(
          e.response.data.message || 'Failed to get invoices',
        );
      });
  };

  const data = invoices.map((item) => {
    const periods = checkByMultiple(item.periods);
    const hasTreasury = checkByMultiple(
      item.treasuries.map((item) => item.hasTreasury),
    );
    return {
      _id: item.internalId,
      publicId: item.publicId,
      entity: item.entity,
      contractNumber: checkByMultiple(item.contractNumbers) || 'No',
      consultantPublicId:
        checkByMultiple(item.consultants.map((item) => item?.publicId)) || 'No',
      firstName:
        checkByMultiple(item.consultants.map((item) => item?.firstName)) ||
        'No',
      lastName:
        checkByMultiple(item.consultants.map((item) => item?.lastName)) || 'No',
      workForm: checkByMultiple(item.workForms) || 'No',
      period:
        periods === 'multiple'
          ? 'multiple'
          : periods
          ? moment(periods as string).format(commonMonthYearSpaceFormat)
          : '',
      type: item.type,
      status: item.status,
      invoiceDate: item.invoiceDate
        ? moment(item.invoiceDate).format(commonDateSpaceFormat)
        : '',
      clientName: item.clientName,
      clientPublicId: item.clientPublicId,
      country: checkByMultiple(item.addresses.map((item) => item?.country)),
      treasury:
        hasTreasury === 'multiple'
          ? 'multiple'
          : hasTreasury
          ? item.treasuries[0]?.treasuryPaymentDate
          : 'No',
      vat: item.vat?.name,
      unit: checkByMultiple(item?.units) || 'No',
      billRate: checkByMultiple(item.billRates) || 'No',
      std: checkByMultiple(item.std) || 'No',
      ovrt: checkByMultiple(item.ovrt) || 'No',
      dblt: checkByMultiple(item.dblt) || 'No',
      expensesTotal: item.expensesTotal || 'No',
      currency: item.totalCalculations?.currency,
      fxRate: item?.fxRate?.rate,
      ht: formatAmount(item.totalCalculations?.total) || 'No',
      vatSum: formatAmount(item?.totalCalculations?.vat) || 'No',
      ttc: formatAmount(item?.totalCalculations?.totalWithVAT) || 'No',
      paymentTerm: item.paymentTerm,
      payByDate: item.payByDate
        ? moment(item.payByDate).format(commonMonthDateYearSpaceFormat)
        : '',
      daysLeftToPay: item.daysLeftToPay,
      paidByClient: item.paidByClient,
      leftToPay: formatAmount(item.leftToPay) || 'No',
      factoring: item.isClientFactoring ? 'Yes' : 'No',
      factoringInvoice: item.factoringInvoiceUnpaid,
      paymentComment: item.paymentComment,
      internalId: item.internalId,
      paymentDate: item.paymentDate,
      paymentTermRange: item.paymentTermRange,
    };
  });

  const columns: any = [
    {
      title: 'Entity',
      dataIndex: 'entity',
      key: 'entity',
      className: sortClassName('entity'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      fixed: 'left',
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.entity as string[]) ?? [],
        fieldFilterName: 'entity',
      })(numeriqOfficeOptions),
    },
    {
      title: 'Invoice #',
      dataIndex: 'publicId',
      key: 'publicId',
      className: `status-td`,
      fixed: 'left',
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.publicId as string) ?? '',
      })('publicId'),
      render: (text: string, data: any) => {
        return (
          <span
            className={`${
              TableColorStatusParser[data.status]
            } status-invoice-td`}>
            {text || 'No'}
          </span>
        );
      },
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      className: sortClassName('invoiceDate'),
      sorter: true,
      fixed: 'left',
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['invoiceDate'] ? (filter['invoiceDate'] as string) : '',
        field: 'invoiceDate',
      })(),
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      className: sortClassName('clientName'),
      sorter: true,
      fixed: 'left',
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      className: sortClassName('country'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.country as string) ?? '',
      })('country'),
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      className: sortClassName('firstName'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.firstName as string) ?? '',
      })('firstName'),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      className: sortClassName('lastName'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.lastName as string) ?? '',
      })('lastName'),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      className: sortClassName('period'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'period',
        placeholder: 'Period',
        defaultValue: (filter?.period as string) ?? '',
      })(),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      className: sortClassName('vat'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.vat as string[]) ?? [],
        fieldFilterName: 'vat',
      })(Object.values(VATType)),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      className: sortClassName('unit') + TableColorContants.blueColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.unit as string[]) ?? '',
        fieldFilterName: 'unit',
      })(Object.values(SalaryType)),
      render: (text: string) => <b>{text || ''}</b>,
    },
    {
      title: 'Bill rate',
      dataIndex: 'billRate',
      key: 'billRate',
      className: sortClassName('billRate') + TableColorContants.blueColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Std',
      dataIndex: 'std',
      key: 'std',
      className: sortClassName('std') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'Ovrt',
      dataIndex: 'ovrt',
      key: 'ovrt',
      className: sortClassName('ovrt') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'Dblt',
      dataIndex: 'dblt',
      key: 'dblt',
      className: sortClassName('dblt') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'Expenses',
      dataIndex: 'expensesTotal',
      key: 'expensesTotal',
      className: sortClassName('expensesTotal') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'HT',
      dataIndex: 'ht',
      key: 'ht',
      className: sortClassName('ht') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'VAT sum',
      dataIndex: 'vatSum',
      key: 'vatSum',
      className: sortClassName('vatSum') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'TTC',
      dataIndex: 'ttc',
      key: 'ttc',
      className: sortClassName('ttc') + TableColorContants.blueColor,
      width: 150,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      className: sortClassName('currency') + TableColorContants.blueColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.currency as string[]) ?? '',
        fieldFilterName: 'currency',
      })(['EUR', 'CHF', 'GBP', 'USD']),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: `status-td`,
      key: 'status',
      width: 150,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.status as string[]) ?? [],
        fieldFilterName: 'status',
      })(Object.values(InvoiseStatuses)),
      render: (text: TViewInvoiceStatusParserKey, data: any) => {
        return (
          <Tooltip
            title={
              <ChangeStatusTooltip
                id={data.internalId}
                getInvoices={getInvoices}
                isInvoicePage={true}
              />
            }
            placement="left"
            overlayClassName="overlay-tooltip-status">
            <div className="status-wrapper">
              <span className={TableColorStatusParser[text]}>
                {text || 'No'}
              </span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Payment term',
      dataIndex: 'paymentTerm',
      key: 'paymentTerm',
      className: sortClassName('paymentTerm') + TableColorContants.orangeColor,
      sorter: true,
      width: 180,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.paymentTerm as string) ?? '',
      })('paymentTerm'),
      render: (text: string, data: any) => (
        <span>{`${text} ${data.paymentTermRange || ''}`}</span>
      ),
    },
    {
      title: 'Due date',
      dataIndex: 'payByDate',
      key: 'payByDate',
      className: sortClassName('payByDate') + TableColorContants.orangeColor,
      sorter: true,
      width: 180,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['payByDate'] ? (filter['payByDate'] as string) : '',
        field: 'payByDate',
      })(),
    },
    {
      title: 'Days left',
      dataIndex: 'daysLeftToPay',
      key: 'daysLeftToPay',
      className:
        sortClassName('daysLeftToPay') + TableColorContants.orangeColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.daysLeftToPay as string) ?? '',
      })('daysLeftToPay'),
    },
    {
      title: 'Paid by client',
      dataIndex: 'paidByClient',
      key: 'paidByClient',
      className: sortClassName('paidByClient') + TableColorContants.orangeColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.paidByClient as string) ?? '',
      })('paidByClient'),
      render: (value: string, data: any) => (
        <InputRowChange
          data={data}
          defaultValue={value}
          onChangeValue={onChangePaidByClient}
          type="number"
        />
      ),
    },
    {
      title: 'Payment date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      className: 'date-picker-column-invoice' + TableColorContants.orangeColor,
      sorter: true,
      width: 180,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableDateFilterByField({
        ...searchFilterArg,
        values: filter['paymentDate'] ? (filter['paymentDate'] as string) : '',
        field: 'paymentDate',
      })(),
      render: (text: string, dataInvoice: any) => {
        const onChangeDate = (date: any) => {
          updatePaymentDate([dataInvoice.internalId], date);
        };
        const selectedDate = dataInvoice.paymentDate;
        return (
          <Row align="middle" style={{ position: 'relative' }}>
            <CommonDatePicker
              defaultValue={selectedDate || ''}
              changeDate={onChangeDate}
              disabled={
                ![
                  InvoiseStatuses.PAID,
                  InvoiseStatuses.PARTIALLY_PAID,
                  InvoiseStatuses.SENT,
                ].includes(dataInvoice.status)
              }
            />
          </Row>
        );
      },
    },
    {
      title: 'Payment comment',
      dataIndex: 'paymentComment',
      key: 'paymentComment',
      className:
        sortClassName('paymentComment') + TableColorContants.orangeColor,
      width: 150,
      render: (value: string, data: any) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={() => setSelectedComment(data)}>
          <span>
            {value && value.length > 8
              ? `${value.slice(0, 8)}...`
              : value || ''}
          </span>
          <EditIcon />
        </div>
      ),
    },
    {
      title: 'Left to pay',
      dataIndex: 'leftToPay',
      key: 'leftToPay',
      className: sortClassName('leftToPay') + TableColorContants.orangeColor,
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.leftToPay as string) ?? '',
      })('leftToPay'),
    },
    {
      title: 'FX Rate',
      dataIndex: 'fxRate',
      key: 'fxRate',
      className: sortClassName('fxRate'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.fxRate as string) ?? '',
      })('fxRate'),
    },
    {
      title: 'Invoice type',
      dataIndex: 'type',
      key: 'type',
      className: sortClassName('type'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.type as string[]) ?? [],
        fieldFilterName: 'type',
      })(['Regular', 'Deposit', 'Credit note']),
    },
    {
      title: 'Client #',
      dataIndex: 'clientPublicId',
      key: 'clientPublicId',
      className: sortClassName('clientPublicId'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.clientPublicId as string) ?? '',
      })('clientPublicId'),
    },
    {
      title: 'Consultant ID',
      dataIndex: 'consultantPublicId',
      key: 'consultantPublicId',
      className: sortClassName('consultant.publicId'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.consultantPublicId as string) ?? '',
        searchAsString: false,
      })('consultantPublicId'),
    },
    {
      title: 'Payroll/Corp',
      dataIndex: 'workForm',
      key: 'workForm',
      className: sortClassName('workForm'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.workForm as string[]) ?? [],
        fieldFilterName: 'workForm',
      })(['payroll', 'corporation']),
    },
    {
      title: 'Treasury',
      dataIndex: 'treasury',
      key: 'treasury',
      className: sortClassName('treasury'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.treasury as string[]) ?? [],
        fieldFilterName: 'treasury',
        isNoValue: true,
      })([...paymentDates, 'No']),
    },

    {
      title: 'Factoring',
      dataIndex: 'factoring',
      key: 'factoring',
      className: sortClassName('factoring'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.factoring as string[]) ?? [],
        fieldFilterName: 'factoring',
      })(['Yes', 'No']),
    },
    {
      title: 'Factoring invoice unpaid',
      dataIndex: 'factoringInvoice',
      key: 'factoringInvoice',
      className: sortClassName('factoringInvoice'),
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.factoringInvoice as string) ?? '',
      })('factoringInvoice'),
    },

    {
      title: 'Deal #',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      sorter: true,
      width: 150,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        searchAsString: false,
        defaultValue: (filter?.contractNumber as string) ?? '',
      })('contractNumber'),
    },
  ];

  const onDoubleClick = (record: any) => {
    window.open(
      `/generate-invoice/${record.internalId}${
        record.type === 'Deposit' ? '/deposit' : ''
      }`,
      '_blank',
    );
  };

  return (
    <div style={{ height: '100%' }}>
      <div className="header-section">
        <Breadcrumb />
        <div className="right-header-section" style={{ gap: '10px' }}>
          {selectedInvoicesArray.length && selectedInvoices.length ? (
            <span style={{ fontWeight: 'bold' }}>
              Total left to pay: {setTotalPay()}
            </span>
          ) : null}
          {(isHasFilters || selectedInvoices.length > 0) && (
            <button
              className="rest-filters-button"
              onClick={() => {
                onSelectChange([], []);
                dispatch(clearInvoicesFiltersAndSorts());
                getInvoices({ sort: '', sortBy: '' }, []);
              }}>
              <ResetFilterIcon />
            </button>
          )}
          <Button
            onClick={() => {
              downloadExcel();
            }}
            buttonStyle="secondary"
            buttonSize="medium"
            buttonWidth={200}
            leftIcon={<ExitIcon className="export-icon" />}>
            Export to Excel
          </Button>
          {(isHasFilters || selectedInvoices.length > 0) && (
            <Button
              buttonSize="medium"
              buttonStyle="primary"
              onClick={() => {
                getInvoicesByIds();
              }}>
              Set up the payment date
            </Button>
          )}
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="container-payslips invoice-table-container">
          <CommonTable
            paginationCurrentPage={currentPage}
            paginationPageSize={perPage}
            paginationOnChange={onChangePagination}
            paginationTotalElement={totalElement}
            data={data}
            columns={columns}
            onChange={onChangeSort}
            onDoubleClick={onDoubleClick}
            rowSelection={rowSelection}
            rowKey="internalId"
          />
        </div>
      </Spin>
      {selectedComment && (
        <PaymentCommentModal
          isOpen={!!selectedComment}
          onCloseModal={onCloseCommentModal}
          selectedInvoice={selectedComment}
          onSubmit={onChangePaymentComment}
        />
      )}
      {isOpenMultipleDateModal && (
        <MultipleDateModal
          isOpen={isOpenMultipleDateModal}
          onCloseModal={closeMultipleDate}
          onSubmit={updateMultipleDate}
        />
      )}
    </div>
  );
};

export default InvoicesPage;
