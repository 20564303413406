import { FC } from 'react';
import { PaginationProps, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import AngleIcon from '../../assets/icons/AngleIcon';

import './CommonTable.less';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useWindowDimensions } from '../../common/hooks/useWindowDimensions';
import TableMobile from './TableMobile';
import { ISort } from '../../redux/types';

interface IProps {
  columns: any;
  data: any[];
  paginationTotalElement?: number;
  paginationOnChange?: (page: number, size: number) => void;
  paginationPageSize?: number;
  paginationCurrentPage?: number;
  onChange?: TableProps<any>['onChange'];
  rowSelection?: TableRowSelection<any>;
  rowKey?: string;
  onDoubleClick?: (record: any) => void;
  hasRowDatePicker?: boolean;
  hidePagination?: boolean;
  loading?: boolean;
  height?: number;
  tableHeight?: number;
  submitFilters?: any;
  sortBy?: ISort;
  onChangeMobileSort?: (sort: ISort) => void;
}

const CommonTable: FC<IProps> = ({
  columns,
  data,
  paginationTotalElement,
  paginationOnChange,
  paginationCurrentPage,
  paginationPageSize,
  onChange,
  rowSelection,
  rowKey,
  onDoubleClick,
  hasRowDatePicker,
  hidePagination,
  loading,
  height,
  tableHeight,
  submitFilters,
  sortBy,
  onChangeMobileSort,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <a href="#">
          <AngleIcon />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a href="#">
          <AngleIcon />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <>
      <Table
        className={`common-table ${
          hasRowDatePicker ? 'table-row-date-picker' : ''
        }`}
        columns={columns}
        dataSource={data}
        pagination={
          hidePagination
            ? false
            : {
                itemRender,
                total: paginationTotalElement,
                onChange: paginationOnChange,
                current: paginationCurrentPage,
                pageSize: paginationPageSize,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 50, 100, 200],
              }
        }
        scroll={{ x: 400, y: height || windowHeight - 350 }}
        onChange={onChange}
        style={{ height: tableHeight || windowHeight - 250 }}
        rowSelection={rowSelection}
        rowKey={rowKey}
        loading={loading || false}
        onRow={(record) => {
          return {
            onDoubleClick: (e: any) => {
              if (
                e.target?.type === 'checkbox' ||
                e.target?.className === 'ant-select-selection-item'
              ) {
                return;
              }
              onDoubleClick && onDoubleClick(record);
            },
          };
        }}
      />
      {/* TODO: hook useMedia */}
      {window?.screen.width <= 768 && (
        <div className="table-mobile-container">
          <TableMobile
            columns={columns}
            data={data}
            onChangePagination={paginationOnChange}
            paginationCurrentPage={paginationCurrentPage}
            paginationPageSize={paginationPageSize}
            paginationTotalElement={paginationTotalElement}
            submitFilters={submitFilters}
            sortBy={sortBy}
            onChangeMobileSort={onChangeMobileSort}
          />
        </div>
      )}
    </>
  );
};

export default CommonTable;
