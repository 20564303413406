import {
  IClientAddressResponse,
  IClientContact,
} from '../api/services/ClientApi/types/IClientResponse';
import { IAddress } from '../api/services/OnboardingApi/types/IWorkContractRequest';

export const compareAddresses = (
  array1: IAddress[],
  array2: IClientAddressResponse[],
) => {
  return array1.every((obj1) =>
    array2.some(
      (obj2) =>
        obj1.city === obj2.city &&
        obj1.country === obj2.country &&
        obj1.postCode === obj2.postCode &&
        obj1.street === obj2.street,
    ),
  );
};

export const compareDynamicFields = (array1: any, array2: any) => {
  return array1.every((obj1: any) =>
    array2.some(
      (obj2: any) =>
        obj1.email === obj2.email &&
        obj1.lastName === obj2.lastName &&
        obj1.name === obj2.name,
    ),
  );
};

export const compareClientContacts = (
  array1: IClientContact[],
  array2: IClientContact[],
) => {
  return array1.every((obj1) =>
    array2.some(
      (obj2) =>
        (obj1.email || '') === (obj2.email || '') &&
        (obj1.lastName || '') === (obj2.lastName || '') &&
        (obj1.name || '') === (obj2.name || '') &&
        (obj1.phone || '') === (obj2.phone || ''),
    ),
  );
};
