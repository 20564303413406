export interface IMainActivity {
  _id: string;
  creationMode: string;
  visibility: string;
  clientId: string;
  status: string;
  period: string;
  createdAt: string;
  updatedAt: string;
  publicId: string;
  kind: string;
  consultant: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    status: string;
    publicId: 143;
  };
  clientName: string;
  projectInfo: string;
  projectDescription: string;
}

export type MainActivitiesType = Array<IMainActivity>;

export const mainActivitiesTypes = ['T', 'EO', 'EC', 'MA'];
