import { FC } from 'react';
import { Col, Modal, Row } from 'antd';
import Button from '../../../components/button/Button';

interface IProps {
  hideModal: () => void;
  fullName: string;
  submit: () => void;
  isApproved: boolean;
  isOpen: boolean;
}

const ModalByFinanceAndAdmin: FC<IProps> = ({
  hideModal,
  fullName,
  isApproved,
  submit,
  isOpen,
}) => {
  const onSubmit = () => {
    submit();
    hideModal();
  };

  const submitTextBtn = isApproved ? 'Yes, remove' : 'Yes, approve';

  const modalText = isApproved
    ? 'Are you sure you want to remove approval for'
    : 'Are you sure you want to approve';

  return (
    <Modal
      width={600}
      title={isApproved ? 'Remove approval' : 'Approve consultant'}
      open={isOpen}
      onCancel={hideModal}
      centered
      footer={null}
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <p className="archive-text" style={{ color: '#10132F' }}>
        {modalText + ' '}
        <b>{fullName}?</b>
      </p>
      <Row gutter={[16, 0]} className="archive-buttons">
        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={hideModal}>
            Cancel
          </Button>
        </Col>

        <Col span={12}>
          <Button
            buttonWidth="100%"
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onSubmit}>
            {submitTextBtn}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalByFinanceAndAdmin;
