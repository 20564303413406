import { ChangeEvent, FC } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import Input from '../../../../components/input/Input';
import Textarea from '../../../../components/textarea/Textarea';
import { ISsnInformationRequest } from '../../../../common/api/services/OnboardingApi/types/IAvsAhvRequest';
import Dropdown from '../../../../components/dropdown/Dropdown';
import { LPPLevel } from '../../../../common/api/services/OnboardingApi/enums/OnboardingLPPLevel';
import PopoverHelpInfo from '../../../../components/popover-help-info/PopoverHelpInfo';
import { Roles } from '../../../../common/enums/Roles';
import { useAppSelector } from '../../../../redux/store';
import InfoIcon from '../../../../assets/icons/InfoIcon';

interface IProps {
  ssnInformation: ISsnInformationRequest;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | RadioChangeEvent,
  ) => void;
  onChangeDropdown: (fieldName: string) => (fieldValue: string) => void;
  getError: (fieldName: string) => string;
  isCorporation: boolean;
}

const listLPPLevel = Object.values(LPPLevel);

const AvsAhvTabInformationSection: FC<IProps> = (props) => {
  const {
    ssnInformation,
    onChange,
    onChangeDropdown,
    getError,
    isCorporation,
  } = props;
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  const {
    hasLppRegistration,
    hasSSN,
    ssn,
    lppLevel,
    lppAmount,
    comment,
    annualSalaryAmount,
    hasRegistration,
  } = ssnInformation;

  return (
    <Col span={12} className="avs-ahv">
      {isCorporation && (
        <div className="information-message">
          <div className="icon-wrapper">
            <InfoIcon />
          </div>
          <p>
            As you work as a Corporation you don’t need to fill information for
            a pension. You can skip this step.
          </p>
        </div>
      )}
      <h4>Information</h4>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}
        className="my-information-avs-avh">
        <Col>
          <span>Do you have an AVS/AHV number?</span>
          <PopoverHelpInfo
            placement="right"
            title={
              <p>
                AVS/AHV = your social security number. <br />
                It starts 756 And can be found on your health insurance card
                and/or payslips. <br />
                If you don’t have one yet leave blank
              </p>
            }
          />
        </Col>
        <Col>
          <Radio.Group
            name="hasSSN"
            value={hasSSN}
            onChange={onChange}
            disabled={isCorporation}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="ssn"
            label="AVS/AHV Number"
            value={ssn}
            onChange={onChange}
            errorMessage={getError('data.ssn')}
            disabled={!hasSSN || isCorporation}
            placeholder="756.XXXX.XXXX.XX"
          />
          <div className="info-text">Example: 756.8289.0844.92</div>
        </Col>
      </Row>
      {isAdmin && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span>
              Do you have a registration?<span className="asterisk">*</span>
            </span>
          </Col>
          <Col>
            <Radio.Group
              name="hasRegistration"
              value={hasRegistration}
              disabled={isCorporation}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="annualSalaryAmount"
              value={`${annualSalaryAmount}`}
              onChange={onChange}
              label="Annual AVS/AHV salary amount"
              errorMessage={getError('data.annualSalaryAmount')}
              placeholder="Number"
              disabled={isCorporation}
              inputType="number"
              showAsterisk
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={22}>
          <Dropdown
            options={listLPPLevel}
            value={lppLevel}
            onChange={onChangeDropdown('lppLevel')}
            label="LPP level"
            showSearch={false}
            disabled={isCorporation}
            errorMessage={getError('data.lppLevel')}
            allowClear
            onClear={() => onChangeDropdown('lppLevel')('')}
          />
        </Col>
        <PopoverHelpInfo
          title={
            <p>
              Loi sur la Prévoyance Professionelle also known as BVG in German.
              <br />
              You can find this info in your salary simulation in line 20
            </p>
          }
        />
      </Row>

      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={22}>
            <Input
              name="lppAmount"
              value={`${lppAmount}`}
              onChange={onChange}
              label="LPP amount CHF"
              errorMessage={getError('data.lppAmount')}
              placeholder="Number"
              showAsterisk
              disabled={isCorporation}
            />
          </Col>
          <PopoverHelpInfo
            title={
              <p>You can find this info in your salary simulation in line 20</p>
            }
          />
        </Row>
      )}

      {isAdmin && (
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span>
              Do you have a LPP registration?<span className="asterisk">*</span>
            </span>
          </Col>
          <Col>
            <Radio.Group
              name="hasLppRegistration"
              value={hasLppRegistration}
              disabled={isCorporation}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Textarea
              name="comment"
              rows={4}
              label="Comments*"
              onChange={onChange}
              errorMessage={''}
              value={comment}
              autoSize={false}
              disabled={isCorporation}
            />
            <div className="info-text">{comment?.length || 0}/200</div>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default AvsAhvTabInformationSection;
