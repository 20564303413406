import { ComponentType } from 'react';
import { useAppDispatch } from '../../redux/store';
import {
  addClientWorkContractAsConsultant,
  deleteClientWorkContractAsConsultant,
} from '../../redux/reducers/onboarding/tabs/workContractAsyncThunk';

interface IProps {
  onAddNewClient: () => void;
  deleteClientWorkContract: () => void;
}

export const withConsultantWorkContractDeletingAndAddingContract = (
  WrappedComponent: ComponentType<IProps>,
) => {
  const Component = () => {
    const dispatch = useAppDispatch();

    const onAdd = () => {
      dispatch(addClientWorkContractAsConsultant());
    };

    const onDelete = () => {
      dispatch(deleteClientWorkContractAsConsultant());
    };

    return (
      <WrappedComponent
        deleteClientWorkContract={onDelete}
        onAddNewClient={onAdd}
      />
    );
  };

  return Component;
};
