import { FC, ReactElement } from 'react';
import loginImg from '../../assets/images/login-image.jpg';

import './AuthWrapperBackground.less';

interface IProps {
  children: ReactElement[] | ReactElement;
}

const AuthWrapperBackground: FC<IProps> = ({ children }) => {
  return (
    <div className="auth-wrapper-background">
      <div className="img-section">
        <img src={loginImg} alt="decorative for login" />
      </div>
      <div className="wrapper-content-section">
        <div className="content-section-head">
          {/*<InternationalDropdown />*/}
        </div>
        <div className="content-section-body">{children}</div>
      </div>
    </div>
  );
};

export default AuthWrapperBackground;
