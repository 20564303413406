import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const InfoIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.66675"
        y="1.66663"
        width="16.6667"
        height="16.6667"
        rx="4.16667"
        stroke="#0671E0"
        strokeWidth="1.5"
      />
      <path
        d="M10.417 14.1666L10.417 9.16663"
        stroke="#0671E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75033 9.16663L10.417 9.16663"
        stroke="#0671E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.417 6.66659L10.417 5.83325"
        stroke="#0671E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoIcon;
