import { ApiServiceBase } from '../../ApiServiceBase';
import { ISort } from '../../../../redux/types';
import { IGetListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';
import { IClientResponse } from './types/IClientResponse';
import { IClientRequest } from './types/IClientRequest';
import { IUpdateUnboardingProfile } from '../OnboardingApi/types/IUpdateUnboardingProfile';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../../components/notification/Notification';
import { ClientRoutes } from './enums/ClientRoutes';
import {
  IDeleteClientEntityRequest,
  IUpdateClientEntityRequest,
} from './types/IUpdateClientEntity';
import { TAnyObject } from '../../../types/TAnyObject';
import { checkArrayEmptyObj } from '../../../utils/checkArrayEmptyObj';
import { downloadExel } from '../../utils/downloadExel';

const convertQueryClient = (query: IClientRequest) => {
  const invoiceries = checkArrayEmptyObj(query.invoiceries);
  const reminders = checkArrayEmptyObj(query.reminders);
  const signatories = checkArrayEmptyObj(query.signatories);
  const clientContacts = checkArrayEmptyObj(query.clientContacts);
  const addresses = checkArrayEmptyObj(query.addresses);
  const emails = checkArrayEmptyObj(query.emails);
  const phones = checkArrayEmptyObj(query.phones);
  return {
    ...query,
    invoiceries,
    reminders,
    signatories,
    clientContacts,
    addresses,
    emails,
    phones,
  };
};
class ClientApi extends ApiServiceBase {
  protected baseUrl = '/api/v1/client';

  getClients(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IClientResponse> | null> {
    return this.get<IGetListDto<IClientResponse>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  getClientById(clientId: string): Promise<IClientResponse | null> {
    return this.get<IClientResponse>('/' + clientId)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  createClient(query: IClientRequest): Promise<IUpdateUnboardingProfile> {
    return this.post<IClientRequest, IClientResponse>(
      '',
      convertQueryClient(query),
    )
      .then((response) => {
        openSuccessNotification('Client has been created');
        return {
          isSuccess: true,
          data: response,
        };
      })
      .catch((e) => {
        if (e.response.status !== 400) {
          openErrorNotification('Client not created');
        }
        if (e.response.status === 400 && e.response.data?.message) {
          openErrorNotification(e.response.data.message);
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  updateClient(
    clientId: string,
    query: IClientRequest,
  ): Promise<IUpdateUnboardingProfile> {
    return this.put<IClientRequest, IClientResponse>(
      '/' + clientId,
      convertQueryClient(query),
    )
      .then((response) => {
        openSuccessNotification('Client has been updated');
        return {
          isSuccess: true,
          data: response,
        };
      })
      .catch((e) => {
        if (e.response.status !== 400) {
          openErrorNotification('Client not updated');
        }
        if (e.response.status === 400 && e.response.data?.message) {
          openErrorNotification(e.response.data.message);
        }
        return {
          isSuccess: false,
          data: parsErrorMessageObj(e),
        };
      });
  }

  addNewEntity(
    clientId: string,
    data: IUpdateClientEntityRequest,
  ): Promise<TAnyObject | string> {
    return this.post<IUpdateClientEntityRequest, IClientResponse>(
      '/' + clientId + '/' + ClientRoutes.entity,
      data,
    )
      .then((res) => {
        const responseData = res[data.entity].sort(
          (a, b) =>
            new Date(a.createdAt || '').getTime() -
            new Date(b.createdAt || '').getTime(),
        );
        return responseData[responseData.length - 1];
      })
      .catch(() => data.entity + ' has not been added'); //TODO
  }

  deleteEntity(
    clientId: string,
    data: IDeleteClientEntityRequest,
  ): Promise<IClientResponse | string> {
    return this.delete<IClientResponse>(
      '/' + clientId + '/' + ClientRoutes.entity,
      data,
    )
      .then((res) => res)
      .catch((e) => e.response.data.message);
  }

  deleteEntityWithUpdate(
    prevId: string | null,
    data: IDeleteClientEntityRequest,
    clientId: string,
  ): Promise<IClientResponse | string> {
    return this.put('/' + clientId + '/entity/' + prevId, data)
      .then((res) => res)
      .catch((e) => e.response.data.message);
  }

  deleteClient(clientId: string): Promise<IClientResponse | null> {
    return this.delete<IClientResponse>('/' + clientId)
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
  exportToExel(
    filter: Record<string, string | boolean | string[]>,
    sort: ISort,
    selectedIds: string[],
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    return this.get<any>(`/export`, {
      query,
      responseType: 'blob',
    })
      .then((response) => downloadExel(response))
      .catch(() => null);
  }
}

export const clientApi = new ClientApi();
