import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  employeePayslipsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
  selectedPayslips: [],
};

const reducer = createSlice({
  name: 'employee-payslips',
  initialState,
  reducers: {
    setEmployeePayslipsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.employeePayslipsList.content = action.payload.result;
      state.employeePayslipsList.pagination.totalElement = action.payload.total;
    },
    changeEmployeePayslipsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.employeePayslipsList.filter = {
        ...state.employeePayslipsList.filter,
        ...action.payload,
      };
      state.employeePayslipsList.pagination.currentPage = 1;
    },
    setEmployeePayslipsSorting(state, action: PayloadAction<any>) {
      state.employeePayslipsList.sort.sortBy = action.payload[0];
      state.employeePayslipsList.sort.sort = action.payload[1];
    },
    clearEmployeePayslipsFiltersAndSorts(state) {
      state.employeePayslipsList.sort = initialState.employeePayslipsList.sort;
      state.employeePayslipsList.filter =
        initialState.employeePayslipsList.filter;
    },
    setEmployeePayslipsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.employeePayslipsList.pagination.currentPage =
        action.payload.currentPage;
      state.employeePayslipsList.pagination.perPage = action.payload.perPage;
    },
    selectEmployeePayslipsItem(state, action: PayloadAction<string[]>) {
      state.selectedPayslips = action.payload;
    },
  },
});

export const {
  setEmployeePayslipsList,
  changeEmployeePayslipsFiltering,
  setEmployeePayslipsSorting,
  clearEmployeePayslipsFiltersAndSorts,
  setEmployeePayslipsPagination,
  selectEmployeePayslipsItem,
} = reducer.actions;

export default reducer.reducer;
