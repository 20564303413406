import { ApiServiceBase } from '../../ApiServiceBase';
import { ISort } from '../../../../redux/types';
import { IGetListDto } from '../../types/IGetListDto';
import { getQueryParams } from '../../utils/getQueryParams';
import { ReportingRoutes } from './enums/ReportingRoutes';
import {
  IDownloadZipReportingRequest,
  IReportingResponse,
} from './types/IReportingResponse';

class ReportingApi extends ApiServiceBase {
  protected readonly baseUrl = '/api/v1/reports';

  getReporting(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IReportingResponse[]> | null> {
    return this.get<IGetListDto<IReportingResponse[]>>(
      `/${ReportingRoutes.consultants}/${ReportingRoutes.total}`,
      {
        query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
      },
    )
      .then((response) => response)
      .catch(() => null);
  }

  exportToExel(
    filter: Record<string, string | string[]>,
    sort: ISort,
    selectedIds: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.get<any>(
      `/${ReportingRoutes.consultants}/${ReportingRoutes.total}/${ReportingRoutes.export}`,
      {
        query,
        responseType: 'blob',
      },
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => null);
  }
  downloadReportingZip(body: IDownloadZipReportingRequest) {
    return this.post('/consultants/total/zip', body, { responseType: 'blob' });
  }
  getWorkPermitList(
    currentPage: number,
    perPage: number,
    filter: Record<string, string | string[]>,
    sort: ISort,
  ): Promise<IGetListDto<IReportingResponse[]> | null> {
    return this.get<IGetListDto<IReportingResponse[]>>(`/work-permit`, {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch(() => null);
  }
  exportToExelWorkPermit(
    filter: Record<string, string | string[]>,
    sort: ISort,
    selectedIds: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const query = { ...getQueryParams(filter, sort) };
    if (selectedIds.length) {
      query.selectedIds = selectedIds.toString();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.get<any>(`/work-permit/${ReportingRoutes.export}`, {
      query,
      responseType: 'blob',
    });
  }
}

export const reportingApi = new ReportingApi();
