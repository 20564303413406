import { Col, Modal, Row, Spin, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import Dropdown from '../../../components/dropdown/Dropdown';
import ILabelInValue from '../../../common/types/ILabelInValue';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import Button from '../../../components/button/Button';
import DropzoneIcon from '../../../assets/icons/DropzoneIcon';
import CrossIcon from '../../../assets/icons/CrossIcon';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  fileList: any;
  uploadFiles: (info: UploadChangeParam<UploadFile>) => void;
  selectedType: ILabelInValue;
  onChangeType: (value: ILabelInValue) => void;
  onChangeDate: (value: Date | null) => void;
  onSubmit: () => void;
  errorMessages: Array<{ message: string; property: string }>;
  isLoading: boolean;
}

const options = [
  { label: 'Payslip', value: 'payslip' },
  { label: 'Simulation', value: 'salary_simulation' },
];

const UploadFilesModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    fileList,
    uploadFiles,
    selectedType,
    onChangeType,
    onChangeDate,
    onSubmit,
    errorMessages,
    isLoading,
  } = props;

  const getError = (fileName: string): string => {
    return (
      errorMessages.find((item) => item.property === fileName)?.message || ''
    );
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      centered
      destroyOnClose={true}
      wrapClassName="modal-window-wrapper">
      <div>
        <Spin spinning={isLoading}>
          <div style={{ padding: '30px 0' }}>
            <h4>Upload files</h4>
            <Row style={{ paddingTop: 24 }}>
              <Col span={24}>
                <CommonDatePicker
                  picker="month"
                  label="Period"
                  defaultValue={''}
                  changeDate={onChangeDate}
                  className="date-picker-form period-form"
                  errorMessage={getError('period')}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '23px' }}>
              <Col span={24}>
                <Dropdown
                  labelInValue={true}
                  options={options}
                  value={selectedType}
                  onChange={onChangeType}
                  label="Type of the document"
                  showSearch={false}
                  errorMessage={getError('type')}
                />
              </Col>
            </Row>
            <Row
              style={{ marginTop: '16px' }}
              className="upload-files-activity">
              <Col span={24}>
                <Upload
                  beforeUpload={() => false}
                  multiple
                  style={{ width: '100%' }}
                  fileList={fileList}
                  onChange={uploadFiles}>
                  <div
                    className="dropzone-section"
                    style={{ height: '230px', width: '100%' }}>
                    <div
                      className="dropzone-wrapper"
                      style={{ color: '#10132f' }}>
                      <div className="dropzone">
                        <div className="dropzone-content">
                          <DropzoneIcon />

                          <p className="main-text">
                            To upload click or drag files to this area.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Upload>
                {getError('files') && (
                  <span className="error-text" style={{ margin: 0 }}>
                    <div className="icon-wrapper">
                      <CrossIcon />
                    </div>
                    {getError('files')}
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonStyle="secondary"
                buttonSize="normal"
                buttonWidth="100%"
                onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                buttonStyle="primary"
                buttonSize="normal"
                buttonWidth="100%"
                onClick={() => onSubmit()}>
                Submit
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
