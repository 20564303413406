import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setOnboardingErrorValidation,
  setOnboardingTabStatus,
} from './../sliceReducer';
import { onboardingApi } from '../../../../common/api/services/OnboardingApi/onboardingApi';
import { IRootState } from '../../../types';
import { push } from 'redux-first-history';
import { getOnboardingRedirect } from '../../../../common/utils/onboardingRedirectLinks';
import { IQueryParameters } from '../../../../common/api/services/OnboardingApi/types/IQueryParameters';
import { OnboardingRequestKeys } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestKeys';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { OnboardingRequestTypes } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestTypes';
import { ISsnInformationRequest } from '../../../../common/api/services/OnboardingApi/types/IAvsAhvRequest';
import { IOnboardingSaveOptions } from './../types';
import { getOnboardingProfile } from '../actionAsyncThunk';

export const saveDraftAvsAhvAsEmployee = createAsyncThunk(
  'onboarding/saveDraftAvsAhv',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { ssnInformation, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const isDraft =
      tabStatus[6] === OnboardingRequestStatuses.draft ||
      tabStatus[6] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: ssnInformation,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putAvsAhvAsEmployee(
        consultantId,
        dataQuery,
      );

      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 6, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 6,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'ssnInformation',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const saveToConfirmAvsAhvAsEmployee = createAsyncThunk(
  'onboarding/saveToConfirmAvsAhv',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { ssnInformation, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status: OnboardingRequestStatuses.toConfirm,
      type: OnboardingRequestTypes.optional,
      data: ssnInformation,
    };

    const { isSuccess, data } = await onboardingApi.putAvsAhvAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      if (moveToNextPage) {
        dispatch(push(getOnboardingRedirect(tabStatus, 6, redirectRootRout)));
      }
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value: OnboardingRequestStatuses.toConfirm,
        }),
      );
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'ssnInformation',
          fieldValue: data,
        }),
      );
    }
  },
);

export const saveDraftAvsAhvAsConsultant = createAsyncThunk(
  'onboarding/saveDraftAvsAhvAsConsultant',
  async (options: IOnboardingSaveOptions, { getState, dispatch }) => {
    const { ssnInformation, tabStatus } = (getState() as IRootState).onboarding;
    const isDraft =
      tabStatus[6] === OnboardingRequestStatuses.draft ||
      tabStatus[6] === OnboardingRequestStatuses.empty;
    const { moveToNextPage, redirectRootRout } = options;

    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status: OnboardingRequestStatuses.draft,
      type: OnboardingRequestTypes.required,
      data: ssnInformation,
    };

    if (isDraft) {
      const { isSuccess, data } = await onboardingApi.putAvsAhvAsConsultant(
        dataQuery,
      );
      if (data === 120009) {
        dispatch(getOnboardingProfile());
        return;
      }
      if (isSuccess) {
        if (moveToNextPage) {
          dispatch(push(getOnboardingRedirect(tabStatus, 6, redirectRootRout)));
        }
        dispatch(
          setOnboardingTabStatus({
            key: 6,
            value: OnboardingRequestStatuses.draft,
          }),
        );
      } else {
        dispatch(
          setOnboardingErrorValidation({
            fieldName: 'ssnInformation',
            fieldValue: data,
          }),
        );
      }
    }
  },
);

export const savePublishAvsAhvAsEmployee = createAsyncThunk(
  'onboarding/savePublishAvsAhv',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { ssnInformation, tabStatus, consultantId } = (
      getState() as IRootState
    ).onboarding;

    const { workForm } = ssnInformation;

    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status:
        ssnInformation.hasSSN || workForm === 'corporation'
          ? OnboardingRequestStatuses.published
          : OnboardingRequestStatuses.toConfirm,
      type:
        ssnInformation.hasSSN && workForm !== 'corporation'
          ? OnboardingRequestTypes.required
          : OnboardingRequestTypes.optional,
      data: ssnInformation,
    };

    const { isSuccess, data } = await onboardingApi.putAvsAhvAsEmployee(
      consultantId,
      dataQuery,
    );

    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value:
            ssnInformation.hasSSN || workForm === 'corporation'
              ? OnboardingRequestStatuses.published
              : OnboardingRequestStatuses.toConfirm,
          isAdmin: true,
        }),
      );
      dispatch(push(getOnboardingRedirect(tabStatus, 6, redirectRootRout)));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'ssnInformation',
          fieldValue: data,
        }),
      );
    }
  },
);

export const savePublishAvsAhvAsConsultant = createAsyncThunk(
  'onboarding/savePublishAvsAhvAsConsultant',
  async (redirectRootRout: string | undefined, { getState, dispatch }) => {
    const { ssnInformation, tabStatus } = (getState() as IRootState).onboarding;
    const { workForm } = ssnInformation;
    const dataQuery: IQueryParameters<ISsnInformationRequest> = {
      key: OnboardingRequestKeys.ssnInformation,
      status:
        ssnInformation.hasSSN && workForm !== 'corporation'
          ? OnboardingRequestStatuses.published
          : OnboardingRequestStatuses.toConfirm,
      type:
        ssnInformation.hasSSN && workForm !== 'corporation'
          ? OnboardingRequestTypes.required
          : OnboardingRequestTypes.optional,
      data: {
        ...ssnInformation,
        hasSSN: workForm === 'corporation' ? false : ssnInformation.hasSSN,
      },
    };

    const { isSuccess, data } = await onboardingApi.putAvsAhvAsConsultant(
      dataQuery,
    );
    if (data === 120009) {
      dispatch(getOnboardingProfile());
      return;
    }
    if (isSuccess) {
      dispatch(
        setOnboardingTabStatus({
          key: 6,
          value:
            ssnInformation.hasSSN || workForm === 'corporation'
              ? OnboardingRequestStatuses.published
              : OnboardingRequestStatuses.toConfirm,
        }),
      );
      dispatch(push(getOnboardingRedirect(tabStatus, 6, redirectRootRout)));
    } else {
      dispatch(
        setOnboardingErrorValidation({
          fieldName: 'ssnInformation',
          fieldValue: data,
        }),
      );
    }
  },
);
