export const countries = [
  'Bulgaria',
  'Falkland Islands',
  'Syria',
  'India',
  'Cuba',
  'Guadeloupe',
  'North Macedonia',
  'Tajikistan',
  'Bhutan',
  'Equatorial Guinea',
  'Sierra Leone',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Ukraine',
  'Israel',
  'Somalia',
  'Nicaragua',
  'South Georgia',
  'Sri Lanka',
  'United States',
  'Moldova',
  'Gabon',
  'Estonia',
  'Canada',
  'Nauru',
  'Niger',
  'French Guiana',
  'Caribbean Netherlands',
  'Taiwan',
  'Bouvet Island',
  'Tunisia',
  'Cocos (Keeling) Islands',
  'Papua New Guinea',
  'Hungary',
  'Greenland',
  'Montserrat',
  'Palau',
  'Wallis and Futuna',
  'Finland',
  'Mali',
  'Honduras',
  'Faroe Islands',
  'Guinea-Bissau',
  'Central African Republic',
  'Guinea',
  'Sint Maarten',
  'Saint Vincent and the Grenadines',
  'Comoros',
  'Andorra',
  'Croatia',
  'China',
  'Niue',
  'Liberia',
  'Hong Kong',
  'American Samoa',
  'Iraq',
  'Saint Kitts and Nevis',
  'Åland Islands',
  'Botswana',
  'Curaçao',
  'Libya',
  'Mongolia',
  'Chile',
  'Zambia',
  'Ivory Coast',
  'Liechtenstein',
  'Kyrgyzstan',
  'Turkmenistan',
  'Mauritius',
  'San Marino',
  'Philippines',
  'Brunei',
  'Vatican City',
  'Kazakhstan',
  'Réunion',
  'Saudi Arabia',
  'Mexico',
  'Thailand',
  'Iran',
  'Solomon Islands',
  'Slovakia',
  'Isle of Man',
  'Samoa',
  'Seychelles',
  'Singapore',
  'Serbia',
  'Guyana',
  'Benin',
  'Luxembourg',
  'Maldives',
  'Mozambique',
  'Aruba',
  'Jersey',
  'Sweden',
  'Turkey',
  'New Zealand',
  'El Salvador',
  'Georgia',
  'Yemen',
  'Dominican Republic',
  'Micronesia',
  'Heard Island and McDonald Islands',
  'United States Minor Outlying Islands',
  'Uzbekistan',
  'French Southern and Antarctic Lands',
  'Kiribati',
  'Anguilla',
  'Barbados',
  'Austria',
  'Northern Mariana Islands',
  'Togo',
  'Saint Barthélemy',
  'United States Virgin Islands',
  'Bermuda',
  'Saint Lucia',
  'Uganda',
  'Cameroon',
  'Pitcairn Islands',
  'Kenya',
  'Slovenia',
  'Bahamas',
  'Costa Rica',
  'Malta',
  'Norway',
  'Saint Martin',
  'Vietnam',
  'Chad',
  'Mayotte',
  'Cyprus',
  'Denmark',
  'Kosovo',
  'Kuwait',
  'Malawi',
  'Bangladesh',
  'Lebanon',
  'Burkina Faso',
  'North Korea',
  'Antarctica',
  'Cook Islands',
  'Cayman Islands',
  'Monaco',
  'Tokelau',
  'Antigua and Barbuda',
  'Laos',
  'Marshall Islands',
  'Tanzania',
  'Puerto Rico',
  'New Caledonia',
  'Argentina',
  'French Polynesia',
  'Timor-Leste',
  'Sudan',
  'Tuvalu',
  'Australia',
  'Nepal',
  'British Virgin Islands',
  'Bosnia and Herzegovina',
  'Poland',
  'Paraguay',
  'Namibia',
  'Belgium',
  'Greece',
  'Mauritania',
  'Eswatini',
  'Martinique',
  'Senegal',
  'United Arab Emirates',
  'Colombia',
  'Dominica',
  'Turks and Caicos Islands',
  'Cape Verde',
  'Gibraltar',
  'Netherlands',
  'Albania',
  'Germany',
  'France',
  'Peru',
  'Venezuela',
  'Suriname',
  'Jamaica',
  'Algeria',
  'Ecuador',
  'Tonga',
  'Brazil',
  'Bahrain',
  'Zimbabwe',
  'Pakistan',
  'Angola',
  'Republic of the Congo',
  'Nigeria',
  'Macau',
  'Afghanistan',
  'South Sudan',
  'Norfolk Island',
  'South Korea',
  'Qatar',
  'Belize',
  'Trinidad and Tobago',
  'Iceland',
  'Rwanda',
  'Azerbaijan',
  'Gambia',
  'Burundi',
  'Latvia',
  'Italy',
  'Eritrea',
  'Morocco',
  'Ghana',
  'Romania',
  'Svalbard and Jan Mayen',
  'Fiji',
  'Indonesia',
  'Jordan',
  'British Indian Ocean Territory',
  'Uruguay',
  'Spain',
  'Oman',
  'Portugal',
  'Bolivia',
  'Switzerland',
  'Madagascar',
  'Lithuania',
  'Montenegro',
  'Vanuatu',
  'Lesotho',
  'Guernsey',
  'Cambodia',
  'Ethiopia',
  'São Tomé and Príncipe',
  'DR Congo',
  'United Kingdom',
  'Guatemala',
  'Armenia',
  'Japan',
  'Saint Pierre and Miquelon',
  'Djibouti',
  'Egypt',
  'Guam',
  'Malaysia',
  'Palestine',
  'South Africa',
  'Christmas Island',
  'Haiti',
  'Western Sahara',
  'Ireland',
  'Grenada',
  'Czechia',
  'Myanmar',
  'Panama',
  'Russia',
].sort();
