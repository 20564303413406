export const createMask = (string: string, oldString: string) => {
  const str = string.replace(/\D/g, '').substring(0, 13);

  if (string.length === 14) return oldString;

  return str.replace(/(\d{3})(\d{4})(\d{4})(\d{2})/, '$1.$2.$3.$4');
};

export const createDateMask = (string: string) => {
  const str = string.replace(/\D/g, '').substring(0, 8);

  return str.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
};
