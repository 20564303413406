import { Col, Form, FormInstance, Row } from 'antd';
import Input from '../../../components/input/Input';
import { getError } from '../../../common/api/utils/getError';
import {
  IInvoice,
  InvoiseStatuses,
} from '../../../common/api/services/InvoiceApi/types/types';
import Dropdown from '../../../components/dropdown/Dropdown';
import { shippingMethodsList } from '../../../common/constants/shippingMethodsList';
import { UndoOutlined } from '@ant-design/icons';
import { invoiceApi } from '../../../common/api/services/InvoiceApi/invoiceApi';
import { openErrorNotification } from '../../../components/notification/Notification';
import { clientApi } from '../../../common/api/services/ClientApi/clientApi';

interface IProps {
  errorMessages: any;
  form: FormInstance<any>;
  setInvoice: any;
  invoice: IInvoice | null;
  saveAsDraft: (isHideNotification?: boolean) => void;
}

const ShippingMethodComponent = (props: IProps) => {
  const { errorMessages, form, setInvoice, invoice, saveAsDraft } = props;
  const isDraft = invoice?.status === InvoiseStatuses.DRAFT;
  const selectedShippingMethod = invoice?.shippingMethod;
  const shippingEmails = invoice?.shippingMethodDetails?.emails || [];

  const refreshEmails = () => {
    if (invoice) {
      invoiceApi
        .getInvoiceById(invoice._id)
        .then((resp) => {
          setInvoice((prevState: any) => ({
            ...prevState,
            shippingMethodDetails: resp.shippingMethodDetails,
          }));
        })
        .catch((e) => {
          openErrorNotification(
            e?.response?.data?.message || 'Failed to refresh emails',
          );
        });
    }
  };

  // const getClientEmails = () => {
  //   if (invoice) {
  //     clientApi
  //       .getClientById(invoice.clientId)
  //       .then((resp) => {
  //         setInvoice((prevState: any) => ({
  //           ...prevState,
  //           shippingMethodDetails: {
  //             emails: resp?.invoiceries?.length
  //               ? resp?.invoiceries?.map((item) => item.email)
  //               : [],
  //           },
  //         }));
  //       })
  //       .catch((e) => {
  //         openErrorNotification(
  //           e?.response?.data?.message || 'Failed to refresh emails',
  //         );
  //       });
  //   }
  // };

  return (
    <>
      <Row style={{ marginTop: '16px' }}>
        <Form.Item name="shippingMethod">
          <Dropdown
            label="Shipping method"
            options={shippingMethodsList}
            onSelect={(value) => {
              setInvoice((prevState: any) => ({
                ...prevState,
                shippingMethod: value,
              }));
            }}
            disabled={!isDraft}
          />
        </Form.Item>
      </Row>
      {selectedShippingMethod === 'portal' && (
        <Col span={24}>
          <Form.Item name="shippingMethodPortalUrl">
            <Input
              name="portalUrl"
              label="Portal URL"
              errorMessage={getError(
                `shippingMethodDetails.portalUrl`,
                errorMessages,
              )}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
      )}

      {selectedShippingMethod === 'by post' && (
        <Col span={24}>
          <Form.Item name="shippingMethodPostalAddress">
            <Input
              name="postalAddress"
              label="Postal address"
              errorMessage={getError(
                `shippingMethodDetails.postalAddress`,
                errorMessages,
              )}
              disabled={!isDraft}
            />
          </Form.Item>
        </Col>
      )}
      {selectedShippingMethod === 'by post' && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="shippingMethodFirstName">
              <Input
                name="firstName"
                label="First name"
                errorMessage={getError(
                  `shippingMethodDetails.firstName`,
                  errorMessages,
                )}
                disabled={!isDraft}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shippingMethodLastName">
              <Input
                name="lastName"
                label="Last name"
                errorMessage={getError(
                  `shippingMethodDetails.lastName`,
                  errorMessages,
                )}
                disabled={!isDraft}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {selectedShippingMethod === 'email' && shippingEmails?.length
        ? shippingEmails.map((item: string, index: number) => (
            <Row key={index} style={{ marginBottom: '2rem' }}>
              <Col span={24}>
                <Input
                  name="shippingEmal"
                  label="Email"
                  errorMessage={getError(
                    `shippingMethodDetails.emails[${index}]`,
                    errorMessages,
                  )}
                  disabled={true}
                  value={item}
                />
              </Col>
            </Row>
          ))
        : null}
      {selectedShippingMethod === 'email' && isDraft && (
        <Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
          <Col span={24}>
            <UndoOutlined style={{ color: '#0665c7', marginRight: '1rem' }} />
            <span
              className="refresh-emails-text"
              onClick={() => saveAsDraft(true)}>
              Refresh invoicing emails
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ShippingMethodComponent;
