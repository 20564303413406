import { ApiServiceBase } from '../../ApiServiceBase';
import { IGetListDto } from '../../types/IGetListDto';
import { LeadRoutes } from './enums/LeadRoutes';
import { ISendInviteToLead } from './types/ISendInviteToLead';
import { ILeadDto } from './types/ILeadDto';
import { parsErrorMessageObj } from '../../utils/parsErrorMessageObj';
import { parsErrorMessage } from '../../utils/parsErrorMessage';
import { IEmployeeDto } from '../UserApi/types/IEmployeeDto';
import { IAssignManagerRequest } from './types/IAssignManagerRequest';
import { ISort } from '../../../../redux/types';
import { getQueryParams } from '../../utils/getQueryParams';

class LeadApi extends ApiServiceBase {
  protected readonly baseUrl = `/api/v1/lead`;

  fetchLeads(
    currentPage: number,
    perPage: number,
    filter: Record<string, string>,
    sort: ISort,
  ) {
    return this.get<IGetListDto<ILeadDto>>('', {
      query: { page: currentPage, perPage, ...getQueryParams(filter, sort) },
    })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  sendInviteToLead(id: string) {
    return this.post<any, ISendInviteToLead>(`/${id}/` + LeadRoutes.invite)
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }

  getLeadById(id: string) {
    return this.get<ILeadDto>(`/${id}`)
      .then((response) => response)
      .catch(() => null);
  }

  changeLeadDetails(
    id: string,
    data: ILeadDto,
  ): Promise<string | Record<string, string> | void> {
    return this.put(`/${id}`, data)
      .then(() => undefined)
      .catch((e) => parsErrorMessageObj(e));
  }

  archiveLead(id: string): Promise<string | ILeadDto> {
    return this.put<any, ILeadDto>(`/${id}/` + LeadRoutes.archive)
      .then((response) => response)
      .catch((e) => parsErrorMessage(e));
  }

  duplicatedLead(id: string): Promise<any> {
    return this.put(`/${id}/` + LeadRoutes.duplicated)
      .then((response) => response)
      .catch(() => null);
  }

  assignManager(id: string, data: IEmployeeDto): Promise<ILeadDto | null> {
    const { id: userId, firstName, lastName, role, email, phone } = data;

    return this.put<IAssignManagerRequest, ILeadDto>(
      `/${id}/` + LeadRoutes.manager,
      {
        userId,
        firstName,
        lastName,
        role,
        email,
        phone,
      },
    )
      .then((response) => response)
      .catch(() => null);
  }
}

export const leadApi = new LeadApi();
