import axios from 'axios';
import { IGetConsultantInfoResponse } from '../OnboardingApi/types/IGetConsultantInfoResponse';

class PostCodeApi {
  getFullAddress(
    street: string,
    city: string,
  ): Promise<IGetConsultantInfoResponse> {
    return axios
      .get(`/postCode/houses/search?city=${city}&limit=10&street=${street}`)
      .then((res) => {
        return res.data.houses;
      })
      .catch((res) => {
        console.error(res);
        return [];
      });
  }
}

export const postCodeApi = new PostCodeApi();
