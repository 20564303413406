import { ROUTER_PATHS } from '../../navigation/routerPaths';
import { OnboardingRequestStatuses } from '../api/services/OnboardingApi/enums/OnboardingRequestStatuses';

const {
  personalInformation,
  workPermit,
  clientContract,
  workContract,
  bankAccount,
  childAllowance,
  onboarding,
  avsAhv,
  additionalInformation,
} = ROUTER_PATHS;

export const getOnboardingRedirect = (
  statusArray: OnboardingRequestStatuses[],
  currentPage: number,
  rootRout = onboarding,
  exact?: boolean,
): string => {
  const onboardingRedirectLinks = [
    rootRout + personalInformation,
    rootRout + workContract,
    rootRout + clientContract,
    rootRout + workPermit,
    rootRout + bankAccount,
    rootRout + childAllowance,
    rootRout + avsAhv,
    rootRout + additionalInformation,
  ];

  if (exact) {
    return onboardingRedirectLinks[currentPage];
  }

  let idx = currentPage;
  let returnPath;
  let isExit = false;
  let countIdx = 0;

  do {
    idx += 1;
    countIdx += 1;
    if (idx >= onboardingRedirectLinks.length) {
      idx = 0;
    }

    if (statusArray[idx] !== OnboardingRequestStatuses.published) {
      returnPath = onboardingRedirectLinks[idx];
      isExit = true;
    }
  } while (!isExit && countIdx <= onboardingRedirectLinks.length);

  return returnPath ?? onboardingRedirectLinks[currentPage];
};
