import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import './SystemInfoPage.less';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import SystemInfoHistory from './components/SystemInfoHistory';
import {
  BankAccountsType,
  FXRateType,
  IBankAccount,
  IFXRates,
  IVATs,
  VATType,
} from '../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { systemInfoApi } from '../../common/api/services/SystemInfoApi/SystemInfoApi';
import moment from '../../common/constants/moment';
import {
  commonMonthYearSpaceFormat,
  commonYearDateFormat,
} from '../../common/constants/dateConstants';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';
import TrashIcon from '../../assets/icons/TrashIcon';
import AddBankAccountModal from './components/AddBankAccountModal';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { DeleteBankAccount } from '../../components/common-texts/CommonTexts';

const SystemInfoPage = () => {
  const [isOpenFxSection, setIsOpenFxSection] = useState(true);
  const [isOpenVATSection, setIsOpenVATSection] = useState(true);
  const [isOpenBankSection, setIsOpenBankSection] = useState(true);
  const [selectedDelete, setSelectedDelete] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<string | FXRateType>('');
  const [isOpenAddNew, setIsOpenAddNew] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState<IBankAccount | null>(null);
  const [fxData, setFxData] = useState<IFXRates>([]);
  const [vatData, setVatData] = useState<IVATs>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccountsType>([]);

  useEffect(() => {
    getActualFXRates();
  }, []);

  useEffect(() => {
    getActualVAT();
  }, []);

  useEffect(() => {
    getBankAccounts();
  }, []);

  const getActualFXRates = () => {
    systemInfoApi
      .getActualFXRates(moment().format(commonYearDateFormat))
      .then((response: any) => {
        setFxData(response);
      })
      .catch(() => openErrorNotification('Failed to get FX rates'));
  };

  const getBankAccounts = () => {
    systemInfoApi
      .getBankAccounts()
      .then((resp: any) => setBankAccounts(resp.result))
      .catch(() => openErrorNotification('Failed to get bank accounts'));
  };

  const getActualVAT = () => {
    systemInfoApi
      .getActualVAT(moment().format(commonYearDateFormat))
      .then((response: any) => {
        setVatData(response);
      })
      .catch(() => openErrorNotification('Failed to get VAT'));
  };

  const deleteBankAccount = () => {
    systemInfoApi
      .deleteBankAccount(selectedDelete._id)
      .then(() => {
        openSuccessNotification('Bank account was deleted successfully');
        getBankAccounts();
        setSelectedDelete(null);
      })
      .catch((e) => {
        openErrorNotification('Failed to delete bank account');
      });
  };

  const closeHistoryModal = () => {
    setSelectedType('');
    getActualFXRates();
    getActualVAT();
  };

  const closeDeleteModal = () => {
    setSelectedDelete(null);
  };

  return (
    <div className="system-info-container">
      <div className="header-section">
        <Breadcrumb />
      </div>
      <div className="tables-wrapper">
        <div className="system-info-block">
          <div
            className="block-header"
            onClick={() => setIsOpenFxSection(!isOpenFxSection)}>
            <span>Fx rate</span>
            {isOpenFxSection ? <UpOutlined /> : <DownOutlined />}
          </div>
          {isOpenFxSection && (
            <div className="system-info-list">
              <div
                className="system-info-item"
                onDoubleClick={() => setSelectedType(FXRateType.EUR)}>
                <div className="system-info-item-value">EUR/CHF</div>
                <div>
                  {fxData.find((item) => item.currencyPair === FXRateType.EUR)
                    ?.rate || ''}
                </div>
                <div className="system-info-item-period">
                  {fxData.find((item) => item.currencyPair === FXRateType.EUR)
                    ?.period
                    ? moment(
                        fxData.find(
                          (item) => item.currencyPair === FXRateType.EUR,
                        )?.period,
                      ).format(commonMonthYearSpaceFormat)
                    : ''}
                </div>
              </div>
              <div
                className="system-info-item"
                onDoubleClick={() => setSelectedType(FXRateType.USD)}>
                <div className="system-info-item-value">USD/CHF</div>
                <div>
                  {fxData.find((item) => item.currencyPair === FXRateType.USD)
                    ?.rate || ''}
                </div>
                <div className="system-info-item-period">
                  {fxData.find((item) => item.currencyPair === FXRateType.USD)
                    ?.period
                    ? moment(
                        fxData.find(
                          (item) => item.currencyPair === FXRateType.USD,
                        )?.period,
                      ).format(commonMonthYearSpaceFormat)
                    : ''}
                </div>
              </div>
              <div
                className="system-info-item"
                onDoubleClick={() => setSelectedType(FXRateType.GBP)}>
                <div className="system-info-item-value">GBP/CHF</div>
                <div>
                  {fxData.find((item) => item.currencyPair === FXRateType.GBP)
                    ?.rate || ''}
                </div>
                <div className="system-info-item-period">
                  {fxData.find((item) => item.currencyPair === FXRateType.GBP)
                    ?.period
                    ? moment(
                        fxData.find(
                          (item) => item.currencyPair === FXRateType.GBP,
                        )?.period,
                      ).format(commonMonthYearSpaceFormat)
                    : ''}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="system-info-block">
          <div
            className="block-header"
            onClick={() => setIsOpenVATSection(!isOpenVATSection)}>
            <span>VAT rate</span>
            {isOpenVATSection ? <UpOutlined /> : <DownOutlined />}
          </div>
          {isOpenVATSection && (
            <div className="system-info-list">
              <div className="system-info-item">
                <div className="system-info-item-value">Exportation</div>
                <div>0%</div>
                <div></div>
              </div>
              <div className="system-info-item">
                <div className="system-info-item-value">Formation</div>
                <div>0%</div>
                <div></div>
              </div>
              <div className="system-info-item">
                <div className="system-info-item-value">Exempt</div>
                <div>0%</div>
                <div></div>
              </div>
              <div
                className="system-info-item"
                onDoubleClick={() => setSelectedType('VAT')}>
                <div className="system-info-item-value">Swiss VAT</div>
                <div>
                  {vatData.length > 0
                    ? `${
                        vatData.find((item) => item.name === VATType.SwissVAT)
                          ?.percentage || ''
                      }%`
                    : ''}
                </div>
                <div className="system-info-item-period">
                  {vatData.length > 0 &&
                  vatData.find((item) => item.name === VATType.SwissVAT)
                    ? moment(
                        vatData.find((item) => item.name === VATType.SwissVAT)
                          ?.period,
                      ).format(commonMonthYearSpaceFormat)
                    : ''}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="system-info-block">
          <div
            className="block-header"
            onClick={() => setIsOpenBankSection(!isOpenBankSection)}>
            <span>Bank accounts</span>
            {isOpenBankSection ? <UpOutlined /> : <DownOutlined />}
          </div>
          {isOpenBankSection && (
            <div className="system-info-list">
              {bankAccounts.length
                ? bankAccounts.map((item, index) => (
                    <div
                      className="system-info-item"
                      key={index}
                      onDoubleClick={() => setSelectedEdit(item)}>
                      <div className="system-info-item-value">
                        {item.name || ''}
                      </div>
                      <div>{item.currency || ''}</div>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <TrashIcon onClick={() => setSelectedDelete(item)} />
                      </div>
                    </div>
                  ))
                : null}
              <span
                onClick={() => setIsOpenAddNew(true)}
                className="add-bank-btn">
                + Add bank account
              </span>
            </div>
          )}
        </div>
      </div>
      {selectedType && (
        <SystemInfoHistory
          isOpen={!!selectedType}
          onClose={closeHistoryModal}
          selectedType={selectedType}
        />
      )}
      {isOpenAddNew && (
        <AddBankAccountModal
          isOpen={isOpenAddNew}
          setIsOpen={setIsOpenAddNew}
          getBankAccounts={getBankAccounts}
        />
      )}
      {selectedEdit && (
        <AddBankAccountModal
          isOpen={!!selectedEdit}
          setIsOpen={setIsOpenAddNew}
          getBankAccounts={getBankAccounts}
          selectedBank={selectedEdit}
          setSelectedBank={setSelectedEdit}
        />
      )}
      {selectedDelete && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeDeleteModal}
          confirmAction={deleteBankAccount}
          confirmBtnTitle="Yes, delete"
          content={<DeleteBankAccount name={selectedDelete.name} />}
          isOpen={selectedDelete}
          hideCloseBtn
          isHideAttentionText
        />
      )}
    </div>
  );
};

export default SystemInfoPage;
