import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const ReportingIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 26 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5959 2.19177H5.87647C3.73593 2.19177 2.00067 3.92702 2.00067 6.06757V18.1473C2.00067 20.2879 3.73593 22.0231 5.87647 22.0231H12.8211C13.3276 22.0231 13.7381 22.4337 13.7381 22.9401C13.7381 23.4466 13.3276 23.8571 12.8211 23.8571H5.87647C2.72584 23.8504 0.173425 21.298 0.1667 18.1473V6.06757C0.163454 4.55 0.763194 3.09329 1.83398 2.01792C2.90477 0.942543 4.3589 0.336583 5.87647 0.333344H10.5959C12.3718 0.333344 13.8115 1.773 13.8115 3.54891C13.8181 4.30917 14.4328 4.92386 15.1931 4.9305H19.9125C23.0631 4.93723 25.6155 7.48964 25.6222 10.6403C25.6293 11.1577 25.2224 11.5863 24.7053 11.6062C24.4655 11.6162 24.232 11.5279 24.0589 11.3617C23.8858 11.1955 23.7881 10.9658 23.7883 10.7259C23.8046 9.68512 23.4043 8.68103 22.6763 7.93704C21.9484 7.19304 20.9533 6.77087 19.9125 6.76448H15.1931C13.4171 6.76448 11.9775 5.32482 11.9775 3.54891C11.9577 2.79821 11.3468 2.1981 10.5959 2.19177ZM15.6454 14.4672C15.6454 13.9607 16.056 13.5502 16.5624 13.5502H22.4311C22.9376 13.5502 23.3481 13.9607 23.3481 14.4672C23.3481 14.9736 22.9376 15.3841 22.4311 15.3841H16.5624C16.056 15.3841 15.6454 14.9736 15.6454 14.4672ZM16.5624 22.0109H19.0077C19.5142 22.0109 19.9247 22.4215 19.9247 22.9279C19.9247 23.4343 19.5142 23.8449 19.0077 23.8449H16.5624C16.056 23.8449 15.6454 23.4343 15.6454 22.9279C15.6454 22.4215 16.056 22.0109 16.5624 22.0109ZM24.7053 17.7805H16.5624C16.056 17.7805 15.6454 18.1911 15.6454 18.6975C15.6454 19.204 16.056 19.6145 16.5624 19.6145H24.7297C25.2362 19.6145 25.6467 19.204 25.6467 18.6975C25.6467 18.1911 25.2362 17.7805 24.7297 17.7805H24.7053Z"
        fill="#0671E0"
      />
    </svg>
  );
};

export default ReportingIcon;
