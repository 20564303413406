import { ColumnsType, TableProps } from 'antd/lib/table';
import CommonTable from '../../components/common-table/CommonTable';
import { commonMonthTimeFormat } from '../../common/constants/dateConstants';
import moment from '../../common/constants/moment';
import { TabConstant } from './types/tabTypes';
import { ElementsTypes } from './types/ElementTypes';
import { useEffect, useState } from 'react';
import { onboardingApi } from '../../common/api/services/OnboardingApi/onboardingApi';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  changeLogsFiltering,
  clearLogsFiltersAndSorts,
  setLogsList,
  setLogsPagination,
  setLogsSorting,
} from '../../redux/reducers/logs-reducer/sliceReducer';
import { SorterResult } from 'antd/lib/table/interface';
import { TSortValue } from '../../redux/types';
import { useTableLocalSearchFilter } from '../../common/hooks/useTableLocalSearchFilter';
import { useTableSelectFilter } from '../../common/hooks/useTableSelectFilter';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import TableTooltip from '../../components/common-table/table-tooltip';
import { openErrorNotification } from '../../components/notification/Notification';
import { OnboardingRequestStatuses } from '../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { ILogs } from '../../common/api/services/OnboardingApi/types/ILogs';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import ResetFilterIcon from '../../assets/icons/ResetFilterIcon';
import './LogsPage.less';
import { removeEmptyValues } from '../../common/utils/removeEmptyFromObj';

//TODO: utils
const { draft, published, toConfirm, empty, toVerify } =
  OnboardingRequestStatuses;

type TStatusParserKey =
  | OnboardingRequestStatuses.draft
  | OnboardingRequestStatuses.published
  | OnboardingRequestStatuses.toConfirm
  | OnboardingRequestStatuses.empty
  | OnboardingRequestStatuses.toVerify;

const statusParser: Record<TStatusParserKey, string> = {
  [draft]: 'DRAFT',
  [empty]: 'EMPTY',
  [published]: 'PUBLISHED',
  [toConfirm]: 'TO CONFIRM',
  [toVerify]: 'TO VERIFY',
};
const filterNames = [draft, published, toConfirm, toVerify, empty];

const LogsPage = () => {
  const dispatch = useAppDispatch();
  const { consultantId } = useParams<{ consultantId: string }>();
  const [logs, setLogs] = useState<ILogs>([]);
  const [consultantFullName, setConsultantFullName] = useState('');
  const { logsList } = useAppSelector((state) => state.logs);
  const { pagination, sort, filter } = logsList;
  const { currentPage, perPage, totalElement } = pagination;
  const isHasFilters =
    sort.sortBy !== '' || Object.values(filter).some((el) => el.length !== 0);

  useEffect(() => {
    getLogs({
      page: currentPage,
      perPage,
      sort: sort.sort,
      sortBy: sort.sortBy,
      filter,
    });
  }, [currentPage, perPage, filter]);

  const getLogs = (body: any) => {
    onboardingApi
      .getOnboardingLogs(consultantId as string, {
        ...body,
        filter: removeEmptyValues(body.filter),
      })
      .then((value: any) => {
        if (value.result.length > 0) {
          setConsultantFullName(`${value.result[0].consultant?.firstName || ''}
          ${value.result[0].consultant?.lastName || ''}`);
        }
        dispatch(setLogsList(value));
        setLogs(value.result || []);
      })
      .catch((e) =>
        openErrorNotification(
          'Failed to retrieve consultant onboarding history',
        ),
      );
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(setLogsPagination({ perPage, currentPage }));
  };

  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field as string;
      const newSort = setSort(sortBy);
      dispatch(setLogsSorting(newSort));
      getLogs({
        filter,
        page: currentPage,
        perPage,
        sortBy: newSort[0],
        sort: newSort[1] as TSortValue,
      });
    }
  };
  //TODO: helper
  const setSort = (currentSortBy: string) => {
    if (currentSortBy !== sort.sortBy) {
      return [currentSortBy, 'asc'];
    }

    if (sort.sort === 'asc' && currentSortBy === sort.sortBy) {
      return [currentSortBy, 'desc'];
    }
    return ['', ''];
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeLogsFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => {
      return;
    },
    changeValues: changeFilter,
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const columns: ColumnsType<ILogs> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: sortClassName('name'),
      width: 250,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.name as string) ?? '',
      })('name'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      className: sortClassName('role'),
      ...useTableSelectFilter({
        ...searchFilterArg,
        defaultValues: (filter?.role as string[]) ?? '',
        fieldFilterName: 'role',
      })(['admin', 'manager', 'consultant']),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      className: sortClassName('createdAt'),
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'createdAt',
        values: (filter?.createdAt as string) ?? '',
      })(),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Onboarding tab',
      dataIndex: 'tab',
      key: 'tab',
      width: 250,
      className: sortClassName('tab'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.tab as string[]) ?? [],
        fieldFilterName: 'tab',
      })(Object.values(TabConstant)),
    },
    {
      title: 'Field name',
      dataIndex: 'fieldName',
      key: 'fieldName',
      width: 600,
      className: sortClassName('fieldName'),
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.fieldName as string) ?? '',
        searchAsString: false,
      })('fieldName'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={600}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Before',
      dataIndex: 'valueBefore',
      key: 'valueBefore',
      width: 250,
      className: sortClassName('valueBefore'),
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.valueBefore as string) ?? '',
        searchAsString: false,
      })('valueBefore'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip
          tootltipTitle={text}
          columnWidth={250}
          placement="bottomLeft">
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'After',
      dataIndex: 'valueAfter',
      key: 'valueAfter',
      width: 250,
      ...useTableLocalSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.valueAfter as string) ?? '',
        searchAsString: false,
      })('valueAfter'),
      render: (text: string) => (
        <TableTooltip
          tootltipTitle={text}
          columnWidth={250}
          placement="bottomLeft">
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Before status',
      dataIndex: 'statusBefore',
      key: 'statusBefore',
      className: `status-td ${sortClassName('statusBefore')}`,
      render: (text: TStatusParserKey) => {
        const className = empty === text ? draft : text;
        return <span className={className}>{statusParser[text]}</span>;
      },
      width: 200,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.statusBefore as string[]) ?? [],
        fieldFilterName: 'statusBefore',
      })(filterNames),
    },
    {
      title: 'After status',
      dataIndex: 'statusAfter',
      key: 'statusAfter',
      className: `status-td ${sortClassName('statusAfter')}`,
      render: (text: TStatusParserKey) => {
        const className = empty === text ? draft : text;
        return <span className={className}>{statusParser[text]}</span>;
      },
      width: 200,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.statusAfter as string[]) ?? [],
        fieldFilterName: 'statusAfter',
      })(filterNames),
    },
  ];
  const isObject = (value: any) => {
    return value && typeof value === 'object';
  };

  const convertToString = (value: any) => {
    return value == null ? '' : value.toString();
  };
  const removeEmpty = (obj: any) => {
    const newObj: any = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        newObj[key] = isDate(obj[key])
          ? moment(obj[key]).format(commonMonthTimeFormat)
          : obj[key];
      }
    }
    return newObj;
  };

  const convertField = (field: any) => {
    if (isObject(field)) {
      const newObject = removeEmpty(field);
      return Object.values(newObject).join(', ');
    }
    return convertToString(field);
  };

  const isDate = (value: any) => {
    const iso8601DatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return (
      typeof value === 'string' &&
      iso8601DatePattern.test(value) &&
      !isNaN(Date.parse(value))
    );
  };

  const data = logs.map((items) => {
    const { user, createdAt, path, lhs, rhs, item, lhsStatus, rhsStatus } =
      items;
    const { firstName, lastName, role } = user;
    const pathTab = path.slice(0, 2).join('.') as keyof typeof TabConstant;
    const newPath = [...path];
    newPath.shift(); // Remove the first element
    const filteredPath =
      path.length === 1
        ? [...path].join(' ')
        : (newPath
            .filter((value) => typeof value !== 'number')
            .join(' > ') as keyof typeof ElementsTypes);

    const checkLhs = () => {
      if (item) {
        if (!item.lhs) {
          return '';
        }
        if (isDate(item.lhs)) {
          return moment(item.lhs).format(commonMonthTimeFormat);
        }
        return convertField(item.lhs);
      }
      if (isDate(lhs)) {
        return moment(lhs).format(commonMonthTimeFormat);
      }
      return convertField(lhs);
    };

    const checkRhs = () => {
      if (item) {
        if (!item.rhs) {
          return '';
        }
        if (isDate(item.rhs)) {
          return moment(item.rhs).format(commonMonthTimeFormat);
        }
        return convertField(item.rhs);
      }
      if (isDate(rhs)) {
        return moment(rhs).format(commonMonthTimeFormat);
      }
      return convertField(rhs);
    };

    return {
      role,
      name: `${firstName || ''} ${lastName || ''}`,
      createdAt: moment(createdAt).format(commonMonthTimeFormat),
      tab: TabConstant[pathTab] || 'Personal information',
      fieldName: filteredPath,
      valueBefore: checkLhs(),
      valueAfter: checkRhs(),
      statusBefore: lhsStatus,
      statusAfter: rhsStatus,
    };
  });

  return (
    <div style={{ padding: '5rem' }}>
      <div className="header-container">
        {consultantFullName && (
          <h4 style={{ marginBottom: '2rem' }}>{consultantFullName} history</h4>
        )}
        {isHasFilters && (
          <button
            className="rest-filters-button"
            onClick={() => {
              dispatch(clearLogsFiltersAndSorts());
              getLogs({
                sort: '',
                sortBy: '',
                page: currentPage,
                perPage,
                filter,
              });
            }}>
            <ResetFilterIcon />
          </button>
        )}
      </div>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        // rowSelection={rowSelection}
        onChange={onChangeSort}
        rowKey="_id"
      />
    </div>
  );
};

export default LogsPage;
