import { FC, useEffect, useMemo, useRef } from 'react';
import { Layout } from 'antd';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import OnboardingFooter from '../../components/footer/components/OnboardingFooter';
import FullLogoIcon from '../../assets/icons/FullLogoIcon';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import PersonalInfoTab from './tabs/personal-info-tab/PersonalInfoTab';
import NavigationTabs from './components/navigation-tabs/NavigationTabs';

import './OnboardingPage.less';
import WorkContractTab from './tabs/work-contact-tab/WorkContractTab';
import WorkPermitTab from './tabs/work-permit-tab/WorkPermitTab';
import BankAccountTab from './tabs/bank-accont-tab/BankAccountTab';
import ChildAllowance from './tabs/child-allowance-tab/ChildAllowance';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getOnboardingProfile } from '../../redux/reducers/onboarding/actionAsyncThunk';
import ClientContractTab from './tabs/client-contract-tab/ClientContractTab';
import AvsAhvTab from './tabs/avs-ahv/AvsAhvTab';
import AdditionalInfoTab from './tabs/additional-info-tab/AdditionalInfoTab';
import { navigationItems } from './components/navigation-tabs/navigationItems';
import { withOnboardingWillUnmount } from '../../common/HOCs/withOnboardingWillUnmount';
import { withConsultantAdditionalInfoDeletingAndAddingContract } from '../../common/HOCs/withConsultantAdditionalInfoAddingAndDeletingContacts';
import { withConsultantWorkContractDeletingAndAddingContract } from '../../common/HOCs/withConsultantWorkContractDeletingAndAddingContract';
import { saveDraftPersonalInformationAsConsultant } from '../../redux/reducers/onboarding/tabs/personalInformationAsyncThunk';
import { saveDraftWorkContractAsConsultant } from '../../redux/reducers/onboarding/tabs/workContractAsyncThunk';
import { saveDraftClientContractAsConsultant } from '../../redux/reducers/onboarding/tabs/clientContractAsyncThunk';
import { saveDraftWorkPermitAsConsultant } from '../../redux/reducers/onboarding/tabs/workPermitAsyncThunk';
import { saveDraftBankAccountAsConsultant } from '../../redux/reducers/onboarding/tabs/bankAccountAsyncThunk';
import { saveDraftChildAllowancesAsConsultant } from '../../redux/reducers/onboarding/tabs/childAllowancesAsyncThunk';
import { saveDraftAvsAhvAsConsultant } from '../../redux/reducers/onboarding/tabs/avsAhvAsyncThunk';
import { saveDraftAdditionalInfoAsConsultant } from '../../redux/reducers/onboarding/tabs/additionalInfoAsyncThunk';
import { Roles } from '../../common/enums/Roles';
import { Statuses } from '../../common/enums/Statuses';
import { putUserData } from '../../redux/reducers/auth/sliceReducer';

const { Content, Header, Footer } = Layout;
const {
  onboarding,
  personalInformation,
  home,
  workPermit,
  workContract,
  clientContract,
  bankAccount,
  childAllowance,
  avsAhv,
  additionalInformation,
  timesheets,
} = ROUTER_PATHS;

const OnboardingPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const divRef: any = useRef(null);
  const location = useLocation().pathname;
  const { validationError } = useAppSelector((state) => state.onboarding);
  const { role, status } = useAppSelector((state) => state.auth);
  const consultantStatus = useAppSelector(
    (state) => state.onboarding.consultantStatus,
  );
  const authState = useAppSelector((state) => state.auth);
  const isOnboarding =
    Roles.CONSULTANT === role && Statuses.inactive === status;

  useEffect(() => {
    if (divRef?.current) {
      divRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch(getOnboardingProfile());
  }, []);

  useEffect(() => {
    if (isOnboarding && consultantStatus === 'active') {
      dispatch(putUserData({ ...authState, status: 'active' }));
      navigate(timesheets);
    }
  }, [consultantStatus]);

  useEffect(() => {
    const el = document.querySelector('.error-text');
    if (el?.parentElement) {
      el.parentElement.scrollIntoView();
    }
  }, [validationError]);

  const personalInfoElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        PersonalInfoTab,
        saveDraftPersonalInformationAsConsultant,
      ),
    [location],
  );

  const workContractElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        withConsultantWorkContractDeletingAndAddingContract(WorkContractTab),
        saveDraftWorkContractAsConsultant,
      ),
    [location],
  );

  const clientContractElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        ClientContractTab,
        saveDraftClientContractAsConsultant,
      ),
    [location],
  );

  const workPermitElement = useMemo(
    () =>
      withOnboardingWillUnmount(WorkPermitTab, saveDraftWorkPermitAsConsultant),
    [location],
  );

  const bankAccountElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        BankAccountTab,
        saveDraftBankAccountAsConsultant,
      ),
    [location],
  );

  const childAllowanceElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        ChildAllowance,
        saveDraftChildAllowancesAsConsultant,
      ),
    [location],
  );

  const avsAhvElement = useMemo(
    () => withOnboardingWillUnmount(AvsAhvTab, saveDraftAvsAhvAsConsultant),
    [location],
  );

  const additionalInfoElement = useMemo(
    () =>
      withOnboardingWillUnmount(
        withConsultantAdditionalInfoDeletingAndAddingContract(
          AdditionalInfoTab,
        ),
        saveDraftAdditionalInfoAsConsultant,
      ),
    [location],
  );

  return (
    <Layout
      className={`onboarding-page ${
        location.includes(workContract || clientContract)
          ? 'work-contract-tab'
          : ''
      }`}>
      <Header>
        <FullLogoIcon className="full-logo-icon" />
        <NavigationTabs navigationItems={navigationItems} />
      </Header>

      <Content ref={divRef}>
        <Routes>
          <Route
            path={home}
            element={<Navigate to={onboarding + personalInformation} />}
          />
          <Route path={personalInformation} element={personalInfoElement} />
          <Route path={workPermit} element={workPermitElement} />
          <Route path={workContract} element={workContractElement} />
          <Route path={clientContract} element={clientContractElement} />
          <Route path={bankAccount} element={bankAccountElement} />
          <Route path={childAllowance} element={childAllowanceElement} />
          <Route path={avsAhv} element={avsAhvElement} />
          <Route path={additionalInformation} element={additionalInfoElement} />
        </Routes>
      </Content>
      <Footer>
        <OnboardingFooter />
      </Footer>
    </Layout>
  );
};

export default OnboardingPage;
