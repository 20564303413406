import { notification } from 'antd';
import InfoIcon from '../../assets/icons/InfoIcon';
import SuccessIcon from '../../assets/icons/SuccessIcon';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import './Notification.less';

export const openInfoNotification = (description: string) => {
  notification.open({
    message: 'Information alert',
    description,
    type: 'info',
    placement: 'topLeft',
    icon: <InfoIcon />,
    className: 'common-info-notification',
  });
};

export const openSuccessNotification = (description: string) => {
  notification.open({
    message: 'Success alert',
    description,
    type: 'success',
    placement: 'topLeft',
    icon: <SuccessIcon />,
    className: 'common-success-notification',
  });
};

export const openErrorNotification = (description: string) => {
  notification.open({
    message: 'Warning alert',
    description,
    type: 'error',
    placement: 'topLeft',
    icon: <ErrorIcon />,
    className: 'common-error-notification',
  });
};
