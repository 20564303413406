import { ChangeEvent, FC } from 'react';
import { Col, Popover, Radio, RadioChangeEvent, Row, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  removeOnboardingPersonalInfo,
  setIsChangeNationality,
  updateOnboardingPersonalInfo,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import { countries } from '../../../../common/constants/mockCountries';
import { createMask } from '../../../../common/utils/inputMaskUtils';
import UploadDropzone from '../../../../components/upload-dropzone/UploadDropzone';
import { Roles } from '../../../../common/enums/Roles';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import { uploadFileOnboardingPersonalInfo } from '../../../../redux/reducers/onboarding/tabs/personalInformationAsyncThunk';

const genders = ['man', 'woman'];
const languages = ['fr', 'en', 'de', 'it'];
const maritalStatuses = [
  'Single',
  'Married',
  'Widow(er)',
  'Separated',
  'Divorced',
];
const spendTimeOptions = [
  'every day',
  'once a week',
  'once a month',
  'once a year',
];
const canton = [
  'Aargau',
  'Appenzell Innerrhoden',
  'Appenzell Ausserrhoden',
  'Bern',
  'Basel-Land',
  'Basel-Stadt',
  'Freiburg',
  'Geneva',
  'Glarus',
  'Graubünden',
  'Jura',
  'Luzern',
  'Neuenburg',
  'Nidwalden',
  'Obwalden',
  'St. Gallen',
  'Schaffhausen',
  'Solothurn',
  'Schwyz',
  'Thurgau',
  'Ticino',
  'Uri',
  'Vaud',
  'Valais',
  'Zug',
  'Zürich',
  'N/A',
];

const PersonalInfoTab: FC = () => {
  const {
    lastName,
    firstName,
    phone,
    email,
    language,
    dateOfBirth,
    nationality,
    gender,
    maritalStatus,
    workForm,
    passportExpirationDate,
    hasSwissAddress,
    swissAddressStreet,
    swissAddressPostCode,
    swissAddressCity,
    hasAbroadAddress,
    abroadAddressStreet,
    abroadAddressPostCode,
    abroadAddressCity,
    abroadAddressCountry,
    abroadAddressTotalAttendance,
    abroadAddressUseAsCorrespondenceLocation,
    maritalStatusDetailsMarriageDate,
    maritalStatusDetailsSpouseName,
    maritalStatusDetailsSpouseLastName,
    maritalStatusDetailsSpouseDateOfBirth,
    maritalStatusDetailsSpouseNationality,
    maritalStatusDetailsIsSpouseSwissResident,
    maritalStatusDetailsSpouseSSN,
    maritalStatusDetailsSpousalIncome,
    maritalStatusDetailsSpouseWorkCountry,
    maritalStatusDetailsSpouseWorkCity,
    maritalStatusDetailsSpouseWorkCanton,
    maritalStatusDetailsSpouseStartedWorkingFrom,
    documents,
  } = useAppSelector((state) => state.onboarding.personalInfo);
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.personalInfo,
  );

  const isConsultant = useAppSelector(
    (state) => state.auth.role === Roles.CONSULTANT,
  );
  const dispatch = useAppDispatch();

  const changeDate = (fieldName: string) => (date: Date | null) => {
    const value = date?.toDateString() ?? '';
    dispatch(updateOnboardingPersonalInfo({ [fieldName]: value }));
  };

  const onChangeDropdown = (fieldName: string) => (data: any) => {
    if (fieldName === 'nationality') {
      dispatch(setIsChangeNationality(true));
    }
    dispatch(updateOnboardingPersonalInfo({ [fieldName]: data }));
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => {
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;

    if (event.target.name === 'email') {
      dispatch(updateOnboardingPersonalInfo({ email: fieldValue.trim() }));
      return;
    }

    if (
      fieldName === 'maritalStatusDetailsIsSpouseSwissResident' &&
      !fieldValue
    ) {
      dispatch(
        updateOnboardingPersonalInfo({ maritalStatusDetailsSpouseSSN: '' }),
      );
    }

    if (fieldName === 'maritalStatusDetailsSpousalIncome' && !fieldValue) {
      dispatch(
        updateOnboardingPersonalInfo({
          maritalStatusDetailsSpouseWorkCountry: '',
          maritalStatusDetailsSpouseWorkCanton: '',
          maritalStatusDetailsSpouseWorkCity: '',
          maritalStatusDetailsSpouseStartedWorkingFrom: '',
        }),
      );
    }

    if (fieldName === 'hasSwissAddress' && !fieldValue) {
      dispatch(
        updateOnboardingPersonalInfo({
          swissAddressStreet: '',
          swissAddressPostCode: '',
          swissAddressCity: '',
        }),
      );
    }

    if (fieldName === 'hasAbroadAddress' && !fieldValue) {
      dispatch(
        updateOnboardingPersonalInfo({
          abroadAddressStreet: '',
          abroadAddressPostCode: '',
          abroadAddressCity: '',
          abroadAddressCountry: '',
          abroadAddressTotalAttendance: '',
        }),
      );
      dispatch(
        updateOnboardingPersonalInfo({
          abroadAddressStreet: '',
          abroadAddressPostCode: '',
          abroadAddressCity: '',
          abroadAddressCountry: '',
          abroadAddressTotalAttendance: '',
        }),
      );
    }

    if (fieldName === 'maritalStatusDetailsSpouseSSN') {
      dispatch(
        updateOnboardingPersonalInfo({
          [fieldName]: createMask(fieldValue, maritalStatusDetailsSpouseSSN),
        }),
      );
    } else {
      dispatch(updateOnboardingPersonalInfo({ [fieldName]: fieldValue }));
    }
  };

  const onChangePhone = (value: string) => {
    dispatch(updateOnboardingPersonalInfo({ phone: value }));
  };

  const onChangeChecked = (event: CheckboxChangeEvent) => {
    const fieldName = event.target.name!;
    const fieldValue = event.target.checked;

    dispatch(updateOnboardingPersonalInfo({ [fieldName]: fieldValue }));
  };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };

  const isMarried = maritalStatus === 'Married';
  const isAddressError = !!getError('hasAbroadAddress');

  return (
    <Row>
      <Col span={12}>
        <h4>Personal details</h4>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input
              name="firstName"
              label="First name"
              value={firstName}
              onChange={onChange}
              errorMessage={getError('data.firstName')}
            />
          </Col>
          <Col span={12}>
            <Input
              name="lastName"
              label="Last name"
              value={lastName}
              onChange={onChange}
              errorMessage={getError('data.lastName')}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input
              name="phone"
              label="Phone number"
              value={phone}
              onChange={onChangePhone}
              errorMessage={getError('data.phone')}
              disabled={isConsultant}
              inputType="phone"
            />
          </Col>
          <Col span={12}>
            <Input
              name="email"
              label="Email"
              value={email}
              onChange={onChange}
              errorMessage={getError('data.email')}
              disabled={isConsultant}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <CommonDatePicker
              label="Date of birth"
              defaultValue={dateOfBirth}
              changeDate={changeDate('dateOfBirth')}
              className="date-picker-form"
              errorMessage={getError('data.dateOfBirth')}
              calendarPosition="right-calendar-position"
            />
          </Col>
          <Col span={12}>
            <Dropdown
              options={genders}
              value={gender}
              onChange={onChangeDropdown('gender')}
              label="Gender"
              showSearch={false}
              errorMessage={getError('data.gender')}
              disabled={false}
              allowClear
              onClear={() => onChangeDropdown('gender')('')}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Dropdown
              options={languages}
              value={language}
              onChange={onChangeDropdown('language')}
              label="Language"
              showSearch={false}
              errorMessage={getError('data.language')}
              allowClear
              onClear={() => onChangeDropdown('language')('')}
            />
          </Col>
          <Col span={12}>
            <Dropdown
              options={countries}
              allowClear
              value={nationality || undefined}
              onChange={onChangeDropdown('nationality')}
              label="Nationality"
              customClassName="dropdown-placeholder"
              placeholder="Type to search"
              errorMessage={getError('data.nationality')}
              isSearch={true}
            />
          </Col>
        </Row>
        {nationality === 'Switzerland' && (
          <Row
            justify="space-between"
            align="middle"
            gutter={[16, 16]}
            style={{ height: 54 }}>
            <Col span={12}>
              <span>Passport expiration date</span>
            </Col>
            <Col span={12} style={{ height: '54px' }}>
              <CommonDatePicker
                label="End date"
                defaultValue={passportExpirationDate}
                changeDate={changeDate('passportExpirationDate')}
                className="date-picker-form"
                errorMessage={getError('data.passportExpirationDate')}
                calendarPosition="right-calendar-position"
              />
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          <Col span={isMarried ? 12 : 24}>
            <Dropdown
              options={maritalStatuses}
              value={maritalStatus}
              onChange={onChangeDropdown('maritalStatus')}
              label="Marital status"
              showSearch={false}
              errorMessage={getError('data.maritalStatus')}
              allowClear
              onClear={() => onChangeDropdown('maritalStatus')('')}
            />
          </Col>
          {isMarried && (
            <Col span={12}>
              <CommonDatePicker
                label="Marriage date"
                defaultValue={maritalStatusDetailsMarriageDate}
                changeDate={changeDate('maritalStatusDetailsMarriageDate')}
                className="date-picker-form"
                errorMessage={getError(
                  'data.maritalStatusDetails.marriageDate',
                )}
                calendarPosition="right-calendar-position"
              />
            </Col>
          )}
        </Row>

        {isMarried && (
          <div style={{ marginTop: '25px' }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Input
                  name="maritalStatusDetailsSpouseName"
                  label="Spouse first name"
                  value={maritalStatusDetailsSpouseName}
                  onChange={onChange}
                  errorMessage={getError(
                    'data.maritalStatusDetails.spouseName',
                  )}
                />
              </Col>
              <Col span={12}>
                <Input
                  name="maritalStatusDetailsSpouseLastName"
                  label="Spouse last name"
                  value={maritalStatusDetailsSpouseLastName}
                  onChange={onChange}
                  errorMessage={getError(
                    'data.maritalStatusDetails.spouseLastName',
                  )}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <CommonDatePicker
                  label="Spouse date of birth"
                  defaultValue={maritalStatusDetailsSpouseDateOfBirth}
                  changeDate={changeDate(
                    'maritalStatusDetailsSpouseDateOfBirth',
                  )}
                  className="date-picker-form"
                  errorMessage={getError(
                    'data.maritalStatusDetails.spouseDateOfBirth',
                  )}
                  calendarPosition="right-calendar-position"
                />
              </Col>
              <Col span={12}>
                <Dropdown
                  options={countries}
                  value={maritalStatusDetailsSpouseNationality}
                  onChange={onChangeDropdown(
                    'maritalStatusDetailsSpouseNationality',
                  )}
                  label="Spouse nationality"
                  errorMessage={getError(
                    'data.maritalStatusDetails.spouseNationality',
                  )}
                  isSearch={true}
                  allowClear
                  onClear={() =>
                    onChangeDropdown('maritalStatusDetailsSpouseNationality')(
                      '',
                    )
                  }
                />
              </Col>
            </Row>

            <Row
              justify="space-between"
              align="middle"
              gutter={[16, 16]}
              style={{ height: 54 }}>
              <Col>
                <span>Does your spouse live in Switzerland?</span>
              </Col>
              <Col>
                <Radio.Group
                  name="maritalStatusDetailsIsSpouseSwissResident"
                  value={maritalStatusDetailsIsSpouseSwissResident}
                  onChange={onChange}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {maritalStatusDetailsIsSpouseSwissResident && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    name="maritalStatusDetailsSpouseSSN"
                    label="Spouse AVS number"
                    value={maritalStatusDetailsSpouseSSN}
                    placeholder="756.XXXX.XXXX.XX"
                    onChange={onChange}
                    errorMessage={getError(
                      'data.maritalStatusDetails.spouseSSN',
                    )}
                  />
                </Col>
              </Row>
            )}

            <Row
              justify="space-between"
              align="middle"
              gutter={[16, 16]}
              style={{ height: 54 }}>
              <Col>
                <span>Does your spouse receive an income?</span>
                <Popover
                  className="info-pop-button"
                  placement="rightTop"
                  content={
                    <p>
                      We need to know this information to determine your
                      tax-at-source.
                    </p>
                  }
                  trigger="hover"
                  overlayClassName="info-pop-content"
                  showArrow={false}>
                  <button>
                    <WarningIcon />
                  </button>
                </Popover>
              </Col>
              <Col>
                <Radio.Group
                  name="maritalStatusDetailsSpousalIncome"
                  value={maritalStatusDetailsSpousalIncome}
                  onChange={onChange}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {maritalStatusDetailsSpousalIncome && (
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Dropdown
                    options={countries}
                    value={maritalStatusDetailsSpouseWorkCountry}
                    onChange={onChangeDropdown(
                      'maritalStatusDetailsSpouseWorkCountry',
                    )}
                    label="Country of working"
                    errorMessage={getError(
                      'data.maritalStatusDetails.spouseWorkCountry',
                    )}
                    isSearch
                    allowClear
                    onClear={() =>
                      onChangeDropdown('maritalStatusDetailsSpouseWorkCountry')(
                        '',
                      )
                    }
                  />
                </Col>
                <Col span={8}>
                  {maritalStatusDetailsSpouseWorkCountry === 'Switzerland' && (
                    <Dropdown
                      options={canton}
                      value={maritalStatusDetailsSpouseWorkCanton}
                      onChange={onChangeDropdown(
                        'maritalStatusDetailsSpouseWorkCanton',
                      )}
                      label="Working canton"
                      showSearch={false}
                      errorMessage={getError(
                        'data.maritalStatusDetails.spouseWorkCanton',
                      )}
                      allowClear
                      onClear={() =>
                        onChangeDropdown(
                          'maritalStatusDetailsSpouseWorkCanton',
                        )('')
                      }
                    />
                  )}

                  {maritalStatusDetailsSpouseWorkCountry !== 'Switzerland' && (
                    <Input
                      name="maritalStatusDetailsSpouseWorkCity"
                      value={maritalStatusDetailsSpouseWorkCity}
                      onChange={onChange}
                      label="Working city"
                      errorMessage={getError(
                        'data.maritalStatusDetails.spouseWorkCity',
                      )}
                    />
                  )}
                </Col>
                <Col span={8}>
                  <CommonDatePicker
                    label="Start date"
                    defaultValue={maritalStatusDetailsSpouseStartedWorkingFrom}
                    changeDate={changeDate(
                      'maritalStatusDetailsSpouseStartedWorkingFrom',
                    )}
                    className="date-picker-form"
                    errorMessage={getError(
                      'data.maritalStatusDetails.spouseStartedWorkingFrom',
                    )}
                    calendarPosition="right-calendar-position"
                  />
                </Col>
              </Row>
            )}
          </div>
        )}

        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span>How do you work?</span>
          </Col>
          <Col>
            <Radio.Group name="workForm" value={workForm} onChange={onChange}>
              <Radio value="corporation">As corporation</Radio>
              <Radio value="payroll">As payroll</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <div className="address-title">
          <h4>Address</h4>
          <Popover
            className="info-pop-button"
            placement="topRight"
            content={<AddressInfoContent />}
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover>
        </div>

        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span style={{ color: isAddressError ? '#c43025' : '#10132f' }}>
              Do you have a Swiss address?
            </span>
          </Col>
          <Col>
            <Radio.Group
              name="hasSwissAddress"
              value={hasSwissAddress}
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>

        {hasSwissAddress && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="swissAddressStreet"
                  label="Street name and number"
                  value={swissAddressStreet}
                  onChange={onChange}
                  errorMessage={getError('data.swissAddress.street')}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Input
                  name="swissAddressPostCode"
                  label="Postcode"
                  value={swissAddressPostCode}
                  onChange={onChange}
                  errorMessage={getError('data.swissAddress.postCode')}
                />
              </Col>
              <Col span={12}>
                <Input
                  name="swissAddressCity"
                  label="City"
                  value={swissAddressCity}
                  onChange={onChange}
                  errorMessage={getError('data.swissAddress.city')}
                />
              </Col>
            </Row>
          </>
        )}

        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ height: 54 }}>
          <Col>
            <span style={{ color: isAddressError ? '#c43025' : '#10132f' }}>
              Do you have an address abroad?
            </span>
          </Col>
          <Col>
            <Radio.Group
              value={hasAbroadAddress}
              name="hasAbroadAddress"
              onChange={onChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>

        {hasAbroadAddress && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Input
                  name="abroadAddressStreet"
                  label="Street name and number"
                  value={abroadAddressStreet}
                  onChange={onChange}
                  errorMessage={getError('data.abroadAddress.street')}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Input
                  name="abroadAddressPostCode"
                  label="Postcode"
                  value={abroadAddressPostCode}
                  onChange={onChange}
                  errorMessage={getError('data.abroadAddress.postCode')}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="abroadAddressCity"
                  label="City"
                  value={abroadAddressCity}
                  onChange={onChange}
                  errorMessage={getError('data.abroadAddress.city')}
                />
              </Col>
              <Col span={8}>
                <Dropdown
                  options={countries}
                  label="Country"
                  value={abroadAddressCountry}
                  onChange={onChangeDropdown('abroadAddressCountry')}
                  errorMessage={getError('data.abroadAddress.country')}
                  isSearch={true}
                  allowClear
                  onClear={() => onChangeDropdown('abroadAddressCountry')('')}
                />
              </Col>
            </Row>

            <Row
              justify="space-between"
              align="middle"
              gutter={[16, 16]}
              style={{ height: 54 }}>
              <Col span={14}>
                <span>How often do you visit this address?</span>
              </Col>
              <Col span={10}>
                <Dropdown
                  options={spendTimeOptions}
                  value={abroadAddressTotalAttendance}
                  onChange={onChangeDropdown('abroadAddressTotalAttendance')}
                  label="Time"
                  showSearch={false}
                  errorMessage={getError('data.abroadAddress.totalAttendance')}
                  allowClear
                  onClear={() =>
                    onChangeDropdown('abroadAddressTotalAttendance')('')
                  }
                />
              </Col>
            </Row>

            <Row
              justify="space-between"
              align="middle"
              gutter={[16, 16]}
              style={{ height: 54 }}>
              <Col>
                <Checkbox
                  name="abroadAddressUseAsCorrespondenceLocation"
                  checked={abroadAddressUseAsCorrespondenceLocation}
                  onChange={onChangeChecked}>
                  I want to receive correspondense to this address.
                </Checkbox>
              </Col>
            </Row>
          </>
        )}

        {isAddressError && (
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col span={24}>
              <span className="personal-info-address-error">
                One of the addresses must be YES
              </span>
            </Col>
          </Row>
        )}

        <div className="document-title">
          <h4>Documents (ID/Passport)</h4>
          <Popover
            className="info-pop-button"
            placement="rightTop"
            content={<DocumentInfContent />}
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover>
        </div>

        <UploadDropzone
          defaultFiles={documents}
          onSubmit={(files) =>
            dispatch(uploadFileOnboardingPersonalInfo(files))
          }
          removeFile={(file) => dispatch(removeOnboardingPersonalInfo(file))}
          errorMessage={getError('data.documents')}
          isDownload
        />
      </Col>
    </Row>
  );
};

export default PersonalInfoTab;

const DocumentInfContent: FC = () => (
  <p>You will be asked to upload work permit in a later section</p>
);

const AddressInfoContent: FC = () => (
  <p>
    If you change the address, you must inform Numeriq immediately as
    tax-at-source should be updated.
  </p>
);
