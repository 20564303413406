import { FC } from 'react';
import { Menu } from 'antd';
import { TimesheetStatus } from '../../../common/api/services/TimesheetApi/enums/TimesheetStatus';

interface IProps {
  showModalEditTimesheet: () => void;
  showModalViewTimesheet: () => void;
  approveTimesheet: () => void;
  rejectTimesheet: () => void;
  expireTimesheet: () => void;
  status: TimesheetStatus;
  id: string;
  publicId: string;
  firstName: string;
  lastName: string;
  setDeleteInfo: (info: any) => void;
}

const statuses: Record<TimesheetStatus, string[]> = {
  [TimesheetStatus.DRAFT]: ['1', '4', '7'],
  [TimesheetStatus.NO_ACTIVITY]: ['1', '7'],
  [TimesheetStatus.EXPIRED]: ['1', '7'],
  [TimesheetStatus.WAITING_FOR_APPROVAL]: ['2', '6', '7'],
  [TimesheetStatus.CLIENT_APPROVAL]: ['2', '6', '3', '7'],
  [TimesheetStatus.PRE_APPROVED]: ['2', '6', '3', '7'],
  [TimesheetStatus.APPROVED]: ['2', '6', '7'],
  [TimesheetStatus.REJECTED]: ['1', '4', '7'],
};

const ActionMenu: FC<IProps> = ({
  showModalEditTimesheet,
  showModalViewTimesheet,
  approveTimesheet,
  rejectTimesheet,
  expireTimesheet,
  status,
  id,
  publicId,
  firstName,
  lastName,
  setDeleteInfo,
}) => {
  const allItems = [
    {
      key: '1',
      label: <span onClick={showModalEditTimesheet}>Edit</span>,
    },
    {
      key: '2',
      label: <span onClick={showModalViewTimesheet}>View</span>,
    },
    {
      key: '3',
      label: <span onClick={approveTimesheet}>Approve</span>,
    },
    {
      key: '4',
      label: <span onClick={expireTimesheet}>Close</span>,
    },
    {
      key: '6',
      label: <span onClick={rejectTimesheet}>Reject</span>,
    },
    {
      key: '7',
      label: (
        <span
          style={{ color: '#C33025' }}
          onClick={() => setDeleteInfo({ id, publicId, firstName, lastName })}>
          Delete
        </span>
      ),
    },
  ];

  const items = allItems
    .map((item) => {
      if (!statuses[status].includes(item.key)) return null;
      return item;
    })
    .filter(Boolean);

  return <Menu items={items} />;
};

export default ActionMenu;
