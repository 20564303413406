import { FC, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ROUTER_PATHS } from './navigation/routerPaths';
import LoginPage from './pages/login-page/LoginPage';
import SetupPasswordPage from './pages/setup-password-page/SetupPasswordPage';
import ForgotPasswordPage from './pages/forgot-password-page/ForgotPasswordPage';
import { useAppSelector } from './redux/store';
import HomePage from './pages/home-page/HomePage';
import OnboardingPage from './pages/onboarding-page/OnboardingPage';
import { Roles } from './common/enums/Roles';
import { Statuses } from './common/enums/Statuses';
import { withResetNewPassword } from './common/HOCs/withResetNewPassword';
import { withConfirmEmployeePassword } from './common/HOCs/withConfirmEmployeePassword';
import { withConfirmLeadPassword } from './common/HOCs/withConfirmLeadPassword';
import OnboardingEditConsultantPage from './pages/onboarding-page/OnboardingEditConsultantPage';
import EmailResponsePage from './pages/email-response-page/EmailResponsePage';
import ViewPayslipsPage from './pages/view-payslips-page/ViewPayslipsPage';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import LogsPage from './pages/logs-page/LogsPage';

const {
  home,
  login,
  setupNewPassword,
  onboarding,
  confirmEmployeePassword,
  confirmLeadPassword,
  approved,
  rejected,
  expired,
  timesheets,
  expenses,
  viewPayslipsPage,
  logs,
} = ROUTER_PATHS;

const App: FC = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isAccess);
  const { role, status } = useAppSelector((state) => state.auth);
  const isOnboarding =
    Roles.CONSULTANT === role && Statuses.inactive === status;
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_HOTJAR && window !== undefined) {
      hotjar.initialize(
        Number(process.env.REACT_APP_HOTJAR_ID),
        Number(process.env.REACT_APP_HOTJAR_SV),
      );
    }
    if (process.env.REACT_APP_ENABLE_GTM && window !== undefined) {
      const GTMArgs = {
        gtmId: process.env.REACT_APP_GTM_ID || '',
      };
      TagManager.initialize(GTMArgs);
    }
  }, []);

  const rolePageRender = isOnboarding ? (
    <Navigate to={onboarding} />
  ) : (
    <HomePage />
  );

  const homeElement = isLoggedIn ? rolePageRender : <Navigate to={login} />;

  return (
    <div className="app">
      <Routes>
        <Route path={home + '*'} element={homeElement} />

        <Route path={login} element={<LoginPage />} />

        <Route
          path={confirmEmployeePassword + '/:token'}
          element={withConfirmEmployeePassword(SetupPasswordPage)}
        />

        <Route
          path={confirmLeadPassword + '/:token'}
          element={withConfirmLeadPassword(SetupPasswordPage)}
        />

        <Route
          path={setupNewPassword + '/:token'}
          element={withResetNewPassword(SetupPasswordPage)}
        />

        {isAdmin && (
          <Route
            path={viewPayslipsPage + '/:consultantId'}
            element={<ViewPayslipsPage />}
          />
        )}
        {isAdmin && (
          <Route path={logs + '/:consultantId'} element={<LogsPage />} />
        )}

        <Route path={setupNewPassword} element={<ForgotPasswordPage />} />

        {isOnboarding ? (
          <Route path={onboarding + '/*'} element={<OnboardingPage />} />
        ) : (
          <Route path={onboarding} element={<Navigate to={home} />} />
        )}

        {isAdmin && (
          <Route
            path={onboarding + '/:consultantId' + '/*'}
            element={<OnboardingEditConsultantPage />}
          />
        )}

        <Route path={expired} element={<EmailResponsePage />} />
        <Route path={approved + timesheets} element={<EmailResponsePage />} />
        <Route path={rejected + timesheets} element={<EmailResponsePage />} />
        <Route path={approved + expenses} element={<EmailResponsePage />} />
        <Route path={rejected + expenses} element={<EmailResponsePage />} />
      </Routes>
    </div>
  );
};

export default App;
