import { ChangeEvent, FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AuthWrapperBackground from '../../components/auth-wrapper-background/AuthWrapperBackground';
import AuthFormHead from '../../components/auth-form-head/AuthFormHead';
import Input from '../../components/input/Input';
import Checkbox from '../../components/checkbox/Checkbox';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import Button from '../../components/button/Button';
import { validation } from './validation';

import './LoginPage.less';
import { ILoginRequest } from '../../common/api/services/AuthApi/types/ILoginRequest';
import { getCurrentUserData } from '../../redux/reducers/auth/asyncThunkActions';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setAuthMessage } from '../../redux/reducers/auth/sliceReducer';

const { setupNewPassword } = ROUTER_PATHS;

const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const errorValidation = useAppSelector((state) => state.auth.errorMassage);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm<ILoginRequest>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: validation,
  });

  useEffect(() => {
    if (errorValidation) {
      setError('email', { message: errorValidation });
    }
    return () => {
      dispatch(setAuthMessage(''));
    };
  }, [errorValidation]);

  const passwordValue = watch().password;
  const emailValue = watch().email;
  const emailErrorMessage = errors.email?.message;
  const passwordErrorMessage = errors.password?.message;

  const onChangeLoginInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldName = e.target.name as 'email' | 'password';
    const fieldValue =
      e.target.name === 'email' ? e.target.value.trim() : e.target.value;
    setValue(fieldName, fieldValue);
  };

  const onSubmit = handleSubmit((data): void => {
    const { password, email } = data;
    dispatch(getCurrentUserData({ email, password }));
  });

  return (
    <AuthWrapperBackground>
      <form className="login-page" onSubmit={onSubmit}>
        <AuthFormHead title="Welcome to Numeriq" />

        <Input
          onChange={onChangeLoginInput}
          value={emailValue}
          label="Email"
          name="email"
          errorMessage={emailErrorMessage}
        />

        <Input
          onChange={onChangeLoginInput}
          value={passwordValue}
          label="Password"
          inputType="password"
          name="password"
          errorMessage={passwordErrorMessage}
        />

        <div className="section-management">
          <Checkbox
            checkboxStyle={{ visibility: 'hidden' }}
            label="Remember me"
          />
          <Link to={setupNewPassword}>Forgot password</Link>
        </div>

        <Button type="submit" buttonSize="normal" buttonStyle="primary">
          Log in
        </Button>
      </form>
    </AuthWrapperBackground>
  );
};

export default LoginPage;
