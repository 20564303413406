import { IClientResponse } from '../../../../common/api/services/ClientApi/types/IClientResponse';
import ILabelInValue from '../../../../common/types/ILabelInValue';

export interface IDropDownElement {
  searchName: string;
  selectedName: ILabelInValue | null;
}
export interface IInitClientContract {
  isFetching: boolean;
  response: IClientResponse | null | undefined;
  signatories: IDropDownElement[];
  listOfSignatoryNames: ILabelInValue[];
  invoiceries: IDropDownElement[];
  listOfInvoiceryNames: ILabelInValue[];
  reminders: IDropDownElement[];
  listOfRemainderNames: ILabelInValue[];
  clientContacts: IDropDownElement[];
  listOfClientContractsNames: ILabelInValue[];
  shippingMethodDetails: any;
  shippingMethod: string;
  vatRate: string;
}

export const initClientContract: IInitClientContract = {
  isFetching: false,
  response: null,
  signatories: [
    {
      searchName: '',
      selectedName: null,
    },
  ],
  listOfSignatoryNames: [],
  invoiceries: [
    {
      searchName: '',
      selectedName: null,
    },
  ],
  listOfInvoiceryNames: [],
  reminders: [
    {
      searchName: '',
      selectedName: null,
    },
  ],
  listOfRemainderNames: [],
  clientContacts: [
    {
      searchName: '',
      selectedName: null,
    },
  ],
  listOfClientContractsNames: [],
  shippingMethodDetails: {},
  shippingMethod: '',
  vatRate: '',
};

export type TInitClientContractType =
  | 'signatories'
  | 'invoiceries'
  | 'reminders'
  | 'clientContacts';
