import { FC } from 'react';
import { Menu } from 'antd';
import { useAppDispatch } from '../../../redux/store';
import { updateOpenModalInvite } from '../../../redux/reducers/employees/sliceReducer';
import { resendInviteEmployee } from '../../../redux/reducers/employees/asyncThunkActions';

interface IProps {
  employeeId: string;
  status: string;
}

const ActionMenu: FC<IProps> = ({ employeeId, status }) => {
  const dispatch = useAppDispatch();

  const onResendInvite = () => {
    dispatch(updateOpenModalInvite(true));
    dispatch(resendInviteEmployee(employeeId));
  };

  const items = [
    {
      key: '3',
      label: (
        <button
          onClick={onResendInvite}
          disabled={status === 'active'}
          style={{ color: status === 'active' ? 'gray' : '#000' }}>
          Resend invite
        </button>
      ),
    },
    // {
    //     key: '3',
    //     label: (
    //         <span style={{color: '#C33025'}}>
    //             Archive
    //         </span>
    //     ),
    // },
  ];

  return <Menu items={items} />;
};

export default ActionMenu;
