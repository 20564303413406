import { FC, useEffect } from 'react';
import CommonTable from '../../components/common-table/CommonTable';
import { TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { ILeadDetails } from '../waiting-room-page/types';
import {
  getFormatDateWithMonth,
  getFormatDateWithSmallMonth,
  getFormatDateWithTime,
} from '../../common/utils/getFormatDate';
import { useAppDispatch, useAppSelector } from '../../redux/store';

import { useTableDateFilter } from '../../common/hooks/useTableDateFilter';
import {
  expensesStatusParser,
  TExpensesStatusParserKey,
} from '../../common/constants/expensesStatusParser';
import {
  changePayslipsSorting,
  getPayslipsList,
} from '../../redux/reducers/payslips/asyncThunkActions';
import { changePayslipsFiltering } from '../../redux/reducers/payslips/sliceReducer';
import DownloadCloudIcon from '../../assets/icons/DownloadCloudIcon';

const PayslipsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { payslipsList } = useAppSelector((state) => state.payslips);

  useEffect(() => {
    dispatch(getPayslipsList());
  }, []);

  const { pagination, sort, filter } = payslipsList;
  const { totalElement, currentPage, perPage } = pagination;

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changePayslipsFiltering(date));
  };

  const dataFilter = useTableDateFilter({
    fetchRequest: () => dispatch(getPayslipsList({ currentPage: 1 })),
    changeValues: changeFilter,
    values: filter as Record<string, string>,
  })();

  // const searchFilterArg = {
  //   fetchRequest: () => dispatch(getExpensesList({ currentPage: 1 })),
  //   changeValues: changeFilter,
  // };

  const onChangeSort: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changePayslipsSorting(sortBy as string));
    }
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getPayslipsList({ perPage, currentPage }));
  };

  const sortClassName = (fieldName: string): string => {
    return sort.sortBy === fieldName ? '__sorted' : '';
  };

  const columns: ColumnsType<ILeadDetails> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Month and year',
      dataIndex: 'period',
      key: 'period',
      ...dataFilter,
      className: sortClassName('period'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      // ...useTableSelectFilter({
      //   ...searchFilterArg,
      //   defaultValues: (filter?.type as string[]) ?? '',
      //   fieldFilterName: 'type',
      // })([
      //   ExpensesType.EXPENSES_FOR_OPTIMISATION,
      //   ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
      // ]),
      className: sortClassName('type'),
      sorter: true,
      sortDirections: ['descend'],
      showSorterTooltip: false,
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Client approval date',
      dataIndex: 'approvalAt',
      key: 'approvalAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      className: `status-td ${sortClassName('status')}`,
      sortDirections: ['descend'],
      showSorterTooltip: false,
      render: (text: TExpensesStatusParserKey) => {
        return (
          <div className="status-wrapper">
            <span className={expensesStatusParser[text]}>{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'payslip-edit-action-td',
      render: () => {
        return <DownloadCloudIcon className="download-icon" />;
      },
    },
  ];

  const data = [1, 2, 3, 4].map((_, idx) => {
    return {
      key: idx,
      id: idx,
      period: getFormatDateWithMonth(new Date().toDateString()),
      createdAt: getFormatDateWithSmallMonth(new Date().toDateString()),
      approvalAt: getFormatDateWithTime(new Date().toDateString()),
      type: 'Some type ' + idx,
      status: 'Some status ' + idx,
    };
  });

  return (
    <CommonTable
      paginationCurrentPage={currentPage}
      paginationPageSize={perPage}
      paginationOnChange={onChangePagination}
      paginationTotalElement={totalElement}
      data={data}
      columns={columns}
      onChange={onChangeSort}
    />
  );
};

export default PayslipsPage;
