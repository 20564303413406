import { Input } from 'antd';

import './Textarea.less';
import { ChangeEvent, FC, useState } from 'react';
import CrossIcon from '../../assets/icons/CrossIcon';

const { TextArea } = Input;

interface IProps {
  value: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  name?: string;
  errorMessage?: string;
  disabled?: boolean;
  rows?: number;
  autoSize?: boolean;
  showAsterik?: boolean;
}

const Textarea: FC<IProps> = (props) => {
  const {
    label,
    name = '',
    value,
    onChange,
    errorMessage,
    disabled,
    rows = 1,
    autoSize: autoSize = true,
    showAsterik,
  } = props;

  const [isFocusClass, setFocusClass] = useState(false);

  const focusClass = isFocusClass ? 'focus-textarea' : '';
  const textareaClassName = !!value
    ? `typed textarea ${focusClass}`
    : `textarea ${focusClass}`;
  const placeholder = !value && isFocusClass ? 'Text' : label;
  const textareaStatus = errorMessage ? 'error' : '';
  const labelErrorClass = textareaStatus ? 'textarea-label-error' : '';
  const labelClass = `textarea-label ${focusClass} ${labelErrorClass}`;

  const textareaClassNameWithoutLabel = !label
    ? `${textareaClassName} without-label`
    : textareaClassName;

  return (
    <label className={labelClass}>
      <TextArea
        autoSize={autoSize}
        status={textareaStatus}
        name={name}
        onFocus={() => setFocusClass(true)}
        onBlur={() => setFocusClass(false)}
        className={textareaClassNameWithoutLabel}
        bordered={false}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        rows={rows}
      />
      <span className="label">
        {label}
        {showAsterik && <span className="asterisk">*</span>}
      </span>
      {errorMessage && (
        <span className="error-message error-text">
          <CrossIcon />
          {errorMessage}
        </span>
      )}
    </label>
  );
};

export default Textarea;
