import { ChangeEvent, FC, useRef, useState, useEffect } from 'react';
import { Col, Modal, Popover, RadioChangeEvent, Row, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  updateOnboardingWorkContract,
  updateOnboardingWorkContractCheckbox,
  setCurrentClientOnboardingContract,
  resetOnboardingWorkContractWorkLocation,
  resetOnboardingWorkContractPaymentCurrency,
  updateOptimizationOnboardingWorkContract,
  clearOnboardingErrorsValidation,
  updateUseMealAllowanceOnboardingWorkContract,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import WorkContactTabInformationSection from './WorkContractTabInformationSection';
import WorkContactTabJobDetailsSection from './WorkContractTabJobDetailsSection';
import Button from '../../../../components/button/Button';
import { Roles } from '../../../../common/enums/Roles';
import {
  SalaryType,
  TreasuryPaymentDate,
} from '../../../../common/api/services/OnboardingApi/types/IWorkContractRequest';
import { IncomeLossInsurance } from '../../../../common/api/services/OnboardingApi/enums/OnboardingIncomeLossInsurance';
import ErrorIcon from '../../../../assets/icons/ErrorIcon';
import moment from '../../../../common/constants/moment';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import { Link } from 'react-router-dom';
import { dublicateClientWorkContractAsEmployee } from '../../../../redux/reducers/onboarding/tabs/workContractAsyncThunk';
import AttentionModal from '../../../../components/attention-modal/AttentionModal';
import { SelectDublicateClient } from '../../../../components/common-texts/CommonTexts';
import { compareBeforeDates } from '../../../../common/utils/compareEndDate';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';

interface IProps {
  onAddNewClient: () => void;
  deleteClientWorkContract: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IncomeInsuranceTable: any = {
  woman: {
    [IncomeLossInsurance.None]: 0,
    [IncomeLossInsurance.ZeroDays]: 7.12,
    [IncomeLossInsurance.TwoDays]: 4.44,
    [IncomeLossInsurance.FourteenDays]: 2.02,
    [IncomeLossInsurance.ThirtyDays]: 1.35,
  },
  man: {
    [IncomeLossInsurance.None]: 0,
    [IncomeLossInsurance.ZeroDays]: 5.6,
    [IncomeLossInsurance.TwoDays]: 3.42,
    [IncomeLossInsurance.FourteenDays]: 1.58,
    [IncomeLossInsurance.ThirtyDays]: 1.19,
  },
};

const WorkContractTab: FC<IProps> = ({
  onAddNewClient,
  deleteClientWorkContract,
}) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refWorkContract = useRef<any>(null);
  const [isOpenDublicateModal, setIsOpenDublicateModal] = useState(false);

  const { workContract, personalInfo, initialWorkContractOrder } =
    useAppSelector((state) => state.onboarding);
  const { role } = useAppSelector((state) => state.auth);
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.workContract,
  );
  const { companies } = workContract;

  const { selectedClientIdx } = workContract;

  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  const [selectedForRemoving, setSelectedForRemoving] = useState(false);
  const [isFirstOpen, setisFirstOpen] = useState(true);

  const clientCount = companies.length;
  useEffect(() => {
    let element;
    element = document.querySelector('.error-message');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    element = document.querySelector('.error-text');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [errorMessages]);

  const getError = (
    fieldName: string,
    isGroup = false,
    likeIs = false,
  ): string => {
    if (errorMessages) {
      let txtMessage = errorMessages[fieldName] ?? '';
      if (txtMessage && !likeIs) {
        if (isGroup) {
          txtMessage =
            typeof errorMessages[fieldName] === 'string'
              ? 'The field is required'
              : '';
        }
        txtMessage = txtMessage.includes(fieldName)
          ? 'The field ' + txtMessage.substring(txtMessage.indexOf(' ') + 1)
          : txtMessage;
      }
      return txtMessage;
    }
    return '';
  };

  const clearValidation = (): void => {
    dispatch(clearOnboardingErrorsValidation());
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    const value = { [fieldName]: fieldValue };
    if (fieldName === 'doubleTimeAllowed' || fieldName === 'overtimeAllowed') {
      value.doubleTimeAllowed = fieldValue;
      value.overtimeAllowed = fieldValue;
    }
    dispatch(updateOnboardingWorkContract(value));
  };

  const onChangePhone = (value: string) => {
    dispatch(updateOnboardingWorkContract({ phone: value }));
  };

  const onChangeDate =
    (fieldName: string) =>
    (date: Date | null): void => {
      const value = date ? moment(date)?.format(commonYearDateFormat) : '';
      dispatch(updateOnboardingWorkContract({ [fieldName]: value }));
    };

  const onChangeNumber =
    (fieldName: string) => (value: number | string | null) => {
      if (typeof value === 'number' && value < 0) return;
      const newObject = { [fieldName]: value ? value : 0 };
      if (fieldName === 'numeriqTime') {
        newObject.key = 'otherJob';
      }
      dispatch(updateOnboardingWorkContract(newObject));
    };

  const onChangeCheckbox = (fieldName: 'receivedWorkContract') => {
    return () => {
      dispatch(updateOnboardingWorkContractCheckbox(fieldName));
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeDropdown = (fieldName: string) => (data: any) => {
    if (fieldName === 'invoiceCurrency')
      dispatch(resetOnboardingWorkContractPaymentCurrency());
    const values = { [fieldName]: data };
    const { type, treasuryPaymentDate } =
      workContract.companies[selectedClientIdx];
    if (
      fieldName === 'treasuryPaymentDate' &&
      !!data &&
      data === TreasuryPaymentDate.IN_DAYS &&
      type !== SalaryType.PerMonth
    ) {
      values.type = '';
    }
    if (
      fieldName === 'type' &&
      !!data &&
      data !== SalaryType.PerMonth &&
      treasuryPaymentDate === TreasuryPaymentDate.IN_DAYS
    ) {
      values.treasuryPaymentDate = '';
    }
    if (fieldName === 'incomeLossInsurance') {
      if (!personalInfo.gender) {
        notification.open({
          message: 'Warning alert',
          description:
            'You have to select the gender in the Personal Information tab.',
          type: 'error',
          icon: <ErrorIcon />,
          className: 'common-error-notification',
        });
        return;
      }
      const listValues = IncomeInsuranceTable[personalInfo.gender];
      values.lossOfIncome = listValues[data] || 0;
    }
    dispatch(updateOnboardingWorkContract(values));
  };

  const onSelectCurrentClient =
    (index: number) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      setisFirstOpen(true);
      dispatch(setCurrentClientOnboardingContract(index));
    };

  const onChangeSwitch = (fieldName: 'indefinite') => {
    const { selectedClientIdx, companies } = workContract;
    const client = companies[selectedClientIdx];
    const { [fieldName]: value } = client;
    const init = { indefinite: false };
    if (fieldName === 'indefinite') onChangeDate('endDate')(null);
    dispatch(
      updateOnboardingWorkContract({
        [fieldName]: value !== undefined ? !value : !init[fieldName],
      }),
    );
  };

  const onChangeSwitchOptimization = () => {
    const { useExpensesForOptimization } = workContract;
    dispatch(
      updateOptimizationOnboardingWorkContract(
        useExpensesForOptimization !== undefined
          ? !useExpensesForOptimization
          : false,
      ),
    );
  };

  const onChangeUseMealAllowance = () => {
    const { useMealAllowance } = workContract;
    dispatch(
      updateUseMealAllowanceOnboardingWorkContract(
        useMealAllowance !== undefined ? !useMealAllowance : false,
      ),
    );
  };

  const onChangeSwitchCommon = (
    fieldName: 'hasRepresentativeAllowance',
    defaultValue = false,
  ) => {
    const { selectedClientIdx, companies } = workContract;
    const company = companies[selectedClientIdx];
    const { [fieldName]: value } = company;
    dispatch(
      updateOnboardingWorkContract({
        [fieldName]: value !== undefined ? !value : !defaultValue,
      }),
    );
  };

  const onChangeLocation = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    dispatch(
      updateOnboardingWorkContract({
        [fieldName]: fieldValue,
        key: 'workLocation',
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeDropdownLocation = (fieldName: string) => (data: any) => {
    dispatch(resetOnboardingWorkContractWorkLocation());
    dispatch(
      updateOnboardingWorkContract({ [fieldName]: data, key: 'workLocation' }),
    );
  };

  const onChangeOtherJob = (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    dispatch(
      updateOnboardingWorkContract({
        [fieldName]: fieldValue,
        key: 'otherJob',
      }),
    );
  };

  const onDeleteClientBtn = () => {
    setSelectedForRemoving(true);
  };

  const onDeleteClientBtnConfirm = () => {
    deleteClientWorkContract();
    setSelectedForRemoving(false);
    refWorkContract.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const checkTab = (index: number) => {
    if (
      errorMessages &&
      Object.keys(errorMessages).filter((item) =>
        item.startsWith(
          `data.companies[${convertSelectedIdx(
            companies,
            initialWorkContractOrder,
            index,
          )}]`,
        ),
      ).length > 0
    )
      return 'error-tab';
    return '';
  };

  const isClientNameDeleted =
    workContract.companies[selectedClientIdx]?.name?.length > 0;
  const clientNameDeleted = isClientNameDeleted
    ? workContract.companies[selectedClientIdx].name
    : `Client ${selectedClientIdx + 1}`;

  const dublicateClient = () => {
    dispatch(dublicateClientWorkContractAsEmployee());
    setisFirstOpen(true);
    setIsOpenDublicateModal(false);
  };

  return (
    <div ref={refWorkContract}>
      <div>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            padding: '0 16px 0 0',
            marginBottom: '20px',
            height: '30px',
          }}>
          <h4>Information</h4>
          <div style={{ display: 'flex' }}>
            <button
              className="add-common-btn add-client-btn"
              onClick={() => {
                const clientsWithName = companies.filter((item) => item.name);
                if (clientCount === 1 || clientsWithName.length === 0) {
                  dublicateClient();
                  return;
                }
                setIsOpenDublicateModal(true);
              }}>
              <CrossIcon />
              Dublicate info
            </button>
            <button
              className="add-common-btn add-client-btn"
              onClick={() => {
                onAddNewClient();
                setisFirstOpen(true);
              }}>
              <CrossIcon />
              Add a Client
            </button>
          </div>
        </div>
        {clientCount !== 1 && (
          <div
            style={{ marginBottom: '1rem', overflowX: 'auto', width: '100%' }}>
            <nav className="navigation-tabs" style={{ marginBottom: 0 }}>
              {companies.map(({ _id, name = '', endDate }, idx) => {
                const isActive = selectedClientIdx === idx;
                const tabName = name.length > 0 ? name : `Client ${idx + 1}`;
                return (
                  <Col
                    key={_id}
                    span={5}
                    style={{ paddingBottom: '5px' }}
                    className={checkTab(idx)}>
                    <Popover
                      placement="top"
                      content={tabName}
                      trigger="hover"
                      overlayClassName="info-pop-content"
                      showArrow={false}>
                      <Link
                        to={'#'}
                        className={`${
                          isActive ? '__active cut-text' : 'cut-text'
                        } ${compareBeforeDates(endDate) ? 'date-ended' : ''}`}
                        onClick={onSelectCurrentClient(idx)}>
                        <span className="tab-label">{tabName}</span>
                      </Link>
                    </Popover>
                  </Col>
                );
              })}
            </nav>
          </div>
        )}
      </div>
      <Row>
        <WorkContactTabInformationSection
          errorMessages={errorMessages}
          workContract={workContract}
          getError={getError}
          clearValidation={clearValidation}
          onAddNewClient={onAddNewClient}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          onSelectCurrentClient={onSelectCurrentClient}
          onDeleteClient={onDeleteClientBtn}
          onChangeNumber={onChangeNumber}
          onChangePhone={onChangePhone}
          isAdmin={isAdmin}
          isFirstOpen={isFirstOpen}
          setIsFirstOpen={setisFirstOpen}
        />

        <WorkContactTabJobDetailsSection
          onChangeNumber={onChangeNumber}
          getError={getError}
          workContract={workContract}
          onChange={onChange}
          onChangeCheckbox={onChangeCheckbox}
          onChangeDate={onChangeDate}
          onChangeDropdown={onChangeDropdown}
          onChangeSwitch={onChangeSwitch}
          onChangeSwitchCommon={onChangeSwitchCommon}
          onChangeSwitchOptimization={onChangeSwitchOptimization}
          onChangeLocation={onChangeLocation}
          onChangeOtherJob={onChangeOtherJob}
          onChangeDropdownLocation={onChangeDropdownLocation}
          isAdmin={isAdmin}
          onChangeUseMealAllowance={onChangeUseMealAllowance}
        />
      </Row>
      {selectedForRemoving && (
        <Modal
          width={490}
          open={selectedForRemoving}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <h4 style={{ marginBottom: 32 }}>Delete</h4>
          <p>
            Are you sure you want to delete <b>{clientNameDeleted}</b>? This
            action is irreversible.
          </p>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() => setSelectedForRemoving(false)}>
                Cancel
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => onDeleteClientBtnConfirm()}>
                Yes, delete
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
      {isOpenDublicateModal && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          width={550}
          closeModal={() => setIsOpenDublicateModal(false)}
          confirmAction={dublicateClient}
          confirmBtnTitle="Dublicate"
          content={<SelectDublicateClient />}
          isOpen={isOpenDublicateModal}
          isHideAttentionText
        />
      )}
    </div>
  );
};

export default WorkContractTab;
