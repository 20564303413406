import { ComponentType } from 'react';
import { useAppDispatch } from '../../redux/store';
import { ISetupPasswordRequest } from '../api/services/AuthApi/types/ISetupPasswordRequest';
import { confirmLeadPassword } from '../../redux/reducers/auth/asyncThunkActions';

interface IPassProps {
  onSubmit: (data: ISetupPasswordRequest) => void;
}

export const withConfirmLeadPassword = (
  WrappedComponent: ComponentType<IPassProps>,
) => {
  const Component = () => {
    const dispatch = useAppDispatch();

    const onSubmit = (data: ISetupPasswordRequest): void => {
      dispatch(confirmLeadPassword(data));
    };

    return <WrappedComponent onSubmit={onSubmit} />;
  };

  return <Component />;
};
