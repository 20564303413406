import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  consultantPayslipsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
};

const reducer = createSlice({
  name: 'consultant-payslips',
  initialState,
  reducers: {
    setConsultantPayslipsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.consultantPayslipsList.content = action.payload.result;
      state.consultantPayslipsList.pagination.totalElement =
        action.payload.total;
    },
    changeConsultantPayslipsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.consultantPayslipsList.filter = {
        ...state.consultantPayslipsList.filter,
        ...action.payload,
      };
      state.consultantPayslipsList.pagination.currentPage = 1;
    },
    setConsultantPayslipsSorting(state, action: PayloadAction<any>) {
      state.consultantPayslipsList.sort.sortBy = action.payload[0];
      state.consultantPayslipsList.sort.sort = action.payload[1];
    },
    clearConsultantPayslipsFiltersAndSorts(state) {
      state.consultantPayslipsList.sort =
        initialState.consultantPayslipsList.sort;
      state.consultantPayslipsList.filter =
        initialState.consultantPayslipsList.filter;
    },
    setConsultantPayslipsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.consultantPayslipsList.pagination.currentPage =
        action.payload.currentPage;
      state.consultantPayslipsList.pagination.perPage = action.payload.perPage;
    },
  },
});

export const {
  setConsultantPayslipsList,
  changeConsultantPayslipsFiltering,
  setConsultantPayslipsSorting,
  clearConsultantPayslipsFiltersAndSorts,
  setConsultantPayslipsPagination,
} = reducer.actions;

export default reducer.reducer;
