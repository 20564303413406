import React, { useEffect, useState } from 'react';
import SearchFilter from './filters/SearchFilter';
import Button from '../../button/Button';
import PeriodFilter from './filters/PeriodFilter';
import SelectFilter from './filters/SelectFilter';
import { FilterMobileTypes } from '../types';
import PeriodRangeFilter from './filters/PeriodRangeFilter';

interface IProps {
  filters: any;
  submitFilters: any;
  setIsOpenFilter: (isOpen: boolean) => void;
}

const MobileFilter = (props: IProps) => {
  const { filters, submitFilters, setIsOpenFilter } = props;
  const [currentFilters, setCurrentFilters] = useState<any>([]);

  useEffect(() => {
    setCurrentFilters([...filters]);
  }, []);

  const onChangeFilter = (value: any, key: string) => {
    setCurrentFilters((prevValue: any) =>
      prevValue.map((item: any) => {
        if (item.dataIndex === key) {
          return { ...item, defaultFilter: value };
        }
        return item;
      }),
    );
  };

  const onSubmitFilter = () => {
    const body: any = {};
    currentFilters.map(
      (item: any) => (body[item.dataIndex] = item.defaultFilter),
    );

    submitFilters(body);
    setIsOpenFilter(false);
  };

  return (
    <div className="filters-mobile-container">
      <h4 className="filters-mobile-title">Filters</h4>
      {currentFilters?.length &&
        currentFilters
          .filter((item: any) => item.filterType)
          .map((filter: any) => {
            if (filter.filterType === FilterMobileTypes.search) {
              return (
                <SearchFilter
                  filter={filter}
                  key={filter.dataIndex}
                  onChangeFilter={onChangeFilter}
                />
              );
            }
            if (filter.filterType === FilterMobileTypes.data) {
              return (
                <PeriodFilter
                  filter={filter}
                  key={filter.dataIndex}
                  onChangeFilter={onChangeFilter}
                />
              );
            }
            if (filter.filterType === FilterMobileTypes.dataRange) {
              return (
                <PeriodRangeFilter
                  filter={filter}
                  key={filter.dataIndex}
                  onChangeFilter={onChangeFilter}
                />
              );
            }
            if (filter.filterType === FilterMobileTypes.select) {
              return (
                <SelectFilter
                  filter={filter}
                  key={filter.dataIndex}
                  onChangeFilter={onChangeFilter}
                />
              );
            }
            return <div key={filter.dataIndex}>{filter.key}</div>;
          })}
      <div className="mobile-filters-footer">
        <Button
          buttonSize="normal"
          buttonStyle="error"
          onClick={() => setIsOpenFilter(false)}>
          Close
        </Button>
        <Button
          buttonSize="normal"
          buttonStyle="primary"
          onClick={onSubmitFilter}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MobileFilter;
