import { ChangeEvent, FC, useEffect } from 'react';
import { Row, RadioChangeEvent, Col, Popover } from 'antd';
import {
  addNewInvoiceryOnboardingClientContract,
  addNewReminderOnboardingClientContract,
  addNewSignatoryOnboardingClientContract,
  removeInvoiceryOnboardingClientContract,
  removeReminderOnboardingClientContract,
  removeSignatoryOnboardingClientContract,
  resetOnboardingClientContractShippingDetails,
  setCurrentClientOnboardingContract,
  updateOnboardingClientContract,
  updateOnboardingClientContractCheckbox,
  updateOnboardingClientContractShippingDetails,
  updateOnboardingClientContractInvoicery,
  updateOnboardingClientContractReminder,
  updateOnboardingClientContractSignatory,
  updateOnboardingClientContractContact,
  addPurchaseItem,
  addNewAddressOnboardingClientContract,
  updateOnboardingClientContractAddress,
  removeAddressOnboardingClientContract,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import ClientContractTabAgencySection from './ClientContractTabAgencySection';
import ClientContractTabInformationSection from './ClientContractTabInformationSection';
import { Roles } from '../../../../common/enums/Roles';
import { TInitClientContractType } from './helper';
import moment from '../../../../common/constants/moment';
import { commonYearDateFormat } from '../../../../common/constants/dateConstants';
import InfoMessage from '../../../../components/info-messages/InfoMessage';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { Link } from 'react-router-dom';
import {
  compareBeforeDates,
  compareDates,
} from '../../../../common/utils/compareEndDate';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';

const ClientContractTab: FC = () => {
  const dispatch = useAppDispatch();
  const { clientContract, workContract, initialClientContractOrder } =
    useAppSelector((state) => state.onboarding);
  const clientPaymentTermRange = useAppSelector(
    (state) => state.clients.selectedClient.paymentTermRange,
  );
  const clientPaymentTerm = useAppSelector(
    (state) => state.clients.selectedClient.paymentTerm,
  );
  const { paymentTermRange, purchaseOrders } =
    clientContract.clients[clientContract.selectedClientIdx];
  const { role } = useAppSelector((state) => state.auth);

  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.clientContract,
  );
  const client = useAppSelector((state) => state.clients.selectedClient);

  const { selectedClientIdx, clients } = clientContract;

  const clientCount = clients.length;

  const shippingMethodDetails =
    client.shippings.length > 0
      ? client.shippings[0].details
      : {
          postalAddress: '',
          firstName: '',
          lastName: '',
          portalUrl: '',
        };

  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  useEffect(() => {
    let element;
    element = document.querySelector('.error-message');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    element = document.querySelector('.error-text');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [errorMessages]);

  const getError = (fieldName: string, isGroup = false): string => {
    if (errorMessages) {
      let txtMessage = errorMessages[fieldName] ?? '';
      if (txtMessage) {
        if (isGroup) {
          txtMessage =
            typeof errorMessages[fieldName] === 'string'
              ? 'The field is required'
              : '';
        }
        txtMessage = txtMessage.includes(fieldName)
          ? 'The field ' + txtMessage.substring(txtMessage.indexOf(' ') + 1)
          : txtMessage;
      }
      return txtMessage;
    }
    return '';
  };

  const onAddNewSignatory = (): void => {
    dispatch(addNewSignatoryOnboardingClientContract());
  };

  const onAddNewInvoicery = (): void => {
    dispatch(addNewInvoiceryOnboardingClientContract());
  };

  const onAddNewReminder = (): void => {
    dispatch(addNewReminderOnboardingClientContract());
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;

    if (fieldName === 'enabledPurchaseOrders' && !purchaseOrders?.length) {
      dispatch(addPurchaseItem());
    }
    if (fieldName === 'isAgencyInvolved') {
      resetFields('agencyName', '');
      resetFields('agencyEmail', '');
      resetFields('agencyCountry', '');
    }
    dispatch(updateOnboardingClientContract({ [fieldName]: fieldValue }));
  };

  const onChangeCheckbox = (
    fieldName: 'receivedClientContract' | 'agencyConfirmed',
  ) => {
    return () => {
      dispatch(updateOnboardingClientContractCheckbox(fieldName));
    };
  };

  const onChangeDate =
    (fieldName: string) =>
    (date: Date | null): void => {
      const value = date ? moment(date)?.format(commonYearDateFormat) : '';
      dispatch(updateOnboardingClientContract({ [fieldName]: value }));
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeDropdown = (fieldName: string) => (data: any) => {
    if (fieldName === 'shippingMethod') {
      const shippingMethod = data ? data.replace(/\s*\([^)]*\)\s*/, '') : '';
      dispatch(
        resetOnboardingClientContractShippingDetails(shippingMethodDetails),
      );
      dispatch(
        updateOnboardingClientContract({
          [fieldName]: shippingMethod,
        }),
      );
      return;
    }
    if (fieldName === 'numeriqFeesType') {
      dispatch(
        updateOnboardingClientContract({
          numeriqFees: 0,
          minFee: 0,
          maxFee: 0,
        }),
      );
    }

    if (fieldName === 'paymentTerm' || fieldName === 'vatRate') {
      dispatch(
        updateOnboardingClientContract({
          [fieldName]: data,
        }),
      );

      if (clientPaymentTerm === data)
        dispatch(
          updateOnboardingClientContract({
            paymentTermRange: clientPaymentTermRange || paymentTermRange,
          }),
        );
      return;
    }

    dispatch(updateOnboardingClientContract({ [fieldName]: data }));
  };

  const onChangeValuesByIdxAndName = (
    index: number,
    rootName: TInitClientContractType,
    value: Record<string, string>,
  ) => {
    switch (rootName) {
      case 'signatories':
        dispatch(
          updateOnboardingClientContractSignatory({
            index,
            ...value,
          }),
        );
        break;
      case 'invoiceries':
        dispatch(
          updateOnboardingClientContractInvoicery({
            index,
            ...value,
          }),
        );
        break;
      case 'reminders':
        dispatch(
          updateOnboardingClientContractReminder({
            index,
            ...value,
          }),
        );
        break;
      case 'clientContacts':
        dispatch(
          updateOnboardingClientContractContact({
            index,
            ...value,
          }),
        );
        break;
      default:
        break;
    }
  };

  const onChangeNumber =
    (fieldName: string) => (value: number | string | null) => {
      if (typeof value === 'number' && value < 0) return;
      dispatch(
        updateOnboardingClientContract({
          [fieldName]: value,
        }),
      );
    };

  const onChangeShippingDetails = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    dispatch(
      updateOnboardingClientContractShippingDetails({
        [fieldName]: fieldValue,
      }),
    );
  };

  const onChangeSwitch = (fieldName: 'indefinite') => {
    const { selectedClientIdx, clients } = clientContract;
    const client = clients[selectedClientIdx];
    const { [fieldName]: value } = client;
    const init = { indefinite: false };
    if (fieldName === 'indefinite') onChangeDate('endDate')(null);
    dispatch(
      updateOnboardingClientContract({
        [fieldName]: value !== undefined ? !value : !init[fieldName],
      }),
    );
  };

  const onAddNewAddress = (): void => {
    dispatch(addNewAddressOnboardingClientContract());
  };

  const onChangeAddressByIndex =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fieldName = event.target.name!;
      const fieldValue = event.target.value;
      if (fieldName === 'vat' && fieldValue === '') {
        dispatch(
          updateOnboardingClientContractAddress({
            index,
            [fieldName]: undefined,
          }),
        );
        return;
      }
      dispatch(
        updateOnboardingClientContractAddress({
          index,
          [fieldName]: fieldValue,
        }),
      );
    };

  const onChangeAddressByIndexField =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, fieldName: string) => (data: any) => {
      dispatch(
        updateOnboardingClientContractAddress({ index, [fieldName]: data }),
      );
    };

  const onDeleteAddress = (index: number) => () => {
    dispatch(removeAddressOnboardingClientContract(index));
  };

  const onChangeWorkType = (
    event: ChangeEvent<HTMLInputElement> | RadioChangeEvent,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value ? 'Full time' : 'Part time';
    const newState = { [fieldName]: fieldValue };
    if (event.target.value) newState.workHoursPerWeek = ''; // reset workHoursPerWeek if selected full time
    dispatch(updateOnboardingClientContract(newState));
  };

  const onSelectCurrentClient =
    (index: number) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      dispatch(setCurrentClientOnboardingContract(index));
    };

  const resetFields = (fieldName: string, fieldValue: string | null): void => {
    dispatch(updateOnboardingClientContract({ [fieldName]: fieldValue }));
  };

  const onDeleteSignatory = (index: number) => () => {
    dispatch(removeSignatoryOnboardingClientContract(index));
  };

  const onDeleteInvoicery = (index: number) => () => {
    dispatch(removeInvoiceryOnboardingClientContract(index));
  };

  const onDeleteReminder = (index: number) => () => {
    dispatch(removeReminderOnboardingClientContract(index));
  };

  const onChangeSignatoryByIndex =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fieldName = event.target.name!;
      const fieldValue = event.target.value;
      dispatch(
        updateOnboardingClientContractSignatory({
          index,
          [fieldName]: fieldValue,
        }),
      );
    };

  const onChangeInvoiceryByIndex =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fieldName = event.target.name!;
      const fieldValue = event.target.value;
      dispatch(
        updateOnboardingClientContractInvoicery({
          index,
          [fieldName]: fieldValue,
        }),
      );
    };

  const onChangeReminderByIndex =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fieldName = event.target.name!;
      const fieldValue = event.target.value;
      dispatch(
        updateOnboardingClientContractReminder({
          index,
          [fieldName]: fieldValue,
        }),
      );
    };

  const onChangeContractContact = (
    index: number,
    value: string,
    name: string,
  ): void => {
    dispatch(updateOnboardingClientContractContact({ index, [name]: value }));
  };

  const checkTab = (index: number) => {
    if (
      errorMessages &&
      Object.keys(errorMessages).filter((item) =>
        item.startsWith(
          `data.clients[${convertSelectedIdx(
            clients,
            initialClientContractOrder,
            index,
          )}]`,
        ),
      ).length > 0
    )
      return 'error-tab';
    return '';
  };

  return (
    <div
      style={
        isAdmin
          ? { width: '100%', overflow: 'hidden' }
          : { width: '100%', overflowX: 'hidden' }
      }>
      <div>
        <h4>Information</h4>
        <br />
        <div style={{ width: '50%' }}>
          <InfoMessage message="Here you can enter the details of the contract between Numeriq and your client" />
        </div>

        {clientCount !== 1 && (
          <div
            style={{
              marginBottom: '1rem',
              overflowX: 'auto',
              width: '100%',
            }}>
            <nav
              className="navigation-tabs"
              style={{ marginBottom: 0, justifyContent: 'flex-start' }}>
              {clients.map(({ _id, endDate, companyId }, idx) => {
                const isActive = selectedClientIdx === idx;
                const isWorkContractName = workContract.companies.find(
                  (item) => item._id === companyId,
                )?.name;
                const tabName = isWorkContractName || `Client ${idx + 1}`;
                return (
                  <Col key={_id} span={5} className={checkTab(idx)}>
                    <Popover
                      placement="top"
                      content={tabName}
                      trigger="hover"
                      overlayClassName="info-pop-content"
                      showArrow={false}>
                      <Link
                        to={'#'}
                        className={`${
                          isActive ? '__active cut-text' : 'cut-text'
                        } ${compareBeforeDates(endDate) ? 'date-ended' : ''}`}
                        onClick={onSelectCurrentClient(idx)}>
                        <span className="tab-label">{tabName}</span>
                      </Link>
                    </Popover>
                  </Col>
                );
              })}
            </nav>
          </div>
        )}
        <br />
        <span className="help-info-label">
          Enter the name and email address of the person who needs to sign the
          contract at the client
        </span>
        <br />
        <ErrorMessage
          errorMessage={getError(
            `data.clients[${selectedClientIdx}].signatories`,
          )}
        />
      </div>
      <Row>
        <ClientContractTabInformationSection
          workContract={workContract}
          errorMessages={errorMessages}
          clientContract={clientContract}
          getError={getError}
          onChange={onChange}
          onChangeDate={onChangeDate}
          onChangeDropdown={onChangeDropdown}
          onChangeShippingDetails={onChangeShippingDetails}
          onChangeSwitch={onChangeSwitch}
          onChangeWorkType={onChangeWorkType}
          onChangeValuesByIdxAndName={onChangeValuesByIdxAndName}
          onSelectCurrentClient={onSelectCurrentClient}
          resetFields={resetFields}
          onAddNewSignatory={onAddNewSignatory}
          onAddNewInvoicery={onAddNewInvoicery}
          onAddNewReminder={onAddNewReminder}
          onDeleteSignatory={onDeleteSignatory}
          onDeleteInvoicery={onDeleteInvoicery}
          onDeleteReminder={onDeleteReminder}
          onChangeSignatoryByIndex={onChangeSignatoryByIndex}
          onChangeInvoiceryByIndex={onChangeInvoiceryByIndex}
          onChangeReminderByIndex={onChangeReminderByIndex}
          onChangeContractContact={onChangeContractContact}
          isAdmin={isAdmin}
        />
        <ClientContractTabAgencySection
          getError={getError}
          clientContract={clientContract}
          workContract={workContract}
          onChange={onChange}
          onChangeCheckbox={onChangeCheckbox}
          onChangeDropdown={onChangeDropdown}
          resetFields={resetFields}
          onChangeNumber={onChangeNumber}
          isAdmin={isAdmin}
          onAddNewAddress={onAddNewAddress}
          onChangeAddressByIndex={onChangeAddressByIndex}
          onChangeAddressByIndexField={onChangeAddressByIndexField}
          onDeleteAddress={onDeleteAddress}
        />
      </Row>
    </div>
  );
};

export default ClientContractTab;
