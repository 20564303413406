import '../ModifyClientPage.less';
import { Modal, Space } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown/Dropdown';
import { deleteClientEntityWithUpdate } from '../../../redux/reducers/clients/asyncThunkActions';
import { toogleErrorModal } from '../../../redux/reducers/clients/sliceReducer';
import { IClientEntity } from '../../../redux/reducers/clients/type';
import { useAppSelector } from '../../../redux/store';
import Button from '../../../components/button/Button';

interface IProps {
  isOpen: boolean;
  data: IClientEntity | null;
}
const DeleteInfoModal = (props: IProps) => {
  const { isOpen, data } = props;
  const { currentDeleteInfo } = useAppSelector((state) => state.clients);

  const dispatch: any = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedError, setSelectederror] = useState(false);
  const currentEntity = currentDeleteInfo?.entity;

  const options =
    data?.reminders ||
    data?.signatories ||
    data?.invoiceries ||
    data?.emails ||
    data?.phones ||
    data?.addresses ||
    [];

  const setDataLabel = (s: any) => {
    if (data?.emails) {
      return s.email;
    }
    if (data?.phones) {
      return s.phone;
    }
    if (data?.addresses) {
      return `${s.street || ''} ${s.postCode || ''} ${s.country || ''} ${
        s.city || ''
      }`;
    }
    return `${s.name || ''} ${s.lastName || ''} ${s.email || ''}`;
  };

  const signatoriesOptions = data
    ? options?.map((s) => {
        return {
          label: setDataLabel(s),
          value: s._id,
        };
      })
    : [];

  const onCancel = () => {
    dispatch(toogleErrorModal(false));
  };

  const onSave = () => {
    if (!selectedOption) {
      setSelectederror(true);
      return;
    }
    const dataRequest: any = {
      id: selectedOption,
      entity: currentDeleteInfo?.entity as string,
      prevId: currentDeleteInfo?.id,
    };
    dispatch(deleteClientEntityWithUpdate(dataRequest));
  };

  return (
    <Modal
      open={isOpen}
      closable={false}
      maskClosable={false}
      footer={null}
      wrapClassName="footer-edit-lead-modal-window-wrapper">
      <div>
        <span>
          This {currentEntity?.slice(0, -1) || 'data'} exists in{' '}
          {data?.consultants.length} more Consultants. Replace it with suggested{' '}
          {currentEntity || 'data'} or type a new one.
        </span>
        <span>
          Consultants with this {currentEntity?.slice(0, -1) || 'data'}:
        </span>
        {data?.consultants.map((c, index) => (
          <div key={c._id} className="consultants-list">
            <span>{index + 1}. </span>
            <span>
              {c.firstName || ''} {c.lastName || ''}
            </span>
          </div>
        ))}
        <span className="error-message">
          If you save the changes, they will be applied immediately
        </span>
        <Dropdown
          label="Choose option"
          options={signatoriesOptions}
          onChange={setSelectedOption}
          value={selectedOption}
        />
        {selectedError && (
          <span className="error-message">Please, select option first</span>
        )}
      </div>
      <Space className="button-wrapper footer-btns">
        <Button buttonSize="medium" buttonStyle="secondary" onClick={onCancel}>
          RETURN
        </Button>
        <Button buttonSize="medium" buttonStyle="primary" onClick={onSave}>
          SAVE
        </Button>
      </Space>
    </Modal>
  );
};

export default DeleteInfoModal;
