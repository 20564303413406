import React, { ChangeEvent } from 'react';
import {
  ExpensesStatus,
  ExpensesType,
} from '../../../common/api/services/ConsultantApi/enums/ResponseEnums';
import { Modal, RadioChangeEvent } from 'antd';
import { expensesStatusParser } from '../../../common/constants/expensesStatusParser';
import ModalViewExpense from '../../internal-expenses-page/components/ModalViewExpense';
import ModalEditExpense from '../../internal-expenses-page/components/ModalEditExpense';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  removeFileSelectedExpense,
  resetFilesSelectedExpense,
  setErrorMessages,
  updateSelectedExpense,
  updateSelectedExpenseCheckbox,
} from '../../../redux/reducers/expenses/sliceReducer';
import {
  saveAsDraftInternalExpense,
  uploadFile,
} from '../../../redux/reducers/expenses/asyncThunkActions';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';

interface IProps {
  selectedMainActivity: any;
  modalType: 'close' | '' | 'delete' | 'view' | 'edit' | 'add';
  closeAction: () => void;
  getMainActivities: () => void;
}

const ExpensesComponent = (props: IProps) => {
  const { modalType, selectedMainActivity, closeAction, getMainActivities } =
    props;
  const dispatch = useAppDispatch();
  const { isUpLoading, errorMessages, selectedExpense } = useAppSelector(
    (state) => state.expenses,
  );
  const getError = (fieldName: string): string => {
    if (errorMessages) {
      if (typeof errorMessages === 'string')
        return fieldName === '' ? errorMessages : '';

      return errorMessages[fieldName] ?? '';
    } else {
      return '';
    }
  };
  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = event.target.name!;
    const fieldValue = event.target.value;
    if (fieldName === 'hasExpensesInThisMonth' && fieldValue === false) {
      dispatch(resetFilesSelectedExpense());
      dispatch(setErrorMessages(null));
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
          numeriqComments: '',
          total: 0,
        }),
      );
    }
    dispatch(
      updateSelectedExpense({
        [fieldName]: fieldValue,
      }),
    );
  };

  const onChangeCheckbox = (fieldName: 'isSigned' | 'approvalRequired') => {
    return () => {
      dispatch(
        updateSelectedExpense({
          isSigned: false,
          approvalRequired: false,
          companyRepresentativeEmail: '',
          representativeFirstName: '',
          representativeLastName: '',
          comments: '',
        }),
      );
      dispatch(updateSelectedExpenseCheckbox(fieldName));
    };
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = Array.isArray(e) ? e : e.target?.files;
    if (!files) return;

    dispatch(uploadFile(files));
  };
  const onChangeVisibility = (visibility: boolean) => {
    dispatch(
      updateSelectedExpense({
        visibility: visibility ? 'public' : 'private',
      }),
    );
  };
  const onRemoveAttachFile = (originalFilename: string) => {
    dispatch(removeFileSelectedExpense(originalFilename));
  };

  const onSaveAsDraft = async () => {
    const { _id: expenseId, consultantId, total } = selectedExpense;
    const { isSuccess, data } = await consultantApi.putInternalExpense(
      expenseId,
      consultantId,
      {
        ...selectedExpense,
        total: total || 0,
        status: ExpensesStatus.DRAFT,
      },
    );
    if (isSuccess) {
      closeAction();
      getMainActivities();
    } else {
      dispatch(setErrorMessages(data));
    }
  };

  const onSubmit = async () => {
    const {
      _id: expenseId,
      consultantId,
      hasExpensesInThisMonth,
      isSigned,
      approvalRequired,
      type,
    } = selectedExpense;

    if (
      [
        ExpensesType.EXPENSES_FOR_OPTIMISATION,
        ExpensesType.EXPENSES_MEAL_ALLOWANCE,
      ].includes(type) &&
      hasExpensesInThisMonth
    ) {
      const { isSuccess, data } = await consultantApi.putInternalExpense(
        expenseId,
        consultantId,
        {
          ...selectedExpense,
          status: ExpensesStatus.PRE_APPROVED,
          approvalRequired: false,
          isSigned: true,
        },
      );
      if (isSuccess) {
        closeAction();
        getMainActivities();
      } else {
        dispatch(setErrorMessages(data));
      }
    } else {
      const status = !hasExpensesInThisMonth
        ? ExpensesStatus.NO_EXPENSES
        : isSigned
        ? ExpensesStatus.PRE_APPROVED
        : ExpensesStatus.WAITING_FOR_APPROVAL;

      const { isSuccess, data } = await consultantApi.putInternalExpense(
        expenseId,
        consultantId,
        {
          ...selectedExpense,
          status,
          approvalRequired:
            status === ExpensesStatus.WAITING_FOR_APPROVAL
              ? true
              : approvalRequired,
        },
      );

      if (isSuccess) {
        closeAction();
        getMainActivities();
      } else {
        dispatch(setErrorMessages(data));
      }
    }
  };
  return (
    <>
      {modalType === 'view' &&
        [
          ExpensesType.EXPENSES_FOR_OPTIMISATION,
          ExpensesType.EXPENSES_MEAL_ALLOWANCE,
          ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ].includes(selectedMainActivity?.type) && (
          <Modal
            width={640}
            title={
              <div className="modal-view-title">
                View expenses{' '}
                <span
                  className={
                    expensesStatusParser[selectedMainActivity?.status]
                  }>
                  {selectedMainActivity?.status.toUpperCase() || ''}
                </span>
              </div>
            }
            open={
              modalType === 'view' &&
              [
                ExpensesType.EXPENSES_FOR_OPTIMISATION,
                ExpensesType.EXPENSES_MEAL_ALLOWANCE,
                ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
              ].includes(selectedMainActivity?.type)
            }
            onCancel={closeAction}
            centered
            footer={null}
            destroyOnClose={true}
            wrapClassName="modal-window-wrapper i-expense view">
            <ModalViewExpense selectedExpense={selectedMainActivity} />
          </Modal>
        )}
      {modalType === 'edit' &&
        [
          ExpensesType.EXPENSES_FOR_OPTIMISATION,
          ExpensesType.EXPENSES_MEAL_ALLOWANCE,
          ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
        ].includes(selectedMainActivity?.type) && (
          <Modal
            width={640}
            title={'Edit mode'}
            open={
              modalType === 'edit' &&
              [
                ExpensesType.EXPENSES_FOR_OPTIMISATION,
                ExpensesType.EXPENSES_MEAL_ALLOWANCE,
                ExpensesType.EXPENSES_REIMBURSED_BY_CLIENT,
              ].includes(selectedMainActivity?.type)
            }
            onCancel={closeAction}
            centered
            footer={null}
            destroyOnClose={true}
            wrapClassName="modal-window-wrapper expense edit">
            <ModalEditExpense
              isUpLoading={isUpLoading}
              selectedExpense={selectedExpense}
              getError={getError}
              onChange={onChange}
              onChangeCheckbox={onChangeCheckbox}
              onChangeFile={onChangeFile}
              onRemoveAttachFile={onRemoveAttachFile}
              onSaveAsDraft={onSaveAsDraft}
              onSubmit={onSubmit}
              onChangeVisibility={onChangeVisibility}
            />
          </Modal>
        )}
    </>
  );
};

export default ExpensesComponent;
