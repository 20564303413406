import { FC, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import Button from '../../button/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../navigation/routerPaths';
import { useCallbackPrompt } from '../../../common/hooks/useCallbackPrompt';
import { setIsUpdateSelectedClient } from '../../../redux/reducers/clients/sliceReducer';
import { AsyncThunk } from '@reduxjs/toolkit';

const { clients } = ROUTER_PATHS;

interface IProps {
  onSubmit: AsyncThunk<void, void, any>;
}
const ModifyClientFooter: FC<IProps> = ({ onSubmit }) => {
  const { isUpdateSelectedClient } = useAppSelector((state) => state.clients);
  const [showPrompt, configurationNavigation, cancelNavigation] =
    useCallbackPrompt(isUpdateSelectedClient);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const onSubmitDetails = () => {
    dispatch(setIsUpdateSelectedClient(false));
    dispatch(onSubmit());
  };

  const showModal = () => {
    if (isUpdateSelectedClient) {
      setOpen(true);
      dispatch(setIsUpdateSelectedClient(false));
      return;
    }

    navigate(clients);
  };

  const handleCancel = () => {
    setOpen(false);
    dispatch(setIsUpdateSelectedClient(true));
  };

  return (
    <Row
      className="waiting-room-footer"
      justify="end"
      align="middle"
      style={{ padding: '13px 40px' }}>
      <Row gutter={[16, 0]}>
        <Col>
          <Button
            buttonSize="normal"
            buttonStyle="secondary"
            onClick={showModal}>
            Cancel
          </Button>
        </Col>

        <Col style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Button
            buttonSize="normal"
            buttonStyle="primary"
            onClick={onSubmitDetails}>
            Save
          </Button>
        </Col>
      </Row>

      {isOpen && (
        <Modal
          width={490}
          open={isOpen}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p>
            Are you sure you want to exit? Changes you have made will not be
            saved.
          </p>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={handleCancel}>
                Return
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={() => {
                  handleCancel();
                  navigate(clients);
                }}>
                Yes, exit
              </Button>
            </Col>
          </Row>
        </Modal>
      )}

      {showPrompt && (
        <Modal
          width={490}
          open={showPrompt}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <p>
            Are you sure you want to exit? Changes you have made will not be
            saved.
          </p>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={cancelNavigation}>
                Return
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={configurationNavigation}>
                Yes, exit
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Row>
  );
};

export default ModifyClientFooter;
