import { Moment } from 'moment';
import moment from '../constants/moment';

export const isMonthDisabled =
  (dateContract: Record<string, string | undefined>) =>
  (current: Moment): boolean => {
    const { startDate, endDate } = dateContract;

    if (!startDate && !endDate) return false;
    const _startDate = moment(startDate);
    const _endDate = moment(endDate);

    // Allow only month after start date
    if (startDate && current.isBefore(_startDate)) return true;

    // Allow only month before and include end date
    if (endDate && current.isAfter(_endDate)) return true;

    return false;
  };
