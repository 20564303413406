import { FC } from 'react';
import { IPassProps, passProps } from './passProps';
const TimesheetsIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.20928 6.32497C6.96302 6.0955 6.57927 6.10228 6.34126 6.34029C6.10325 6.5783 6.09648 6.96205 6.32595 7.2083L9.47595 10.3583V14.2666C9.47595 14.6118 9.75577 14.8916 10.1009 14.8916C10.4461 14.8916 10.7259 14.6118 10.7259 14.2666V10.1C10.7258 9.93427 10.6599 9.7754 10.5426 9.6583L7.20928 6.32497Z" />
      <rect
        x="1.66699"
        y="1.66675"
        width="16.6667"
        height="16.6667"
        rx="4.16667"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default TimesheetsIcon;
