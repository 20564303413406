import { FC } from 'react';
import { passProps, IPassProps } from './passProps';

const SuccessIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.583206"
        y="0.333252"
        width="13.3333"
        height="13.3333"
        rx="6.66667"
        fill="#009262"
      />
      <path
        d="M11.032 4.59985L6.23202 9.39985L4.0502 7.21804"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
