import './MonitoringPage.less';
import './../../components/common-table/CommonTable.less';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTableSearchFilter } from '../../common/hooks/useTableSearchFilter';
import { Modal, TableProps } from 'antd';
import { getDateByGreenwich } from '../../common/utils/getFormatDate';
import CommonTable from '../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Key, useEffect, useState } from 'react';
import {
  changeMonitoringSorting,
  getMonitoring,
  saveMonitoringNotes,
} from '../../redux/reducers/monitoring/actionAsyncThunk';
import {
  changeMonitoringFiltering,
  selectMonitoringListItem,
} from '../../redux/reducers/monitoring/sliceReducer';
import EditIcon from '../../assets/icons/EditIcon';
import NodeEdit, { ISelectedNode } from './components/NodeEdit';
import { MonitoringStatuses } from '../../common/api/services/MonitorngApi/enums/MonitoringStatuses';
import {
  renderEndOfDateContract,
  renderEndOfDatePermit,
  renderStatusContract,
  renderStatusPermit,
} from '../../common/utils/monitoringPageUtils';
import { useTableDateFilterByField } from '../../common/hooks/useTableDataFilterByField';
import { useTableSelectStatusFilter } from '../../common/hooks/useTableSelectStatusFilter';
import { useTableMonthDate } from '../../common/hooks/useTableMonthDate';
import {
  cantons,
  documentTypes,
  permitTypes,
} from '../../common/constants/dropdownTypes';
import { LPPLevel } from '../../common/api/services/OnboardingApi/enums/OnboardingLPPLevel';
import { IncomeLossInsurance } from '../../common/api/services/OnboardingApi/enums/OnboardingIncomeLossInsurance';
import { MonitoringEndOfServices } from './enums/MonitoringEndOfServices';
import { numeriqOfficeOptions } from '../../common/constants/numeriqOfficeOptions';
import TableTooltip from '../../components/common-table/table-tooltip';
import EditNoteModal from '../../components/edit-note-modal/EditNoteModal';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import SimpleDropdown from '../../components/simple-dropdown/SimpleDropdown';
import EditActionIcon from '../../assets/icons/EditActionIcon';
import ActionMenu from './components/ActionMenu';
import AttentionModal from '../../components/attention-modal/AttentionModal';
import { FinishContractText } from '../../components/common-texts/CommonTexts';
import { monitoringApi } from '../../common/api/services/MonitorngApi/monitoringApi';
import { IGetMonitoringListRequest } from '../../common/api/services/MonitorngApi/types/IGetMonitoringListRequest';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../components/notification/Notification';

const {
  follow,
  action,
  optimal,
  finished,
  wlabroad,
  no_permit,
  ongoing,
  partly_ongoing,
} = MonitoringStatuses;

const { onboarding } = ROUTER_PATHS;

const filtersOptions = [
  follow,
  action,
  optimal,
  finished,
  wlabroad,
  no_permit,
  ongoing,
  partly_ongoing,
];

const MonitoringPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { monitoringList } = useAppSelector((state) => state.monitoring);
  const { content, pagination, sort, filter, selectedElement } = monitoringList;
  const { totalElement, currentPage, perPage } = pagination;
  const [selectedNote, setSelectedNote] = useState<ISelectedNode | null>(null);
  const [isOpenFinishedContract, setisOpenFinishedContract] = useState(false);
  const [selectedReport, setSelectedReport] =
    useState<IGetMonitoringListRequest | null>(null);

  useEffect(() => {
    dispatch(getMonitoring());
  }, [dispatch]);

  const onRenewContract = (item: any) => {
    monitoringApi
      .renewContract(
        item._id,
        item.consultantOnboarding.workContract.companies._id,
      )
      .then(() => {
        openSuccessNotification('The contract was renewed successfully');
        closeFinishContract();
        dispatch(getMonitoring());
      })
      .catch((e) => {
        openErrorNotification('Failed to renew the contract');
      });
  };

  const onHideModal = (): void => {
    setSelectedNote(null);
  };

  const onChangePagination = (currentPage: number, perPage: number): void => {
    dispatch(getMonitoring({ currentPage, perPage }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange: TableProps<any>['onChange'] = (...params) => {
    const currentDataSours = params[3].action;

    if (currentDataSours === 'sort') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortBy = (params[2] as SorterResult<any>).field;
      dispatch(changeMonitoringSorting(sortBy as string));
    }
  };

  const sortClassName = (fieldName: string, smallText?: boolean): string => {
    //const smallTextClass = smallText ? ' small-text' : '';
    //for return smallText in column
    const smallTextClass = smallText ? '' : '';
    return sort.sortBy === fieldName
      ? '__sorted' + smallTextClass
      : smallTextClass;
  };

  const changeFilter = (date: Record<string, string | string[]>): void => {
    dispatch(changeMonitoringFiltering(date));
  };

  const searchFilterArg = {
    fetchRequest: () => dispatch(getMonitoring({ currentPage: 1 })),
    changeValues: changeFilter,
  };

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    dispatch(selectMonitoringListItem(newSelectedRowKeys as string[]));
  };

  const rowSelection = {
    selectedRowKeys: selectedElement,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onChangeNotes = (
    consultantId: string,
    clientId: string,
    notes: string,
  ): void => {
    dispatch(saveMonitoringNotes([consultantId, clientId, notes]));
  };

  const setEndServiceClassName = (value: string) => {
    switch (value) {
      case MonitoringEndOfServices.OK: {
        return 'ok';
      }
      case MonitoringEndOfServices.ERROR: {
        return 'error';
      }
      case MonitoringEndOfServices.FINISHED: {
        return 'finished-cc';
      }
      case MonitoringEndOfServices.TOACTIONCC: {
        return 'to-action-cc';
      }
      case MonitoringEndOfServices.TOFOLLOW: {
        return 'to-follow';
      }
      default:
        return 'urgent';
    }
  };

  const openFinishContract = (item: any) => {
    setSelectedReport(item);
    setisOpenFinishedContract(true);
  };

  const closeFinishContract = () => {
    setSelectedReport(null);
    setisOpenFinishedContract(false);
  };

  const onSubmitFinishContract = () => {
    if (!selectedReport) {
      return;
    }
    monitoringApi
      .finishContract(
        selectedReport._id,
        selectedReport.consultantOnboarding.workContract.companies._id,
      )
      .then(() => {
        openSuccessNotification(
          'The Consultant was marked as finished successfully',
        );
        closeFinishContract();
        dispatch(getMonitoring());
      })
      .catch((e) => {
        openErrorNotification(
          e?.response?.data?.message ||
            'Failed to mark the consultant as finished',
        );
      });
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'publicId',
      width: 100,
      fixed: 'left',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.publicId as string) ?? '',
        searchAsString: false,
      })('publicId'),
      sorter: true,
      sortDirections: ['descend'],
    },
    {
      title: 'Payroll/Corp',
      dataIndex: 'payrollCorp',
      fixed: 'left',
      key: 'payrollCorp',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.payrollCorp as string[]) ?? [],
        fieldFilterName: 'payrollCorp',
      })(['payroll', 'corporation']),
      className: sortClassName('payrollCorp'),
      sorter: true,
      sortDirections: ['descend'],
      width: 155,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.firstName as string) ?? '',
      })('firstName'),
      sorter: true,
      sortDirections: ['descend'],
      className: sortClassName('firstName'),
      width: 150,
      fixed: 'left',
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.lastName as string) ?? '',
      })('lastName'),
      sorter: true,
      className: sortClassName('lastName'),
      sortDirections: ['descend'],
      width: 150,
      showSorterTooltip: false,
      fixed: 'left',
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'clientName',
      key: 'clientName',
      className: sortClassName('clientName'),
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.clientName as string) ?? '',
      })('clientName'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      showSorterTooltip: false,
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={150}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Project info',
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.projectDescription as string) ?? '',
      })('projectDescription'),
      className: sortClassName('projectDescription'),
      sorter: true,
      sortDirections: ['descend'],
      width: 150,
      render: (projectInfo) => {
        return (
          <TableTooltip tootltipTitle={projectInfo} columnWidth={150}>
            <div className="cut-text">{projectInfo}</div>
          </TableTooltip>
        );
      },
    },
    {
      title: 'Consultant start date',
      dataIndex: 'workContractStart',
      key: 'workContractStart',
      width: 170,
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'workContractStart',
        placeholder: 'date',
        defaultValue: (filter?.workContractStart as string) ?? '',
      })(),
      sorter: true,
      className: sortClassName('workContractStart', true),
      showSorterTooltip: false,
      sortDirections: ['descend'],
    },
    {
      title: 'Consultant end date',
      dataIndex: 'workContractEnd',
      key: 'workContractEnd',
      width: 170,
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'workContractEnd',
        placeholder: 'date',
        defaultValue: (filter?.workContractEnd as string) ?? '',
      })(),
      sorter: true,
      className: sortClassName('workContractEnd', true),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (value: string, data: any) => {
        return (
          <span
            style={{
              color: value !== data.clientContractEnd ? '#e02b1d' : '',
            }}>
            {value}
          </span>
        );
      },
    },
    {
      title: 'Client end date',
      dataIndex: 'clientContractEnd',
      key: 'clientContractEnd',
      width: 170,
      ...useTableMonthDate({
        ...searchFilterArg,
        valueType: 'clientContractEnd',
        placeholder: 'date',
        defaultValue: (filter?.clientContractEnd as string) ?? '',
      })(),
      sorter: true,
      className: sortClassName('clientContractEnd'),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (value: string, data: any) => {
        return (
          <span
            style={{ color: value !== data.workContractEnd ? '#e02b1d' : '' }}>
            {value}
          </span>
        );
      },
    },
    {
      title: 'End of service',
      dataIndex: 'endOfService',
      showSorterTooltip: false,
      sortDirections: ['descend'],
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.endOfService as string[]) ?? [],
        fieldFilterName: 'endOfService',
      })(Object.values(MonitoringEndOfServices)),
      key: 'endOfService',
      width: 170,
      sorter: true,
      className: `status-td ${sortClassName('endOfService')}`,
      render: (text: string, record: any) => {
        const endOfService = record?.item?.endOfService || '';
        return (
          <span className={setEndServiceClassName(endOfService)}>
            {endOfService || ''}
          </span>
        );
      },
    },
    {
      title: 'Permit type',
      dataIndex: 'permitType',
      key: 'permitType',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.permitType as string[]) ?? [],
        fieldFilterName: 'permitType',
      })(permitTypes),
      className: `cut-text ${sortClassName('permitType')}`,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      width: 150,
    },
    {
      title: 'Permit end',
      dataIndex: 'permitEnd',
      key: 'permitEnd',
      width: 145,
      ...useTableDateFilterByField({
        ...searchFilterArg,
        field: 'permitEnd',
        values: (filter?.permitEnd as string) ?? '',
      })(),
      sorter: true,
      className: sortClassName('permitEnd'),
      showSorterTooltip: false,
      sortDirections: ['descend'],
    },
    {
      title: 'Work permit status',
      dataIndex: 'workPermitStatus',
      key: 'workPermitStatus',
      sorter: true,
      className: `status-td ${sortClassName('workPermitStatus', true)}`,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.workPermitStatus as string[]) ?? [],
        fieldFilterName: 'workPermitStatus',
      })(filtersOptions),
      width: 175,
      render: (text: string) => {
        return <span className={text.split(' ').join('-')}>{text}</span>;
      },
    },
    {
      title: 'Banque',
      dataIndex: 'banque',
      sorter: true,
      showSorterTooltip: false,
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.banque as string[]) ?? [],
        fieldFilterName: 'banque',
        isNoValue: true,
      })(['No', 'IBAN']),
      sortDirections: ['descend'],
      className: sortClassName('banque'),
      width: 170,
      render: (value: string, record: any) => (
        <TableTooltip
          tootltipTitle={record?.item?.banque || 'No'}
          columnWidth={170}>
          <span>{record?.item?.banque || 'No'}</span>
        </TableTooltip>
      ),
    },
    {
      title: 'AVS/AHV number',
      dataIndex: 'ssn',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.ssn as string) ?? '',
      })('ssn'),
      key: 'ssn',
      width: 180,
      sorter: true,
      className: sortClassName('ssn'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={200}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'Registration',
      dataIndex: 'hasRegistration',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.hasRegistration as string[]) ?? [],
        fieldFilterName: 'hasRegistration',
      })(['Yes', 'No']),
      key: 'hasRegistration',
      width: 150,
      sorter: true,
      className: sortClassName('hasRegistration'),
    },

    {
      title: 'Annual AVS/AHV salary amount',
      dataIndex: 'annualSalaryAmount',
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.annualSalaryAmount as string) ?? '',
      })('annualSalaryAmount'),
      key: 'annualSalaryAmount',
      width: 210,
      sorter: true,
      className: sortClassName('annualSalaryAmount', true),
    },
    {
      title: 'LPP level',
      dataIndex: 'lppLevel',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.lppLevel as string[]) ?? [],
        fieldFilterName: 'lppLevel',
      })(Object.values(LPPLevel)),
      key: 'lppLevel',
      showSorterTooltip: false,
      width: 130,
      sorter: true,
      className: sortClassName('lppLevel'),
      render: (text: string) => (
        <TableTooltip tootltipTitle={text} columnWidth={130}>
          {text}
        </TableTooltip>
      ),
    },
    {
      title: 'LPP Registration',
      dataIndex: 'hasLppRegistration',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.hasLppRegistration as string[]) ?? [],
        fieldFilterName: 'hasLppRegistration',
      })(['Yes', 'No']),
      key: 'hasLppRegistration',
      showSorterTooltip: false,
      width: 180,
      sorter: true,
      className: sortClassName('hasLppRegistration'),
    },
    {
      title: 'Loss of income insurance',
      dataIndex: 'incomeLossInsurance',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.incomeLossInsurance as string[]) ?? [],
        fieldFilterName: 'incomeLossInsurance',
      })(Object.values(IncomeLossInsurance)),
      key: 'incomeLossInsurance',
      showSorterTooltip: false,
      width: 190,
      sorter: true,
      className: sortClassName('incomeLossInsurance', true),
      render: (text: string, record: any) => (
        <span>
          {record?.item?.consultantOnboarding?.workContract?.companies
            ?.incomeLossInsurance || 'No'}
        </span>
      ),
    },
    {
      title: 'Meal Allowance',
      dataIndex: 'useMealAllowance',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.useMealAllowance as string[]) ?? [],
        fieldFilterName: 'useMealAllowance',
      })(['Yes', 'No']),
      key: 'useMealAllowance',
      showSorterTooltip: false,
      width: 170,
      sorter: true,
      className: sortClassName('useMealAllowance'),
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'CBA/CCT',
      dataIndex: 'hasCBA',
      key: 'hasCBA',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.hasCBA as string[]) ?? [],
        fieldFilterName: 'hasCBA',
      })(['Yes', 'No']),
      className: sortClassName('hasCBA'),
      sorter: true,
      sortDirections: ['descend'],
      width: 120,
    },
    {
      title: 'CCT Official',
      dataIndex: 'isCctOfficial',
      key: 'isCctOfficial',
      ...useTableSelectStatusFilter({
        ...searchFilterArg,
        defaultValues: (filter?.isCctOfficial as string[]) ?? [],
        fieldFilterName: 'isCctOfficial',
      })(['Yes', 'No']),
      className: sortClassName('isCctOfficial'),
      sorter: true,
      sortDirections: ['descend'],
      width: 145,
    },
    // {
    //   title: 'Numeriq Office WC',
    //   dataIndex: 'numeriqOfficeWC',
    //   key: 'numeriqOfficeWC',
    //   ...useTableSelectStatusFilter({
    //     ...searchFilterArg,
    //     defaultValues: (filter?.numeriqOfficeWC as string[]) ?? [],
    //     fieldFilterName: 'numeriqOfficeWC',
    //   })(numeriqOfficeOptions),
    //   className: sortClassName('numeriqOfficeWC', true),
    //   sorter: true,
    //   sortDirections: ['descend'],
    //   width: 160,
    // },
    // {
    //   title: 'Numeriq Office CC',
    //   dataIndex: 'numeriqOfficeCC',
    //   key: 'numeriqOfficeCC',
    //   ...useTableSelectStatusFilter({
    //     ...searchFilterArg,
    //     defaultValues: (filter?.numeriqOfficeCC as string[]) ?? [],
    //     fieldFilterName: 'numeriqOfficeCC',
    //   })(numeriqOfficeOptions),
    //   className: sortClassName('numeriqOfficeCC', true),
    //   sorter: true,
    //   sortDirections: ['descend'],
    //   width: 160,
    // },

    // {
    //   title: 'Work contract days to end',
    //   dataIndex: 'workContractDaysToEnd',
    //   key: 'workContractDaysToEnd',
    //   width: 170,
    //   sorter: true,
    //   className: sortClassName('workContractDaysToEnd', true),
    //   showSorterTooltip: false,
    //   sortDirections: ['descend'],
    // },
    // {
    //   title: 'Client start date',
    //   dataIndex: 'clientContractStart',
    //   key: 'clientContractStart',
    //   width: 170,
    //   ...useTableMonthDate({
    //     ...searchFilterArg,
    //     valueType: 'clientContractStart',
    //     placeholder: 'date',
    //     defaultValue: (filter?.clientContractStart as string) ?? '',
    //   })(),
    //   sorter: true,
    //   className: sortClassName('clientContractStart'),
    //   showSorterTooltip: false,
    //   sortDirections: ['descend'],
    // },

    // {
    //   title: 'Client contract days to end',
    //   dataIndex: 'clientContractDaysToEnd',
    //   key: 'clientContractDaysToEnd',
    //   width: 170,
    //   sorter: true,
    //   className: sortClassName('clientContractDaysToEnd', true),
    //   showSorterTooltip: false,
    //   sortDirections: ['descend'],
    // },

    // {
    //   title: 'Remaining days',
    //   dataIndex: 'remainingDays',
    //   key: 'remainingDays',
    //   width: 150,
    //   sorter: true,
    //   className: sortClassName('remainingDays'),
    //   showSorterTooltip: false,
    //   sortDirections: ['descend'],
    // },

    // {
    //   title: 'Permit canton',
    //   dataIndex: 'permitCanton',
    //   key: 'permitCanton',
    //   ...useTableSelectStatusFilter({
    //     ...searchFilterArg,
    //     defaultValues: (filter?.permitCanton as string[]) ?? [],
    //     fieldFilterName: 'permitCanton',
    //   })(cantons),
    //   sorter: true,
    //   sortDirections: ['descend'],
    //   className: sortClassName('permitCanton'),
    //   width: 176,
    //   render: (text: string) => (
    //     <TableTooltip tootltipTitle={text} columnWidth={176}>
    //       {text}
    //     </TableTooltip>
    //   ),
    // },
    {
      title: 'Seller',
      dataIndex: 'seller',
      key: 'seller',
      width: 170,
      sorter: true,
      className: sortClassName('seller', true),
      showSorterTooltip: false,
      sortDirections: ['descend'],
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.seller as string) ?? '',
        searchAsString: false,
      })('seller'),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      className: '',
      width: 216,
      render: (text: string, { item, firstName, lastName }) => {
        return (
          <div className="monitoring-edit-notes">
            <span className="cut-text">{text}</span>
            <EditIcon
              onClick={() => {
                setSelectedNote({
                  clientId:
                    item.consultantOnboarding.clientContract.clients._id,
                  consultantId: item._id,
                  name: `${firstName} ${lastName}`,
                  notesValue: text,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: `Consultant's email`,
      dataIndex: 'email',
      key: 'email',
      width: 220,
      sorter: true,
      className: sortClassName('email'),
      showSorterTooltip: false,
      ...useTableSearchFilter({
        ...searchFilterArg,
        defaultValue: (filter?.email as string) ?? '',
        searchAsString: false,
      })('email'),
      sortDirections: ['descend'],
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'edit-action-td',
      render: (_, { item }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SimpleDropdown
              options={
                <ActionMenu
                  item={item}
                  openFinishContract={openFinishContract}
                  onRenewContract={onRenewContract}
                />
              }>
              <EditActionIcon className="edit-action-icon" />
            </SimpleDropdown>
          </div>
        );
      },
      fixed: 'right',
      width: 80,
    },
  ];

  const data = content.map((item) => {
    const {
      consultantOnboarding,
      workContractStartInDay,
      workContractEndInDays,
      clientContractEndInDays,
      clientContractStartInDays,
      workPermitStatus,
      permitType,
      remainingDays,
      permitEnd,
      seller,
      _id,
      email,
      endOfService,
    } = item;

    const isIndefiniteWork =
      !!consultantOnboarding.workContract.companies?.indefinite;
    const isIndefiniteClient =
      !!consultantOnboarding.clientContract.clients?.indefinite;

    const workContractDaysToEnd = renderEndOfDateContract(
      workContractStartInDay,
      workContractEndInDays,
    );

    const clientContractDaysToEnd = renderEndOfDateContract(
      clientContractStartInDays,
      clientContractEndInDays,
    );

    const workContractEnd = isIndefiniteWork
      ? 'Indefinite'
      : getDateByGreenwich(
          item.consultantOnboarding.workContract.companies.endDate,
        );

    const clientContractEnd = isIndefiniteClient
      ? 'Indefinite'
      : getDateByGreenwich(
          item.consultantOnboarding.clientContract.clients.endDate,
        );
    const hasCBA = item.consultantOnboarding.workContract.companies?.hasCBA
      ? 'Yes'
      : 'No';
    const document = item.consultantOnboarding.workPermit.documentType;
    const permitCanton = item.consultantOnboarding.workPermit.permitCanton;

    return {
      key: item.consultantOnboarding.workContract.companies._id,
      item,
      publicId: item.publicId,
      firstName: item.firstName,
      lastName: item.lastName,
      clientName:
        item.consultantOnboarding.workContract.companies?.name ?? 'No',
      projectDescription:
        item.consultantOnboarding.workContract.companies.projectDescription ||
        'No',
      workContractStart: getDateByGreenwich(
        item.consultantOnboarding.workContract.companies?.startDate ?? '',
      ),
      workContractEnd,
      workContractDaysToEnd,
      hasCBA,
      clientContractStart: getDateByGreenwich(
        item.consultantOnboarding.clientContract.clients?.startDate ?? '',
      ),
      clientContractEnd,
      clientContractDaysToEnd,
      clientContractStatus: renderStatusContract(clientContractEndInDays),
      workPermitStatus: workPermitStatus,
      permitEnd: getDateByGreenwich(permitEnd ?? ''),
      remainingDays,
      document: (document?.length ?? 0) > 0 ? document : 'No',
      permitType: (permitType?.length ?? 0) > 0 ? permitType : 'No',
      permitCanton: (permitCanton?.length ?? 0) > 0 ? permitCanton : 'No',
      notes: item.consultantOnboarding.clientContract.clients?.notes ?? 'No',
      annualSalaryAmount:
        item?.consultantOnboarding?.ssnInformation?.annualSalaryAmount || 'No',
      ssn: item?.consultantOnboarding?.ssnInformation?.ssn || 'No',
      lppLevel: item?.consultantOnboarding?.ssnInformation?.lppLevel || 'No',
      hasLppRegistration: item?.consultantOnboarding?.ssnInformation
        ?.hasLppRegistration
        ? 'Yes'
        : 'No',
      hasRegistration: item?.consultantOnboarding?.ssnInformation
        ?.hasRegistration
        ? 'Yes'
        : 'No',
      numeriqOfficeCC:
        item?.consultantOnboarding?.clientContract?.clients?.numeriqOffice ||
        'No',
      numeriqOfficeWC:
        item?.consultantOnboarding?.workContract?.companies?.numeriqOffice ||
        'No',
      isCctOfficial: consultantOnboarding?.workContract?.companies
        ?.isCctOfficial
        ? 'Yes'
        : 'No',
      useMealAllowance: consultantOnboarding?.workContract?.useMealAllowance
        ? 'Yes'
        : 'No',
      payrollCorp: consultantOnboarding?.personalInformation?.workForm,
      consultantId: _id,
      seller: `${seller?.firstName || ''} ${seller?.lastName || ''}`,
      email,
      endOfService,
    };
  });

  const onDoubleClick = (record: any) => {
    window.open(onboarding + `/${record.consultantId}`, '_blank');
  };

  return (
    <>
      <CommonTable
        paginationCurrentPage={currentPage}
        paginationPageSize={perPage}
        paginationOnChange={onChangePagination}
        paginationTotalElement={totalElement}
        data={data}
        columns={columns}
        onChange={onChange}
        rowSelection={rowSelection}
        onDoubleClick={onDoubleClick}
      />
      {!!selectedNote && (
        <EditNoteModal
          isOpen={!!selectedNote}
          onCancel={onHideModal}
          onChangeNotes={onChangeNotes}
          selectedNote={selectedNote}
        />
      )}
      {isOpenFinishedContract && (
        <AttentionModal
          cancelBtnTitle="Cancel"
          closeModal={closeFinishContract}
          confirmBtnTitle="Submit"
          confirmAction={onSubmitFinishContract}
          content={<FinishContractText />}
          isOpen={isOpenFinishedContract}
          isHideAttentionText
          hideCloseBtn
          width={546}
          btnWidth="225px"
        />
      )}
    </>
  );
};

export default MonitoringPage;
