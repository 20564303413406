import { FC, Fragment } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../redux/store';
import { childGenderParser } from '../../../common/constants/childGenderParser';
import { getFormatDateWithSmallMonth } from '../../../common/utils/getFormatDate';
import DocumentsViewer from '../components/DocumentsViewer';

const ChildAllowance: FC = () => {
  const { children, documents, hasChildren } = useAppSelector(
    (state) => state.onboarding.childAllowances,
  );

  if (!hasChildren) {
    return (
      <Row>
        <Col>
          <h4>You don&lsquo;t have children</h4>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="my-information-tab">
      <Col span={12}>
        <h4>Child’s information</h4>

        {children.map(
          (
            {
              firstName,
              lastName,
              dateOfBirth,
              gender,
              SSNumber,
              useFamilyAllowance,
            },
            idx,
          ) => (
            <Fragment key={idx}>
              {children.length > 1 && (
                <h4 className="others-title">Child {idx + 1}</h4>
              )}
              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">First name</span>
                      <span className="item-value">{firstName}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Last name</span>
                      <span className="item-value">{lastName}</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Date of birth</span>
                      <span className="item-value">
                        {dateOfBirth
                          ? getFormatDateWithSmallMonth(dateOfBirth)
                          : ''}
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">Gender</span>
                      <span className="item-value">
                        {childGenderParser[gender as 'man' | 'woman'] ?? ''}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">AVS number</span>
                      <span className="item-value">
                        {SSNumber ? SSNumber : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="personal-info">
                      <span className="item-label">
                        Do you want to receive the family allowance for this
                        child?
                      </span>
                      <span className="item-value">
                        {useFamilyAllowance ? 'Yes' : 'No'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Fragment>
          ),
        )}
      </Col>

      <Col span={12}>
        <h4>Documents</h4>

        <DocumentsViewer documents={documents} />
      </Col>
    </Row>
  );
};

export default ChildAllowance;
