import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const PayslipsIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 10V7.27009C15.8333 6.88019 15.6966 6.50264 15.447 6.20311L12.1664 2.26636C11.8498 1.88638 11.3807 1.66667 10.886 1.66667H9.16667H5.83333C3.99238 1.66667 2.5 3.15906 2.5 5V15C2.5 16.841 3.99238 18.3333 5.83333 18.3333H10"
        stroke="#DBEDFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.082 11V12.3959H14.2925V11H15.082ZM14.9858 18.7271V20H14.1964V18.7271H14.9858ZM15.5425 17.1751C15.5425 16.9953 15.5071 16.8423 15.4362 16.7161C15.3688 16.5899 15.2574 16.4779 15.1022 16.3801C14.9504 16.2823 14.7446 16.1877 14.4848 16.0962C14.0462 15.9385 13.6599 15.7713 13.3259 15.5946C12.9953 15.4148 12.7372 15.1924 12.5516 14.9274C12.3661 14.6593 12.2733 14.3202 12.2733 13.9101C12.2733 13.5189 12.3728 13.1798 12.5719 12.8927C12.7709 12.6057 13.0459 12.3849 13.3968 12.2303C13.751 12.0726 14.1626 11.9937 14.6316 11.9937C14.9892 11.9937 15.3131 12.0442 15.6032 12.1451C15.8934 12.2429 16.143 12.388 16.3522 12.5804C16.5614 12.7697 16.7217 13.0016 16.833 13.276C16.9443 13.5505 17 13.8644 17 14.2177H15.5476C15.5476 14.0284 15.5256 13.8612 15.4818 13.7161C15.4379 13.571 15.3738 13.4495 15.2895 13.3517C15.2085 13.2539 15.1107 13.1814 14.996 13.1341C14.8812 13.0836 14.7547 13.0584 14.6164 13.0584C14.4106 13.0584 14.2419 13.0962 14.1103 13.1719C13.9787 13.2476 13.8826 13.3502 13.8219 13.4795C13.7645 13.6057 13.7358 13.7508 13.7358 13.9148C13.7358 14.0757 13.7662 14.2161 13.8269 14.336C13.891 14.4558 14.0007 14.5662 14.1559 14.6672C14.3111 14.765 14.5236 14.8659 14.7935 14.97C15.2321 15.1278 15.6167 15.2981 15.9474 15.4811C16.278 15.664 16.5361 15.888 16.7217 16.153C16.9072 16.418 17 16.7555 17 17.1656C17 17.5726 16.8988 17.9196 16.6964 18.2066C16.4939 18.4905 16.2105 18.7082 15.8462 18.8596C15.4818 19.0079 15.0601 19.082 14.581 19.082C14.2706 19.082 13.9619 19.0442 13.6549 18.9685C13.3478 18.8896 13.0695 18.7634 12.8198 18.5899C12.5702 18.4164 12.3711 18.1861 12.2227 17.8991C12.0742 17.6088 12 17.2524 12 16.8297H13.4575C13.4575 17.0599 13.4895 17.2524 13.5536 17.4069C13.6177 17.5584 13.7021 17.6798 13.8067 17.7713C13.9146 17.8596 14.0361 17.9227 14.1711 17.9606C14.306 17.9984 14.4426 18.0174 14.581 18.0174C14.7969 18.0174 14.974 17.9811 15.1123 17.9085C15.254 17.836 15.3603 17.7366 15.4312 17.6104C15.5054 17.4811 15.5425 17.336 15.5425 17.1751Z"
        fill="#DBEDFF"
      />
      <path
        d="M11.6667 2.08333V5C11.6667 5.92047 12.4129 6.66666 13.3334 6.66666H15.4167"
        stroke="#DBEDFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PayslipsIcon;
