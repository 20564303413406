import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const PeopleIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 20 15"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        r="2.83688"
        transform="matrix(-1 0 0 1 10.1778 4.00363)"
        strokeWidth="1.5"
      />
      <path
        d="M5.21289 11.0496C5.21289 10.4394 5.59648 9.89509 6.17111 9.68986V9.68986C8.76185 8.7646 11.593 8.7646 14.1837 9.68986V9.68986C14.7584 9.89509 15.142 10.4394 15.142 11.0496V11.9826C15.142 12.8247 14.3961 13.4716 13.5624 13.3525L13.2844 13.3128C11.2236 13.0184 9.1313 13.0184 7.07043 13.3128L6.79247 13.3525C5.95878 13.4716 5.21289 12.8247 5.21289 11.9826V11.0496Z"
        strokeWidth="1.5"
      />
      <path
        d="M14.4341 6.91914C15.6651 6.91914 16.6631 5.92119 16.6631 4.69016C16.6631 3.45913 15.6651 2.46118 14.4341 2.46118"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.8752 12.0044L17.0936 12.0356C17.7486 12.1292 18.3347 11.6209 18.3347 10.9592V10.2261C18.3347 9.74668 18.0333 9.31901 17.5818 9.15776C17.1314 8.99692 16.6718 8.87166 16.207 8.78198"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.56687 6.91914C4.33584 6.91914 3.33789 5.92119 3.33789 4.69016C3.33789 3.45913 4.33584 2.46118 5.56687 2.46118"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.12578 12.0044L2.90739 12.0356C2.25234 12.1292 1.66629 11.6209 1.66629 10.9592V10.2261C1.66629 9.74668 1.96767 9.31901 2.41917 9.15776C2.86955 8.99692 3.32917 8.87166 3.79395 8.78198"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PeopleIcon;
