import { Col, Modal, Row, Upload } from 'antd';
import { useEffect, useState } from 'react';
import ILabelInValue from '../../../common/types/ILabelInValue';
import { consultantApi } from '../../../common/api/services/ConsultantApi/consultantApi';
import useDebounce from '../../../common/hooks/useDebounce';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IConsultantsResponse } from '../../../common/api/services/ConsultantApi/types/IConsultantsResponse';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';
import Textarea from '../../../components/textarea/Textarea';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { fileApi } from '../../../common/api/services/FileApi/FileApi';
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../../components/notification/Notification';
import '../../../components/input/Input.less';
import AttachFileIcon from '../../../assets/icons/AttachFileIcon';

const initDropdownValue = { label: '', value: '' };

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getDocuments: () => void;
}

const UploadDocumentModal = (props: IProps) => {
  const { isOpen, setIsOpen, getDocuments } = props;
  const [selectedConsultant, setSelectedConsultant] =
    useState<ILabelInValue>(initDropdownValue);
  const [searchConsultant, setSearchConsultant] = useState<string>('');
  const searchConsultantDeb = useDebounce(searchConsultant, 500);
  const [isFetching, setIsFetching] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [notes, setNotes] = useState('');
  const [consultants, setConsultants] =
    useState<IGetListDto<IConsultantsResponse> | null>(null);
  const [optionConsultants, setOptionConsultants] = useState<ILabelInValue[]>(
    [],
  );

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const response = await consultantApi.getConsultants(
        1,
        0,
        {
          fullName: searchConsultantDeb,
          consultantOnboardingStatus: 'published,toConfirm',
        },
        { sort: 'asc', sortBy: 'firstName' },
      );
      const data = response?.result.map((item) => ({
        label: `${item.firstName} ${item.lastName} (${item.publicId})`,
        value: item._id,
      }));
      if (data) {
        setConsultants(response);
        setOptionConsultants(data);
      }
      setIsFetching(false);
    };

    getData();
  }, [searchConsultantDeb]);

  const onSearchConsultant = (value: string) => {
    setSearchConsultant(value);
  };
  const onChangeConsultant = (item: ILabelInValue) => {
    setSelectedConsultant({
      value: item?.value || '',
      label: item?.label || '',
    });
  };

  const onClear = () => {
    setSearchConsultant('');
  };

  const onChangeFile = (info: UploadChangeParam<UploadFile>) => {
    setFiles(info.fileList);
  };

  const onSubmit = () => {
    fileApi
      .uploadDocuments(files, selectedConsultant.value, notes)
      .then((resp: any) => {
        if (resp.failed.length > 0) {
          openErrorNotification(
            resp.failed
              .map(
                (item: any) =>
                  `${item.originalFilename || ''}: ${item.error || ''}`,
              )
              .join(', '),
          );
          return;
        }
        openSuccessNotification('Documents was uploaded successfully');
      })
      .catch((e) => {
        openErrorNotification('Failed to upload documents');
      })
      .finally(() => {
        setIsOpen(false);
        getDocuments();
      });
  };

  return (
    <Modal
      open={isOpen}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
      title="Upload document"
      wrapClassName="modal-window-wrapper">
      <div>
        <Row style={{ margin: '2rem 0' }}>
          <Col span={24}>
            <Dropdown
              allowClear={!!selectedConsultant.value}
              labelInValue={true}
              isSearch={false}
              options={optionConsultants}
              value={selectedConsultant}
              onChange={onChangeConsultant}
              onSearch={onSearchConsultant}
              onClear={onClear}
              label="Consultant"
              isFetching={isFetching}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '2rem' }}>
          <Col span={24}>
            <Upload
              beforeUpload={() => false}
              multiple
              showUploadList={true}
              fileList={files}
              className="upload-doc-modal"
              onChange={onChangeFile}>
              <div className="input-label-wrapper">
                <label
                  className="input-label file"
                  htmlFor={`file`}
                  style={{ width: '100%' }}>
                  <span className={`attach-label`}>Attach a file</span>
                  <AttachFileIcon style={{ opacity: 1 }} />
                </label>
              </div>
            </Upload>
          </Col>
          <span style={{ color: '#E48900', marginLeft: '10px' }}>
            You can upload several files. For each file separate line will be
            created.
          </span>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '2rem' }}>
          <Col span={24}>
            <Textarea
              name="notes"
              value={notes || ''}
              rows={3}
              label="Notes"
              onChange={(e) => setNotes(e.target.value)}
              autoSize={false}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Button
              buttonStyle="secondary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              disable={!selectedConsultant.value || files.length === 0}
              buttonStyle="primary"
              buttonSize="normal"
              buttonWidth="100%"
              onClick={() => onSubmit()}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default UploadDocumentModal;
