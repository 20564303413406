export interface ITaxSource {
  isSubject: boolean | null;
  taxItems: Array<ITaxItem>;
}

export interface ITaxItem {
  startDate: string;
  endDate: string;
  city: string;
  subjectToTaxAtSource: boolean;
  cantonIS: string;
  taxScale: string;
  adjustedTaxScale: string;
  comment: string;
  isManual: boolean;
  isCityUpdated: boolean;
  isAbroad?: boolean | null;
}

export enum CantonIS {
  AG = 'AG',
  AI = 'AI',
  AR = 'AR',
  BE = 'BE',
  BL = 'BL',
  BS = 'BS',
  FR = 'FR',
  GE = 'GE',
  GL = 'GL',
  GR = 'GR',
  JU = 'JU',
  LU = 'LU',
  NE = 'NE',
  NW = 'NW',
  OW = 'OW',
  SG = 'SG',
  SH = 'SH',
  SO = 'SO',
  SZ = 'SZ',
  TG = 'TG',
  TI = 'TI',
  UR = 'UR',
  VD = 'VD',
  VS = 'VS',
  ZG = 'ZG',
  ZH = 'ZH',
}
