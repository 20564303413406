import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import TimesheetsIcon from '../../assets/icons/TimesheetsIcon';
import ExpensesIcon from '../../assets/icons/ExpensesIcon';
import ActivityReportIcon from '../../assets/icons/ActivityReportIcon';
import InvoicesIcon from '../../assets/icons/InvoicesIcon';
import PeopleIcon from '../../assets/icons/PeopleIcon';
import WorkPermitTrackingIcon from '../../assets/icons/WorkPermitTrackingIcon';
import { ROUTER_PATHS } from '../../navigation/routerPaths';
import MyInfoIcon from '../../assets/icons/MyInfoIcon';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import PayslipsIcon from '../../assets/icons/PayslipsIcon';
import ReportingIcon from '../../assets/icons/ReportingIcon';
import SystemInfoIcon from '../../assets/icons/SystemInfoIcon';

interface Props {
  label: ReactNode;
  key: string;
  icon?: ReactNode;
  children?: any[];
  type?: 'group';
}

const getItem = (
  label: ReactNode,
  key: string,
  icon?: ReactNode,
  children?: any[],
  type?: 'group',
): Props => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const {
  timesheets,
  activityReport,
  reporting,
  employees,
  invoices,
  expenses,
  consultants,
  waitingRoom,
  monitoring,
  payslips,
  documents,
  myInformation,
  clients,
  consultantPayslips,
  employeePayslips,
  systemInfo,
  mainActivities,
  workPermit,
} = ROUTER_PATHS;

export const menuItems: Props[] = [
  getItem(
    <Link to={mainActivities}>Main Activities</Link>,
    mainActivities,
    <TimesheetsIcon className="time-icon" />,
  ),
  getItem(
    <Link to={timesheets}>Timesheets</Link>,
    timesheets,
    <TimesheetsIcon className="time-icon" />,
  ),
  getItem(
    <Link to={expenses}>Expenses</Link>,
    expenses,
    <ExpensesIcon className="expenses-icon" />,
  ),
  getItem(
    <Link to={activityReport}>Activity report</Link>,
    activityReport,
    <ActivityReportIcon className="report-icon" />,
  ),
  getItem(
    <Link to={reporting}>Reporting</Link>,
    reporting,
    <ReportingIcon className="reporting-icon" />,
  ),
  getItem(
    <Link to={invoices}>Invoices</Link>,
    invoices,
    <InvoicesIcon className="invoice-icon" />,
  ),
  getItem('People', 'sub1', <PeopleIcon className="people-icon" />, [
    getItem(<Link to={clients}>Clients</Link>, clients, '·'),
    getItem(<Link to={employees}>Employees</Link>, employees, '·'),
    getItem(<Link to={consultants}>Consultants</Link>, consultants, '·'),
    getItem(<Link to={waitingRoom}>Waiting room</Link>, waitingRoom, '·'),
  ]),
  getItem(
    <Link to={monitoring}>Admin Report</Link>,
    monitoring,
    <WorkPermitTrackingIcon className="permit-icon" />,
  ),
  getItem(
    <Link to={workPermit}>Work Permit Report</Link>,
    workPermit,
    <DocumentIcon className="document-icon" />,
  ),
  getItem(
    <Link to={payslips}>Payslips</Link>,
    payslips,
    <PayslipsIcon className="payslips-icon" />,
  ),
  getItem(
    <Link to={documents}>Documents</Link>,
    documents,
    <DocumentIcon className="document-icon" />,
  ),

  getItem(
    <Link to={myInformation}>My information</Link>,
    myInformation,
    <MyInfoIcon className="my-info-icon" />,
  ),
  getItem(
    <Link to={consultantPayslips}>Payslips</Link>,
    consultantPayslips,
    <PayslipsIcon className="payslips-icon" />,
  ),
  getItem(
    <Link to={employeePayslips}>Payslips</Link>,
    employeePayslips,
    <PayslipsIcon className="payslips-icon" />,
  ),
  getItem(
    <Link to={systemInfo}>System info</Link>,
    systemInfo,
    <SystemInfoIcon className="payslips-icon" />,
  ),
];
