import Button from '../../../../../components/button/Button';
import CommonTable from '../../../../../components/common-table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import moment from '../../../../../common/constants/moment';
import { commonDateMonthFormat } from '../../../../../common/constants/dateConstants';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import {
  addNewTaxItem,
  removeTaxItem,
  updateTaxItem,
} from '../../../../../redux/reducers/onboarding/sliceReducer';
import TaxInput from './TaxInput';
import { CantonIS } from '../../../../../common/api/services/OnboardingApi/types/ITaxSource';
import CommonDatePicker from '../../../../../components/date-picker/CommonDatePicker';
import { Select, Switch } from 'antd';
import AngleIcon from '../../../../../assets/icons/AngleIcon';
import { useState } from 'react';
import DeleteTaxModal from './DeleteTaxModal';
import { openSuccessNotification } from '../../../../../components/notification/Notification';

const TaxAtSourceItems = () => {
  const dispatch = useAppDispatch();
  const { taxItems, isSubject } = useAppSelector(
    (state) => state.onboarding.taxAtSource,
  );
  const [selectedDelete, setSelectedDelete] = useState<number | null>(null);

  const initialItem = {
    startDate: '',
    endDate: '',
    city: '',
    subjectToTaxAtSource: !!isSubject,
    cantonIS: '',
    taxScale: '',
    adjustedTaxScale: '',
    comment: '',
    isManual: true,
    isCityUpdated: false,
    isAbroad: null,
  };

  const onClose = () => {
    setSelectedDelete(null);
  };

  const onDelete = () => {
    if (selectedDelete !== null) {
      dispatch(removeTaxItem(selectedDelete));
      openSuccessNotification('The tax item was deleted successfully.');
      onClose();
    }
  };

  const data: any = taxItems?.map((item, index) => {
    return {
      startDate: item?.startDate
        ? moment(item.startDate).format(commonDateMonthFormat)
        : '',
      endDate: item?.endDate
        ? moment(item.endDate).format(commonDateMonthFormat)
        : '',
      city: item?.city || '',
      subjectToTaxAtSource: !!item?.subjectToTaxAtSource,
      cantonIS: item?.cantonIS,
      taxScale: item?.taxScale,
      adjustedTaxScale: item?.adjustedTaxScale,
      comment: item?.comment,
      key: index,
      isManual: item?.isManual,
      isAbroad: item?.isAbroad,
    };
  });

  const columns: any = [
    {
      title: 'Start date',
      dataIndex: 'startDate',
      width: 125,
      render: (text: any, data: any) => (
        <CommonDatePicker
          label=""
          changeDate={(date) =>
            dispatch(
              updateTaxItem({
                index: data.key,
                key: 'startDate',
                value: date ? moment(date).format(commonDateMonthFormat) : '',
              }),
            )
          }
          defaultValue={data.startDate}
          bodyContainer
          disabled={!isSubject}
        />
      ),
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      width: 125,
      render: (text: any, data: any) => (
        <CommonDatePicker
          label=""
          changeDate={(date) =>
            dispatch(
              updateTaxItem({
                index: data.key,
                key: 'endDate',
                value: date ? moment(date).format(commonDateMonthFormat) : '',
              }),
            )
          }
          defaultValue={data.endDate}
          bodyContainer
          disabled={!isSubject}
        />
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 90,
      render: (text: string, data: any) =>
        data?.isAbroad ? (
          <TaxInput
            index={data.key}
            keyValue="city"
            value="EX"
            disabled={true}
          />
        ) : (
          <TaxInput
            index={data.key}
            keyValue="city"
            value={text}
            disabled={!isSubject}
          />
        ),
    },
    {
      title: 'Subject to Tax at Source',
      dataIndex: 'subjectToTaxAtSource',
      width: 100,
      render: (text: boolean, data: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Switch
            checked={!!text}
            onChange={(checked) =>
              dispatch(
                updateTaxItem({
                  index: data.key,
                  key: 'subjectToTaxAtSource',
                  value: checked,
                }),
              )
            }
            disabled={!isSubject}
          />
        </div>
      ),
    },
    {
      title: 'Canton IS',
      dataIndex: 'cantonIS',
      width: 110,
      render: (text: string, data: any) => (
        <Select
          options={Object.values(CantonIS).map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          disabled={!isSubject || !data?.subjectToTaxAtSource}
          onChange={(value: any) =>
            dispatch(
              updateTaxItem({
                index: data.key,
                key: 'cantonIS',
                value,
              }),
            )
          }
          className="tax-dropdown"
          suffixIcon={<AngleIcon />}
          value={text}
        />
      ),
    },
    {
      title: 'Tax scale',
      dataIndex: 'taxScale',
      width: 135,
      render: (text: string, data: any) => (
        <TaxInput
          index={data.key}
          keyValue="taxScale"
          value={text}
          disabled={!isSubject || !data?.subjectToTaxAtSource}
        />
      ),
    },
    {
      title: 'Adjusted Tax Scale',
      dataIndex: 'adjustedTaxScale',
      width: 75,
      render: (text: string, data: any) => (
        <TaxInput
          index={data.key}
          keyValue="adjustedTaxScale"
          value={text}
          disabled={!isSubject || !data?.subjectToTaxAtSource}
        />
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: 180,
      render: (text: string, data: any) => (
        <TaxInput
          index={data.key}
          keyValue="comment"
          value={text}
          disabled={!isSubject || !data?.subjectToTaxAtSource}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 60,
      render: (text: string, data: any) => {
        if (!data.isManual) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TrashIcon
              onClick={() => {
                if (isSubject) {
                  setSelectedDelete(data.key);
                }
              }}
              disabled={!isSubject}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="tax-table-wrapper">
      <CommonTable
        columns={columns}
        data={data}
        tableHeight={300}
        height={300}
        hidePagination
        hasRowDatePicker
        rowKey="key"
        key="key"
      />
      <Button
        buttonSize="medium"
        buttonStyle="primary"
        buttonWidth={'100%'}
        disable={!isSubject}
        className={`add-tax-item-btn-${!isSubject ? 'disabled' : ''}`}
        onClick={() => dispatch(addNewTaxItem(initialItem))}>
        + Add line
      </Button>
      {selectedDelete !== null && (
        <DeleteTaxModal
          isOpen={selectedDelete !== null}
          onClose={onClose}
          onSubmit={onDelete}
        />
      )}
    </div>
  );
};

export default TaxAtSourceItems;
