import { CSSProperties, FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

import './Checkbox.less';

interface IProps {
  checked?: boolean;
  checkboxStyle?: CSSProperties;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  disabled?: boolean;
  className?: string;
  showAsterisk?: boolean;
}

const Checkbox: FC<IProps> = ({
  checked = false,
  checkboxStyle,
  className = '',
  disabled,
  label,
  onChange,
  showAsterisk = false,
}) => {
  return (
    <div className="checkbox-wrapper">
      <AntCheckbox
        className={`checkbox ${className}`}
        checked={checked}
        disabled={disabled}
        style={checkboxStyle}
        onChange={onChange}>
        {label}
      </AntCheckbox>
      {showAsterisk && <span className="asterisk">*</span>}
    </div>
  );
};

export default Checkbox;
