import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const UploadedFileIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2198 8.91624L8.62189 14.5142C7.15742 15.9786 4.78305 15.9786 3.31859 14.5142V14.5142C1.85412 13.0497 1.85412 10.6753 3.31859 9.21086L10.095 2.43442C11.0713 1.45811 12.6542 1.45811 13.6306 2.43442V2.43442C14.6069 3.41073 14.6069 4.99365 13.6306 5.96996L6.76994 12.8306C6.28179 13.3187 5.49033 13.3187 5.00217 12.8306V12.8306C4.51402 12.3424 4.51402 11.551 5.00217 11.0628L10.6843 5.3807"
        stroke="#ABBED1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UploadedFileIcon;
