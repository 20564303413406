import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const DropzoneIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.5584 15.0824C56.878 6.3848 47.5003 0.199997 36.5155 0.199997C25.5307 0.199997 16.153 6.3776 12.4726 15.0752C5.58604 16.6952 0.499428 22.3184 0.499428 29C0.499428 36.956 7.69139 43.4 16.5628 43.4H19.7851C20.1387 43.4 20.428 43.1408 20.428 42.824V40.664V38.504C20.428 38.1872 20.1387 37.928 19.7851 37.928H16.5628C13.8548 37.928 11.3075 36.9632 9.41103 35.2136C7.52264 33.4712 6.51818 31.124 6.60657 28.6904C6.67889 26.7896 7.40211 25.004 8.71193 23.4992C10.0539 21.9656 11.9342 20.8496 14.0235 20.3528L17.0691 19.64L18.186 17.0048C18.8771 15.3632 19.8414 13.8296 21.0548 12.44C22.2527 11.0627 23.6717 9.85196 25.2655 8.8472C28.5682 6.7664 32.4575 5.6648 36.5155 5.6648C40.5735 5.6648 44.4628 6.7664 47.7655 8.8472C49.3646 9.8552 50.7789 11.0648 51.9762 12.44C53.1896 13.8296 54.1539 15.3704 54.845 17.0048L55.9539 19.6328L58.9914 20.3528C63.3468 21.404 66.3923 24.9536 66.3923 29C66.3923 31.3832 65.3557 33.6296 63.4753 35.3144C62.5532 36.1455 61.4562 36.8044 60.2479 37.253C59.0396 37.7016 57.744 37.9311 56.436 37.928H53.2137C52.8601 37.928 52.5709 38.1872 52.5709 38.504V42.824C52.5709 43.1408 52.8601 43.4 53.2137 43.4H56.436C65.3075 43.4 72.4994 36.956 72.4994 29C72.4994 22.3256 67.4289 16.7096 60.5584 15.0824Z"
        fill="#ABBED1"
      />
      <path
        d="M43.6994 27.2L38.842 21.1283C37.6411 19.6271 35.3578 19.6271 34.1568 21.1283L29.2994 27.2"
        stroke="#ABBED1"
        strokeWidth="5.4"
        strokeLinecap="round"
      />
      <path
        d="M36.4994 21.2L36.4994 57.2"
        stroke="#ABBED1"
        strokeWidth="5.4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DropzoneIcon;
