import { FC } from 'react';
import { IPassProps, passProps } from './passProps';

const EyeIcon: FC<IPassProps> = (props) => {
  return (
    <svg
      {...passProps(props)}
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.16714 6.65368C0.944283 6.24617 0.944287 5.75371 1.16715 5.3462C2.58334 2.75669 5.33613 1 8.49997 1C11.6639 1 14.4167 2.75674 15.8329 5.34632C16.0557 5.75383 16.0557 6.24629 15.8328 6.6538C14.4167 9.24331 11.6639 11 8.50003 11C5.33614 11 2.58331 9.24326 1.16714 6.65368Z"
        stroke="#ABBED1"
        strokeWidth="1.5"
      />
      <circle cx="8.5" cy="6" r="2.5" stroke="#ABBED1" strokeWidth="1.5" />
    </svg>
  );
};

export default EyeIcon;
