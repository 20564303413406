export const downloadExel = (response: Blob, name?: string): void => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name || Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const downloadFileType = (
  response: Blob,
  name?: string,
  fileType?: string,
): void => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name || Date.now()}.${fileType || 'xlsx'}`);
  document.body.appendChild(link);
  link.click();
};
