import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IPagination } from '../../types';

const initialState: IState = {
  viewPayslipsList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
  },
  errorMessages: null,
  selectedPayslips: [],
  selectedIds: [],
};

const reducer = createSlice({
  name: 'view-payslips',
  initialState,
  reducers: {
    setViewPayslipsList(state, action: PayloadAction<IGetListDto<any>>) {
      state.viewPayslipsList.content = action.payload.result;
      state.viewPayslipsList.pagination.totalElement = action.payload.total;
    },
    changeViewPayslipsFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.viewPayslipsList.filter = {
        ...state.viewPayslipsList.filter,
        ...action.payload,
      };
      state.viewPayslipsList.pagination.currentPage = 1;
    },
    setViewPayslipsSorting(state, action: PayloadAction<any>) {
      state.viewPayslipsList.sort.sortBy = action.payload[0];
      state.viewPayslipsList.sort.sort = action.payload[1];
    },
    clearViewPayslipsFiltersAndSorts(state) {
      state.viewPayslipsList.sort = initialState.viewPayslipsList.sort;
      state.viewPayslipsList.filter = initialState.viewPayslipsList.filter;
    },
    setViewPayslipsPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.viewPayslipsList.pagination.currentPage =
        action.payload.currentPage;
      state.viewPayslipsList.pagination.perPage = action.payload.perPage;
    },
    selectViewPayslipsItem(state, action: PayloadAction<string[]>) {
      state.selectedPayslips = action.payload;
    },
    setSelectedIds(state, action: PayloadAction<string[]>) {
      state.selectedIds = action.payload;
    },
  },
});

export const {
  setViewPayslipsList,
  changeViewPayslipsFiltering,
  setViewPayslipsSorting,
  clearViewPayslipsFiltersAndSorts,
  setViewPayslipsPagination,
  selectViewPayslipsItem,
  setSelectedIds,
} = reducer.actions;

export default reducer.reducer;
