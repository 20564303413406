export enum OnboardingRequestKeys {
  personalInformation = 'personalInformation',
  clientContract = 'clientContract',
  workContract = 'workContract',
  workPermit = 'workPermit',
  bankAccount = 'bankAccount',
  childAllowances = 'childAllowances',
  ssnInformation = 'ssnInformation',
  additionalInformation = 'additionalInformation',
}
