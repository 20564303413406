import { ExpensesStatus } from '../api/services/ConsultantApi/enums/ResponseEnums';
import { InvoiseStatuses } from '../api/services/InvoiceApi/types/types';
import { TimesheetStatus } from '../api/services/TimesheetApi/enums/TimesheetStatus';

export type TTimesheetStatusParserKey =
  | TimesheetStatus.DRAFT
  | TimesheetStatus.WAITING_FOR_APPROVAL
  | TimesheetStatus.PRE_APPROVED
  | TimesheetStatus.APPROVED
  | TimesheetStatus.NO_ACTIVITY
  | TimesheetStatus.EXPIRED
  | TimesheetStatus.REJECTED
  | TimesheetStatus.CLIENT_APPROVAL;

export const timesheetStatusParser: Record<any, string> = {
  [TimesheetStatus.DRAFT]: 'draft',
  [TimesheetStatus.WAITING_FOR_APPROVAL]: 'waiting-for-approval',
  [TimesheetStatus.PRE_APPROVED]: 'pre-approved',
  [TimesheetStatus.APPROVED]: 'approved-consultant',
  [TimesheetStatus.NO_ACTIVITY]: 'no-activity',
  [TimesheetStatus.EXPIRED]: 'expired',
  [TimesheetStatus.REJECTED]: 'rejected',
  [TimesheetStatus.CLIENT_APPROVAL]: 'client-approval',
};

export const mainActivitiesStatusParser: Record<any, string> = {
  [TimesheetStatus.DRAFT]: 'draft',
  [TimesheetStatus.WAITING_FOR_APPROVAL]: 'waiting-for-approval',
  [TimesheetStatus.PRE_APPROVED]: 'pre-approved',
  [TimesheetStatus.APPROVED]: 'approved-consultant',
  [TimesheetStatus.NO_ACTIVITY]: 'no-activity',
  [TimesheetStatus.EXPIRED]: 'expired',
  [TimesheetStatus.REJECTED]: 'rejected',
  [TimesheetStatus.CLIENT_APPROVAL]: 'client-approval',
  [ExpensesStatus.NO_EXPENSES]: 'no-expenses',
};

export type TViewInvoiceStatusParserKey =
  | InvoiseStatuses.DRAFT
  | InvoiseStatuses.PAID
  | InvoiseStatuses.PARTIALLY_PAID
  | InvoiseStatuses.SENT;

export const viewIncoiceStatusParser: Record<any, string> = {
  [InvoiseStatuses.DRAFT]: 'draft',
  [InvoiseStatuses.PAID]: 'paid',
  [InvoiseStatuses.SENT]: 'sent',
  [InvoiseStatuses.PARTIALLY_PAID]: 'partially-paid',
};
