import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import SuccessIcon from '../../../../assets/icons/SuccessIcon';
import { INavigationItems } from './navigationItems';
import { useAppSelector } from '../../../../redux/store';
import { OnboardingRequestStatuses } from '../../../../common/api/services/OnboardingApi/enums/OnboardingRequestStatuses';
import { Roles } from '../../../../common/enums/Roles';

interface IProps {
  navigationItems: INavigationItems[];
}

const NavigationTabs: FC<IProps> = ({ navigationItems }) => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const { tabStatus } = useAppSelector((state) => state.onboarding);
  const { role } = useAppSelector((state) => state.auth);
  const isAdmin = Roles.ADMIN === role || Roles.MANAGER === role;

  return (
    <nav className="navigation-tabs">
      {navigationItems.map(({ to, width, label, key }) => {
        const isActive = pathname.includes(to);

        const tabState =
          tabStatus[key] === OnboardingRequestStatuses.published ||
          (!isAdmin && tabStatus[key] === OnboardingRequestStatuses.toVerify)
            ? '__publish'
            : tabStatus[key] === OnboardingRequestStatuses.draft ||
              (isAdmin && tabStatus[key] === OnboardingRequestStatuses.toVerify)
            ? '__draft'
            : tabStatus[key] === OnboardingRequestStatuses.toConfirm
            ? '__to-confirm'
            : '';

        return (
          <span
            key={key}
            onClick={() =>
              navigation(to, { state: { prevLocation: pathname } })
            }
            style={{ width }}
            className={isActive ? '__active' : tabState}>
            {label}
            {(tabStatus[key] === OnboardingRequestStatuses.draft ||
              tabStatus[key] === OnboardingRequestStatuses.toConfirm ||
              (isAdmin &&
                tabStatus[key] === OnboardingRequestStatuses.toVerify)) &&
              !isActive && <WarningIcon />}
            {(tabStatus[key] === OnboardingRequestStatuses.published ||
              (!isAdmin &&
                tabStatus[key] === OnboardingRequestStatuses.toVerify)) &&
              !isActive && <SuccessIcon />}
          </span>
        );
      })}
    </nav>
  );
};

export default NavigationTabs;
