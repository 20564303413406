import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './type';
import { IPagination, TSortValue } from '../../types';
import { IGetListDto } from '../../../common/api/types/IGetListDto';
import { IDownloadZipReportingRequest } from '../../../common/api/services/ReportingApi/types/IReportingResponse';

const initialState: IState = {
  reportingList: {
    content: [],
    pagination: {
      totalElement: 0,
      currentPage: 1,
      perPage: 10,
    },
    sort: {
      sort: '',
      sortBy: '',
    },
    filter: {},
    selectedConsultant: [],
  },
  selectedArray: null,
  isLoading: false,
  selectedToExport: [],
};

const reducer = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setReportingList(state, action: PayloadAction<IGetListDto<any>>) {
      state.reportingList.content = action.payload.result;
      state.reportingList.pagination.totalElement = action.payload.total;
    },
    changeReportingFiltering(
      state,
      action: PayloadAction<Record<string, string | string[]>>,
    ) {
      state.reportingList.filter = {
        ...state.reportingList.filter,
        ...action.payload,
      };
    },
    setReportingSorting(state, action: PayloadAction<[string, TSortValue]>) {
      state.reportingList.sort.sortBy = action.payload[0];
      state.reportingList.sort.sort = action.payload[1];
    },
    clearReportingFiltersAndSorts(state) {
      state.reportingList.sort = initialState.reportingList.sort;
      state.reportingList.filter = initialState.reportingList.filter;
      state.selectedArray = null;
      state.reportingList.selectedConsultant =
        initialState.reportingList.selectedConsultant;
    },
    setReportingPagination(
      state,
      action: PayloadAction<Omit<IPagination, 'totalElement'>>,
    ) {
      state.reportingList.pagination.currentPage = action.payload.currentPage;
      state.reportingList.pagination.perPage = action.payload.perPage;
    },
    selectReportingItem(state, action: PayloadAction<string[]>) {
      state.reportingList.selectedConsultant = action.payload;
    },
    selectReportingArray(
      state,
      action: PayloadAction<IDownloadZipReportingRequest | null>,
    ) {
      state.selectedArray = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSelectedToExport(state, action: PayloadAction<string[]>) {
      state.selectedToExport = action.payload;
    },
  },
});

export const {
  setReportingList,
  setIsLoading,
  selectReportingItem,
  clearReportingFiltersAndSorts,
  setReportingPagination,
  changeReportingFiltering,
  setReportingSorting,
  selectReportingArray,
  setSelectedToExport,
} = reducer.actions;

export default reducer.reducer;
