import { FC } from 'react';
import CommonTable from '../../../../components/common-table/CommonTable';
import { ColumnsType } from 'antd/es/table/interface';

const FinancialStructure: FC = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'Order',
      dataIndex: 'treasury',
      key: 'treasury',
    },
    {
      title: '?',
      dataIndex: 'advancedTiming',
      key: 'advancedTiming',
    },
    {
      title: 'Currency',
      dataIndex: 'billCurrency',
      key: 'billCurrency',
    },
    {
      title: 'Rate category',
      dataIndex: 'billRateType',
      key: 'billRateType',
    },
  ];

  return (
    <div className="timesheets-and-expenses">
      <CommonTable data={[]} columns={columns} />
    </div>
  );
};

export default FinancialStructure;
