import CrossIcon from '../../../assets/icons/CrossIcon';
import ExitIcon from '../../../assets/icons/ExitIcon';
import ResetFilterIcon from '../../../assets/icons/ResetFilterIcon';
import Button from '../../../components/button/Button';
import {
  exportToExelConsultant,
  getConsultantsList,
} from '../../../redux/reducers/consultants/asyncThunkActions';
import {
  clearConsultantsFiltersAndSorts,
  setIsShowConsultantModal,
} from '../../../redux/reducers/consultants/sliceReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import '../../../components/extra-section/ExtraSection.less';

const ConsultantExtraSection = () => {
  const dispatch = useAppDispatch();

  const {
    sort: consultantsSorts,
    filter: consultantsFilters,
    selectedConsultants,
  } = useAppSelector((state) => state.consultants.consultantsList);

  const isResetConsultantsFilters =
    consultantsSorts.sortBy !== '' ||
    Object.values(consultantsFilters).some((el) => {
      if (Array.isArray(el)) return el.length !== 0;
      return !!el;
    }) ||
    selectedConsultants.length;
  return (
    <>
      {isResetConsultantsFilters ? (
        <button
          className="rest-filters-button"
          onClick={() => {
            dispatch(clearConsultantsFiltersAndSorts());
            dispatch(getConsultantsList({ currentPage: 1 }));
          }}>
          <ResetFilterIcon />
        </button>
      ) : null}
      <Button
        onClick={() => dispatch(setIsShowConsultantModal(true))}
        buttonStyle="primary"
        buttonSize="medium"
        buttonWidth={160}
        leftIcon={<CrossIcon style={{ transform: 'rotate(45deg)' }} />}>
        Add new
      </Button>
      <Button
        onClick={() => dispatch(exportToExelConsultant())}
        buttonStyle="secondary"
        buttonSize="medium"
        buttonWidth={200}
        leftIcon={<ExitIcon className="export-icon" />}>
        Export to Excel
      </Button>
    </>
  );
};

export default ConsultantExtraSection;
