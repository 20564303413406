import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Moment } from 'moment';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import FilterIcon from '../../assets/icons/FilterIcon';
import Button from '../../components/button/Button';
import CommonDatePicker from '../../components/date-picker/CommonDatePicker';
import '../../components/date-picker/DatePicker.less';
import {
  commonDateMonthFormat,
  commonYearDateFormat,
} from '../constants/dateConstants';
import moment from '../../common/constants/moment';

interface IArguments {
  fetchRequest: () => void;
  changeValues: (period: Record<string, string>) => void;
  defaultValue: string;
  valueType: string;
  placeholder?: string;
}

export const useTableMonthDate = ({
  fetchRequest,
  changeValues,
  defaultValue,
  valueType,
  placeholder,
}: IArguments) => {
  const value = defaultValue ? defaultValue.split('|')[0] : '';
  const [periodDate, setPeriodDate] = useState<Moment | string>(value);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (value) {
      setIsFiltered(true);
    }

    if (!value) {
      setIsFiltered(false);
      setPeriodDate('');
    }
  }, [defaultValue]);

  const onSubmit = (confirm: (param?: FilterConfirmProps) => void): void => {
    if (!!periodDate) {
      const periodDateValue =
        typeof periodDate === 'string' ? moment(periodDate) : periodDate;
      changeValues({
        [`${valueType}`]:
          (periodDateValue as Moment)
            .startOf('month')
            .format(commonYearDateFormat) +
          '|' +
          (periodDateValue as Moment)
            .endOf('month')
            .format(commonYearDateFormat),
        before: '',
        after: '',
      });

      setIsFiltered(true);
      fetchRequest();
    } else {
      setIsFiltered(false);
    }
    confirm();
  };

  const onClear = (confirm: (param?: FilterConfirmProps) => void): void => {
    changeValues({ [`${valueType}`]: '' });
    fetchRequest();
    confirm();
    setIsFiltered(false);
  };

  return (): ColumnType<any> => ({
    filterDropdown: ({ confirm }) => (
      <div className="table-date-filter-dropdown">
        <div className="filter-date">
          <CommonDatePicker
            defaultValue={
              periodDate ? moment(periodDate).format(commonDateMonthFormat) : ''
            }
            changeDate={(date) => setPeriodDate(date ? moment(date) : '')}
            className="date-picker-form"
            placeholder={placeholder || valueType || 'Period'}
            picker="month"
          />
        </div>
        <Space
          className={`${
            isFiltered ? '__filtered reset-filters' : 'reset-filters'
          }`}>
          <button
            className="reset-filters"
            onClick={() => onClear(confirm)}
            style={{ color: isFiltered ? '#0671e0' : '#abbed1' }}>
            Reset filters
          </button>
          <Button
            buttonSize="small"
            buttonStyle="primary"
            onClick={() => onSubmit(confirm)}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <FilterIcon className={isFiltered ? '__filtered' : ''} />,
  });
};
