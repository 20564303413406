import { ChangeEvent, FC, Fragment, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import Input from '../../../../components/input/Input';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import { updateOnboardingAdditionalInfoItem } from '../../../../redux/reducers/onboarding/sliceReducer';
import Button from '../../../../components/button/Button';

interface IProps {
  addContact: () => void;
  deleteContact: (id: string) => void;
}

const AdditionalInfoTab: FC<IProps> = ({ addContact, deleteContact }) => {
  const dispatch = useAppDispatch();
  const [contactId, setContactId] = useState('');
  const { contacts } = useAppSelector(
    (state) => state.onboarding.additionalInfo,
  );
  const errorMessages = useAppSelector(
    (state) => state.onboarding.validationError.additionalInfo,
  );

  const removeContact = (id: string) => (): void => {
    deleteContact(id);
    setContactId('');
  };

  const onChangePhone = (id: string) => (value: string) => {
    dispatch(
      updateOnboardingAdditionalInfoItem({
        id,
        field: { phone: value },
      }),
    );
  };

  const onChange =
    (id: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const fieldName = event.target.name;
      const fieldValue = event.target.value;

      dispatch(
        updateOnboardingAdditionalInfoItem({
          id,
          field: { [fieldName]: fieldValue },
        }),
      );
    };

  const getError = (fieldName: string): string => {
    if (errorMessages) {
      return errorMessages[fieldName] ?? '';
    }

    return '';
  };

  const contactNumber =
    (contacts?.findIndex((contact) => contact._id === contactId) ?? 0) + 1;

  return (
    <Row>
      <Col span={12}>
        <h4>Emergency contact</h4>
        <p className="aditional-p">
          Filling information on this page is optional.
        </p>

        {contacts.map((contact, idx) => {
          const { firstName, lastName, phone, email, _id, relationshipStatus } =
            contact;

          return (
            <Fragment key={_id}>
              {contacts.length > 1 && (
                <Row justify="space-between" align="middle">
                  <h4>Contact {idx + 1}</h4>

                  <button
                    onClick={() => setContactId(_id)}
                    className="remove-child-btn">
                    Delete
                    <TrashIcon />
                  </button>
                </Row>
              )}

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Input
                    name="firstName"
                    label="First name"
                    value={firstName}
                    onChange={onChange(_id)}
                    errorMessage={getError(`data.contacts[${idx}].firstName`)}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="lastName"
                    label="Last name"
                    value={lastName}
                    onChange={onChange(_id)}
                    errorMessage={getError(`data.contacts[${idx}].lastName`)}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Input
                    inputType="phone"
                    value={phone ?? ''}
                    onChange={onChangePhone(_id)}
                    label="Phone number"
                    errorMessage={getError(`data.contacts[${idx}].phone`)}
                    placeholder="+XX XXX XXX XX XX"
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="email"
                    label="Email"
                    value={email}
                    onChange={onChange(_id)}
                    errorMessage={getError(`data.contacts[${idx}].email`)}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    name="relationshipStatus"
                    value={relationshipStatus}
                    onChange={onChange(_id)}
                    label="Relationship with the contact person"
                    errorMessage={getError(
                      `data.contacts[${idx}].relationshipStatus`,
                    )}
                  />
                </Col>
              </Row>
            </Fragment>
          );
        })}

        <button
          className="add-common-btn"
          // disabled={!hasChildren}
          onClick={addContact}>
          <CrossIcon />
          Add another contact
        </button>
      </Col>

      {!!contactId && (
        <Modal
          width={490}
          open={!!contactId}
          centered
          footer={null}
          destroyOnClose={true}
          wrapClassName="footer-edit-lead-modal-window-wrapper">
          <h4 style={{ marginBottom: 32 }}>Delete</h4>
          <p>
            Are you sure you want to delete <b>Contact {contactNumber}</b>? This
            action is irreversible.
          </p>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="secondary"
                onClick={() => setContactId('')}>
                Cancel
              </Button>
            </Col>

            <Col span={12}>
              <Button
                buttonWidth="100%"
                buttonSize="normal"
                buttonStyle="primary"
                onClick={removeContact(contactId)}>
                Yes, delete
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Row>
  );
};

export default AdditionalInfoTab;
